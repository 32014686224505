<!--
- Create Reference > Step 2 Third Party > Header
-
-->

<template>
	<div>
		<p class="org-size modal-top-title">
			<a v-if="!isDrawer" href="#" @click.prevent="$router.go(-1)" class="link-icon">
				<span class="svg bi-chevron-left" title="Back" />
			</a>
			Step 2 of 3: Select a reference to use
		</p>

		<reference-search-third-party
			:is-drawer="isDrawer"
			:is-first-search="false" />

		<transition name="route-fade">
			<div v-if="showManualCreateButton">
				<div class="d-grid">
					<button type="button" class="btn btn-sm btn-primary mt-1" @click.prevent="$router.go(-1)">
						Can't find it? Create a reference manually...
					</button>
				</div>
			</div>
		</transition>

		<hr class="mt-2 mb-0" />

	</div>
</template>

<script>
import ReferenceSearchThirdParty from '@/modules/CreateReferenceStep1/SearchThirdParty';

export default {
	name: 'CreateReferenceStep2ThirdPartyHeader',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			showManualCreateButton: false,
		}
	},
	methods: {
	},
	created(){
		this.emitter.on('CreateReferenceStep2ThirdParty_ShowManualCreateButton', () => {
			this.showManualCreateButton = true;
		});
	},
	components:{
		ReferenceSearchThirdParty
	},
	destroyed(){
		this.emitter.off('CreateReferenceStep2ThirdParty_ShowManualCreateButton');
	},
}
</script>



<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";
	@include color-mode(dark) {
		.modal-top-title{
			a.link-icon{
				color: $dark-body-color;
			}	
		}
	}

	.modal-top-title{

		a.link-icon{
			color: $body-color;
			float: left;

			&:hover {
				text-decoration: none;
			}

			span.svg {
				font-size: 1.5rem;
				line-height: 25px;
			}
		}
	}

</style>