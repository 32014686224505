var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "mb-3" }, [
        _vm._v(
          "\n\t\tReview and edit the information we found to make sure it's correct.\n\t"
        ),
      ]),
      _vm._v(" "),
      _vm.referenceTypeId === 3
        ? _c(
            "div",
            { key: "alertWebite", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tReferences viewed online should use the reference type for the original work. For example, journal articles viewed online use the Journal reference type - not Webpage.\n\t\t"
                ),
              ]),
            ]
          )
        : _vm.referenceTypeId === 17
        ? _c(
            "div",
            { key: "alertExecutiveOrders", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tThis reference type is for citing the formal publication of Executive Orders in the Code of Federal Regulations. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
                ),
              ]),
            ]
          )
        : _c("div", { key: "alertWarning", staticClass: "alert alert-info" }, [
            _c("span", { staticClass: "svg bi-check-circle-fill float-start" }),
            _vm._v(" "),
            _c("p", { staticClass: "ps-3" }, [
              _vm._v(
                "\n\t\t\tCommonly used fields are outlined in blue.\n\t\t"
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("reference-types", {
        attrs: {
          "is-third-party-look-up": true,
          "reference-type-id": _vm.referenceTypeId,
        },
      }),
      _vm._v(" "),
      _c("reference-output"),
      _vm._v(" "),
      _c("copy-json-data", {
        attrs: {
          "data-to-display": JSON.stringify(
            _vm.$store.state.referenceRules.rulesObjectData
          ),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-between" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnBack.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col text-end" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit", title: "Next" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.generateReference.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tNext\n\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }