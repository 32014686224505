<!--
- InputCartCreditCard
-- This is a one off but i'm putting it here to match the other form inputs
-->

<template>
	<div class="form-input-cart-credit-card position-relative">
		<label
			:class="['form-label', {'fst-italic':isInvalidShown}]"
			v-if="showLabel"
			v-html="calcLabelOutput" />

		<input
			autocomplete="off"
			v-cleave="{creditCard: true, onCreditCardTypeChanged : cardChanged}"
			:class="['form-control', {'is-invalid': isInvalidShown}]"	
			:disabled="isDisabled"
			:id="fullId"
			:placeholder="placeholder"
			:role="role"
			:type="localType"
			:value="value"
			@blur="blurInput($event.target.value)"
			@input="typeInput($event.target.value)" />
		
		<span
			v-if="calcCreditCardCssClass !== ''"
			:class="['svg', calcCreditCardCssClass]" />

		<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
			{{ invalidErrorMessage }}
		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import Cleave from 'cleave.js';
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'InputCartCreditCard',
	mixins: [mixinId],
	directives: {
        cleave: {
            inserted: (el, binding) => {
                el.cleave = new Cleave(el, binding.value || {})
            },
            update: (el) => {
                const event = new Event('input', {bubbles: true});
                setTimeout(function () {
                    el.value = el.cleave.properties.result
                    el.dispatchEvent(event)
                }, 100);
            }
        }
    },
	props:{
		formId: {
			type: String,
		},
		isDisabled: {
			default: false,
			type: Boolean,
		},
		helpText: {
			type: String,
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		role: {
			type: String,
		},
		showLabel:{
			default: true,
			type: Boolean,
		},
		type:{
			default: 'text',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			isShowSuggestion: false,
			localType: null,
		}
	},
	computed:{
		calcLabelOutput(){
			if(
				this.validateEnum === config.enums.Validation.EMAIL_REQUIRED || 
				this.validateEnum === config.enums.Validation.NUMERIC_REQUIRED ||
				this.validateEnum === config.enums.Validation.REQUIRED
			){
				return this.label + '*';
			}
			// if(this.thisValidation === )
			return this.label;
		},
		
		calcCreditCardCssClass(){
			let returnString = 'icons8-';

			switch (this.$store.state.cart.creditCardType){
				case 'Amex':
					return returnString + 'american-express';
				case 'Discover':
					return returnString + 'discover-card';
				case 'JCB':
					return returnString + 'jcb';
				case 'MasterCard':
					return returnString + 'mastercard';
				case 'Visa':
					return returnString + 'visa';
			}

			return returnString + 'credit-card';
		},
		
		fullId(){
			// generate one based on mixin if a specitic suffix isn't defined
			return this.safeId;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},

		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.fullId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		cardChanged(cardName){
			this.$store.commit('cart/SET_CREDIT_CARD_TYPE', cardName);
		},

		blurInput(value){
			AS_ValidateForm({
				singleFieldId: this.fullId
			}).then(()=>{
				this.$emit('blur-input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:blurInput

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.$emit('input', value);
		},//e:typeInput
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.fullId,
				validateEnum: this.validateEnum,
			})
		}
		this.localType = this.type;
	},
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars_perrla";
.form-input-cart-credit-card {
	.svg{
		color: $gray-600;
		font-size: 42px;
		position: absolute;
		top: 17px;
		right: 2px;
	}

	.is-invalid + .svg {
		right: 25px;
	}

}
</style>