var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "receipt-wrp mx-auto" }, [
    _c("div", { staticClass: "row mb-4 d-print-none" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("h1", { staticClass: "mb-3" }, [
            _vm._v("\n\t\t\t\t\tThanks for purchasing!\n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _vm.isNewCustomer
            ? _c(
                "p",
                { key: "pReceiptNewCustomer", staticClass: "mb-4 org-size" },
                [
                  _vm._v(
                    '\n\t\t\t\t\tWe\'ve sent "Getting Started Instructions" and a copy of your receipt to your email address.\n\t\t\t\t'
                  ),
                ]
              )
            : _c(
                "p",
                { key: "pReceiptEmailElse", staticClass: "mb-4 org-size" },
                [
                  _vm._v(
                    "\n\t\t\t\t\tWe've sent a copy of your receipt to the email address in your PERRLA account.\n\t\t\t\t"
                  ),
                ]
              ),
          _vm._v(" "),
          _vm.isNewCustomer
            ? _c("h2", [
                _vm._v(
                  "\n\t\t\t\t\tReady to start your first paper?\n\t\t\t\t"
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.isNewCustomer
      ? _c(
          "div",
          { key: "divNewCustomer1", staticClass: "row mb-4 d-print-none" },
          [
            _c("div", { staticClass: "col-12 col-md-6 mb-2 mb-md-0" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnBackToUsingPerrla.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "fw-bold" }, [
                      _vm._v("Use PERRLA Online"),
                    ]),
                    _c("br"),
                    _vm._v("\n\t\t\t\t\t- PERRLA's web-based editor\n\t\t\t\t"),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6 mb-2 mb-md-0" }, [
              _vm.isNewCustomer
                ? _c("div", { key: "divNewCustomer", staticClass: "d-grid" }, [
                    _vm._m(0),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      : _c(
          "div",
          { key: "divNewCustomerElse", staticClass: "row mb-4 d-print-none" },
          [
            _c("div", { staticClass: "col-6 mx-auto" }, [
              _c(
                "div",
                { staticClass: "d-grid" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: {
                        to: {
                          name: "MyProfile",
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tGo to Account Settings\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4 d-print-none" }, [
      _vm.isNewCustomer
        ? _c("div", { key: "divNewCustomer3", staticClass: "col org-size" }, [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col org-size" }, [
        _c("div", { staticClass: "boxset-wrp boxset-receipt-wrp" }, [
          _c("h2", [_vm._v("Receipt")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table table-borderless table-cart-totals" },
            [
              _c(
                "tbody",
                [
                  _vm._l(
                    _vm.$store.state.cart.products,
                    function (productData) {
                      return _c("tr", { key: productData.productID }, [
                        _c("td", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(productData.name) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "text-end",
                            attrs: { width: "100px" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t$" +
                                _vm._s(productData.price.toFixed(2)) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "fw-light" }, [
                      _vm._v("\n\t\t\t\t\t\t\t\tSubtotal\n\t\t\t\t\t\t\t"),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "fw-light text-end" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.calcSubTotalDisplay) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.calcDiscountDisplay !== "---"
                    ? _c("tr", [
                        _c("td", { staticClass: "fw-light" }, [
                          _vm._v("\n\t\t\t\t\t\t\t\tDiscount\n\t\t\t\t\t\t\t"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "fw-light text-end text-danger" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.calcDiscountDisplay) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "fw-light" }, [
                      _vm._v("\n\t\t\t\t\t\t\t\tTax\n\t\t\t\t\t\t\t"),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "fw-light text-end" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.calcTaxDisplay) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v("\n\t\t\t\t\t\t\t\tTotal\n\t\t\t\t\t\t\t"),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.calcTotalDisplay) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(5),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Transaction Details")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table table-borderless table-cart-totals" },
            [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v("\n\t\t\t\t\t\t\t\tTransaction ID\n\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "fw-light" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.transactionId) +
                        "\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n\t\t\t\t\t\t\t\tPurchase Number\n\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "fw-light" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.purchaseId) +
                        "\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n\t\t\t\t\t\t\t\tPayment Method\n\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "fw-light" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.calcPaymentMethodName) +
                        "\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n\t\t\t\t\t\t\t\tExpiration Date\n\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "fw-light" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.syncedExpirationDateOutput) +
                        "\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n\t\t\t\t\t\t\t\tRenewal\n\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "fw-light" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.calcRenewalMessage) +
                        "\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "btn btn-outline-primary px-5 float-end d-print-none",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnPrintReceipt.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\t\tPrint Receipt\n\t\t\t\t")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-primary",
        attrs: {
          href: "https://www.perrla.com/#/word-setup",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("span", { staticClass: "fw-bold" }, [_vm._v("Use Microsoft Word")]),
        _c("br"),
        _vm._v("\n\t\t\t\t\t- PERRLA's Microsoft Word Add-in\n\t\t\t\t"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Remember: every PERRLA account includes "),
      _c("span", { staticClass: "fst-italic fw-bold" }, [
        _vm._v("both PERRLA Online & PERRLA for Word"),
      ]),
      _vm._v(
        " - so you can use either at any time. For now, choose the one you'd like to try first."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/articles/360034402514-PERRLA-Online-vs-PERRLA-for-Word-Add-in-",
            rel: "noopener noreferrer",
            target: "_blank",
          },
        },
        [
          _vm._v(
            "\n\t\t\t\t\tNeed help deciding? Check out this article to see how PERRLA best fits your writing style.\n\t\t\t\t"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [
        _c("div", { staticClass: "divider my-0 w-100" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [
        _c("div", { staticClass: "divider my-0 w-100" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [
        _c("div", { staticClass: "divider my-0 w-100" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }