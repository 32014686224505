var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isShowRenewalText
      ? _c("h3", { key: "h3IsShowRenewalText", staticClass: "mt-0" }, [
          _vm._v("\n\t\tSubscription Preview \n\t\t"),
          _c("div", { staticClass: "help-text" }, [
            _vm._v(
              "\n\t\t\tThese changes will be applied to your next subscription renewal on " +
                _vm._s(_vm.calcExpirationDateOutput) +
                ". \n\t\t"
            ),
          ]),
        ])
      : _c("h3", { key: "h3IsShowRenewalTextElse", staticClass: "mt-0" }, [
          _vm._v("\n\t\tTransaction Preview\n\t"),
        ]),
    _vm._v(" "),
    _vm.isPrioritySupportOnly
      ? _c("div", { key: "divSummaryPSOnly" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("h4", { staticClass: "text-body text-end" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.calcProRatedPrioritySupportPrice) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "help-text ms-3" }, [
                _c("strong", [
                  _vm._v(
                    "Priority Support has been pro-rated for your current subscription."
                  ),
                ]),
                _vm._v(
                  " If you renew your subscription, Priority Support will be included at the standard rate " +
                    _vm._s(_vm.calcRateDisplay) +
                    ".\n\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          { key: "divSummaryPSElse" },
          [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("h4", { staticClass: "text-body text-end" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.calcSubscriptionPriceOutput) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm.$store.state.cart.prioritySupport
              ? _c("div", { staticClass: "row" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "text-body text-end" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.calcPrioritySupportPriceOutput) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowPromoCode ? _c("promo-code") : _vm._e(),
          ],
          1
        ),
    _vm._v(" "),
    _c("div", { staticClass: "divider w-100 my-4" }),
    _vm._v(" "),
    _c("table", { staticClass: "table table-borderless table-cart-totals" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { staticClass: "fw-light" }, [
            _vm._v("\n\t\t\t\t\tSubtotal\n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "fw-light text-end" }, [
            _vm._v(
              "\n\t\t\t\t\t" + _vm._s(_vm.calcSubTotalDisplay) + "\n\t\t\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "fw-light" }, [
            _vm._v("\n\t\t\t\t\tTax\n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "fw-light text-end" }, [
            _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.calcTaxDisplay) + "\n\t\t\t\t"),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "divider w-100 my-4" }),
    _vm._v(" "),
    _c("table", { staticClass: "table table-borderless table-cart-totals" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v("\n\t\t\t\t\tTotal\n\t\t\t\t")]),
          _vm._v(" "),
          _c("td", { staticClass: "text-end" }, [
            _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.calcTotalOutput) + "\n\t\t\t\t"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "text-body" }, [
        _vm._v("\n\t\t\t\t\tPriority Support\n\t\t\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "text-body" }, [
        _vm._v("\n\t\t\t\t\tPERRLA Subscription\n\t\t\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-text ms-3" }, [
      _vm._v("\n\t\t\tYour subscription includes:"),
      _c("br"),
      _vm._v(" \n\t\t\t- PERRLA for Word - our Word Add-in"),
      _c("br"),
      _vm._v(" \n\t\t\t- PERRLA Online - our web-based editor"),
      _c("br"),
      _vm._v(" \n\t\t\t- PERRLA Google Chrome Extension"),
      _c("br"),
      _vm._v(" \n\t\t\t- Legendary US-Based Customer Support\n\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "text-body" }, [
        _vm._v("\n\t\t\t\t\tPriority Support\n\t\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }