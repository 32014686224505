<template>
	<div class="container-fluid max-height overflow-x-hidden">
		<div class="row mx-0 max-height">
			<div class="col app-wrp">
				<transition name="loader-fade">
					<loader-component loader-id="App" />
				</transition>

				<po-sidebar v-if="$store.state.app.layoutEnum === $enums.Layout.PO && $store.state.app.isSidebarShown" />
				
				<cart-header v-if="$store.state.app.layoutEnum === $enums.Layout.CART" />
				<po-header v-else-if="$store.state.app.layoutEnum === $enums.Layout.PO" />
				<pcom-header v-else-if="$store.state.app.layoutEnum === $enums.Layout.PCOM" />
				
				<section
					:class="['row', {
						'page-outer-cart': $store.state.app.layoutEnum === $enums.Layout.CART,
						'page-outer-pcom': $store.state.app.layoutEnum === $enums.Layout.PCOM || $store.state.app.layoutEnum === $enums.Layout.SIMPLE,
						'page-outer': $store.state.app.layoutEnum === $enums.Layout.PO,
						'page-outer-sm': $store.state.app.layoutEnum === $enums.Layout.PO && $root.isNavSmall
					}]">
					<section class="col page-inner-no-footer">
						<transition name="route-fade" mode="out-in" appear>
							<router-view />
						</transition>
					</section>	
				</section>

				<pcom-footer v-if="$store.state.app.layoutEnum === $enums.Layout.PCOM" />

			</div><!--col-12-->
		</div><!-- row --> 

		<toaster />
		
		<modal-class-create />
		<modal-reminder-create />
		<modal-test-server-alert />

	</div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import _has from 'lodash/has';
import _throttle from 'lodash/throttle';
import ApiS_LogBrowser from '@/services/app/api/logBrowser';
import AS_InitTooltips from '@/services/app/initTooltips';
import CartHeader from '@/layouts/cart/Header';
import config from '@/config'
import LoaderComponent from '@/components/Loader';
import ModalClassCreate from '@/components/modals/ClassCreate';
import ModalReminderCreate from '@/components/modals/ReminderCreate';
import ModalTestServerAlert from '@/components/modals/TestServerAlert';
import PcomFooter from '@/layouts/pcom/Footer';
import PcomHeader from '@/layouts/pcom/Header';
import PoHeader from '@/layouts/po/Header';
import PoSidebar from '@/layouts/po/Sidebar';
import Toaster from '@/components/Toaster';

const reloadPageTimeoutHours = 36; // 24

export default {
	name: 'App',
	data(){
		return{
			exitHandler: null
		}
	},
	methods:{
		appKickoff(){
			// console.log('appKickoff');

			AS_InitTooltips();

			ApiS_LogBrowser();
		
			// Generic Error Handler (outside of Vue like in ckEditor)
			window.onerror = (message, source, line, column, error) => {
				if(config.useErrorLogger){
					// console.log('Send non vue error to rollbar');
					this.$rollbar.error(error);
				} else {
					console.group('Non Vue Error');
					console.log(message);
					console.log(source);
					console.log(error);
					console.groupEnd();
				}

				if(window.newrelic){
					// console.log('Send non vue error to new relic')
					newrelic.noticeError(error);
				}
			};

			// console.log('this.$store.state.hasSeenTestServerAlert');
			// console.log(this.$store.state.hasSeenTestServerAlert);
			
			// check if this is the test server
			if(config.isTest){
				// Test Server
				if(!this.$store.state.hasSeenTestServerAlert){
					this.$store.commit('modals/OPEN', 'TestServerAlert');
				}
			}//e:config.isTest

			if(!config.isDev){
				this.$gtag.linker({
					'domains': [
						'perrla.com',
						'perrlacomplete.com',
						'store.perrla.com',
						'word.perrla.com'
					],
					'accept_incoming': true,
					'decorate_forms': true
				});
			}

			window.addEventListener('resize', _throttle(this.resizeWindow, 210, { 'leading': false }));
		
		},//e:appKickoff

		resizeWindow(){
			// emits to a mixin called resizer
			this.emitter.emit('resizeWindow');
		},
	},
	mounted() {
		if(this.$store.state.lastReloadDate === ''){
			// last reload is empty - set it to now so it won't be empty next time
			// console.log('last reload is empty');
			this.$store.commit('LAST_RELOAD_DATE', new Date().getTime());
			this.appKickoff();
		} else {
			// last reload has a value - compare it
			// console.log('last reload has a value');
			let nowInMs = new Date().getTime();

			// Divide the milliseconds by 1000 to get the seconds.
			// Divide the seconds by 60 to get the minutes.
			// Divide the minutes by 60 to get the hours.
			let differenceInHrs = Math.round((nowInMs - this.$store.state.lastReloadDate) / 1000 / 60 / 60);

			// console.log('differenceInHrs');
			// console.log(differenceInHrs);

			if(differenceInHrs >= reloadPageTimeoutHours) {
				// over hours, reload
				this.$store.commit('LAST_RELOAD_DATE', nowInMs);
				// alert('Over Hours - The page is about to reload');
				window.location.reload();
			} else {
				// under hours, just load it
				// console.log('under hours, just load it');
				this.appKickoff();
			}
		}
	},
	components: {
		CartHeader,
		LoaderComponent,
		ModalClassCreate,
		ModalReminderCreate,
		ModalTestServerAlert,
		PcomFooter,
		PcomHeader,
		PoHeader,
		PoSidebar,
		Toaster,
	},
	// destroyed(){
	// 	window.removeEventListener('beforeunload', this.exitHandler);
	// }
}
</script>

