<!--
- Login To Account
-- comes from p.com
-- after login just go to Settings > My Profile 
-- clean this up later since it's a near duplicate of Login.vue
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0" style="background: url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff;">
			<main class="container-text container-text-login-box-wrp mt-0 pt-3">

				<div class="white-inset login-box-wrp">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div class="text-center">
							<h1>PERRLA</h1>
							<p>Write perfect papers from anywhere.</p>
							<h2>
								Log in to your account
							</h2>
						</div>

						<form>
							<div class="alert alert-danger" v-if="isLoginError">
								The email or password provided is incorrect
							</div>
							
							<form-input
								label="Email"
								form-id="formLogin"
								type="email"
								v-model="email"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
								
							<form-password
								label="Password"
								form-id="formLogin"
								v-model="password"
								:validate-enum="$enums.Validation.REQUIRED" />

							<p class="link-forgot-password">
								<router-link
									:to="{
										name: 'ForgotPassword'
									}"
									class="hover-underline">
									Forgot password?
								</router-link>
							</p>

							<div class="form-group">
								<div class="d-grid gap-2">
									<button type="submit" class="btn btn-success" @click.prevent="btnLogin">
										Log In
									</button>

									<router-link
										:to="{
											name: 'FreeTrialStart'
										}"
										class="btn btn-outline-primary btn-block">
										Start a Free Trial
									</router-link>
								</div>
							</div>
						</form>

					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>

	</div>
		
	
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_StoreSyncData from '@/services/app/storeSyncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormPassword from '@/components/form/Password';

export default {
	name: 'LoginToAccount',
	data() {
		return {
			email: (config.isDev) ? 'riley@perrla.com' : '',
			isLoginError: false,
			password: (config.isDev) ? 'perrla' : '',
		}
	},
	methods: {
		btnLogin(){
			this.isLoginError = false;

			AS_ValidateForm({
				formId: 'formLogin'
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				// 1 - get an access token - this is a real login now
				ApiS_MakeCall({
					body: 'client_id=complete&grant_type=password&username=' + encodeURIComponent(this.email) + '&password=' + encodeURIComponent(this.password),
					contentType: 'application/x-www-form-urlencoded',
					ignoreAuth: true,
					isHardUrl: true,
					method: 'POST',
					responseType: 'json',
					url: (config.isLive) ? 'https://auth.perrla.com/connect/token' : 'https://testauth.perrla.com/connect/token',
				}).then((responseData)=>{
					// console.log('auth responseData');
					// console.log(responseData);

					// 2 - store the token in vuex
					this.$store.commit('api/SET_TOKEN', responseData.access_token);

					// set a flag to hide the PO sidebar navigation - user wants to see their account page only
					this.$store.commit('app/SET_IS_SIDEBAR_SHOWN', false);

					// 3 - local development can't acccess the MVC project
					if(config.isDev){
						// 3a - set items manually and go to the dashboard
						this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
						
						// it would be really cool if i knew if the user came from perrla.com or not
						this.$router.push({
							name: "MyProfile"
						}).catch(()=>{});

					} else {
						// 3b - pass this token back to an MVC project 
						ApiS_MakeCall({
							method: 'GET',
							// ignoreAuth: true,
							outsideSpa: true,
							responseType: 'json',
							url: 'Forms/Login',
						}).then((responseData2)=>{
							// console.log('responseData2');
							// console.log(responseData2);

							this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
							
							AS_StoreSyncData(responseData2).then(()=>{
								// are there any redirects stored i should go to instead?
								if(this.$store.state.redirectToAfterLogin === ''){
									// i have to use a hard redirect here to get from Public to App
									window.location = config.appUrl + '/App/#/settings/my-profile';

								} else {
									// use the redirect
									let tempRedirectTo = this.$store.state.redirectToAfterLogin;
									this.$store.commit('SET_REDIRECT_TO_AFTER_LOGIN', '');
									window.location = config.appUrl + '/App/#/' + tempRedirectTo;
								}
							});//e:AS_StoreSyncData
						}).catch((error2) =>{
							
							this.$store.commit('loaders/REMOVE_ID', 'App');

							console.log('error2');
							console.log(error2);
						});//e:ApiS_MakeCall
					}//e::if:else:config.isDev
					
				}).catch((error)=>{
					// console.log('error');
					// console.log(error);

					this.$store.commit('loaders/REMOVE_ID', 'App');
					this.isLoginError = true;
				});//e:ApiS_MakeCall


			}).catch(()=>{
				return false;
				
			});//e:AS_ValidateForm
		},//e:btnLogin

	},
	mounted() {
		this.$store.commit('loaders/REMOVE_ID', 'App');
		
		if(this.$store.state.api.token === ''){
			// blank token - user isn't logged in yet

			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);
		
			// force remove any theme classes - this view will not have a customer theme
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');

		} else {
			if(config.isDev){
				this.$router.push({
					name: 'MyProfile'
				}).catch(()=>{});
			} else {
				// force jump this to App (user could be logged in then going to login from perrla.com (yeah it's weird))
				window.location.href = config.appUrl + '/App/#/settings/my-profile';
			}
		}
	},
	components: {
		FormInput,
		FormPassword,
	}
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/vars_perrla";
	
	.hero {
		padding-top: 115px;
		padding-bottom: 115px;

		margin-left: -$grid-half-gutter;
		margin-right: -$grid-half-gutter;

		p {
			font-size: 1.2rem;
			font-weight: 400;
			line-height: 2.2rem;
			margin: 0 auto 8px auto;
				width: 75%;
		}

		ul{
			margin: 24px auto 0 auto;
			width: 50%;

			li {
				font-size: 19.2px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 16px;
				text-align: left;
			}	
		}


		// Content Text Color
		h1, p {
			color:#fff;	
		}
		p {
			max-width:900px;
		}
		a.hero-link {
			color:#fff;
		}
		.btn {
			text-shadow: none;
		}
	
		

		// Content Text Shadow
		h1,p,a.hero-link {
			text-shadow: 0 0 0.5rem $body-color;
		}

	}



</style>