<!--
- Paper Edit > Research Notes Tab
-->

<template>
	<nav-tab-content>
		
		<div class="d-grid g-research-notes-header">

			<div class="row row-button-adds">
				<div class="col-6">
					<div class="d-grid">
						<button class="btn btn-success" @click.prevent="openOrganizeTabModuleModal('PaperTabResearchCreateReferenceStep1')" type="button">
							Create a new reference
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-success" @click.prevent="btnOpenAddReferenceFromLibraryModal">
							Add reference from library
						</button>
					</div>
				</div>
			</div>

			<div class="d-grid g-sort-and-search" v-if="filteredReferencesInPaper.length > 0">
				<div class="dropdown">
					Sort: <a href="#" class="hover-underline text-body" data-bs-toggle="dropdown" role="button" aria-expanded="false" id="dropdownMenu_RN_SortReference">
						<span class="dropdown-toggle"><span class="fw-bold">References</span></span>
					</a>

					<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu_RN_SortReference">
						<li>
							<a
								href="#" 
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortReferenceBy === 'displayValue_asc',
										'hover hover-primary': $data.sortReferenceBy !== 'displayValue_asc'
									}
								]"
								@click.prevent="$data.sortReferenceBy = 'displayValue_asc'">
								Alphabetical (A to Z)
							</a>
						</li>
						<li>
							<a
								href="#" 
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortReferenceBy === 'displayValue_desc',
										'hover hover-primary': $data.sortReferenceBy !== 'displayValue_desc'
									}
								]"
								@click.prevent="$data.sortReferenceBy = 'displayValue_desc'">
								Alphabetical (Z to A)
							</a>
						</li>
						<li>
							<a
								href="#" 
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortReferenceBy === 'createdDate_asc',
										'hover hover-primary': $data.sortReferenceBy !== 'createdDate_asc'
									}
								]"
								@click.prevent="$data.sortReferenceBy = 'createdDate_asc'">
								Created Date (newest first)
							</a>
						</li>

						<li>
							<a
								href="#" 
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortReferenceBy === 'createdDate_desc',
										'hover hover-primary': $data.sortReferenceBy !== 'createdDate_desc'
									}
								]"
								@click.prevent="$data.sortReferenceBy = 'createdDate_desc'">
								Created Date (oldest first)
							</a>
						</li>
					</ul>

					<a href="#" class="hover-underline text-body" data-bs-toggle="dropdown" role="button" aria-expanded="false" id="dropdownMenu_RN_SortNotes">
						<span class="dropdown-toggle"><span class="fw-bold">Notes</span></span>
					</a>
					<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu_RN_SortReference">
						<li>
							<a 
								href="#" 
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortNotesBy === $enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
										'hover hover-primary': $data.sortNotesBy !== $enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST
									}
								]"
								@click.prevent="$data.sortNotesBy = $enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST">
								Created Date (newest first)
							</a>
						</li>
						<li>
							<a
								href="#"
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortNotesBy === $enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST,
										'hover hover-primary': $data.sortNotesBy !== $enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST
									}
								]"
								@click.prevent="$data.sortNotesBy = $enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST">
								Created Date (oldest first)
							</a>
						</li>
						<li>
							<a
								href="#"
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortNotesBy === $enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST,
										'hover hover-primary': $data.sortNotesBy !== $enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST
									}
								]"
								@click.prevent="$data.sortNotesBy = $enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST">
								Citation (first to last)
							</a>
						</li>
						<li>
							<a
								href="#"
								:class="[
									'dropdown-item', {
										'dropdown-item-selected': $data.sortNotesBy === $enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST,
										'hover hover-primary': $data.sortNotesBy !== $enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST
									}
								]"
								@click.prevent="$data.sortNotesBy = $enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST">
								Citation (last to first)
							</a>
						</li>
					</ul>
				</div>

				<div class="d-flex">
					<div class="w-100">
						<input
							class="form-control"
							placeholder="Search research notes"
							type="text"
							v-model="searchTerm" />
					</div>
					<div class="flex-shrink-1" v-show="searchTerm && searchTerm.length > 0">
						<button class="btn btn-link hover-underline" @click.prevent="$data.searchTerm = '';" type="button">
							Clear
						</button>
					</div>
				</div> 
			</div>

		</div><!--g-research-notes-header-->

		<div v-if="filteredReferencesInPaper.length > 0" key="divResearchNotesReferenceInPaper">
			<reference-row
				v-for="referenceObject in filteredReferencesInPaper"
				:key="referenceObject.referenceUniqueID"
				:reference-unique-id="referenceObject.referenceUniqueID.toUpperCase()"
				:reference-object="referenceObject"
				:search-term="searchTerm" 
				:sort-by-enum="sortNotesBy"
				@set-sort-by-date-newest-first="setSortByDateNewestFirst" />
		</div>
		<div v-else class="text-empty mt-2" key="divReserachNotesNoReferencesInPaper">
			<p>Create a new reference or add an existing one to your paper.</p>
		</div>
		

		<route-modal />

		<modal-add-reference-from-library />
		<modal-research-note-citation-add />
		<modal-research-note-citation-edit />

	</nav-tab-content>
</template>

<script>
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import ModalAddReferenceFromLibrary from './TabResearch/modals/AddFromLibrary';
import ModalResearchNoteCitationAdd from '@/modules/ResearchNotes/modals/CitationAdd';
import ModalResearchNoteCitationEdit from '@/modules/ResearchNotes/modals/CitationEdit';
import NavTabContent from '@/components/NavTabContent';
import ReferenceRow from './TabResearch/ReferenceRow';
import RouteModal from '@/components/modals/RouteModal';

export default {
	name: 'TabOrganize',
	data() {
		return {
			filteredGridData: [],
			searchTerm: '',
			sortNotesBy: config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
			sortReferenceBy: 'displayValue_asc',
		}
	},
	computed:{
		filteredReferencesInPaper(){
			let searchedArray = [];

			this.$store.state.paperEdit.referencesInPaper.forEach((ref) =>{
				// console.log(ref);
				// skip references that have been deleted from the library
				if(ref.isDeleted){
					return false;
				}
				searchedArray.push(ref);
			});//e:forEach

			switch(this.sortReferenceBy){
				case 'createdDate_asc':
					searchedArray = _orderBy(_uniqBy(searchedArray, 'referenceUniqueID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['asc', 'asc']);
					break;
				case 'createdDate_desc':
					searchedArray = _orderBy(_uniqBy(searchedArray, 'referenceUniqueID'), ['createdDate', (gridData) => gridData.displayValue.toLowerCase()], ['desc', 'asc']);
					break;

				case 'displayValue_desc':
					searchedArray = _orderBy(_uniqBy(searchedArray, 'referenceUniqueID'), [(gridData) => gridData.displayValue.toLowerCase()], ['desc']);
					break;
				default: //e:displayValue_asc
					searchedArray = _orderBy(_uniqBy(searchedArray, 'referenceUniqueID'), [(gridData) => gridData.displayValue.toLowerCase()], ['asc']);
					break;
			}//e:switch
			
			// console.log('searchedArray');
			// console.log(searchedArray);

			return searchedArray;
		},
	},
	methods:{
		btnOpenAddReferenceFromLibraryModal(){
			this.$store.commit('modals/OPEN', 'ResearchNotesAddReferenceFromLibrary')
		},//e:btnOpenAddReferenceFromLibraryModal

		openOrganizeTabModuleModal(modalRoute){
			this.emitter.emit('openRouteModal', {
				modalRoute: modalRoute,
				modalParams: {
					openReferenceTab: 'common'
				},
			});
		},
		
		setSortByDateNewestFirst(){
			this.sortNotesBy = config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST;
		},
	},

	mounted(){
		// refreshing page off the Editor Tab > force user back to it
		if(!this.$store.state.paperEdit.ck.isEditorLoadedOnce){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
		}
	},
	components: {
		ModalAddReferenceFromLibrary,
		ModalResearchNoteCitationAdd,
		ModalResearchNoteCitationEdit,
		NavTabContent,
		ReferenceRow,
		RouteModal,
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";

	.g-research-notes-header {
		// align-items: center;
		column-gap: 1.5rem;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		
		.row-button-adds{
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			grid-row-start: 2;
		}

		@include media-breakpoint-up(lg) { 
			grid-template-columns: 50% auto;

			.row-button-adds {
				grid-row-start: 1;
			}
		}
		
		.g-sort-and-search{
			align-items: center;
			column-gap: 0.75rem;
			grid-template-columns: auto;
			grid-template-rows: auto auto;

			@include media-breakpoint-up(md) { 
				grid-template-columns: max-content 33%;
				grid-template-rows: auto;	
				justify-content: end;
			}

			

			button {
				margin: 0;
			}

			p {
				font-size: 1rem;
				text-align: right;
			}
		}//.g-sort-and-search

	}//.g-research-notes-header

</style>