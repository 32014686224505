var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Abstract" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c("p", [
        _vm._v(
          "The abstract should give a succinct description of your paper in under 250 words."
        ),
      ]),
      _vm._v(" "),
      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
        _vm.$enums.Format.MLA8 ||
      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
        _vm.$enums.Format.MLA9
        ? _c("div", { key: "divMla" }, [
            _c("p", [
              _vm._v(
                "PERRLA will create a place to type your abstract following MLA styles."
              ),
            ]),
          ])
        : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.APA7
        ? _c("div", { key: "divApa7" }, [
            _c("p", [_vm._v("They are often followed by a keywords section.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "PERRLA will create a place to type your abstract and a place to list keywords following the latest APA 7th Edition standards."
              ),
            ]),
          ])
        : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.APA6
        ? _c("div", { key: "divApa6" }, [
            _c("p", [_vm._v("They are often followed by a keywords section.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "PERRLA will create a place to type your abstract and a place to list keywords following the latest APA 6th Edition standards."
              ),
            ]),
          ])
        : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.Turabian9
        ? _c("div", { key: "divTurabian9" }, [
            _c("p", [_vm._v("They are often followed by a keywords section.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "PERRLA will create a place to type your abstract following the latest Turabian 9th Edition standards."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.IncludeAbstract,
                expression: "!IncludeAbstract",
              },
            ],
            staticClass: "btn btn-success",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addAbstract.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tAdd an Abstract\n\t\t\t")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.IncludeAbstract,
                expression: "IncludeAbstract",
              },
            ],
            staticClass: "btn btn-outline-danger",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.removeAbstract.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tRemove Abstract\n\t\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-empty mt-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Abstracts are a brief summary of your paper's content that lets the reader know what you are going to discuss."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "It should always be a single paragraph that is 150 – 250 words long."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Good abstracts should be brief, clear, and non-biased (a report on the paper, not an opinion about it)."
          ),
        ]),
        _vm._v(" "),
        _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
        _vm.$enums.Format.APA7
          ? _c("p", [
              _vm._v(
                "\n\t\t\t\tYou can add keywords to help your paper be more easily discovered by other researchers or students.\n\t\t\t"
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Writing tips for abstracts")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("You can also find more information about Abstracts in "),
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/categories/360001366714-PERRLA-Add-in-BETA-",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v("PERRLA's Help Center")]
      ),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }