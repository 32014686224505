<!--
- Reminders > List

-->

<template>
	<div>
		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				Reminders
			</h1>

			<button class="btn btn-success float-start me-3" type="button" @click.prevent="$store.commit('modals/OPEN', 'ReminderCreate')">
				<span class="svg bi-plus-lg me-1" /> New Reminder
			</button>
			
			<grid-search
				class="ms-auto col-4"
				placeholder-text="Search Reminders"
				@update-grid="calcGridData" />

		</div><!--flex-row-->


		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Title"
								colspan="2"
								sort-key="name"
								:entity-id="$enums.Entity.REMINDER" 
								:is-local-storage="true"
								:is-title="true"
								@update-grid="calcGridData" />
							
							<th class="table-spacer" />

							<th>Association</th>

							<th class="table-spacer" />

							<sortable-grid-th
								cell-text="Due Date"
								set-width="175px"
								sort-key="dueDate"
								:entity-id="$enums.Entity.REMINDER" 
								:is-local-storage="true"
								@update-grid="calcGridData" />

							<!-- <th width="30px">
								&nbsp;
							</th> -->
						</tr>
					</thead>
					<tbody>
						<!-- associatedEntityID -->
						<!-- associatedEntityTypeID -->
						<!-- <tr>
							<td>{{ filteredGridData }}</td>
						</tr> -->
						<reminder-row
							v-for="reminderData in filteredGridData"
							:reminder-associated-entity-id="reminderData.associatedEntityID"
							:reminder-associated-entity-type-id="reminderData.associatedEntityTypeID"
							:reminder-class-id="reminderData.classID"
							:reminder-due-date="reminderData.dueDate"
							:reminder-encrypted-id="reminderData.reminderEncryptedID"
							:reminder-id="reminderData.reminderID"
							:reminder-is-complete="reminderData.isComplete"
							:reminder-name="reminderData.name"
							:key="reminderData.reminderID" />
						
						<tr class="tr-padding-left-icon">
							<td colspan="6">
								<button
									class="btn btn-outline-primary border-0"
									type="button"
									@click.prevent="btnToggleShowCompleted">
									<span v-if="isShowCompleted">
										Hide Completed Items
									</span>
									<span v-else>
										Show Completed Items
									</span>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No reminders found.</p>
				</div>

			</div>
		</div> 
		
		<route-modal />

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import AS_SyncData from '@/services/app/syncData';
import GridSearch from '@/components/EntityGrid/Search';
import ReminderRow from '@/components/EntityGrid/ReminderRow';
import RouteModal from '@/components/modals/RouteModal';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ReminderList',
	data() {
		return {
			filteredGridData: [],
			isShowCompleted: false,
		}
	},
	methods: {
		btnToggleShowCompleted(){
			this.isShowCompleted = !this.isShowCompleted;
			AS_SyncData().then(()=>{
				this.calcGridData();
			});
		},

		calcGridData(){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.reminders.list);

			// loop through each paper and prep for display
			_data.forEach((reminderData) =>{

				if(
					(this.isShowCompleted && reminderData.isComplete) || 
					(!this.isShowCompleted && !reminderData.isComplete)
				){
					// console.log(reminderData);
					if(this.$store.state.gridSorts.searchTerm){
						// search by term
						if(reminderData.name.toLowerCase().includes(this.$store.state.gridSorts.searchTerm)){
							searchedArray.push(reminderData);
						}
					} else {
						// not searching
						searchedArray.push(reminderData);
					}//e:_searchTerm
				}
			});//e:forEach

			switch(this.$store.state.gridSorts.reminderListOrderBy){
				case 'dueDate':
					if(this.$store.state.gridSorts.reminderListSorts['dueDate'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'reminderID'), ['dueDate', (gridData) => gridData.name.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'reminderID'), ['dueDate', (gridData) => gridData.name.toLowerCase()], ['desc']);
					}
					break;
				case 'name':
					if(this.$store.state.gridSorts.reminderListSorts['name'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'reminderID'), [(gridData) => gridData.name.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'reminderID'), [(gridData) => gridData.name.toLowerCase()], ['desc']);
					}
					break;
			}//e:switch

			// console.log('this.filteredGridData');
			// console.log(this.filteredGridData);
			
			this.$nextTick(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		},//e:calcGridData

	},
	created() {
		this.emitter.on('reloadReminderList', ()=>{
			AS_SyncData().then(()=>{
				this.isShowCompleted = false;
				this.calcGridData();
			});
		});
	},
	mounted() {
		this.$store.commit('loaders/ADD_ID', 'App');

		// this could be a sync call or a specific GetReminder call - either way a list of reminders will come back down
		AS_SyncData({
			isStealthSync: true
		}).then(()=>{
			this.calcGridData();
		});
	},
	components: {
		GridSearch,
		ReminderRow,
		RouteModal,
		SortableGridTh,
	},
	destroyed(){
		this.emitter.off('reloadReminderList');
	},
}
</script>
