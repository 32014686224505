var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "org-size" }, [
    _c("h2", [_vm._v("Class Details")]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-1" }, [
      _c("strong", [_vm._v("Professor")]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ms-3" }, [
        _vm._v(_vm._s(_vm.classData.teacherName)),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-1" }, [
      _c("strong", [_vm._v("Email")]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "ms-3" }, [
        _vm._v(_vm._s(_vm.classData.teacherEmail)),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "d-grid" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-danger",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnArchiveClass.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\t\tArchive Class\n\t\t")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }