var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    class: [
      "paper-footnote-single cursor-pointer",
      {
        "paper-footnote-menu-open": _vm.isMenuOpen,
        "paper-footnote-single-selected": _vm.isSelected,
      },
    ],
    attrs: {
      "citation-find-by-id": _vm.footnoteData.findById,
      "footnote-index": _vm.footnoteIndex,
    },
    domProps: { innerHTML: _vm._s(_vm.footnoteContent) },
    on: {
      click: function ($event) {
        $event.preventDefault()
        return _vm.toggleSelectFootnote.apply(null, arguments)
      },
      dblclick: function ($event) {
        $event.preventDefault()
        return _vm.btnEditFootnote("citation")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }