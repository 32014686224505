<!--
- Module > Create Reference Step 2 > Body
-
-->

<template>
	<div>
		<!-- 3PLU Redirect (yellow) -->
		<div v-if="show3pluRedirectMessage" class="alert alert-warning" key="alert3pluRedirected">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				We&#39;ve opened an empty reference form since you searched for a generic term. If you&#39;d like to find a specific work, try entering the work&#39;s title, author, DOI, or URL.
			</p>
		</div>

		<!-- Interview -->
		<div v-if="referenceTypeId === 23" key="alertInterview">
			<!-- APA7 / MLA9 (red) -->
			<div v-if="referenceFormatTypeId === $enums.Format.APA7 || referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-danger" key="alertInterviewAPAMLA">
				<p>
					<strong>Interviews in APA & MLA are treated as Personal Communications in papers.</strong> We recommend creating interviews in the reference type where they were published.
				</p>
			</div><!--alertInterviewTurabian9-->

			<!-- Turabian9 (yellow) -->
			<div v-else-if="referenceFormatTypeId === $enums.Format.Turabian9" class="alert alert-warning" key="alertInterviewTurabian9">
				<span class="svg bi-exclamation-lg float-start" />
				<p class="ps-3">
					<strong>Published interviews should be created in their corresponding reference types</strong> (e.g., radio interviews would be created using the Podcast & Radio reference type).
				</p>
			</div><!--alertInterviewTurabian9-->

		</div><!--alertInterview-->
		
		
		

		<!-- Website (yellow) -->
		<div v-else-if="referenceTypeId === 3" class="alert alert-warning" key="alertWebite">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				References viewed online should use the reference type for the original work. For example, journal articles viewed online use the Journal reference type - not Webpage.
			</p>
		</div>
		
		<!-- Bills & Resolutions - Turabian 9 & Enaced (red) -->
			<div v-else-if="referenceTypeId === 10 && referenceFormatTypeId === $enums.Format.Turabian9 && $store.state.referenceRules.formData.type === 'enactedFed'" class="alert alert-danger" key="alertBills">
			<p class="ps-3">
				According to the Turabian 9th Edition, enacted bills and resolutions should be cited as Statutes.
				<a href="#" @click.prevent="btnLinkToStatues">Click here to create a Statute for an enacted bill or resolution.</a>
			</p>
		</div>

		<!-- Executive Orders (yellow) -->
		<div v-else-if="referenceTypeId === 17" class="alert alert-warning" key="alertExecutiveOrders">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				This reference type is for citing the formal publication of Executive Orders in the Code of Federal Regulations. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<!-- Online Comment & Turabian 9 (yellow) -->
		<div v-else-if="referenceFormatTypeId === $enums.Format.Turabian9 && referenceTypeId === 30" class="alert alert-warning" key="alertOnlineComment">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				In Turabian papers you must include a reference to the comment and the work being commented on. For example, when adding a reference for a comment on an article, also add a reference for the article itself.
			</p>
		</div>

		<!-- Social Media (yellow) -->
		<div v-else-if="referenceTypeId === 39" class="alert alert-warning" key="alertSocialMedia">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				Direct messages, private posts, and other publicly inaccessible content should be cited as <a href="#" @click.prevent="btnLinkToPersonalCommunications">Personal Communications</a>
			</p>
		</div>

		<!-- Turabian 9 US Federal Court Cases (yellow)-->
		<div v-else-if="referenceTypeId === 45 && referenceFormatTypeId === $enums.Format.Turabian9" class="alert alert-warning" key="alertUSFederalCourtCases">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				The Turabian style only includes information about the appealed case at the Circuit Court level
			</p>
		</div>


		<!-- Common Fields (blue) -->
		<div v-else class="alert alert-info" key="alertWarning">
			<span class="svg bi-check-circle-fill float-start" />
			<p class="ps-3">
				Commonly used fields are outlined in blue.
			</p>
		</div>
	
		<reference-types :reference-type-id="referenceTypeId" />

		<reference-output />

		<copy-json-data :data-to-display="JSON.stringify($store.state.referenceRules.rulesObjectData)" />
		
		<div class="mt-2 row justify-content-between">
			<div class="col" v-if="$router.currentRoute.meta.module === $enums.Module.REFERENCE_LIST" key="colReferenceList">

				<div class="d-grid">
					<router-link :to="{
							name: 'ReferenceListCreateReferenceStep1',
							params:{
								openReferenceTab: $route.params.openReferenceTab
							}
						}"
						class="btn btn-outline-primary">
						Back
					</router-link>
				</div>
			</div>
			<div class="col" v-else-if="$router.currentRoute.meta.module === $enums.Module.PAPER_EDIT" key="colPaperEdit">
				<div class="d-grid" v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR" key="divBackEditor">
					<router-link
						:to="{
							name: 'EditorCreateReferenceStep1',
							params:{
								openReferenceTab: $route.params.openReferenceTab,
							}
						}"
						class="btn btn-outline-primary">
						Back
					</router-link>
				</div>
				<div class="d-grid" v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE" key="divBackOutline">
					<router-link
						:to="{
							name: 'OutlineCreateReferenceStep1',
							params:{
								openReferenceTab: $route.params.openReferenceTab,
							}
						}"
						class="btn btn-outline-primary">
						Back
					</router-link>
				</div>
				<div class="d-grid" v-else-if="$router.currentRoute.meta.tab === $enums.Tab.RESEARCH" key="divBackResearch">
					<router-link
						:to="{
							name: 'PaperTabResearchCreateReferenceStep1',
							params:{
								openReferenceTab: $route.params.openReferenceTab,
							}
						}"
						class="btn btn-outline-primary">
						Back
					</router-link>
				</div>
			</div>
			<div class="col text-end">
				<div class="d-grid">
					<button type="button" class="btn btn-success" @click.prevent="generateReference" :disabled="!$store.state.referenceRules.isCreateNextEnabled">
						Next
					</button>
				</div>
			</div>
		</div><!--row-->
	</div>
</template>

<script>
import _has from 'lodash/has';
import CopyJsonData from '@/components/CopyJsonData'
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import ReferenceOutput from '@/components/ReferenceOutput';
import ReferenceTypes from '@/components/reference-rules-genesis/Types';

export default {
	name: 'CreateReferenceStep2Body',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
		}
	},
	computed:{
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
		referenceTypeId(){
			// commit from Step1 Row when selecting type
			return this.$store.state.referenceRules.referenceTypeId;
		},
		show3pluRedirectMessage(){
			return this.$store.state.show3pluRedirectMessage;
		},
	},
	methods: {
		btnLinkToPersonalCommunications(){
			if(this.$router.currentRoute.meta.module === this.$enums.Module.REFERENCE_LIST){
				this.$router.push({
					name: 'ReferenceListCreateReferenceStep2',
					params: {
						openReferenceTab: this.$route.params.openReferenceTab,
						referenceTypeSlug: 'personal-communication'
					},
				}).catch(()=>{});
			} else if(this.$router.currentRoute.meta.module === this.$enums.Module.PAPER_EDIT){
				if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
					this.$router.push({
						name:'EditorCreateReferenceStep2',
						params:{
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'personal-communication'
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
					this.$router.push({
						name: 'OutlineCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'personal-communication'
						},
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.RESEARCH){
					this.$router.push({
						name: 'PaperTabResearchCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'personal-communication'
						},
					}).catch(()=>{});
				}
			}//e:module
		},

		btnLinkToStatues(){
			if(this.$router.currentRoute.meta.module === this.$enums.Module.REFERENCE_LIST){
				this.$router.push({
					name: 'ReferenceListCreateReferenceStep2',
					params: {
						openReferenceTab: this.$route.params.openReferenceTab,
						referenceTypeSlug: 'statutes'
					},
				}).catch(()=>{});
			} else if(this.$router.currentRoute.meta.module === this.$enums.Module.PAPER_EDIT){
				if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
					this.$router.push({
						name:'EditorCreateReferenceStep2',
						params:{
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'statutes'
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
					this.$router.push({
						name: 'OutlineCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'statutes'
						},
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.RESEARCH){
					this.$router.push({
						name: 'PaperTabResearchCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: 'statutes'
						},
					}).catch(()=>{});
				}
			}//e:module
		},
		generateReference(){
			// takes what is already in the vuex store, temp form Data and makes it actual reference rules
			this.$store.dispatch('referenceRules/generateReference');
		},//e:generateReference
	},
	mounted() {
		// this.$store.commit('forms/MARK_CLEAN');
	},
	components: {
		CopyJsonData,
		ReferenceOutput,
		ReferenceTypes,
	},
	destroyed(){
		this.$store.commit('SET_SHOW_3PLU_REDIRECT_MESSAGE', false);
	},
}
</script>
