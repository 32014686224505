
import enums from '../src/config/enums';

const urlDev = 'http://localhost:5003';
const urlTest = 'https://testonline.perrla.com/Public';
const urlLive = 'https://www.perrlacomplete.com/Public';

// env detection (only one should be true)
let isDev = false;
let isTest = false;
let isLive = false;

let appUrl = window.location.origin;

if(MODE === 'development'){
	// DEV
	isDev = true;

} else {
	// TEST and LIVE are lumped together for now
	if(appUrl.includes('testonline')){
		// Test
		isTest = true;
	} else {
		// Live ?
		isLive = true;
	}
	
}

// console.log('isDev');
// console.log(isDev);
// console.log('isTest');
// console.log(isTest);

// store todays' date so i won't have to calculate it every time
let todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);	// kill the time - i only want the date part of the date object

export default {
	appUrl: appUrl,
	enums: enums,
	emptyText: '---',
	forceResyncBeforeDate: '2024-11-12T10:15:00',	// set a date string here, if lastSyncDate comes before this date, a full force Sync will happen
	isDev: isDev,
	isLive: isLive,
	isTest: isTest,
	metaDataKeys: [
		'CourseName','CustomerID','FileName', 'FontFamily', 'IsComplete', 'Description',
		'StartDate', 'DueDate',  'ClassID', 'IncludeAbstract',
		'IncludeAuthorNote', 'IncludeTableOfContents', 'Institution',
		'PaperFormatID', 'PaperFormatVersionID', 'PaperID','PaperType',
		'DocumentType', 'PaperUniqueID', 'RequiredLength', 'PublishedDate',
		'RunningHead','StudentName', 'Teacher','Title', 'UseNewCitationMode'
	],
	platformId: enums.Platform.ONLINE,
	saveDocumentTimeout: 60000, // ms until a Paper Save should wait until throwing a generic error or trying again
	startContent:{
		outline: '<ol><li>Start your outline here...</li></ol>'
	},
	today: todayDate,
	urlDev,
	urlLive,
	urlTest,
	useErrorLogger: (isTest || isLive) ? true : false, // use Rollbar 
	usePendo: (isLive) ? true : false, // use Pendo
	useWProofreader: (isLive || isTest) ? true : false, // only use WProofreader
}
