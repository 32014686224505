/*
- Clean Reference Value
-- takes a reference value, removes any extra character, converts it to lower case (js sorting), and returns the new value
-- used in places where a Sort Order is needed for the reference library
-- 2023.12.12
*/

export default (referenceValue) => {
	if(referenceValue){
		let defaultName = referenceValue.replace(/(<([^>]+)>)/gi, "").toLowerCase();
		return defaultName;	
	}

	return '';
}
