var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "float-end",
      attrs: {
        href: "#",
        "data-bs-toggle": "tooltip",
        "data-bs-title": "Close Tool Drawer",
        "data-bs-placement": "left",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.closeDrawer.apply(null, arguments)
        },
      },
    },
    [_c("span", { staticClass: "svg bi-chevron-right" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }