import ClassEdit from '@/views/ClassEdit';
import ClassList from '@/views/ClassList';

export default [
	{
		name: 'ClassEdit',
		component: ClassEdit,
		path: '/class/:encryptedId',
		props: true,
		
	},
	{
		name: 'ClassList',
		component: ClassList,
		path: '/classes',
	},
]