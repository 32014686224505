<!--
- Paper Drawer > Image
-->
<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Insert Figure" />
		</div><!--drawer-header-->
		
		<div class="drawer-body">
			<div v-if="showAppendixInsert" key="divAppendixInsert">
				<div v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7" key="divApa7">
					<h2>Adding a correct Figure Number in an appendix</h2>
					<p>Appendices require unique Figure Numbers in the APA format depending on the content presented in the appendix.</p>
					<p><strong>If the figure is the only element in the appendix, no figure number should be shown.</strong></p>
					<p>The title of the Appendix takes the place of the figure number.&nbsp;<em>Highlight the figure number text and delete it.</em></p>
					<p>Any appendix callouts in your paper should refer to the appendix label directly (e.g., &quot;...as seen in the Appendix.&quot;).</p>
					<p><strong>If the appendix contains other elements, the figure should be numbered according to the Appendix label.</strong></p>
					<p>Figures in a single appendix should be numbered: Figure A1, Figure A2, etc.</p>
					<p>Figures in Appendix B or greater should be numbered: Figure B1, Figure C1, etc.</p>
					<p>Any appendix callouts in the body of your paper should refer to the table or figure number directly (e.g., &quot;According to Figure A1...&quot;).</p>
				</div>

				<div class="d-grid">
					<button type="button" class="btn btn-success" @click.prevent="showAppendixInsert = false">
						Insert a new figure
					</button>
				</div>

				<div class="text-empty mt-4">
					<p>
						<a href="https://perrla.zendesk.com/hc/en-us/articles/1260804431829-Figure-Table-Titles-in-Appendices-APA-7-" target="_blank" rel="noopener noreferrer">
							For a complete description of figures in appendices, click here.
						</a>
					</p>
				</div>

			</div><!--divAppendixInsert-->
			
			<div v-else key="divNoAppendixInsert">

				<p>Figures help illustration ideas in your paper. They can be photographs, charts, or other images. </p>
				<p>Place your cursor where you want us to insert the figure. Then select the Figure file and add a caption below. PERRLA will create the formatting and manage the figure number for you.</p>

				<form>
					<div class="form-group">
						<form-input
							label="Figure title"
							:help-text="calcHelpText"
							v-model="title" />

						<label>Select the figure image</label>
						<div class="help-text mb-1">
							Choose the location for the figure's image
						</div>
						<div class="row">
							<div class="col ms-2">
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="imgOnComputer"
										name="imgOnComputer" 
										:value="0"
										v-model="selectTypeId" /> 
									<label class="form-check-label" for="imgOnComputer">Image on my computer</label>
								</div><!--form-check-->

								<slide-up-down :active="selectTypeId === 0">
									<div class="ms-3">
										<input
											type="file"
											aria-label="Select an Image on my computer"
											:class="['form-control-file mb-1 p-1', {'file-select-is-invalid': isFileError}]"
											ref="formFileSelect"
											@change="fileSelectChange($event.target.files);" />

										<div class="invalid-feedback d-block" v-if="isFileError" key="divImageSelectError">
											Please select a .jpg, or .png file.
										</div>
										<div class="help-text" v-else key="divImageSelectHelp">
											You can insert .jpg, and .png files as figures.
										</div>
									</div>
								</slide-up-down>

								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="imgFromUrl"
										name="imgFromUrl" 
										:value="1"
										v-model="selectTypeId" /> 
									<label class="form-check-label" for="imgFromUrl">Image from a URL</label>
								</div><!--form-check-->
								<slide-up-down :active="selectTypeId === 1">
									<div class="ms-3">
										<input
											autocomplete="off"
											class="form-control"
											placeholder="Enter the image URL"
											ref="inputEnterImageUrl"
											type="text" v-model="imageUrl" v-debounce:300ms="inputImageUrl" />
										
										<!-- <div class="alert alert-info">
											https://previews.123rf.com/images/memoangeles/memoangeles1508/memoangeles150800018/44197444-meditating-cartoon-ninja-vector-clip-art-illustration-with-simple-gradients-ninja-and-mask-s-clothes.jpg
										</div> -->
									</div>
								</slide-up-down>

							</div><!--col-->
						</div><!--row-->
					</div><!--form-group-->
					
					<div v-if="$store.state.paperEdit.config.allowFigureNotes">
						<div class="form-group mb-0">
							<label>
								Figure notes <span class="font-weight-normal">(optional)</span>
							</label>
							<div class="help-text">
								Figure notes are used to clarify information in your figure.
							</div>
						</div>
						<div class="form-group form-check ms-3 mb-3 mt-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="chkIncludeNotes"
								v-model="includeNotes" />
							<label for="chkIncludeNotes" class="form-check-label">
								Include notes
							</label>
						</div>
					</div>
					
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="insertFigurePrep" :disabled="isFormSubmitDisabled || !canAddFigure">
							Insert Figure
						</button>
					</div>
				</form>

				<p v-if="isPaperAbstract" class="text-danger mt-2">
					Note: Inserting a figure in your abstract is not proper formatting for this type of paper.
				</p>
				
				<p class="text-empty mt-4">
					Once your figure has been inserted, adjust the image or edit the text directly in your paper.
				</p>
			</div><!--divNoAppendixInsert-->
		</div><!--drawer-body-->
	</div>
</template>

<script>
import config from '@/config';
import FormInput from '@/components/form/Input';
import InsertRawHtml from '@/helpers/insert-raw-html';
import PS_SaveDocument from '@/services/paper/saveDocument';
import SlideUpDown from '@/components/VueSlideUpDown.vue';
import Swal from 'sweetalert2';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

const maxImageWidth = 650;	// max width in px

export default {
	name: 'PaperDrawerInsertFigure',
	mixins: [],
	data() {
		return {
			imageBase64Encode: '',
			imageUrl: '',
			includeNotes: false,
			isFileError: false,
			isFormSubmitDisabled: true,
			selectTypeId: 0,
			showAppendixInsert: false,
			title:'',
		}
	},
	watch:{
		selectTypeId(oldValue, newValue){
			// if user is clicking to Enter an image from a url then focus that input after it renders
			if(newValue === 0){
				this.$nextTick(()=>{
					if(this.$refs.inputEnterImageUrl){
						this.$refs.inputEnterImageUrl.focus();
					}
				});
			}
		}
	},
	computed:{
		canAddFigure(){
			if(this.editorLastFocused){
				if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.ABSTRACT ||
					this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX ||
					this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.BODY){
					return true;
				}
			}
			return false;
		},
		calcHelpText(){
			let _referenceFormatTypeId = 0;

			if(config.platformId === config.enums.Platform.ONLINE){
				_referenceFormatTypeId = this.$store.state.paperEdit.meta.PaperFormatVersionID;
			} else {
				_referenceFormatTypeId = this.$store.state.formatVersionID;
			}
			
			if(_referenceFormatTypeId === config.enums.Format.Turabian9){
				return `Titles should be concise and use sentence-case capitalization and usually has a terminal period.`;
			}
			return `Titles should be concise and use Title Case capitalization.`;
		},
		editorLastFocused(){
			if(CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused]){
				return CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
			}
			return null;
		},
		isPaperAbstract(){
			if(this.editorLastFocused && this.editorLastFocused.name === 'ckPaperAbstract'){
				return true;
			}
			return false;
		},
	},
	methods: {
		fileSelectChange(file){
			this.$store.commit('loaders/ADD_ID', 'App');

			// defaults
			this.isFileError = false;
			this.isFormSubmitDisabled = true;

			if(!file[0]){
				this.imageBase64Encode = '';
				return false;
			}

			// verify allowed file type
			const fileType = file[0].type;

			if(fileType.includes('jpeg') || fileType.includes('jpg') || fileType.includes('png') || fileType.includes('gif') || fileType.includes('webp')) {
				// Uploadable File Type
					
				// currently using FileReader
				// option 3 foud here: https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript

				// consider using a different approach that allows file compression
				// https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser

				// a larger example that combines some options (start with this one!)
				// https://zocada.com/compress-resize-images-javascript-browser/

				let reader = new FileReader();
				reader.readAsDataURL(file[0]);
				reader.onload = (event) => {
					const img = new Image();
					img.crossOrigin = 'Anonymous';
					img.src = event.target.result;

					img.onload = () => {
						let canvas = document.createElement('canvas');
						
						// calculated the new encoded image size now
						let setWidth = img.width;
						let setHeight = img.height;

						if(img.width > maxImageWidth){
							// needs to scale down this image
							setWidth = maxImageWidth;
							setHeight = img.height * (maxImageWidth / img.width);
						}

						canvas.width = setWidth;
						canvas.height = setHeight;

						let ctx = canvas.getContext('2d');
						ctx.drawImage(img, 0, 0, setWidth, setHeight);

						let imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
					
						this.imageBase64Encode = imageDataUrl.replace(/^data:image\/(jpeg|jpg|png|webp);base64,/, "");

					};//e:img.onload

					this.isFormSubmitDisabled = false;
					this.$store.commit('loaders/REMOVE_ID', 'App');
				};
				reader.onerror = () => {
					this.imageBase64Encode = '';
					this.isFormSubmitDisabled = true;
					this.$store.commit('loaders/REMOVE_ID', 'App');
				};	
			
			} else {
				// Invalid File Type Selected
				this.isFileError = true;
				this.$store.commit('loaders/REMOVE_ID', 'App');
			}
		},//e:fileSelectChange
		
		inputImageUrl(){
			// https://cdn.shopify.com/s/files/1/0856/7748/files/Depositphotos_8161410_xl-2015_480x480.jpg?v=1599851512
			
			// check for a query string, remove it
			if(this.imageUrl === ''){
				return false;
			}
			// make sure this can convert to a value url
			try {
				let stringToUrl = new URL(this.imageUrl);

				this.imageUrl = this.imageUrl.split(/[?#]/)[0];

				if(this.imageUrl !== '' && (/\.(jpe?g|png|gif|bmp|webp)$/i.test(this.imageUrl))){
					this.isFormSubmitDisabled = false;
				} else {
					this.isFormSubmitDisabled = true;
				}
				
			} catch (error) {
				console.log('Image URL Could not be found');
				
			}
			
			
		},//inputImageUrl

		// prepares the figure by checking the encoding process
		insertFigurePrep(){
			this.$store.commit('loaders/ADD_ID', 'App');

			if(this.selectTypeId == 0){
				// Image from Computer / Upload - make sure there is a base 64 encode
				if(this.imageBase64Encode == ''){
					return false;
				}
				this.insertFigure();
				
			} else {
				// Image from URL
				// https://i.imgur.com/Z4Ji7Ix.jpg - normal
				// https://i.ytimg.com/vi/koKhBAOmm-c/mqdefault.jpg - CORS error
				// https://cdn.shopify.com/s/files/1/0856/7748/files/Depositphotos_8161410_xl-2015_480x480.jpg?v=1599851512 - with query string
				let xhr = new XMLHttpRequest();

				// generic error
				xhr.onerror = () => {
					this.$store.commit('loaders/REMOVE_ID', 'App');

					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: 'Error',
						text: 'There was a problem loading the image from a url. If the problem persists, please try to download the image and select "Image on my computer".',
						icon: 'error',
						cancelButtonText: 'OK',
						showCloseButton: false,
						showCancelButton: true,
						showConfirmButton: false,
						customClass:{
							cancelButton: 'btn btn-outline-danger',
						},
					});
				}//e:onerror

				// success / load
				xhr.onload = () => {
					let reader = new FileReader();

					reader.onloadend = (event) => {
						const img = new Image();
						img.crossOrigin = 'Anonymous';
						img.src = event.target.result;
						
						img.onload = () => {
							let canvas = document.createElement('canvas');
							
							// calculated the new encoded image size now
							let setWidth = img.width;
							let setHeight = img.height;

							if(img.width > maxImageWidth){
								// needs to scale down this image
								setWidth = maxImageWidth;
								setHeight = img.height * (maxImageWidth / img.width);
							}

							canvas.width = setWidth;
							canvas.height = setHeight;

							let ctx = canvas.getContext('2d');
							ctx.drawImage(img, 0, 0, setWidth, setHeight);
														
							let imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
							this.imageBase64Encode = imageDataUrl.replace(/^data:image\/(jpeg|jpg|png|);base64,/, "");
							this.insertFigure();

						};//e:img.onload
					};
					reader.readAsDataURL(xhr.response);
				};
				
				// using a proxy for CORS - https://stackoverflow.com/questions/20920965/cross-origin-problems-getting-base64-encoding-of-an-image
				// xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + this.imageUrl);
				
				xhr.open('GET', this.imageUrl);
				xhr.responseType = 'blob';
				xhr.send();
			}//e:if:selectTypeId

		},//e:insertFigurePrep

		// the actual insert after the prep function
		insertFigure(){
			if(this.imageBase64Encode) {

				let titlePlaceholder = 'Figure X';

				// inserting into an appendix

				if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX){
					titlePlaceholder = '***See panel for numbering instructions***';
				}
				let rawHtml = '';

				if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
					rawHtml =	'<figure>' +
									'<figcaption>' +
										'<figure-caption title="Figure numbers will be updated automatically">' + titlePlaceholder + '</figure-caption>' +
										'<br/><br/>' + 
										'<figure-title>' + this.title + '</figure-title>' +
									'</figcaption>'+
									'<img alt="' + this.title + '" src="data:image/png;base64,' + this.imageBase64Encode + '"/>';
					if(this.includeNotes){
						rawHtml +=	'<p class="figure-notes"><em>Note.</em> Type your note content here.</p>';
					}
					rawHtml +=	'</figure><br/>';

				} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
					rawHtml =	'<figure>' +
									'<img alt="' + this.title + '" src="data:image/png;base64,' + this.imageBase64Encode + '"/>' + 
									'<figcaption>' +
										'<figure-caption title="Figure numbers will be updated automatically">' + titlePlaceholder + '</figure-caption>' +
										'<figure-title>' + this.title + '. Type the source for the figure here.</figure-title>' +
									'</figcaption>'+
								'</figure><br/>';

				} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					rawHtml =	'<figure>' +
									'<img alt="' + this.title + '" src="data:image/png;base64,' + this.imageBase64Encode + '"/>' + 
									'<figcaption>' +
										'<figure-caption title="Figure numbers will be updated automatically">' + titlePlaceholder + '</figure-caption>' +
										'<span class="figure-title">' + this.title + '</span>' +
									'</figcaption>';
					rawHtml +=	'</figure><br/>';
				}
				
				// if it's blank then there was a problem with the paper format version id and nothing needs to be inserted
				if(rawHtml === ''){
					this.$store.commit('loaders/REMOVE_ID', 'App');
					return false;
				}//e:if:rawHtml

				InsertRawHtml.insertBlock(rawHtml);

				PS_SaveDocument({
					message: 'Added figure ' + this.title,
				}).then(()=>{
					if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX){
						this.showAppendixInsert = true;

					} else {
						this.emitter.emit('globalToasterOpen', {
							textContent: 'Figure inserted',
						});
					}

					// reset form
					this.imageBase64Encode = '';
					this.imageUrl = '';
					this.includeNotes = false;
					this.isFormSubmitDisabled = true;
					this.selectTypeId = 0;
					this.title = '';
					
					// maybe something weird in Safari when inserting from URL and there isn't a file select element :: https://app.raygun.io/crashreporting/mb3tr7/errors/82499334314
					if(this.$refs.formFileSelect){
						this.$refs.formFileSelect.value = null;
					}
				});
			
			} else {
				alert('Error imageBase64Encode is empty string');
			}
			
			this.$store.commit('loaders/REMOVE_ID', 'App');

		},//e:insertFigure
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorInsertFigure'
		});
	},
	components:{
		FormInput,
		SlideUpDown,
		ToolDrawerTitle
	}
}
</script>



