var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tCreate Reference List\n\t"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Many students will use Reference Lists to build a bibliography for other programs, like Powerpoint."
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      [
        _c("form-select", {
          attrs: {
            label: "Paper Format",
            "select-object": _vm.paperFormatSelectList,
          },
          model: {
            value: _vm.paperType,
            callback: function ($$v) {
              _vm.paperType = $$v
            },
            expression: "paperType",
          },
        }),
        _vm._v(" "),
        _c("form-input", {
          attrs: {
            label: "Document Title",
            placeholder: "(required)",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.REQUIRED,
          },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.emitter.emit("closeRouteModal")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.createReferenceList.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate Reference List\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Reference Lists are great when you need to build a list of references "
      ),
      _c("em", [_vm._v("without")]),
      _vm._v(" needing additional text."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }