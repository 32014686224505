<!--
- Reference List > Modal > Create Reference Step 3 Third Party
-->
<template>
	<div>
		<h2 class="nodal-title">
			{{ referenceTypeName }}
		</h2>
			
		<create-reference-step-3-third-party-body :is-drawer="false" />
	</div>
</template>

<script>
import CreateReferenceStep3ThirdPartyBody from '@/modules/CreateReferenceStep3ThirdPartyBody'

export default {
	name: 'ReferenceListCreateReferenceStep3ThirdParty',
	computed: {
		referenceTypeName(){
			return this.$store.state.referenceRules.referenceTypeName;
		},
	},
	components: {
		CreateReferenceStep3ThirdPartyBody,
	}
}
</script>
