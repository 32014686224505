var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 layout-pcom" }, [
    _c("section", { staticClass: "section-text pt-0" }, [
      _c(
        "main",
        {
          staticClass: "container-text container-text-login-box-wrp mt-0 pt-3",
        },
        [
          _c("div", { staticClass: "white-inset login-box-wrp" }, [
            _c("div", { staticClass: "inset-body" }, [
              _c("h2", { staticClass: "mb-0" }, [
                _vm._v("\n\t\t\t\t\t\tForgot Password\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                [
                  _c("form-input", {
                    attrs: {
                      label: "Email",
                      type: "email",
                      "form-id": _vm.zFormId,
                      "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnSendForgotPassword.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t\tChange my password\n\t\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "divider d-none d-md-block w-100 my-4",
              }),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tInclude your first name, last name, and the approximate date you started using PERRLA.\n\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "We'll send you an email with a link to reset your password from "
      ),
      _c("a", { attrs: { href: "mailto:support@perrla.com" } }, [
        _vm._v("support@perrla.com"),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n\t\t\t\t\t\tIf you can't remember the email address you used for PERRLA, email our Customer Support team at "
      ),
      _c("a", { attrs: { href: "mailto:support@perrla.com" } }, [
        _vm._v("support@perrla.com"),
      ]),
      _vm._v(".\n\t\t\t\t\t"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }