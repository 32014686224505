<!--
- Outline Editor > Filebar
-->

<template>
	<nav class="navbar navbar-expand  filebar" :class="[{'filebar-hover':isActive}]" v-on-clickaway="closeAllMenus">
        <ul class="navbar-nav">
            <!-- File -->
            <li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.FILE}]" @mouseenter="mouseEnterMenu(menuEnums.FILE)">
                <a href="#" :class="[{'show': openMenuEnum === menuEnums.FILE}]" @click.prevent="toggleMenu(menuEnums.FILE)">File</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.FILE}]">
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('saveOutline')">Save</a>
					<a class="dropdown-item" href="#" @click.prevent="printDocument">Print</a>
					<div class="dropdown-divider" />
					
					<router-link
						class="dropdown-item"
						:to="{ name:'PaperTabOverview' }">
						View recent saves
					</router-link>
                </div>
            </li>

            <!-- Edit -->
            <li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.EDIT}]" @mouseenter="mouseEnterMenu(menuEnums.EDIT)">
				<a href="#" :class="[{'show': openMenuEnum === menuEnums.EDIT}]" @click.prevent="toggleMenu(menuEnums.EDIT)">Edit</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.EDIT}]">
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('undo')">Undo</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('redo')">Redo</a>
                    
                    <div v-show="configUseWProofreader" class="dropdown-divider" />

					
					<a v-show="configUseWProofreader" :class="['dropdown-item', {'disabled': !isWproofReaderActive}]" href="#" @click.prevent="ddOpenProofreader('ckOutline')">PERRLA Spell Check</a>

					<a v-show="configUseWProofreader" class="dropdown-item" href="#" @click.prevent="ddToggleProofreader">
						<span v-show="isWproofReaderActive">Disable</span>
						<span v-show="!isWproofReaderActive">Enable</span>
						PERRLA Spellcheck
					</a>
					<!--for Grammarly-->

                </div>
            </li>

			<!-- View -->
            <li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.VIEW}]" @mouseenter="mouseEnterMenu(menuEnums.VIEW)">
				<a href="#" :class="[{'show': openMenuEnum === menuEnums.VIEW}]" @click.prevent="toggleMenu(menuEnums.VIEW)">View</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.VIEW}]">
					<router-link
						class="dropdown-item"
						:to="{ name:'OutlineReferencesCitations' }">
						Reference &amp; Citations
					</router-link>

					<router-link
						class="dropdown-item"
						:to="{ name:'OutlineResearchNotes' }">
						Research Notes
					</router-link>

                </div>
            </li>


            <!-- Format -->
            <li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.FORMAT}]" @mouseenter="mouseEnterMenu(menuEnums.FORMAT)">
				<a href="#" :class="[{'show': openMenuEnum === menuEnums.FORMAT}]" @click.prevent="toggleMenu(menuEnums.FORMAT)">Format</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.FORMAT}]">
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('bold')">Bold</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('italic')">Italic</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('underline')">Underline</a>

                    <div class="dropdown-divider" />

                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('strike')">Strikethrough</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('superscript')">Superscript</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('subscript')">Subscript</a>

                    <div class="dropdown-divider" />
					
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('justifyleft')">Align Left</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('justifycenter')">Align Center</a>
                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('justifyright')">Align Right</a>

                    <div class="dropdown-divider" />

                    <a class="dropdown-item" href="#" @click.prevent="ddCkExec('removeFormat')">Remove Format</a>
                </div>
            </li>

			<!-- Insert -->
            <li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.INSERT}]" @mouseenter="mouseEnterMenu(menuEnums.INSERT)">
				<a href="#" :class="[{'show': openMenuEnum === menuEnums.INSERT}]" @click.prevent="toggleMenu(menuEnums.INSERT)">Insert</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.INSERT}]">

                    <a class="dropdown-item" href="#" @click.prevent="ddOpenSpecialCharactersModal">Special Characters</a>
                </div>
            </li>

			<!-- References -->
			<li :class="['nav-item', {'dropdown': !isReadOnly, 'dropdown-hover': openMenuEnum === menuEnums.REFERENCES}]" @mouseenter="mouseEnterMenu(menuEnums.REFERENCES)">
				<a href="#" :class="[{'show': openMenuEnum === menuEnums.REFERENCES}]" @click.prevent="toggleMenu(menuEnums.REFERENCES)">References</a>
                <div :class="['dropdown-menu',{'show':openMenuEnum === menuEnums.REFERENCES}]">

					<router-link
						class="dropdown-item"
						:to="{name:'OutlineCreateReferenceStep1', params: {openReferenceTab: 'common'}}">
						Create New Reference
					</router-link>

					<router-link
						class="dropdown-item"
						:to="{ name:'OutlineAddFromLibrary' }">
						Add Reference from My Library
					</router-link>

                </div>
            </li>
			
        </ul>

    </nav>
</template>

<script>
import _forEach from 'lodash/forEach';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import ErrS_GenericAlert from '@/services/app/error/generic-alert.js';
import { mixin as clickaway } from 'vue-clickaway';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import PCKS_wProofreaderDestroy from '@/services/paper/ck/wProofreaderDestroy';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_BuildHtml from '@/services/paper/buildHtml';
import PS_CopyDiscussionPostToClipboard from '@/services/paper/copyDiscussionPostToClipboard';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import PS_SaveDocument from '@/services/paper/saveDocument';
import Swal from 'sweetalert2';

export default {
	name: 'FilebarComponent',
	mixins: [clickaway],
	data() {
		return {
			isActive: false,
			isReadOnly: false,
			menuEnums:{
				CLOSED: 0,
				EDIT: 2,
				FILE: 1,
				FORMAT: 4,
				INSERT: 5,
				REFERENCES: 7,
				SECTIONS: 6,
				TUTORIALS: 8,
				VIEW: 3,
			},
			openMenuEnum: 0,	// 0 means all are closed, feed a menu enum here when clicked
		}
	},
	computed:{
		configUseWProofreader(){
			return config.useWProofreader;
		},
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
		includeAnnotations() {
			return this.$store.state.paperEdit.annotations.isInclude;
		},
		IncludeAbstract(){
			return this.$store.state.paperEdit.meta.IncludeAbstract;
		},
		isPaperFooterOn(){
			return this.$store.state.paperEdit.isPaperFooterOn;
		},
		isWproofReaderActive(){
			return this.$store.state.paperEdit.ck.isWproofReaderActive;
		},
		showDevBlocks(){
			return this.$store.state.paperEdit.ck.showDevBlocks;
		},
		showImpersonationMenu(){
			if(this.$isLive){
				return this.$store.state.customer.isImpersonating
			}
			// Dev | Test
			return true;
		},
	},
	methods: {
		btnShowErrorKeystroke(){
			this.closeAllMenus();

			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Throw error in paper editor?',
				html: '<p class="mb-0">ALT + CONTROL + ` (backtick)</p><p>to simulate an error outside of Vue</p>',
				icon: 'error',
				confirmButtonText: 'I understand',
				showCloseButton: false,
				showCancelButton: false,
				showConfirmButton: true,
				customClass:{
					confirmButton: 'btn btn-success',
				},
			});
		},//e:btnShowErrorKeystroke

		btnShowInactivityModal(){
			this.closeAllMenus();

			Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'Are you still working?',
					text: `If you're finished working with PERRLA for now, it's a good idea to close PERRLA Online in your browser. This will keep everything up to date and safe.`,
					iconHtml: '<span class="svg icons8-snooze"/>',
					cancelButtonText: 'Save & log out',
					confirmButtonText: `I'm still working`,
					showCloseButton: false,
					showCancelButton: true,
					showConfirmButton: true,
					customClass:{
						cancelButton: 'btn btn-danger',
						confirmButton: 'btn btn-outline-primary me-2',
						icon: 'custom-icon'
					},
				});

		},//e:btnShowInactivityModal
	
		closeAllMenus(){
			this.isActive = false;
			this.openMenuEnum = this.menuEnums.CLOSED;
		},//e:closeAllMenus

		copyDiscussionPostToClipboard(){
			PS_CopyDiscussionPostToClipboard().then(()=>{
				this.closeAllMenus();
			});
		},//e:copyDiscussionPostToClipboard

		ddCreateCopy(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				method: 'POST',
				params:{
					encryptedId: this.$route.params.encryptedId
				},
				url: 'v3/Paper/Duplicate',
			}).then(()=>{
				this.$router.push({
					name: 'PaperList'
				}).catch(()=>{});

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Copy created',
				});
			});//e:ApiS_MakeCall
		},//e:ddCreateCopy

		// Generic CkEditor Command
        ddCkExec(command) {
			let editor = CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
			if(editor){
				editor.execCommand(command);
			}
            this.closeAllMenus();
        },
		
		ddOpenPageCountModal(){
			PS_SaveDocument({
				message: 'Saved - Page Count',
			}).then((response)=>{
				this.$store.commit('modals/OPEN', 'PageCount');
				this.$store.commit('modals/SET_MODAL_DATA', {
					pageCount: response.pageCount,	
				});
			}).catch((error)=>{
				console.log("Error in ddSavePaperContent");
				console.log(error);
			}).then(()=>{
				this.closeAllMenus();
			});
		},//e:ddOpenPageCountModal
		
		ddToggleProofreader(){
			if(this.isWproofReaderActive){
				PCKS_wProofreaderDestroy().then(()=>{
					this.closeAllMenus();
				});
			} else {
				PCKS_wProofreaderInit({
					container: 'all',
				}).then(()=>{
					this.closeAllMenus();
				});
			}
		},//e:ddToggleProofreader

		ddOpenProofreader(instanceName){
			if(config.useWProofreader && typeof WEBSPELLCHECKER != 'undefined'){
				// get all wsc instances - should only be PaperAbstract or PaperBody at this point
				let wscInstances = WEBSPELLCHECKER.getInstances();
				// console.log(wscInstances);
				
				// loop through instance
				wscInstances.forEach((wscInstance)=>{
					// console.log(wscInstance);
					// find the right instance to open the dialog
					if(wscInstance.getContainerNode().id === 'body_' + instanceName){
						wscInstance.openDialog();
					}
				});
			} else {
				console.log('WProofreader has been disabled');
			}

			this.closeAllMenus();

		},//e:ddOpenProofreader

		ddOpenSpecialCharactersModal(){
			this.$store.commit('modals/OPEN', 'SpecialCharacters');
		},

		ddToggleHtml(){
			PS_BuildHtml().then((buildHtmlResponseObj)=>{
				this.$store.commit('modals/OPEN', 'PaperSource');
				this.$store.commit('modals/SET_MODAL_DATA', {
					documentAsHtml: buildHtmlResponseObj.returnHtml,	
				});
				this.closeAllMenus();
				
			}).catch((errorObj)=>{
				ErrS_GenericAlert({
					enum: errorObj.enum,
					src: errorObj.src
				});
			});//e:PS_BuildHtml
		},

		ddToggleShowBlocks(){
			this.$store.commit('paperEdit/ck/TOGGLE_SHOW_DEV_BLOCKS');
			
			if(this.showDevBlocks){
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					if(ckInstance.document){
						let $ckElement = ckInstance.document.getBody();
						$ckElement.addClass('cke_editable-show-blocks')
					}
				});//e:forEach
			} else {
				// Remove Class from ckEditor
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					if(ckInstance.document){
						let $ckElement = ckInstance.document.getBody();
						$ckElement.removeClass('cke_editable-show-blocks')
					}
				});//e:forEach
			}

			this.closeAllMenus();
		},
		
		ddOpenDownloadedDocument(contentType){
			let convertTypeMessage = '?';

			switch(contentType){
				case config.enums.DownloadPaperContentType.WORD:
					convertTypeMessage = 'Word';
					break;
				case config.enums.DownloadPaperContentType.PDF:
					convertTypeMessage = 'PDF';
					break;
				case config.enums.DownloadPaperContentType.WORD_SIMPLE:
					convertTypeMessage = 'Simplified Word';
					break;
			}

			PS_SaveDocument({
				message: 'Downloaded paper as ' + convertTypeMessage,
			}).then((response)=>{
				if(contentType === config.enums.DownloadPaperContentType.COMPRESSED){
					// Papercontent.txt
					window.open(response.contentUrl);
				} else {
					// PDF or Word
					PS_DownloadDocumentVersionV4({
						contentType: contentType,
						platform: 1, // just Online Papers here
					}).then(()=>{
						this.closeAllMenus();
					});
				}
			}).catch((error)=>{
				console.log("Error in ddOpenDownloadPDF");
				console.log(error);
			}).then(()=>{
				this.closeAllMenus();
			});
		},//e:ddOpenDownloadedDocument

        ddToggleFooter() {
			if (this.isPaperFooterOn) {
                // set to hide
                this.$store.commit('paperEdit/SET_IS_PAPER_FOOTER_ON', false);
            } else {
                // set to show
                this.$store.commit('paperEdit/SET_IS_PAPER_FOOTER_ON', true);
                this.$nextTick(() => {
					this.emitter.emit('footerUpdateWordCount', {
						editorName: 'ckPaperBody'
					});
                });
			}
            this.closeAllMenus();
		},

		mouseEnterMenu(menuEnum){
			if(this.isActive){
				this.openMenuEnum = menuEnum;
				this.isActive = true;
			}
		},

		printDocument(){
			OS_SaveContent().then(()=>{
				let editor = CKEDITOR.instances['ckPaperOutline'];
				CKEDITOR.plugins.preview.createPreview( editor, function( previewWindow ) {
					var nativePreviewWindow = previewWindow.$;
	
					if ( CKEDITOR.env.gecko ) {
						nativePreviewWindow.print();
					} else {
						nativePreviewWindow.document.execCommand( 'Print' );
					}
	
					nativePreviewWindow.close();
				} );
			});
		},
        
        // general bus emit without any other processing
        genericBusEmit(eventName) { 
			this.emitter.emit(eventName);
            this.closeAllMenus();
		},

		removeReferenceAnnotationsConfirm(){
			this.closeAllMenus();

			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Are you sure?',
				text: 'This will remove all your annotations and can not be undone.',
				icon: 'warning',
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Delete',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					this.$store.commit('paperEdit/annotations/IS_INCLUDE', false);
					this.$store.commit('paperEdit/annotations/CLEAR_IN_PAPER');
					
					// loop through all references in this paper
					this.$store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
						// remove the ckEditor instance of this reference's annotation
						if(CKEDITOR.instances['ck_annotation_' + referenceObject.referenceUniqueID.toUpperCase()]){
							CKEDITOR.instances['ck_annotation_' + referenceObject.referenceUniqueID.toUpperCase()].destroy(true);
						}
					});

					PS_SaveDocument({
						isMetaDataChanges: true,
						message: 'Removed Annotations from References',
					}).then(()=>{
						this.emitter.emit('globalToasterOpen',{
							textContent: 'Annotations removed',
							variant: 'danger'
						});
					});
				}
			});
		},//e:removeReferenceAnnotationsConfirm

		toggleMenu(toggleEnum){
			if(this.openMenuEnum === toggleEnum){
				// Currently Open - Close It
				this.closeAllMenus();
			} else {
				// Currently Closed - Open it
				this.openMenuEnum = toggleEnum;
				this.isActive = true;
			}
		}//e:toggleMenu
	},
	created() {
		this.emitter.on('closeAllDropdowns',()=>{
			this.closeAllMenus();
		});
    },
	destroyed(){
		this.emitter.off('closeAllDropdowns');
	},
}
</script>


<style lang="scss" scoped>
	@import "./../../../assets/styles/vars_perrla.scss";
	@include color-mode(dark) {
		nav.filebar {	
			background: $gray-800;
			border-color: $secondary-500;

			ul.navbar-nav {
				li {
					&.dropdown a:hover, &.dropdown-hover > a {
						background: $gray-700;
					}

					a {
						color: $dark-body-color;
						&.dropdown-item.disabled, &.dropdown-item:disabled{
							color: $gray-700;
						}
					}
					.dropdown-menu {
						background-color: $gray-900;
						border-color: $gray-800;
						a {
							color: $dark-body-color;
						}

						.dropdown-divider {
							border-color: $secondary-500;
						}


					}
				}
			}
		}
	}
	nav.filebar {
		background: $gray-200;
		border-color: $secondary-300;
		border-width: 1px 1px 0 1px;
		border-style: solid;
		padding-left:0;
		padding-right:0;
		margin-bottom: 0px;

		ul.navbar-nav {
			font-size: $font-size-sm;
			line-height: $line-height-base;
			margin: -2px 0 0 0;
			padding: 0;

			li {
				list-style-type: none;

				&.dropdown a:hover, &.dropdown-hover > a {
					background: $gray-400;
				}

				a {
					color: $body-color;
					text-decoration: none; 
					padding: 0.5rem 0.75rem;
					
					&.dropdown-item.disabled, &.dropdown-item:disabled{
						color: $gray-500;
					}
				}

				.dropdown-menu {
					@include border-top-radius(0);
					font-size: $font-size-sm;
					margin-top: 0.25rem;
					padding: 0.25rem 0;
					a {
						padding: 0.125rem 0.75rem;
					}
					.dropdown-divider {
						border-color: $secondary-100;
						margin: 0.25rem 0;
					}
				}

			}
		} //ul
	
	}//nav.filebar

</style>
