var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c("section", { staticClass: "section-text" }, [
      _c("main", { staticClass: "container-text mt-2" }, [
        _c("div", [
          _c("h3", [_vm._v("Subscription Expired")]),
          _vm._v(" "),
          _c("p", [_vm._v("Uh oh! It looks like your subscription expired!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "We hope you'll choose to renew. Thanksgiving will be weird without you. Okay, it might have been weird with you... we don't know... we have to write this text in advance. The point is, the best way to avoid any future awkwardness is for you to renew. Just renew and we can go back to the way things were when we were both young and in love with each other."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnGoToCart.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\t\tRenew PERRLA\n\t\t\t\t\t\t\t")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "d-grid" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: {
                        to: {
                          name: "Logout",
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\t\tLogout\n\t\t\t\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }