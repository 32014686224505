var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c("section", { staticClass: "section-text pt-0" }, [
      _c("main", { staticClass: "container-text mt-0 pt-3" }, [
        _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
          _c("div", { staticClass: "inset-body" }, [
            _c("img", {
              staticClass: "mx-auto d-block",
              attrs: {
                src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                width: "140px",
                role: "presentation",
              },
            }),
            _vm._v(" "),
            _c("div", [
              _c("h1", { staticClass: "text-center" }, [
                _vm._v("\n\t\t\t\t\t\t\tPERRLA\n\t\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              !_vm.isCustomerInFreeTrial
                ? _c("div", { key: "divNotInFreeTrial" }, [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tLooks like you already have PERRLA\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                  ])
                : _vm.isExpired
                ? _c("div", { key: "divExpired" }, [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tTime's up - this offer has expired.\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                  ])
                : _c("div", { key: "divElse" }, [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tPreparing your discount.\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tIf you are not forwarded to checkout shortly, "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.calcStoreLink,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                        [_vm._v("click here")]
                      ),
                      _vm._v(".\n\t\t\t\t\t\t\t"),
                    ]),
                  ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n\t\t\t\t\t\t\t\tThis offer was only valid during a Free Trial. However, there are other ways you can save with PERRLA. "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/articles/4403893451547-How-to-Use-PERRLA-s-Referral-Rewards-Program",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [
          _vm._v(
            "Click here to learn how to use your Referral Rewards code to get three months of PERRLA for free."
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n\t\t\t\t\t\t\t\tThis offer was only valid for 60 minutes. However, there are other ways you can save with PERRLA. "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/articles/4403893451547-How-to-Use-PERRLA-s-Referral-Rewards-Program",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [
          _vm._v(
            "Click here to learn how to use your Referral Rewards code to get three months of PERRLA for free."
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }