<!--
- Test Server Alert
-- designed to gracefully guide users away from a test server page
-- 2023.05.29
-->

<template>
	<generic-modal-wrap modal-id="TestServerAlert" :is-close-button="false">
		<h2 class="nodal-title">
			Oops! It looks like you found our Test site.
		</h2>

		<p>This version of the PERRLA website is built for internal testing only. You'll want to do your work in the "real" PERRLA. <a href="#" @click.prevent="goToLiveLink">Click here to go to the stable version of this page</a>.</p>

		<button class="btn btn-success" type="button" @click.prevent="goToLiveLink">
			Go to PERRLA
		</button>
	</generic-modal-wrap>
</template>

<script>
import config from '@/config';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalClassCreate',
	methods:{
		goToLiveLink(){
			let goToPath = this.$route.path;
			window.location = config.urlLive + '/#' + goToPath;
		},
	},
	components:{
		GenericModalWrap
	},
}
</script>


