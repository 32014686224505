<!--
- Footer
-
-->

<template>
	<footer class="container-fluid section px-4">
		<div class="row d-none d-md-flex">
			<div class="col-3">
				<h4>Company</h4>
				<ul>
					<li>
						<a href="///perrla.com/#/about">
							About PERRLA
						</a>
					</li>
					<li>
						<a href="///perrla.com/#/testimonials">
							Testimonials
						</a>
					</li>
					<li>
						<a href="///perrla.com/#/group-sales">
							Group Sales
						</a>
					</li>
					<li>
						<a href="///perrla.com/#/student-stress-relief">
							Stress-Relief Toolkit
						</a>
					</li>
				</ul>
			</div>
			<div class="col-3">
				<h4>Support</h4>
				<ul>
					<li><a href="https://perrla.zendesk.com/hc/en-us">Help Center</a></li>
					<li>
						<router-link
							:to="{
								name: 'ContactSupport'
							}">
							Contact Support
						</router-link>
					</li>
					<li><a href="https://perrla.zendesk.com/hc/en-us/categories/360000322513-Writing-Papers-">Writing Tips &amp; Guides</a></li>
				</ul>
			</div>
			<div class="col-3">
				<h4>Legal</h4>
				<ul>
					<li>
						<a href="///perrla.com/#/privacy-policy">
							Privacy Policy
						</a>
					</li>
					<li>
						<a href="///perrla.com/#/terms-and-conditions">
							Terms &amp; Conditions
						</a>
					</li>
					<li>
						<a href="///perrla.com/#/trademarks">
							Disclaimers
						</a>
					</li>
				</ul>
			</div>
		
		</div>
		<div class="grid">
			<div class="col-12">
				<p>Copyright &copy; 2022 PERRLA, LLC. All Rights Reserved. <i>{{ $version }}</i></p>
			</div>
		</div>
	</footer>
</template>

<script>

export default {
	name: 'PcomFooter',
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";
		
	footer {
		background: #fff;
		border-top: 3px solid $gray-800;

		h4 {
			font-size: 2rem;
			font-weight: 400;
			line-height: 2.5rem;
			margin-bottom: 16px;
		}

		ul {
			font-size:1rem;
			list-style-type: none;
			padding:0;
			
			li{
				margin-bottom: 8px;
				
				a{
					color:$body-color;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		p {
			color: $gray-700;
			font-size: 0.75rem;
			line-height: 1.5rem;

			i {
				color:$gray-700;
			}
		}
	}
	footer.section {
		padding-top:32px;
		padding-bottom:32px;
	}
</style>
