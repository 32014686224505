<template>
	<div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" />
</template>

<script>
export default {
	name: 'RecaptchaComponent',
	data () {
		return {
			sitekey: '6LfD1l0aAAAAANW8vOjLIHolVEtSJigvyUGd6Qvz',
			isRendered: false,
		}
	},
	methods: {
		render () {
			if (window.grecaptcha && window.grecaptcha.render && !this.isRendered) {
				window.grecaptcha.render('g-recaptcha', {
					sitekey: this.sitekey,
					size: 'normal',
					// the callback executed when the user solve the recaptcha
					callback: (response) => {
						// emit an event called verify with the response as payload
						// this.$emit('store-recaptcha', response);
					}
				});//e:window.grecaptcha.render
				this.isRendered = true;
			}//e:if:window.grecaptcha
		}//e:render
	},
	mounted(){
		this.render();
	},
	created(){
		this.emitter.on('recaptchaOnLoadCallback', ()=>{
			this.render();
		});
	},
	destroyed(){
		this.emitter.off('recaptchaOnLoadCallback');
	}
}
</script>