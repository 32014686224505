var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c(
      "div",
      { staticClass: "max-width-900" },
      [
        _c("citation-form", {
          attrs: {
            "paper-format-version-id":
              _vm.$store.state.references.displayFormatVersionId,
            "reference-unique-id": _vm.$route.params.referenceUid.toUpperCase(),
          },
          on: {
            enableCitationFormButton: function ($event) {
              _vm.isDisabled = false
            },
            disableCitationFormButton: function ($event) {
              _vm.isDisabled = true
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }