<!--
- Outline Footnotes
-
-->

<template>
	<div id="outlineFootnotesBlock" v-show="$store.state.paperEdit.outline.citationObjects.length > 0">
		<!-- list of just the citations output, the rows still need right click context menus but no before after content -->
		<outline-footnote-row
			v-for="(footnoteData, index) in $store.state.paperEdit.outline.citationObjects"
			:key="index"
			:footnote-data="footnoteData"
			:footnote-index="index" /> 

	</div>
</template>

<script>
import OutlineFootnoteRow from './Footnotes/Row';

export default {
	name: 'OutlineFootnotesBlock',
	props:{

	},
	data() {
		return {
		}
	},
	computed:{

	},
	methods: {

	},
	created() {
	},
	mounted() {
		// console.log('Footnotes in Outline Monted');
		let outlineFootnotesBlockElement = document.getElementById('outlineFootnotesBlock');

		if(outlineFootnotesBlockElement){
			outlineFootnotesBlockElement.addEventListener('contextmenu', (e) =>{
				
				let closestElement = e.target.closest('.outline-footnote-single');
				
				if(closestElement && closestElement.classList.contains('outline-footnote-single')){
				
					let citationUniqueIdsList = closestElement.getAttribute('citation-unique-ids-list');
					

					if(citationUniqueIdsList){
						e.preventDefault();
						
						this.emitter.emit('showOutlineFootnoteContextMenu',{
							citationUniqueIdsList,
						});
					}
					
				}
				
			}, false);
		}
	},
	components: {
		OutlineFootnoteRow
	}
}
</script>


<style lang="scss" scoped>
	@import "../../../assets/styles/_vars_perrla.scss"; 

	#outlineFootnotesBlock {
		background: $white;
		border-top: 1px solid $body-color;
		padding: 0.5rem 1.5rem;
		
	}
</style>