<!--
- FreeTrialVerify
-
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0" style="background: url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff;">
			<main class="container-text mt-0 pt-3">

				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div class="text-center mb-5">
							<h1>PERRLA</h1>
						</div>

						<p>Thanks for signing up for a Free Trial {{ firstName }}!</p>
						<p>We just sent you an email with a 6-digit verification code. Enter the code from the email below.</p>

						<form class="mt-4">
							<form-input
								label="6-Digit Verification Code"
								type="text"
								v-model="code"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.NUMERIC_REQUIRED" />

							<div class="form-group">
								<div class="d-grid">
									<button type="submit" class="btn btn-success" @click.prevent="btnVerifyAccount">
										Verify My Account
									</button>
								</div>
							</div>
						
							<router-link
								:to="{
									name: 'FreeTrialAlternateEmail'
								}">
								If you didn't receive the email or need to change the email address, click here.
							</router-link>

							<div class="mt-4">
								<p>
									Here's the information we have for your account so far:<br />
										<span class="ms-4 fw-bold">Name:</span> {{ firstName }} {{ lastName }} <br />
										<span class="ms-4 fw-bold">Account Email:</span> {{ email }} <br />
										<span class="ms-4 fw-bold">Institution:</span> {{ school }} <br />
								</p>
							</div>
						</form>

					</div>
				</div>
			</main>
		</section>
	
		<section class="section-text">
			<main class="container-text mt-2">

				<h2 class="text-center">
					Answers to a few common questions
				</h2>
				
				<h4>What do I&nbsp;need to do now?</h4>
				<p class="small mb-5">
					To start using PERRLA, you&#39;ll need to enter the 6-digit verification code that we have emailed you. You can copy and paste the code into the field above. Then, click the&nbsp;<em>Verify My Account</em>&nbsp;button.
				</p>

				<h4>What if I didn&#39;t get the verification email?</h4>
				<p class="small">
					First, you&#39;ll want to check your spam or junk folders. The verification email will be sent from &quot;support@perrla.com&quot;.
				</p>
				<p class="small mb-5">
					If you still can&#39;t find it, you can use a different email address by 
					<router-link
						:to="{
							name: 'FreeTrialAlternateEmail'
						}">
						clicking here
					</router-link>. If you continue to have trouble, email our Customer&nbsp;Support team at support@perrla.com. Be sure to tell them the email address you are trying to use for your Free Trial.
				</p>
				
				<h4>Why do I&nbsp;have to verify my email?</h4>
				<p class="small mb-5">
					We communicate with all our users primarily through email. This helps us ensure we are emailing the correct address when we send updates, account information, or reply to any Support questions.
				</p>

				<h4>What if I&#39;ve used PERRLA&nbsp;before?</h4>
				<p class="small">
					If you&#39;ve used PERRLA&nbsp;before, then we recommend you try to
						<router-link :to="{name: 'Login'}">
							log in using that account information
						</router-link>.
					All of the papers and references you&#39;ve already created will be located in your previous account.
				</p>
				<p class="small mb-5">
					You can reset your password on the log in page if you&#39;ve forgotten it.&nbsp;If you don&#39;t have access to that email or can&#39;t remember your account, you can&nbsp;<a href="mailto:support@perrla.com?subject=I%20can%27t%20access%20my%20PERRLA%20Account">email our Customer Support team</a>&nbsp;for help locating your account.
				</p>

				<h4>Have more questions?</h4>
				<p class="small">
					We have more answers!
				</p>
				<p class="small">
					Check out our <a href="https://perrla.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Help Center</a> for more articles and videos on how to use PERRLA. Or, reach out to our <a href="mailto:support@perrla.com" id="https-www.perrlacomplete.com-ContactSupport">Customer Support team</a> directly. They are available 7 days a week until 10pm Central.
				</p>

				
			</main>
		</section>

	</div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_StoreSyncData from '@/services/app/storeSyncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';

export default {
	name: 'FreeTrialVerify',
	data() {
		return {
			code: '',
			zFormId: 'verifyEmailCode',
		}
	},
	computed:{
		email(){
			return this.$store.state.freeTrial.email;
		},
		firstName(){
			return this.$store.state.freeTrial.firstName;
		},
		lastName(){
			return this.$store.state.freeTrial.lastName;
		},
		school(){
			return this.$store.state.freeTrial.school;
		},
	},
	methods: {
		btnVerifyAccount(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				let sendBody = {
					"id": this.$route.params.verifyId,
					"code": this.code
				};

				// local development can't acccess the MVC project so don't even start down that path if you are local
				if(config.isDev){
					alert('Verification not possible locally');
					this.$store.commit('loaders/REMOVE_ID', 'App');
				} else {
					// 1 - verify this information and create an account
					ApiS_MakeCall({
						body: sendBody,
						ignoreAuth: true,
						method: 'POST',
						responseType: 'text',
						url: 'v4/FreeTrial/Verify',
					}).then((responseData)=>{
						console.log('responseData');
						console.log(responseData);	// this seems to just be a token

						this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
							formId: this.zFormId
						});
						
						// ok now how does the user get from here to the perrla.com how do you want to use perrla section?

						// maybe this is a real log in
						
						// 2 - store the token in vuex
						this.$store.commit('api/SET_TOKEN', responseData);

						// 3a - pass this token back to an MVC project 
						ApiS_MakeCall({
							method: 'GET',
							// ignoreAuth: true,
							outsideSpa: true,
							responseType: 'json',
							url: 'Forms/Login',
						}).then((responseData2)=>{
							console.log('responseData2');
							console.log(responseData2);

							this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
							
							AS_StoreSyncData(responseData2).then(()=>{
								// everything should be done, but take the user to a registration confirmation success page
								this.$store.commit('loaders/REMOVE_ID', 'App');
								this.$router.push({
									name: 'RegistrationSuccess'
								}).catch(()=>{});
							});//e:AS_StoreSyncData
						}).catch((error2) =>{
							console.log('error2');
							console.log(error2);
						});//e:ApiS_MakeCall

					}).catch((error) =>{
						this.$store.commit('loaders/REMOVE_ID', 'App');
						
					});//e:ApiS_MakeCall
				}
			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
		},//e:btnVerifyAccount
	},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App')
		if(_isEmpty(this.$store.state.freeTrial.email)){
			// email is empty, need to verify this user
			ApiS_MakeCall({
				ignoreAuth: true,
				method: 'GET',
				params: {
					id: this.$route.params.verifyId
				},
				responseType: 'json',
				url: 'v4/FreeTrial/Verify',
			}).then((responseData)=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				
				// console.log('responseData');
				// console.log(responseData);
				
				this.$store.commit('freeTrial/SET_EMAIL', responseData.email);
				this.$store.commit('freeTrial/SET_FIRST_NAME', responseData.firstName);
				this.$store.commit('freeTrial/SET_LAST_NAME', responseData.lastName);
				this.$store.commit('freeTrial/SET_PASSWORD', responseData.password);
				this.$store.commit('freeTrial/SET_SCHOOL', responseData.school);

				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormId
				});
				
			}).catch((error2) =>{
				console.log('error2');
				console.log(error2);
			});//e:ApiS_MakeCall

		} else {
			this.$store.commit('loaders/REMOVE_ID', 'App');
		}
	},
	components: {
		FormInput
	},
}
</script>
