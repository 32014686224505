var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "layout-pcom" }, [
      _c("section", { staticClass: "section-text pt-0" }, [
        _c("main", { staticClass: "container-text mt-0 pt-3" }, [
          _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
            _c("div", { staticClass: "inset-body" }, [
              _c("img", {
                staticClass: "mx-auto d-block",
                attrs: {
                  src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                  width: "140px",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("div", [
                _c("h1", { staticClass: "text-center" }, [
                  _vm._v("\n\t\t\t\t\t\t\tPERRLA\n\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("h2", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tPreparing your discount.\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }