var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", { attrs: { title: "Outline" } }),
        _vm._v(" "),
        _c("div", { staticClass: "d-grid mt-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-success",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnInsertAll.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tInsert entire outline\n\t\t\t")]
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "drawer-body", attrs: { id: "outlineDrawerWrp" } },
      _vm._l(_vm.outlineCraft, function (nodeData, index) {
        return _c("div", { key: index }, [
          nodeData.content === "/HR/"
            ? _c("div", { key: "divOutlineNodeBlank" }, [_c("hr")])
            : _c(
                "div",
                { key: "divOutlineNode" },
                [_c("outline-row", { attrs: { "node-data": nodeData } })],
                1
              ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }