var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "ResearchNotesModalsCitationEdit" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tEdit Citation\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "form",
        [
          _c("citation-form", {
            attrs: {
              "load-citation-data": _vm.findCitation,
              "paper-format-version-id": _vm.paperFormatVersionID,
              "reference-unique-id":
                _vm.$store.state.modals.passData.referenceUniqueId,
            },
            on: {
              enableCitationFormButton: function ($event) {
                _vm.isDisabled = false
              },
              disableCitationFormButton: function ($event) {
                _vm.isDisabled = true
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnDeleteCitation.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Delete Citation")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "submit", disabled: _vm.isDisabled },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.updateCitation(false)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tUpdate Citation\n\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }