var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paper-edit-wrp" },
    [
      _c(
        "nav-tabs",
        [
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "PaperTabOverview",
                },
              },
            },
            [_vm._v("\n\t\t\tOverview\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "PaperTabResearch",
                },
              },
            },
            [_vm._v("\n\t\t\tResearch\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "OutlineCitationLastVisitedDrawer",
                },
                "is-active":
                  _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.OUTLINE,
              },
            },
            [_vm._v("\n\t\t\tOutline\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "EditorReferencesCitations",
                },
                "is-active":
                  _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.EDITOR,
              },
            },
            [_vm._v("\n\t\t\tEditor\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "PaperTabExport",
                },
              },
            },
            [_vm._v("\n\t\t\tExport / Print\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("modal-special-characters"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }