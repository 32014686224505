<!--
- CitationForm
-- create/update a citaion
-- this happens from multiple places, tabs, and modules, so add lots of Module checks 
-->

<template>
	<div v-if="citationLocations.length > 0">
        <!-- Turabian 9 && Newspaper-->
        <div class="alert alert-warning" v-if="isShowTurb9NewspaperWarning" key="turabianAlert">
			<p>
				According to the Turabian Manual 17.4.2, <strong>you should not include page numbers for newspaper references</strong> - even printed editions
			</p>
		</div>
        
        <citation-live-preview v-show="isLocationPart || isDatePart || isNamePart" :citation-output="citationOutput" />

        <section class="mb-4" v-if="isShowCitationParts" key="spanPartsToInclude">
			<h3>Parts to include in Citation</h3>
			<div class="help-text mb-1">
				If the author&#39;s name, date, or citation is already included in the sentence you&#39;re citing, you can leave it out of the citation.
			</div>

			<form-checkbox
				label="Contributor&#39;s name"
				class="mb-0"
				v-model="isNamePart" />
				
			<form-checkbox
				v-if="isShowDateOption"
				label="Date"
				class="mb-0"
				v-model="isDatePart" />
			
			<form-checkbox 
				label="Location (page number, etc.)"
				class="mb-0"
				v-model="isLocationPart" />
		</section><!--spanPartsToInclude-->

        <section class="mb-3">
			<h3>
				Choose a Citation location
			</h3>
            <!-- {{  citationLocations }} -->
            <div v-for="(citationLocationEnum) in citationLocations" :key="citationLocationEnum">
                <div v-if="citationLocationEnum === enumsCitationLocation.NOT_A_DIRECT_QUOTE">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_NotDirect"
                            name="type_NotDirect"
                            ref="focusNotDirectRadio"
                            type="radio"
                            v-model="citationLocationType"
                            :disabled="!isLocationPart"
                            value="n.d." />

                        <label class="form-check-label" for="type_NotDirect">Not a direct quote</label>
                    </div>
                </div><!--NOT_A_DIRECT_QUOTE-->

                <div v-if="citationLocationEnum === enumsCitationLocation.ACT_SCENE_LINE">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Time"
                            name="type_Time"
                            type="radio"
                            value="actSceneLine"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Time">Act, Scene, &amp; Line</label>
                    </div>
                    <div class="col px-3 my-1 w-100" v-show="citationLocationType === 'actSceneLine'">
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Act</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    v-model="actSceneLineAct" />
                            </div>
                        </div>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Scene</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    v-model="actSceneLineScene" />
                            </div>
                        </div>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Line</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    v-model="actSceneLineLine" />
                            </div>
                        </div>
                    </div>
                </div><!--ACT_SCENE_LINE-->

                <div v-if="citationLocationEnum === enumsCitationLocation.BOOK_CHAPTER_VERSE">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Religious"
                            autocomplete="off"
                            name="type_Religious"
                            type="radio"
                            value="religious"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Religious">Book, chapter, &amp; verse</label>
                    </div>
                    <div class="col px-3 my-1" v-show="citationLocationType === 'religious'">
                        <p class="help-text">
                            Abbreviate the book if possible. Separate chapter &amp; verse with a colon.
                        </p>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-2">
                                <label class="col-form-label">Book</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="book"
                                    v-model="otherLabel" />
                            </div>
                        </div>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Chap: Verse</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="chapter:verse"
                                    v-model="citationLocationValue" />
                            </div>
                        </div>
                    </div>
                </div><!--BOOK_CHAPTER_VERSE-->
                                
                <div v-if="citationLocationEnum === enumsCitationLocation.PAGE_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Page"
                            name="type_Page"
                            type="radio"
                            value="page"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Page">Page number</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'page'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--PAGE_NUMBER-->
                
                <div v-if="citationLocationEnum === enumsCitationLocation.PARAGRAPH_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Parahraph"
                            name="type_Parahraph"
                            type="radio"
                            value="paragraph"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Parahraph">Paragraph</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'paragraph'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--PARAGRAPH_NUMBER-->

                <div v-if="citationLocationEnum === enumsCitationLocation.CHAPTER_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Chapter"
                            name="type_Chapter"
                            type="radio"
                            value="chapter"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Chapter">Chapter</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'chapter'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--CHAPTER_NUMBER-->
                
                <div v-if="citationLocationEnum === enumsCitationLocation.SECTION_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Section"
                            name="type_Section"
                            type="radio"
                            value="section"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Section">Section</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'section'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--SECTION_NUMBER-->

                <div v-if="citationLocationEnum === enumsCitationLocation.TABLE_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Table"
                            name="type_Table"
                            type="radio"
                            value="table"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Table">Table</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'table'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--TABLE_NUMBER-->

                <div v-if="citationLocationEnum === enumsCitationLocation.FIGURE_NUMBER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Figure"
                            name="type_Figure"
                            type="radio"
                            value="figure"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Figure">Figure</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'figure'">
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            autocomplete="off"
                            v-focus-update
                            v-model="citationLocationValue" />
                    </div>
                </div><!--FIGURE_NUMBER-->

                <div v-if="citationLocationEnum === enumsCitationLocation.TIME">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_Time"
                            name="type_Time"
                            type="radio"
                            value="time"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_Time">Time</label>
                    </div>
                    <div class="col px-3 my-1 w-50" v-show="citationLocationType === 'time'">
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Hours</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    ref="time_hours"
                                    autocomplete="off"
                                    class="form-control"
                                    v-model="timeBasedMediaHours" />
                            </div>
                        </div>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Minutes</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    ref="time_minutes"
                                    autocomplete="off"
                                    class="form-control"
                                    v-model="timeBasedMediaMinutes" />
                            </div>
                        </div>
                        <div class="form-group form-group-horizontal mb-2">
                            <div class="flex-shrink-1">
                                <label class="col-form-label">Seconds</label>
                            </div>
                            <div class="w-100">
                                <input
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    ref="time_seconds"
                                    v-model="timeBasedMediaSeconds" />
                            </div>
                        </div>
                    </div>
                </div><!--TIME-->
            
                <div v-if="citationLocationEnum === enumsCitationLocation.OTHER">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="type_other"
                            name="type_other"
                            type="radio"
                            value="other"
                            :disabled="!isLocationPart"
                            v-model="citationLocationType" />
                        <label class="form-check-label" for="type_other">Other</label>
                    </div>
                    <div class="col px-3 my-1" v-show="citationLocationType === 'other'">
                        <small class="form-text text-muted mb-1">
                            If you enter something other than page, chapter, table, etc then you need to enter what part you are citing as well as the value. Ex: Track 15, Screen 7.
                        </small>

                        <div class="row">
                            <div class="col">
                                <input
                                    class="form-control form-control-sm w-50 mb-1 d-inline"
                                    placeholder="Label"
                                    type="text"
                                    autocomplete="off"
                                    v-model="otherLabel" />

                                <input
                                    class="form-control form-control-sm w-25 d-inline"
                                    placeholder="Value"
                                    type="text"
                                    autocomplete="off"
                                    v-model="citationLocationValue" />
                            </div>
                        </div>
                    </div>
                </div><!--OTHER-->

            </div><!--v-for-->
            
            <copy-json-data class="mt-3" :data-to-display="JSON.stringify(citationDataToCopy)" />
            
        </section>
	</div>
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import CitationLivePreview from '@/components/CitationLivePreview';
import config from '@/config.js';
import CopyJsonData from '@/components/CopyJsonData'
import Engine from '@/services/reference/Engine';
import FormCheckbox from '@/components/form/Checkbox';
import toBoolean from 'validator/lib/toBoolean';	//https://www.npmjs.com/package/validator

export default {
	name: 'CitationForm',
	props:{
        loadCitationData:{
			type: Object,
		},
		referenceUniqueId:{
			type: String
		},
	},
	data() {
		return {
			actSceneLineAct: '',
			actSceneLineScene: '',
			actSceneLineLine: '',
			citationDataToCopy: {},
			citationLocationType: 'n.d.',	// defaults to Not a Direct Quote
			citationLocationValue: '',
            enumsCitationLocation: {
                'ACT_SCENE_LINE': 1,
                'BOOK_CHAPTER_VERSE': 2,
                'CHAPTER_NUMBER': 5,
                'FIGURE_NUMBER': 8,
                'NOT_A_DIRECT_QUOTE': 0,
                'PAGE_NUMBER': 3,
                'PARAGRAPH_NUMBER': 4,
                'SECTION_NUMBER': 6,
                'TABLE_NUMBER': 7,
                'TIME': 9,
                'OTHER': 10
            },
			isDatePart : true,
			isLocationPart: true,
			isNamePart : true,
			otherLabel: '',
			shortTitle: '',	// for mla9 but unused for now
			timeBasedMediaHours: '00',
			timeBasedMediaMinutes: '00',
			timeBasedMediaSeconds: '00',
		}
	},
    watch: {
		isLocationPart(newValue){
			// is the user turns off the location - set the location value to empty
			if(!newValue){
				this.citationLocationValue = '';
			}
		},
        $route() {
			// Paper Edit only: incase user clicks to add citation, then while that is open they click on a citation in the paper to open its edit panel
            this.loadCitationEditData();
		}
	},
	computed:{
        citationLocations(){
            let returnObject = [];

            if(!_isEmpty(this.parentReferenceObject)){
                // all of them start with this
                returnObject.push(this.enumsCitationLocation.NOT_A_DIRECT_QUOTE);

                // ancient/classical works only
                if(this.parentReferenceObject.referenceTypeID == 6){
                    returnObject.push(this.enumsCitationLocation.ACT_SCENE_LINE);
                }

                // religious works only
                if(this.parentReferenceObject.referenceTypeID == 37){
                    returnObject.push(this.enumsCitationLocation.BOOK_CHAPTER_VERSE);
                }

                // time as the 2nd option - other ref types get time added at the end
                if(
                    this.parentReferenceObject.referenceTypeID == 19 || // film
                    this.parentReferenceObject.referenceTypeID == 28 || // music
                    this.parentReferenceObject.referenceTypeID == 34 || // podcast & radio
                    this.parentReferenceObject.referenceTypeID == 43 || // television
                    this.parentReferenceObject.referenceTypeID == 47    // videos(online)
                ){
                    returnObject.push(this.enumsCitationLocation.TIME);
                }

                // (no Films or Movies)
                if(
                    this.parentReferenceObject.referenceTypeID != 5 && // no advertisement    
                    this.parentReferenceObject.referenceTypeID != 19 && // no films
                    this.parentReferenceObject.referenceTypeID != 28 && // no movies
                    this.parentReferenceObject.referenceTypeID != 47 // no videos (online)
                ){
                    returnObject.push(this.enumsCitationLocation.PAGE_NUMBER);
                    returnObject.push(this.enumsCitationLocation.PARAGRAPH_NUMBER);    
                }

                if(
                    this.parentReferenceObject.referenceTypeID == 2 ||  // book
                    this.parentReferenceObject.referenceTypeID == 4 ||  // grey literatur
                    this.parentReferenceObject.referenceTypeID == 6 ||  // ancient/classical works
                    this.parentReferenceObject.referenceTypeID == 7 ||  // anthology
                    this.parentReferenceObject.referenceTypeID == 11 || // blog
                    this.parentReferenceObject.referenceTypeID == 31 || // brochures
                    this.parentReferenceObject.referenceTypeID == 37 || // religioud work
                    this.parentReferenceObject.referenceTypeID == 47    // videos(online)
                ){
                    returnObject.push(this.enumsCitationLocation.CHAPTER_NUMBER);
                }
                
                if(
                    this.parentReferenceObject.referenceTypeID != 5 && // no advertisement    
                    this.parentReferenceObject.referenceTypeID != 19 && // no films
                    this.parentReferenceObject.referenceTypeID != 28 && // no movies
                    this.parentReferenceObject.referenceTypeID != 47 // no videos(online)
                ){
                    returnObject.push(this.enumsCitationLocation.SECTION_NUMBER);
                }
                
                if(
                    this.parentReferenceObject.referenceTypeID != 5 && // no advertisement        
                    this.parentReferenceObject.referenceTypeID != 6 &&  // no ancient/classical works
                    this.parentReferenceObject.referenceTypeID != 19 && // no films
                    this.parentReferenceObject.referenceTypeID != 28 && // no movies
                    this.parentReferenceObject.referenceTypeID != 36 && // no reference work
                    this.parentReferenceObject.referenceTypeID != 47  // no videos(online)
                ){
                    returnObject.push(this.enumsCitationLocation.TABLE_NUMBER);
                    returnObject.push(this.enumsCitationLocation.FIGURE_NUMBER);
                }

                // time as the next to last option
                if(
                    this.hasNarrator ||
                    this.parentReferenceObject.referenceTypeID == 2 ||  // book
                    this.parentReferenceObject.referenceTypeID == 5 || // advertisement
                    this.parentReferenceObject.referenceTypeID == 9    // artwork
                    
                ){
                    returnObject.push(this.enumsCitationLocation.TIME);
                }
                
                // all of them end with this
                returnObject.push(this.enumsCitationLocation.OTHER);

                // remove items if this is an interview
                if(this.parentReferenceObject.referenceTypeID === 23){
                    let parseJsonData = JSON.parse(this.parentReferenceObject.data);
                    if(parseJsonData.type === 'personal'){
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.PAGE_NUMBER), 1);
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.PARAGRAPH_NUMBER), 1);
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.SECTION_NUMBER), 1);
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.TABLE_NUMBER), 1);
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.FIGURE_NUMBER), 1);
                        returnObject.splice(returnObject.indexOf(this.enumsCitationLocation.OTHER), 1);
                    }
                }
                
            }//e:isEmpty
            
            return returnObject;
        },//e:citationLocations

        citationOutput(){
            if(!_isEmpty(this.parentReferenceObject) && _has(this.parentReferenceObject, 'data')){
            
				let valueOutput = '';
                
				if(this.citationLocationType === 'actSceneLine') {
					// assemble {act}/{scene}/{line}
					valueOutput += this.actSceneLineAct + '/';
					valueOutput += this.actSceneLineScene + '/';
					valueOutput += this.actSceneLineLine;

				} else if(this.citationLocationType === 'time') {
					// calculate time from 3 form inputs (H:MM:SS)
					valueOutput = this.timeBasedMediaHours + '/' + this.timeBasedMediaMinutes + '/' + this.timeBasedMediaSeconds;

				} else {
					valueOutput = this.citationLocationValue.toString();
				}

				this.citationDataToCopy = {
					'datePart': this.isDatePart.toString(),
					'label': this.otherLabel,
					'namePart': this.isNamePart.toString(),
					'shortTitle': this.shortTitle,
					'type': this.citationLocationType,
					'value': valueOutput,
				}
				// console.log(citationData);

				let modeLevel = 'available';
				if(config.isDev){
					modeLevel = 'dev';

				} else if(config.isTest){
					modeLevel = 'test';
					
				}
				
				let citationEngineResult = Engine.getCitation(this.parentReferenceObject.referenceTypeID, JSON.parse(this.parentReferenceObject.data), this.citationDataToCopy, modeLevel);
				citationEngineResult.citationData = this.citationDataToCopy;

                if(this.$route.meta.module === config.enums.Module.PAPER_EDIT){
                    // commit the engine result to the vuex store temporarily so i can access it from the parent component
                    this.$store.commit('paperEdit/citations/SET_TEMP_CITATION_DATA', citationEngineResult);
                    
                    // if all three checkbox options are blank, i need to signal up to disable the create button
                    if(!this.isDatePart && !this.isLocationPart && !this.isNamePart){
                        this.$emit('disableCitationFormButton');
                    } else {
                        this.$emit('enableCitationFormButton');
                    }
                }
                
                // default to paper edit, only change if it's from the Reference Edit Page
                let formatToCheck = this.$store.state.paperEdit.meta.PaperFormatVersionID;

                if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT){
                    formatToCheck = this.$store.state.references.displayFormatVersionId;
                }

                switch(formatToCheck){
                    case config.enums.Format.MLA9:
                        return citationEngineResult.mla9.first;
                    case config.enums.Format.Turabian9:
                        return citationEngineResult.turabian9.first;
                    default:
                        return citationEngineResult.apa7.first
                }
			}
			return '';
            
		},//e:citationOutput

        hasNarrator(){
			let returnValue = false;
            if(!_isEmpty(this.parentReferenceObject)){
                let refRules; 
                // use a try here incase the parse doens't work - that was happening in rare cases that i don't want to deal with
                try {
                    refRules = JSON.parse(this.parentReferenceObject.data);
					
                    refRules.contributors.forEach((contributor)=>{
                        if(contributor.type === 'narrator') {
                            returnValue = true;
                        }
                    });//e:forEach
                } catch {
                    returnValue = false;
                }
            }
            return returnValue;
        },//e:hasNarrator

        isShowCitationParts(){
            if(
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT && 
                    this.$store.state.references.displayFormatVersionId === config.enums.Format.Turabian9
                ) ||
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT && 
                    this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9
                )
            ){
                // this area wil be hidden in Turabian 9 so force them all to true to prevent any weirdness
                this.isDatePart = true;
                this.isLocationPart = true;
                this.isNamePart = true;
                return false;
            }

            return true;
        },//e:isShowCitationParts

        isShowDateOption(){
            if(
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT && 
                    this.$store.state.references.displayFormatVersionId === config.enums.Format.MLA9
                ) ||
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT && 
                    this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9
                )
            ){
                // this area wil be hidden in MLA 9 so force them all to true to prevent any weirdness
                this.isDatePart = true;
                return false;
            }

            return true;

        },//e:isShowDateOption

        isShowTurb9NewspaperWarning(){
            if(
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT && 
                    this.$store.state.references.displayFormatVersionId === config.enums.Format.Turabian9 &&
                    this.parentReferenceObject.referenceTypeID === 29
                ) ||
                (
                    this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT && 
                    this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 && 
                    this.parentReferenceObject.referenceTypeID === 29
                )
            ){
                return true;
            }

            return false;
        },//e:isShowTurb9NewspaperWarning

        parentReferenceObject(){
            // this makes sure sync has been stored and there actually references in here to look through
            if(!_isEmpty(this.$store.state.referenceLibraryGenesis)){
                // look a fresh copy of this reference up buy the UID that was passed in as a prop
                let referenceFind = this.$store.state.referenceLibraryGenesis.find((reference)=>{
                    return reference.referenceUniqueID.toUpperCase() === this.referenceUniqueId;
                });

                if(referenceFind){
                    return referenceFind;
                }//e:if:referenceFind
            }//e:isEmpty

            return {};
        },//e:parentReferenceObject
	},
    directives: {
        // Standard quotation locaiton / Focus on textbox after radio clicked
        focusUpdate: {
            componentUpdated: function (el) {
                el.focus();
            }
        }
    },
    methods:{
        loadCitationEditData(){
            if(!_isEmpty(this.loadCitationData)){
                this.isDatePart = toBoolean(this.loadCitationData.citationData.datePart);
                this.isNamePart = toBoolean(this.loadCitationData.citationData.namePart); 
                this.citationLocationType = this.loadCitationData.citationData.type;
                
                let splitValueArray;

                switch(this.citationLocationType){
                    case 'actSceneLine':
                        // 	// ancient or classical works
                        splitValueArray = this.loadCitationData.citationData.value.split("/");
                        this.actSceneLineAct = splitValueArray[0] || '';
                        this.actSceneLineScene = splitValueArray[1] || '';
                        this.actSceneLineLine = splitValueArray[2]|| '';
                        break;

                    case 'time':
                        // deconstruct time values
                        splitValueArray = this.loadCitationData.citationData.value.split("/");
                        // check the length of this array - could be 00/00 or 00/00/00
                        if(splitValueArray.length === 2){
                            // MM/SS only
                            this.timeBasedMediaHours = '00';
                            this.timeBasedMediaMinutes = splitValueArray[0] || '00';
                            this.timeBasedMediaSeconds = splitValueArray[1]|| '00';

                        } else {
                            // Full HH/MM/SS
                            this.timeBasedMediaHours = splitValueArray[0] || '00';
                            this.timeBasedMediaMinutes = splitValueArray[1] || '00';
                            this.timeBasedMediaSeconds = splitValueArray[2]|| '00';
                        }
                        break;

                    case 'religious':
                        this.otherLabel = this.loadCitationData.citationData.label;
                        this.citationLocationValue = this.loadCitationData.citationData.value;
                        break;

                    default:
                        if(this.citationLocationType === '') {
                            // no type, so guess that qutoation location was off when they created
                            this.isLocationPart = false;
                        }
                        this.otherLabel = this.loadCitationData.citationData.label;
                        this.citationLocationValue = this.loadCitationData.citationData.value;
                }
            }
        },//e:loadCitationEditData
    },
    mounted(){
        this.loadCitationEditData();
    },
	components: {
        CitationLivePreview,
        CopyJsonData,
        FormCheckbox,
	}
}
</script>
