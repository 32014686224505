var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "clearfix mb-2 px-1 py-2 research-note-row",
        {
          "research-note-row-in-paper": _vm.isActive,
        },
      ],
      attrs: { draggable: "true", id: _vm.calcResearchNoteRowId },
      on: {
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("div", { staticClass: "svg-wrp" }, [
        _c(
          "a",
          {
            staticClass: "svg-link svg-link-add float-end",
            attrs: { href: "#", "aria-label": "Add Research Note" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnAddResearchNote.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg bi-plus-lg" })]
        ),
        _vm._v(" "),
        _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.EDITOR
          ? _c(
              "a",
              {
                staticClass: "svg-link svg-link-toggle float-end",
                attrs: { href: "#", title: "Toggle Checked" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleIsActive.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "svg bi-check-lg" })]
            )
          : _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.OUTLINE
          ? _c(
              "a",
              {
                staticClass: "svg-link svg-link-toggle float-end",
                attrs: { href: "#", title: "Toggle Checked" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleIsActive.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "svg bi-check-lg" })]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "research-note-text mx-1",
        attrs: { title: "Drag into your paper" },
        domProps: { innerHTML: _vm._s(_vm.researchNoteText) },
      }),
      _vm._v(" "),
      _vm.calcCitationOutput != ""
        ? _c("p", { class: ["research-note-citation mb-0 float-end"] }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.calcCitationOutput) },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }