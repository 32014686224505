var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.show3pluRedirectMessage
        ? _c(
            "div",
            { key: "alert3pluRedirected", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tWe've opened an empty reference form since you searched for a generic term. If you'd like to find a specific work, try entering the work's title, author, DOI, or URL.\n\t\t"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.referenceTypeId === 23
        ? _c("div", { key: "alertInterview" }, [
            _vm.referenceFormatTypeId === _vm.$enums.Format.APA7 ||
            _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
              ? _c(
                  "div",
                  {
                    key: "alertInterviewAPAMLA",
                    staticClass: "alert alert-danger",
                  },
                  [_vm._m(0)]
                )
              : _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9
              ? _c(
                  "div",
                  {
                    key: "alertInterviewTurabian9",
                    staticClass: "alert alert-warning",
                  },
                  [
                    _c("span", {
                      staticClass: "svg bi-exclamation-lg float-start",
                    }),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                )
              : _vm._e(),
          ])
        : _vm.referenceTypeId === 3
        ? _c(
            "div",
            { key: "alertWebite", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tReferences viewed online should use the reference type for the original work. For example, journal articles viewed online use the Journal reference type - not Webpage.\n\t\t"
                ),
              ]),
            ]
          )
        : _vm.referenceTypeId === 10 &&
          _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9 &&
          _vm.$store.state.referenceRules.formData.type === "enactedFed"
        ? _c("div", { key: "alertBills", staticClass: "alert alert-danger" }, [
            _c("p", { staticClass: "ps-3" }, [
              _vm._v(
                "\n\t\t\tAccording to the Turabian 9th Edition, enacted bills and resolutions should be cited as Statutes.\n\t\t\t"
              ),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnLinkToStatues.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "Click here to create a Statute for an enacted bill or resolution."
                  ),
                ]
              ),
            ]),
          ])
        : _vm.referenceTypeId === 17
        ? _c(
            "div",
            { key: "alertExecutiveOrders", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tThis reference type is for citing the formal publication of Executive Orders in the Code of Federal Regulations. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
                ),
              ]),
            ]
          )
        : _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9 &&
          _vm.referenceTypeId === 30
        ? _c(
            "div",
            { key: "alertOnlineComment", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tIn Turabian papers you must include a reference to the comment and the work being commented on. For example, when adding a reference for a comment on an article, also add a reference for the article itself.\n\t\t"
                ),
              ]),
            ]
          )
        : _vm.referenceTypeId === 39
        ? _c(
            "div",
            { key: "alertSocialMedia", staticClass: "alert alert-warning" },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tDirect messages, private posts, and other publicly inaccessible content should be cited as "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnLinkToPersonalCommunications.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Personal Communications")]
                ),
              ]),
            ]
          )
        : _vm.referenceTypeId === 45 &&
          _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9
        ? _c(
            "div",
            {
              key: "alertUSFederalCourtCases",
              staticClass: "alert alert-warning",
            },
            [
              _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
              _vm._v(" "),
              _c("p", { staticClass: "ps-3" }, [
                _vm._v(
                  "\n\t\t\tThe Turabian style only includes information about the appealed case at the Circuit Court level\n\t\t"
                ),
              ]),
            ]
          )
        : _c("div", { key: "alertWarning", staticClass: "alert alert-info" }, [
            _c("span", { staticClass: "svg bi-check-circle-fill float-start" }),
            _vm._v(" "),
            _c("p", { staticClass: "ps-3" }, [
              _vm._v(
                "\n\t\t\tCommonly used fields are outlined in blue.\n\t\t"
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("reference-types", {
        attrs: { "reference-type-id": _vm.referenceTypeId },
      }),
      _vm._v(" "),
      _c("reference-output"),
      _vm._v(" "),
      _c("copy-json-data", {
        attrs: {
          "data-to-display": JSON.stringify(
            _vm.$store.state.referenceRules.rulesObjectData
          ),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2 row justify-content-between" }, [
        _vm.$router.currentRoute.meta.module ===
        _vm.$enums.Module.REFERENCE_LIST
          ? _c("div", { key: "colReferenceList", staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "d-grid" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: {
                        to: {
                          name: "ReferenceListCreateReferenceStep1",
                          params: {
                            openReferenceTab:
                              _vm.$route.params.openReferenceTab,
                          },
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ])
          : _vm.$router.currentRoute.meta.module ===
            _vm.$enums.Module.PAPER_EDIT
          ? _c("div", { key: "colPaperEdit", staticClass: "col" }, [
              _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.EDITOR
                ? _c(
                    "div",
                    { key: "divBackEditor", staticClass: "d-grid" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-outline-primary",
                          attrs: {
                            to: {
                              name: "EditorCreateReferenceStep1",
                              params: {
                                openReferenceTab:
                                  _vm.$route.params.openReferenceTab,
                              },
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                      ),
                    ],
                    1
                  )
                : _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.OUTLINE
                ? _c(
                    "div",
                    { key: "divBackOutline", staticClass: "d-grid" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-outline-primary",
                          attrs: {
                            to: {
                              name: "OutlineCreateReferenceStep1",
                              params: {
                                openReferenceTab:
                                  _vm.$route.params.openReferenceTab,
                              },
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                      ),
                    ],
                    1
                  )
                : _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.RESEARCH
                ? _c(
                    "div",
                    { key: "divBackResearch", staticClass: "d-grid" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-outline-primary",
                          attrs: {
                            to: {
                              name: "PaperTabResearchCreateReferenceStep1",
                              params: {
                                openReferenceTab:
                                  _vm.$route.params.openReferenceTab,
                              },
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col text-end" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.$store.state.referenceRules.isCreateNextEnabled,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.generateReference.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tNext\n\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Interviews in APA & MLA are treated as Personal Communications in papers."
        ),
      ]),
      _vm._v(
        " We recommend creating interviews in the reference type where they were published.\n\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ps-3" }, [
      _c("strong", [
        _vm._v(
          "Published interviews should be created in their corresponding reference types"
        ),
      ]),
      _vm._v(
        " (e.g., radio interviews would be created using the Podcast & Radio reference type).\n\t\t\t"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }