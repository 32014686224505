var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "label",
      { class: [{ "fst-italic": _vm.isInvalid }], attrs: { for: _vm.safeId } },
      [
        _vm._v("\n\t\tRunning Head\n\t\t"),
        _c("span", { staticClass: "label-action" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.value.length > 0 && !_vm.isRunningHeadLinked,
                  expression: "value.length > 0 && !isRunningHeadLinked",
                },
              ],
              staticClass: "hover-underline",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.resetRunningHeadLink.apply(null, arguments)
                },
              },
            },
            [_vm._v("Reset text")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.isRemoveable
      ? _c("div", { staticClass: "d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-hover btn-hover-danger",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("running-head-toggle")
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-x-lg" }),
              _vm._v(" Remove running head\n\t\t"),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      class: ["form-control", { "is-invalid": _vm.isInvalid }],
      attrs: {
        autocomplete: "off",
        placeholder: "(required)",
        type: "text",
        id: _vm.safeId,
      },
      domProps: { value: _vm.value },
      on: {
        blur: function ($event) {
          return _vm.blurInput($event.target.value)
        },
        input: function ($event) {
          return _vm.formInput($event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.isInvalid
      ? _c("div", { staticClass: "invalid-feedback" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }