/*
- dateIsEntryInvalid
-- checks if a user input date is valid (not over the max limit for a calendar)
-- note: this does NOT validated the actul date object
-- 2022.10.11
*/
const maxDaysPerMonth = 31;
const maxMonthsPerYear = 12;

export default ($opts) => {
	// console.log('dateIsEntryInvalid');
	
	if(
		($opts.type === 'd' && parseInt($opts.value) > maxDaysPerMonth) || 
		($opts.type === 'm' && parseInt($opts.value) > maxMonthsPerYear)
	){
		return true;
	}
	return false;
}