var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "w-100" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
          {
            name: "debounce",
            rawName: "v-debounce:500ms",
            value: _vm.emitToParentGrid,
            expression: "emitToParentGrid",
            arg: "500ms",
          },
        ],
        staticClass: "form-control form-control-grid-search",
        attrs: { placeholder: _vm.placeholderText, type: "text" },
        domProps: { value: _vm.searchTerm },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchTerm && _vm.searchTerm.length > 0,
            expression: "searchTerm && searchTerm.length > 0",
          },
        ],
        staticClass: "flex-shrink-1",
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-link hover-underline",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.clearSearch.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tClear\n\t\t")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }