<!--
- Outline Editor > Tool Drawer > Create Step 4 Third Party Lookup
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title :title="referenceTypeName" :is-browser-back="true" />
		</div>
	
		<div class="drawer-body">
			<create-reference-step-4-third-party-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import CreateReferenceStep4ThirdPartyBody from '@/modules/CreateReferenceStep4ThirdPartyBody'
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'OutlineCreateStep4ThirdParty',
	computed: {
		referenceTypeName(){
			// commit from Step1 Row when selecting type
			return this.$store.state.referenceRules.referenceTypeName;
		},
	},
	mounted() {
		// check for blank referenceTypeId on create only (webpack hot reload without a selected reference type)
		if(this.$store.state.referenceRules.referenceTypeId === 0){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
			return;
		}
	},
	components: {
		CreateReferenceStep4ThirdPartyBody,
		ToolDrawerTitle
	}
}
</script>
