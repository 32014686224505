<!--
- Drawer Toaster
-- loads at the bottom of Tool Drawer
-- used to display a message, like creating a table
-- after display, it closes after 2.5s
-->

<template>
	<div :class="['toaster', {'toaster-displayed':isDisplayed ,'toaster-open': isOpen}]" ref="toasterWrp">
		<div :class="['toaster-header toaster-' + type]" v-html="title" />

		<!-- Optional Body Content as HTML -->
		<div v-if="body.length > 0" class="toaster-body">
			<div v-html="body" />
			<div class="d-grid">
				<button type="button" :class="['btn btn-' + buttonType]" @click.prevent="isOpen = false">
					{{ buttonText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'DrawerToaster',
	props:{
	},
	data() {
		return {
			body: '',
			buttonText: 'Got it - thanks!',	// default
			buttonType: 'success',
			isDisplayed: false,		// is element rendered on the page and ready to open/show
			isOpen: false,			// css class to control transition of the bottom property 
			isSticky: false,
			title: 'Success!',	// default for now
			type: 'success'		// default (Heading)
		}
	},
	methods: {
		closeToast(){
			this.isDisplayed = false;
			this.isOpen = false;
		},
	},
	created() {
		this.emitter.on('paperDrawerToasterOpen', ($opts)=>{
			this.body = ($opts.body) ? $opts.body : '';
			this.buttonText = ($opts.buttonText) ? $opts.buttonText : 'Got it - thanks!';
			this.isSticky = ($opts.isSticky) ? $opts.isSticky : false;
			this.title = ($opts.title) ? $opts.title : 'Success!';
			
			if($opts.type){
				this.type = $opts.type
			} else {
				this.type = 'success';	// cover the default
			}

			// render the alert in the DOM so i can measure it
			this.isDisplayed = true;			

			this.$nextTick(()=>{
				// measure the element's height, this content could change drastically
				let toasterHeight = this.$refs.toasterWrp.clientHeight;
			// 	this.$refs.toasterWrp.style.display = "block";
				this.$refs.toasterWrp.style.bottom = -toasterHeight - 12 + "px";
					
				// adds a css class that will start the open/slide movement
				this.isOpen = true;
			});

			// set close timer
			if(!this.isSticky){
				setTimeout(()=>{
					this.closeToast();
				}, 2500);
			}
		});

		this.emitter.on('paperDrawerToasterClose', ($opts)=>{
			this.closeToast();
		});
	},
	//mounted() {},
	destroyed(){
		this.emitter.off('paperDrawerToasterClose');
		this.emitter.off('paperDrawerToasterOpen');
	}
}
</script>


<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla.scss";

	.toaster {
		bottom: -200px;	// keep this low and out of view
		display: none;
		left: 0;
		position: absolute;
		right: 0;
		z-index: 1000000; // this is so high so it will float over the zendesk help button

		.toaster-header {
			color:#fff;
			font-size: 1.5rem;
			font-weight: $font-weight-bold;
			line-height: 38px;
			padding: 0.75rem 1.25rem;
			text-align: center;

			// Header Color Variations
			&.toaster-danger {
				background: $danger;
			}

			&.toaster-info {
				background: $info;
			}

			&.toaster-primary {
				background: $primary;
			}

			&.toaster-success {
				background: $success;
			}
			
			&.toaster-warning {
				background: $warning;
			}
		}

		.toaster-body {
			background: #fff;
			padding: 0.75rem 1.25rem;
		}

		&.toaster-displayed {
			display: block;
		}
		&.toaster-open {
			box-shadow: $box-shadow;
			bottom: 0 !important;
			transition: bottom 0.5s;
		}

		.svg {
			font-size: 21px;
			top: 3px;
		}
	}
</style>
