/*
- Truncate Running Head
-- running heads can only be 50 characers long, and also can't break in the middle of a word
-- 2022.09.22
*/

export default (title) => {
	// it can't exceed 50 chars, and it cannot end in the middle of a word
	let runningHead = "";
	let _title = title.trim();

	if (_title.length < 51) {
		runningHead = title;

	} else {
		// if the 51st character is a space, then grab the first 50
		if (_title.substring(50, 51) == " ") {
			runningHead = _title.substring(0, 50);

		} else {
			let thisChar = "";
			let counter = 50;

			// find an space
			do {
				if (counter == -1) {
					break;
				}

				thisChar = _title.substring(counter - 1, counter);
				counter = counter - 1;
			} while (thisChar != " ");

			if (counter == -1) {
				// no spaces and greater than 50
				runningHead = _title.substring(0, 50);
			} else {
				runningHead = _title.substring(0, counter + 1);
			}
		}//e:if:_title.substring
	}//e:if:_title.length < 51
	
	return runningHead.toUpperCase();
}