/*
- Local Version
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.localVersion.x
*/
import _has from 'lodash/has';
import ErrS_GenericAlert from '@/services/app/error/generic-alert.js';
import LzString from 'lz-string';
import PS_BuildHtml from '@/services/paper/buildHtml';
import store from '@/store';

// const stopTypingMs = 10 * 1000;	// 10s
const stopTypingMs = 5000;	// 5s

export default {
	namespaced: true,
	
	state:{
		isForceSaveAfterLoading: false,
		isLocalStorageFresh: false,
		stoUserStoppedTypingLocal: null,
	},//e:state

	mutations: {
		USER_TYPE_LOCAL_TIMER_CANCEL(state){
			clearTimeout(state.stoUserStoppedTypingLocal);
			state.stoUserStoppedTypingLocal = null;
		},
		USER_TYPE_LOCAL_TIMER_START(state){
			if(state.stoUserStoppedTypingLocal){
				clearTimeout(state.stoUserStoppedTypingLocal);
			}
			state.stoUserStoppedTypingLocal = setTimeout(()=>{
				store.dispatch('paperEdit/localVersion/checkIn');
			}, stopTypingMs);
		},
		SET_IS_FORCE_SAVE_AFTER_LOADING(state, value){
			state.isForceSaveAfterLoading = value;
		},
		SET_IS_LOCAL_STORAGE_FRESH(state, value){
			state.isLocalStorageFresh = value;
		},
		
	},//e:mutations

	actions:{
		checkIn({commit}) {
			return new Promise((resolve) => {

				const openRequest = indexedDB.open('po_papers', 2);

				// triggers if the client had no database
				openRequest.onupgradeneeded = () => {
					let db = openRequest.result;

					if(!db.objectStoreNames.contains('papers')){
						db.createObjectStore('papers', {
							autoIncrement: true,	// people online says this will fix an error i'm seeing in rollbar - i can't replicate to error to verify, changing this doesn't seem to matter locally
							keyPath: 'paperId',
						});
					}
				};//e:onupgradeneeded

				openRequest.onsuccess = () => {
					// get document content 
					PS_BuildHtml({
						updateMetaTags: false,
					}).then((buildHtmlResponseObj)=>{
						// console.log(buildHtmlResponseObj);
						// console.log('localVersion checkIn');

						// if there is NOT a paperId or if the paperId is set to 0 then skip this step
						if(_has(buildHtmlResponseObj, 'paperId') && buildHtmlResponseObj.paperId !== 0){
							
							let compressedPaperContent = LzString.compressToUTF16(buildHtmlResponseObj.returnHtml);

							let db = openRequest.result;

							if(db.objectStoreNames.contains('papers')){
								const txn = db.transaction(['papers'], 'readwrite');
								const store = txn.objectStore('papers');
								
								let request = store.openCursor(buildHtmlResponseObj.paperId);

								request.onsuccess = () => {
									let createdDateObj = new Date();
									createdDateObj.setDate(createdDateObj.getDate());

									commit('SET_IS_LOCAL_STORAGE_FRESH', true);
	
									store.put({
										content: compressedPaperContent,
										createdDate: createdDateObj.toISOString(),
										isFresh: false,
										paperId: buildHtmlResponseObj.paperId
									});
								};//e:onsuccess
	
								txn.oncomplete = () => {
									db.close();
								};
							}//e:if
								
						}//e:if

					}).catch((errorObj)=>{
						ErrS_GenericAlert({
							enum: errorObj.enum,
							src: errorObj.src
						});
					});//e:PS_BuildHtml
					
				};//e:onsuccess

				resolve();
			});//e:Promise
		},//e:checkIn

		clearAll() {
			return new Promise((resolve) => {
			
				const openRequest = indexedDB.open('po_papers', 2);

				openRequest.onsuccess = () => {
					let db = openRequest.result;
					
					if(db.objectStoreNames.contains('papers')){
						const txn = db.transaction(['papers'], 'readwrite');
						const store = txn.objectStore('papers');

						store.clear();

						window.$vm.emitter.emit('globalToasterOpen',{
							textContent: 'IndexedDB po_papers clear',
						});

						txn.oncomplete = () => {
							db.close();
						};
					}

				};//e:onsuccess

				resolve();
			});//e:Promise
		},//e:clearAll

	},//e:actions
}
