<!--
- PERRLA.com Layout > Header
-->
<template>
	<nav class="nav-header">
		<a
			class="logo-link ps-3 me-4"
			title="PERRLA, LLC"
			:href="calcHomeUrl">
			<img src="///perrla.s3.amazonaws.com/images/logo-p-small.png" class="me-2" alt="PERRLA, LLC" />
			<span>PERRLA</span>
			<h1>PERRLA</h1>
		</a>
	

		<ul class="navbar-nav me-auto d-none d-sm-flex">
			<li class="nav-item">
				<a href="///perrla.com/#/how-it-works" class="nav-link">How it works</a>
			</li>
			<li class="nav-item">
				<a href="///perrla.com/#/why-perrla" class="nav-link">Why PERRLA</a>
			</li>
			<li class="nav-item">
				<a href="///perrla.com/#/pricing" class="nav-link">Pricing</a>
			</li>
			<li class="nav-item">
				<a href="https://perrla.zendesk.com/hc/en-us" class="nav-link">Help</a>
			</li>
		</ul>
		
	</nav>

</template>

<script>

export default {
	name: 'PcomHeader',
	computed:{
		calcHomeUrl(){
			if(this.$isLive){
				return '///perrla.com/#/';
			} else {
				return '///test.perrla.com/#/';
			}
		},
	},
}
</script>


<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";

	.nav-header {
		align-items: center;
		background: $body-bg;
		border-bottom: 3px solid $gray-800;
		display: flex;
		height: $pcom-navbar-height;
		z-index: 105;

		// eventually take this out when every page can inner scroll
		left: 0;
		position: fixed;
		right: 0;
		top: 0;

		.logo-link {
			display: flex;
			justify-content: flex-start;
			text-decoration: none;

			img {
				margin-top: calc(($pcom-navbar-height - ($pcom-navbar-height * 0.7)) / 2);
				height: $pcom-navbar-height * 0.7;
				width: $pcom-navbar-height * 0.7;
			}

			// pre-logo text
			span {
				color: $body-bg;
				font-size: 0px;
				visibility: hidden;
			}

			h1 {
				margin-bottom:0;
				color: $body-color;
				font-size: 2rem;
				font-weight: 800;
				line-height: $pcom-navbar-height;
			}

			&:hover {
				h1 {
					color: tint-color($body-color, $btn-active-bg-tint-amount),
				}
			}
		}//e:.logo-link

		.navbar-nav {
			flex-direction: row;

			> li > a {
				border-bottom: 6px solid transparent;
				color: $body-color;
				padding: 1rem;
				font-size: 1.2rem;
				line-height: 21px;
				font-weight: $font-weight-bold;
				text-decoration: none;
				transition: all 150ms ease-in-out;

				&:hover{
					border-bottom-color: $primary;
					color: $primary;
				}
				&:active {
					background: $primary;
					color:$body-bg;
				}
			}

		}

	}//.nav-header

	// .side-menu-trigger {
	// 	color:$body-color;
	// 	.svg {
	// 		font-size:36px;
	// 	}
	// }
</style>
