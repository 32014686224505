/*
- Citations
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.citations.x
*/
import _forEach from 'lodash/forEach';
import config from '@/config';
import Engine from '@/services/reference/Engine';

export default {
	namespaced: true,
	
	state:{
		inPaper: [],		// Array - each item is a citationResult Object that contains everythign to rebuild the citation
		tempCitationData: {},	// Object - temporary place to hold citation data while it's being edited or created
		// toUpdateAfterSync: [],	 // array of references to update the citations for (if refs change in the word add in the citations will not be updated in PO.com)
		
		elementInPaper: null,	// should be the citation widget inside the CkInstance 

	},//e:state

	mutations: {
		ADD_GROUP_UID_AT_INDEX(state, $opts){
			// console.log('ADD_GROUP_UID_AT_INDEX');
			// console.log($opts);
			state.inPaper[$opts.findIndex].groupUniqueID = $opts.groupUniqueID;
			state.inPaper[$opts.findIndex].createOrder = $opts.createOrder;
		},
		ADD_TO_PAPER(state, value){
			state.inPaper.push(value);
		},
		// ADD_TO_UPDATE_AFTER_SYNC(state, value){
		// 	state.toUpdateAfterSync.push(value);
		// },
		// fires when references were updated in the add-in and the citations need to be updated here in the paper content
		REFRESH_AT_INDEX(state, $opts){
			let currentCitationData = state.inPaper[$opts.findIndex];

			let updatedCitationData = Engine.getCitation($opts.updatedReference.referenceTypeID, JSON.parse($opts.updatedReference.data), currentCitationData.citationData);

			// Replace Citation HTML Content (first of subsequent)
			if($opts.$thisCitationEl){
				if ($opts.isFirstUse) {
					// First
					if(window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						$opts.$thisCitationEl.setHtml(updatedCitationData.apa7.first);
					} else if(window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						$opts.$thisCitationEl.setHtml(updatedCitationData.mla9.first);
					}
				} else {
					// Subsequent
					if(window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						if (updatedCitationData.apa7.subsequent === '') {
							$opts.$thisCitationEl.setHtml(updatedCitationData.apa7.first);
						} else {
							$opts.$thisCitationEl.setHtml(updatedCitationData.apa7.subsequent);
						}
					} else if(window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						if (updatedCitationData.mla9.subsequent === '') {
							$opts.$thisCitationEl.setHtml(updatedCitationData.mla9.first);
						} else {
							$opts.$thisCitationEl.setHtml(updatedCitationData.mla9.subsequent);
						}
					}
				}
			}//e:thisCitationData
		},//e:REFRESH_AT_INDEX
		REMOVE_AT_INDEX(state, findIndex){
			state.inPaper.splice(findIndex, 1);
		},
		REMOVE_GROUP_UID_AT_INDEX(state, findIndex){
			delete state.inPaper[findIndex].groupUniqueID;
		},

		SET_ELEMENT_IN_PAPER(state, value) {
			state.elementInPaper = value;
		},
		SET_IN_PAPER(state, value) {
			state.inPaper = value;
		},
		SET_TEMP_CITATION_DATA(state, value) {
			state.tempCitationData = value;
		},
		UPDATE_AT_INDEX(state, $opts){
			// does this citation have a group id currently?
			if(state.inPaper[$opts.findIndex].groupUniqueID){
				// Grouped already - carry that over
				let carryOverGroupUniqueID = state.inPaper[$opts.findIndex].groupUniqueID;
				state.inPaper[$opts.findIndex] = $opts.newCitationResult;
				state.inPaper[$opts.findIndex].groupUniqueID = carryOverGroupUniqueID;
			} else {
				// NOT Grouped
				state.inPaper[$opts.findIndex] = $opts.newCitationResult;
			}
		},
		
	},//e:mutations

	actions:{
		setCitationElementIfActive({commit}, $opts) {
			return new Promise((resolve) => {
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					if(ckInstance.document){
						// look for group first
						let returnElement = ckInstance.document.findOne('span[data-group-unique-id="' + $opts.findById + '"]');

						if(!returnElement){
							// if not found check for single
							returnElement = ckInstance.document.findOne('span[data-citation-unique-id="' + $opts.findById + '"]');
						}

						if(returnElement){
							commit('SET_ELEMENT_IN_PAPER', returnElement);
							return false; // stop looping, i only need one element
						}
					}
				});//e:forEach

				resolve();
			});//e:Promise
		},//e:assembleObject
	}
}
