<!--
- Paper Edit > Write Tab > Footer of Paper
- For now shows word count
- don't confuse this with PageFooter.vue which is for Turabian 9
-->

<template>
	<div :class="['paper-editor-footer', {'paper-editor-footer-tool-drawer-open': $store.state.paperEdit.isDrawerOpen}]">
        <button type="button" class="btn btn-light btn-sm d-block mx-auto mt-1" @click.prevent="openWordCountModal">
			{{ wordCount }} Words ({{ sectionDisplay }})
		</button>
    </div>
</template>

<script lang="js">
import _forEach from 'lodash/forEach';
import config from '@/config';
import WordCount from '@/helpers/word-count';

export default {
	name: 'PaperFooter',
	data() {
		return {
			sectionDisplay: 'Body',	// will display Body or Abstract
			wordCount: 0,
		}
	},
	methods: {
		openWordCountModal(){
			this.$store.commit('modals/OPEN', 'WordCount');
		},//e:openWordCountModal

		updateWordCount(editorName){
			let ckInstance = CKEDITOR.instances[editorName];
			
			if(!ckInstance){
				return false;
			}

			// get which editor so i can update the name
			switch(ckInstance.$ckEditorType){
				case config.enums.CkEditorType.ABSTRACT:
					this.sectionDisplay = 'Abstract';
					this.wordCount = WordCount(ckInstance.getData());
					break;
				case config.enums.CkEditorType.ANNOTATION:
					this.sectionDisplay = 'ANNOTATIONS';

					// annotations get a cumulative count
					this.wordCount = 0;

					// loop through all ck editors
					_forEach(CKEDITOR.instances, (thisCkInstance)=>{
						if(thisCkInstance.$ckEditorType === config.enums.CkEditorType.ANNOTATION){
							this.wordCount += WordCount(thisCkInstance.getData());	
						}
					});
					break;
				case config.enums.CkEditorType.APPENDIX:
					this.sectionDisplay = ckInstance.$appendixLabel;
					this.wordCount = WordCount(ckInstance.getData());
					break;
				case config.enums.CkEditorType.BODY:
					this.sectionDisplay = 'BODY';
					this.wordCount = WordCount(ckInstance.getData());
					break;
			}
		},
	},
	created() {
		// emits from paper abstract or body > ck editor > on editor focus
        this.emitter.on('footerUpdateWordCount', ($opts) => {
			this.updateWordCount($opts.editorName);
		});
		
		// emits from paper abstract or body > ck instance > on change
        this.emitter.on('userStoppedTyping', ($opts) => {
			this.updateWordCount($opts.editorName);
		});//e:initDocumentEditor
		
	},
	destroyed(){
		this.emitter.off('footerUpdateWordCount');
		this.emitter.off('userStoppedTyping');
	},
}
</script>
