<!--
- Paper Editor > Ribbon
-->

<template>
	<div class="rb-wrp clearfix">
		
		<div class="rb float-start">
			<router-link
				:class="['rb-btn',{'rb-btn-active': $route.name === 'EditorTitlePage'}]"
				:to="{name:'EditorTitlePage'}">
				<div class="rb-icon rb-icon-title-page" />
				Title
				<br />
				Page
			</router-link>

			<div class="rb-block">
				<router-link
					v-if="documentType == $enums.DocumentType.RESEARCH_PAPER"
					:class="['rb-btn rb-btn-three',{'rb-btn-active': $route.name === 'EditorAbstract'}]"
					:to="{ name: 'EditorAbstract' }">
					<span class="rb-icon rb-icon-abstract" /> Abstract
				</router-link>
				<div v-else class="rb-btn rb-btn-disabled rb-btn-three">
					<span class="rb-icon rb-icon-abstract" /> Abstract
				</div>

				<router-link
					v-if="documentType == $enums.DocumentType.RESEARCH_PAPER"
					:class="['rb-btn rb-btn-three',{'rb-btn-active': $route.name === 'EditorTableOfContents'}]"
					:to="{ name: 'EditorTableOfContents' }">
					<span class="rb-icon rb-icon-toc" /> Table of Contents
				</router-link>
				<div v-else class="rb-btn rb-btn-disabled rb-btn-three">
					<span class="rb-icon rb-icon-toc" /> Table of Contents
				</div>

				<router-link
					v-if="documentType == $enums.DocumentType.RESEARCH_PAPER"
					:class="['rb-btn rb-btn-three',{'rb-btn-active': $route.name === 'EditorAppendices'}]"
					:to="{ name: 'EditorAppendices' }">
					<span class="rb-icon rb-icon-appendix" /> Appendix
				</router-link>
				<div v-else class="rb-btn rb-btn-disabled rb-btn-three">
					<span class="rb-icon rb-icon-appendix" /> Appendix
				</div>
			</div>
			
			<div class="rb-divider" />

			<router-link
				:class="['rb-btn',{'rb-btn-active': $route.name === 'EditorReferencesCitations'}]"
				:to="{ name: 'EditorReferencesCitations' }">
				<div class="rb-icon rb-icon-references" />
				References &amp;
				<br />
				Citations
			</router-link>

			<div class="rb-block">
				<router-link
					:class="['rb-btn rb-btn-two',{'rb-btn-active': $route.name === 'EditorResearchNotes'}]"
					:to="{ name: 'EditorResearchNotes' }">
					<div class="rb-icon rb-icon-research-notes" />
					Research Notes
				</router-link>

				<router-link
					:class="['rb-btn rb-btn-two',{'rb-btn-active': $route.name === 'EditorOutline'}]"
					:to="{ name: 'EditorOutline' }">
					<span class="rb-icon rb-icon-outline" />
					Outline
				</router-link>
			</div>
	
			<div class="rb-divider" />

			<ribbon-btn-heading-styles />

			<router-link
				v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER"
				:class="['rb-btn rb-btn-three',{'rb-btn-active': $route.name === 'EditorInsertFigure'}]"
				:to="{ name: 'EditorInsertFigure' }">
				<span class="rb-icon rb-icon-figure" />
				Insert<br />Figure
			</router-link>
			<div v-else class="rb-btn rb-btn-disabled rb-btn-three">
				<span class="rb-icon rb-icon-figure" /> Insert<br />Figure
			</div>


			<router-link
				v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER"
				:class="['rb-btn rb-btn-three',{'rb-btn-active': $route.name === 'EditorInsertTable'}]"
				:to="{ name: 'EditorInsertTable' }">
				<span class="rb-icon rb-icon-table" />
				Insert<br />Table
			</router-link>
			<div v-else class="rb-btn rb-btn-disabled rb-btn-three">
				<span class="rb-icon rb-icon-table" /> Insert<br />Table
			</div>
	
			<div class="rb-divider" />

			<router-link
				:class="['rb-btn rb-btn-two',{'rb-btn-active': $route.name === 'EditorPaperReview'}]"
				:to="{ name: 'EditorPaperReview' }">
				<span class="rb-icon rb-icon-paper-review" />
				Paper<br />Review
			</router-link>

			<router-link
				:class="['rb-btn rb-btn-two',{'rb-btn-active': $route.name === 'EditorCreateCleanCopy'}]"
				:to="{ name: 'EditorCreateCleanCopy' }">
				<span class="rb-icon rb-icon-create-clean-copy" />
				Create<br />Clean Copy
			</router-link>

			<div class="rb-divider" />

			<a href="https://perrla.zendesk.com/hc/en-us/categories/360000330433-PERRLA-Online" target="_blank" rel="noopener noreferrer" class="rb-btn">
				<div class="rb-icon rb-icon-help" />
				Help
			</a>

			<div class="rb-divider" />

		</div> <!--rb-left-->

		<ck-toolbar editor-type="document" />
		
	</div>
</template>

<script>
import CkToolbar from '@/components/CkToolbar';
import RibbonBtnHeadingStyles from './RibbonBtnHeadingStyles';

export default {
	name: 'RibbonComponent',
	computed:{
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
	},
	components:{
		CkToolbar,
		RibbonBtnHeadingStyles,
	},
}
</script>
