<!--
- Cart Layout > Header

-->
<template>
	<header>
		<nav class="navbar navbar-expand d-flex fixed-top d-print-none">
			<div class="leego-lynk ms-2 me-2">
				<img src="///perrla.s3.amazonaws.com/images/logo-p-small.png" class="leego-img me-2" alt="PERRLA, LLC" />
				<span class="prego-text">PERRLA</span>
				<span class="leego-text">PERRLA</span>
			</div>

			<ul class="navbar-nav ms-auto" v-if="showUserDropdown">
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<span id="userFullName">
							{{ $store.state.customer.fullName }}
						</span>
					</a>
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<router-link
								:to="{
									name: 'MyProfile'
								}"
								class="dropdown-item"
								key="settingsElse">
								Settings
							</router-link>
						</li>
						<li>
							<router-link :to="{name:'Logout'}" class="dropdown-item">
								Logout
							</router-link>
						</li>
					</ul>
				</li>
			</ul>

		</nav>
	</header>
</template>

<script>

export default {
	name: 'CartHeader',
	computed:{
		showUserDropdown(){
			if(this.$store.state.api.token !== ''){
				return true;
			}
			return false;
		}
	}
}
</script>


<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";
	
	.navbar {
		background: #fff;
		border-bottom: 3px solid $gray-800;
		height: $pcom-navbar-height;

		.nav-link {
			margin-right: 4px;
			margin-left: 4px;
			padding: 18px 12px 16px 12px;
			border-bottom: 6px solid transparent;
			-webkit-transition: all 150ms ease-in-out;
			transition: all 150ms ease-in-out;
			color: $gray-800;
			font-size: 1.2rem;
			line-height: 1.2rem;
			font-weight: $font-weight-bold;
			text-decoration: none;
		}

		// Current (active)
		ul.navbar-nav li.nav-item.active a.nav-link {
			border-bottom-color: $brand-500;
			color: $brand-500;
		}

		// Hover
		ul.navbar-nav li.nav-item a.nav-link:hover{
			border-bottom-color: $primary;
			color: $primary;
		}
		// Active (during a click)
		ul.navbar-nav li.nav-item a.nav-link:active {
			background: $primary;
			color:#fff;
		}
		
		// style dropdown for logged in users
		.navbar-nav > li.nav-item > a.nav-link {
			border-bottom: none;
			color: $body-color;

			&:active, &:hover  {
				background: $gray-400 !important;
				color: $body-color !important;
			}

			&.dropdown-trigger-icon {
				height: $po-navbar-height;
				
				span.svg {
					display:block;
					font-size: 1.5rem;
				}
			}
		} //.navbar-nav
		
	}

	.leego-lynk {
		display: block;
		line-height: 70px;

		.leego-img {
			height: 64px;
			float:left;
			margin-top: 6px;
			width: 64px;
		}
		.leego-text {
			margin-bottom:0;
			color: $body-color;
			float:left;
			font-size: 2rem;
			font-weight: 800;
		}

		.prego-text {
			color: $white;
			font-size: 1px;
			visibility: hidden;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.side-menu-trigger {
		color:$body-color;
		.svg {
			font-size:36px;
		}
	}
</style>
