<!--
- PaperList > Route Modal > Create Annotated Bibliography
-->
<template>
	<div>
		<module-create-annotated-bibliography />
	</div>
</template>

<script>
import ModuleCreateAnnotatedBibliography from '@/modules/paper/CreateAnnotatedBibliography'

export default {
	name: 'PaperListCreateAnnotatedBibliography',
	components: {
		ModuleCreateAnnotatedBibliography
	}
}
</script>
