<!--
- Class List > Row
-- clickable title to open a document for editing
-->
<!--  -->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-class']" :color-hover="classDataColorValue" :style="calcHoverClassColorStyle">
		<td class="td-entity-title cursor-pointer" title="Open Class" @click.prevent="gotoGridElement">
			{{ classDataName }} <span v-if="classDataIsArchived" class="badge">Archived</span>
		</td>
		<td v-if="context === 'ClassList'" class="cursor-pointer" @click.prevent="gotoGridElement" :style="calcClassColorStyle">
			{{ classDataTeacherName }}
		</td>
		<td class="td-more-menu">
			<div class="dropstart">
				<a href="#" :class="['svg bi-three-dots-vertical float-end']" data-bs-toggle="dropdown" aria-expanded="false" aria-label="More Options" />
				<ul class="dropdown-menu">
					<li>
						<a href="#" class="dropdown-item hover hover-primary" @click.prevent="gotoGridElement">
							Open Class
						</a>
						<a href="#" class="dropdown-item hover hover-danger" @click.prevent="btnToggleIsArchived">
							<span v-if="classDataIsArchived" key="spanRestoreClass">
								Restore
							</span>
							<span v-else key="spanArchiveClass">
								Archive
							</span>
							Class
						</a>
					</li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import ClassColorParse from '@/helpers/class-color-parse';

export default {
	name: 'ClassRow',
	props:{
		classDataColorValue:{
			type: String,
		},
		classDataEncryptedId:{
			required: true,
			type: String,
		},
		classDataName:{
			required: true,
			type: String,
		},
		classDataId:{
			required: true,
			type: Number,
		},
		classDataIsArchived:{
			default: false,
			type: Boolean,
		},
		
		classDataTeacherName:{
			type: String,
		},
		context:{
			type: String,
		},
	},
	data() {
		return {

		}
	},
	computed:{
		calcClassColorStyle(){
			return ClassColorParse({
				'in': this.classDataColorValue,
				'isOutBlock': false,
			});
		},
		calcHoverClassColorStyle(){
			return {
				// background: '#ff0000'
			}
		},
	},
	methods: {
		btnToggleIsArchived(){
			let url = 'v4/Class/Archive'
			if(this.classDataIsArchived){
				// class already archived - use the Restore api
				url = 'v4/Class/Restore';
			}
			ApiS_MakeCall({
				params:{
					'id': this.classDataId,
				},
				method: 'PUT',
				url,
			}).then(()=>{
				this.emitter.emit('updateClassList');
			});
		},//e:btnToggleIsArchived
		
		gotoGridElement(){
			if(this.$store.state.app.showAlphaFeatures){
				this.$router.push({
					name: 'ClassEdit',
					params:{
						encryptedId: this.classDataEncryptedId,
					},
				}).catch(()=>{});
			} else {
				if(this.$isDev){
					alert("Classes can't be loaded locally");
				} else {
					window.location = '/Classes/Edit/' + this.classDataEncryptedId;
				}
			}
		},//e:gotoGridElement
	},
}
</script>

<style lang="scss" scoped>
	/*
	-- the below is sloppy and reduntant but it's the best way to handle color shades since old color values can still mix in with the new ones
	-- i promise i'll steamline all this when Class Create is all inside the SPA
	*/
	@import "../../assets/styles/vars_perrla";
	tr.tr-entity-grid-class{
		&[color-hover="BLUE"]:hover td, &[color-hover="0000bb"]:hover td, &[color-hover="000066"]:hover td {
			background: $class-color-blue-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-blue-block, $btn-hover-bg-shade-amount)
			}
		}
		&[color-hover="GREEN"]:hover td, &[color-hover="00bb00"]:hover td, &[color-hover="006600"]:hover td {
			background: $class-color-green-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-green-block, $btn-hover-bg-shade-amount)
			}
		}
		&[color-hover="NONE"]:hover td, &[color-hover="bbbbbb"]:hover td, &[color-hover="666666"]:hover td, &[color-hover="bb00bb"]:hover td, &[color-hover="660066"]:hover td {
			background: $class-color-none-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-none-block, $btn-hover-bg-shade-amount)
			}
		}
		&[color-hover="RED"]:hover td, &[color-hover="bb0000"]:hover td, &[color-hover="660000"]:hover td, &[color-hover="bb5500"]:hover td, &[color-hover="885500"]:hover td {
			background: $class-color-red-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-red-block, $btn-hover-bg-shade-amount)
			}
		}
		&[color-hover="TEAL"]:hover td, &[color-hover="00bbbb"]:hover td, &[color-hover="006666"]:hover td {
			background: $class-color-teal-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-teal-block, $btn-hover-bg-shade-amount)
			}
		}
		&[color-hover="YELLOW"]:hover td, &[color-hover="bbbb00"]:hover td, &[color-hover="666600"]:hover td {
			background: $class-color-yellow-block;
			color: $body-color !important;
			.bi-three-dots-vertical:hover  {
				background: darken($class-color-yellow-block, $btn-hover-bg-shade-amount)
			}
		}
	}
</style>