var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    { attrs: { "is-no-padding": true } },
    [
      _c("editor-filebar"),
      _vm._v(" "),
      _c("editor-ribbon"),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "paperDocumentOut",
          staticClass: "float-start w-100",
          style: _vm.calcPaperDocumentOutStyles,
        },
        [
          _c(
            "div",
            {
              ref: "paperDocumentIn",
              class: _vm.calcPaperDocumentClasses,
              style: _vm.calcPaperDocumentInStyles,
              attrs: { id: "paperDocumentIn" },
            },
            [
              _vm.$store.state.customer.citationAutoInsert
                ? _c("citation-context-menu")
                : _vm._e(),
              _vm._v(" "),
              _vm.paperFormatVersionID === _vm.$enums.Format.APA7
                ? _c(
                    "div",
                    { key: "formatAPA7" },
                    [
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-title-page")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-abstract")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-toc")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType ==
                        _vm.$enums.DocumentType.DISCUSSION_POST ||
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-body")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType !=
                      _vm.$enums.DocumentType.DISCUSSION_POST
                        ? _c("paper-reference-page")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-appendices")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.paperFormatVersionID === _vm.$enums.Format.MLA9
                ? _c(
                    "div",
                    { key: "formatMLA9" },
                    [
                      _vm.documentType ==
                        _vm.$enums.DocumentType.DISCUSSION_POST ||
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "page-group page-group-min-height page-group-mla9",
                            },
                            [
                              _vm.documentType ==
                              _vm.$enums.DocumentType.RESEARCH_PAPER
                                ? _c("paper-title-page")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.documentType ==
                              _vm.$enums.DocumentType.RESEARCH_PAPER
                                ? _c("paper-abstract")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.documentType ==
                              _vm.$enums.DocumentType.RESEARCH_PAPER
                                ? _c("paper-toc")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.documentType ==
                                _vm.$enums.DocumentType.DISCUSSION_POST ||
                              _vm.documentType ==
                                _vm.$enums.DocumentType.RESEARCH_PAPER
                                ? _c("paper-body")
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType !=
                      _vm.$enums.DocumentType.DISCUSSION_POST
                        ? _c("paper-reference-page")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-appendices")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.paperFormatVersionID === _vm.$enums.Format.Turabian9
                ? _c(
                    "div",
                    { key: "formatTurabian9" },
                    [
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-title-page")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-abstract")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-toc")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType ==
                        _vm.$enums.DocumentType.DISCUSSION_POST ||
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-body")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                        ? _c("paper-appendices")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentType !=
                      _vm.$enums.DocumentType.DISCUSSION_POST
                        ? _c("paper-reference-page")
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("tool-drawer", { attrs: { "is-editor": true } }),
      _vm._v(" "),
      _vm.$store.state.paperEdit.isPaperFooterOn
        ? _c("paper-footer")
        : _vm._e(),
      _vm._v(" "),
      _c("modal-page-count"),
      _vm._v(" "),
      _c("modal-paper-source"),
      _vm._v(" "),
      _c("modal-replace-figure"),
      _vm._v(" "),
      _c("modal-stale-paper"),
      _vm._v(" "),
      _c("modal-word-count"),
      _vm._v(" "),
      _c("paper-footnotes-dropdown"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }