var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-settings-wrp" }, [
    _c("div", { staticClass: "boxset-wrp pt-4" }, [
      !_vm.$isLive
        ? _c("div", { staticClass: "alert alert-danger" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("h1", [_vm._v("Payment")]),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-3" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6 col-divider-right mt-2" },
          [
            _c(
              "transition",
              { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
              [
                _vm.isShowCurrentPaymentMethod
                  ? _c("div", { key: "divShowPaymentMethod" }, [
                      _c("h3", { staticClass: "mt-0" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCurrent Payment Method\n\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-borderless table-cart-totals",
                        },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-end",
                                  attrs: { width: "200px" },
                                },
                                [
                                  _c("h4", { staticClass: "text-body m-0" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\tPayment Info\n\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "fw-light text-start" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.truncatePaymentSourceNumber) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "text-end" }, [
                                _c("h4", { staticClass: "text-body m-0" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t\tExpiration Date\n\t\t\t\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "fw-light text-start" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.calcExpirationDateOnCurrentPaymentMethod
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-grid" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.isHasPaymentMethodEnterNew = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t+ Enter a new payment method\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      { key: "divEnterPaymentMethod" },
                      [
                        _c("module-payment-settings"),
                        _vm._v(" "),
                        _vm.isHasPaymentMethodEnterNew
                          ? _c("div", { staticClass: "d-grid mt-3" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-danger border-0 text-start",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.isHasPaymentMethodEnterNew = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tUse existing payment method\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6 mt-2 ps-3" },
          [
            _c("transaction-preview", {
              attrs: { "is-priority-support-only": true },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-grid gap-2 mb-2" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnProcessPayment.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\tPurchase Priority Support\n\t\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline-danger",
                    attrs: {
                      to: {
                        name: "AccountBilling",
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\tCancel - don't add Priority Support\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "All charges are in US dollars. Payment will be processed today."
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fw-bold p-2" }, [
      _vm._v(
        "\n\t\t\t\tThis is testing environment, to make a purchase, please visit: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.perrlacomplete.com/Public/#/cart-step-1",
          },
        },
        [_vm._v("https://www.perrlacomplete.com/Public/#/cart-step-1")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }