var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "text-center" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.pageNumberOutput) + "\n\t"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }