var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["form-group", { row: _vm.isHorizontal }] }, [
    _c(
      "label",
      {
        class: [{ "col-sm-3 col-form-label": _vm.isHorizontal }],
        attrs: { for: _vm.safeId },
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t")]
    ),
    _vm._v(" "),
    _vm.helpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.helpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: { "col-sm-9": _vm.isHorizontal } }, [
      _c("textarea", {
        ref: "focusElement",
        class: ["form-control"],
        attrs: {
          id: _vm.safeId,
          "pendo-grab-text": _vm.pendoGrabText,
          placeholder: _vm.placeholder,
          rows: _vm.calcRows,
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.typeInput($event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }