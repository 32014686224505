var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeAllMenus,
          expression: "closeAllMenus",
        },
      ],
      staticClass: "navbar navbar-expand filebar",
      class: [{ "filebar-hover": _vm.isActive }],
    },
    [
      _c("ul", { staticClass: "navbar-nav" }, [
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.FILE,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.FILE)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.FILE }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.FILE)
                  },
                },
              },
              [_vm._v("File")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.FILE },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("saveOutline")
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.printDocument.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Print")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "PaperTabOverview" } },
                  },
                  [_vm._v("\n\t\t\t\t\t\tView recent saves\n\t\t\t\t\t")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.EDIT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.EDIT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.EDIT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.EDIT)
                  },
                },
              },
              [_vm._v("Edit")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.EDIT },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("undo")
                      },
                    },
                  },
                  [_vm._v("Undo")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("redo")
                      },
                    },
                  },
                  [_vm._v("Redo")]
                ),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.configUseWProofreader,
                      expression: "configUseWProofreader",
                    },
                  ],
                  staticClass: "dropdown-divider",
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.configUseWProofreader,
                        expression: "configUseWProofreader",
                      },
                    ],
                    class: [
                      "dropdown-item",
                      { disabled: !_vm.isWproofReaderActive },
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenProofreader("ckOutline")
                      },
                    },
                  },
                  [_vm._v("PERRLA Spell Check")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.configUseWProofreader,
                        expression: "configUseWProofreader",
                      },
                    ],
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddToggleProofreader.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isWproofReaderActive,
                            expression: "isWproofReaderActive",
                          },
                        ],
                      },
                      [_vm._v("Disable")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isWproofReaderActive,
                            expression: "!isWproofReaderActive",
                          },
                        ],
                      },
                      [_vm._v("Enable")]
                    ),
                    _vm._v("\n\t\t\t\t\t\tPERRLA Spellcheck\n\t\t\t\t\t"),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.VIEW,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.VIEW)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.VIEW }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.VIEW)
                  },
                },
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.VIEW },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "OutlineReferencesCitations" } },
                  },
                  [_vm._v("\n\t\t\t\t\t\tReference & Citations\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "OutlineResearchNotes" } },
                  },
                  [_vm._v("\n\t\t\t\t\t\tResearch Notes\n\t\t\t\t\t")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.FORMAT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.FORMAT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.FORMAT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.FORMAT)
                  },
                },
              },
              [_vm._v("Format")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.FORMAT },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("bold")
                      },
                    },
                  },
                  [_vm._v("Bold")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("italic")
                      },
                    },
                  },
                  [_vm._v("Italic")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("underline")
                      },
                    },
                  },
                  [_vm._v("Underline")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("strike")
                      },
                    },
                  },
                  [_vm._v("Strikethrough")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("superscript")
                      },
                    },
                  },
                  [_vm._v("Superscript")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("subscript")
                      },
                    },
                  },
                  [_vm._v("Subscript")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifyleft")
                      },
                    },
                  },
                  [_vm._v("Align Left")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifycenter")
                      },
                    },
                  },
                  [_vm._v("Align Center")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("justifyright")
                      },
                    },
                  },
                  [_vm._v("Align Right")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddCkExec("removeFormat")
                      },
                    },
                  },
                  [_vm._v("Remove Format")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.INSERT,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.INSERT)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [{ show: _vm.openMenuEnum === _vm.menuEnums.INSERT }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.INSERT)
                  },
                },
              },
              [_vm._v("Insert")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.INSERT },
                ],
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.ddOpenSpecialCharactersModal.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Special Characters")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: [
              "nav-item",
              {
                dropdown: !_vm.isReadOnly,
                "dropdown-hover": _vm.openMenuEnum === _vm.menuEnums.REFERENCES,
              },
            ],
            on: {
              mouseenter: function ($event) {
                return _vm.mouseEnterMenu(_vm.menuEnums.REFERENCES)
              },
            },
          },
          [
            _c(
              "a",
              {
                class: [
                  { show: _vm.openMenuEnum === _vm.menuEnums.REFERENCES },
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleMenu(_vm.menuEnums.REFERENCES)
                  },
                },
              },
              [_vm._v("References")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  { show: _vm.openMenuEnum === _vm.menuEnums.REFERENCES },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to: {
                        name: "OutlineCreateReferenceStep1",
                        params: { openReferenceTab: "common" },
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tCreate New Reference\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "OutlineAddFromLibrary" } },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\tAdd Reference from My Library\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }