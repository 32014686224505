/*
- Parse Document & Format Type
-- takes a paper format id and document type, returns a text formated string
-- 2023.06.21
*/
import config from '@/config';

export default ($opts) => {
	// console.log('ParseDocFormatType');

	let returnString = '';

	switch($opts.paperFormatVersionID){
		case config.enums.Format.APA6:
			if($opts.isFormatOnlyReturn){
				returnString += 'APA 6th Edition';
			} else {
				returnString += 'APA 6 ';				
			}
			break;
		case config.enums.Format.APA7:
			if($opts.isFormatOnlyReturn){
				returnString += 'APA 7th Edition';
			} else {
				returnString += 'APA 7 ';				
			}
			break;
		case config.enums.Format.MLA8:
			if($opts.isFormatOnlyReturn){
				returnString += 'MLA 8th Edition ';
			} else {
				returnString += 'MLA 8 ';
			}
			break;
		case config.enums.Format.MLA9:
			if($opts.isFormatOnlyReturn){
				returnString += 'MLA 9th Edition ';
			} else {
				returnString += 'MLA 9 ';
			}
			break;
		case config.enums.Format.Turabian9:
			if($opts.isFormatOnlyReturn){
				returnString += 'Turabian 9th Edition ';
			} else {
				returnString += 'Turabian 9 ';
			}
			break;
	}

	if(!$opts.isFormatOnlyReturn){
		switch($opts.paperTypeEnum){
			case config.enums.PaperType.APA7_AnnotatedBibliography:
			case config.enums.PaperType.MLA9_AnnotatedBibliography:
			case config.enums.PaperType.Turabian9_AnnotatedBibliography:
				returnString += 'Annotated Bibliography';
				break;
			case config.enums.PaperType.APA_DiscussionPost:
			case config.enums.PaperType.APA7_DiscussionPost:
			case config.enums.PaperType.MLA_DiscussionPost:
			case config.enums.PaperType.MLA9_DiscussionPost:
			case config.enums.PaperType.Turabian9_DiscussionPost:
				returnString += 'Discussion Post';
				break;
			case config.enums.PaperType.APA7_ReferenceList:
			case config.enums.PaperType.MLA9_ReferenceList:
			case config.enums.PaperType.Turabian9_ReferenceList:
				returnString += 'Reference List';
				break;
			case config.enums.PaperType.APA7_StudentPaper:
				returnString += 'Student Research Paper';
				break;
			case config.enums.PaperType.APA7_ProfessionalPaper:
				returnString += 'Professional Research Paper';
				break;
			case config.enums.PaperType.APA_ResearchPaper:
			case config.enums.PaperType.MLA_ResearchPaper:
			case config.enums.PaperType.MLA9_ResearchPaper:
			case config.enums.PaperType.Turabian9_ResearchPaper:
				returnString += 'Research Paper';
				break;
		}
	}

	return returnString;
}