import config from '@/config';

import Dashboard from '@/views/Dashboard';
import DashboardCreateWork from '@/views/Dashboard/modals/CreateWork';

export default [
	{
		name: 'Dashboard',
		component: Dashboard,
		path: '/dashboard',
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.DASHBOARD,
			tab: config.enums.Tab._
		},
		children: [
			{
				name: 'DashboardCreateWork',
				component: DashboardCreateWork,
				path: 'create-work/:workType',
				props: true,
				meta: {
					module: config.enums.Module.DASHBOARD,
					tab: config.enums.Tab._
				},
			},
			

		]
	},
]