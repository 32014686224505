var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _c("label", {
        staticClass: "form-label form-label-v2",
        attrs: { for: _vm.fullId },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-value" }, [
      _c("input", {
        staticClass: "form-control mb-2",
        attrs: {
          autocomplete: "off",
          disabled: "",
          type: "text",
          role: _vm.role,
        },
        domProps: { value: _vm.orgEmail },
      }),
      _vm._v(" "),
      _vm.secondaryEmail != ""
        ? _c("div", { key: "divHasSecondaryEmail" }, [
            _c("input", {
              class: [
                "form-control mb-2",
                { "is-invalid": _vm.isInvalidShown },
              ],
              attrs: {
                autocomplete: "off",
                type: "text",
                id: _vm.fullId,
                role: _vm.role,
              },
              domProps: { value: _vm.secondaryEmail },
              on: {
                blur: function ($event) {
                  return _vm.blurInput($event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.isInvalidShown
              ? _c("div", { staticClass: "invalid-feedback d-block mt-1" }, [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t\t\t"
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { key: "divElseSecondaryEmail" }, [
            _vm.isNewFieldShown
              ? _c("div", { key: "divIsNewFieldShown" }, [
                  _c("input", {
                    ref: "userInputField",
                    class: [
                      "form-control mb-2",
                      { "is-invalid": _vm.isInvalidShown },
                    ],
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Enter a new Email address",
                      type: "text",
                      id: _vm.fullId,
                      role: _vm.role,
                    },
                    domProps: { value: _vm.secondaryEmail },
                    on: {
                      blur: function ($event) {
                        return _vm.blurInput($event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.isInvalidShown
                    ? _c(
                        "div",
                        { staticClass: "invalid-feedback d-block mt-1" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.invalidErrorMessage) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-hover btn-hover-danger",
                        attrs: { type: "button" },
                        on: { click: _vm.btnCloseSecondaryEmail },
                      },
                      [
                        _c("span", { staticClass: "svg bi-x-lg" }),
                        _vm._v(" Cancel\n\t\t\t\t\t"),
                      ]
                    ),
                  ]),
                ])
              : _c("div", { key: "divElseIsNewFieldShown" }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-hover btn-hover-success",
                        attrs: { type: "button" },
                        on: { click: _vm.btnAddSecondaryEmail },
                      },
                      [
                        _c("span", { staticClass: "svg bi-plus-lg" }),
                        _vm._v(" Add Secondary Email\n\t\t\t\t\t"),
                      ]
                    ),
                  ]),
                ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }