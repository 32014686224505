var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.appendicesInPaper, function (appendixData) {
        return _c("appendix-instance", {
          key: appendixData.uid,
          attrs: { "appendix-data": appendixData },
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid" }, [
        _c(
          "button",
          {
            class: [
              "btn btn-success btn-page-group",
              {
                "mb-2":
                  _vm.$store.state.paperEdit.meta.PaperFormatVersionID !=
                  _vm.$enums.Format.Turabian9,
              },
            ],
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addAppendix.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tAdd Appendix\n\t\t")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }