var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.calcInstanceStyles },
      [
        _c("paper-running-head", { attrs: { "page-type": "title" } }),
        _vm._v(" "),
        _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
        _vm.$enums.Format.APA7
          ? _c(
              "a",
              {
                key: "titleWidgetAPA7",
                staticClass: "fake-widget fake-widget-title-page text-center",
                attrs: { href: "#", title: "Click to Edit your Title Page" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openTitlePageDrawer.apply(null, arguments)
                  },
                },
              },
              [
                _c("br"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("p", { staticClass: "fw-bold" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.Title) + "\n\t\t\t"),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.StudentName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.Institution))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.CourseName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.Teacher))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.PublishedDate))]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _c("br"),
              ]
            )
          : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.MLA9
          ? _c(
              "a",
              {
                key: "titleWidgetMLA9",
                staticClass: "fake-widget fake-widget-title-page",
                attrs: { href: "#", title: "Click to Edit your Title Page" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openTitlePageDrawer.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.StudentName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.Teacher))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.CourseName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.PublishedDate))]),
              ]
            )
          : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.Turabian9
          ? _c(
              "a",
              {
                key: "titleWidgetTurabian9",
                staticClass: "fake-widget fake-widget-title-page text-center",
                attrs: { href: "#", title: "Click to Edit your Title Page" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openTitlePageDrawer.apply(null, arguments)
                  },
                },
              },
              [
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("p", {
                  staticClass: "fw-bold",
                  domProps: { innerHTML: _vm._s(_vm.calcSplitTitle) },
                }),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.StudentName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.Institution))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.CourseName))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.Teacher))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.PublishedDate))]),
                _vm._v(" "),
                _c("br"),
                _c("br"),
                _c("br"),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.IncludeAuthorNote
          ? _c("div", [
              _c("br"),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "fw-bold text-center" }, [
                _vm._v("\n\t\t\t\tAuthor Note\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.paperAuthorNoteContent,
                    expression: "paperAuthorNoteContent",
                  },
                ],
                staticClass: "d-none",
                attrs: { id: "ckPaperAuthorNote", autocomplete: "off" },
                domProps: { value: _vm.paperAuthorNoteContent },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.paperAuthorNoteContent = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }