<!--
- Specific Heading Styles button and it's dropdown menu
-->

<template>
	<div class="float-start rb-btn-heading-styles-wrp">
		<div class="dropdown">
			<a href="#" class="rb-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuTextHeadingStyle">
				<div class="rb-icon rb-icon-heading-style" />
				Text &amp;<br />Heading Style
			</a>
			<ul class="dropdown-menu" aria-labelledby="dropdownMenuTextHeadingStyle">
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('P')">
						<span class="rb-icon-dropdown rb-icon-paragraph" />
						Paragraph
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('H1')">
						<span class="rb-icon-dropdown rb-icon-h1" />
						Heading 1
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('H2')">
						<span class="rb-icon-dropdown rb-icon-h2" />
						Heading 2
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('H3')">
						<span class="rb-icon-dropdown rb-icon-h3" />
						Heading 3
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('H4')">
						<span class="rb-icon-dropdown rb-icon-h4" />
						Heading 4
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('H5')">
						<span class="rb-icon-dropdown rb-icon-h5" />
						Heading 5
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="applyStyle('Blockquote')">
						<span class="rb-icon-dropdown rb-icon-blockquote" />
						Blockquote
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'RibbonBtnHeadingStyles',
	computed:{
		// computed this since some formats will have different block vs inline properties
		ckEditorStyles(){
			let returnObject = {
				'H1': new CKEDITOR.style({element: 'h1'}),
				'H2': new CKEDITOR.style({element: 'h2'}),
				'H3': new CKEDITOR.style({element: 'h3'}),
				'P': new CKEDITOR.style({element: 'p'}),
				'Bold': new CKEDITOR.style({element: 'strong'}),
				'Underline': new CKEDITOR.style({element: 'u'}),
				'Italics': new CKEDITOR.style({element: 'em'}),
			};

			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnObject['H4'] = new CKEDITOR.style({
					element: 'span',
					attributes: {
						'class': 'h4'
					}
				});
				returnObject['H5'] = new CKEDITOR.style({
					element: 'span',
					attributes: {
						'class': 'h5'
					}
				});

			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
				returnObject['H4'] = new CKEDITOR.style({element: 'h4'});
				returnObject['H5'] = new CKEDITOR.style({element: 'h5'});

			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnObject['H4'] = new CKEDITOR.style({element: 'h4'});

				returnObject['H5'] = new CKEDITOR.style({
					element: 'span',
					attributes: {
						'class': 'h5'
					}
				});
				
			}

			return returnObject;
		},
	},
	methods: {
		applyStyle(styleName){
			// styleName: text output of the style the user would like to apply

			let editor = CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
			
			if(!editor){
				return false;
			}
			
			editor.focus();
			// editor.fire('saveSnapshot');

			let elementPath = editor.elementPath();

			if (styleName === 'P') {
				// Aplying a Paragraph Style - if pargaph is selected, then somehow remove anything else that is there.
				
				if (elementPath.elements[0].getName() === 'span') {
					// Inside Span
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						// Remove the appropriate heading
						switch (elementPath.elements[0].$.className) {
							case 'h4':
								editor['removeStyle'](this.ckEditorStyles['H4']);
								break;
							case 'h5':
								editor['removeStyle'](this.ckEditorStyles['H5']);
								break;
						}

					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
						// only h5s are inline here
						if (elementPath.elements[0].$.className === 'h5') {
							let $parentPTag = elementPath.elements[0].getParent();
							$parentPTag.$.classList.remove("has-span-h5");	// remove the spacing on the p tag parent
							
							editor['removeStyle'](this.ckEditorStyles['H5']);
						}

					}

				} else {

					if (elementPath.elements[1] && elementPath.elements[1].getName() === 'blockquote') {
						// user is inside a blockquote trying to go back to a paragraph
						
						// https://perrla.monday.com/boards/1893642853/pulses/2456616870
						// If there are two Blockquote paragraphs and I try to set
						// the second paragraph to Paragraph style, it's deleted
						// and the first paragraph is set to Paragraph style.

						// grab this blockquote element
						let elBlockquoteTag = elementPath.elements[1];

						// check child elements
						let blockquoteChildNodeList = elementPath.elements[1].getChildren();
						if(blockquoteChildNodeList.count() === 1){
							// only one child in this blockquote
							let elPtag = elementPath.elements[0];
							elPtag.replace(elBlockquoteTag);
							editor.applyStyle(this.ckEditorStyles[styleName]);
						} else {
							// more than one blockquote child

							// start by getting the parent element from where the cursor was
							let selection = editor.getSelection();
							let range = selection && selection.getRanges()[0];

							if (!range) {
								return;
							}

							let parentElementAroundCursor = range.startContainer;

							// travel up until i'm in an element
							while (parentElementAroundCursor.type !== CKEDITOR.NODE_ELEMENT) {
								parentElementAroundCursor = parentElementAroundCursor.getParent();
							}

							// create three parts to reassemble later
							let preElmInsert = [];
							let atElmInsert;
							let postElmInsert = [];
							let isElmReached = false;

							// loop through all children in this blockquote
							blockquoteChildNodeList.toArray().forEach((node) => {
								if(node.equals(parentElementAroundCursor)){
									// i'm on the one i want to move
									atElmInsert = node;
									isElmReached = true;
								} else {
									// i'm not on the one i want to move
									if(isElmReached){
										// After										
										postElmInsert.push(node);										
									} else {
										// Before 
										preElmInsert.push(node);
									}
								}
							});

							// rebuild the elements you want to insert now

							// Pre: array of nodes that go back in a blockquote before this p tag
							let $preBlockquoteInsert = new CKEDITOR.dom.element('blockquote');
							preElmInsert.forEach((node)=>{
								$preBlockquoteInsert.append(node);
							});
							$preBlockquoteInsert.insertBefore(elBlockquoteTag);
							
							// At: new element for the p tag that is being transformed OUT of this blockquote
							let $newPInsert = new CKEDITOR.dom.element('p');
							$newPInsert.setHtml(atElmInsert.getHtml());
							$newPInsert.insertBefore(elBlockquoteTag);

							// Post: array of nodes that go back in a blockquote after this p tag)
							let $postBlockquoteInsert = new CKEDITOR.dom.element('blockquote');
							postElmInsert.forEach((node)=>{
								$postBlockquoteInsert.append(node);
							});
							$postBlockquoteInsert.replace(elBlockquoteTag);

						}//e:if:count

					} else {
						// NOT inside a blockquote - has to be a heading (or a fake heading class in a SPAN)
						switch (elementPath.elements[0].getName()) {
							case 'h1':
								editor.removeStyle(this.ckEditorStyles['H1']);
								editor.applyStyle(this.ckEditorStyles[styleName]);
								break;
							case 'h2':
								editor.removeStyle(this.ckEditorStyles['H2']);
								editor.applyStyle(this.ckEditorStyles[styleName]);
								break;
							case 'h3':
								editor.removeStyle(this.ckEditorStyles['H3']);
								editor.applyStyle(this.ckEditorStyles[styleName]);
								break;
							case 'h4':	// MLA9 or Turabian9
								editor.removeStyle(this.ckEditorStyles['H4']);
								editor.applyStyle(this.ckEditorStyles[styleName]);
								break;
							case 'h5':	// MLA9 Only
								editor.removeStyle(this.ckEditorStyles['H5']);
								editor.applyStyle(this.ckEditorStyles[styleName]);
								break;
							default:	// down to SPAN tags
								let childNodeList = elementPath.elements[0].getChildren();

								if (childNodeList.count() > 0) {
									childNodeList.$.forEach((node) => {
										if (node.nodeName == 'SPAN') {
											switch (node.className) {
												case 'h4':	// APA7 Only
													editor.removeStyle(this.ckEditorStyles['H4']);
													editor.applyStyle(this.ckEditorStyles[styleName]);
													break;
												case 'h5':	// APA7 or Turabian9 
													editor.removeStyle(this.ckEditorStyles['H5']);
													editor.applyStyle(this.ckEditorStyles[styleName]);
													break;
											}//e:switch
										} //e:ofSPAN
									});//e:forEach
								} // end if count
						}//e:switch
						
					}//e:if:else

				}//e:if:else:span

			} else if (styleName === 'Blockquote') {
				// blockquote

				// check if i'm inside a blockquote already
				let isInsideBlockquote = false;

				// loop through all elements where the cursor is
				elementPath.elements.forEach((element)=>{
					if(element.getName() === 'blockquote') {
						isInsideBlockquote = true;	// already in block quote - set the flag
					}
				});

				if(isInsideBlockquote){
					return false;
				}

				let selection = editor.getSelection();
				let range = selection && selection.getRanges()[0];

				if (!range) {
					return;
				}

				var bookmarks = selection.createBookmarks();

				// Kludge for https://dev.ckeditor.com/ticket/1592: if the bookmark nodes are in the beginning of
				// blockquote, then move them to the nearest block element in the
				// blockquote.
				if ( CKEDITOR.env.ie ) {
					var bookmarkStart = bookmarks[ 0 ].startNode,
						bookmarkEnd = bookmarks[ 0 ].endNode,
						cursor;

					if ( bookmarkStart && bookmarkStart.getParent().getName() == 'blockquote' ) {
						cursor = bookmarkStart;
						while ( ( cursor = cursor.getNext() ) ) {
							if ( cursor.type == CKEDITOR.NODE_ELEMENT && cursor.isBlockBoundary() ) {
								bookmarkStart.move( cursor, true );
								break;
							}
						}
					}

					if ( bookmarkEnd && bookmarkEnd.getParent().getName() == 'blockquote' ) {
						cursor = bookmarkEnd;
						while ( ( cursor = cursor.getPrevious() ) ) {
							if ( cursor.type == CKEDITOR.NODE_ELEMENT && cursor.isBlockBoundary() ) {
								bookmarkEnd.move( cursor );
								break;
							}
						}
					}
				}

				var iterator = range.createIterator();
				var block;
				iterator.enlargeBr = editor.config.enterMode != CKEDITOR.ENTER_BR;

				// Apply Blockquote
				let paragraphs = [];
				while ((block = iterator.getNextParagraph())) {
					paragraphs.push(block);
				}

				// If no paragraphs, create one from the current selection position.
				if ( paragraphs.length < 1 ) {
					var para = editor.document.createElement( editor.config.enterMode == CKEDITOR.ENTER_P ? 'p' : 'div' ),
						firstBookmark = bookmarks.shift();
					range.insertNode( para );
					para.append( new CKEDITOR.dom.text( '\ufeff', editor.document ) );
					range.moveToBookmark( firstBookmark );
					range.selectNodeContents( para );
					range.collapse( true );
					firstBookmark = range.createBookmark();
					paragraphs.push( para );
					bookmarks.unshift( firstBookmark );
				}

				// Make sure all paragraphs have the same parent.
				var commonParent = paragraphs[ 0 ].getParent(),
					tmp = [];
				for ( var i = 0; i < paragraphs.length; i++ ) {
					block = paragraphs[ i ];
					commonParent = commonParent.getCommonAncestor( block.getParent() );
				}
				
				// The common parent must not be the following tags: table, tbody, tr, ol, ul.
				var denyTags = { table: 1, tbody: 1, tr: 1, ol: 1, ul: 1 };
				while ( denyTags[ commonParent.getName() ] )
					commonParent = commonParent.getParent();
			
				// Reconstruct the block list to be processed such that all resulting blocks
				// satisfy parentNode.equals( commonParent ).
				var lastBlock = null;
				while ( paragraphs.length > 0 ) {
					block = paragraphs.shift();
					while (!block.getParent().equals( commonParent ))
						block = block.getParent();
					if (!block.equals(lastBlock))
						tmp.push(block);
					lastBlock = block;
				}

				// If any of the selected blocks is a blockquote, remove it to prevent
				// nested blockquotes.
				while ( tmp.length > 0 ) {
					block = tmp.shift();
					if ( block.getName() == 'blockquote' ) {
						var docFrag = new CKEDITOR.dom.documentFragment( editor.document );
						while ( block.getFirst() ) {
							docFrag.append( block.getFirst().remove() );
							paragraphs.push( docFrag.getLast() );
						}

						docFrag.replace( block );
					} else {
						paragraphs.push( block );
					}
				}

				// Now we have all the blocks to be included in a new blockquote node.
				var bqBlock = editor.document.createElement( 'blockquote' );
				bqBlock.insertBefore( paragraphs[ 0 ] );
				while ( paragraphs.length > 0 ) {
					block = paragraphs.shift();
					bqBlock.append( block );
				}


				// loop through paragraphs, adding each's text to the bqBlock then removing it
				// paragraphs.forEach((p) => {
				// 	bqBlock.appendText(p.getText());
				// 	p.remove();
				// });

				// %Rh after a blockquote style is applied - remove indent automatically from the element directly "after" this blockquote
				let getNextParagraph = bqBlock.getNext();
				if(getNextParagraph && getNextParagraph.$ && getNextParagraph.$.tagName === 'P' ){
					// getNextParagraph.addClass('no-indent');
					getNextParagraph.$.classList.add("no-indent");

				}

				selection.selectBookmarks(bookmarks);
				editor.focus();

			} else {
				if (elementPath.elements[1] && elementPath.elements[1].getName() === 'blockquote') {
					// select element to unwrap
					let elHtag = elementPath.elements[0];
					// get the element's parent node
					let elBlockquoteTag = elementPath.elements[1];
					elHtag.replace(elBlockquoteTag);

				} else {
					// Not a Paragraph, assume one of the headings 1-5

					// Applying an H1 style 
					let $thisElement = elementPath.elements[0];
					// console.log($thisElement);
					let thisElementName = $thisElement.getName();
					// console.log(thisElementName);
					
					// remove extra formatting tags
					editor.removeStyle(this.ckEditorStyles['Bold']);
					editor.removeStyle(this.ckEditorStyles['Italics']);
					editor.removeStyle(this.ckEditorStyles['Underline']);

					switch (thisElementName) {
						case 'h1':
							editor.removeStyle(this.ckEditorStyles['H1']);
							break;
						case 'h2':
							editor.removeStyle(this.ckEditorStyles['H2']);
							break;
						case 'h3':
							editor.removeStyle(this.ckEditorStyles['H3']);
							break;
						case 'h4':	// mla9 block level h4
							editor.removeStyle(this.ckEditorStyles['H4']);
							break;
						case 'h5':	// mla9 block level h5
							if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
								editor.removeStyle(this.ckEditorStyles['H5']);
							}
							break;
						case 'p':
							// turabian has spacing above the h5, but the h5 is an inline heading so add this class to this p tag
							if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 && styleName === 'H5'){
								$thisElement.$.classList.add("has-span-h5");
							}
							break;
						case 'span':
							if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
								// Remove based on which class the span has
								if ($thisElement.hasClass('h4')) {
									editor.removeStyle(this.ckEditorStyles['H4']);
								} else if ($thisElement.hasClass('h5')) {
									editor.removeStyle(this.ckEditorStyles['H5']);
								}
							}

							break;
					} // end switch
				}//e:if:else:span

				editor.applyStyle(this.ckEditorStyles[styleName]);
			}//e:if:else:styleName

			this.$store.dispatch('paperEdit/toc/generateContent');

		},//e:applyStyle
	},
}
</script>

<style lang="scss" scoped>
	@import './../../../assets/styles/vars_perrla.scss';

	$rb-icon-dropdown-size: 1rem !default;
	
	.rb-btn-heading-styles-wrp {
		position: relative;
		
		.rb-icon-heading-style{
			width: 48px !important;
			
			// caret
			&::after {
				border-top: $caret-width solid;
				border-right: $caret-width solid transparent;
				border-bottom: 0;
				border-left: $caret-width solid transparent;
				content: "";
				display: block;
				width: 0;
				height: 0;
				margin-top: 24px;
				float:right;
			}
		}
		
		.dropdown {
			.dropdown-menu {
				position: absolute;
				top: 42px;

				border-top-left-radius: 0;
				border-top-right-radius: 0;

				.dropdown-item {
					padding-left: 0.75rem;
				}
			}

			.rb-icon-dropdown {
				display: block;
				float: left;
				height: $rb-icon-dropdown-size;
				margin: 0.25rem 0.25rem 0 0;
				width: $rb-icon-dropdown-size;
				background-repeat: no-repeat;
				background-size: $rb-icon-dropdown-size $rb-icon-dropdown-size;
			}

			.rb-icon-blockquote {background-image: url('https://s3.amazonaws.com/perrla/icons/BlockQuoteStyle16x16.png');}
			.rb-icon-h1 {background-image: url('https://s3.amazonaws.com/perrla/icons/H116x16.png');}
			.rb-icon-h2 {background-image: url('https://s3.amazonaws.com/perrla/icons/H216x16.png');}
			.rb-icon-h3 {background-image: url('https://s3.amazonaws.com/perrla/icons/H316x16.png');}
			.rb-icon-h4 {background-image: url('https://s3.amazonaws.com/perrla/icons/H416x16.png');}
			.rb-icon-h5 {background-image: url('https://s3.amazonaws.com/perrla/icons/H516x16.png');}
			.rb-icon-paragraph {background-image: url('https://s3.amazonaws.com/perrla/icons/ParagraphStyle16x16.png');}

			a {
				text-decoration: none;
			}
		}
	}
</style>
