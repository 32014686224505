<!--
- Quick Add Menu
-- only in the header of the dashboard for now
-->

<template>
	<div class="dropdown me-3">
		<button class="btn dropdown-toggle btn-outline-success border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
			<span class="svg bi-plus-lg me-1" /> Create New
		</button>
		<ul class="dropdown-menu">
			<li class="dropdown-submenu">
				<a class="dropdown-item pr-2" tabindex="-1" href="#">
					<span class="svg icons8-document" /> Paper <span class="dropdown-item-right-arrow" />
				</a>

				<ul class="dropdown-menu">
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalResearchPaper">
							Research Paper
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalDiscussionPost">
							Discussion Post
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalReferenceList">
							Reference List
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalAnnotatedBibliography">
							Annotated Bibliography
						</a>
					</li>
				</ul>
			</li>

			<li>
				<a href="#" class="dropdown-item hover hover-success" @click.prevent="btnCreateReference()">
					<span class="svg icons8-edit-property" /> Reference 
				</a>
			</li>
			
			<li class="dropdown-divider" />

			<li>
				<a href="#" class="dropdown-item hover hover-success" @click.prevent="$store.commit('modals/OPEN', 'ClassCreate')">
					<span class="svg icons8-class" /> Class 
				</a>
			</li>

			<li>
				<a href="#" class="dropdown-item hover hover-success" @click.prevent="btnOpenWorkCreateModal(true)">
					<span class="svg icons8-to-do" /> To-Do 
				</a>
			</li>
			
			<li>
				<a href="#" class="dropdown-item hover hover-success" @click.prevent="btnOpenWorkCreateModal(false)">
					<span class="svg icons8-edit-property" /> Assignment 
				</a>
			</li>
		</ul>
	</div>

</template>

<script>

export default {
	name: 'QuickAddMenu',
	methods: {
		btnCreateReference(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReferenceListCreateReferenceStep1',
				modalParams: {
					openReferenceTab: 'common',
				},
			});
		},
		btnOpenCreateModalAnnotatedBibliography(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateAnnotatedBibliography',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalDiscussionPost(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateDiscussionPost',
				modalParams: {
					associatedClassId: '0',
				},
			});
			
		},
		btnOpenCreateModalReferenceList(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateReferenceList',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalResearchPaper(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateResearchPaper',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenWorkCreateModal(isTodo){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'DashboardCreateWork',
				modalParams:{
					workType: (isTodo) ? '1' : '2',
				},
			});
		},

	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";

	@include color-mode(dark) {
		.dropdown-menu {
			.hover-success {
				.svg {
					color: $dark-body-color;
				}
			}
		}	
	}
	
	.dropdown-menu {
		.dropdown-submenu {
			position: relative;

			span.dropdown-item-right-arrow{
				border-top: .3em solid transparent;
				border-right: 0;
				border-bottom: .3em solid transparent;
				border-left: .3em solid;
				content: "";
				float: right;
				margin-top: 8px;
			}

			&:hover > .dropdown-menu {
				display: block;
			}

			> .dropdown-menu {
				top: 0;
				left: 100%;
				margin-top: -6px;
				margin-left: -1px;
			}
		}


		.hover-success {
			.svg {
				color: $body-color;
			}
		}
			
		

	}

</style>