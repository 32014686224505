var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { attrs: { for: _vm.$data["safeId"] } }, [
      _vm._v("\n\t\tTags\n\t"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "help-text mb-1" }, [
      _vm._v("\n\t\tType your tags, separated by a comma.\n\t"),
    ]),
    _vm._v(" "),
    _c("textarea", {
      ref: "focusElement",
      staticClass: "form-control",
      attrs: {
        autocomplete: "off",
        rows: "1",
        id: _vm.$data["safeId"],
        "pendo-grab-text": _vm.pendoGrabText,
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.typeInput($event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }