var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rb-wrp clearfix" },
    [
      _c(
        "div",
        { staticClass: "rb float-start" },
        [
          _c(
            "router-link",
            {
              class: [
                "rb-btn",
                {
                  "rb-btn-active":
                    _vm.$route.name === "OutlineReferencesCitations",
                },
              ],
              attrs: { to: { name: "OutlineReferencesCitations" } },
            },
            [
              _c("div", { staticClass: "rb-icon rb-icon-references" }),
              _vm._v("\n\t\t\tReferences &\n\t\t\t"),
              _c("br"),
              _vm._v("\n\t\t\tCitations\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              class: [
                "rb-btn",
                { "rb-btn-active": _vm.$route.name === "OutlineResearchNotes" },
              ],
              attrs: { to: { name: "OutlineResearchNotes" } },
            },
            [
              _c("div", { staticClass: "rb-icon rb-icon-research-notes" }),
              _vm._v("\n\t\t\tResearch Notes\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ck-toolbar", { attrs: { "editor-type": "outline" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }