var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "ArchiveAllReferences" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\t\tAre you really sure you want to do this?\n\t\t"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            This will archive all of the references in your PERRLA Library - it will be like starting over.\n        "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Alternatively, you can archive select references (one at a time, or multiple at once) in your PERRLA Reference Library.\n        "
        ),
      ]),
      _vm._v(" "),
      _c("form-checkbox", {
        staticClass: "mb-4",
        attrs: {
          label: "YES - I WANT TO WIPE OUT MY REFERENCE LIBRARY COMPLETELY.",
        },
        model: {
          value: _vm.isConfirmChecked,
          callback: function ($$v) {
            _vm.isConfirmChecked = $$v
          },
          expression: "isConfirmChecked",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-7 d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-danger",
              attrs: { type: "button", disabled: !_vm.isConfirmChecked },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnArchiveAllReferences.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n                    Yes - Archive All References\n                "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-5 d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$store.commit(
                    "modals/CLOSE",
                    "ArchiveAllReferences"
                  )
                },
              },
            },
            [_vm._v("\n                    Nevermind\n                ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }