var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row-slot-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _vm.label
        ? _c("label", {
            staticClass: "form-label form-label-v2",
            domProps: { innerHTML: _vm._s(_vm.label) },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "col-value-full-width": _vm.isFullWidth,
          "col-value": !_vm.isFullWidth,
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }