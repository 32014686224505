var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("generic-modal-wrap", { attrs: { "modal-id": "ReplaceFigure" } }, [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tReplace Figure\n\t"),
    ]),
    _vm._v(" "),
    _c("label", [_vm._v("Select the figure image")]),
    _vm._v(" "),
    _c("div", { staticClass: "help-text mb-1" }, [
      _vm._v(
        "\n\t\tChoose where we to get your figure image from the options below.\n\t"
      ),
    ]),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "row mb-2" }, [
        _c(
          "div",
          { staticClass: "col ms-2" },
          [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectTypeId,
                    expression: "selectTypeId",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "modalImgOnComputer",
                  name: "modalImgOnComputer",
                },
                domProps: { value: 0, checked: _vm._q(_vm.selectTypeId, 0) },
                on: {
                  change: function ($event) {
                    _vm.selectTypeId = 0
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "modalImgOnComputer" },
                },
                [_vm._v("Image on my computer")]
              ),
            ]),
            _vm._v(" "),
            _c("slide-up-down", { attrs: { active: _vm.selectTypeId === 0 } }, [
              _c("div", { staticClass: "ms-3" }, [
                _c("input", {
                  ref: "formFileSelect",
                  class: [
                    "form-control-file mb-1 p-1",
                    { "file-select-is-invalid": _vm.isFileError },
                  ],
                  attrs: {
                    type: "file",
                    "aria-label": "Select an Image on my computer",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.fileSelectChange($event.target.files)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.isFileError
                  ? _c(
                      "div",
                      {
                        key: "divImageSelectError",
                        staticClass: "invalid-feedback d-block",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tPlease select a .jpg, or .png file.\n\t\t\t\t\t\t"
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { key: "divImageSelectHelp", staticClass: "help-text" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tYou can insert .jpg, and .png files as figures.\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectTypeId,
                    expression: "selectTypeId",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "modalImgFromUrl",
                  name: "modalImgFromUrl",
                },
                domProps: { value: 1, checked: _vm._q(_vm.selectTypeId, 1) },
                on: {
                  change: function ($event) {
                    _vm.selectTypeId = 1
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "modalImgFromUrl" },
                },
                [_vm._v("Image from a URL")]
              ),
            ]),
            _vm._v(" "),
            _c("slide-up-down", { attrs: { active: _vm.selectTypeId === 1 } }, [
              _c("div", { staticClass: "ms-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.imageUrl,
                      expression: "imageUrl",
                    },
                    {
                      name: "debounce",
                      rawName: "v-debounce:300ms",
                      value: _vm.inputImageUrl,
                      expression: "inputImageUrl",
                      arg: "300ms",
                    },
                  ],
                  ref: "inputEnterImageUrl",
                  staticClass: "form-control",
                  attrs: {
                    autocomplete: "off",
                    placeholder: "Enter the image URL",
                    type: "text",
                  },
                  domProps: { value: _vm.imageUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.imageUrl = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$store.commit("modals/CLOSE", "ReplaceFigure")
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit", disabled: _vm.isFormSubmitDisabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.insertFigurePrep.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tReplace Figure\n\t\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }