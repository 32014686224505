var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown me-3" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("ul", { staticClass: "dropdown-menu" }, [
      _c("li", { staticClass: "dropdown-submenu" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-success",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnOpenCreateModalResearchPaper.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tResearch Paper\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-success",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnOpenCreateModalDiscussionPost.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tDiscussion Post\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-success",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnOpenCreateModalReferenceList.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tReference List\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-success",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnOpenCreateModalAnnotatedBibliography.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tAnnotated Bibliography\n\t\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "dropdown-item hover hover-success",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnCreateReference()
              },
            },
          },
          [
            _c("span", { staticClass: "svg icons8-edit-property" }),
            _vm._v(" Reference \n\t\t\t"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "dropdown-item hover hover-success",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$store.commit("modals/OPEN", "ClassCreate")
              },
            },
          },
          [
            _c("span", { staticClass: "svg icons8-class" }),
            _vm._v(" Class \n\t\t\t"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "dropdown-item hover hover-success",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnOpenWorkCreateModal(true)
              },
            },
          },
          [
            _c("span", { staticClass: "svg icons8-to-do" }),
            _vm._v(" To-Do \n\t\t\t"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "dropdown-item hover hover-success",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnOpenWorkCreateModal(false)
              },
            },
          },
          [
            _c("span", { staticClass: "svg icons8-edit-property" }),
            _vm._v(" Assignment \n\t\t\t"),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle btn-outline-success border-0",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "svg bi-plus-lg me-1" }),
        _vm._v(" Create New\n\t"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item pr-2",
        attrs: { tabindex: "-1", href: "#" },
      },
      [
        _c("span", { staticClass: "svg icons8-document" }),
        _vm._v(" Paper "),
        _c("span", { staticClass: "dropdown-item-right-arrow" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }