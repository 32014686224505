<template>
	<a href="#" @click.prevent="goToReferenceType" class="d-block ref-type-select-row rounded">
		<p class="mb-0 org-size">
			{{ referenceTypeTitle }}
		</p>
		<p v-html="referenceTypeDescription" class="mb-0" />
	</a>

</template>

<script>

export default {
	name: 'ReferenceCreateStep1Row',
	props: {
		isDrawer:{
			default: true,
			type: Boolean,
		},
		referenceTypeDescription:{
			required: true,
			type: String
		},
		referenceTypeFormats:{
			required: true,
			type: Object
		},
		referenceTypeSlug:{
			required: true,
			type: String
		},
		referenceTypeTitle:{
			required: true,
			type: String
		},
	},

	methods: {
		goToReferenceType(){
			// open this reference type
			if(this.$router.currentRoute.meta.module === this.$enums.Module.REFERENCE_LIST){
				this.$router.push({
					name: 'ReferenceListCreateReferenceStep2',
					params: {
						openReferenceTab: this.$route.params.openReferenceTab,
						referenceTypeSlug: this.referenceTypeSlug
					},
				}).catch(()=>{});
			} else if(this.$router.currentRoute.meta.module === this.$enums.Module.PAPER_EDIT){
				if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
					this.$router.push({
						name:'EditorCreateReferenceStep2',
						params:{
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: this.referenceTypeSlug
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
					this.$router.push({
						name: 'OutlineCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: this.referenceTypeSlug
						},
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.RESEARCH){
					this.$router.push({
						name: 'PaperTabResearchCreateReferenceStep2',
						params: {
							openReferenceTab: this.$route.params.openReferenceTab,
							referenceTypeSlug: this.referenceTypeSlug
						},
					}).catch(()=>{});
				}
			}//e:module
		},//e:goToReferenceType
	}
}
</script>

<style lang="scss" scoped>
	@import "./../../assets/styles/vars_perrla";
	
	@include color-mode(dark) {
		a.ref-type-select-row {
			color:$dark-body-color;
		}
	}

	a.ref-type-select-row {
		color:$body-color;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
		text-decoration: none;

		&:hover {
			background-color: $primary;
			text-decoration: none;

			p {
				color: $white;
			}
		}
	}

</style>
