<!--
- JSON Data - Copy to clipboard
-- Takes generic string of data and displays it in a text area with an option to copy it
-- should only show up Dev/Test and Impersonation Live
-- 2023.08.23
-->

<template>
	<section class="mb-4 bg-secondary p-2" v-if="showJsonRefData">
		<input class="form-control form-control-sm mb-1" type="text" :value="jsonRefData" aria-label="JSON Ref Data" />
		
		<div class="d-grid">
			<button class="btn btn-clipboard btn-success btn-sm" @click.prevent="copyJsonData" type="button">
				Copy
			</button>
		</div>
	</section>
</template>

<script>
import CopyToClipboard from 'copy-to-clipboard';

export default {
	name: 'CopyJsonData',
	props:{
		dataToDisplay:{
			required: true,
			type: String,
		}
	},
	computed:{
		jsonRefData(){
			if(this.showJsonRefData){
				return this.dataToDisplay;
			} else {
				return '';
			}
		},
		showJsonRefData(){
			if(this.$isLive){
				return this.$store.state.customer.isImpersonating;	
			} 
			return true;
		},
	},
	methods:{
		copyJsonData(){
			CopyToClipboard(this.jsonRefData, {
				format: 'text/html',
				onCopy(){
					window.$vm.emitter.emit('globalToasterOpen',{
						textContent: 'Copied to clipboard',
					});
				},
			});
		},//e:copyJsonData
	},
}
</script>
