/*
- Tag Cleaner
-- 2022.09.22
*/
export default {
	methods: {
		cleanTags(tagList) {
			let tagsArrayDirty = tagList.split(',');
			let tagsArrayClean = [];

			// trim white space before and after each tag 
			tagsArrayDirty.forEach((tag) =>{
				let cleanTag = ' ' + tag.trim();
				if(!tagsArrayClean.includes(cleanTag)){
					tagsArrayClean.push(cleanTag);
				}
			});

			return tagsArrayClean.join(',').replace(/^,/, '').trim();
		},
	},
}