<!--
- Live Preview
-- Display / Copy citation output
-- 2022.11.12
-->

<template>
	<div class="alert alert-info mb-4">
		<button class="btn btn-clipboard btn-sm btn-primary float-end" type="button" @click.prevent="copyCitationPreview">
			Copy
		</button>
		<h3>Live Preview</h3>
		<p>
			<span v-html="citationOutput" />
		</p>
	</div>
</template>

<script>
import config from '@/config';
import CopyToClipboard from 'copy-to-clipboard';

export default {
	name: 'CitationLivePreview',
	props:{
		citationOutput: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			clipboard: null,
		}
	},
	methods: {
		copyCitationPreview(){
			CopyToClipboard(this.citationOutput, {
				format: 'text/html',
				onCopy(){
					if(config.platformId === config.enums.Platform.ONLINE){
						window.$vm.emitter.emit('globalToasterOpen',{
							textContent: 'Copied to clipboard',
						});

					} else if(config.platformId === config.enums.Platform.ADD_IN){
						window.$vm.emitter.emit('globalToasterOpen', {
							title: 'Copied to clipboard',
						});
						
					}
				},
			});
		},//e:copyCitationPreview
	},
}
</script>
