/*
- Suggest Field
-- Helper to Check Suggested Fields of a Form || CheckSuggestedField
-- only used when creating a reference and typing in the title of a textarea
-- 2024.06.14
*/

import _forEach from 'lodash/forEach';

export default {
	allCapsInput(value){
		// Guard against blank values
		if(value.length === 0) {
			return false;
		}

		let isShowSuggestion = false;

		let regexpCapAlphaNumbers = /^[A-Z1-9]*$/;
		let regexpOnlyNumbers = /^\d+$/;

		// split into an array of Words
		let splitArray = value.trim().split(' ');
		
		_forEach(splitArray, (word, index)=>{
			// words over 2 characters (prevents A and I)
			if(word.length >= 2){
				if(
					!regexpOnlyNumbers.test(word) &&	// ignore this if it's all numbers
					regexpCapAlphaNumbers.test(word)	// matches capital letters and numbers
				){
					isShowSuggestion = true;
					return false;	// stop looping when one is found
				}
			}
		});

		return isShowSuggestion;
	},

	bracketInput(firstChar){
		if(firstChar === '['){
			return true;
		}
	},

	// Contributor > Group Author - https://trello.com/c/0iD9q9fR/394-qol-field-verification-for-group-authors
	groupAuthorInput(value){
		if(value.includes('&') || value.includes(',') || value.includes(' and ')){
			return true;
		}
	},

	titleCaseInput(value){
		// Guard against blank values
		if(value.length === 0) {
			return false;
		}

		let isShowSuggestion = false;
		let regexp = /^[A-Z]/;
		let numberOfUpperCaseWords = 0;

		// split into an array of Words
		let splitArray = value.trim().split(' ');
		
		_forEach(splitArray, (word, index)=>{
			if (regexp.test(word.charAt(0))){
				numberOfUpperCaseWords++;
			}
			if(numberOfUpperCaseWords >=3) {
				isShowSuggestion = true;
				return false;	// stop looping after three are found
			}
		});

		return isShowSuggestion;
	},
}