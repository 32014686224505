var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("generic-modal-wrap", { attrs: { "modal-id": "ReminderCreate" } }, [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tCreate Reminder\n\t"),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("* Required fields")]),
    _vm._v(" "),
    _c(
      "form",
      [
        _c("form-input", {
          attrs: {
            label: "Reminder Name*",
            placeholder: "(required)",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.REQUIRED,
          },
          model: {
            value: _vm.reminderName,
            callback: function ($$v) {
              _vm.reminderName = $$v
            },
            expression: "reminderName",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "CR_dueDate",
            label: "Due Date*",
            "form-id": _vm.zFormId,
            "is-clearable": true,
            "validate-is-all-date": "",
            "validate-is-required": "",
          },
          on: { "date-set": _vm.dateDueSet },
        }),
        _vm._v(" "),
        _c("form-entity-association", {
          attrs: { "entity-type-id": _vm.$enums.Entity.REMINDER },
          on: { "entity-selected": _vm.entitySelected },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$store.commit("modals/CLOSE", "ReminderCreate")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.createReminder.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate Reminder\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }