<!--
- Class Edit > Upcoming Work
-
-->

<template>
	<div class="">
		<div class="row">
			<div class="col">
				<h2>
					Upcoming Work
				</h2>
			</div><!--col-->
		</div><!--row-->

		<div class="row">
			<div class="col">
				<!-- {{ filteredGridData }} -->
				<table v-if="filteredGridData && filteredGridData.length > 0" class="table table-borderless entity-grid mb-0">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Name"
								colspan="2"
								sort-key="name"
								:is-title="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<sortable-grid-th 
								cell-text="Due Date"
								set-width="165px"
								sort-key="sortByDueDate" 
								:is-align-right="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />
						</tr>
					</thead>
					<tbody>
						<work-row
							v-for="workData in filteredGridData"
							:work-data-encrypted-id="workData.entityEncryptedID"
							:work-data-entity-id="workData.entityID"
							:work-data-due-date="workData.dueDate"
							:work-data-name="workData.name"
							:work-data-type="workData.workType"
							:key="workData.workID" />
					</tbody>
				</table>
				<div v-else class="text-muted">
					<p>No upcoming work found.</p>
				</div>
			</div><!--col-->
		</div><!--row-->

		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import WorkRow from '@/components/EntityGrid/WorkRow';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ClassEditUpcomingWork',
	props:{

	},
	data() {
		return {
			sortOrderBy: 'sortByDueDate',
			sorts: {
				'name': 1,
				'sortByDueDate': 1,
			}
		}
	},
	computed:{
		classData(){
			return this.$store.state.classes.openObject;
		},
		filteredGridData(){
			let filteredArray = [];
			let _data = _cloneDeep(this.$store.state.work.list);

			_data.forEach((workData) =>{
				// console.log(workData);
				if(workData.isComplete || workData.classID !== this.classData.classID){
					return false;
				}

				if(_has(workData, 'dueDate') && workData.dueDate) {
					workData.sortByDueDate = workData.dueDate;	// remove hh:mm:ss so you can sort down to the day
				} else {
					workData.sortByDueDate = '2000-01-01T00:00:00';
				}

				filteredArray.push(workData);
			});//e:forEach

			// now sort if user has modified the THs
			if(this.sorts[this.sortOrderBy] === 1){
				return _orderBy(filteredArray, [filteredArray => filteredArray[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				return _orderBy(filteredArray, [filteredArray => filteredArray[this.sortOrderBy].toLowerCase()], ['desc']);
			}

		},
	},
	methods: {
		updateGridSort($obj){
			this.sortOrderBy = $obj.sortKey;
			this.sorts = $obj.sorts;
		},

	},
	components: {
		WorkRow,
		SortableGridTh
	},
}
</script>
