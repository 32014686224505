import config from '@/config';

import ReferralRewards from '@/views/Account/ReferralRewards';
import SubscriptionExpired from '@/views/Account/SubscriptionExpired';

export default [
	
	{
		name: 'ReferralRewards',
		component: ReferralRewards,
		path: '/referral-rewards',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'SubscriptionExpired',
		component: SubscriptionExpired,
		path: '/subscription-expired',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
]