/*
- Forms
-- 2024.04.23
-- ex: this.$store.state.forms.x
*/
import _has from 'lodash/has';
import config from '@/config';

export default {
	namespaced: true,
	
	state:{
		isDirty: false,	// boolean to track when changes have been made to a form like Create Reference Step 1 to confirm leaving that route
		list:	[],	// Array of Form Objects
		validation: [],	// Array of Validation Objects
	},//e:state

	mutations: {
		ADD_FORM_TO_LIST(state, $opts){
			state.list.push({
				formId: $opts.formId,
				isDirty: false,
				isValid: false,
			});
		},
		ADD_VALIDATION(state, $opts){
			state.validation.push({
				formId: $opts.formId,
				fullId: $opts.fullId,
				invalidErrorMessage: $opts.invalidErrorMessage,
				isShown: false,	
				validateEnum: $opts.validateEnum,
			});
		},
		MARK_ALL_CLEAN(state){
			state.list.forEach((formData)=>{
				formData.isDirty = false;
			});
		},
		MARK_FORM_CLEAN_BY_ID(state, $opts){
			let findIndex = state.list.findIndex((formData)=>{
				return formData.formId === $opts.formId;
			});
			if(findIndex !== -1){
				state.list[findIndex].isDirty = false;
			}
		},
		MARK_FORM_DIRTY_BY_ID(state, $opts){
			let findIndex = state.list.findIndex((formData)=>{
				return formData.formId === $opts.formId;
			});
			if(findIndex > -1){
				state.list[findIndex].isDirty = true;
			}
		},
		MARK_FORM_INVALID_BY_ID(state, $opts){
			let findIndex = state.list.findIndex((formData)=>{
				return formData.formId === $opts.formId;
			});
			if(findIndex !== -1){
				state.list[findIndex].isValid = false;
			}
		},
		MARK_FORM_VALID_BY_ID(state, $opts){
			let findIndex = state.list.findIndex((formData)=>{
				return formData.formId === $opts.formId;
			});
			if(findIndex !== -1){
				state.list[findIndex].isValid = true;
			}
		},
		REMOVE_FORM_BY_ID(state, $opts){
			let findIndex = state.list.findIndex((formData)=>{
				return formData.formId === $opts.formId;
			});
			if(findIndex > -1){
				state.list.splice(findIndex, 1);	
			}
		},
		SET_VALIDATION(state, value){
			state.validation = value;
		},
	},//e:mutations

	actions:{
		addValidation({commit}, $opts){
			return new Promise((resolve) => {
				// console.log('addValidation');
				// console.log($opts);
				
				let invalidErrorMessage = '';

				switch($opts.validateEnum){
					case config.enums.Validation.EMAIL:
					case config.enums.Validation.EMAIL_REQUIRED:
						invalidErrorMessage = 'Please enter a valid ' + $opts.fieldLabel;
						break;
					case config.enums.Validation.NUMERIC:
					case config.enums.Validation.NUMERIC_REQUIRED:
						invalidErrorMessage = 'Please enter numeric ' + $opts.fieldLabel;
						break;
					case config.enums.Validation.DATE:
					case config.enums.Validation.REQUIRED:
						invalidErrorMessage = 'Please enter ' + $opts.fieldLabel;
						break;
				}
				commit('ADD_VALIDATION', {
					formId: $opts.formId,
					fullId: $opts.fullId,
					invalidErrorMessage,
					validateEnum: $opts.validateEnum,
				});

				resolve();
			});//e:promise
		}
	},//e:actions
}
