/*
- Paper List
-- ex: this.$store.state.paperList.x
*/

export default {
	namespaced: true,
	
	state:{
		// local storage states
		list: [],		// Array: list of papers for this customer, each entry is a single Paper Object

		// non local storage states
	},//e:state

	mutations: {
		ADD_PAPER_TO_LIST(state, paperData){
			state.list.push(paperData);
		},
		ADD_REFERENCE_ID_TO_PAPER(state, $opts){
			state.list[$opts.paperIndex].genesisReferences.push($opts.referenceID);
		},
		CLEAR_LIST(state){
			state.list = [];
		},
		REMOVE_AT_INDEX(state, paperIndex){
			state.list.splice(paperIndex, 1);
		},
		REMOVE_REFERENCE_ID_FROM_PAPER(state, $opts){
			state.list[$opts.paperIndex].genesisReferences.splice(state.list[$opts.paperIndex].genesisReferences.indexOf($opts.referenceID), 1);
		},
		TOGGLE_COMPLETE_AT_INDEX(state, paperIndex){
			state.list[paperIndex].isComplete = !state.list[paperIndex].isComplete;
		},
		UPDATE_PAPER_IN_LIST(state, $opts){
			state.list[$opts.paperIndex] = $opts.paperData;
		},

		REFERENCE_REMOVE_FROM_CUSTOMER_PAPER(state, $opts){
			// get this customerPaper
			let findPaper = state.list.find((paper)=>{
				return paper.paperID === $opts.paperID;
			});
			if(findPaper){
				// paper found, is this reference in the list (assume APA7 / Genesis)
				if(findPaper.genesisReferences.includes($opts.referenceID)){
					findPaper.genesisReferences.splice(findPaper.genesisReferences.indexOf($opts.referenceID), 1);
				}
			}
		},


	},//e:mutations
}
