<!--
- Research Notes Module > Add Citation Modal
-- can be called from ReferenceEdit or PaperEdit
-->

<template>
	<generic-modal-wrap modal-id="ResearchNotesModalsCitationAdd">
		<h2 class="nodal-title">
			Add Citation
		</h2>
		<form>
			<citation-form
				:paper-format-version-id="paperFormatVersionID"
				:reference-unique-id="$store.state.modals.passData.referenceUniqueId"
				@enableCitationFormButton="isDisabled = false"
				@disableCitationFormButton="isDisabled = true" />
			
			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" @click.prevent="closeModal" class="btn btn-outline-danger">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="btnAddCitation" :disabled="isDisabled">
							Create Citation
						</button>
					</div>
				</div>
			</div>
		</form>
	</generic-modal-wrap>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import CitationForm from '@/modules/CitationForm';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import { v4 as uuidv4 } from 'uuid';


export default {
	name: 'ResearchNotesModalsCitationAdd',
	data() {
		return {
			isDisabled: true,
		}
	},
	computed:{
		paperFormatVersionID(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},

		parentResearchNote(){
			// find this Research Note by the Research Note UID in the Route
			let findResearchNote = this.$store.state.researchNotes.list.find((researchNoteData)=>{
				return researchNoteData.genesisResearchNoteUniqueId.toUpperCase() === this.$store.state.modals.passData.researchNoteUniqueId;
			});
			if(findResearchNote){
				return findResearchNote;
			}

			return null;
		},
	},
	methods: {
		btnAddCitation(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);
			newCitationResult.citationData.citationUniqueID = uuidv4().toUpperCase();
			newCitationResult.citationData.referenceUniqueID = this.$store.state.modals.passData.researchNoteUniqueId;
			
			// console.log(newCitationResult);

			let sendData = {
				'GenesisReferenceID': this.parentResearchNote.genesisReferenceId,
				'GenesisResearchNoteID': this.parentResearchNote.genesisResearchNoteId,
				'Note': this.parentResearchNote.note,
				'Citation': JSON.stringify(newCitationResult),
			}
			
			ApiS_MakeCall({
				body: sendData,
				method: 'PUT',
				responseType: 'json',
				url: 'v3/GenesisResearchNote',
			}).then((responseData)=>{
				this.$store.commit('researchNotes/ADD_CITATION_TO_UNIQUE_ID', {
					newCitationData: newCitationResult,
					researchNoteUniqueId: this.parentResearchNote.genesisResearchNoteUniqueId,
				}); 

				this.closeModal();

				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:ApiS_MakeCall
		},//e:btnAddCitation

		closeModal(){
			this.$store.commit('modals/CLEAR_MODAL_DATA');
			this.$store.commit('modals/CLOSE', 'ResearchNotesModalsCitationAdd');
		},
	},
	components: {
		CitationForm,
		GenericModalWrap,
	}
}
</script>
