var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", { staticClass: "float-start" }, [
          _vm._v("\n\t\t\t\tRecent Papers\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown float-end" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-success",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnOpenCreateModalResearchPaper.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\t\tResearch Paper\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-success",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnOpenCreateModalDiscussionPost.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\t\tDiscussion Post\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-success",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnOpenCreateModalReferenceList.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\t\tReference List\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-success",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnOpenCreateModalAnnotatedBibliography.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\t\tAnnotated Bibliography\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.filteredGridData.length > 0
          ? _c(
              "table",
              { staticClass: "table table-borderless entity-grid mb-0" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Title",
                          "sort-key": "title",
                          colspan: "2",
                          "is-title": true,
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("th", [_vm._v("\n\t\t\t\t\t\t\tType\n\t\t\t\t\t\t")]),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Class",
                          "sort-key": "className",
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Due Date",
                          "sort-key": "dueDate",
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Modified",
                          "sort-key": "lastModifiedDate",
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("th", { attrs: { width: "30px" } }, [
                        _vm._v("\n\t\t\t\t\t\t\t \t"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.filteredGridData, function (paperData) {
                      return _c("paper-row", {
                        key: paperData.paperID,
                        attrs: {
                          "class-id": paperData.classID,
                          "paper-class-name": paperData.className,
                          "paper-description": paperData.description,
                          "paper-due-date": paperData.dueDate,
                          "paper-encrypted-id": paperData.encryptedID,
                          "paper-format-version-id":
                            paperData.paperFormatVersionID,
                          "paper-id": paperData.paperID,
                          "paper-is-complete": paperData.isComplete,
                          "paper-last-modified-date":
                            paperData.lastModifiedDate,
                          "paper-start-date": paperData.startDate,
                          "paper-title": paperData.title,
                          "paper-type": paperData.paperType,
                          context: "Dashboard",
                        },
                        on: { "update-grid": _vm.updateGrid },
                      })
                    }),
                    _vm._v(" "),
                    _c("tr", { staticClass: "tr-padding-left-icon" }, [
                      _c(
                        "td",
                        { attrs: { colspan: "6" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-outline-primary border-0",
                              attrs: { to: { name: "PaperList" } },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tView all papers\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            )
          : _c("div", { staticClass: "text-muted text-center mt-5" }, [
              _c("p", [_vm._v("No recent papers found.")]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle btn-outline-success border-0",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "svg bi-plus-lg me-1" }),
        _vm._v(" Create paper\n\t\t\t\t"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }