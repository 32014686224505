<!--
- Top Right Corner Toast
-- this is global since it can occur on any page in the SPA
-->

<template>
	<div :class="calcStyleClasses">
		<span class="svg bi-exclamation-lg float-start" /> {{ textContent }}
		<a href="#" class="svg bi-x-lg float-end pe-1" title="Close" @click.prevent="closeToast" tabindex="-1" />
	</div>
</template>

<script>

export default {
	name: 'ToasterComponent',
	data() {
		return {
			isOpen: false,
			stoClose: null,
			textContent: '',
			variant: 'success',
		}
	},
	computed:{
		calcStyleClasses(){
			let returnString = 'toaster ';

			returnString += 'toaster-' + this.variant + ' ';

			if(this.isOpen){
				returnString +=	'toaster-open ';
			}

			return returnString;
		},
	},
	methods: {
		closeToast(){
			this.stoClose = null;
			this.isOpen = false;

			// reset defaults
			this.textContent = '';
			this.variant = 'success';
		},
		
		openToast(){
			this.isOpen = true;
			
			if(this.stoClose){
				clearTimeout(this.stoClose);
			}

			this.stoClose = setTimeout(()=>{
				this.closeToast();
			}, 3333);
		},
	},
	created() {
		this.emitter.on('globalToasterOpen', ($opts)=>{
			if($opts.textContent){
				this.textContent = $opts.textContent;
			}
			if($opts.variant){
				this.variant = $opts.variant;
			} else {
				this.variant = 'success';
			}

			this.openToast();
		});
	},
	destroyed(){
		this.emitter.off('globalToasterOpen');
	}
}
</script>

<style lang="scss" scoped>
	
	@import "./../assets/styles/vars_perrla.scss";

	.toaster {
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		display: block;
		font-size: $font-size-base;
		font-weight: $font-weight-normal;
		min-width: 320px;
		line-height: 21px;
		padding: 0.5rem;
		position: absolute;
		top: $grid-half-gutter;
		right: -320px;
		z-index: $zindex-toaster;

		&.toaster-danger{
			background: $danger-300;
			border: 1px solid $danger;
			color: $danger-900;
			
			.svg {
				&.bi-exclamation-lg {
					color: $danger-700;
				}

				&.bi-x-lg {
					color: $danger-700;
				}
			}

		}

		&.toaster-success{
			background: $success-300;
			border: 1px solid $success;
			color: $success-900;

			.svg {
				&.bi-exclamation-lg {
					color: $success-700;
				}

				&.bi-x-lg {
					color: $success-700;
				}
			}
		}

		&.toaster-warning{
			background: $warning-300;
			border: 1px solid $warning;
			color: $warning-900;

			.svg {
				&.bi-exclamation-lg {
					color: $warning-700;
				}

				&.bi-x-lg {
					color: $warning-700;
				}
			}
		}
		
		&.toaster-open {
			right: $grid-half-gutter;
			transition: right 0.12s ease-in-out;
		}
		
		// status icon to the left
		.bi-exclamation-lg {
			font-size: 1.5rem;
			line-height: 1;
		}

		// close icon to the right
		.svg.bi-x-lg {
			font-size: $font-size-base;
			line-height: 1;
		}
		
	}

</style>