var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Paper Review" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "drawer-body" },
      [
        _c("h3", [_vm._v("Check your paper for common errors.")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "These are some of the most common errors we've seen students make over the years."
          ),
        ]),
        _vm._v(" "),
        _c("form-checkbox", {
          attrs: {
            label: "Review References for missing information and/or typos",
            "check-type": "success",
          },
          model: {
            value: _vm.checkTypos,
            callback: function ($$v) {
              _vm.checkTypos = $$v
            },
            expression: "checkTypos",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "ms-4 mt-2 text-muted" }, [
          _vm._v(
            "\n\t\t\tUse PERRLA to edit references containing typos or missing data.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.DISCUSSION_POST ||
        _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER
          ? _c(
              "div",
              [
                _c("form-checkbox", {
                  class: { "form-check-danger": _vm.isOneCitationError },
                  attrs: {
                    label: "Make sure all References have a Citation",
                    "check-type": "success",
                  },
                  model: {
                    value: _vm.checkOneCitation,
                    callback: function ($$v) {
                      _vm.checkOneCitation = $$v
                    },
                    expression: "checkOneCitation",
                  },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    class: [
                      "ms-4 mt-2 text-muted",
                      { "text-danger": _vm.isOneCitationError },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\tIf you're not citing a Reference in the body of your paper, remove it from the References list.\n\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("form-checkbox", {
          attrs: {
            label: "Single vs. double space after a period.",
            "check-type": "success",
          },
          model: {
            value: _vm.checkPeriod,
            callback: function ($$v) {
              _vm.checkPeriod = $$v
            },
            expression: "checkPeriod",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "ms-4 mt-2 text-muted" }, [
          _vm._v(
            "\n\t\t\tUnless your teacher says otherwise, include only one space between sentences.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.DISCUSSION_POST ||
        _vm.documentType === _vm.$enums.DocumentType.RESEARCH_PAPER
          ? _c(
              "div",
              [
                _c("form-checkbox", {
                  attrs: {
                    label: "Check table and figure numbering.",
                    "check-type": "success",
                  },
                  model: {
                    value: _vm.checkTablesFigures,
                    callback: function ($$v) {
                      _vm.checkTablesFigures = $$v
                    },
                    expression: "checkTablesFigures",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "ms-4 mt-2 text-muted" }, [
                  _vm._v(
                    "\n\t\t\t\tMake sure the callouts in your text are referencing the correct table and figure numbers.\n\t\t\t"
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.documentType === _vm.$enums.DocumentType.DISCUSSION_POST
          ? _c(
              "div",
              [
                _c("form-checkbox", {
                  attrs: {
                    label:
                      "Copy your paper's content to the clipboard so it's easy to paste into an online forum.",
                    "check-type": "success",
                  },
                  model: {
                    value: _vm.checkCopyPaperToClipboard,
                    callback: function ($$v) {
                      _vm.checkCopyPaperToClipboard = $$v
                    },
                    expression: "checkCopyPaperToClipboard",
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary ms-4 mt-1 mb-3",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.copyDiscussionPostToClipboard.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tCopy paper to clipboard\n\t\t\t")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("form-checkbox", {
          attrs: {
            label:
              "Download a clean version of your paper to turn into your professor.",
            "check-type": "success",
          },
          model: {
            value: _vm.checkDownloadCleanCopy,
            callback: function ($$v) {
              _vm.checkDownloadCleanCopy = $$v
            },
            expression: "checkDownloadCleanCopy",
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary ms-4 mt-1",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnCleanCopy.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tDownload Simplified Word file\n\t\t")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }