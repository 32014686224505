<!--
- PaperList > Route Modal > Create Discussion Post
-->
<template>
	<div>
		<module-create-discussion-post />
	</div>
</template>

<script>
import ModuleCreateDiscussionPost from '@/modules/paper/CreateDiscussionPost'

export default {
	name: 'PaperListCreateDiscussionPost',
	components: {
		ModuleCreateDiscussionPost
	}
}
</script>
