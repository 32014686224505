var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: {
            title: "Edit Citation",
            "close-to-route-name": "EditorReferencesCitations",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
      _vm.$enums.Format.Turabian9
        ? _c(
            "div",
            { key: "divFootnote2" },
            [
              _c(
                "nav-tabs",
                { staticClass: "mb-3" },
                [
                  _c(
                    "nav-tab",
                    {
                      attrs: {
                        to: {
                          name: "EditorCitationEdit",
                          params: {
                            citationUid:
                              _vm.findCitation.citationData.citationUniqueID,
                            openCitationTab: _vm.$enums.CitationTab.CITATION,
                          },
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tCitation\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "nav-tab",
                    {
                      attrs: {
                        to: {
                          name: "EditorCitationEdit",
                          params: {
                            citationUid:
                              _vm.findCitation.citationData.citationUniqueID,
                            openCitationTab: _vm.$enums.CitationTab.FOOTNOTE,
                          },
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tFootnote Text\n\t\t\t\t")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$route.params.openCitationTab ===
                        _vm.$enums.CitationTab.FOOTNOTE,
                      expression:
                        "$route.params.openCitationTab === $enums.CitationTab.FOOTNOTE",
                    },
                  ],
                  key: "tabFootnote",
                },
                [
                  _c("div", { ref: "divHeader" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "p-relative" },
                    [
                      _c("footnote-editor-ribbon"),
                      _vm._v(" "),
                      _c("textarea", {
                        staticClass: "d-none",
                        attrs: { id: "ckPaperFootnotes", autocomplete: "off" },
                        domProps: { value: _vm.thisFootnoteContent },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { ref: "divFooter", staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "d-grid" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-danger",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnFootnoteCancel.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "d-grid" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnUpdateFootnote.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t\t\t\tUpdate\n\t\t\t\t\t\t\t")]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$route.params.openCitationTab ===
                        _vm.$enums.CitationTab.CITATION,
                      expression:
                        "$route.params.openCitationTab === $enums.CitationTab.CITATION",
                    },
                  ],
                  key: "tabCitation",
                },
                [
                  _c(
                    "form",
                    [
                      _c("citation-form", {
                        attrs: {
                          "load-citation-data": _vm.findCitation,
                          "paper-format-version-id": _vm.paperFormatVersionID,
                          "reference-unique-id": _vm.referenceUniqueId,
                        },
                        on: {
                          enableCitationFormButton: function ($event) {
                            _vm.isDisabled = false
                          },
                          disableCitationFormButton: function ($event) {
                            _vm.isDisabled = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "d-grid" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-outline-danger",
                                  attrs: {
                                    to: { name: "EditorReferencesCitations" },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "d-grid" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: {
                                  type: "submit",
                                  disabled: _vm.isDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnUpdateCitation.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tUpdate\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _c("div", { key: "divCitation2" }, [
            _c(
              "form",
              [
                _c("citation-form", {
                  attrs: {
                    "load-citation-data": _vm.findCitation,
                    "paper-format-version-id": _vm.paperFormatVersionID,
                    "reference-unique-id": _vm.referenceUniqueId,
                  },
                  on: {
                    enableCitationFormButton: function ($event) {
                      _vm.isDisabled = false
                    },
                    disableCitationFormButton: function ($event) {
                      _vm.isDisabled = true
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "d-grid" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-outline-danger",
                            attrs: {
                              to: { name: "EditorReferencesCitations" },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t\t")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "d-grid" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit", disabled: _vm.isDisabled },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.btnUpdateCitation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tUpdate Citation\n\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }