/*
- isGenesisPaper
-- checks against specific paper formats to see if this is a Genesis paper or older
-- 2023.06.26
*/
import config from '@/config';

export default {
	checkFormatVersion(paperFormatVersionId){
		// console.log('isGenesisPaper');
		if(
			paperFormatVersionId === config.enums.Format.APA7 ||
			paperFormatVersionId === config.enums.Format.MLA9 ||
			paperFormatVersionId === config.enums.Format.Turabian9
		){
			return true;
		}
	},
	checkPaperType(paperType){
		if(
			paperType === config.enums.PaperType.APA7_AnnotatedBibliography ||
			paperType === config.enums.PaperType.APA7_DiscussionPost ||
			paperType === config.enums.PaperType.APA7_ProfessionalPaper ||
			paperType === config.enums.PaperType.APA7_ReferenceList ||
			paperType === config.enums.PaperType.APA7_StudentPaper ||
			paperType === config.enums.PaperType.MLA9_AnnotatedBibliography ||
			paperType === config.enums.PaperType.MLA9_DiscussionPost ||
			paperType === config.enums.PaperType.MLA9_ReferenceList ||
			paperType === config.enums.PaperType.MLA9_ResearchPaper ||
			paperType === config.enums.PaperType.Turabian9_AnnotatedBibliography ||
			paperType === config.enums.PaperType.Turabian9_DiscussionPost ||
			paperType === config.enums.PaperType.Turabian9_ReferenceList ||
			paperType === config.enums.PaperType.Turabian9_ResearchPaper) {
		
			return true;
		}
	},
}
