<!--
- Pricing Grid
-- Org Invite 
-->

<template>
	<div class="price-grid-wrp">
		<section>
			<div class="container">
				<div class="row">
					<div class="col-12 col-lg-6 text-center">
						<main>
							<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png" class="icon" role="presentation" />
							<h3>PERRLA&nbsp;for&nbsp;Word</h3>
							<p class="text-start mb-0">
								PERRLA&#39;s Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.
							</p>
						</main>
					</div>
					<div class="col-12 col-lg-6 text-center">
						<main>
							<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png" class="icon" role="presentation" />
							<h3>PERRLA&nbsp;Online</h3>
							<p class="text-start mb-0">
								PERRLA Online is our web-based application that allows you to write APA &amp; MLA papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper&#39;s research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.
							</p>
						</main>
					</div>
				</div>
			</div>
		</section>

		<section class="py-0">
			<div class="container">
				<hr />
			</div>
		</section>

		<section>
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg" class="icon" role="presentation" />
								<h4>Latest APA &amp;&nbsp;MLA&nbsp;formats</h4>
							</div>
							<p class="text-start org-size">
								PERRLA is 100% compatible with APA 7, APA 6, MLA 9, and MLA 8 formatting guidelines. We monitor all APA &amp; MLA updates to make sure PERRLA is always up-to-date!
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg" class="icon" role="presentation" />
								<h4>Google Chrome Extension</h4>
							</div>
							<p class="text-start org-size">
								Our Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg" class="icon" role="presentation" />
								<h4>Formats your entire paper</h4>
							</div>
							<p class="text-start org-size">
								PERRLA formats your references, page margins, headings &amp; text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don&rsquo;t have to.
							</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg" class="icon" role="presentation" />
								<h4>Auto-fill Reference Wizard</h4>
							</div>
							<p class="text-start org-size">
								Use our Auto-fill tool to search for books, journal articles, &amp; websites without any typing. Manually create every other reference type with our guided Reference Wizard.
							</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg" class="icon" role="presentation" />
								<h4>Reference Syncing</h4>
							</div>
							<p class="text-start org-size">
								PERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg" class="icon" role="presentation" />
								<h4>PERRLA is always up-to-date</h4>
							</div>
							<p class="text-start org-size">
								Updating software is a pain. PERRLA updates itself automatically without you having to do a thing.
							</p>
						</main>
					</div>
				</div><!--row-->
				<div class="row">
					<div class="col-xs-12 col-sm-6 col-md-4 mx-md-auto g-start-md-5">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg" class="icon" role="presentation" />
								<h4>US-based Support</h4>
							</div>
							<p class="text-start org-size">
								Our Customer Support team is serious about helping students. They&rsquo;re available <strong>7 days a week until 10pm Central</strong>.
							</p>
						</main>
					</div>
				</div><!--row-->
			</div>
		</section>
	</div>
</template>

<script>

export default {
	name: 'PricingGrid',
}
</script>
