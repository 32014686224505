var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "TestServerAlert", "is-close-button": false } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tOops! It looks like you found our Test site.\n\t"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'This version of the PERRLA website is built for internal testing only. You\'ll want to do your work in the "real" PERRLA. '
        ),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goToLiveLink.apply(null, arguments)
              },
            },
          },
          [_vm._v("Click here to go to the stable version of this page")]
        ),
        _vm._v("."),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.goToLiveLink.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\tGo to PERRLA\n\t")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }