/*
- Author's Note
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.authorsNote.x
*/

export default {
	namespaced: true,
	
	state:{
		content: '',	// String
	},//e:state

	mutations: {
		CONTENT(state, value) {
			state.content = value;
		},
	},//e:mutations
}
