<!--
- Review a Single Genesis Reference
-- as of 2023.09.06 this is a modified copy of ReferenceEdit
-- used only to "resave" a reference if there isn't a Turabian9 Value from the engine
-->

<template>
	<div>
		<h2 class="nodal-title">
			Review Reference
		</h2>

		<edit-reference-body :is-drawer="false" />
	</div>
</template>


<script>
import EditReferenceBody from '@/modules/EditReferenceBody';

export default {
	name: 'OranizeReviewReference',
	components: {
		EditReferenceBody,
	}
}
</script>
