/*
- App
-- App level state managemnet
-- ex: this.$store.state.app.x
*/
import config from '@/config';

export default {
	namespaced: true,
	
	state: {
		// local storage states
		showAlphaFeatures: false,	// impersonation menu item
		showBetaFeatures: false,	// impersonation menu item
		
		// non local storage states
		isSidebarShown: true,
		layoutEnum: config.enums.Layout.PO,	// default to PERRLA Online - but this can be changed in the view to Pcom
	},
	
	mutations: {
		SET_IS_SIDEBAR_SHOWN(state, value){
			state.isSidebarShown = value;
		},
		SET_LAYOUT_ENUM(state, value) {
			state.layoutEnum = value;
		},

		SET_SHOW_ALPHA_FEATURES(state, value) {
			state.showAlphaFeatures = value;
		},
		SET_SHOW_BETA_FEATURES(state, value) {
			state.showBetaFeatures = value;
		},
	},

}