<!--
- Module > Convert Reference Step 2 > Body
-
-->

<template>
	<div>
		<p class="org-size">
			Step 2 of 2: Confirm your reference
		</p>
		
		<reference-output />

		<section class="mb-5">
			<h3>Optional Content</h3>

			<form-input
				label="Nickname"
				help-text="Optional. Shows in Library &amp; Panel."
				pendo-grab-text="Txt_RefCreate_Nickname"
				v-model="$data['nickname']" />

			<form-textarea
				label="Description"
				help-text="Add a private description to your Reference."
				pendo-grab-text="Txt_RefCreate_Description"
				v-model="$data['description']" />

			<form-tags
				v-model="$data['tags']"
				pendo-grab-text="Txt_RefCreate_Tags" />
		</section>

		<div class="row">
			<div class="col">
				<div class="d-grid">
					<router-link
						v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR"
						:to="{name:'EditorConvertReferenceStep1'}"
						class="btn btn-outline-primary"
						key="routerLinkTabEditor">
						Back
					</router-link>
					<router-link
						v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE"
						:to="{name:'OutlineConvertReferenceStep1'}"
						class="btn btn-outline-primary"
						key="routerLinkTabOutline">
						Back
					</router-link>
					<button v-else class="btn btn-outline-primary" @click.prevent="moduleModalBack" type="button">
						Back
					</button>
				</div>
			</div>
			<div class="col text-end">
				<div class="d-grid">
					<button type="submit" class="btn btn-success" @click.prevent="createReference">
						Next
					</button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormTags from '@/components/form/Tags';
import FormTextarea from '@/components/form/Textarea';
import mixinTagCleaner from '@/mixins/tag-cleaner';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import ReferenceOutput from '@/components/ReferenceOutput';
import {v4 as uuidv4} from 'uuid';

export default {
	name: 'ConvertReferenceStep2Body',
	mixins: [mixinTagCleaner],
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			description: '',
			nickname: '',
			tags: '',
		}
	},
	computed:{
		editReference(){
			return this.$store.state.paperEdit.editReference
		},
	},
	methods: {
		createReference(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let urlQueryString = '';
			// Whatever integer value you want to pass me to denote the reference type.
			urlQueryString += 'referenceTypeId=' + this.$store.state.referenceRules.referenceTypeId;
			
			// A GUID to identify the reference. In the past I created this server side, but since they can create these without our server now, I need it passed to me.
			urlQueryString += '&uniqueId=' + uuidv4().toUpperCase();

			// Optional. Eventually pass me the old style ReferenceID if this is one they converted.
			urlQueryString += '&legacyReferenceId=' + this.editReference.referenceID; 

			// Optional. Note/Description field like the old refs.
			if(this.description.length > 0){
				urlQueryString += '&note=' + this.description;
			}
			
			// Optional. A nickname field like the old ones. In case we use it.
			if(this.nickname.length > 0){
				urlQueryString += '&nickname=' + this.nickname;
			}
			
			// Optional. Just like the old system.
			if(this.tags.length > 0){
				urlQueryString += '&tags=' + this.cleanTags(this.tags);
			}

			urlQueryString = encodeURI(urlQueryString);

			let referenceRulesObject = {
				'data': this.$store.state.referenceRules.rulesObjectData,
				'values': this.$store.state.referenceRules.rulesObjectValues,
			}


			ApiS_MakeCall({
				body: referenceRulesObject,
				method: 'POST',
				responseType: 'json',
				url: 'v3/GenesisReference?' + urlQueryString,
			}).then((responseData)=>{
				let referenceReturn = _cloneDeep(responseData);
				referenceReturn.legacyReferenceId = this.editReference.referenceID;

				this.$store.commit('REFERENCE_ADD_TO_LIBRARY', referenceReturn);

				PS_AddReferenceToPaper({
					isDrawer: this.isDrawer,
					reference: referenceReturn
				}).then(()=>{
					// Must be in a Paper (Editor or Organize Tab)
					if(this.$store.state.paperEdit.annotations.isInclude){
						this.emitter.emit('createNewAnnotation', {
							referenceUniqueID: referenceReturn.referenceUniqueID.toUpperCase()
						});
					}

					if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						this.$router.push({
							name:'EditorReferencesCitations'
						}).catch(()=>{});

					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						this.$router.push({
							name:'OutlineReferencesCitations'
						}).catch(()=>{});

					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
						this.emitter.emit('closeRouteModal');
						this.$store.commit('loaders/REMOVE_ID', 'App');

					}
				});//e:PS_AddReferenceToPaper
			});//e:ApiS_MakeCall
		},//e:createReference

		moduleModalBack(){
			this.$router.go(-1)
		},
		
	},
	mounted() {
		// set values from store object to editable fields
		this.nickname = (this.editReference.name === 'empty') ? '' : this.editReference.name;
		this.description = (this.editReference.note === 'empty') ? '' : this.editReference.note;
		this.tags = (this.editReference.tags === 'empty') ? '' : this.editReference.tags;
	},
	components: {
		FormInput,
		FormTags,
		FormTextarea,
		ReferenceOutput,
	}
}
</script>
