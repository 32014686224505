/*
- Ck (CkEditor)
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.ck.x
*/

const stopTypingMs = 1500;	// 1.5s

export default {
	namespaced: true,
	
	state:{
		// local storage states
		isWproofReaderActive: true,		// bool to use WProofreader or not - toggles in Filebar.Vue

		// non local storage states
		isEditorLoadedOnce: false,				// Bool: force the user to the Editor tab (for refreshes when not in the Editor Tab)
		showDevBlocks: false,					// Bool: for support/dev to see blocks around each element in ckeditor
		editorLastFocused: 	'ckPaperBody',		// String: when an editor gets focus, change this value (ex to ckPaperAbstract)
		stoUserStoppedTypingFooter: null,				// Init a timeout variable to be used on change
	},//e:state

	mutations: {
		SET_EDITOR_LAST_FOCUSED(state, value) {
			state.editorLastFocused = value;
		},
		SET_IS_EDITOR_LOADED_ONCE(state) {
			state.isEditorLoadedOnce = true;
		},
		SET_IS_WPROOF_READER_ACTIVE(state, value) {
			state.isWproofReaderActive = value;
		},
		TOGGLE_SHOW_DEV_BLOCKS(state){
			state.showDevBlocks = !state.showDevBlocks;
		},
		USER_TYPE_FOOTER_TIMER_START(state, editorName){
			if(state.stoUserStoppedTypingFooter){
				clearTimeout(state.stoUserStoppedTypingFooter);
			}
			state.stoUserStoppedTypingFooter = setTimeout(()=>{
				window.$vm.emitter.emit('userStoppedTyping', {
					editorName: editorName
				});
			}, stopTypingMs);
		},
	},//e:mutations
}
