/*
- Abstract
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.abstract.x
*/
export default {
	namespaced: true,
	
	state:{
		content: '',	// String: content of what was in the abstract (used to keep Abstact content when user switches tabs on Paper Edit)
		keywords: '',	// String
	},//e:state

	mutations: {
		CONTENT(state, value) {
			state.content = value;
		},
		KEYWORDS(state, value) {
			state.keywords = value;
		},
	},//e:mutations
	
}