var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tCreate a reference\n\t"),
      ]),
      _vm._v(" "),
      _c("create-reference-step-4-third-party-body", {
        attrs: { "is-drawer": false },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }