<!--
- Overview Tab > Recent Paper Saves
-
-->

<template>
	<div class="d-grid">
		<h2>Recent Paper Saves</h2>

		<div ref="divRecentPaperSavesListWrp" class="overflow-y-scroll">
			<table class="table table-fixed-header entity-grid">
				<thead>
					<tr>
						<th width="215px">
							Time
						</th>
						<th>
							Action
						</th>
						<th width="115px">
							Options
						</th>
						
						<th width="135px">
							&nbsp; 	<!-- restore -->
						</th>

						<th width="32px">
							&nbsp;	<!-- menu icon -->
						</th>
					</tr>
				</thead>
				<tbody>
					<recent-paper-save-row
						v-for="(paperVersionData, index) in paperVersions"
						:key="index"
						:paper-version-data="paperVersionData" />
				</tbody>
			</table>
		</div>

	</div><!--g-recent-paper-saves-->
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import mixinResizer from '@/mixins/resizer';
import RecentPaperSaveRow from './RecentPaperSavesRow';

export default {
	name: 'RecentPaperSaves',
	mixins: [mixinResizer],
	data(){
		return {
			rzRef: 'divRecentPaperSavesListWrp',
			paperVersions: []
		}
	},
	methods: {
		loadPaperVersions(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				url: 'v4/Paper/Versions',
				params:{
					paperId: this.$store.state.paperEdit.meta.PaperID,
					platform: 1,
				},
				responseType: 'json',
			}).then((responseData)=>{
				this.paperVersions = _cloneDeep(responseData.versions);

				// this.searchResults = responseData.versions;
				
				if(responseData.versions.length > 0){
					this.$store.commit('paperEdit/ACTIVE_VERSION_ID', responseData.versions[0].versionID);
				}

				this.$store.commit('loaders/REMOVE_ID', 'App');
			});//e:ApiS_MakeCall

		},//e:loadPaperVersions
	},
	mounted(){
		// no paper id - maybe webpack hot reload, go back to EditorTab to get one
		if(!this.$store.state.paperEdit.meta.PaperID){
			this.$router.push({
				name: 'PaperTabEditor'
			}).catch(()=>{});
			return false;
		}
		
		this.loadPaperVersions();
	},
	components: {
		RecentPaperSaveRow
	}
}
</script>



<style lang="scss" scoped>
	@import "../../../assets/styles/_vars_perrla.scss";

	

</style>