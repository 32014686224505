<!--
- Navigation Tabs > Navigatin Tab Content as Grid
-- Single Content Block
-- So far only used on Paper Edit > Overview Tab
-->
<!-- overflow-y-auto  -->

<template>
	<div class="d-grid p-3 g-nav-tab-content-grid" ref="tabContentGridScroll">
		<slot />
	</div> 
</template>

<script>
import mixinResizer from '@/mixins/resizer';

export default {
	name: 'NavTabContent',
	mixins: [mixinResizer],
	props:{
		// isNoPadding:{
		// 	default: false,
		// 	type: Boolean,
		// }
	},
	data() {
		return {
			rzRef: 'tabContentGridScroll',
		}
	},
}
</script>

