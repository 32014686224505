/*
- Resizer
-- respond to resizeable events
-- 2022.12.29
*/
export default {
	data() {
		return {
			rzFooterOffset: 0,
			rzIgnoreBreakpoints: true,
		}
	},
	methods: {
		calcHeight(){
			if(this.$refs[this.rzRef]){
				let elmBounding = this.$refs[this.rzRef].getBoundingClientRect();
				if(this.rzIgnoreBreakpoints){
					this.$refs[this.rzRef].style.height = (window.innerHeight - elmBounding.top - 16 - this.rzFooterOffset) + 'px';
				} else {
					if(window.innerHeight >= 720){
						this.$refs[this.rzRef].style.height = (window.innerHeight - elmBounding.top - 16 - this.rzFooterOffset) + 'px';
					} else {
						this.$refs[this.rzRef].style.height = 'auto';
					}
				}
			}
		},
	},
	created() {
		// $emits from App.vue > mounted() > window.addEventListener
		this.emitter.on('resizeWindow',()=>{
			this.calcHeight();
		});
	},
	mounted() {
		this.calcHeight();
	},
	destroyed(){
		this.emitter.off('resizeWindow');
	},
}