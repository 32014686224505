<!--
- Single Row for a reference you can add from your library to your paper 
-->

<template>
	<div :class="['element-row mb-2',{'test-data': referenceData.isTestData ,'archived-reference': referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.DISABLED}]">

		<p v-add-blank-target v-html="outputReferenceValue" :class="['mb-0 rounded reference-value py-1 neg-gutter', {'bg-active': isInPaper}]" />

		<!-- Add To Paper (Button will change based on the right AddMode) -->	
		<div v-show="!isInPaper">
	
			<!-- Disabled -->
			<p class="ps-2 mt-0 font-weight-bold incompatible-text" v-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.DISABLED" key="btnAddModeDisabled">
				<span class="svg bi-exclamation-lg" /> Incompatible Format
			</p>
			
			<!-- Coming Soon -->
			<p class="ps-2 mt-0 font-weight-bold incompatible-text" v-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.COMMING_SOON" key="btnAddModeComingSoon">
				<span class="svg bi-exclamation-lg" /> Reference type coming soon
			</p>
			
			<!-- Normal -->
			<div class="d-grid" v-else-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.NORMAL" key="btnAddModeNormal">
				<button type="button" class="btn btn-sm btn-hover btn-hover-success ps-2 mt-0" @click="addReference">
					<span class="svg bi-plus-lg" /> Add to Paper
				</button>
			</div>

			<!-- Update -->
			<div class="d-grid" v-else-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.UPDATE" key="btnAddModeUpdate">
				<button type="button" class="btn btn-sm btn-hover btn-hover-success ps-2 mt-0" @click="convertReference">
					<span class="svg bi-plus-lg" /> Update &amp; Add to Paper
				</button>
			</div>

			<!-- Review -->
			<div class="d-grid" v-else-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.REVIEW" key="btnAddModeReview">
				<!-- <p class="ps-2 mt-0 font-weight-bold text-danger">TURABIAN REVIEW</p> -->
				<button type="button" class="btn btn-sm btn-hover btn-hover-success ps-2 mt-0" @click="reviewReference">
					<span class="svg bi-plus-lg" /> Review &amp; Add to Paper
				</button>
			</div>

			<!-- Format Disabled -->
			<div class="d-grid" v-else-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.FORMAT_DISABLED" key="btnAddModeFormatDisabled">
				<button type="button" class="btn btn-sm btn-hover btn-hover-danger ps-2 mt-0 disabled" @click.prevent="">
					<span class="svg bi-x-lg" /> {{ outputFormatDisabledText }}
				</button>
			</div>

			<!-- Gimmick -->
			<!-- Just added for testing / display -->
			<!-- <div class="d-grid" v-else-if="referenceData.addFromLibraryMode === $enums.AddFromLibraryMode.GIMMICK" key="btnAddModeGimmick">
				<p class="ps-2 mt-0 font-weight-bold text-success">TURABIAN GOOD TO ADD</p>
			</div> -->

		</div>

		<slide-up-down :active="showRefDeleteWaring">
			<p class="mt-1">
				Removing the Reference from your paper will also delete its citations in your paper.  Are you sure you want to remove this reference?
			</p>
			<div class="d-grid gap-2">
				<button type="button" class="btn btn-sm btn-danger" @click.prevent="removeReference">
					Remove Reference &amp; Delete Citations
				</button>
				<button type="button" class="btn btn-sm btn-outline-primary" @click.prevent="showRefDeleteWaring = false">
					Cancel
				</button>
			</div>
		</slide-up-down>

		<!-- Remove from Paper -->
		<div class="d-grid" v-if="!showRefDeleteWaring">
			<button type="button" class="btn btn-sm btn-hover btn-hover-danger ps-2" @click.prevent="removeReferenceConfim" v-show="isInPaper">
				<span class="svg bi-x-lg" /> Remove from Paper
			</button>
		</div>
	</div>
</template>

<script>
import _findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config.js';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import PS_RemoveReferenceFromPaper from '@/services/paper/removeReferenceFromPaper';
import ReferenceTypes from '@/services/reference/types.json';
import SlideUpDown from '@/components/VueSlideUpDown.vue';

export default {
	name: 'AddFromLibraryRow',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		},
		referenceData: {
			required: true,
			type:Object
		},
		referenceUniqueId:{
			required: true,
			type: String
		},
	},
	data() {
		return {
			hasActiveCitations: false,
			showRefDeleteWaring: false,
		}
	},
	computed:{
		isInPaper(){
			// blank guard
			if(this.$store.state.paperEdit.referencesInPaper.length === 0){
				return false;
			}
			
			// Look for this unique id in the referenceUniqueIdsInPaper array
			let findReferenceIndex = this.$store.state.paperEdit.referencesInPaper.findIndex((referenceObject)=>{
				return referenceObject.referenceUniqueID.toUpperCase() === this.referenceUniqueId;
			});
			
			if(findReferenceIndex === -1){
				// not found
				return false;
			} else {
				// found
				return true;
			}
		},

		outputFormatDisabledText(){
			let referenceTypeData = ReferenceTypes.refTypes.find((referenceTypeDataLoop)=>{
				return referenceTypeDataLoop.id == this.referenceData.referenceTypeID;
			});
			let returnText = 'Reference unavailable in this format';

			if(referenceTypeData){
				returnText = referenceTypeData.title + ' ' + ' is unavailable in ';
				
				switch(this.$store.state.paperEdit.meta.PaperFormatVersionID){
					case config.enums.Format.APA7:
						returnText += 'APA';
						break;
					case config.enums.Format.MLA9:
						returnText += 'MLA';
						break;
					case config.enums.Format.Turabian9:
						returnText += 'Turabian';
						break;
				}
			}

			return returnText;
		},

		outputReferenceValue(){
			return this.referenceData.displayValue;
		},

	},
	methods: {
		addReference(){
			if(this.referenceData.isTestData){
				alert('Add Reference');

			} else {
				this.$store.commit('loaders/ADD_ID', 'App');

				PS_AddReferenceToPaper({
					isDrawer: this.isDrawer,
					reference: this.referenceData
				}).then(()=>{
					this.emitter.emit('updateReferenceList');
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});

			}

		},//e:addReference

		convertReference(){
			if(this.referenceData.isTestData){
				alert('Convert Reference');

			} else {
					
				this.$store.commit('paperEdit/SET_EDIT_REFERENCE', this.referenceData);
				
				// console.log(this.referenceData);
				
				if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						// in an APA7 paper - regular converting (APA6 to APA7)
						this.$router.push({
							name:'EditorConvertReferenceStep1'
						}).catch(()=>{});
					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						// in an MLA9 paper - trying to update and insert an APA7 reference 
						this.$router.push({
							name:'EditorEditReference'
						}).catch(()=>{});
					}
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						// in an APA7 paper - regular converting (APA6 to APA7)
						this.$router.push({
							name:'OutlineConvertReferenceStep1'
						}).catch(()=>{});
					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						// in an MLA9 paper - trying to update and insert an APA7 reference 
						this.$router.push({
							name:'OutlineEditReference'
						}).catch(()=>{});
					}
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						// in an APA7 paper - regular converting (APA6 to APA7)
						this.$store.commit('modals/CLOSE', 'ResearchNotesAddReferenceFromLibrary')					
						this.emitter.emit('openRouteModal', {
							modalRoute: 'PaperTabResearchConvertReferenceStep1',
						});
					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						// in an MLA9 paper - trying to update an APA7 reference 
						this.emitter.emit('openRouteModal', {
							modalRoute: 'PaperTabResearchEditReferenceBody',
						});
					}
				}
			}
		},//e:convertReference

		removeReference(){
			this.showRefDeleteWaring = false;
			this.$store.commit('loaders/ADD_ID', 'App');

			PS_RemoveReferenceFromPaper({
				hasActiveCitations: this.hasActiveCitations,
				isDrawer: this.isDrawer,
				paperID: this.$store.state.paperEdit.meta.PaperID,
				referenceID: this.referenceData.referenceID,
			}).then(()=>{
				this.emitter.emit('updateReferenceList');
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});	
			
		},//e:removeReference

		removeReferenceConfim(){
			let findCitation = false;
			
			if(this.isDrawer){
				// Drawer
				let $firstCitationEl = null;

				_forEach(CKEDITOR.instances, (ckInstance)=>{
					$firstCitationEl = ckInstance.document.findOne('span[data-reference-unique-id="' + this.referenceUniqueId + '"]');
					if($firstCitationEl){
						findCitation = true;
						return false;	// stop looking when you find one
					}
				});

			} else {
				// Module Modal
				
				// loop through all the references - to find this citation
				_forEach(this.$store.state.paperEdit.referencesInPaper, (referenceObject)=>{
					if(_has(referenceObject, 'citations') && referenceObject.citations.length > 0){
						_forEach(referenceObject.citations, (citationObject)=>{
							if(citationObject.citationData.referenceUniqueID.toUpperCase() === this.referenceUniqueId){
								findCitation = true;
								return false;	// stop looping once it's found
							}
						});//e:forEach:citations
					}//e:if:_has
				});//e:forEach:referencesInPaper
			}

			if(findCitation){
				// Active Citations - confirm delete
				this.hasActiveCitations = true;
				this.showRefDeleteWaring = true;
			} else {
				// No Active Citations - Just Remove it
				this.hasActiveCitations = false;
				this.removeReference();
			}
		},//e:removeReferenceConfim

		// for when the user is adding a Turabian9 Reference, there isn't a turabian9 citation value (an older ref before Turabian9 was in the engine), and publishers are missing information
		// ideally this should take them to the Edit Reference type screen where they just update (which calls the Engine again)
		reviewReference(){
			if(this.referenceData.isTestData){
				alert('Review Reference');

			} else {

				let thisReferenceFind = this.$store.state.referenceLibraryGenesis.find((ref) => {
					return ref.referenceUniqueID.toUpperCase() == this.referenceData.referenceUniqueID.toUpperCase()
				});

				if(thisReferenceFind){
					this.$store.commit('paperEdit/SET_EDIT_REFERENCE', thisReferenceFind);

					if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						this.$router.push({
							name:'EditorReviewReference',
							params: {
								referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase()
							}
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						this.$router.push({
							name:'OutlineReviewReference',
							params: {
								referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase()
							}
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
						// console.log('from research notes tab')
						this.$store.commit('modals/CLOSE', 'ResearchNotesAddReferenceFromLibrary');

						this.emitter.emit('openRouteModal', {
							modalRoute: 'PaperTabResearchReviewReference',
							modalParams: {
								referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase(),
							},
						});
					}
				}
			}
		},//e:reviewReference

	},
	components:{
		SlideUpDown,
	}
}
</script>

<style lang="scss" scoped>
	@import './../../assets/styles/vars_perrla.scss';
	
	.test-data{
		border:2px dashed $warning-500;
	}

	.element-row .dropstart .bi-three-dots {
		margin-top: 0.5rem;
	}
	
</style>