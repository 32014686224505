<!--
- Paper Edit > Organize Tab > Research Notes > Edit Reference
-- Edit a single genesis reference
-->

<template>
	<div>
		<h2 class="nodal-title">
			Edit Reference
		</h2>
			
		<edit-reference-body :is-drawer="false" />

	</div>
</template>

<script>
import EditReferenceBody from '@/modules/EditReferenceBody';

export default {
	name: 'OrganizeEditReference',
	components: {
		EditReferenceBody
	}
}
</script>
