var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("You've already created this reference")]),
    _vm._v(" "),
    _vm.isReferenceInPaper
      ? _c("div", { key: "divDuplicateReferenceInPaper" }, [
          _c("p", [
            _vm._v(
              "It looks like you already have this reference added to your paper."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Would you like to cite the reference you've already added to your paper or create a new, duplicate reference?"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-grid gap-2 mb-2" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnCiteExisitingReference.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tUse my existing reference\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnCreateDuplicateReference.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\tCreate duplicate reference\n\t\t\t")]
            ),
          ]),
        ])
      : _c("div", { key: "divDuplicateReferenceNotInPaper" }, [
          _c("p", [
            _vm._v(
              "We've found a reference in your PERRLA Library matching your search."
            ),
          ]),
          _vm._v(" "),
          _vm.$router.currentRoute.meta.module ===
          _vm.$enums.Module.REFERENCE_LIST
            ? _c("p", [
                _vm._v("Would you like to create a duplicate reference?"),
              ])
            : _c("p", [
                _vm._v(
                  "Would you like to re-use the reference you've created before or create a new, duplicate reference?"
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-grid gap-2 mb-2" }, [
            _vm.calcAddFromMode === _vm.$enums.AddFromLibraryMode.DISABLED
              ? _c(
                  "p",
                  {
                    key: "btnAddModeDisabled",
                    staticClass: "ps-2 mt-0 font-weight-bold incompatible-text",
                  },
                  [
                    _c("span", { staticClass: "svg bi-exclamation-lg" }),
                    _vm._v(" Incompatible Format\n\t\t\t"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.calcAddFromMode === _vm.$enums.AddFromLibraryMode.COMMING_SOON
              ? _c(
                  "p",
                  {
                    key: "btnAddModeComingSoon",
                    staticClass: "ps-2 mt-0 font-weight-bold incompatible-text",
                  },
                  [
                    _c("span", { staticClass: "svg bi-exclamation-lg" }),
                    _vm._v(" Reference type coming soon\n\t\t\t"),
                  ]
                )
              : _vm.calcAddFromMode === _vm.$enums.AddFromLibraryMode.NORMAL
              ? _c("div", { key: "btnAddModeNormal", staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnUseMyExisitingReference.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tUse my exisiting reference\n\t\t\t\t")]
                  ),
                ])
              : _vm.calcAddFromMode === _vm.$enums.AddFromLibraryMode.NEVERMIND
              ? _c(
                  "div",
                  { key: "btnAddModeFormatNevermind", staticClass: "d-grid" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.btnCloseModal.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\tNevermind\n\t\t\t\t")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnCreateDuplicateReference.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\tCreate duplicate reference\n\t\t\t")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }