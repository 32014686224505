var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ref-select-type-wrp my-2" }, [
    _vm.$route.params.openReferenceTab === "common"
      ? _c(
          "div",
          { key: "tabContentCommon" },
          [
            _vm.$store.state.referenceDuplicate.isShowWarning
              ? _c("duplicate-reference-confirm")
              : _c(
                  "div",
                  { key: "divNoDuplicateReferenceWarning" },
                  [
                    _c("h3", [_vm._v("Manually create a reference")]),
                    _vm._v(" "),
                    _vm._l(_vm.refTypesByCommon, function (referenceType) {
                      return _c("reference-type-select-row", {
                        key: referenceType.id,
                        attrs: {
                          "is-drawer": _vm.isDrawer,
                          "reference-type-description": referenceType.desc,
                          "reference-type-formats": referenceType.formats,
                          "reference-type-slug": referenceType.slug,
                          "reference-type-title": referenceType.title,
                        },
                      })
                    }),
                  ],
                  2
                ),
          ],
          1
        )
      : _vm.$route.params.openReferenceTab === "all"
      ? _c("div", { key: "tabContentAll" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.referenceTypeSearchTerm === "",
                  expression: "referenceTypeSearchTerm === ''",
                },
              ],
            },
            _vm._l(
              _vm.refTypesByCategory,
              function (referenceTypes, refTypeCategory) {
                return _c(
                  "div",
                  {
                    key: referenceTypes.id,
                    staticClass: "ref-select-type-wrp",
                  },
                  [
                    _c("h3", [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(refTypeCategory) + "\n\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "my-2" },
                      _vm._l(referenceTypes, function (referenceType) {
                        return _c("reference-type-select-row", {
                          key: referenceType.id,
                          attrs: {
                            "is-drawer": _vm.isDrawer,
                            "reference-type-description": referenceType.desc,
                            "reference-type-formats": referenceType.formats,
                            "reference-type-slug": referenceType.slug,
                            "reference-type-title": referenceType.title,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                )
              }
            ),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.referenceTypeSearchTerm !== "",
                  expression: "referenceTypeSearchTerm !== ''",
                },
              ],
            },
            _vm._l(_vm.filteredReferenceTypes, function (referenceType) {
              return _c("reference-type-select-row", {
                key: referenceType.id,
                attrs: {
                  "is-drawer": _vm.isDrawer,
                  "reference-type-description": referenceType.desc,
                  "reference-type-formats": referenceType.formats,
                  "reference-type-slug": referenceType.slug,
                  "reference-type-title": referenceType.title,
                },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.params.openReferenceTab === "recent"
      ? _c(
          "div",
          { key: "tabContentRecent" },
          [
            _c("h3", [_vm._v("Recently created references")]),
            _vm._v(" "),
            _vm._l(_vm.refTypesByRecent, function (referenceType) {
              return _c("reference-type-select-row", {
                key: referenceType.id,
                attrs: {
                  "select-row": "",
                  "is-drawer": _vm.isDrawer,
                  "reference-type-description": referenceType.desc,
                  "reference-type-formats": referenceType.formats,
                  "reference-type-slug": referenceType.slug,
                  "reference-type-title": referenceType.title,
                },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }