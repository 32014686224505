<!--
- Reference Output
-- displays in an alert box for Ref Create, Edit, 3plu, etc
-- 2024.06.21
-->

<template>
	<div class="alert alert-info mb-4" v-if="referenceOutput != ''">
		<h3>Live Preview</h3>
		<p class="org-size font-reference" v-add-blank-target v-html="referenceOutput" />
	</div>
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';

export default {
	name: 'ReferenceOutput',
	computed: {
		referenceOutput(){
			if(!_isEmpty(this.$store.state.referenceRules.rulesObjectValues)){
				
				let _referenceFormatTypeId = HelperGetReferenceFormatId();

				// set reference output based on format
				switch(_referenceFormatTypeId){
					case config.enums.Format.APA7:
						if(_has(this.$store.state.referenceRules.rulesObjectValues, 'apa7') && _has(this.$store.state.referenceRules.rulesObjectValues.apa7, 'value')){
							return this.$store.state.referenceRules.rulesObjectValues.apa7.value;
						}
						break;
					case config.enums.Format.MLA9:
						if(_has(this.$store.state.referenceRules.rulesObjectValues, 'mla9') && _has(this.$store.state.referenceRules.rulesObjectValues.mla9, 'value')){
							return this.$store.state.referenceRules.rulesObjectValues.mla9.value;
						}
						break;
					case config.enums.Format.Turabian9:
						if(_has(this.$store.state.referenceRules.rulesObjectValues, 'turabian9') && _has(this.$store.state.referenceRules.rulesObjectValues.turabian9, 'value')){
							return this.$store.state.referenceRules.rulesObjectValues.turabian9.value;
						}
						break;
					default:
						console.log('_referenceFormatTypeId not found in ReferenceOutput.vue');
				}
			}

			return '';
		},
	},
}
</script>
