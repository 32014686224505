<!--
- Testimonial Row
-
-->

<template>
	<div :class="['quote-single', {'quote-single-last': isLast}]">
		<div class="quote-header">
			<div class="five-star-wrp">
				<img src="/static/images/star.svg" class="img-star" role="presentation" />
				<img src="/static/images/star.svg" class="img-star" role="presentation" />
				<img src="/static/images/star.svg" class="img-star" role="presentation" />
				<img src="/static/images/star.svg" class="img-star" role="presentation" />
				<img src="/static/images/star.svg" class="img-star" role="presentation" />
			</div>
			<p class="name">
				{{ quoteData.name }}
			</p>
		</div>
		<div class="quote">
			<h3>&quot;<span v-html="quoteData.quote" />&quot;</h3>
		</div>
	</div>
</template>

<script>

import testimonialData from '@/data/testimonialData';

export default {
	name: 'TestimonialRow',
	props:{
		isLast:{
			default: false,
			type: Boolean,
		},
		pullIndex:{
			required: true,
			type: Number,
		}
	},
	computed:{
		quoteData(){
			return testimonialData[this.pullIndex];
		}
	},
	components: {
		
	}
}
</script>

<style lang="scss" scoped>
	div.five-star-wrp img.img-star{
		height: 24px;
		float:left;
		margin-right:4px;
		width: 24px;
	}
</style>