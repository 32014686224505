<!-- Commented out / not called from sidebar yet - 2023.05.17 -->
<template>
	<div>
		<div class="row">
			<div class="col-8">
				<h1 class="float-start me-4">
					Calendar
				</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12" ref="divCalendarWrp">
				<!-- <div class="full-calendar-wrp"> -->
					<p>Full Calendar</p>
					<!-- <full-calendar
						class="full-calendar"
						:options="config" /> -->
				<!-- </div> -->
			</div>
		</div>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import dateFormat from "dateformat";
// import FullCalendar from '@fullcalendar/vue';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';

export default {
	name: 'CalendarView',
	computed:{
		// config () {
		// 	return {
		// 		// options				
		// 		contentHeight: window.innerHeight - 235,	// 235 is random to make it fit with the headers and footers
		// 		editable: true,
		// 		selectable: true,
		// 		selectMirror: true,
		// 		dayMaxEvents: true,
				
		// 		events: (info, successCallback, failureCallback)=>{
		// 			this.loadEvents(info, successCallback);
		// 		},//e:events
		// 		// events: this.events,
		// 		// events: [
		// 		// 	{
		// 		// 		title  : 'event1',
		// 		// 		start  : '2023-05-16'
		// 		// 	},
		// 		// ],

		// 		weekends: this.weekendsVisible,
		// 		plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
		// 		headerToolbar: {
		// 			left: 'prev,today,next',
		// 			center: 'title',
		// 			right: 'dayGridMonth,timeGridWeek,timeGridDay'
		// 		},
		// 		initialView: 'dayGridMonth',
				
		// 		// events
		// 		dateClick: this.onDateClick,
		// 		eventClick: this.onEventClick,
		// 		eventDrop: this.onEventDrop,
		// 		eventResize: this.onEventDrop,
		// 		select: this.onDateSelect,


		// 	}
		// },
		

	},
	data() {
		return {
			events: [],
		}
	},
	methods:{
		// onDateClick(){
		// 	console.log('onDateClick')
		// },
		// onDateClick (payload) {
		// 	const title = prompt('Please enter a new title for your event')

		// 	if (!title) {
		// 		return
		// 	}

		// 	const id = (this.events.length + 1) * 10
		// 	const { start, end, date, allDay } = payload

		// 	return this.createEvent({
		// 		id,
		// 		title,
		// 		date,
		// 		start,
		// 		end,
		// 		allDay
		// 	})
		// },

		loadCalendar(){
						
			this.$store.commit('loaders/REMOVE_ID', 'App');
			
		},//loadCalendar

		loadEvents(info, successCallback){
			// console.log('loadEvents');
			// console.log($opts);
			

			

			if(config.isDev){
				// simulate loading events

				successCallback([
					{
						title  : 'Demo Event for Today',
						start  : dateFormat(config.$today, 'yyyy-mm-dd'),
					},
				]);


			} else {
				// test and live envs can access the MVC api
				ApiS_MakeCall({
					method: 'GET',
					outsideSpa: true,
					params: {
						start: dateFormat(info.start, 'yyyy-mm-dd'),
						end: dateFormat(info.end, 'yyyy-mm-dd')
					},
					responseType: 'json',
					url: 'Events/GetCalendarEvents',
				}).then((responseData)=>{
					console.log('events responseData');
					console.log(responseData);
					successCallback(responseData);
				});
			}
		},//e:loadEvents
		
	},
	mounted() {	
		// console.log('calendar mounted')
		AS_SyncData().then(()=>{
			this.loadCalendar();
		});

		
	},
	components: {
		// FullCalendar,
	}
}
</script>

<style lang="scss" scoped>
.full-calendar-wrp{
	background: #fff;
	padding: 1rem;
}
</style>