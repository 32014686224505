var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("generic-modal-wrap", { attrs: { "modal-id": "WordCount" } }, [
    _c("h2", { staticClass: "nodal-title" }, [_vm._v("\n\t\tWord Count\n\t")]),
    _vm._v(" "),
    _c("table", { staticClass: "table table-border table-condensed mb-0" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("Section")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-end" }, [
            _vm._v("\n\t\t\t\t\tWords\n\t\t\t\t"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.documentSections, function (pages, section) {
            return _c("tr", { key: section }, [
              _c("td", [
                _vm._v("\n\t\t\t\t\t" + _vm._s(section) + "\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-end" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(pages) + "\n\t\t\t\t"),
              ]),
            ])
          }),
          _vm._v(" "),
          _c(
            "tr",
            {
              class: {
                "bg-success-100":
                  _vm.$store.state.customer.themeId ===
                  _vm.$enums.Theme.REGULAR,
                "bg-success-700":
                  _vm.$store.state.customer.themeId === _vm.$enums.Theme.DARK,
              },
            },
            [
              _c("td", [_c("strong", [_vm._v("Total")])]),
              _vm._v(" "),
              _c("td", { staticClass: "text-end" }, [
                _c("strong", [_vm._v(" " + _vm._s(_vm.totalCount) + " ")]),
              ]),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }