var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "third-party-row d-flex" }, [
    _c("div", { staticClass: "img-wrp" }, [
      _c("img", { attrs: { src: _vm.calcImgSrc, role: "presentation" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row-content" }, [
      _c("p", [
        _vm.thirdPartyReference.subTitle === ""
          ? _c("span", { key: "noSubtitle" }, [
              _c("span", { staticClass: "row-title" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.thirdPartyReference.title) +
                    "\n\t\t\t\t"
                ),
              ]),
            ])
          : _c("span", { key: "yesSubtitle" }, [
              _c("span", { staticClass: "row-subtitle" }, [
                _vm._v('"' + _vm._s(_vm.thirdPartyReference.title) + '"'),
              ]),
              _vm._v("\t\n\t\t\t\tin "),
              _c("span", { staticClass: "row-title" }, [
                _vm._v(_vm._s(_vm.thirdPartyReference.subTitle)),
              ]),
            ]),
        _vm._v(
          "\n\t\t\t\n\t\t\t(" +
            _vm._s(_vm.thirdPartyReference.typeLabel) +
            ")\n\n\t\t\t"
        ),
        _c("br"),
        _vm._v("\n\n\t\t\t" + _vm._s(_vm.thirdPartyReference.authorInfo) + " "),
        _vm.thirdPartyReference.authorInfo ? _c("br") : _vm._e(),
        _vm._v(
          "\n\n\t\t\t" + _vm._s(_vm.thirdPartyReference.publisherInfo) + " "
        ),
        _vm.thirdPartyReference.publisherInfo ? _c("br") : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-hover btn-hover-success ms-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.selectReferenceType.apply(null, arguments)
              },
            },
          },
          [
            _c("span", { staticClass: "svg bi-plus-lg" }),
            _vm._v(" Use this work\n\t\t\t"),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }