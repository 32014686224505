<!--
- Module Modal > Create Discussion Post
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create Reference List
		</h2>
			
		<p>Reference Lists are great when you need to build a list of references&nbsp;<em>without</em>&nbsp;needing additional text.</p>
		<p>Many students will use Reference Lists to build a bibliography for other programs, like Powerpoint.</p>

		<form>
			<form-select
				label="Paper Format"
				:select-object="paperFormatSelectList"
				v-model="paperType" />

			<form-input
				label="Document Title"
				placeholder="(required)"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED"
				v-model="title" />

			<div class="row">
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="button" class="btn btn-outline-danger" @click.prevent="emitter.emit('closeRouteModal')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="submit" class="btn btn-success" @click.prevent="createReferenceList">
							Create Reference List
						</button>
					</div>
				</div>
			</div><!--row-->
		</form>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormSelect from '@/components/form/Select';
import mixinCreateDocument from '@/mixins/create-document';

export default {
	name: 'ModuleModalCreateReferenceList',
	mixins: [mixinCreateDocument],
	data() {
		return {
			paperType: 0,
			title: '',
			zFormId: 'formCreateReferenceList'
		}
	},
	watch:{
		paperType(paperFormatEnum){
			if(paperFormatEnum == config.enums.PaperType.MLA9_ReferenceList.toString()) {
				this.title = 'Works Cited';
			} else {
				this.title = 'References';
			}
		}
	},
	computed:{
		paperFormatSelectList(){
			let returnPaperFormatSelectList = [
				{
					value: config.enums.PaperType.APA7_ReferenceList.toString(),
					name: "APA 7",
				},
				{
					value: config.enums.PaperType.MLA9_ReferenceList.toString(),
					name: "MLA 9",
				},
			]

			// if(!this.$isLive){
				returnPaperFormatSelectList.push({
					value: config.enums.PaperType.Turabian9_ReferenceList.toString(),
					name: "Turabian 9 - Footnotes",
				});
			// }

			return returnPaperFormatSelectList;
		},//e:paperFormatSelectList
	},
	methods: {
		createReferenceList(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				// main object passed to paper service create
				let sendData = {
					'Title': this.title,
					'PaperType':  parseInt(this.paperType),
					'UseNewCitationMode': true,
				}

				// append data if there should be an accociated class behind the scenes
				if(this.associatedClassId !== 0){
					sendData.ClassID = this.associatedClassId;
					sendData.AssociatedEntityID = this.associatedClassId;
					sendData.AssociatedEntityName = this.associatedClassName;
					sendData.AssociatedEntityTypeID = 2; // class entity type id is 2 in the old vmWare system
				}

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					responseType: 'json',
					url: 'v3/Paper',
				}).then((responseData)=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					// APA7 | MLA9 - keep in spa
					this.$router.push({
						name: 'EditorReferencesCitations',
						params:{
							encryptedId: responseData.encryptedPaperID
						},
					}).catch(()=>{});
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				
			});//e:AS_ValidateForm

		},//e:createReferenceList
	},
	mounted() {
		// refresh here to keep the modal open
		if(this.$route.meta.isPaperCreate){
			this.emitter.emit('openRouteModal', {
				modalRoute: this.$route.name,
				modalParams: {
					associatedClassId: '0',
				},
			});

			// use preferredFormatVersionID to calculate a default paper id
			switch(this.$store.state.customer.preferredFormatVersionID){
				case config.enums.Format.MLA9:
					this.paperType = config.enums.PaperType.MLA9_ReferenceList.toString();
					break;
				case config.enums.Format.Turabian9:
					this.paperType = config.enums.PaperType.Turabian9_ReferenceList.toString();
					break;
				default: // APA7
					this.paperType = config.enums.PaperType.APA7_ReferenceList.toString();
			}

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});
		}
	},
	components: {
		FormInput,
		FormSelect,
	}
}
</script>
