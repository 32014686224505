<!--
- Reference List > Modal > Create Reference Step 1
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>
	
		<p class="org-size d-block">
			Step 1 of 3: Select a reference type
		</p>

		<nav-tabs class="mb-2">
			<nav-tab
				:to="{
					name:'ReferenceListCreateReferenceStep1',
					params: {openReferenceTab: 'common'}
				}">
				Common
			</nav-tab>
			<nav-tab
				:to="{
					name:'ReferenceListCreateReferenceStep1',
					params: {openReferenceTab: 'all'}
				}">
				All types
			</nav-tab>
			<nav-tab
				v-if="$store.state.references.recentTypeIds.length > 0"
				:to="{
					name:'ReferenceListCreateReferenceStep1',
					params: {openReferenceTab: 'recent'}
				}">
				Recent
			</nav-tab>
		</nav-tabs>

		<div v-if="$route.params.openReferenceTab === 'common'" key="searchThirdParty">
			<reference-search-third-party
				:is-drawer="false"
				:is-first-search="true" />
		</div>
		<div v-else-if="$route.params.openReferenceTab === 'all'" key="searchAll">
			<reference-type-search :ref-types="refTypes" />
		</div>
		
		<create-reference-step-1-body :is-drawer="false" />

	</div>
</template>

<script>
import CreateReferenceStep1Body from '@/modules/CreateReferenceStep1/Body'
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';
import ReferenceSearchThirdParty from '@/modules/CreateReferenceStep1/SearchThirdParty';
import ReferenceTypes from '@/services/reference/types';
import ReferenceTypeSearch from '@/modules/CreateReferenceStep1/Search';

export default {
	name: 'ReferenceListCreateReferenceStep1',
	computed:{
		refTypes(){
			return ReferenceTypes.refTypes;
		}
	},
	mounted() {
		if(this.$route.name === 'ReferenceListCreateReferenceStep1'){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReferenceListCreateReferenceStep1',
			});
		}
	},
	components: {
		CreateReferenceStep1Body,
		NavTab,
		NavTabs,
		ReferenceSearchThirdParty,
		ReferenceTypeSearch,
	}
}
</script>
