var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c(
      "div",
      {
        staticClass: "hero text-center hero-dark hero-main hero-text-shadow",
        style: _vm.getStyle,
      },
      [_c("h1", [_vm._v("Getting started with PERRLA")])]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "section-text pt-0" }, [
      _c("main", { staticClass: "container-text mt-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-5" }, [
          _c("div", { staticClass: "col-6 text-center" }, [
            _c(
              "div",
              { staticClass: "d-grid" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary btn-lg btn-extra-padding",
                    attrs: {
                      to: {
                        name: "Dashboard",
                      },
                    },
                  },
                  [
                    _vm._v("\n\t\t\t\t\t\t\tI want to try the "),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\t\t\t\tPERRLA Online Editor\n\t\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t\tEvery PERRLA subscription & free trial comes with both PERRLA Online & PERRLA for Word - so you can use either at any time. Choose which one you'd like to try out first.\n\t\t\t"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h2", [_vm._v("How would you like to write papers with PERRLA?")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 text-center" }, [
      _c("div", { staticClass: "d-grid" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-lg btn-extra-padding",
            attrs: { href: "https://www.perrla.com/#/word-setup" },
          },
          [
            _vm._v("\n\t\t\t\t\t\t\tI want to write with "),
            _c("br"),
            _vm._v("\n\t\t\t\t\t\t\tMicrosoft Word\n\t\t\t\t\t\t"),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/articles/360034402514-PERRLA-Online-vs-PERRLA-for-Word-Add-in-",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [
          _vm._v(
            "\n\t\t\t\t\tNeed help deciding? Check out this article to see how PERRLA best fits your writing style.\n\t\t\t\t"
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }