var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Title Page" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c(
        "form",
        [
          _c("form-input", {
            attrs: {
              label: "Title",
              "form-id": _vm.zFormId,
              "validate-enum": _vm.$enums.Validation.REQUIRED,
            },
            on: { input: _vm.calcRunningHead },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _vm._v(" "),
          _vm.showResearchPaperFields
            ? _c("form-input", {
                attrs: { label: "Author", "form-id": _vm.zFormId },
                model: {
                  value: _vm.author,
                  callback: function ($$v) {
                    _vm.author = $$v
                  },
                  expression: "author",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showResearchPaperFields &&
          _vm.$store.state.paperEdit.meta.PaperFormatVersionID !==
            _vm.$enums.Format.MLA9
            ? _c("form-input", {
                attrs: { label: "Institution", "form-id": _vm.zFormId },
                model: {
                  value: _vm.institution,
                  callback: function ($$v) {
                    _vm.institution = $$v
                  },
                  expression: "institution",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.MLA9
            ? _c(
                "div",
                [
                  _c("form-input", {
                    attrs: { label: "Instructor", "form-id": _vm.zFormId },
                    model: {
                      value: _vm.instructor,
                      callback: function ($$v) {
                        _vm.instructor = $$v
                      },
                      expression: "instructor",
                    },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: {
                      label: "Course Number & Name",
                      "form-id": _vm.zFormId,
                    },
                    model: {
                      value: _vm.courseName,
                      callback: function ($$v) {
                        _vm.courseName = $$v
                      },
                      expression: "courseName",
                    },
                  }),
                  _vm._v(" "),
                  _c("form-datepicker", {
                    attrs: {
                      label: "Date",
                      id: "TP_mla9_dueDate",
                      "form-id": _vm.zFormId,
                      "paper-format": _vm.$enums.Format.MLA9,
                    },
                    on: {
                      "date-set": _vm.dueDateSet,
                      "date-clear": _vm.dueDateClear,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isStudentPaper
            ? _c("div", [
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                _vm.$enums.Format.APA7
                  ? _c(
                      "div",
                      [
                        _c("form-input", {
                          attrs: {
                            label: "Course Number & Name",
                            "form-id": _vm.zFormId,
                          },
                          model: {
                            value: _vm.courseName,
                            callback: function ($$v) {
                              _vm.courseName = $$v
                            },
                            expression: "courseName",
                          },
                        }),
                        _vm._v(" "),
                        _c("form-input", {
                          attrs: {
                            label: "Instructor",
                            "form-id": _vm.zFormId,
                          },
                          model: {
                            value: _vm.instructor,
                            callback: function ($$v) {
                              _vm.instructor = $$v
                            },
                            expression: "instructor",
                          },
                        }),
                        _vm._v(" "),
                        _c("form-datepicker", {
                          attrs: {
                            label: "Date",
                            id: "TP_apa7_dueDate",
                            "form-id": _vm.zFormId,
                            "paper-format": _vm.$enums.Format.APA7,
                          },
                          on: {
                            "date-set": _vm.dueDateSet,
                            "date-clear": _vm.dueDateClear,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                    _vm.$enums.Format.Turabian9
                  ? _c(
                      "div",
                      [
                        _c("form-input", {
                          attrs: {
                            label: "Course Number & Name",
                            "form-id": _vm.zFormId,
                          },
                          model: {
                            value: _vm.courseName,
                            callback: function ($$v) {
                              _vm.courseName = $$v
                            },
                            expression: "courseName",
                          },
                        }),
                        _vm._v(" "),
                        _c("form-input", {
                          attrs: {
                            label: "Instructor",
                            "form-id": _vm.zFormId,
                          },
                          model: {
                            value: _vm.instructor,
                            callback: function ($$v) {
                              _vm.instructor = $$v
                            },
                            expression: "instructor",
                          },
                        }),
                        _vm._v(" "),
                        _c("form-datepicker", {
                          attrs: {
                            label: "Date",
                            id: "TP_turabian9_dueDate",
                            "form-id": _vm.zFormId,
                            "paper-format": _vm.$enums.Format.Turabian9,
                          },
                          on: {
                            "date-set": _vm.dueDateSet,
                            "date-clear": _vm.dueDateClear,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.paperEdit.meta.DocumentType ===
            _vm.$enums.DocumentType.RESEARCH_PAPER &&
          _vm.$store.state.paperEdit.meta.PaperFormatVersionID !==
            _vm.$enums.Format.MLA9 &&
          _vm.$store.state.paperEdit.meta.PaperFormatVersionID !==
            _vm.$enums.Format.Turabian9
            ? _c(
                "div",
                [
                  _vm.showRunningHead
                    ? _c("form-input-running-head", {
                        attrs: {
                          "form-id": _vm.zFormId,
                          "is-removeable": true,
                        },
                        on: { "running-head-toggle": _vm.runningHeadToggle },
                        model: {
                          value: _vm.runningHead,
                          callback: function ($$v) {
                            _vm.runningHead = $$v
                          },
                          expression: "runningHead",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showRunningHead,
                          expression: "!showRunningHead",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c("label", [_vm._v("Running Head")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "help-text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tNot a standard part of a Student Research Paper\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-grid gap-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-hover btn-hover-success",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.runningHeadToggle.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "svg bi-plus-lg" }),
                            _vm._v(" Add running head\n\t\t\t\t\t\t"),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success mt-2",
                attrs: { type: "submit", disabled: !_vm.isSubmitClickable },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.updateTitlePage.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n\t\t\t\t\t\tUpdate Title \n\t\t\t\t\t\t"),
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                _vm.$enums.Format.APA7
                  ? _c("span", [_vm._v("Page")])
                  : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                    _vm.$enums.Format.MLA9
                  ? _c("span", [_vm._v("Section")])
                  : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                    _vm.$enums.Format.Turabian9
                  ? _c("span", [_vm._v("Page")])
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }