<!--
- Tool Drawer
-- Loads different panels (should simulate what the Add-In does)
-- Can live in Outline and Editor tabs
-->

<template>
	<div>
		<div v-show="isDrawerOpen" :class="['tool-drawer-open overflow-x-hidden', {'disable-text-selection': isDrawerDragOn}]" key="divDrawerWrpOpen" :style="calcToolDrawerStyles" ref="toolDrawerWrp">
			
			<loader-component loader-id="EditorTabToolDrawer" />

			<div class="tool-drawer-drag-edge" ref="dragEdge" />
 
			<side-menu :is-editor="isEditor" />
			
			<transition name="route-fade" mode="out-in" appear>
				<router-view />
			</transition>
			
			<drawer-toaster />
		</div>

		<div v-show="!isDrawerOpen" @click.prevent="openDrawer" class="tool-drawer-closed" key="divDrawerWrpClosed" data-bs-toggle="tooltip" data-bs-title="Open Tool Drawer" data-bs-placement="left">
			<span class="svg bi-chevron-left" />
        </div>
	</div>
</template>

<script>
import _throttle from 'lodash/throttle';
import _isEqual from 'lodash/isEqual';
import _forEach from 'lodash/forEach';
import DrawerToaster from './Toaster';
import LoaderComponent from '@/components/Loader';
import SideMenu from './SideMenu';

export default {
	name: 'PaperDrawer',
	props:{
		isEditor:{
			default: false,
			type: Boolean
		},
	},
	data() {
		return {
			drawerStartWidth: '',
			drawerStartX: 0,
			isDrawerDragOn: false,

			doDragFunction: _throttle(this.doDrag, 33, { 'leading': false }),
			stopDragFunction: _throttle(this.stopDrag, 33, { 'leading': false }),
		}
	},
	computed:{
		isDrawerOpen(){
			return this.$store.state.paperEdit.toolDrawer.isOpen;
		},
		calcToolDrawerStyles(){
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				return {
					width: this.$store.state.paperEdit.toolDrawer.openWidth + 'px',
				}
			}
			return {
				width: this.$store.state.paperEdit.toolDrawer.closedWidth + 'px',
			}
		},
	},
	methods: {
		doDrag(e) {
			let setWidth = (this.drawerStartWidth - e.clientX + this.drawerStartX);

			if(setWidth <= 550 && setWidth >= 350){
				this.$store.commit('paperEdit/toolDrawer/SET_OPEN_WIDTH', setWidth);
			}
		},

		openDrawer(){
			if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
				this.$router.push({
					name: 'EditorReferencesCitations'
				}).catch(()=>{});
			} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
				this.$router.push({
					name: 'OutlineResearchNotes'
				}).catch(()=>{});
			}
			
		},//e:openDrawer

		stopDrag(e) {
			// console.log('stopDrag');
			if(this.$refs.toolDrawerWrp){
				this.$refs.toolDrawerWrp.classList.remove("disable-text-selection");
			}
			document.documentElement.removeEventListener('mousemove', this.doDragFunction, false);
			document.documentElement.removeEventListener('mouseup', this.stopDragFunction, false);
		}

	},
	mounted(){
		this.$refs.dragEdge.addEventListener('mousedown', (e) => {
			this.drawerStartX = e.clientX;
			// console.log(this.drawerStartX);
			
			this.drawerStartWidth = parseInt(document.defaultView.getComputedStyle(this.$refs.toolDrawerWrp).width, 10);
			// console.log(this.drawerStartWidth);
			if(this.$refs.toolDrawerWrp){
				this.$refs.toolDrawerWrp.classList.add("disable-text-selection");
			}

			document.documentElement.addEventListener('mousemove', this.doDragFunction);
			document.documentElement.addEventListener('mouseup', this.stopDragFunction);
		}, false);

	},
	components: {
		DrawerToaster,
		LoaderComponent,
		SideMenu,
	},
}
</script>
