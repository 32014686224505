var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Insert Figure" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _vm.showAppendixInsert
        ? _c("div", { key: "divAppendixInsert" }, [
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.APA7
              ? _c("div", { key: "divApa7" }, [
                  _c("h2", [
                    _vm._v("Adding a correct Figure Number in an appendix"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Appendices require unique Figure Numbers in the APA format depending on the content presented in the appendix."
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'Any appendix callouts in your paper should refer to the appendix label directly (e.g., "...as seen in the Appendix.").'
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Figures in a single appendix should be numbered: Figure A1, Figure A2, etc."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Figures in Appendix B or greater should be numbered: Figure B1, Figure C1, etc."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'Any appendix callouts in the body of your paper should refer to the table or figure number directly (e.g., "According to Figure A1...").'
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showAppendixInsert = false
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tInsert a new figure\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _vm._m(3),
          ])
        : _c("div", { key: "divNoAppendixInsert" }, [
            _c("p", [
              _vm._v(
                "Figures help illustration ideas in your paper. They can be photographs, charts, or other images. "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Place your cursor where you want us to insert the figure. Then select the Figure file and add a caption below. PERRLA will create the formatting and manage the figure number for you."
              ),
            ]),
            _vm._v(" "),
            _c("form", [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("form-input", {
                    attrs: {
                      label: "Figure title",
                      "help-text": _vm.calcHelpText,
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Select the figure image")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "help-text mb-1" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\tChoose the location for the figure's image\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col ms-2" },
                      [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectTypeId,
                                expression: "selectTypeId",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "radio",
                              id: "imgOnComputer",
                              name: "imgOnComputer",
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.selectTypeId, 0),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectTypeId = 0
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "imgOnComputer" },
                            },
                            [_vm._v("Image on my computer")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "slide-up-down",
                          { attrs: { active: _vm.selectTypeId === 0 } },
                          [
                            _c("div", { staticClass: "ms-3" }, [
                              _c("input", {
                                ref: "formFileSelect",
                                class: [
                                  "form-control-file mb-1 p-1",
                                  { "file-select-is-invalid": _vm.isFileError },
                                ],
                                attrs: {
                                  type: "file",
                                  "aria-label":
                                    "Select an Image on my computer",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileSelectChange(
                                      $event.target.files
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.isFileError
                                ? _c(
                                    "div",
                                    {
                                      key: "divImageSelectError",
                                      staticClass: "invalid-feedback d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\tPlease select a .jpg, or .png file.\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      key: "divImageSelectHelp",
                                      staticClass: "help-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\tYou can insert .jpg, and .png files as figures.\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectTypeId,
                                expression: "selectTypeId",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "radio",
                              id: "imgFromUrl",
                              name: "imgFromUrl",
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.selectTypeId, 1),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectTypeId = 1
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "imgFromUrl" },
                            },
                            [_vm._v("Image from a URL")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "slide-up-down",
                          { attrs: { active: _vm.selectTypeId === 1 } },
                          [
                            _c("div", { staticClass: "ms-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.imageUrl,
                                    expression: "imageUrl",
                                  },
                                  {
                                    name: "debounce",
                                    rawName: "v-debounce:300ms",
                                    value: _vm.inputImageUrl,
                                    expression: "inputImageUrl",
                                    arg: "300ms",
                                  },
                                ],
                                ref: "inputEnterImageUrl",
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "off",
                                  placeholder: "Enter the image URL",
                                  type: "text",
                                },
                                domProps: { value: _vm.imageUrl },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.imageUrl = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.$store.state.paperEdit.config.allowFigureNotes
                ? _c("div", [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group form-check ms-3 mb-3 mt-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includeNotes,
                              expression: "includeNotes",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "chkIncludeNotes" },
                          domProps: {
                            checked: Array.isArray(_vm.includeNotes)
                              ? _vm._i(_vm.includeNotes, null) > -1
                              : _vm.includeNotes,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.includeNotes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.includeNotes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.includeNotes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.includeNotes = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "chkIncludeNotes" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tInclude notes\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: {
                      type: "submit",
                      disabled: _vm.isFormSubmitDisabled || !_vm.canAddFigure,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.insertFigurePrep.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tInsert Figure\n\t\t\t\t\t")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.isPaperAbstract
              ? _c("p", { staticClass: "text-danger mt-2" }, [
                  _vm._v(
                    "\n\t\t\t\tNote: Inserting a figure in your abstract is not proper formatting for this type of paper.\n\t\t\t"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "text-empty mt-4" }, [
              _vm._v(
                "\n\t\t\t\tOnce your figure has been inserted, adjust the image or edit the text directly in your paper.\n\t\t\t"
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "If the figure is the only element in the appendix, no figure number should be shown."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "The title of the Appendix takes the place of the figure number. "
      ),
      _c("em", [_vm._v("Highlight the figure number text and delete it.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "If the appendix contains other elements, the figure should be numbered according to the Appendix label."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-empty mt-4" }, [
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              href: "https://perrla.zendesk.com/hc/en-us/articles/1260804431829-Figure-Table-Titles-in-Appendices-APA-7-",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [
            _vm._v(
              "\n\t\t\t\t\t\tFor a complete description of figures in appendices, click here.\n\t\t\t\t\t"
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group mb-0" }, [
      _c("label", [
        _vm._v("\n\t\t\t\t\t\t\tFigure notes "),
        _c("span", { staticClass: "font-weight-normal" }, [
          _vm._v("(optional)"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "help-text" }, [
        _vm._v(
          "\n\t\t\t\t\t\t\tFigure notes are used to clarify information in your figure.\n\t\t\t\t\t\t"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }