/*
- Currency
-- formatter for currency, nothing flashy
-- 2022.10.06
*/

export default ($opts) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format($opts.value);
}
