<!--
- GridSearch
-- So far used to filter grid data (Papers List / References List)
-->

<template>
	<div class="d-flex">
		<div class="w-100">
			<input
				class="form-control form-control-grid-search"
				:placeholder="placeholderText"
				type="text"
				v-model="searchTerm"
				v-debounce:500ms="emitToParentGrid" />
		</div>
		<div class="flex-shrink-1" v-show="searchTerm && searchTerm.length > 0">
			<button class="btn btn-link hover-underline" @click.prevent="clearSearch" type="button">
				Clear
			</button>
		</div>
	</div> 
</template>

<script>

export default {
	name: 'GridSearch',
	props:{
		placeholderText:{
			default: 'Search',
			type: String
		},
	},
	data() {
		return {
			searchTerm: '',
		}
	},
	methods: {
		clearSearch(){
			this.searchTerm = '';
			this.emitToParentGrid();
		},
		emitToParentGrid(){
			this.$store.commit('gridSorts/SEARCH_TERM', this.searchTerm);
			this.$emit('update-grid');
		},
	},
	destroyed(){
		this.$store.commit('gridSorts/SEARCH_TERM', '');
	}
}
</script>
