<!--
- Single Appendix Instance
-
-->

<template>
	<div>
		<div :class="calcInstanceStyles" :id="calcInstanceIdWrp">
			<paper-running-head page-type="appendix" />

			<h1 :class="['text-center', {'fw-bold': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7, 'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9}]">
				{{ appendixData.label }}
			</h1>
			<textarea :id="calcInstanceId" :value="appendixContentValue" class="d-none" autocomplete="off" />
		</div>
		<div class="d-grid">
			<button type="button" class="btn btn-outline-danger btn-outline-white-bg btn-page-group mb-2" @click.prevent="btnDeleteAppendixConfirm">
				Delete {{ appendixData.label }}
			</button>
		</div>
	</div>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import PaperRunningHead from './RunningHead';
import PApS_CleanUp from '@/services/paper/appendices/cleanUp';
import PCKS_OnChange from '@/services/paper/ck/onChange';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_SaveDocument from '@/services/paper/saveDocument';
import Swal from 'sweetalert2';

export default {
	name: 'AppendixInstance',
	props:{
		appendixData:{
			required: true,
			type: Object,
		}
	},
	data() {
		return {
			appendixContentValue: '',
		}
	},
	computed:{
		calcInstanceId(){
			return 'ck_appendix_' + this.appendixData.uid;
		},
		calcInstanceStyles(){
			let returnClassList = [
				'instance-appendix',
			]

			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnClassList.push('page-group page-group-min-height', 'page-group-apa7');
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
				returnClassList.push('page-group page-group-min-height', 'page-group-mla9');
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnClassList.push('page-group page-group-min-height', 'page-group-turabian9');
			}
			
			return returnClassList;
		},
		calcInstanceIdWrp(){
			return 'appendixWrp_' + this.appendixData.uid;
		},
	},
	methods: {
		btnDeleteAppendixConfirm(){

			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Are you sure?',
				html: `
					<p>This will remove this appendix and its content.</p>
					<p>If you delete this appendix, the remaining appendices may be renamed. Be sure to check any call-outs in the body to make sure they refer to the correct appendix.</p>
				`,
				icon: 'warning',
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Delete',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					let findAppendixIndex = this.$store.state.paperEdit.appendices.inPaper.findIndex((appendixData)=>{
						return appendixData.uid.toUpperCase() === this.appendixData.uid.toUpperCase();
					});
					if(findAppendixIndex != -1){
						this.$store.commit('paperEdit/appendices/REMOVE_FROM_PAPER_AT_INDEX', findAppendixIndex);

						PS_SaveDocument({
							isMetaDataChanges: true,
							isStealthSave: true,
							message: 'Deleted Appendix ' + this.appendixData.label,

						}).then(()=>{

							// remove ck instance 
							if(CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()]){
								CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()].focusManager.blur(true);
								CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()].destroy(true);
							}

							PApS_CleanUp().then((cleanedAppendicesInPaper)=>{
								this.$store.commit('paperEdit/appendices/IN_PAPER', cleanedAppendicesInPaper);
								
								this.emitter.emit('globalToasterOpen',{
									textContent: 'Appendix removed',
									variant: 'danger'
								});

								this.$store.dispatch('paperEdit/toc/generateContent').then(()=>{
									// force focus on the paper body ckInstance
									if(_has(CKEDITOR.instances, 'ckPaperBody')){
										CKEDITOR.instances.ckPaperBody.focus();
									}
								});//e:dispatch:paperEdit/toc/generateContent
							});//e:PApS_CleanUp
						});//e:PS_SaveDocument
					}//e:if:findAppendixIndex
				}//e:if:result.value
			});//e:Swal.fire
		},//e:btnDeleteAppendixConfirm

		loadAppendix(isNewAppendix){
			// this probably can't happen but good to check just incase
			if(CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()]){
				console.log('load appenedix but already here')
				return false;
			}

			// load appendix
			let configCkAppendix = configCkEditor.getSettings('ckAppendices');
			
			this.appendixContentValue = this.appendixData.content;

			let $this = this;

			configCkAppendix.on = {
				change(evt) {
					PCKS_OnChange({
						editorName: evt.editor.name,
					});
				},//e:change
				focus(evt) {
					PCKS_OnFocus({
						editorName: evt.editor.name,
					});
				},
				instanceReady(evt) {
					evt.editor.$ckEditorType = config.enums.CkEditorType.APPENDIX;
					evt.editor.$appendixLabel = $this.appendixData.label;

					CKEDITOR.on('dialogDefinition', function (ev) {
						ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
					});
					
					PCKS_wProofreaderInit({
						container: evt.editor.window.getFrame().$,
					}).then(()=>{
						// this probably will never fire since the PaperBody will have to also load
					});

					// focus a new instance when created - https://stackoverflow.com/questions/7658319/set-caret-to-end-after-focus-ckeditor
					if(isNewAppendix){
						evt.editor.focus();
		
						// let s = evt.editor.getSelection(); // getting selection
						// let selected_ranges = s.getRanges(); // getting ranges
						// let node = selected_ranges[0].startContainer; // selecting the starting node
						// let parents = node.getParents(true);

						// node = parents[parents.length - 2].getFirst();

						// while (true) {
						// 	let x = node.getNext();
						// 	if (x == null) {
						// 		break;
						// 	}
						// 	node = x;
						// }

						// s.selectElement(node);
						// selected_ranges = s.getRanges();
						// selected_ranges[0].collapse(false);  //  false collapses the range to the end of the selected node, true before the node.
						// s.selectRanges(selected_ranges);  // putting the current selection there
						
					}
				},
			}

			CKEDITOR.replace(this.calcInstanceId, configCkAppendix);
		},
	},
	created() {
		this.emitter.on('loadCkAppendices', ()=>{
			this.loadAppendix(false);
		});

		this.emitter.on('createNewAppendix', (newUUID)=>{
			if(this.appendixData.uid.toUpperCase() === newUUID.toUpperCase()){
		
				this.loadAppendix(true);

				// scroll new appendix into view
				let $newInstnaceWrp = document.getElementById('appendixWrp_' + newUUID);
				if($newInstnaceWrp){
					$newInstnaceWrp.scrollIntoView('smooth');
				}
			}
		});
	},
	components:{
		PaperRunningHead,
	},
	destroyed(){
		this.emitter.off('createNewAppendix');
		this.emitter.off('loadCkAppendices');
			
		if(CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()]){
			CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()].focusManager.blur(true);
			CKEDITOR.instances['ck_appendix_' + this.appendixData.uid.toUpperCase()].destroy(true);
		}
	},
}
</script>
