<!--
- PaperFootnotesRow
-- A single row for a reference in the Paper Footnotes
-- probably unused if this becomes a CkEditor but keep here for now
-->

<template>
	<div
		:class="['paper-footnote-single cursor-pointer', {'paper-footnote-menu-open':isMenuOpen, 'paper-footnote-single-selected': isSelected}]"
		:citation-find-by-id="footnoteData.findById"
		:footnote-index="footnoteIndex"
		@click.prevent="toggleSelectFootnote"
		@dblclick.prevent="btnEditFootnote('citation')"
		v-html="footnoteContent" />
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';

export default {
	name: 'FootnotesRow',
	props:{
		footnoteData:{
			required: true,
			type: Object,
		},
		footnoteIndex:{
			required: true,
			type: Number,
		},
	},
	computed:{
		footnoteContent(){
			let _footnoteData = _cloneDeep(this.footnoteData);	// clone this footnote so i can modify it's properties
			// console.log(_footnoteData);
			
			let citationObjectFound = null;
			
			// i need to get citation display value, that only lives in referencesInPaper, so i have to use that one
			this.$store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
				// console.log('referenceObject.citations');
				// console.log(referenceObject.citations);
				
				if(_has(referenceObject, 'citations')){
					referenceObject.citations.forEach((citationObject)=>{
						// console.log('citationObject');
						// console.log(citationObject);
						if(_has(citationObject, 'groupUniqueID')){
							// merged citation pull them all out based on groupUniqueId - but all i really need is citation displayValue
							if(citationObject.groupUniqueID.toUpperCase() === _footnoteData.findById.toUpperCase()){
								citationObjectFound = citationObject;
							}
						} else {
							if(citationObject.citationData.citationUniqueID.toUpperCase() === _footnoteData.findById.toUpperCase()){
								citationObjectFound = citationObject;
							}
						}
					});//e:forEach
				}//e:if
			});//e:forEach
			
			if(citationObjectFound){
				let citationValue = citationObjectFound.displayValue;
				
				let trimFootnote = _footnoteData.content.replace('@@@', '&nbsp;' + citationValue);	// replace the citation placeholder with a display value
			
				trimFootnote = trimFootnote.slice(3); 	// remove the first <p> so i can put the footnote index just inside it
					
				return '<p>' + (this.footnoteIndex + 1) + '.&nbsp;' + trimFootnote;
			}
		

			return '';
		},

		isMenuOpen(){
			if(this.$store.state.paperEdit.footnotes.contextMenuOpenOnCitationFindById === this.footnoteData.findById){
				return true;
			}
			return false;
		},

		isSelected:{
			get(){
				if(this.$store.state.paperEdit.footnotes.contextMenuSelectedOnFindById === this.footnoteData.findById){
					return true;
				}
				return false;
			},
			set(newValue){
				if(newValue){
					this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_SELECT_ON_CITATION_FIND_BY_ID', this.footnoteData.findById);
				} else {
					this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_SELECT_ON_CITATION_FIND_BY_ID', '');
				}
			},
		},
	},
	data(){
		return {
			firstCitationUniqueId: null,
		}
	},
	methods:{
		btnEditFootnote(editType){
			if(this.footnoteData.findById){
				// loop throught citations in this paper
				_forEach(this.$store.state.paperEdit.citations.inPaper, (citationObject)=>{
					if(_has(citationObject, 'groupUniqueID')){
						// group
						if(citationObject.groupUniqueID.toUpperCase() === this.footnoteData.findById.toUpperCase()){
							this.firstCitationUniqueId = citationObject.citationData.citationUniqueID.toUpperCase();
							return false;
						}
					} else {
						// single
						if(citationObject.citationData.citationUniqueID.toUpperCase() === this.footnoteData.findById.toUpperCase()){
							this.firstCitationUniqueId = citationObject.citationData.citationUniqueID.toUpperCase();
							return false;
						}
					}
				});//e:forEach:citations.inPaper

				if(this.firstCitationUniqueId){
					this.$router.push({
						name:'EditorCitationEdit',
						params: {
							citationUid: this.firstCitationUniqueId,
							openCitationTab: (editType === 'text') ? config.enums.CitationTab.FOOTNOTE : config.enums.CitationTab.CITATION,
						}
					}).catch(()=>{});
				}
			}
		},//e:btnEditFootnote

		toggleSelectFootnote(){
			this.isSelected = !this.isSelected;
		},//e:btnSelectFootnote
	},
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/_vars_perrla.scss";

.paper-footnote-single{
	border: 1px solid $white;
	font-family: $font-family-paper;
	position: relative;
	text-indent: $paper-indent-width;
	margin-bottom: 0.25rem;
	padding: 0.25rem;

	&:hover, &.paper-footnote-menu-open{
		background-color: $secondary-100;
	}
	&.paper-footnote-single-selected {
		border-color: $primary;
	}

}
</style>