<!--
- Form Checkbox
-- 2022.09.22
-->

<template>
	<div :class="calcClasses">
		<input
			class="form-check-input"
			type="checkbox"
			:id="safeId"
			v-model="isChecked"
			@change="$emit('input', $event.target.checked)" />

		<label :for="safeId" class="form-check-label cursor-pointer" v-html="label" />
	</div>
</template>

<script>
import mixinId from '@/mixins/id';

export default {
	name: 'FormCheckbox',
	mixins: [mixinId],
	props:{
		checkType:{
			default: '',
			type: String,
		},
		label: {
			type: String,
		},
		value: {
			type: Boolean,
		},
	},
	computed:{
		calcClasses(){
			let returnString = 'form-check ';

			// checkbox
			if(this.checkType != ''){
				returnString += 'form-check-' + this.checkType;
			}
			return returnString;
		},
		isChecked:{
			get(){
				return this.value;
			},
			set(isSet){
				// console.log('isChecked set');
				// console.log(isSet);
			},
		},
	},
}
</script>


<style lang="scss" scoped>
	@import '../../assets/styles/vars_perrla.scss';

	.form-check-success {
		.form-check-input:checked {
			background-color: $success;
			border-color: $success;
		}
	}

	.form-check-danger {
		.form-check-label {
			color: $danger;
		}

		.form-check-input:checked {
			background-color: $danger;
			border-color: $danger;
		}
	}

</style>