<!--
- Paper Appendicies
- Dev and Test Only
-- https://docs.google.com/document/d/1YsEFzHSJNtm6CPL8XADs7sLHuqrnVEKoGsKvbqEptf4/edit#heading=h.6vghiy4z5eg8
-->

<template>
	<div>
		<appendix-instance v-for="appendixData in appendicesInPaper" :key="appendixData.uid" :appendix-data="appendixData" />

		<div class="d-grid">
			<button :class="['btn btn-success btn-page-group', { 'mb-2': $store.state.paperEdit.meta.PaperFormatVersionID != $enums.Format.Turabian9}]" type="button" @click.prevent="addAppendix">
				Add Appendix
			</button>
		</div>
	</div>
</template>

<script>
import AppendixInstance from './AppendixInstance';
import PApS_AddToPaper from '@/services/paper/appendices/addToPaper';

export default {
	name: 'PaperAppendicies',
	computed:{
		appendicesInPaper(){
			return this.$store.state.paperEdit.appendices.inPaper;
		},
	},
	methods: {
		addAppendix(){
			PApS_AddToPaper().then((newUUID)=>{
				this.emitter.emit('createNewAppendix', newUUID);
			});
		},
	},
	components: {
		AppendixInstance
	},
}
</script>

