<!--
- Reference Edit > Details Tab
-->
<template>
	<nav-tab-content>
		<div class="max-width-900">
			<edit-reference-body :is-drawer="false" />
		</div>
	</nav-tab-content>
</template>

<script>
import EditReferenceBody from '@/modules/EditReferenceBody';
import NavTabContent from '@/components/NavTabContent';

export default {
	name: 'TabDetails',
	components:{
		EditReferenceBody,
		NavTabContent,
	},
}
</script>
