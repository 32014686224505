var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "ResearchNotesAddReferenceFromLibrary" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tAdd reference from library\n\t"),
      ]),
      _vm._v(" "),
      _c("add-reference-from-library-header", {
        attrs: { "is-drawer": false },
      }),
      _vm._v(" "),
      _c("add-reference-from-library-body", { attrs: { "is-drawer": false } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "d-grid mt-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$store.commit(
                      "modals/CLOSE",
                      "ResearchNotesAddReferenceFromLibrary"
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tFinished\n\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }