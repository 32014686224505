/*
- Grid Sort Orders
-- saves into localStorage
-- 2023.01.16
*/

export default {
	namespaced: true,
	state: {
		addFromLibraryIsByName: true,

		classListOrderBy:	'title',
		classListSorts: {
			'title': 1,
			'teacherName': -1,
		},

		paperListOrderBy:	'lastModifiedDate',
		paperListSorts: {
			'dueDateDisplay': 1,
			'lastModifiedDate': -1,
			'title': 1,
		},

		referenceListOrderBy:	'createdDate',
		referenceListSorts: {
			'title': 1,
			'createdDate': -1,
		},

		reminderListOrderBy:	'dueDate',
		reminderListSorts: {
			'dueDate': 1,
			'name': 1,
		},
		
		searchTerm: '',	// each grid has it's own search component
	},
	mutations: {
		CLASS_LIST_ORDER_BY(state, value) {
			state.classListOrderBy = value;
		},
		CLASS_LIST_SORTS_TOGGLE(state, key){
			state.classListSorts[key] = state.classListSorts[key] * -1;
		},
		PAPER_LIST_ORDER_BY(state, value) {
			state.paperListOrderBy = value;
		},
		PAPER_LIST_SORTS_TOGGLE(state, key){
			state.paperListSorts[key] = state.paperListSorts[key] * -1;
		},
		REFERENCE_LIST_ORDER_BY(state, value) {
			state.referenceListOrderBy = value;
		},
		REFERENCE_LIST_SORTS_TOGGLE(state, key){
			state.referenceListSorts[key] = state.referenceListSorts[key] * -1;
		},
		REMINDER_LIST_ORDER_BY(state, value) {
			state.reminderListOrderBy = value;
		},
		REMINDER_LIST_SORTS_TOGGLE(state, key){
			state.reminderListSorts[key] = state.reminderListSorts[key] * -1;
		},
		SEARCH_TERM(state, value){
			state.searchTerm = value.toLowerCase();
		},
		SET_ADD_FROM_LIBRARY_IS_BY_NAME(state, value){
			state.addFromLibraryIsByName = value;
		},

		
	},
}