var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: {
            title: "Add Citation",
            "close-to-route-name": "OutlineReferencesCitations",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "\n\t\t\tBefore creating the citation, place your cursor where you want the citation to appear.\n\t\t"
        ),
      ]),
      _vm._v(" "),
      _c(
        "form",
        [
          _c("citation-form", {
            attrs: {
              "paper-format-version-id": _vm.paperFormatVersionID,
              "reference-unique-id":
                _vm.$route.params.referenceUid.toUpperCase(),
            },
            on: {
              enableCitationFormButton: function ($event) {
                _vm.isDisabled = false
              },
              disableCitationFormButton: function ($event) {
                _vm.isDisabled = true
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c(
                "div",
                { staticClass: "d-grid" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-outline-danger",
                      attrs: { to: { name: "OutlineReferencesCitations" } },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    ref: "btnCreateCitation",
                    staticClass: "btn btn-success",
                    attrs: { type: "submit", disabled: _vm.isDisabled },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnAddCitation.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\tCreate Citation\n\t\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }