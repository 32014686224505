<!--
- RowSlot
-- Row with an offset where the label goes
-->

<template>
	<div class="form-row-slot-v2 row mb-3">
		<div class="col-label">
			<label
				v-if="label"
				class="form-label form-label-v2"
				v-html="label" />
		</div>

		<div :class="{'col-value-full-width': isFullWidth, 'col-value': !isFullWidth }">
			<slot />
		
		</div><!--col-->
	</div>
</template>

<script>

export default {
	name: 'RowSlot',
	props:{
		isFullWidth:{
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
	},
	data() {
		return {
		}
	},
	computed:{

	},
	methods: {

	},
	created() {
	},
	mounted() {
	},
	components: {
	}
}
</script>
