<!--
- Settings > Your Papers 
-- View created for expired users, they can only download the Simplified Word File
-- https://magical-scratch.webflow.io/public-site/account/settings
-->

<template>
	<nav-tab-content>
		<div class="row">
			<div class="col">
				<table v-if="filteredGridData && filteredGridData.length > 0" class="table table-borderless entity-grid">
					<thead>
						<tr>
							<th>Title</th>

							<th class="table-spacer" />
							
							<th>Modified</th>

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<paper-row
							v-for="paperData in filteredGridData"
							:paper-id="paperData.paperID"
							:paper-is-complete="paperData.isComplete"
							:paper-last-modified-date="paperData.lastModifiedDate"
							:paper-title="paperData.title"
							:key="paperData.paperID"
							context="PaperList"
							loader-id="PaperList" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5" key="divNoPaperData">
					<p>No papers found.</p>
				</div>
			</div><!--col-->
		</div><!--row-->
	</nav-tab-content>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import HelperIsGenesisPaper from '@/helpers/is-genesis-paper';
import NavTabContent from '@/components/NavTabContent';
import PaperRow from './YourPapers/PaperRow.vue';

export default {
	name: 'SettingsYourPapers',
	data() {
		return {
			filteredGridData: [],
		}
	},
	methods: {
		calcGridData(){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.paperList.list);	// clone so i can add a sortBy property 

			// loop through each paper and prep for display
			_data.forEach((paperData) =>{
				let isGenesisPaper = HelperIsGenesisPaper.checkFormatVersion(paperData.paperFormatVersionID);

				// do not include Add-In papers, or any non valid formats, or any non genesis papers
				if(!paperData.isOnlinePaper || !isGenesisPaper || !_includes(config.enums.Format, paperData.paperFormatVersionID)){
					return false;
				}

				if(_has(paperData, 'lastModifiedDate') && paperData.lastModifiedDate != '') {
					paperData.sortByLastModifiedDate = paperData.lastModifiedDate;	// remove hh:mm:ss so you can sort down to the day
				} else {
					paperData.sortByLastModifiedDate = '2000-01-01';
				}

				// due date isn't required - if you get back the default server value just blank it out on display
				if(!_has(paperData, 'dueDate') || paperData.dueDate === '2099-01-01T00:00:00') {
					paperData.dueDateDisplay = '';
				} else {
					paperData.dueDateDisplay = paperData.dueDate;
				}
			
				searchedArray.push(paperData);

			});//e:forEach

			this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'paperID'), ['sortByLastModifiedDate', (gridData) => gridData.title.toLowerCase()], ['desc', 'asc']);

			this.$nextTick(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		
		},
	},
	mounted() {
		// reset the last sync date so the sync can grab all the papers
		this.$store.commit('paperList/CLEAR_LIST');
		this.$store.commit('LAST_SYNC_DATE', '');

		// start off with a full sync - this will update the vuex store for this customer
		AS_SyncData().then(()=>{
			
			// Account Expired - make sure the light theme is active
			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');
			
			this.calcGridData();

			this.$store.commit('loaders/REMOVE_ID', 'App');
		});	//e:AS_SyncData
	},
	components: {
		NavTabContent,
		PaperRow
	}
}
</script>
