var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c(
      "div",
      { staticClass: "max-width-900" },
      [_c("edit-reference-body", { attrs: { "is-drawer": false } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }