<!--
- Paper Edit > Editor Tab > Tool Drawer > Outline > Single Row of outline output
-
-->

<template>
	<div
		:class="calcLevelClass"
		:data-id="nodeData.id"
		:id="calcOutlineNodeId"
		draggable="true">
		<div class="svg-wrp">
			<a href="#" class="svg-link svg-link-add float-end" @click.prevent="btnAddOutlineNode" title="Add Outline Row">
				<span class="svg bi-plus-lg" />
			</a>

			<a href="#" class="svg-link svg-link-toggle float-end" @click.prevent="toggleIsActive" title="Toggle Checked">
				<span class="svg svg-link-toggle bi-check-lg" />
			</a>
		</div>

		<p class="outline-node-content">
			<span class="prefix">{{ calcPrefix }}</span>
			<span class="outline-node-text" v-html="nodeData.content" />
		</p>
		<p
			v-if="$store.state.paperEdit.config.renderCitationsAsFootnotes && calcCitationOutput != ''"
			:class="['outline-node-citation mb-0 float-end']">
			<span v-html="calcCitationOutput" />
		</p>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import InsertRawHtml from '@/helpers/insert-raw-html';
import outlinePrefixes from '@/config/outlinePrefixes';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import {v4 as uuidv4} from 'uuid';
import Vue from 'vue';

export default {
	name: 'OutlineRow',
	props:{
		nodeData:{
			required: true,
			type: Object,
		},
	},
	data() {
		return {
		}
	},
	computed:{
		calcCitationOutput(){
			// is there more than one citation here?
			if(_has(this.nodeData, 'content') && this.nodeData.content !== ''){
				let parsedHtml = new DOMParser().parseFromString(this.nodeData.content, 'text/html');
				let citationElements = parsedHtml.querySelectorAll('span.perrla_citation');
				
				// only a single citation will return a citation output
				if(
					citationElements.length === 1 &&
					_has(this.nodeData, 'citation') && 
					!_isEmpty(this.nodeData.citation)
				){
					switch(this.$store.state.paperEdit.meta.PaperFormatVersionID){
						case config.enums.Format.Turabian9:
							if(_has(this.nodeData.citation, 'turabian9') && _has(this.nodeData.citation.turabian9, 'subsequent')){
								return this.nodeData.citation.turabian9.subsequent;
							}
							break;
					}
				}
			}
		
			return '';

		},//e:calcCitationOutput

		calcLevelClass(){
			let classArray = ['outline-node'];

			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				let findIndex = this.$store.state.paperEdit.outline.activeNodes.findIndex((outlineNodeData)=>{
					return outlineNodeData.nodeId === this.nodeData.id && outlineNodeData.paperId === this.$store.state.paperEdit.openObject.paperID
				});
				if(findIndex > -1){
					classArray.push('outline-node-row-in-paper');
				}
			}
		
			classArray.push('level-' + this.nodeData.level);

			return classArray;
		},//e:calcLevelClass

		calcPrefix(){
			if(outlinePrefixes[this.nodeData.level]){
				return outlinePrefixes[this.nodeData.level][this.nodeData.prefixLevel] + '. ';
			}
			return '';
		},

		calcOutlineNodeId(){
			return 'DragOutlineNode_' + this.nodeData.id;
		},
	},
	methods: {
		btnAddOutlineNode(){
			// console.log('btnAddOutlineNode');

			let insertText = '<p>' + this.nodeData.content + '&nbsp;</p>';
			
			// console.log('insertText');
			// console.log(insertText);

			InsertRawHtml.insertText(insertText);
			
			let replaceCitationUniqueId;

			if(this.nodeData.citation){
				replaceCitationUniqueId = this.nodeData.citation.citationData.citationUniqueID;
			}

			// after the citation is inserted update the values (this pays off when you have a group author with abbreviation and subsequent citations)
			Vue.nextTick(()=>{
				// inject the citation unique id on the element just injected
				let $citationNode = null;

				$citationNode = CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused].document.findOne('span[replace-citation-unique-id="' + replaceCitationUniqueId + '"]');

				if($citationNode){
					let newlyGeneratedCitationUniqueId = uuidv4().toUpperCase();
					$citationNode.setAttribute('citation-unique-id', newlyGeneratedCitationUniqueId);
					$citationNode.setAttribute('data-citation-unique-id', newlyGeneratedCitationUniqueId);
					$citationNode.removeAttribute('replace-citation-unique-id');

					let addCitationData  = _cloneDeep(this.nodeData.citation);
					addCitationData.citationData['citationUniqueID'] = newlyGeneratedCitationUniqueId;

					if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						this.$store.commit('paperEdit/citations/ADD_TO_PAPER', addCitationData);
					}

					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
						this.$store.commit('paperEdit/footnotes/ADD_TO_PAPER', {
							content: '<p>@@@</p>',
							findById: newlyGeneratedCitationUniqueId,
						});
					}

					// after the citation is inserted update the values (this pays off when you have a group author with abbreviation and subsequent citations)
					Vue.nextTick(()=>{
						PS_BuildReferencesInPaper().then(()=>{
							if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
								this.$store.commit('paperEdit/outline/ADD_ACTIVE_NODE', this.nodeData.id);
							}
						});//e:PS_BuildReferencesInPaper
					});

				} else {
					Vue.nextTick(()=>{
						if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
							this.$store.commit('paperEdit/outline/ADD_ACTIVE_NODE', this.nodeData.id);
						}
					});
				}
			
			});
			
		},//e:btnAddOutlineNode


		toggleIsActive(){
			this.$store.commit('paperEdit/outline/TOGGLE_ACTIVE_NODE', this.nodeData.id);
		},//e:toggleIsActive
	},
	mounted() {
		CKEDITOR.document.getById(this.calcOutlineNodeId).on('dragstart', (dragstartEvt) => {
			CKEDITOR.plugins.clipboard.initDragDataTransfer(dragstartEvt);
			
			let dataTransfer = dragstartEvt.data.dataTransfer;

			dataTransfer.setData('outlineNodeFull', this.nodeData);
			
			// text only fallback
			dataTransfer.setData('text/html', this.nodeData.content);
		});
	},
}
</script>

<style lang="scss" scoped>
	@import './../../../../../assets/styles/vars_perrla.scss';

	@include color-mode(dark) {
		.outline-node {
			background: $extra-dark;
			border: 1px solid $extra-dark;
			
			&:hover{
				border-color: $secondary-500;
			}

			div.svg-wrp {
				background-color: $extra-dark;

				a.svg-link{
					&.svg-link-add {
						background: $dark-primary;
						&:hover {
							background-color: $extra-dark;
							color: $dark-primary;
						}
					}
					&.svg-link-toggle {
						background: $success;
						&:hover {
							background-color: $extra-dark;
							color: $success;
						}
					}
				}
			}


			&.outline-node-row-in-paper{
				background: $success-300;
				border-color: $success-300;
				
				div.svg-wrp {
					background-color: $success-300;
				}

				&:hover {
					border-color: $success-300;

					div.svg-wrp {
						a.svg-link{
							&.svg-link-add {
								&:hover {
									background-color: $success-300;
								}
							}
							&.svg-link-toggle {
								&:hover {
									background-color: $success-300;
								}
							}
						}
					}
				}
				.outline-node-content {
					color: $success-700;
				}
			}
		}
		
	}

	.outline-node{
		background-color: $card-bg;
		border: 1px solid $card-bg;
		border-radius: $border-radius;
		cursor: move;
		font-size:14px;
		line-height: 21px;
		margin: 0 0 0.75rem 0;
		overflow: hidden;
		padding: 0.5rem 0.5rem 1rem;
		position: relative;

		&.level-0 {margin-left: 0;}
		&.level-1 {margin-left: 12px;}
		&.level-2 {margin-left: 24px;}	// add the left border for each one
		&.level-3 {margin-left: 36px;}
		&.level-4 {margin-left: 48px;}
		&.level-5 {margin-left: 60px;}
		&.level-6 {margin-left: 72px;}
		&.level-7 {margin-left: 84px;}
		&.level-8 {margin-left: 96px;}
		&.level-9 {margin-left: 108px;}
		&.level-10 {margin-left: 120px;}

		div.svg-wrp {
			background-color: $card-bg;
			padding-left: 0.5rem;
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			visibility: hidden;
				
			a.svg-link{
				border-radius: $border-radius;
				color: $body-bg;
				float: right;


				&.svg-link-add {
					background: $primary;
					margin-left: 4px;
					&:hover {
						background-color: $card-bg;
						color: $primary;
					}
				}
				&.svg-link-toggle {
					background: $success;
					&:hover {
						background-color: $card-bg;
						color: $success;
					}
				}

				.svg {
					font-size: 16px;
					line-height: 1;
					padding: 0.25rem 0.25rem 0.25rem 0.25rem;
				}
			}
		}

		&:hover{
			div.svg-wrp {
				visibility: visible;
			}
		}

		p.outline-node-content {
			font-size: 1rem;
			line-height: 1.5rem;
			margin-bottom: 0;
			padding-bottom: 0;
			

			.prefix{
				font-weight: $font-weight-bold;
			}
		}


		.outline-node-citation {
			color: $success-500;
			font-size: 0.8rem;
			font-weight: 400;
			line-height: 0.8rem;
			padding-top: 0.5rem;
		}

		&.outline-node-row-in-paper{
			background: $success-100;
			border-color: $success-100;

			div.svg-wrp {
				background-color: $success-100;
			}

			&:hover {
				border-color: $success-300;

				div.svg-wrp {
					a.svg-link{
						&.svg-link-add {
							&:hover {
								background-color: $success-100;
							}
						}
						&.svg-link-toggle {
							&:hover {
								background-color: $success-100;
							}
						}
					}
				}
			}
		}
		
	}//.outline-node
</style>