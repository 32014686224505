var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showJsonRefData
    ? _c("section", { staticClass: "mb-4 bg-secondary p-2" }, [
        _c("input", {
          staticClass: "form-control form-control-sm mb-1",
          attrs: { type: "text", "aria-label": "JSON Ref Data" },
          domProps: { value: _vm.jsonRefData },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-clipboard btn-success btn-sm",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.copyJsonData.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\tCopy\n\t\t")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }