<!--
- Reference Edit > Citations Tab
-->
<template>
	<nav-tab-content>
		<div class="max-width-900">

			<citation-form
				:paper-format-version-id="$store.state.references.displayFormatVersionId"
				:reference-unique-id="$route.params.referenceUid.toUpperCase()"
				@enableCitationFormButton="isDisabled = false"
				@disableCitationFormButton="isDisabled = true" />
		</div>
	</nav-tab-content>
</template>

<script>
import CitationForm from '@/modules/CitationForm';
import NavTabContent from '@/components/NavTabContent';
import RS_LoadByRoute from '@/services/reference/loadByRoute';

export default {
	name: 'TabCitations',
	mounted(){
		RS_LoadByRoute();
	},
	components:{
		CitationForm,
		NavTabContent
	},
}
</script>
