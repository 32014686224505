var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: [
        "tr-entity-grid-row tr-entity-grid-primary",
        { "tr-entity-grid-row-success": _vm.isComplete },
      ],
    },
    [
      _c("td", { staticClass: "td-entity-icon" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isComplete,
                expression: "isComplete",
              },
            ],
            attrs: { href: "#", title: "Mark Finished" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleComplete.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg bi-check-lg" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isComplete,
                expression: "!isComplete",
              },
            ],
          },
          [
            _c(
              "a",
              {
                staticClass: "svg",
                attrs: { href: "#", title: "Mark Unfinished" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleComplete.apply(null, arguments)
                  },
                },
              },
              [_c("span", { staticClass: "svg icons8-unchecked-checkbox" })]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "td-entity-title td-entity-click",
          attrs: { title: "Open Work Item" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.gotoGridElement.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.workDataName) + "\n\t")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "text-end" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.outputWorkDueDate) + "\n\t"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }