var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-3 element-row" }, [
    _c(
      "div",
      {
        class: [
          "dropstart ps-2",
          { "archived-reference": _vm.referenceObject.isArchived },
        ],
      },
      [
        _c("a", {
          staticClass: "svg bi-three-dots float-end",
          attrs: {
            href: "#",
            "data-bs-toggle": "dropdown",
            role: "button",
            "aria-expanded": "false",
            "aria-label": "More Options",
          },
        }),
        _vm._v(" "),
        _c("p", {
          directives: [
            { name: "add-blank-target", rawName: "v-add-blank-target" },
          ],
          ref: "referenceRowOutput",
          staticClass: "mb-1 word-break",
          domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
        }),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          !_vm.referenceObject.isArchived
            ? _c(
                "li",
                { key: "liEditReference" },
                [
                  _c(
                    "router-link",
                    {
                      class: ["dropdown-item hover hover-primary"],
                      attrs: {
                        to: {
                          name: "OutlineEditReference",
                          params: {
                            referenceUid: _vm.upperCaseRid,
                          },
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "svg bi-pencil-fill" }),
                      _vm._v("\n\t\t\t\t\t\tEdit Reference\n\t\t\t\t"),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-danger",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeRefFromPaper.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-x-lg" }),
                _vm._v("\n\t\t\t\t\tRemove Reference\n\t\t\t\t"),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.referenceObject.isArchived
      ? _c("p", { staticClass: "ms-3 mb-1 archived-reference-help-text" }, [
          _vm._v("\n\t\tThis reference has been archived.\n\t"),
        ])
      : _c(
          "div",
          { staticClass: "d-grid" },
          [
            _c(
              "router-link",
              {
                class: ["btn btn-sm btn-hover btn-hover-success ms-2"],
                attrs: {
                  to: {
                    name: "OutlineCitationAdd",
                    params: {
                      referenceUid: _vm.upperCaseRid,
                    },
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-plus-lg" }),
                _vm._v(" Add Citation\n\t\t"),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }