var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["mb-1 element-row", { "archived-reference": _vm.isExternal }] },
    [
      _c("div", { staticClass: "dropstart" }, [
        _c("a", {
          staticClass: "svg bi-three-dots float-end",
          attrs: {
            href: "#",
            "data-bs-toggle": "dropdown",
            role: "button",
            "aria-expanded": "false",
            "aria-label": "More Options",
          },
        }),
        _vm._v(" "),
        _c("p", {
          staticClass: "citation-value org-size mb-0 ms-3",
          domProps: { innerHTML: _vm._s(_vm.citationObject.displayValue) },
        }),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _vm.isActive
            ? _c("li", { key: "liCitationActive" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item hover hover-default",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showCitationInPaper.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "svg bi-search" }),
                    _vm._v("\n\t\t\t\t\tShow in Paper\n\t\t\t\t"),
                  ]
                ),
              ])
            : _c("li", { key: "liCitationNotActive" }, [
                _c(
                  "a",
                  {
                    class: [
                      "dropdown-item hover hover-success",
                      { "disabled hover-disabled": _vm.isExternal },
                    ],
                    attrs: { href: "#", title: "Insert Citation" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.insertOrphanCitation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "svg bi-plus-lg" }),
                    _vm._v("\n\t\t\t\t\tInsert Citation\n\t\t\t\t"),
                  ]
                ),
              ]),
          _vm._v(" "),
          _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.Turabian9
            ? _c(
                "li",
                { key: "liFootnote" },
                [
                  _c(
                    "router-link",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { "disabled hover-disabled": _vm.isExternal },
                      ],
                      attrs: {
                        to: {
                          name: "EditorCitationEdit",
                          params: {
                            citationUid:
                              _vm.citationObject.citationData.citationUniqueID,
                            openCitationTab: _vm.$enums.CitationTab.FOOTNOTE,
                          },
                        },
                        title: "Edit Footnote Text",
                      },
                    },
                    [
                      _c("span", { staticClass: "svg bi-pencil-fill" }),
                      _vm._v("\n\t\t\t\t\tEdit Footnote Text\n\t\t\t\t"),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "li",
                { key: "liCitation" },
                [
                  _c(
                    "router-link",
                    {
                      class: [
                        "dropdown-item hover hover-primary",
                        { "disabled hover-disabled": _vm.isExternal },
                      ],
                      attrs: {
                        to: {
                          name: "EditorCitationEdit",
                          params: {
                            citationUid:
                              _vm.citationObject.citationData.citationUniqueID,
                            openCitationTab: _vm.$enums.CitationTab.CITATION,
                          },
                        },
                        title: "Edit Citation",
                      },
                    },
                    [
                      _c("span", { staticClass: "svg bi-pencil-fill" }),
                      _vm._v("\n\t\t\t\t\tEdit Citation\n\t\t\t\t"),
                    ]
                  ),
                ],
                1
              ),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-danger",
                attrs: { href: "#", title: "Delete Citation" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteCitation.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-x-lg" }),
                _vm._v("\n\t\t\t\t\tDelete Citation\n\t\t\t\t"),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }