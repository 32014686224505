<!--
- Loader Component
-- 2024.09.25
-->

<template>
	<transition name="loader-fade">
		<div :class="calcLoaderMaskClasses" v-show="isShown">
			<div class="spinner-border text-primary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			<router-link
				v-if="$platformId === $enums.Platform.ONLINE"
				:to="{
					name:'Logout'
				}"
				class="btn-logout-spinner btn btn-link btn-sm">
				Logout
			</router-link>
			<button
				v-else-if="loaderId === 'App' && ($platformId === $enums.Platform.ADD_IN || $platformId === $enums.Platform.CHROME_EXT || $platformId === $enums.Platform.ADMIN)"
				type="button" class="btn-logout-spinner btn btn-link btn-sm" @click.prevent="fullLogout">
				Log Out
			</button>
		</div>
	</transition>
</template>

<script>
import AS_FullLogout from '@/services/app/fullLogout';

export default {
	name: 'LoaderComponent',
	props:{
		loaderId:{
			required: true,
			type: String,
		}
	},
	methods:{
		fullLogout(){
			AS_FullLogout().then(()=>{
				this.$router.push({
					name: 'Login'
				}).catch(()=>{});
			});
		}
	},
	computed:{
		calcLoaderMaskClasses(){
			let returnArray = ['loader-mask'];
			if(this.loaderId === 'App'){
				returnArray.push('loader-mask-app');
			}
			return returnArray;
		},
		
		isShown(){
			if(this.$store.state.loaders.showArray.includes(this.loaderId)){
				return true;
			}
			return false;
		},
	},
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/_vars_perrla.scss";
	
	$loader-size-md: 9.63rem;	// medium and up
	$loader-size-sm: 3.69rem;	// small and down

	@include color-mode(dark) {
		.loader-mask {
			background: rgba($dark-body-bg, 0.93);
		}
	}

	body.platform-admin, body.platform-online{
		.loader-mask {	
			&.loader-mask-app {
				position: fixed;

				.spinner-border {
					// size the loader reponsivly
					@include media-breakpoint-up(md) { 
						height: $loader-size-md;
						width: $loader-size-md;
					}

					@include media-breakpoint-down(md) { 
						height: $loader-size-sm;
						width: $loader-size-sm;
					}
				}
			}
		}
	}//body.platform-admin, body.platform-online

	body.platform-chrome-extension, body.platform-word{
		.loader-mask {	
			.spinner-border {
				height: $loader-size-md;
				width: $loader-size-md;
			}
		}
	}//body.platform-chrome-extension, body.platform-word

	.loader-mask {
		background: rgba($body-bg, 0.93);
		bottom: 0;
		display: grid;
		grid-template-columns: auto minmax(min-content, auto) auto;
		grid-template-rows: auto auto auto;
		height: 100%;
		left: 0;
		overflow-x: hidden;
		padding: 0rem;
		position: absolute;
		right: 0;
		top: 0;
		z-index: $zindex-section-loader;
	
		.spinner-border {
			align-self: start;
			display: grid;
			grid-column: 2;
			grid-row: 2;
			justify-self: center;
		}

		.btn-logout-spinner {
			position:absolute;
			bottom: $grid-half-gutter;
			right: $grid-half-gutter;
		}
	}

	.loader-fade-enter-active {
		transition: opacity 66ms ease-in-out;
	}
	.loader-fade-leave-active {
		transition: opacity 66ms ease-in-out;
	}
	.loader-fade-enter, .loader-fade-leave-to {
		opacity: 0;
	}
</style>