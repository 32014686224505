<!--
- Forms > Entity Association
-- Used in an entity modal to associate it with other entities
-->

<template>
	<div class="form-group">
        <label>Associate with...</label>
        
        <div class="row">
            <div class="col-sm-3">
                <select class="form-select" v-model="associatedEntityTypeId">
                    <option v-for="singleOption in calcAssociatedEntityTypeOptions" :key="singleOption.name" :value="singleOption.value">
                        {{ singleOption.name }}
                    </option>
                </select>
            </div><!--col-sm-3-->

            <div class="col-sm-9">
                <select class="form-select" v-model="associatedEntityId" @change="associatedEntityIdSelectChange" v-if="associatedEntityTypeId != '0'">
                    <option v-for="singleOption in calcAssociatedEntityOptions" :key="singleOption.name" :value="singleOption.value">
                        {{ singleOption.name }}
                    </option>
                </select>
            </div><!--col-sm-9-->
        </div><!--row-->
		
	</div>
</template>

<script>
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import config from '@/config';

export default {
	name: 'FormEntityAssociation',
	props:{
        entityTypeId:{
            required: true,
            type: Number
        },
	},
	data() {
		return {
            associatedEntityId: '0',
            associatedEntityTypeId: '0',
		}
	},
    watch:{
        associatedEntityTypeId(newValue){
            // automatically pick the first item in the list of available entity options (most useful if user changes Type a couple of times)
            if(newValue !== '0'){
                this.associatedEntityId = this.calcAssociatedEntityOptions[0].value;
                this.associatedEntityIdSelectChange();
            }
        }
    },
	computed:{
        calcAssociatedEntityTypeOptions(){
            let returnArray = [{
                value: "0",
                name: "NONE",
            }];

            // different entity types will have different options for what you can associate to it
            switch(this.entityTypeId){
                case config.enums.Entity.REMINDER:
                    returnArray.push({
                        value: config.enums.Entity.CLASS.toString(),
                        name: "Class",
                    });

                    returnArray.push({
                        value: config.enums.Entity.PAPER.toString(),
                        name: "Paper",
                    });

                    returnArray.push({
                        value: config.enums.Entity.ASSIGNMENT.toString(),
                        name: "Assignment",
                    });
                    
                    returnArray.push({
                        value: config.enums.Entity.TODO.toString(),
                        name: "To Do",
                    });
                    break;
            }//e:switch
            
            return returnArray;
        },//e:calcAssociatedEntityTypeOptions
        
        calcAssociatedEntityOptions(){
            let returnArray = [];

            // user selected an Entity Type, now populate the list of matching Entities
            switch(this.associatedEntityTypeId){
                case config.enums.Entity.CLASS.toString():
                    this.$store.state.classes.list.forEach((entityData)=>{
                        if(!entityData.isArchived){
                            returnArray.push({
                                sortBy: entityData.name.toLowerCase(),
                                value: entityData.classID.toString(),
                                name: entityData.name,
                            });
                        }
                    });//e:forEach
                    returnArray = _orderBy(returnArray, ['sortBy'], ['asc']);
                    break; 

                case config.enums.Entity.PAPER.toString():
                    this.$store.state.paperList.list.forEach((entityData)=>{
                        if(!entityData.isDeleted){
                            // console.log(entityData);
                            returnArray.push({
                                sortBy: entityData.lastModifiedDate,
                                value: entityData.paperID.toString(),
                                name: entityData.title,
                            });
                        }
                    });//e:forEach
                    returnArray = _orderBy(returnArray, ['sortBy', 'name'], ['desc', 'asc']);
                    break;
                
                case config.enums.Entity.ASSIGNMENT.toString():
                    this.$store.state.work.list.forEach((entityData)=>{
                        if(!entityData.isComplete && entityData.workType === config.enums.WorkType.ASSIGNMENT){
                            let sortByDueDate ='';
                            if(_has(entityData, 'dueDate') && entityData.dueDate) {
                                sortByDueDate = entityData.dueDate;	// remove hh:mm:ss so you can sort down to the day
                            } else {
                                sortByDueDate = '2000-01-01T00:00:00';
                            }
                            returnArray.push({
                                sortBy: sortByDueDate,
                                value: entityData.entityID.toString(),
                                name: entityData.name,
                            });
                        }
                    });//e:forEach
                    returnArray = _orderBy(returnArray, ['sortBy', 'name'], ['asc', 'asc']);
                    break;

                case config.enums.Entity.TODO.toString():
                    this.$store.state.work.list.forEach((entityData)=>{
                        if(!entityData.isComplete && entityData.workType === config.enums.WorkType.TODO){
                            let sortByDueDate ='';
                            if(_has(entityData, 'dueDate') && entityData.dueDate) {
                                sortByDueDate = entityData.dueDate;	// remove hh:mm:ss so you can sort down to the day
                            } else {
                                sortByDueDate = '2000-01-01T00:00:00';
                            }
                            returnArray.push({
                                sortBy: sortByDueDate,
                                value: entityData.entityID.toString(),
                                name: entityData.name,
                            });
                        }
                    });//e:forEach
                    returnArray = _orderBy(returnArray, ['sortBy', 'name'], ['asc', 'asc']);
                    break;
            }//e:switch
            
            console.log('returnArray');
            console.log(returnArray);

            return returnArray;
        },

	},
	methods: {
        associatedEntityIdSelectChange(){
            // emit back up to the parent with what was selected
            this.$emit('entity-selected', {
                associatedEntityTypeId: this.associatedEntityTypeId,
                associatedEntityId: this.associatedEntityId
            });
        }//e:associatedEntityIdSelectChange
	},
	created() {
        // emits from Single.vue
        this.emitter.on('loadEntityAssociationValues', ($opts)=>{
            if($opts.associatedEntityId !== '0' && $opts.associatedEntityTypeId !== '0'){
                this.associatedEntityId = $opts.associatedEntityId;
                this.associatedEntityTypeId = $opts.associatedEntityTypeId;
            }
        });//e:loadEntityAssociationValues
	},
    destroyed(){
        this.emitter.off('loadEntityAssociationValues');
    }
}
</script>
