<!--
- Paper Edit > Organize Tab > Research Notes > Create Reference Step 2
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>

		<create-reference-step-2-third-party-header :is-drawer="false" />

		<create-reference-step-2-third-party-body :is-drawer="false" />

	</div>
</template>

<script>
import _throttle from 'lodash/throttle';
import CreateReferenceStep2ThirdPartyBody from '@/modules/CreateReferenceStep2ThirdParty/Body'
import CreateReferenceStep2ThirdPartyHeader from '@/modules/CreateReferenceStep2ThirdParty/Header'

const showManualCreateThreshold = 400;	//px amount user scrolls down before scrolling back up shows the button

export default {
	name: 'PaperTabResearchCreateReferenceStep2ThirdParty',
	data() {
		return {
			isReadyToShowManualCreate: false,	// changes to true once when user scrolls down to the threshold
			lastScrollTop: 0,
			showManualCreateButton: false,
		}
	},
	methods: {
		handleScroll(){
			// don't process this if the create button is already showing
			if(this.showManualCreateButton || !this.$refs.scrollableBodyModal){
				return false;
			}

			let currentScrollTop = this.$refs.scrollableBodyModal.scrollTop;

			if(!this.isReadyToShowManualCreate && currentScrollTop >= showManualCreateThreshold){
				// Scrolled down to threshold, wait for up scroll 
				this.isReadyToShowManualCreate = true;
			}

			if(this.isReadyToShowManualCreate && currentScrollTop <= this.lastScrollTop){
				this.showManualCreateButton = true;
				this.emitter.emit('CreateReferenceStep2ThirdParty_ShowManualCreateButton');	// $emits to Header
			}

			this.lastScrollTop = currentScrollTop;

		},//e:handleScroll
	},
	components: {
		CreateReferenceStep2ThirdPartyBody,
		CreateReferenceStep2ThirdPartyHeader
	}
}
</script>
