var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "class-header px-3 py-3",
            attrs: { "color-value": _vm.classData.colorValue },
          },
          [_c("h1", [_vm._v(_vm._s(_vm.classData.name))])]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-8" },
            [_c("class-recent-papers"), _vm._v(" "), _c("class-upcoming-work")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-4" },
            [_c("class-details", { attrs: { "class-data": _vm.classData } })],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }