<!--
- FreeTrialDiscount
-- make api call to joey that sets a timer in the store for this user
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0">
			<main class="container-text mt-0 pt-3">

				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div>
							<h1 class="text-center">
								PERRLA
							</h1>

							<div v-if="!isCustomerInFreeTrial" key="divNotInFreeTrial">
								<h2 class="text-center">
									Looks like you already have PERRLA
								</h2>
								<p>
									This offer was only valid during a Free Trial. However, there are other ways you can save with PERRLA. <a href="https://perrla.zendesk.com/hc/en-us/articles/4403893451547-How-to-Use-PERRLA-s-Referral-Rewards-Program" target="_blank" rel="noopener noreferrer">Click here to learn how to use your Referral Rewards code to get three months of PERRLA for free.</a>
								</p>
							</div>
							<div v-else-if="isExpired" key="divExpired">
								<h2 class="text-center">
									Time's up - this offer has expired.
								</h2>
								<p>
									This offer was only valid for 60 minutes. However, there are other ways you can save with PERRLA. <a href="https://perrla.zendesk.com/hc/en-us/articles/4403893451547-How-to-Use-PERRLA-s-Referral-Rewards-Program" target="_blank" rel="noopener noreferrer">Click here to learn how to use your Referral Rewards code to get three months of PERRLA for free.</a>
								</p>
							</div>
							<div v-else key="divElse">
								<h2 class="text-center">
									Preparing your discount.
								</h2>
								<p class="text-center">
									If you are not forwarded to checkout shortly, <a :href="calcStoreLink" target="_blank" rel="noopener noreferrer">click here</a>.
								</p>	
							</div>
						</div>
					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>
	</div>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';

export default {
	name: 'FreeTrialDiscount',
	data() {
		return {
			calcStoreLink: '',
			isCustomerInFreeTrial: true,
			isExpired: false,
		}
	},
	mounted() {
		// force this view to stay in the PO layout never in dark mode
		AS_SyncData().then(()=>{
			this.$store.commit('loaders/REMOVE_ID', 'App');

			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.SIMPLE);
			document.body.classList.add('bg-simple');
			document.documentElement.setAttribute('data-bs-theme', 'light')

			ApiS_MakeCall({
				params:{
					'customerId': this.$store.state.customer.id,
				},
				ignoreAuth: true,
				method: 'POST',
				responseType: 'text',
				url: 'v4/FreeTrial/Discount',
			}).then((responseToken)=>{
				this.isCustomerInFreeTrial = true;
				this.isExpired = false;
				
				this.calcStoreLink = 'https://store.perrla.com/PopulateCart?customer=' + responseToken + '&products=801';
				window.location = this.calcStoreLink;

			}).catch((errorObject)=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

				if(_has(errorObject, 'code')){
					switch(errorObject.code){
						case 'CUSTOMER_NOT_IN_TRIAL':
							this.isCustomerInFreeTrial = false;
							break;
						case 'DISCOUNT_EXISTS':
							this.isCustomerInFreeTrial = true;
							this.isExpired = false;
							this.calcStoreLink = 'https://store.perrla.com/PopulateCart?customer=' + errorObject.message + '&products=801';
							window.location = this.calcStoreLink;
							break;
						case 'TRIAL_EXPIRED':
							this.isExpired = true;
							break;
					}
				} else {
					// if no code is availavle just assume this is expired
					this.isExpired = true;
				}
				
			});//e:ApiS_MakeCall
			
		});//e:AS_SyncData
	},
}
</script>
