<!--
- Generic Modal > Class Create 
-->

<template>
	<generic-modal-wrap modal-id="ClassCreate">
		<h2 class="nodal-title">
			Create Class
		</h2>

		<form>
			<div class="form-group">
				<label>Choose a Color</label>
				<div class="color-swatch-wrp d-flex">
					<a href="#" class="color-swatch" :title="enumColorName" v-for="(colorData, enumColorName) in classColorValues" :key="enumColorName" :style="{background: '#' + colorData.block}" @click.prevent="toggleSelectedColor(enumColorName)">
						<span class="svg bi-check-lg" v-show="selectedColorName === enumColorName" />
					</a>
				</div>
			</div>

			<form-input
				label="Class Name"
				placeholder="(required)"
				v-model="className"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED" />

			<form-input
				label="Instructor Name"
				:form-id="zFormId"
				v-model="teacherName" />

			<form-input
				label="Instructor Email"
				validate-is-email
				type="email"
				:form-id="zFormId"
				v-model="teacherEmail" />
			
			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="$store.commit('modals/CLOSE', 'ClassCreate')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="createClass">
							Create Class
						</button>
					</div>
				</div>
			</div>
		</form>
	</generic-modal-wrap>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalClassCreate',
	data() {
		return {
			className: '',			
			selectedColorName: 'NONE',
			teacherEmail: '',
			teacherName: '',
			zFormId: 'formCreateClass',
		}
	},
	computed:{
		classColorValues(){
			return config.enums.ClassColors;
		},
	},
	methods:{
		toggleSelectedColor(enumColorName){
			if(this.selectedColorName === enumColorName){
				this.selectedColorName = '';
			} else {
				this.selectedColorName = enumColorName;
			}
		},
		createClass(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				let sendData = {
					"colorValue": this.selectedColorName,
					"name": this.className,
					"teacherEmail": this.teacherEmail,
					"teacherName": this.teacherName,
				}

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					responseType: 'json',
					url: 'v4/Class',
				}).then((responseData)=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					if(this.$isDev && window.location.pathname === '/'){
						console.log('sendData');
						console.log(sendData);
						console.log(responseData);
						alert("Class created - but locally i can't redirect");
					} else {
						// redirect to class edit page (MVC only)
						window.location = '/Classes/Edit/' + responseData.encryptedID;
					}
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
			
		},//e:createClass
	},
	mounted() {
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components:{
		FormInput,
		GenericModalWrap
	},
}
</script>


<style lang="scss" scoped>
	
	@import "../../assets/styles/_vars_perrla.scss";
	$swatch-height: 36px !default;
	$swatch-width: 36px !default;

	.color-swatch-wrp{
		flex-wrap: wrap;

		.color-swatch{
			border: 1px solid $secondary-300;
			border-radius: $border-radius;
			height: $swatch-height;
			margin-right: 0.25rem;
			width: $swatch-width;
			
			&:hover{
				border-color: $secondary-500;
			}

			.svg{
				color: #fff;
				font-size: 1.5rem;
				display: block;
				text-align: center;
				line-height: $swatch-height;
				height:100%;
				width:100%;
			}
		}
	}
</style>
