<!--
- Class Edit
-- route param :encryptedId
-- https://magical-design-system.webflow.io/online/class
-->

<template>
	<div>
		<div class="row mb-3">
			<div class="col">
				<div class="class-header px-3 py-3" :color-value="classData.colorValue">
					<h1>{{ classData.name }}</h1>
				</div>
			</div>
		</div>

		<div class="row mb-5">
			<div class="col-12">
				<div class="row">
					<div class="col-8">
						<class-recent-papers />
						<class-upcoming-work />
					</div>
					<div class="col-4">
						<class-details :class-data="classData" />
					</div>
				</div>

			</div>
		</div>
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import AS_SyncData from '@/services/app/syncData';
import ClassDetails from './ClassEdit/Details';
import ClassRecentPapers from './ClassEdit/RecentPapers';
import ClassUpcomingWork from './ClassEdit/UpcomingWork';

export default {
	name: 'ClassEdit',
	computed:{
		calcLegacyClassEditLink(){
			return '/Classes/Edit/' + this.classData.encryptedID
		},
		classData(){
			return this.$store.state.classes.openObject;
		},
	},
	mounted() {
		if(this.$store.state.app.showAlphaFeatures){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			AS_SyncData({
				isStealthSync: true
			}).then(()=>{
				// look class up by the encryptedId param
				let findClass = this.$store.state.classes.list.find((classData)=>{
					return classData.encryptedID === this.$route.params.encryptedId
				});
				
				this.$store.commit('loaders/REMOVE_ID', 'App');

				if(findClass){
					// set this class as the open object
					this.$store.commit('classes/SET_OPEN_OBJECT', findClass)
				} else {
					console.log('class not found by encrypted id');
					this.$router.push({
						name: 'Dashboard'
					}).catch(()=>{});
				}
			});//e:AS_SyncData
		} else {
			// user shouldn't be here, get out safely
			if(this.$store.state.app.showBetaFeatures){
				// Beta On - go to v2 dashboard
				this.$router.push({
					name: 'Dashboard'
				}).catch(()=>{});
			} else {
				// Beta Off - use location
				window.location = '/Dashboard';
			}
		}
	},
	components: {
		ClassDetails,
		ClassRecentPapers,
		ClassUpcomingWork
	},
	destroyed(){
		this.$store.commit('classes/SET_OPEN_OBJECT', {});
	},
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/vars_perrla";

	div.class-header{
		&[color-value="BLUE"], &[color-value="0000bb"], &[color-value="000066"] {
			background-image: linear-gradient(270deg,#fff, $class-color-blue-block);
		}
		&[color-value="GREEN"], &[color-value="00bb00"], &[color-value="006600"] {
			background-image: linear-gradient(270deg,#fff, $class-color-green-block);
		}
		&[color-value="NONE"], &[color-value="bbbbbb"], &[color-value="666666"], &[color-value="bb00bb"], &[color-value="660066"]  {
			background-image: linear-gradient(270deg,#fff, $class-color-none-block);
		}
		&[color-value="RED"], &[color-value="bb0000"], &[color-value="660000"], &[color-value="bb5500"], &[color-value="885500"] {
			background-image: linear-gradient(270deg,#fff, $class-color-red-block);
		}
		&[color-value="TEAL"], &[color-value="00bbbb"], &[color-value="006666"] {
			background-image: linear-gradient(270deg,#fff, $class-color-teal-block);
		}
		&[color-value="YELLOW"], &[color-value="bbbb00"], &[color-value="666600"] {
			background-image: linear-gradient(270deg,#fff, $class-color-yellow-block);
		}

		h1 {
			padding-bottom: 0;
			margin-bottom: 0;
			line-height: 1;
		}
	}
</style>