/*
- outlinePrefixes.js
-- this lives in the config folder but isn't imported into that file
*/
const outlinePrefix_2_4 = new Array('1', '2', '3', '4', '5', '6', '7', '8', '9',
							'10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
							'20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
							'30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40');

const outlinePrefix_3_5_7_9 = new Array('a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
	'aa', 'bb', 'cc', 'dd', 'ee', 'ff', 'gg', 'hh', 'ii', 'jj', 'kk', 'll', 'mm', 'nn', 'oo', 'pp', 'qq', 'rr', 'ss', 'tt', 'uu', 'vv', 'ww', 'xx', 'yy', 'zz');

const outlinePrefix_6_8_10 = new Array('i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x',
			'xi', 'xii', 'xiii', 'xiv', 'xv', 'xxvi', 'xvii', 'xviii', 'xix', 'xx',
			'xxi', 'xxii', 'xxiii', 'xxiv', 'xxv', 'xxxvi', 'xxvii', 'xxviii', 'xxix', 'xxx',
			'xxxi', 'xxxii', 'xxxiii', 'xxxiv', 'xxxv', 'xxxxvi', 'xxxvii', 'xxxviii', 'xxxix', 'xl',
			'xli', 'xlii', 'xliii', 'xliv', 'xlv', 'xlvi', 'xlvii', 'xlviii', 'xlix', 'l');

export default [
	// 0
	new Array(
		'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X',
		'XI', 'XII', 'XIII', 'XIV', 'XV', 'XXVI', 'XVII', 'XVIII', 'XIX', 'XX',
		'XXI', 'XXII', 'XXIII', 'XXIV', 'XXV', 'XXXVI', 'XXVII', 'XXVIII', 'XXIX', 'XXX',
		'XXXI', 'XXXII', 'XXXIII', 'XXXIV', 'XXXV', 'XXXXVI', 'XXXVII', 'XXXVIII', 'XXXIX', 'XL',
		'XLI', 'XLII', 'XLIII', 'XLIV', 'XLV', 'XLVI', 'XLVII', 'XLVIII', 'XLIX', 'L'),
	// 1
	new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
	'AA', 'BB', 'CC', 'DD', 'EE', 'FF', 'GG', 'HH', 'II', 'JJ', 'KK', 'LL', 'MM', 'NN', 'OO', 'PP', 'QQ', 'RR', 'SS', 'TT', 'UU', 'VV', 'WW', 'XX', 'YY', 'ZZ',
	'AAA', 'BBB', 'CCC', 'DDD', 'EEE', 'FFF', 'GGG', 'HHH', 'III', 'JJJ', 'KKK', 'LLL', 'MMM', 'NNN', 'OOO', 'PPP', 'QQQ', 'RRR', 'SSS', 'TTT', 'UUU', 'VVV', 'WWW', 'XXX', 'YYY', 'ZZZ',
	),
	// 2
	outlinePrefix_2_4,
	// 3
	outlinePrefix_3_5_7_9,
	// 4 (2)
	outlinePrefix_2_4,
	// 5 (3)
	outlinePrefix_3_5_7_9,
	// 6
	outlinePrefix_6_8_10,
	// 7 (3)
	outlinePrefix_3_5_7_9,
	// 8 (6)
	outlinePrefix_6_8_10,
	// 9 (3)
	outlinePrefix_3_5_7_9,
	// 10 (6)
	outlinePrefix_6_8_10,
]