var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Table of Contents" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c("p", [
        _vm._v(
          _vm._s(_vm.calcEditionFormat) +
            " does not offer guidelines for creating a Table of Contents."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "PERRLA creates the Table of Contents by following the rules available in the " +
            _vm._s(_vm.calcEditionFormat) +
            " Manual for other text sections."
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid gap-2" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.IncludeTableOfContents,
                expression: "!IncludeTableOfContents",
              },
            ],
            staticClass: "btn btn-success",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.addToc.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tAdd Table of Contents\n\t\t\t")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.IncludeTableOfContents,
                expression: "IncludeTableOfContents",
              },
            ],
            staticClass: "btn btn-outline-danger",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.removeToc.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tRemove Table of Contents\n\t\t\t")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.IncludeTableOfContents,
                expression: "IncludeTableOfContents",
              },
            ],
            staticClass: "btn btn-success",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.refreshToc.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tRefresh Table of Contents\n\t\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "The Table of Contents is built from the headings you add to your paper using PERRLA's "
      ),
      _c("em", [_vm._v("Text & Heading Styles")]),
      _vm._v(" menu button."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }