
<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Appendix" />
		</div><!--drawer-header-->

		<div class="drawer-body">
		
			<p>Appendices are used to add supplementary material to your paper and are always added at the end of your paper.</p>
			<p>Appendix Titles should describe the content contained in the appendix and should use title case capitalization (capitalizing all major words).</p>
			
			<div class="d-grid">
				<button type="button" class="btn btn-success" @click.prevent="btnAddAppendix">
					Add an Appendix
				</button>
			</div>

			<div class="text-empty mt-5">
				<p>Appendices are labeled based on the number of appendices in the paper. A single appendix is labeled &ldquo;Appendix,&rdquo; while multiple appendices are labeled &ldquo;Appendix A,&rdquo; &ldquo;Appendix B,&rdquo; etc. PERRLA handles the appendix labels for you.</p>
				<p>If you delete appendices or refresh their labels, be sure any appendix callouts in the body of your paper match the proper appendix.</p>
				<p>You can learn more about Appendices in <a href="https://perrla.zendesk.com/hc/en-us/articles/360004129633-Adding-an-Appendix-to-your-Paper-Online" target="_blank" rel="noopener noreferrer">PERRLA&#39;s Help Center</a>.</p>
			</div>

			<br /><br />

		</div><!--drawer-body-->
	</div>
</template>

<script>
import PApS_AddToPaper from '@/services/paper/appendices/addToPaper';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'PaperSectionsAppendices',
	methods:{
		btnAddAppendix(){
			PApS_AddToPaper().then((newUUID)=>{
				this.emitter.emit('createNewAppendix', newUUID);
			});
		},//e:btnAddAppendix
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorAppendices'
		});
	},
	components: {
		ToolDrawerTitle
	}
}
</script>
