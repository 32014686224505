<!--
- Module > Create Reference Step 3 > Body
-
-->

<template>
	<div>
		<p class="org-size">
			Step 3 of 3: Confirm your reference
		</p>
			
		<reference-output />

		<form>
			<section class="mb-5">
				<h3>Optional Content</h3>

				<form-textarea
					help-text="Add a private description to your Reference."
					label="Description"
					pendo-grab-text="Txt_RefCreate_Description"
					:form-id="zFormId"
					:is-autosize="true"
					v-model="description" />

				<form-tags
					pendo-grab-text="Txt_RefCreate_Tags" 
					:form-id="zFormId"
					v-model="tags" />
			</section>

			<div class="row justify-content-between">
				<div class="col">
					<div class="d-grid" v-if="$router.currentRoute.meta.module === $enums.Module.PAPER_EDIT" key="divModulePaperEdit">
						<router-link
							v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR"
							:to="{
								name:'EditorCreateReferenceStep2',
								params: {
									openReferenceTab: $route.params.openReferenceTab,
									referenceTypeSlug: $route.params.referenceTypeSlug,
								}
							}"
							class="btn btn-outline-primary"
							key="routerLinkTabEditor">Back</router-link>
						<router-link
							v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE"
							:to="{
								name:'OutlineCreateReferenceStep2',
								params: {
									openReferenceTab: $route.params.openReferenceTab,
									referenceTypeSlug: $route.params.referenceTypeSlug,
								}
							}"
							class="btn btn-outline-primary"
							key="routerLinkTabOutline">Back</router-link>
						<button
							v-else
							class="btn btn-outline-primary"
							key="routerLinkTabElse"
							type="button"
							@click.prevent="moduleModalBack">
							Back
						</button>
					</div>
					<div class="d-grid" v-else key="divModuleElse">
						<button type="button" class="btn btn-outline-primary" @click.prevent="moduleModalBack">
							Back
						</button>
					</div>
				</div>
				<div class="col text-end">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="createReference">
							Create
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormTags from '@/components/form/Tags';
import FormTextarea from '@/components/form/Textarea';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import mixinTagCleaner from '@/mixins/tag-cleaner';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import ReferenceOutput from '@/components/ReferenceOutput';
import {v4 as uuidv4} from 'uuid';

export default {
	name: 'CreateReferenceStep3Body',
	mixins: [mixinTagCleaner],
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			description: '',
			tags: '',
			zFormId: 'formReferenceCreateStep3',
		}
	},
	methods: {
		createReference(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				let params = {};

				// Whatever integer value you want to pass me to denote the reference type.
				params['referenceTypeId'] = this.$store.state.referenceRules.referenceTypeId;
				
				// A GUID to identify the reference. In the past I created this server side, but since they can create these without our server now, I need it passed to me.
				params['uniqueId'] = uuidv4().toUpperCase();
				
				let referenceRulesObject = {
					'data': _cloneDeep(this.$store.state.referenceRules.rulesObjectData),
					'values': _cloneDeep(this.$store.state.referenceRules.rulesObjectValues),
				}
							
				// Optional. Note/Description field like the old refs.
				if(this.description.length > 0){
					referenceRulesObject.note = this.description;
				}
				
				// Optional. Just like the old system.
				if(this.tags.length > 0){
					referenceRulesObject.tags = this.cleanTags(this.tags);
				}

				ApiS_MakeCall({
					body: referenceRulesObject,
					params,
					method: 'POST',
					responseType: 'json',
					url: 'v3/GenesisReference',
				}).then((responseData)=>{
					// console.log('responseData v3/GenesisReference');
					// console.log(responseData);

					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					let isAddToPaper = false;

					// if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
						// ideally don't add this reference to the paper if there is a blank value coming back for that value
						if(_has(responseData, 'values')){
							let refValues = JSON.parse(responseData.values);
							let formatVersionID = HelperGetReferenceFormatId();
							
							switch(formatVersionID){
								case config.enums.Format.APA7:
									if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value') && refValues.apa7.value !== ''){
										isAddToPaper = true;
									}
									break;
								case config.enums.Format.MLA9:
									if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value') && refValues.mla9.value !== ''){
										isAddToPaper = true;
									}
									break;
								case config.enums.Format.Turabian9:
									if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value') && refValues.turabian9.value !== ''){
										isAddToPaper = true;
									}
									break;
							}//e:switch
						}//e:if
					// }//e:if

					if(isAddToPaper){
						let _referenceUpdated = _cloneDeep(responseData);

						this.$store.commit('REFERENCE_ADD_TO_LIBRARY', _referenceUpdated);

						this.$store.commit('references/RECENT_TYPE_IDS_ADD', this.$store.state.referenceRules.referenceTypeId);

						PS_AddReferenceToPaper({
							isDrawer: this.isDrawer,
							reference: _referenceUpdated
						}).then(()=>{
							if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
								if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
									if(this.$store.state.paperEdit.annotations.isInclude){
										this.emitter.emit('createNewAnnotation', {
											referenceUniqueID: _referenceUpdated.referenceUniqueID.toUpperCase()
										});
									}
									this.$router.push({
										name:'EditorReferencesCitations'
									}).catch(()=>{});
								} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
									if(this.$store.state.paperEdit.annotations.isInclude){
										this.emitter.emit('createNewAnnotation', {
											referenceUniqueID: _referenceUpdated.referenceUniqueID.toUpperCase()
										});
									}
									this.$router.push({
										name:'OutlineReferencesCitations'
									}).catch(()=>{});

								} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
									if(this.$store.state.paperEdit.annotations.isInclude){
										this.emitter.emit('createNewAnnotation', {
											referenceUniqueID: _referenceUpdated.referenceUniqueID.toUpperCase()
										});
									}
									this.emitter.emit('closeRouteModal');
									this.emitter.emit('updateReferenceList');
									this.$store.commit('loaders/REMOVE_ID', 'App');
								}

							} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
								this.emitter.emit('updateReferenceList');
								this.emitter.emit('closeRouteModal');

							}

						});//e:PS_AddReferenceToPaper

					} else {
						this.$store.commit('loaders/REMOVE_ID', 'App');

						// user was trying to create a reference within a format that comes back blank, it should not automatically be added to this paper
						if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
							if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
								this.$router.push({
									name:'EditorReferencesCitations'
								}).catch(()=>{});
							} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
								this.$router.push({
									name:'OutlineReferencesCitations'
								}).catch(()=>{});

							} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
								this.emitter.emit('closeRouteModal');
								this.emitter.emit('updateReferenceList');
							}

						} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
							this.emitter.emit('updateReferenceList');
							this.emitter.emit('closeRouteModal');

						}

					} //e:if:isAddToPaper
				});//e:ApiS_MakeCall

			}).catch(()=>{
				return false;

			});//e:AS_ValidateForm

		},//e:createReference
		
		moduleModalBack(){
			this.$router.go(-1);
		},
	},
	mounted(){
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components: {
		FormTags,
		FormTextarea,
		ReferenceOutput,
	},
}
</script>
