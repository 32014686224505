/*
- CheckReferenceData
-- for now a reference might be missing data that is normal for Turabian, but not usally asked for in another format
-- this helper should return a value for REVIEW or NORMAL
-- 2024.01.30
*/
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config.js';
import ReferenceTypes from '@/services/reference/types.json';
import store from '@/store';

export default (referenceObject) => {
	// 1: is this reference type available for this enviornment?
	// console.log(referenceObject.referenceTypeID);
	// console.log(ReferenceTypes);
	
	// find this reference type by it's id
	let referenceTypeData = ReferenceTypes.refTypes.find((referenceTypeDataLoop)=>{
		return referenceTypeDataLoop.id == referenceObject.referenceTypeID;
	});
	if(referenceTypeData){
		// referent type found
		// console.log(referenceTypeData.title);
		// console.log(referenceTypeData.formats);

		let modeLevel; 
		

		let PaperFormatVersionID;

		if(config.platformId === config.enums.Platform.ONLINE){
			PaperFormatVersionID = store.state.paperEdit.meta.PaperFormatVersionID

		} else if(config.platformId === config.enums.Platform.ADD_IN){
			
			PaperFormatVersionID = store.state.formatVersionID;
		}

		switch(PaperFormatVersionID){
			case config.enums.Format.APA7:
				modeLevel = referenceTypeData.formats.APA7;
				break;
			case config.enums.Format.MLA9:
				modeLevel = referenceTypeData.formats.MLA9;
				break;
			case config.enums.Format.Turabian9:
				modeLevel = referenceTypeData.formats.Turabian9;
				break;
		}
		
		if(modeLevel){
			// modeLevel set
			// console.log(modeLevel);
			// console.log('---');
			if(modeLevel === 'unavailable'){
				if(PaperFormatVersionID === config.enums.Format.Turabian9){
					return config.enums.AddFromLibraryMode.COMMING_SOON;
				}
				return config.enums.AddFromLibraryMode.DISABLED;

			} else {
				// could either be dev | test | available (reject anything that doesn't match for the current environment)
				if(config.isTest){
					// test env - reject any 'dev' entires 
					if(modeLevel === 'dev'){
						if(PaperFormatVersionID === config.enums.Format.Turabian9){
							return config.enums.AddFromLibraryMode.COMMING_SOON;
						}
						return config.enums.AddFromLibraryMode.DISABLED;
					}
				} else if(config.isLive){
					// live env - reject any 'dev' or 'test' entries
					if(modeLevel === 'dev' || modeLevel === 'test'){
						if(PaperFormatVersionID === config.enums.Format.Turabian9){
							return config.enums.AddFromLibraryMode.COMMING_SOON;
						}
						return config.enums.AddFromLibraryMode.DISABLED;
					}
				}
			}

		} else {
			// modeLevel NOT set - shouldn't happen so disable it
			return config.enums.AddFromLibraryMode.DISABLED;
		}

	} else {
		// couldn't find reference type - shouldn't happen so disable it
		return config.enums.AddFromLibraryMode.DISABLED;
	}
	
	// 2: Check Publishers - Turabian usually has a City and State / other types do not
	if(referenceTypeData.slug === 'book'){
		
		// console.log(referenceObject.data.bookTitle);

		// these are types that have a publisher you need to check
		if(!referenceObject.data.includes('"publishers": []')){
			// publishers are NOT blank

			let parsedReferenceData = JSON.parse(referenceObject.data);
			// console.log(parsedReferenceData.bookTitle);
			// console.log(parsedReferenceData.publishers);

			let isPublisherNeedToReview = false;

			// loop through each publisher
			_forEach(parsedReferenceData.publishers, (publisherData)=>{
				if(!_has(publisherData, 'city') || !_has(publisherData, 'state')){
					// doesn't even have a city or state property
					isPublisherNeedToReview = true;
					return false;	// stop looping if you find one
				}
				
				if(publisherData.city === '' || publisherData.state === '' ){
					isPublisherNeedToReview = true;
					return false;	// stop looping if you find one
				}

				// these are the "good ones" for now
				// console.log('-- these are the "good ones" for now');
				// console.log(parsedReferenceData.bookTitle);
				// console.log(publisherData);

			});//e:forEach

			if(isPublisherNeedToReview){
				return config.enums.AddFromLibraryMode.REVIEW;
			}

		}//e:if:publishers are NOT blank

	}
	
	// 3: Check other things

	return config.enums.AddFromLibraryMode.REVIEW;
}

