var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _c("label", {
        staticClass: "form-label form-label-v2",
        attrs: { for: _vm.fullId },
        domProps: { innerHTML: _vm._s(_vm.calcLabelOutput) },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "col-value-full-width": _vm.isFullWidth,
          "col-value": !_vm.isFullWidth,
        },
      },
      [
        _c("input", {
          class: ["form-control", { "is-invalid": _vm.isInvalidShown }],
          attrs: {
            autocomplete: "off",
            disabled: _vm.isDisabled,
            id: _vm.fullId,
            placeholder: _vm.placeholder,
            role: _vm.role,
            type: _vm.localType,
          },
          domProps: { value: _vm.value },
          on: {
            blur: function ($event) {
              return _vm.blurInput($event.target.value)
            },
            input: function ($event) {
              return _vm.typeInput($event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.isInvalidShown
          ? _c("div", { staticClass: "invalid-feedback d-block mt-1" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t\t"),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }