<!--
- Paper Edit > Modal > Paper Source
-->

<template>
	<generic-modal-wrap modal-id="PaperSource">
		<h2 class="nodal-title">
			Paper Source
		</h2>

		<div v-if="documentAsHtml">
			<textarea class="form-control" v-model="documentAsHtml" />
		</div>

	</generic-modal-wrap>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import GenericModalWrap from '@/components/modals/GenericWrap';

export default {
	name: 'ModalPaperSource',
	data() {
		return {
			documentAsHtml: '',
		}
	},
	created(){
		this.emitter.on('GenericModalOpen', ($opts)=>{
			if($opts.modalId === 'PaperSource'){
				if(!_isEmpty(this.$store.state.modals.passData)){
					this.documentAsHtml = this.$store.state.modals.passData.documentAsHtml;
				}
			}
		});
		this.emitter.on('GenericModalClose', ($opts)=>{
			if($opts.modalId === 'PaperSource'){
				this.emitter.emit('hidePaperSource', {
					documentAsHtml: this.documentAsHtml
				});
			}
		});
	},
	components: {
		GenericModalWrap,
	},
	destroyed(){
		this.emitter.off('GenericModalClose');
		this.emitter.off('GenericModalOpen');
	},
}
</script>

<style lang="scss" scoped>
	textarea.form-control {
		height: calc(100vh - 100px);
	}
</style>