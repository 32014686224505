var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReferenceLibraryShown
    ? _c(
        "div",
        {
          key: "divAddFromLibraryReferencesInPaper",
          staticClass: "pt-2 neg-gutter",
        },
        _vm._l(_vm.filteredReferenceLibrary, function (referenceData) {
          return _c("add-from-library-row", {
            key: referenceData.referenceUniqueID,
            attrs: {
              "is-drawer": _vm.isDrawer,
              "reference-unique-id":
                referenceData.referenceUniqueID.toUpperCase(),
              "reference-data": referenceData,
            },
          })
        }),
        1
      )
    : _c(
        "div",
        {
          key: "divAddFromLibraryNoReferencesInPaper",
          staticClass: "text-empty mt-5",
        },
        [
          _c("p", [
            _vm._v(
              "The Reference Library saves all of your PERRLA references for future papers."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.linkToReferenceCreate.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tCreate a new reference\n\t\t")]
            ),
            _vm._v("\n\t\tand you'll see it saved here for your next paper."),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }