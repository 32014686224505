<!--
- Paper Edit > Organize Tab > Research Notes > Create Reference Step 2
-->
<template>
	<div>
		<h2 class="nodal-title">
			<router-link
				class="link-icon"
				:to="{
					name: 'PaperTabResearchCreateReferenceStep1',
					params:{
						openReferenceTab: $route.params.openReferenceTab
					}
				}">
				<span class="svg bi-chevron-left" title="Back" />
			</router-link>

			Create a reference
		</h2>

		<create-reference-step-2-body :is-drawer="false" />

	</div>
</template>

<script>
import CreateReferenceStep2Body from '@/modules/CreateReferenceStep2Body'

export default {
	name: 'PaperTabResearchCreateReferenceStep2',
	components: {
		CreateReferenceStep2Body,
	}
}
</script>
