<!--
- Add Reference From Library Header
-
-->

<template>
	<div class="form-group mb-0">
		<div class="d-flex justify-content-between">
			<label>Search</label>
			<!-- <p>{{ addFromLibraryIsByName }}</p> -->
			<div class="">
				<a href="#" class="btn-sort" @click.prevent="setIsSortName(false)" v-show="addFromLibraryIsByName" key="btnSortName" title="Sort By:">
					Sorted By: <span class="fw-bold">Name</span>
				</a>
				<a href="#" class="btn-sort" @click.prevent="setIsSortName(true)" v-show="!addFromLibraryIsByName" key="btnSortCreated" title="Sort By:">
					Sorted By: <span class="fw-bold">Date Created</span>
				</a>
			</div>
		</div><!--d-flex-->

		<div class="d-flex mt-1">
			<div class="w-100">
				<input
					class="form-control"
					placeholder="Search your references"
					type="text"
					v-debounce:500ms="calcFilterReferenceLibrary"
					v-model="searchTerm" />
			</div>
			<div class="flex-shrink-1" v-show="searchTerm.length > 0">
				<button type="button" class="btn btn-link hover-underline" @click.prevent="clearSearch">
					Clear
				</button>
			</div>
		</div>
	</div><!--form-group-->
</template>

<script>

export default {
	name: 'AddReferenceFromLibraryHeader',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			searchTerm: '',
		}
	},
	computed:{
		addFromLibraryIsByName:{
			get(){
				return this.$store.state.gridSorts.addFromLibraryIsByName;
			},
			set(newValue){
				this.$store.commit('gridSorts/SET_ADD_FROM_LIBRARY_IS_BY_NAME', newValue);
			}
		}
	},
	methods: {
		calcFilterReferenceLibrary(){
			this.emitter.emit('calcFilterReferenceLibrary', {
				searchTerm: this.searchTerm,
			});
		},

		clearSearch(){
			this.searchTerm = '';
			this.calcFilterReferenceLibrary();
		},

		setIsSortName(setTo){
			this.addFromLibraryIsByName = setTo;
			this.calcFilterReferenceLibrary();
		},
	},
	mounted(){
		this.calcFilterReferenceLibrary();
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";

	@include color-mode(dark) {
		.btn-sort {
			color: $dark-body-color;
		}	
	}
	.btn-sort {
		color: $body-color;
		font-size: $font-size-sm;
		text-decoration: none;
		
		&:hover {
			color: $primary;
			text-decoration: underline;
		}
	}
</style>