var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["quote-single", { "quote-single-last": _vm.isLast }] },
    [
      _c("div", { staticClass: "quote-header" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "name" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.quoteData.name) + "\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quote" }, [
        _c("h3", [
          _vm._v('"'),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.quoteData.quote) } }),
          _vm._v('"'),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "five-star-wrp" }, [
      _c("img", {
        staticClass: "img-star",
        attrs: { src: "/static/images/star.svg", role: "presentation" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-star",
        attrs: { src: "/static/images/star.svg", role: "presentation" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-star",
        attrs: { src: "/static/images/star.svg", role: "presentation" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-star",
        attrs: { src: "/static/images/star.svg", role: "presentation" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-star",
        attrs: { src: "/static/images/star.svg", role: "presentation" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }