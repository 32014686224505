<!--
- Reminder Single
-
-->

<template>
	<div>
		<h2 class="nodal-title">
			Reminder
		</h2>

		<form>
			<form-input
				label="Reminder Name*"
				placeholder="(required)"
				v-model="reminderName"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED" />

			<form-datepicker
				id="RS_dueDate"
				label="Due Date*"
				:form-id="zFormId"
				:is-clearable="true"
				validate-is-all-date
				validate-is-required
				@date-set="dateDueSet" />

			<form-entity-association
				:entity-type-id="$enums.Entity.REMINDER"
				@entity-selected="entitySelected" />

			<br />

			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="emitter.emit('closeRouteModal');">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="updateReminder">
							Update Reminder
						</button>
					</div>
				</div>
			</div>

		</form>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_LoadDatepicker from '@/services/app/loadDatepicker';
import AS_SyncData from '@/services/app/syncData';
import AS_ValidateForm from '@/services/app/validateForm';
import dateFormat from "dateformat";
import FormDatepicker from '@/components/form/Datepicker';
import FormEntityAssociation from '@/components/form/EntityAssociation';
import FormInput from '@/components/form/Input';

export default {
	name: 'ReminderSingle',
	props:{

	},
	data() {
		return {
			associatedEntityId: '0',
			associatedEntityTypeId: '0',
			dateDue: '',
			isComplete: false,
			reminderId: 0,
			reminderName: '',
			zFormId: 'formReminderSingle',
		}
	},
	computed:{

	},
	methods: {
		dateDueSet(newDate){
			this.dueDate = dateFormat(newDate, 'isoDateTime');
		},

		entitySelected(entitySelectedValues){
			if(entitySelectedValues.associatedEntityTypeId === '0' || entitySelectedValues.associatedEntityId === '0'){
				// if one is zero, make them both zero
				this.associatedEntityTypeId = '0';
				this.associatedEntityId = '0';

			} else {
				this.associatedEntityTypeId = entitySelectedValues.associatedEntityTypeId;
				this.associatedEntityId = entitySelectedValues.associatedEntityId;
			}
			
			// console.log(this.associatedEntityTypeId);
			// console.log(this.associatedEntityId);
		},

		updateReminder(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
			 	this.$store.commit('loaders/ADD_ID', 'App');

				let sendData = {
					"associatedEntityID": this.associatedEntityId,
					"associatedEntityTypeID": this.associatedEntityTypeId,
					"dueDate": this.dueDate,
					"isComplete": this.isComplete,
					"name": this.reminderName,
					'reminderID': this.reminderId,
				}
				// console.log('sendData');
				// console.log(sendData);

				ApiS_MakeCall({
					body: sendData,
					method: 'PUT',
					responseType: 'string',
					url: 'v4/Reminder',
				}).then(()=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.emitter.emit('closeRouteModal');

					this.emitter.emit('globalToasterOpen',{
						textContent: 'Reminder successfully updated',
					});
					
					this.emitter.emit('reloadReminderList');

				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm

		},//e:updateReminder
	},
	mounted() {
		this.emitter.emit('openRouteModal', {
			modalRoute: 'ReminderListSingle',
			modalParams: {
				encryptedId: this.$route.meta.paperTypeEnum
			},
		});

		AS_SyncData().then(()=>{
			// i need to load this reminder from it's encryptedId in the route
			let findEntity = this.$store.state.reminders.list.find((entityData)=>{
				return entityData.reminderEncryptedID === window.$vm.$route.params.encryptedId
			});

			if(findEntity){
				// Found - update the data
				// console.log(findEntity);
				this.reminderId = findEntity.reminderID;
				this.reminderName = findEntity.name;
				

				this.emitter.emit('loadEntityAssociationValues', {
					associatedEntityId: findEntity.associatedEntityID.toString() || '0',
					associatedEntityTypeId: findEntity.associatedEntityTypeID.toString() || '0'
				});

				this.dueDate = findEntity.dueDate;

				AS_LoadDatepicker({
					id: 'RS_dueDate',
					isIsoString: true,
					loadDate: findEntity.dueDate,
				});
				
				this.isComplete = findEntity.isComplete;

				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormId
				});
				
				this.$store.commit('loaders/REMOVE_ID', 'App');

			} else {
				// Could not find reminder by reminderEncryptedID
				this.emitter.emit('closeRouteModal');

			}//e:if
		
		});//e:AS_SyncData

	},
	components: {
		FormDatepicker,
		FormEntityAssociation,
		FormInput
	}
}
</script>
