/*
- Convert3pluTypeToUrl
-- takes a 3plu short name and converts it to a proper slug we use in types (seems like overkill for now but go with it )
-- 2022.09.22
*/

export default (thirdPartyReference) => {
	// console.log('Convert3pluTypeToUrl');

	switch(thirdPartyReference.shortName.toLowerCase()){
		case 'book':
			return 'book';
		case 'datasets':
			return 'datasets';
		case 'dissertations':
			return 'dissertations';
		case 'informalworks':
			return 'informally-published-works';
		case 'journal':
			return 'journal';
		case 'video':
		case 'videos':
			return 'videos';
		case 'webpage':
			return 'webpage';
		default:
			// usually a new 3plu has been added an the short name isn't in this list
			alert(thirdPartyReference.shortName.toLowerCase());
	}

}
