<!--
- Module Modal > Create Discussion Post
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create Discussion Post
		</h2>
			
		<p>Discussion Posts are frequently used to measure student participation in class.</p>
		<p>Discussion Posts include the body and reference section on a single page. This makes it easy to copy and paste its content into a web forum.</p>

		<form>
			<form-select
				label="Paper Format"
				:select-object="paperFormatSelectList"
				v-model="paperType" />
			
			<form-input
				label="Title"
				placeholder="(required)"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED"
				v-model="title" />

			<div class="row">
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="button" class="btn btn-outline-danger" @click.prevent="emitter.emit('closeRouteModal')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="submit" class="btn btn-success" @click.prevent="createDiscussionPost">
							Create Discussion Post
						</button>
					</div>
				</div>
			</div><!--row-->

		</form>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormSelect from '@/components/form/Select';
import mixinCreateDocument from '@/mixins/create-document';

export default {
	name: 'ModuleCreateDiscussionPost',
	mixins: [mixinCreateDocument],
	data() {
		return {
			paperType: 0,
			title: '',
			zFormId: 'formCreateDiscussionPost'
		}
	},
	computed:{
		paperFormatSelectList(){
			let returnPaperFormatSelectList = [
				{
					value: config.enums.PaperType.APA7_DiscussionPost.toString(),
					name: "APA 7",
				},
				{
					value: config.enums.PaperType.MLA9_DiscussionPost.toString(),
					name: "MLA 9",
				},
			]

			returnPaperFormatSelectList.push({
				value: config.enums.PaperType.Turabian9_DiscussionPost.toString(),
				name: "Turabian 9 - Footnotes",
			});

			return returnPaperFormatSelectList;

		},//e:paperFormatSelectList
	},
	methods: {
		createDiscussionPost(){
			AS_ValidateForm({
				formId: 'formCreateDiscussionPost'
			}).then(()=>{
				// main object passed to paper service create
				let sendData = {
					'Title': this.title,
					'PaperType':  parseInt(this.paperType),
					'UseNewCitationMode': true,
				}
			
				// append data if there should be an accociated class behind the scenes
				if(this.associatedClassId !== 0){
					sendData.ClassID = this.associatedClassId;
					sendData.AssociatedEntityID = this.associatedClassId;
					sendData.AssociatedEntityName = this.associatedClassName;
					sendData.AssociatedEntityTypeID = 2; // class entity type id is 2 in the old vmWare system
				}

				this.$store.commit('loaders/ADD_ID', 'App');

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					responseType: 'json',
					url: 'v3/Paper',
				}).then((responseData)=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.$router.push({
						name: 'EditorReferencesCitations',
						params:{
							encryptedId: responseData.encryptedPaperID
						},
					}).catch(()=>{});
					
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				
			});//e:AS_ValidateForm

		},//e:createDocument
	},
	mounted() {
		// refresh here to keep the modal open
		if(this.$route.meta.isPaperCreate){

			this.emitter.emit('openRouteModal', {
				modalRoute: this.$route.name,
				modalParams: {
					associatedClassId: '0',
				},
			});

			// use preferredFormatVersionID to calculate a default paper id
			switch(this.$store.state.customer.preferredFormatVersionID){
				case config.enums.Format.MLA9:
					this.paperType = config.enums.PaperType.MLA9_DiscussionPost.toString();
					break;
				case config.enums.Format.Turabian9:
					this.paperType = config.enums.PaperType.Turabian9_DiscussionPost.toString();
					break;
				default: // APA7
					this.paperType = config.enums.PaperType.APA7_DiscussionPost.toString();
			}

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});
		}
	},
	components: {
		FormInput,
		FormSelect,
	}
}

</script>
