var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group mb-0" }, [
    _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("label", [_vm._v("Search")]),
      _vm._v(" "),
      _c("div", {}, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.addFromLibraryIsByName,
                expression: "addFromLibraryIsByName",
              },
            ],
            key: "btnSortName",
            staticClass: "btn-sort",
            attrs: { href: "#", title: "Sort By:" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.setIsSortName(false)
              },
            },
          },
          [
            _vm._v("\n\t\t\t\tSorted By: "),
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Name")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.addFromLibraryIsByName,
                expression: "!addFromLibraryIsByName",
              },
            ],
            key: "btnSortCreated",
            staticClass: "btn-sort",
            attrs: { href: "#", title: "Sort By:" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.setIsSortName(true)
              },
            },
          },
          [
            _vm._v("\n\t\t\t\tSorted By: "),
            _c("span", { staticClass: "fw-bold" }, [_vm._v("Date Created")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex mt-1" }, [
      _c("div", { staticClass: "w-100" }, [
        _c("input", {
          directives: [
            {
              name: "debounce",
              rawName: "v-debounce:500ms",
              value: _vm.calcFilterReferenceLibrary,
              expression: "calcFilterReferenceLibrary",
              arg: "500ms",
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchTerm,
              expression: "searchTerm",
            },
          ],
          staticClass: "form-control",
          attrs: { placeholder: "Search your references", type: "text" },
          domProps: { value: _vm.searchTerm },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchTerm = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchTerm.length > 0,
              expression: "searchTerm.length > 0",
            },
          ],
          staticClass: "flex-shrink-1",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-link hover-underline",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clearSearch.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tClear\n\t\t\t")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }