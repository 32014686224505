var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid max-height overflow-x-hidden" },
    [
      _c("div", { staticClass: "row mx-0 max-height" }, [
        _c(
          "div",
          { staticClass: "col app-wrp" },
          [
            _c(
              "transition",
              { attrs: { name: "loader-fade" } },
              [_c("loader-component", { attrs: { "loader-id": "App" } })],
              1
            ),
            _vm._v(" "),
            _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PO &&
            _vm.$store.state.app.isSidebarShown
              ? _c("po-sidebar")
              : _vm._e(),
            _vm._v(" "),
            _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.CART
              ? _c("cart-header")
              : _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PO
              ? _c("po-header")
              : _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PCOM
              ? _c("pcom-header")
              : _vm._e(),
            _vm._v(" "),
            _c(
              "section",
              {
                class: [
                  "row",
                  {
                    "page-outer-cart":
                      _vm.$store.state.app.layoutEnum ===
                      _vm.$enums.Layout.CART,
                    "page-outer-pcom":
                      _vm.$store.state.app.layoutEnum ===
                        _vm.$enums.Layout.PCOM ||
                      _vm.$store.state.app.layoutEnum ===
                        _vm.$enums.Layout.SIMPLE,
                    "page-outer":
                      _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PO,
                    "page-outer-sm":
                      _vm.$store.state.app.layoutEnum ===
                        _vm.$enums.Layout.PO && _vm.$root.isNavSmall,
                  },
                ],
              },
              [
                _c(
                  "section",
                  { staticClass: "col page-inner-no-footer" },
                  [
                    _c(
                      "transition",
                      {
                        attrs: {
                          name: "route-fade",
                          mode: "out-in",
                          appear: "",
                        },
                      },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.$store.state.app.layoutEnum === _vm.$enums.Layout.PCOM
              ? _c("pcom-footer")
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("toaster"),
      _vm._v(" "),
      _c("modal-class-create"),
      _vm._v(" "),
      _c("modal-reminder-create"),
      _vm._v(" "),
      _c("modal-test-server-alert"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }