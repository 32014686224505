<!--
- Navigation Tabs > Navigatin Tab Content
-- Single Content Block
-- mainly used to auto scroll within any tab by default
-->

<template>
	<div :class="['overflow-y-auto mb-3', {'p-3': !isNoPadding}]" ref="tabContentScroll">
		<slot />
	</div>
</template>

<script>
import mixinResizer from '@/mixins/resizer';

export default {
	name: 'NavTabContent',
	mixins: [mixinResizer],
	props:{
		isNoPadding:{
			default: false,
			type: Boolean,
		}
	},
	data() {
		return {
			rzRef: 'tabContentScroll',
		}
	},
}
</script>

