/*
- Tool Drawer
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.toolDrawer.x
*/

export default {
	namespaced: true,
	
	state:{
		// local storage states
		openWidth: 350,	// 550max : 350min

		// non local storage states
		closedWidth: 25,
		isOpen: false,	// Bool: open/close the tool drawer (it's content is determined by route)
		isSideMenuOpen: false,	// Bool: open/close sidemenu inside paper drawer - SideMenuTrigger causes this action to be true
		// lastOpen: 'EditorReferencesCitations',	// String: last open drawer (Add From Library and Create Step 1 will default up to Refs and Cits)
		lastOpenOutline: 'OutlineReferencesCitations', // String: last open drawer in the OutlineTab
	},//e:state

	mutations: {
		IS_OPEN(state, value) {
			state.isOpen = value;
		},
		IS_SIDEMENU_OPEN(state, value) {
			state.isSideMenuOpen = value;
		},
		// LAST_OPEN(state, value){
		// 	state.lastOpen = value;
		// },
		SET_LAST_OPEN_OUTLINE(state, value){
			state.lastOpenOutline = value;
		},
		SET_OPEN_WIDTH(state, value){
			state.openWidth = value;
		}
	},//e:mutations
}
