<!--
- Forgot Password
-
-->

<template>
	<div class="mt-4 layout-pcom">
		<section class="section-text pt-0">
			<main class="container-text container-text-login-box-wrp mt-0 pt-3">
				<div class="white-inset login-box-wrp">
					<div class="inset-body">
						<h2 class="mb-0">
							Forgot Password
						</h2>

						<p>We'll send you an email with a link to reset your password from <a href="mailto:support@perrla.com">support@perrla.com</a>.</p>			

						<form>
							<form-input
								label="Email"
								type="email"
								v-model="email"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />

								<div class="d-grid">
									<button type="submit" class="btn btn-success" @click.prevent="btnSendForgotPassword">
										Change my password
									</button>
								</div>
						</form>

						<div class="divider d-none d-md-block w-100 my-4" />

						<p>
							If you can't remember the email address you used for PERRLA, email our Customer Support team at <a href="mailto:support@perrla.com">support@perrla.com</a>.
						</p>
						<p>
							Include your first name, last name, and the approximate date you started using PERRLA.
						</p>
						
						
					</div>
				</div>
			</main>
		</section>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import Swal from 'sweetalert2';

export default {
	name: 'ForgotPassword',
	data() {
		return {
			email: '',
			zFormId: 'formForgotPassword',
		}
	},
	methods: {
		btnSendForgotPassword(){
			this.$store.commit('loaders/ADD_ID', 'App');

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				ApiS_MakeCall({
					ignoreAuth: true,
					method: 'POST',
					params:{
						'email': this.email,
					},
					url: 'v4/Customer/ForgotPassword/Send',
				}).then(()=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: 'We\'ve sent an email to reset your password.',
						text: 'Check the email address associated with your PERRLA account for an email with a link to reset your password.',
						icon: 'success',
						confirmButtonText: 'OK',
						showCloseButton: false,
						showCancelButton: false,
						showConfirmButton: true,
						customClass:{
							confirmButton: 'btn btn-success',
						},
					}).then((result) => {
						this.$store.commit('loaders/REMOVE_ID', 'App');
					});

				}).catch(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				this.isLoginError = true;

			});//e:AS_ValidateForm
		},//e:btnSendForgotPassword
	},
	mounted(){
		this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);

		// force remove any theme classes - this view will not have a customer theme
		document.documentElement.setAttribute('data-bs-theme', 'light')
		document.body.classList.remove('theme-serrena');
		document.body.classList.add('bg-simple');

		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components: {
		FormInput
	}
}
</script>
