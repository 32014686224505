var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: ["tr-entity-grid-row tr-entity-grid-class"],
      style: _vm.calcHoverClassColorStyle,
      attrs: { "color-hover": _vm.classDataColorValue },
    },
    [
      _c(
        "td",
        {
          staticClass: "td-entity-title cursor-pointer",
          attrs: { title: "Open Class" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.gotoGridElement.apply(null, arguments)
            },
          },
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.classDataName) + " "),
          _vm.classDataIsArchived
            ? _c("span", { staticClass: "badge" }, [_vm._v("Archived")])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.context === "ClassList"
        ? _c(
            "td",
            {
              staticClass: "cursor-pointer",
              style: _vm.calcClassColorStyle,
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.gotoGridElement.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.classDataTeacherName) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "td-more-menu" }, [
        _c("div", { staticClass: "dropstart" }, [
          _c("a", {
            class: ["svg bi-three-dots-vertical float-end"],
            attrs: {
              href: "#",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false",
              "aria-label": "More Options",
            },
          }),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.gotoGridElement.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tOpen Class\n\t\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-danger",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnToggleIsArchived.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.classDataIsArchived
                    ? _c("span", { key: "spanRestoreClass" }, [
                        _vm._v("\n\t\t\t\t\t\t\tRestore\n\t\t\t\t\t\t"),
                      ])
                    : _c("span", { key: "spanArchiveClass" }, [
                        _vm._v("\n\t\t\t\t\t\t\tArchive\n\t\t\t\t\t\t"),
                      ]),
                  _vm._v("\n\t\t\t\t\t\tClass\n\t\t\t\t\t"),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }