var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative mb-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.filteredGridData && _vm.filteredGridData.length > 0
          ? _c(
              "table",
              { staticClass: "table table-borderless entity-grid mb-0" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Title",
                          "sort-key": "title",
                          colspan: "2",
                          "is-title": true,
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("th", [_vm._v("\n\t\t\t\t\t\t\tType\n\t\t\t\t\t\t")]),
                      _vm._v(" "),
                      _c("th", { staticClass: "table-spacer" }),
                      _vm._v(" "),
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Last Modified",
                          "sort-key": "lastModifiedDate",
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.filteredGridData, function (paperData) {
                    return _c("paper-row", {
                      key: paperData.paperID,
                      attrs: {
                        "class-id": paperData.classID,
                        "paper-class-name": paperData.className,
                        "paper-description": paperData.description,
                        "paper-due-date": paperData.dueDate,
                        "paper-encrypted-id": paperData.encryptedID,
                        "paper-format-version-id":
                          paperData.paperFormatVersionID,
                        "paper-id": paperData.paperID,
                        "paper-is-complete": paperData.isComplete,
                        "paper-last-modified-date": paperData.lastModifiedDate,
                        "paper-start-date": paperData.startDate,
                        "paper-title": paperData.title,
                        "paper-type": paperData.paperType,
                        context: "ClassEdit",
                      },
                    })
                  }),
                  1
                ),
              ]
            )
          : _c("div", { staticClass: "text-muted" }, [
              _c("p", [_vm._v("No recent papers found.")]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("Recent Papers")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }