import config from '@/config';

import Calendar from '@/views/Calendar';

export default [
	{
		name: 'Calendar',
		component: Calendar,
		path: '/calendar',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
]