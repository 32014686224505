<!--
- Overview Tab > Recent Paper Saves > Version Row
-
-->

<template>
	<tr :class="calcRowClasses">
		<td class="td-entity-title">
			{{ calcVersionDate }}
		</td>
		<td v-html="paperVersionData.action" class="word-break" />
		<td>
			<a href="#" class="link-no-underline" @click.prevent="openPDF">Preview PDF</a>
		</td>
		<td>
			<a href="#" class="link-no-underline" @click.prevent="restoreVersion" v-if="isRestoreable">Restore this version</a>
		</td>
		<td class="td-more-menu">
			<div class="dropstart">
				<a href="#" :class="['svg bi-three-dots-vertical float-end']" data-bs-toggle="dropdown" aria-expanded="false" />
				<ul class="dropdown-menu">
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.WORD)">
							Download PERRLA Word File
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.WORD_SIMPLE)">
							Download Simplified Word File
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="openDownloadedDocument($enums.DownloadPaperContentType.PDF)">
							Download PDF
						</a>
					</li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import PS_RestoreVersion from '@/services/paper/restoreVersion';
import Swal from 'sweetalert2';

export default {
	name: 'RecentPaperSavesRow',
	props:{
		paperVersionData:{
			required: true,
			type: Object
		},
	},
	computed:{
		calcRowClasses(){
			let returnObj = ['tr-entity-grid-row'];
			
			if(this.isActiveVersion) {
				returnObj.push('tr-background-success');
				returnObj.push('tr-entity-grid-success');
			} else {
				returnObj.push('tr-entity-grid-primary');
			}
			
			return returnObj;
		},
		calcVersionDate(){
			return dateToLongString({
				dateValue: this.paperVersionData.versionDate,
				showTime: true,
			});
		},
		isActiveVersion(){
			if(_has(this.paperVersionData, 'versionID')){
				return (this.paperVersionData.versionID == this.$store.state.paperEdit.activeVersionId);
			} else {
				Swal.fire({
					buttonsStyling: false,
					text: 'versionID not found in paperVersionData - console.log has more info',
					icon: 'error',
					confirmButtonText: 'Ok',
					showCloseButton: false,
					showConfirmButton: true,
					customClass:{
						confirmButton: 'btn btn-danger me-2',
					},
				});
				return false;
			}
		},
		isRestoreable(){
			if(!this.isActiveVersion){
				return true;
			}
			return false;
		},
	},
	methods: {
		openDownloadedDocument(contentType){
			PS_DownloadDocumentVersionV4({
				contentType: contentType,
				platform: 1, // just Online Papers here
				versionDateOuput: this.calcVersionDate,
				versionId: this.paperVersionData.versionID,
			});
		},

		openPDF(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.PDF,
				isOpenAfter: true,
				platform: 1, // just Online Papers here
				versionDateOuput: this.calcVersionDate,
				versionId: this.paperVersionData.versionID,
			});
		},

		restoreVersion(){
			Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'Are you sure you want to restore your paper?',
					html: `<p>Restoring your paper to an earlier version will replace your paper&#39;s current content with the information from a previous save - including References, Citations, typed text, and any outline content.</p>
					<p>We will restore your paper and open the Editor with the restored content.</p>
					<p><a href="https://perrla.zendesk.com/hc/en-us/articles/12494596430107-How-Paper-Versioning-works-in-PERRLA-Online" rel="noopener" target="_blank">You can learn more about paper versions in PERRLA Online here.</a></p>
					`,
					iconHtml: '<span class="svg icons8-restore"/>',
					cancelButtonText: `Nevermind`,
					confirmButtonText: `Restore my paper`,
					showCloseButton: false,
					showCancelButton: true,
					showConfirmButton: true,
					customClass:{
						cancelButton: 'btn btn-outline-danger',
						confirmButton: 'btn btn-primary me-2',
						icon: 'custom-icon'
					},
				}).then((result) => {
					if (result.value) {
						PS_RestoreVersion({
							paperId: this.$store.state.paperEdit.meta.PaperID,
							versionId: this.paperVersionData.versionID,
						}).then(()=>{
							// reload the page - this will reload ckEditor with the full paper data in it
							// this.$router.go();
							window.location.reload();
						});
					}
				});
		},
	},
}
</script>
