import config from '@/config';

import PaperTabOutline from '@/views/Paper/TabOutline';
import OutlineAddFromLibrary from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/AddFromLibrary';
import OutlineCitationAdd from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CitationAdd';
import OutlineCitationEdit from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CitationEdit';
import OutlineConvertReferenceStep1 from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/ConvertStep1';
import OutlineConvertReferenceStep2 from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/ConvertStep2';
import OutlineCreateReferenceStep1 from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep1';
import OutlineCreateReferenceStep2 from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep2';
import OutlineCreateStep2ThirdParty from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep2ThirdParty';
import OutlineCreateReferenceStep3 from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep3';
import OutlineCreateStep3ThirdParty from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep3ThirdParty';
import OutlineCreateStep4ThirdParty from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/CreateStep4ThirdParty';
import OutlineEditReference from '@/views/Paper/TabOutline/ToolDrawer/EditReference';
import OutlineCitationLastVisitedDrawer from '@/views/Paper/TabOutline/ToolDrawer/LastVisited';

import OutlineReferencesCitations from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations';
import OutlineResearchNotes from '@/views/Paper/TabOutline/ToolDrawer/ResearchNotes';
import OutlineReviewReference from '@/views/Paper/TabOutline/ToolDrawer/ReferencesCitations/ReviewReference';

const moduleEnum = config.enums.Module.PAPER_EDIT;
const tabEnum = config.enums.Tab.OUTLINE;

export default [
	{
		name: 'PaperTabOutline',
		component: PaperTabOutline,
		path: 'outline',
		meta: {
			layout: config.enums.Layout.PO,
			module: moduleEnum,
			tab: tabEnum,
		},
		children:[
			{
				name: 'OutlineCitationLastVisitedDrawer',
				component: OutlineCitationLastVisitedDrawer,
				path: 'last-visited',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCitationAdd',
				component: OutlineCitationAdd,
				path: 'citation-add/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineAddFromLibrary',
				component: OutlineAddFromLibrary,
				path: 'reference-add-from-library',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCitationEdit',
				component: OutlineCitationEdit,
				path: 'citation-edit/:citationUniqueId',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineConvertReferenceStep1',
				component: OutlineConvertReferenceStep1,
				path: 'convert-reference-1',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineConvertReferenceStep2',
				component: OutlineConvertReferenceStep2,
				path: 'convert-reference-2',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateReferenceStep1',
				component: OutlineCreateReferenceStep1,
				path: 'create-reference-1/:openReferenceTab',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateReferenceStep2',
				component: OutlineCreateReferenceStep2,
				path: 'create-reference-2/:openReferenceTab/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateStep2ThirdParty',
				component: OutlineCreateStep2ThirdParty,
				path: 'create-reference-2-3plu',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateReferenceStep3',
				component: OutlineCreateReferenceStep3,
				path: 'create-reference-3/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateStep3ThirdParty',
				component: OutlineCreateStep3ThirdParty,						
				path: 'create-reference-3-3plu/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineCreateStep4ThirdParty',
				component: OutlineCreateStep4ThirdParty,
				path: 'create-reference-4-3plu',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineEditReference',
				component: OutlineEditReference,
				path: 'reference-edit/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineReferencesCitations',
				component: OutlineReferencesCitations,
				path: 'references-citations',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'OutlineResearchNotes',
				component: OutlineResearchNotes,
				path: 'research',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum,
				},
			},
			{
				name: 'OutlineReviewReference',
				component: OutlineReviewReference,
				path: 'reference-review-genesis/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
		],
	},
]