var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Insert Table" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _vm.showAppendixInsert
        ? _c("div", { key: "divAppendixInsert" }, [
            _c("h2", [_vm._v("Adding a correct Table Number in an appendix")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Appendices require unique Table Numbers in the APA format depending on the content presented in the appendix."
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                'Any appendix callouts in your paper should refer to the appendix label directly (e.g., "...as seen in the Appendix.").'
              ),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Tables in a single appendix should be numbered: Table A1, Table A2, etc."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Tables in Appendix B or greater should be numbered: Table B1, Table C1, etc."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                'Any appendix callouts in the body of your paper should refer to the table or figure number directly (e.g., "According to Table A1...").'
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showAppendixInsert = false
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tInsert a new table\n\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _vm._m(3),
          ])
        : _c("div", { key: "divNoAppendixInsert" }, [
            _c("p", [
              _vm._v(
                "To create a table, place your cursor where you want the table to be placed. Then, enter the table's details below."
              ),
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c(
              "form",
              [
                _c("form-input", {
                  attrs: {
                    label: "Table Title",
                    "form-id": _vm.zFormId,
                    "help-text": _vm.calcHelpText,
                    "validate-enum": _vm.$enums.Validation.REQUIRED,
                  },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
                _vm._v(" "),
                _c("form-input", {
                  attrs: {
                    label: "Columns",
                    "form-id": _vm.zFormId,
                    "help-text":
                      "The number of columns needed, including Headings.",
                    "validate-enum": _vm.$enums.Validation.NUMERIC_REQUIRED,
                  },
                  model: {
                    value: _vm.columns,
                    callback: function ($$v) {
                      _vm.columns = $$v
                    },
                    expression: "columns",
                  },
                }),
                _vm._v(" "),
                _c("form-input", {
                  attrs: {
                    label: "Rows",
                    "form-id": _vm.zFormId,
                    "help-text":
                      "The number of rows needed, including Headings.",
                    "validate-enum": _vm.$enums.Validation.NUMERIC_REQUIRED,
                  },
                  model: {
                    value: _vm.rows,
                    callback: function ($$v) {
                      _vm.rows = $$v
                    },
                    expression: "rows",
                  },
                }),
                _vm._v(" "),
                _vm.$store.state.paperEdit.config.allowTableNotes
                  ? _c("div", [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group form-check ms-3 mb-3 mt-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.includeNotes,
                                expression: "includeNotes",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "chkIncludeNotes" },
                            domProps: {
                              checked: Array.isArray(_vm.includeNotes)
                                ? _vm._i(_vm.includeNotes, null) > -1
                                : _vm.includeNotes,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.includeNotes,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.includeNotes = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.includeNotes = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.includeNotes = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "chkIncludeNotes" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tInclude notes\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit", disabled: !_vm.canAddTable },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.insertTable.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tInsert Table\n\t\t\t\t\t")]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPaperAbstract
              ? _c("p", { staticClass: "text-danger mt-2" }, [
                  _vm._v(
                    "\n\t\t\t\tNote: Inserting a table in your abstract is not proper formatting for this type of paper.\n\t\t\t"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._m(6),
          ]),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "If the table is the only element in the appendix, no table number should be shown."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("The title of the Appendix takes the place of the table number. "),
      _c("em", [_vm._v("Highlight the table number text and delete it.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "If the appendix contains other elements, the table should be numbered according to the Appendix label."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-empty mt-4" }, [
      _c("p", [
        _c(
          "a",
          {
            attrs: {
              href: "https://perrla.zendesk.com/hc/en-us/articles/1260804431829-Figure-Table-Titles-in-Appendices-APA-7-",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [
            _vm._v(
              "\n\t\t\t\t\t\tFor a complete description of tables in appendices, click here.\n\t\t\t\t\t"
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Enter your table's details below, then click "),
      _c("em", [_vm._v("Insert Table")]),
      _vm._v(". PERRLA will create and format your table for you."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group mb-0" }, [
      _c("label", [
        _vm._v("\n\t\t\t\t\t\t\tTable notes "),
        _c("span", { staticClass: "font-weight-normal" }, [
          _vm._v("(optional)"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "help-text" }, [
        _vm._v(
          "\n\t\t\t\t\t\t\tTable notes are used to clarify information and avoid repetition in a table.\n\t\t\t\t\t\t"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-empty mt-4" }, [
      _c("p", [_c("strong", [_vm._v("Writing Tips for Tables")])]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Once your table is created, you can edit its content, title, and notes directly in your paper."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }