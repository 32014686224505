// injected into testimonials

export default [
		//1
		{
			name: 'Rodney D.',
			quote: 'It literally saved me HOURS of time and energy. There are so many &#39;generators&#39; out there and none of them come close to the perfection that is PERRLA.',
		},
		{
			name: 'Darla C.',
			quote: 'PERRLA has really helped me going back to college! It&#39;s an excellent program and very easy to use! When I got my undergraduate degree, we did not have to use APA format so I was behind the eight ball when I started my graduate degree. PERRLA has made it so much easier.',
		},
		{
			name: 'Chet B.',
			quote: 'Seamless integration into Word. It has saved me a lot of time and headaches in trying to relearn academic formatting in my return to grad school. Highly recommended.',
		},
		//2
		{
			name: 'Kimberly S.',
			quote: 'This is the most amazing way to write college level papers! This will take ALL the stress away. No more worrying about &#39;did I get the citations correct?&#39; This add-in does it for you! It is a must-purchase to make writing APA/MLA papers a dream! You&rsquo;ll wish you&rsquo;d done it sooner.',
		},
		{
			name: 'Heather H.',
			quote: 'This software is simply awesome! After 14 years of not writing papers I went back to writing papers with ease and formatting them like a pro! You will not be disappointed!',
		},
		{
			name: 'Joe R.',
			quote: 'I have been using this product for about 6 or more years and was quickly impressed with its ease of use and functionality. It managed to help me get all of my referencing right on point, which at one time got me on the Dean&#39;s list. I always recommend PERRLA to everyone I know that is in school...',
		},
		//3
		{
			name: 'Lynda T.',
			quote: 'PERRLA has made citations in my graduate school papers SO much easier than I could have imagined. Thank you, PERRLA, for saving me hours and hours double and triple checking my APA style reference pages.',
		},
		{
			name: 'D. A.',
			quote: 'I had been out of school for almost 20 years. PERRLA was my life saver when I had to start writing papers again. Easy to use, keeps all your prior references to reuse, company constantly updating you to keep you ahead of the game. Like I said, don&#39;t leave home without it.',
		},
		{
			name: 'Rita U.',
			quote: 'If I had not had PERRLA, I would not have made it through school while working full time. It almost wrote my papers for me! Now I am teaching and back in school while working full time. But I got this. Why? PERRLA! I tell all my students and classmates to use it. Worth every cent.',
		},
		//4
		{
			name: 'Judith N.',
			quote: 'I LOVE PERRLA!!!! Have used for years both in undergrad and graduate classes. All my papers are done in PERRLA, I am SO happy that I can concentrate on content and PERRLA does my formatting! I have recommended the software to everyone I know that has to write APA formatted papers, and they have all thanked me for it.',
		},
		{
			name: 'David M.',
			quote: 'I have been using PERRLA for several years now. PERRLA saves me hours every week and paid for itself in the first week of use. Not only does it pay for itself in the hours it saves me, but it allows me to focus on content rather than format or looking up how to cite obscure references. It does all that for you, correctly, and it keeps all of your valuable references in your library for quick use. Never lose a reference again!',
		},
		{
			name: 'Heather R.',
			quote: 'I have been out of school for 18 years and have not written any papers in about 23 years. This program makes writing APA papers easier than I would have thought. I enjoy the fact that when I enter a reference the program guides me through the process.',
		},
		//5
		{
			name: 'Joette',
			quote: 'I have been using PERRLA for 3 years now. The newest update, making PERRLA an add-in of WORD, is smooth and effortless. I love the fact that I can save my documents anywhere that I would like.',
		},
		{
			name: 'Melissa C.',
			quote: 'Guys...When I say you need this...You NEED THIS. The new in-app function is amazing! It&#39;s so much easier to use, you don&#39;t have to have multiple windows open, and the only thing I wish it had was spellcheck BUT Word does that for you already. If you&#39;re doubting getting this, please don&#39;t! You will never go back to formatting a paper on your own. I&#39;m so so so glad I found this!',
		},
		{
			name: 'Al',
			quote: 'I have used PERRLA 5 semesters, and I cannot imagine writing an APA paper without it. Support is very responsive. The new release is nice. I highly recommend this product.',
		},
];