<!--
- SortableGridTh
-- <th> element for any Grid of Entities that can click to be sorted
-- currently used for PaperList and ReferenceList
-->

<template>
	<th :class="['sortable', {'th-entity-title': isTitle, 'sort-by': gridOrderBy === sortKey, 'text-end': isAlignRight}]" @click.prevent="sortByThisKey" :width="setWidth">
		{{ cellText }}
		<span :class="['sort-arrow',{'sort-asc': gridSorts[sortKey] === 1, 'sort-des': gridSorts[sortKey] === -1}]" />
	</th>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import config from '@/config';

export default {
	name: 'SortableGridTh',
	props:{
		cellText:{
			required: true,
			type: String,
		},
		entityId:{
			type: Number,
		},
		isAlignRight:{
			default: false,
			type: Boolean,
		},
		isLocalStorage:{
			default: false,
			type: Boolean,
		},
		isTitle:{
			default: false,
			type: Boolean,
		},
		setWidth:{
			type: String,
		},
		sortKey:{
			required: true,
			type: String,
		},
		sortOrderBy:{	// for when local storage sort is off
			default: '',
			type: String,
		},
		sorts:{	// for when local storage sort is off
			type: Object,
		},
		
	},
	computed:{
		gridOrderBy(){
			if(this.isLocalStorage){
				switch(this.entityId){
					case config.enums.Entity.CLASS:
						return this.$store.state.gridSorts.classListOrderBy;
					case config.enums.Entity.PAPER:
						return this.$store.state.gridSorts.paperListOrderBy;
					case config.enums.Entity.REFERENCE:
						return this.$store.state.gridSorts.referenceListOrderBy;
					case config.enums.Entity.REMINDER:
						return this.$store.state.gridSorts.reminderListOrderBy;
				}
			} else {
				return this.sortOrderBy;
			}
			return '';
		},
		gridSorts(){
			if(this.isLocalStorage){
				switch(this.entityId){
					case config.enums.Entity.CLASS:
						return this.$store.state.gridSorts.classListSorts;
					case config.enums.Entity.PAPER:
						return this.$store.state.gridSorts.paperListSorts;
					case config.enums.Entity.REFERENCE:
						return this.$store.state.gridSorts.referenceListSorts;
					case config.enums.Entity.REMINDER:
						return this.$store.state.gridSorts.reminderListSorts;
				}
			} else {
				return this.sorts;
			}
			return '';
		},
	},
	methods: {
		sortByThisKey(){
			if(this.isLocalStorage){
				switch(this.entityId){
					case config.enums.Entity.CLASS:
						this.$store.commit('gridSorts/CLASS_LIST_ORDER_BY', this.sortKey);
						this.$store.commit('gridSorts/CLASS_LIST_SORTS_TOGGLE', this.sortKey);
						break;
					case config.enums.Entity.PAPER:
						this.$store.commit('gridSorts/PAPER_LIST_ORDER_BY', this.sortKey);
						this.$store.commit('gridSorts/PAPER_LIST_SORTS_TOGGLE', this.sortKey);
						break;
					case config.enums.Entity.REFERENCE:
						this.$store.commit('gridSorts/REFERENCE_LIST_ORDER_BY', this.sortKey);
						this.$store.commit('gridSorts/REFERENCE_LIST_SORTS_TOGGLE', this.sortKey);
						break;
					case config.enums.Entity.REMINDER:
						this.$store.commit('gridSorts/REMINDER_LIST_ORDER_BY', this.sortKey);
						this.$store.commit('gridSorts/REMINDER_LIST_SORTS_TOGGLE', this.sortKey);
						break;
				}
				
				this.$emit('update-grid');

			} else {
				let _sorts = _cloneDeep(this.sorts);
				_sorts[this.sortKey] = _sorts[this.sortKey] * -1;
				this.$emit('update-grid', {
					sortKey: this.sortKey,
					sorts: _sorts
				});
			}
		},
	},
}
</script>
