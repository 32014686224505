<!--
- Outline Editor > Ribbon
-->

<template>
	<div class="rb-wrp clearfix">
		
		<div class="rb float-start">

			<router-link
				:class="['rb-btn',{'rb-btn-active': $route.name === 'OutlineReferencesCitations'}]"
				:to="{ name: 'OutlineReferencesCitations' }">
				<div class="rb-icon rb-icon-references" />
				References &amp;
				<br />
				Citations
			</router-link>
		
			<router-link
				:class="['rb-btn',{'rb-btn-active': $route.name === 'OutlineResearchNotes'}]"
				:to="{ name: 'OutlineResearchNotes' }">
				<div class="rb-icon rb-icon-research-notes" />
				Research Notes
			</router-link>

			<div class="rb-divider" />

		</div> <!--rb-left-->

		<ck-toolbar editor-type="outline" />
		
	</div>
</template>

<script>
import CkToolbar from '@/components/CkToolbar';

export default {
	name: 'RibbonComponent',
	computed:{
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
	},
	components:{
		CkToolbar,
	},
}
</script>
