var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      class: [
        "nav-header",
        {
          "nav-header-alpha": _vm.showAlphaFeatures && _vm.showBetaFeatures,
          "nav-header-beta": !_vm.showAlphaFeatures && _vm.showBetaFeatures,
        },
      ],
    },
    [
      _c(
        "router-link",
        {
          staticClass: "logo-link me-3 ps-3",
          attrs: { to: { name: "Dashboard" }, title: "PERRLA" },
        },
        [
          _c("img", {
            staticClass: "me-2",
            attrs: {
              src: "///perrla.s3.amazonaws.com/images/logo-p-small.png",
              alt: "PERRLA, LLC",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("PERRLA")]),
          _vm._v(" "),
          _c("h1", [_vm._v("PERRLA")]),
        ]
      ),
      _vm._v(" "),
      _vm.$route.meta.module === _vm.$enums.Module.DASHBOARD
        ? _c("quick-add-menu")
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.module === _vm.$enums.Module.DASHBOARD &&
      _vm.subscriptionExpiresMessageOutput != "" &&
      _vm.$store.state.customer.organizationId === 0
        ? _c(
            "p",
            { staticClass: "d-none d-md-block mb-0" },
            [
              _c(
                "router-link",
                {
                  staticClass: "hover-underline",
                  attrs: {
                    to: {
                      name: "MyProfile",
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.subscriptionExpiresMessageOutput) +
                      " \n\t\t\t\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav ms-auto" }, [
        _vm.showImpersonationMenu
          ? _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  class: ["nav-link dropdown-trigger-icon"],
                  attrs: {
                    href: "#",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                    "aria-label": "DevTools",
                  },
                },
                [
                  _c("span", {
                    class: [
                      "svg",
                      {
                        "bi-lightning-fill":
                          _vm.showAlphaFeatures || _vm.showBetaFeatures,
                        "bi-lightning":
                          !_vm.showAlphaFeatures && !_vm.showBetaFeatures,
                      },
                    ],
                  }),
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
                _vm.showAlphaFeatures
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          key: "dropdownHideAlpha",
                          staticClass: "dropdown-item hover hover-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setShowAlphaFeatures(false)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tAlpha Features Off\n\t\t\t\t\t")]
                      ),
                    ])
                  : !_vm.showAlphaFeatures
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          key: "dropdownShowAlpha",
                          staticClass: "dropdown-item hover hover-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setShowAlphaFeatures(true)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tAlpha Features On\n\t\t\t\t\t")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showBetaFeatures
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          key: "dropdownHideBeta",
                          staticClass: "dropdown-item hover hover-warning",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setShowBetaFeatures(false)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tBeta Features Off\n\t\t\t\t\t")]
                      ),
                    ])
                  : _c("li", [
                      _c(
                        "a",
                        {
                          key: "dropdownShowBeta",
                          staticClass: "dropdown-item hover hover-warning",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setShowBetaFeatures(true)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tBeta Features On\n\t\t\t\t\t")]
                      ),
                    ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearLocalLogs.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tClear Local Logs\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearLocalPapers.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tClear Local Papers\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearLocalStorage.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tClear Local Storage\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        attrs: { to: { name: "CountDown" } },
                      },
                      [_vm._v("\n\t\t\t\t\t\tCountdown Timer\n\t\t\t\t\t")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item hover hover-danger",
                        attrs: { to: { name: "ErrorTests" } },
                      },
                      [_vm._v("\n\t\t\t\t\t\tError Tests\n\t\t\t\t\t")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-danger",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.expireCurrentToken.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tExpire Current Token\n\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item hover hover-success",
                        attrs: { to: { name: "FreeTrialDiscount" } },
                      },
                      [_vm._v("\n\t\t\t\t\t\tFree Trial Discount\n\t\t\t\t\t")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item hover hover-success",
                        attrs: { to: { name: "DataDecoder" } },
                      },
                      [_vm._v("\n\t\t\t\t\t\tData Decoder\n\t\t\t\t\t")]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: {
                href: "#",
                role: "button",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
              },
            },
            [
              _c("span", { attrs: { id: "userFullName" } }, [
                _vm._v(_vm._s(_vm.$store.state.customer.fullName)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    key: "settingsElse",
                    staticClass: "dropdown-item",
                    attrs: {
                      to: {
                        name: "MyProfile",
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tSettings\n\t\t\t\t\t")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "Logout" } },
                  },
                  [_vm._v("\n\t\t\t\t\t\tLogout\n\t\t\t\t\t")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }