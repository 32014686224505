var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-display-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _c("label", {
        staticClass: "form-label form-label-v2",
        attrs: { for: _vm.fullId },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "col-value-full-width": _vm.isFullWidth,
          "col-value": !_vm.isFullWidth,
        },
      },
      [
        _c("div", {
          class: ["form-control-display", { "text-danger": _vm.displayClass }],
          domProps: { innerHTML: _vm._s(_vm.value) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }