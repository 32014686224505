var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "d-block ref-type-select-row rounded",
      attrs: { href: "#" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.goToReferenceType.apply(null, arguments)
        },
      },
    },
    [
      _c("p", { staticClass: "mb-0 org-size" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.referenceTypeTitle) + "\n\t"),
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "mb-0",
        domProps: { innerHTML: _vm._s(_vm.referenceTypeDescription) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }