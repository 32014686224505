var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "th",
    {
      class: [
        "sortable",
        {
          "th-entity-title": _vm.isTitle,
          "sort-by": _vm.gridOrderBy === _vm.sortKey,
          "text-end": _vm.isAlignRight,
        },
      ],
      attrs: { width: _vm.setWidth },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.sortByThisKey.apply(null, arguments)
        },
      },
    },
    [
      _vm._v("\n\t" + _vm._s(_vm.cellText) + "\n\t"),
      _c("span", {
        class: [
          "sort-arrow",
          {
            "sort-asc": _vm.gridSorts[_vm.sortKey] === 1,
            "sort-des": _vm.gridSorts[_vm.sortKey] === -1,
          },
        ],
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }