var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hotkey",
          rawName: "v-hotkey",
          value: _vm.keymapCloseButton,
          expression: "keymapCloseButton",
        },
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      ref: "citationContextMenu",
      class: [
        "citation-context-menu-wrp rounded",
        { visible: _vm.isOpen, invisible: !_vm.isOpen },
      ],
    },
    [
      _vm.referencesInPaper.length > 0
        ? _c("div", { key: "divListReferencesInPaper" }, [
            _c(
              "div",
              {
                ref: "listOfReferencesWrp",
                staticClass: "citation-context-menu-scroll",
              },
              _vm._l(_vm.referencesInPaper, function (referenceObject) {
                return _c(
                  "div",
                  {
                    key: referenceObject.referenceUniqueID,
                    staticClass: "d-block",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "citation-context-menu-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.gotoCitationCreate(
                              referenceObject.referenceUniqueID
                            )
                          },
                        },
                      },
                      [
                        _c("p", [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                referenceObject.citationDisplayValue
                              ),
                            },
                          }),
                          _vm._v(" - "),
                          _c("em", [
                            _vm._v(" " + _vm._s(referenceObject.primaryTitle)),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _c("div", { key: "divEmtyReferencesInPaper" }, [_vm._m(0)]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty-state-wrp p-2" }, [
      _c("p", [
        _vm._v(
          "\n\t\t\t\tCreate a new reference or add an existing one to your paper. "
        ),
        _c("br"),
        _vm._v(
          "\n\t\t\t\tYou can create citations once you've added a reference to your paper.\n\t\t\t"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }