<!--
- Module > Edit Reference > Body
-->

<template>
	<div>
		<form>
			<h2>Reference Data - {{ referenceTypeName }}</h2>

			<div class="alert alert-info">
				<span class="svg bi-check-circle-fill float-start" />
				<p class="ps-3">
					Commonly used fields are outlined in blue.
				</p>
			</div>
			
			<reference-types
				v-if="editReference && editReference.referenceTypeID"
				:reference-type-id="editReference.referenceTypeID"
				:reference-rules-string="editReference.data" />

			<reference-output />

			<copy-json-data :data-to-display="JSON.stringify($store.state.referenceRules.rulesObjectData)" />

			<h2 style="margin-top:32px;">
				Reference Information
			</h2>

			<form-input
				label="Nickname"
				help-text="Optional. Shows in Library &amp; Panel."
				pendo-grab-text="Txt_RefEdit_Nickname"
				v-if="nickname != ''"
				v-model="nickname" />
			
			<form-textarea
				label="Description"
				:is-autosize="true"
				help-text="Add a private description to your Reference."
				pendo-grab-text="Txt_RefEdit_Description"
				v-model="note" />
			
			<form-tags
				v-model="tags"
				pendo-grab-text="Txt_RefEdit_Tags" />

			<div v-if="showDeleteReference" key="divFooterButtonsReferenceList" class="row mt-2">
				<div class="col">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="deleteReferenceConfirm">
							Archive Reference
						</button>
					</div>
				</div>
				<div class="col">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="updateReference">
							Update Reference
						</button>
					</div>
				</div>
			</div>
			<div class="d-grid" v-else>
				<button type="submit" class="mt-2 btn btn-success" @click.prevent="updateReference">
					Update Reference
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_CheckToken from '@/services/app/checkToken';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import CopyJsonData from '@/components/CopyJsonData'
import FormInput from '@/components/form/Input';
import FormTags from '@/components/form/Tags';
import FormTextarea from '@/components/form/Textarea';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import mixinTagCleaner from '@/mixins/tag-cleaner';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import ReferenceOutput from '@/components/ReferenceOutput';
import ReferenceTypes from '@/components/reference-rules-genesis/Types';
import RS_Compress from '@/services/reference/compress';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import RS_LoadByRoute from '@/services/reference/loadByRoute';
import Swal from 'sweetalert2';
import TDS_Open from '@/services/paper/toolDrawer/open';

export default {
	name: 'EditReferenceBody',
	mixins: [mixinTagCleaner],
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			clipboard: null,
			// editReference: null,
			nickname: '',
			note: '',
			tags: '',
		}
	},
	watch: {	// for when a user has a reference open to edit it in the paper editor drawer, then clicks another reference from the reference page
		$route(to, from) {
			RS_LoadByRoute({
				isForceUpdate: true,
			}).then(()=>{
				this.loadReferenceUI();
			});
		}
	},
	computed:{
		editReference(){
			return this.$store.state.referenceEdit.openObject;
		},
		referenceTypeName(){
			return this.$store.state.referenceRules.referenceTypeName;
		},
		showDeleteReference(){
			if(!this.isDrawer && this.$route.matched.length > 0 && this.$route.matched[0].name === 'ReferenceEdit'){
				return true;
			}
			return false;
		},
	},
	methods: {
		deleteReferenceConfirm(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'This will remove it from your library permanently.  Are you sure?',
				text: 'Archiving your reference will remove it from your library. It will be disabled in any papers where it was used.',
				icon: 'warning',
				cancelButtonText: 'Cancel',
				confirmButtonText: 'Archive Reference',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-primary',
					confirmButton: 'btn btn-danger me-2',
				},
			}).then((result) => {

				if (result.value) {
					ApiS_MakeCall({
						method: 'DELETE',
						params:{
							'referenceID': this.editReference.referenceID,
						},
						url: 'v3/GenesisReference',
					}).then(()=>{
						// Reference Edit View - so i don't care if it's the users paper or not
						this.$store.commit('REFERENCE_REMOVE_FROM_LIBRARY', this.editReference.referenceUniqueID.toUpperCase());

						PS_BuildReferencesInPaper().then(()=>{
							// dispatch to compress the referenceLibrary
							RS_Compress({
								isGenesis: true
							}).then(()=>{
								this.$router.push({
									name: 'ReferenceList'
								}).catch(()=>{});
							});
						});
					});//e:ApiS_MakeCall
				}//e:if:result.value
			});//e:Swal

		},//e:deleteReferenceConfirm


		loadReferenceUI(){
			// set values from store object to editable fields
			// this.nickname = (this.editReference.name === 'empty') ? '' : this.editReference.name;
			this.note = (this.editReference.note === 'empty') ? '' : this.editReference.note;
			this.tags = (this.editReference.tags === 'empty') ? '' : this.editReference.tags;
		},//e:loadReferenceUI

		updateReference(){
			this.$store.commit('loaders/ADD_ID', 'App');
			let _nickname = this.nickname || '';
			
			// build object to pass
			if(_nickname == ''){
				_nickname = 'empty';
			}
			
			let _note = this.note || 'empty';
			let _tags = 'empty';

			if (this.tags) {
				// mixin Tag Cleaner
				_tags = this.cleanTags(this.tags);
			}
			
			// with the Reference Rules returned back with data in the body of the request
			let params = {
				'referenceID': this.editReference.referenceID,	// Required(sorta). The id of the reference that was returned on create. 
				'nickname': _nickname,							// Optional. A nickname field like the old ones. In case we use it.
			}

			this.$store.dispatch('referenceRules/generateReferencePreview').then(()=>{
				let referenceRulesObject = {
					data: this.$store.state.referenceRules.rulesObjectData,
					values: this.$store.state.referenceRules.rulesObjectValues
				}

				referenceRulesObject.note = _note; // Optional. Note field like the old refs.
				referenceRulesObject.tags = _tags; // Optional. Just like the old system.

				ApiS_MakeCall({
					body: referenceRulesObject,
					method: 'PUT',
					params,
					responseType: 'json',
					url: 'v3/GenesisReference',
				}).then((responseData)=>{
					// console.log(responseData);

					let referenceData = responseData;
					referenceData.referenceUniqueID = this.editReference.referenceUniqueID.toUpperCase();

					// update the reference data in the reference library
					this.$store.commit('REFERENCE_UPDATE', {
						'referenceUniqueID': referenceData.referenceUniqueID,
						'newReferenceData': referenceData,
					});
				
					PS_BuildReferencesInPaper().then(()=>{
						
						// console.log('PS_BuildReferencesInPaper done back in EditRefBody');

						if(this.isDrawer){
							// recode child citations in the paper

							// check to see if this reference has been added to the paper (can happen after you Update an APA7 reference to add an MLA9 Value)
							let thisReferenceIndex = this.$store.state.paperEdit.referencesInPaper.findIndex((ref) => {
								return ref.referenceUniqueID.toUpperCase() == this.editReference.referenceUniqueID.toUpperCase()
							});
							// console.log('thisReferenceIndex');
							// console.log(thisReferenceIndex);
							
							// craft a message that will eventually make it's way to saveDocument
							let referenceDisplayValue = RS_GetValueOutput({
								displayFormatVersionId: this.$store.state.customer.preferredFormatVersionID,
								referenceData: referenceData,
							});

							let saveMessage = 'Edited reference - ' + referenceDisplayValue;
							
							if(thisReferenceIndex === -1){
								// reference is NOT in this paper yet
								PS_AddReferenceToPaper({
									isDrawer: true,
									reference: this.editReference
								}).then(()=>{
									PS_BuildReferencesInPaper().then(()=>{
										this.emitter.emit('generateReferencePage', {
											isSaveAfter: true,
											saveMessage: saveMessage,
										});

										this.emitter.emit('updateReferencePageValueById', this.editReference.referenceUniqueID.toUpperCase());

										if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
											this.$router.push({
												name:'EditorReferencesCitations'
											}).catch(()=>{});
										} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
											this.$router.push({
												name:'OutlineReferencesCitations'
											}).catch(()=>{});
										}
									});
								});
							} else {
							
								// reference is already in the paper, just move by
								PS_BuildReferencesInPaper().then(()=>{
									
									if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
										this.emitter.emit('generateReferencePage', {
											isSaveAfter: true,
											saveMessage: saveMessage,
										});

										this.emitter.emit('updateReferencePageValueById', this.editReference.referenceUniqueID.toUpperCase());

										this.$router.push({
											name:'EditorReferencesCitations'
										}).catch(()=>{});

									} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
										
										this.$router.push({
											name:'OutlineReferencesCitations'
										}).catch(()=>{});
										this.$store.commit('loaders/REMOVE_ID', 'App');
									}
									
								});
							}
							
						} else {
							if(this.$route.name === 'ReferenceTabDetails'){
								this.emitter.emit('globalToasterOpen',{
									textContent: 'Changes saved',
								});
								this.$store.commit('loaders/REMOVE_ID', 'App');
							} else {
								// Module Modal (Organize Tab)
								AS_CheckToken().then(()=>{
									AS_SyncData().then(()=>{
										this.emitter.emit('closeRouteModal');
									});
								});
							}
						}
					});
				});//e:ApiS_MakeCall

			});//e:generateReferencePreview
		},//e:updateReference
		
	},
	mounted() {
		if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				TDS_Open({
					drawerName: 'EditorReferencesCitations'
				}).then(()=>{
					RS_LoadByRoute().then(()=>{
						this.loadReferenceUI();
					});
				});
			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				TDS_Open({
					drawerName: 'OutlineReferencesCitations'
				}).then(()=>{
					RS_LoadByRoute().then(()=>{
						this.loadReferenceUI();
					});
				});

			} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
				RS_LoadByRoute().then(()=>{
					// this.loadReferenceUI();
				});
			}

		} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT || this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
			RS_LoadByRoute().then(()=>{
				this.loadReferenceUI();
			});
		}

	},
	components: {
		CopyJsonData,
		FormInput,
		FormTags,
		FormTextarea,
		ReferenceOutput,
		ReferenceTypes,
	},
	destroyed(){
		this.$store.dispatch('referenceRules/deepClear').then(()=>{
			// reset back to empty objects
			this.$store.commit('referenceEdit/SET_OPEN_OBJECT', {});
			
			if(this.$route.matched.length > 0 && this.$route.matched[0].name !== 'ReferenceEdit'){
				this.$store.commit('paperEdit/SET_EDIT_REFERENCE', {});
				this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', 0);
				this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', '');
			}
		});
	},
}
</script>
