/*
- Class Color Parse
-- only used Online to match class colors
-- 2024.04.26
*/

import _forEach from 'lodash/forEach';
import config from '@/config';

export default ($opts) => {
	let legacyColorMatch = '';

	// check if this an old color value
	_forEach(config.enums.ClassColors, (classColorObject, classColorName)=>{
		if(
			(classColorName === $opts.in) ||
			(classColorObject.map && classColorObject.map.includes($opts.in))
		){
			legacyColorMatch = classColorObject;
		}
	});
	
	if(legacyColorMatch === ''){
		// new color (an old one wasn't mapped)
		// console.log('No Legacy Color Matched');
		return {};
	} else {
		// return a css Style block
		if($opts.isOutBlock){
			return {
				backgroundColor: '#' + legacyColorMatch.block,
			}
		} else {
			if($vm.$store.state.customer.themeId === config.enums.Theme.DARK){
				return {
					color: '#' + legacyColorMatch.darkText,
				}
			} else {
				return {
					color: '#' + legacyColorMatch.text,
				}
			}
			
		}
	}
}