
<!--
- IdentifyUser
-- Cart > Step 1
-- https://magical-scratch.webflow.io/public-site/store/store-1-identify-user
-->

<template>
	<div class="p-5">
		<div class="alert alert-danger" v-if="!$isLive">
			<p class="fw-bold p-2">
				This is testing environment, to make a purchase, please visit: <a href="https://www.perrlacomplete.com/Public/#/cart-step-1">https://www.perrlacomplete.com/Public/#/cart-step-1</a>
			</p>
		</div>

		<h1 class="text-center mt-4 mb-0 text-large d-none d-md-block">
			Subscribe to PERRLA
		</h1>
		
		<div class="boxset-wrp">
			
			<transition name="route-fade" mode="out-in" appear>

				<div class="row" v-if="isSignIn" key="divSignIn">
					<div class="col-12 col-md-6 col-divider-right pt-2">
						<h2 class="text-center mb-3">
							Sign in
						</h2>

						<form>
							<div class="alert alert-danger" v-if="isLoginError">
								The email or password provided is incorrect
							</div>

							<form-input
								label="Email"
								v-model="email"
								:form-id="zFormIdLogin"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
							
							<form-password
								label="Password"
								v-model="password"
								:form-id="zFormIdLogin"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.REQUIRED" />

							<p class="link-forgot-password">
								<router-link
									:to="{
										name: 'ForgotPassword'
									}"
									class="hover-underline">
									Forgot password?
								</router-link>
							</p>

							<div class="d-grid gap-2 mt-4">
								<button type="submit" class="btn btn-success" @click.prevent="btnSignIn">
									Sign in
								</button>

								<button type="button" class="btn btn-outline-primary" @click.prevent="isSignIn = false">
									Create a new account
								</button>
							</div>
						</form>
					</div><!--col-->

					<div class="col-12 col-md-6 pt-2 org-size ps-3">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-none d-md-block" role="presentation" />
						<h1 class="d-none d-md-block text-center">
							Welcome back!
						</h1>
						<p>
							<strong>Having trouble accessing your account?</strong><br />
							You can <router-link
								:to="{
									name: 'ForgotPassword'
								}">
								reset your account password here
							</router-link>.
						</p>
						<p>
							If that doesn&#39;t work, reach out to our Support team. You can reach them via email at <a href="mailto:support@perrla.com?subject=I%27ve%20got%20a%20question">support@perrla.com</a>, or 
							<router-link
								:to="{
									name: 'ContactSupport'
								}">
								contact them here
							</router-link>.
						</p>

					</div><!--col-->
				</div><!--row-->

				<div class="row" v-else key="divRegister">
					<div class="col-12 col-md-6 col-divider-right pt-2">
						<form>
							<h2 class="text-center mb-3">
								Create your account
							</h2>

							<form-input
								label="First Name"
								:form-id="zFormIdSubcribe"
								v-model="firstName"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.REQUIRED" />

							<form-input
								label="Last Name"
								v-model="lastName"
								:form-id="zFormIdSubcribe"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.REQUIRED" />

							<form-input
								label="Email"
								v-model="email"
								:form-id="zFormIdSubcribe"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
							
							<form-password
								label="Password"
								v-model="password"
								:form-id="zFormIdSubcribe"
								:is-horizontal="true"
								:validate-enum="$enums.Validation.REQUIRED" />
							
							<div class="d-grid gap-2 mt-4 mb-3">
								<button type="submit" class="btn btn-success" @click.prevent="btnCreateAccount">
									Create my account
								</button>

								<button type="button" class="btn btn-outline-primary" @click.prevent="isSignIn = true">
									Sign in to an existing account
								</button>
							</div>

							<p class="org-size">
								Want to try PERRLA before you buy?
								<a href="#" @click.prevent="btnGoToFreeTrial">Click here to start a 7-day Free Trial.</a>
							</p>

						</form>
					</div>

					<div class="col pt-2 org-size ps-3">
						<p>
							<strong>What&#39;s included in a PERRLA subscription?</strong><br />
							Every PERRLA Subscription includes access to:<br />
							- PERRLA&#39;s Microsoft Word Add-in<br />
							- Web-based PERRLA Online Application<br />
							- Google Chrome Extension<br />
							- Access to our amazing Customer Support Team<br />
							- Immediate access to all new features &amp; improvements
						</p>

						<p>
							<strong>I&#39;ve used PERRLA before. Should I create a new account?</strong><br />
							Please don&#39;t. Your existing PERRLA account has all of your papers, references, and work saved in it. We recommend you 

							<router-link
								:to="{
									name: 'Login'
								}">
								log into your existing account
							</router-link>
							and renew your subscription from there. Plus, it could save you some money!
						</p>

						<p>
							<strong>What if I have trouble getting started with PERRLA?</strong><br />
							We have an amazing US-based Support team standing by. They work every day until 10pm Central ready to help. You can reach them via email at <a href="mailto:support@perrla.com?subject=I%27ve%20got%20a%20question">support@perrla.com</a>, or 

							<router-link
								:to="{
									name: 'ContactSupport'
								}">
								contact them here
							</router-link>.
					
						</p>

					</div>
				</div>
			</transition>
		</div>

		<div class="divider" />

		<section class="section-max-width mx-auto">
			<main>
				<h2 class="text-center">
					Pricing
				</h2>

				<section>
					<!-- Medium View and Above -->
					<div class="container d-none d-md-block">
						<div class="row">
							<div class="col-4 align-self-center text-center">
								<main class="pricing-padding-top">
									<h3>
										7-day Free Trial
									</h3>
									<p class="price py-3">
										$0<span class="usd">USD</span>
									</p>
									<div class="d-grid">
										<button @click.prevent="btnGoToFreeTrial" class="btn btn-outline-primary btn-lg btn-shadow d-block text-uppercase" type="button">
											Start a Free Trial
										</button>
									</div>
								</main>
							</div>
							<div class="col-4 align-self-center text-center">
								<main>
									<div class="best-value-text">
										BEST&nbsp;VALUE
									</div>

									<h3 style="font-weight: 800;">
										Annual Subscription
									</h3>
									<p class="price text-success py-3">
										$79.95<span class="usd">USD</span>
									</p>
								</main>
							</div>
							<div class="col-4 align-self-center text-center">
								<main class="pricing-padding-top">
									<h3>
										6-month Subscription
									</h3>
									<p class="price py-3">
										$49.95<span class="usd">USD</span>
									</p>
									
								</main>
							</div>
						</div>
					</div><!-- container -->
					
					<!-- Small View (only) -->
					<div class="d-none d-sm-block d-md-none">
						<table class="table table-borderless table-price-grid">
							<tbody>
								<tr>
									<td class="text-center">
										7-day  Free Trial
									</td>
									<td>
										<p class="price py-3 text-center">
											$0<span class="usd">USD</span>
										</p>
									</td>
								</tr>
								<tr>
									<td class="text-center fw-bolder">
										Annual Subscription
									</td>
									<td>
										<p class="price text-success py-3 text-center">
											$79.95<span class="usd">USD</span>
										</p>
									</td>
								</tr>
								<tr>
									<td class="text-center">
										6-month Subscription
									</td>
									<td>
										<p class="price py-3 text-center">
											$49.95<span class="usd">USD</span>
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div><!--d-none d-sm-block d-md-none-->
					
					<!-- Below Small view (Extra Small?)-->
					<div class="d-block d-sm-none mt-4">
						<div class="row">
							<div class="col-12 align-self-center text-center">
								<main>
									<h2 style="font-size:32px; font-weight: 400;">
										7-day Free Trial
									</h2>
									<p class="price">
										$0<span class="usd">USD</span>
									</p>
								</main>
							</div>
							<div class="col-12 align-self-center text-center">
								<main style="position:relative;">
									<h1 style="font-size:32px; font-weight: 700;">
										Annual Subscription
									</h1>
									<div class="best-value-text d-none d-lg-block">
										BEST&nbsp;VALUE
									</div>
									<p class="price text-success">
										$79.95<span class="usd">USD</span>
									</p>
								</main>
							</div>
							<div class="ol-12 align-self-center text-center">
								<main>
									<h2 style="font-size:32px; font-weight: 400;">
										6-month Subscription
									</h2>
									<p class="price">
										$49.95<span class="usd">USD</span>
									</p>
								</main>
							</div>
						</div> <!--grid d-none d-md-grid-->
					</div><!--d-block d-sm-none-->
					
				</section>
			
			</main>
		</section>

		<div class="divider" />

		<section class="section-max-width mx-auto">
			<main class="org-size">
				<h2 class="text-center">
					PERRLA handles all the formatting.
				</h2>

				<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/handle-formatting.png" class="img-fluid mb-2" role="presentation" />

				<p>PERRLA formats your entire document for you, automatically &ndash; including <strong>page margins</strong>, <strong>text styles</strong>, <strong>paragraph spacing</strong>, <strong>headers</strong>, and <strong>section breaks</strong>.</p>

				<p>Create a paper for all your assignments: <strong>Research Papers</strong>, <strong>Discussion Posts</strong>, <strong>Reference Lists</strong>, and <strong>Annotated Bibliographies </strong>for the APA 7 and MLA 9 formats.</p>

			</main>
		</section>

		<div class="divider" />
	
		<section class="section-max-width mx-auto">
			<div class="container---grid">
				<h2 class="text-center">
					Hear what <em>real</em> students have to say about PERRLA
				</h2>

				<div class="video square w-video w-embed">
					<div class="ratio ratio-1x1">
					<iframe
						allow="autoplay; encrypted-media"
						allowfullscreen=""
						src="https://www.youtube-nocookie.com/embed/taKK3OGfXSw?rel=0&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;start=0"
						style="width:100%; height:100%; pointer-events:auto"
						title="What real students say about PERRLA" />
					</div>
				</div>

				<div class="text-content">
					<p>
						Every year students face the anxiety and stress of writing papers. Helping students get through the challenges of writing papers is our mission.
					</p>
					<p>
						We get a lot of satisfaction helping students overcome those fears and become successful writers so they can get through school to start the next chapter in their lives.
					</p>
					<a href="http://perrla.com/#/testimonials">
						See more testimonials.
					</a>
				</div>

			</div>
		</section>

		<div class="divider" />

		<section class="section-max-width mx-auto">
			<main class="org-size">
				<h2 class="text-center mb-3">
					Some of our most popular features:
				</h2>
				<ul>
					<li><strong>Automatically retrieve reference data for journal articles, books, and websites</strong></li>
					<li><strong>Google Chrome Web</strong> Extension lets you create references from any website</li>
					<li><strong>Reference Wizard</strong> guides you step-by-step to create all other reference types</li>
					<li><strong>Create perfect citations for every type of reference</strong></li>
					<li><strong>Automatically generates &amp; alphabetizes your references section</strong></li>
					<li><strong>Save your references for use in future papers in the Reference Library</strong></li>
					<li><strong>Create research papers, discussion posts, reference lists, and annotated bibliographies</strong></li>
					<li><strong>Insert tables, figures, abstracts, tables of content, section headings, &amp; appendices</strong></li>
					<li><strong>US-based Customer Support available 7 days a week until 10pm Central</strong></li>
				</ul>
			</main>
		</section>

		<div class="divider" />

		<section class="section-max-width mx-auto">
			<h3 class="text-center">
				Every PERRLA subscription includes PERRLA for Word &amp; PERRLA Online!
			</h3>
			<div class="row">
				<div class="col-12 col-md-6">
					<main>
						<div class="text-center">
							<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png" class="icon " role="presentation" />
							<h4>PERRLA&nbsp;for&nbsp;Word</h4>
						</div>
						<p class="text-start mb-0">
							PERRLA&#39;s Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.
						</p>
					</main>
				</div>
				<div class="col-12 col-md-6">
					<main>
						<div class="text-center">
							<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png" class="icon" role="presentation" />
							<h4>PERRLA&nbsp;Online</h4>
						</div>
						<p class="text-start mb-0">
							PERRLA Online is our web-based application that allows you to write APA &amp; MLA papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper&#39;s research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.
						</p>
					</main>
				</div>
			</div>
		</section>

		<div class="divider" />

		<section>
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg" class="icon" role="presentation" />
								<h4>Latest APA &amp;&nbsp;MLA&nbsp;formats</h4>
							</div>
							<p class="text-start org-size">
								PERRLA is 100% compatible with APA 7, APA 6, MLA 9, and MLA 8 formatting guidelines. We monitor all APA &amp; MLA updates to make sure PERRLA is always up-to-date!
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg" class="icon" role="presentation" />
								<h4>Google Chrome Extension</h4>
							</div>
							<p class="text-start org-size">
								Our Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg" class="icon" role="presentation" />
								<h4>Formats your entire paper</h4>
							</div>
							<p class="text-start org-size">
								PERRLA formats your references, page margins, headings &amp; text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don&rsquo;t have to.
							</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg" class="icon" role="presentation" />
								<h4>Auto-fill Reference Wizard</h4>
							</div>
							<p class="text-start org-size">
								Use our Auto-fill tool to search for books, journal articles, &amp; websites without any typing. Manually create every other reference type with our guided Reference Wizard.
							</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg" class="icon" role="presentation" />
								<h4>Reference Syncing</h4>
							</div>
							<p class="text-start org-size">
								PERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.
							</p>
							<p>&nbsp;</p>
						</main>
					</div>

					<div class="col-xs-12 col-sm-6 col-md-4">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg" class="icon" role="presentation" />
								<h4>PERRLA is always up-to-date</h4>
							</div>
							<p class="text-start org-size">
								Updating software is a pain. PERRLA updates itself automatically without you having to do a thing.
							</p>
						</main>
					</div>
				</div><!--row-->
				<div class="row">
					<div class="col-xs-12 col-sm-6 col-md-4 mx-md-auto g-start-md-5">
						<main>
							<div class="text-center">
								<img src=" https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg" class="icon" role="presentation" />
								<h4>US-based Support</h4>
							</div>
							<p class="text-start org-size">
								Our Customer Support team is serious about helping students. They&rsquo;re available <strong>7 days a week until 10pm Central</strong>.
							</p>
						</main>
					</div>
				</div><!--row-->

			</div>
		</section>

	</div>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormPassword from '@/components/form/Password';

export default {
	name: 'IdentifyUser',
	data() {
		return {
			email: (config.isDev) ? 'riley@perrla.com' : '',
			firstName: '',
			isLoginError: false,
			isSignIn: false,
			lastName: '',
			password: (config.isDev) ? 'perrla' : '',
			zFormIdLogin: 'formLogin',
			zFormIdSubcribe: 'formSubscribeToPerrla'
		}
	},
	methods: {
		btnCreateAccount(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let sendObject = {
				email: this.email,
				firstName: this.firstName,
				lastName: this.lastName,
				password: this.password,
			};

			AS_ValidateForm({
				formId: this.zFormIdSubcribe
			}).then(()=>{

				ApiS_MakeCall({
					body: sendObject,
					ignoreAuth: true,
					method: 'POST',
					responseType: 'json',
					url: 'v4/Purchase/User',
				}).then((shoppingCartUserID)=>{

					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormIdSubcribe
					});

					this.$store.dispatch('cart/getNewCart', {
						'shoppingCartUserID': shoppingCartUserID
					}).then(()=>{
						this.$router.push({
							name: 'SubscriptionSettings',
						}).catch(()=>{});
					});

				}).catch((failReason)=>{
					if(failReason && _has(failReason, 'code') && failReason.code === 'ALREADY_CUSTOMER_VALID'){
						// user is trying to create an account - they are valid - log them in 
						
						// 1 - store the token in vuex
						this.$store.commit('api/SET_TOKEN', failReason.message);

						// 2 - local development can't acccess the MVC project
						if(config.isDev){
							// 2a - set items manually and go to the dashboard
							this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
							this.$router.push({
								name:"Dashboard"
							}).catch(()=>{});
						} else {
							// 2b - pass this token back to an MVC project 
							ApiS_MakeCall({
								// ignoreAuth: true,
								method: 'GET',
								outsideSpa: true,
								responseType: 'json',
								url: 'Forms/Login',
							}).then((responseData2)=>{
								// console.log('responseData2');
								// console.log(responseData2);
								this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);

								// to account settings
								window.location = config.appUrl + '/App/#/settings/my-profile';

							}).catch((error2) =>{
								this.$store.commit('loaders/REMOVE_ID', 'App');

								console.log('error2');
								console.log(error2);
							});//e:ApiS_MakeCall
						}//e::if:else:config.isDev

					} else if(failReason && _has(failReason, 'code') && failReason.code === 'ALREADY_CUSTOMER_INVALID'){
						// user is trying to create an account - but they have a customer account - the password just isn't right probably
						this.isLoginError = true;
						this.isSignIn = true;

						this.$store.commit('loaders/REMOVE_ID', 'App');

					} else if(failReason && _has(failReason, 'code') && failReason.code === 'PENDING_INVITATION'){
						// org invite - unaccepted
						// https://magical-scratch.webflow.io/public-site/store/store-org-invite-sent

						// set the customer email filed here - it's not real but easier way to pass it i think
						this.$store.commit('customer/SET_EMAIL', this.email);

						this.$router.push({
							name: "StoreOrganizationInvite"
						}).catch(()=>{});

					} else {
						this.$store.commit('loaders/REMOVE_ID', 'App');
					}
				});
			
			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm

		},//e:btnCreateAccount
		
		btnGoToFreeTrial(){
			window.scrollTo(0,0);
			this.$router.push({
				name: 'FreeTrialStart'
			}).catch(()=>{});
		},

		btnSignIn(){
			this.isLoginError = false;

			AS_ValidateForm({
				formId: this.zFormIdLogin
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');
				
				// 1 - get an access token - this is a real login now
				ApiS_MakeCall({
					body: 'client_id=complete&grant_type=password&username=' + encodeURIComponent(this.email) + '&password=' + encodeURIComponent(this.password),
					contentType: 'application/x-www-form-urlencoded',
					ignoreAuth: true,
					isHardUrl: true,
					method: 'POST',
					responseType: 'json',
					url: (config.isLive) ? 'https://auth.perrla.com/connect/token' : 'https://testauth.perrla.com/connect/token',
				}).then((responseData)=>{
					console.log('auth responseData');
					console.log(responseData);

					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormIdLogin
					});

					// 2 - store the token in vuex
					this.$store.commit('api/SET_TOKEN', responseData.access_token);

					// 3 - local development can't acccess the MVC project
					if(config.isDev){
						// 3a - set items manually and go to the dashboard
						this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
						
						// alert('localhost: i need to get to MY PERRLA HOME')
						this.$router.push({
							name:"MyProfile"
						}).catch(()=>{});
					} else {
						// 3b - pass this token back to an MVC project 
						ApiS_MakeCall({
							// ignoreAuth: true,
							method: 'GET',
							outsideSpa: true,
							responseType: 'json',
							url: 'Forms/Login',
						}).then((responseData2)=>{
							// console.log('responseData2');
							// console.log(responseData2);
							this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);

							// to account settings
							window.location = config.appUrl + '/App/#/settings/my-profile';
						}).catch((error2) =>{
							this.$store.commit('loaders/REMOVE_ID', 'App');

							console.log('error2');
							console.log(error2);
						});//e:ApiS_MakeCall
					}
					
				}).catch((error)=>{
					console.log('error');
					console.log(error);

					this.$store.commit('loaders/REMOVE_ID', 'App');
					this.isLoginError = true;
				});//e:ApiS_MakeCall


			}).catch(()=>{
				return false;
				
			});//e:AS_ValidateForm
		},//e:btnSignIn
	},
	mounted() {
		// if the user is already logged in just take them to the dashboard
		if(this.$store.state.api.token === ''){
			// blank token - user isn't logged in yet
			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
			
			// force remove any theme classes - this view will not have a customer theme
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');
			document.body.classList.remove('bg-simple');

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormIdLogin
			});

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormIdSubcribe
			});

			this.$store.commit('loaders/REMOVE_ID', 'App');

		} else {
			this.$router.push({
				name: 'Dashboard'
			}).catch(()=>{});
		}
	},
	components: {
		FormInput,
		FormPassword
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars_perrla";

.container---grid{

	h2 {
		grid-column-start: span 3;
		grid-column-end: span 3;
		grid-row-start: span 1;
		grid-row-end: span 1;
	}

	.video.square {
		grid-column-start: span 3;
		grid-column-end: span 3;
		grid-row-start: span 2;
		grid-row-end: span 2;
		
		height: 480px;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		width: 480px;

		@include media-breakpoint-down(lg) { 
			height: 320px;
			width: 320px;
		}
	}

	div.text-content {
		grid-column-start: span 3;
		grid-column-end: span 3;
		grid-row-start: span 1;
		grid-row-end: span 1;
	}
}

</style>