var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input-cart" }, [
    _vm.showLabel
      ? _c("label", {
          class: ["form-label", { "fst-italic": _vm.isInvalidShown }],
          domProps: { innerHTML: _vm._s(_vm.calcLabelOutput) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      ref: "inputElement",
      class: ["form-control", { "is-invalid": _vm.isInvalidShown }],
      attrs: {
        autocomplete: "off",
        disabled: _vm.isDisabled,
        id: _vm.fullId,
        maxlength: _vm.maxLength,
        placeholder: _vm.placeholder,
        role: _vm.role,
        type: _vm.localType,
      },
      domProps: { value: _vm.value },
      on: {
        blur: function ($event) {
          return _vm.blurInput($event.target.value)
        },
        input: function ($event) {
          return _vm.typeInput($event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.isInvalidShown
      ? _c("div", { staticClass: "invalid-feedback d-block mt-1" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }