<!--
- Paper Review
-- https://trello.com/c/QXytbypq/286-paper-review-error-checker-20
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Paper Review" />
		</div><!-- drawer-header -->

		<div class="drawer-body">
			<h3>Check your paper for common errors.</h3>
			<p>These are some of the most common errors we've seen students make over the years.</p>

			<form-checkbox
				label="Review References for missing information and/or typos"
				check-type="success"
				v-model="checkTypos" />
			
			<p class="ms-4 mt-2 text-muted">
				Use PERRLA to edit references containing typos or missing data.
			</p>	

			<div v-if="documentType === $enums.DocumentType.DISCUSSION_POST || documentType === $enums.DocumentType.RESEARCH_PAPER">
				<form-checkbox
					label="Make sure all References have a Citation"
					check-type="success"
					:class="{'form-check-danger': isOneCitationError}"
					v-model="checkOneCitation" />
				<p :class="['ms-4 mt-2 text-muted', {'text-danger': isOneCitationError}]">
					If you&#39;re not citing a Reference in the body of your paper, remove it from the References list.
				</p>
			</div>

			<form-checkbox
				label="Single vs. double space after a period."
				check-type="success"
				v-model="checkPeriod" />
			<p class="ms-4 mt-2 text-muted">
				Unless your teacher says otherwise, include only one space between sentences.
			</p>

			<div v-if="documentType === $enums.DocumentType.DISCUSSION_POST || documentType === $enums.DocumentType.RESEARCH_PAPER">
				<form-checkbox
					label="Check table and figure numbering."
					check-type="success"
					v-model="checkTablesFigures" />
				<p class="ms-4 mt-2 text-muted">
					Make sure the callouts in your text are referencing the correct table and figure numbers.
				</p>
			</div>

			<div v-if="documentType === $enums.DocumentType.DISCUSSION_POST">
				<form-checkbox
					label="Copy your paper's content to the clipboard so it's easy to paste into an online forum."
					check-type="success"
					v-model="checkCopyPaperToClipboard" />

				<button type="button" class="btn btn-outline-primary ms-4 mt-1 mb-3" @click.prevent="copyDiscussionPostToClipboard">
					Copy paper to clipboard
				</button>
			</div>

			<form-checkbox
				label="Download a clean version of your paper to turn into your professor."
				check-type="success"
				v-model="checkDownloadCleanCopy" />
			
			<button type="button" class="btn btn-outline-primary ms-4 mt-1" @click.prevent="btnCleanCopy">
				Download Simplified Word file
			</button>

		</div><!-- drawer-body -->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import config from '@/config';
import FormCheckbox from '@/components/form/Checkbox';
import PS_CopyDiscussionPostToClipboard from '@/services/paper/copyDiscussionPostToClipboard';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import PS_SaveDocument from '@/services/paper/saveDocument';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'PaperReview',
	computed:{
		checkCopyPaperToClipboard:{
			get(){
				return this.$store.state.paperEdit.review.CopyPaperToClipboard;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'CopyPaperToClipboard',
					value: newValue
				});
			},
		},
		checkDownloadCleanCopy:{
			get(){
				return this.$store.state.paperEdit.review.DownloadCleanCopy;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'DownloadCleanCopy',
					value: newValue
				});
			},
		},
		checkOneCitation:{
			get(){
				return this.$store.state.paperEdit.review.OneCitation;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'OneCitation',
					value: newValue
				});
			},
		},
		checkPeriod:{
			get(){
				return this.$store.state.paperEdit.review.Period;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'Period',
					value: newValue
				});
			},
		},
		checkTablesFigures:{
			get(){
				return this.$store.state.paperEdit.review.TablesFigures;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'TablesFigures',
					value: newValue
				});
			},
		},
		checkTypos:{
			get(){
				return this.$store.state.paperEdit.review.Typos;
			},
			set(newValue){
				this.toggleCheckedItem({
					key: 'Typos',
					value: newValue	
				});
			},
		},
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
		isOneCitationError(){
			let returnError = false;
			_forEach(this.$store.state.paperEdit.referencesInPaper, (referenceData)=>{
				if(referenceData.citations.length === 0){
					returnError = true;
					return false;	// just need to find one
				}
			});
			return returnError;
		},
	},
	methods: {
		btnCleanCopy(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.WORD_SIMPLE,
				platform: 1, // just Online Papers here
			}).then(()=>{
				this.toggleCheckedItem({
					key: 'DownloadCleanCopy',
					value: true
				});
			});
		},

		copyDiscussionPostToClipboard(){
			PS_CopyDiscussionPostToClipboard().then(()=>{
				this.toggleCheckedItem({
					key: 'CopyPaperToClipboard',
					value: true
				});
			});
		},


		toggleCheckedItem($opts){
			// update vuex store
			this.$store.commit('paperEdit/review/SET_STATE', {
				key: $opts.key,
				value: $opts.value,
			});

			// stealth save the paper
			PS_SaveDocument({
				isMetaDataChanges: true,
				isStealthSave: true,
				message: 'Paper Review "' + $opts.key + '" to "' +  $opts.value + '"'
			});
		},//e:toggleCheckedItem


	},
	mounted() {
		TDS_Open({
			drawerName: 'EditorPaperReview'
		});
	},
	components: {
		FormCheckbox,
		ToolDrawerTitle,
	}
}
</script>
