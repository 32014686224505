import config from '@/config';

import PaperTabResearch from '@/views/Paper/TabResearch';
import PaperTabResearchConvertReferenceStep1 from '@/views/Paper/TabResearch/modals/ConvertStep1';
import PaperTabResearchConvertReferenceStep2 from '@/views/Paper/TabResearch/modals/ConvertStep2';
import PaperTabResearchCreateReferenceStep1 from '@/views/Paper/TabResearch/modals/CreateReferenceStep1';
import PaperTabResearchCreateReferenceStep2 from '@/views/Paper/TabResearch/modals/CreateReferenceStep2';
import PaperTabResearchCreateReferenceStep2ThirdParty from '@/views/Paper/TabResearch/modals/CreateReferenceStep2ThirdParty';
import PaperTabResearchCreateReferenceStep3 from '@/views/Paper/TabResearch/modals/CreateReferenceStep3';
import PaperTabResearchCreateReferenceStep3ThirdParty from '@/views/Paper/TabResearch/modals/CreateReferenceStep3ThirdParty';
import PaperTabResearchEditReferenceBody from '@/views/Paper/TabResearch/modals/EditReference';
import PaperTabResearchCreateReferenceStep4ThirdParty from '@/views/Paper/TabResearch/modals/CreateReferenceStep4ThirdParty';
import PaperTabResearchReviewReference from '@/views/Paper/TabResearch/modals/ReviewReference';

const moduleEnum = config.enums.Module.PAPER_EDIT;
const tabEnum = config.enums.Tab.RESEARCH;

export default [
	{
		name: 'PaperTabResearch',
		component: PaperTabResearch,
		path: 'research-notes',
		meta: {
			layout: config.enums.Layout.PO,
			module: moduleEnum,
			tab: tabEnum
		},
		children: [
			{
				name: 'PaperTabResearchConvertReferenceStep1',
				component: PaperTabResearchConvertReferenceStep1,
				path: 'convert-reference-1',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchConvertReferenceStep2',
				component: PaperTabResearchConvertReferenceStep2,
				path: 'convert-reference-2',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep1',
				component: PaperTabResearchCreateReferenceStep1,
				path: 'create-reference-1/:openReferenceTab',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep2',
				component: PaperTabResearchCreateReferenceStep2,
				path: 'create-reference-2/:openReferenceTab/:referenceTypeSlug',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep2ThirdParty',
				component: PaperTabResearchCreateReferenceStep2ThirdParty,
				path: 'create-reference-2-3plu',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep3',
				component: PaperTabResearchCreateReferenceStep3,
				path: 'create-reference-3',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep3ThirdParty',
				component: PaperTabResearchCreateReferenceStep3ThirdParty,
				path: 'create-reference-3-3plu/:referenceTypeSlug',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchEditReferenceBody',
				component: PaperTabResearchEditReferenceBody,
				path: 'reference-edit/:referenceUid',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchCreateReferenceStep4ThirdParty',
				component: PaperTabResearchCreateReferenceStep4ThirdParty,
				path: 'create-reference-4-3plu',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'PaperTabResearchReviewReference',
				component: PaperTabResearchReviewReference,
				path: 'reference-review/:referenceUid',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
		]
	},
]