var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.calcStyleClasses }, [
    _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
    _vm._v(" " + _vm._s(_vm.textContent) + "\n\t"),
    _c("a", {
      staticClass: "svg bi-x-lg float-end pe-1",
      attrs: { href: "#", title: "Close", tabindex: "-1" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.closeToast.apply(null, arguments)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }