<!--
- Paper Edit > Organize Tab > Research Notes > Convert Reference Step 2
-->

<template>
	<div>
		<h2 class="nodal-title">
			Update Reference
		</h2>
		
		<convert-reference-step2-body :is-drawer="false" />

	</div>
</template>

<script>
import ConvertReferenceStep2Body from '@/modules/ConvertReferenceStep2Body';

export default {
	name: 'ModuleModalConvertReferenceStep2',
	components: {
		ConvertReferenceStep2Body,
	}
}
</script>
