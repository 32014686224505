/*
- Reference Duplicate
-- ex: this.$store.state.referenceDuplicate.x
*/

import _has from 'lodash/has';
import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import router from '@/router';

export default {
	namespaced: true,

	state: {
        addFromMode: 0,
		isShowWarning: false,
        referenceData: {},      // reference data when duplicating a reference
        referenceObject: {},    // reference object when duplicating a reference
	},
	
	mutations: {
        HIDE_WARNING(state){
            state.isShowWarning = false;
        },
        SET_ADD_FROM_MODE(state, value){
            state.addFromMode = value;
        },
        SET_REFERENCE_DATA(state, value){
            state.referenceData = value;
        },
        SET_REFERENCE_OBJECT(state, value){
            state.referenceObject = value;
        },
        
        SHOW_WARNING(state){
			state.isShowWarning = true;
		},
	},

    actions:{
        processDuplicate({commit}, $opts){
            return new Promise((resolve)=>{
                commit('SHOW_WARNING');
                commit('SET_REFERENCE_DATA', $opts.refData);
                commit('SET_REFERENCE_OBJECT', $opts.referenceObject);
                commit('referenceRules/SET_REFERENCE_TYPE_ID', $opts.referenceObject.referenceID, {root: true});
                commit('referenceRules/SET_RULES_OBJECT_DATA', $opts.refData, {root: true});

                if(router.currentRoute.meta.module !== config.enums.Module.REFERENCE_LIST){
                    let refValues = JSON.parse($opts.referenceObject.values);

                    let _referenceFormatTypeId = HelperGetReferenceFormatId();

                    switch(_referenceFormatTypeId){
                        case config.enums.Format.APA7:
                            if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
                                if(refValues.apa7.value === ''){
                                    //commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.FORMAT_DISABLED);
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.REVIEW);

                                } else {
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.NORMAL);
                                }
                            }
                            break;
                        case config.enums.Format.MLA9:
                            if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value')){
                                if(refValues.mla9.value === ''){
                                    //commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.FORMAT_DISABLED);
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.REVIEW);
                                } else {
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.NORMAL);
                                }
                            } else {
                                // no MLA Value - use the APA one for now
                                if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
                                    if(refValues.apa7.value === ''){
                                        //commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.FORMAT_DISABLED);
                                        commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.REVIEW);
                                    } else {
                                        commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.REVIEW);
                                    }
                                }
                            }
                            break;
                        case config.enums.Format.Turabian9:
                            if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value')){
                                if(refValues.turabian9.value === ''){
                                    //commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.FORMAT_DISABLED);
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.REVIEW);
                                } else {
                                    commit('SET_ADD_FROM_MODE', config.enums.AddFromLibraryMode.NORMAL);
                                }
                
                            } else {
                                // no Turabian 9 Value - use APA
                                if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
                                    commit('SET_ADD_FROM_MODE', HelperCheckReferenceData($opts.referenceObject));
                                }
                
                            }
                            break;
                    }
                }//e:if

                return resolve();
            });//e:Promise
        }
    },
}

