var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawer" },
    [
      _c("close-warning", {
        attrs: {
          "close-id": "CloseEditorCreateStep3ThirdParty",
          "confirm-title": "Stop creating this reference?",
          "confirm-body":
            "If you stop now, the information you've entered on this page will be lost.  To finish making the reference, click the green button at the bottom.",
          "confirm-go-next-text": "Don't create reference",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-header" },
        [
          _c("tool-drawer-title", {
            attrs: { title: _vm.referenceTypeName, "is-browser-back": true },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-body" },
        [
          _c("create-reference-step-3-third-party-body", {
            attrs: { "is-drawer": true },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }