/*
- Config
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.config.x
*/
import config from '@/config';

export default {
    namespaced: true,

    state: {
		// non local storage states
        allowFigureNotes: false,
		allowTableNotes: false,
        ckEditorClass: '',
		renderCitationsAsFootnotes: false,		// Bool: tracks based on PaperFormatID (Turbaian 9 papers will have footnotes, other types will later)
	},//e:state

	mutations: {
        SET_ALLOW_FIGURE_NOTES(state, value){
			state.allowFigureNotes = value;
		},
		SET_ALLOW_TABLE_NOTES(state, value){
			state.allowTableNotes = value;
		},
        SET_CK_EDITOR_CLASS(state, value){
			state.ckEditorClass = value;
		},
        
		SET_RENDER_CITATION_AS_FOOTNOTES(state, value) {
			state.renderCitationsAsFootnotes = value;
		},
	},//e:mutations

	actions:{
		// build the cart object from the vuex store state (used when i need to send the entire cart object to an api call)
		setPaperProperties({commit, state, rootState}) {
			return new Promise((resolve) => {

				switch(rootState.paperEdit.meta.PaperFormatVersionID){
					case config.enums.Format.APA7:
                        commit('SET_ALLOW_FIGURE_NOTES', true);
						commit('SET_ALLOW_TABLE_NOTES', true);
                        commit('SET_CK_EDITOR_CLASS', 'style-apa7');
						commit('SET_RENDER_CITATION_AS_FOOTNOTES', false);
						break;
					case config.enums.Format.MLA9:
                        commit('SET_ALLOW_FIGURE_NOTES', false);
						commit('SET_ALLOW_TABLE_NOTES', false);
                        commit('SET_CK_EDITOR_CLASS', 'style-mla9');
						commit('SET_RENDER_CITATION_AS_FOOTNOTES', false);
						break;
					case config.enums.Format.Turabian9:
                        commit('SET_ALLOW_FIGURE_NOTES', false);
						commit('SET_ALLOW_TABLE_NOTES', true);
                        commit('SET_CK_EDITOR_CLASS', 'style-turabian9');
						commit('SET_RENDER_CITATION_AS_FOOTNOTES', true);
						break;
				}

				return resolve();
			});//e:Promise
		},//e:setPaperProperties
	},
}
