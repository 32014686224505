var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.doSearch.apply(null, arguments)
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("transition", { attrs: { name: "route-fade", mode: "out-in" } }, [
          _vm.isSearching
            ? _c("div", { key: "divIsSearching" }, [
                _c("div", { staticClass: "alert alert-info clearfix" }, [
                  _c(
                    "span",
                    [
                      _vm._v("\n\t\t\t\t\t\tLooking for your reference "),
                      _c("animated-ellipsis"),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("div", { key: "divNotSearching" }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "input-group",
                          { "input-group-error": _vm.searchErrorText != "" },
                        ],
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          ref: "focusSearchTerm",
                          class: [
                            "form-control",
                            { "border-end-0": _vm.searchTerm != "" },
                          ],
                          attrs: {
                            "aria-label": "Look up a reference",
                            autocomplete: "off",
                            id: "ThirdPartyLookup",
                            type: "text",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.searchTerm = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.searchTerm != "",
                                expression: "searchTerm != ''",
                              },
                            ],
                            staticClass: "btn search-clear px-2",
                            attrs: { type: "button", title: "Clear Search" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.clearSearch.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchTerm != "",
                          expression: "searchTerm != ''",
                        },
                      ],
                      staticClass: "flex-shrink-1",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link hover-underline",
                          attrs: { type: "submit", title: "Search" },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\tSearch\n\t\t\t\t\t\t")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.searchErrorText != ""
                  ? _c("div", { staticClass: "help-text mt-2 text-danger" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.searchErrorText) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _c("div", { staticClass: "help-text mt-2" }, [
                      _vm._v(
                        "\n\t\t\t\t\tSearch for journal articles and books using DOI, ISBN, or author & title. Find webpages using a URL.\n\t\t\t\t"
                      ),
                    ]),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "clearfix" }, [
      _c("h3", { staticClass: "float-start" }, [
        _vm._v("\n\t\t\t\tLook up a reference\n\t\t\t"),
      ]),
      _vm._v(" "),
      _c("span", {
        staticClass: "svg bi-info-circle ms-1 has-tooltip",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "bottom",
          title:
            "Create book, journal, and website references by searching for them.  You can search by DOI, ISBN, URL, or author and title.",
          role: "tooltip",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }