var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c("section", { staticClass: "section-text pt-0" }, [
      _c("main", { staticClass: "container-text mt-0 pt-3" }, [
        _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
          _c("div", { staticClass: "inset-body" }, [
            _c("h2", { staticClass: "mb-0" }, [
              _vm._v("\n\t\t\t\t\t\tPassword Reset\n\t\t\t\t\t"),
            ]),
            _vm._v(" "),
            _vm.isKeyInvalid
              ? _c("div", { key: "divInvaldKey" }, [
                  _c("p", [_vm._v("The password reset key is invalid.")]),
                  _vm._v(" "),
                  _vm._m(0),
                ])
              : _vm.isKeyExpired
              ? _c("div", { key: "divExpiredKey" }, [
                  _c("p", [_vm._v("The password reset key is expired.")]),
                  _vm._v(" "),
                  _vm._m(1),
                ])
              : _c("div", { key: "divElse" }, [
                  _vm.isPasswordError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tPlease enter matching passwords.\n\t\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    [
                      _c("form-password", {
                        attrs: {
                          label: "New Password",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.passwordNew,
                          callback: function ($$v) {
                            _vm.passwordNew = $$v
                          },
                          expression: "passwordNew",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-password", {
                        attrs: {
                          label: "Confirm Password",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.passwordConfirm,
                          callback: function ($$v) {
                            _vm.passwordConfirm = $$v
                          },
                          expression: "passwordConfirm",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "d-grid gap-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.btnUpdateAccount.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\tUpdate account\n\t\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Please contact support at "),
      _c("a", { attrs: { href: "mailto:support@perrla.com" } }, [
        _vm._v("support@perrla.com"),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Please contact support at "),
      _c("a", { attrs: { href: "mailto:support@perrla.com" } }, [
        _vm._v("support@perrla.com"),
      ]),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }