var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "route-fade" } }, [
    _vm.showCloseWarning
      ? _c(
          "div",
          { staticClass: "close-warning" },
          [
            _c("tool-drawer-title", {
              attrs: { title: _vm.confirmTitle, "is-title-only": true },
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.confirmBody))]),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid gap-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnGoNextTo.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.confirmGoNextText) + "\n\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showCloseWarning = false
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tKeep working\n\t\t\t")]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }