var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCalendar,
          expression: "showCalendar",
        },
      ],
      ref: "calendarWrp",
      staticClass: "vdp-datepicker__calendar",
      attrs: { id: _vm.calendarId },
      on: {
        mousedown: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("header", { staticClass: "d-flex" }, [
        _c(
          "div",
          {
            staticClass: "prev",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.monthPrevious()
              },
            },
          },
          [_vm._v("\n\t\t\t<\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "next",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.monthNext()
              },
            },
          },
          [_vm._v("\n\t\t\t>\n\t\t")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "month-name flex-grow-1" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.displayMonthYear) + "\n\t\t"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-link hover-underline today",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.monthToday()
              },
            },
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showToday,
                    expression: "showToday",
                  },
                ],
              },
              [_vm._v("Today")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._l(_vm.daysOfWeek, function (d) {
            return _c(
              "span",
              { key: d.timestamp, staticClass: "cell day-header" },
              [_vm._v(_vm._s(d))]
            )
          }),
          _vm._v(" "),
          _vm.blankDays > 0
            ? _vm._l(_vm.blankDays, function (d) {
                return _c("span", {
                  key: d.timestamp,
                  staticClass: "cell day blank",
                })
              })
            : _vm._e(),
          _vm._l(_vm.days, function (day) {
            return _c(
              "span",
              {
                key: day.timestamp,
                staticClass: "cell day",
                class: _vm.dayClasses(day),
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectDate(day)
                  },
                },
              },
              [_vm._v(_vm._s(day.date))]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }