var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen",
        },
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "footnote-dropdown",
    },
    [
      _c(
        "a",
        {
          staticClass: "footnote-dropdown-item",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.showCitationInOutline.apply(null, arguments)
            },
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "footnote-dropdown-item-text" }, [
            _vm._v("\n\t\t\tShow in Outline\n\t\t"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footnote-dropdown-item",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnEditFootnote.apply(null, arguments)
            },
          },
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("span", { staticClass: "footnote-dropdown-item-text" }, [
            _vm._v("\n\t\t\tEdit Footnote Citation\n\t\t"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "footnote-dropdown-item",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnDeleteCitation.apply(null, arguments)
            },
          },
        },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("span", { staticClass: "footnote-dropdown-item-text" }, [
            _vm._v("\n\t\t\tDelete Citation\n\t\t"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "footnote-dropdown-item-color-block" }, [
      _c("img", {
        attrs: {
          src: "/static/ckeditor/plugins/perrlaReferences/icons/hidpi/showinpaper.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "footnote-dropdown-item-color-block" }, [
      _c("img", {
        attrs: {
          src: "/static/ckeditor/plugins/perrlaReferences/icons/hidpi/edit.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "footnote-dropdown-item-color-block" }, [
      _c("img", {
        attrs: {
          src: "/static/ckeditor/plugins/perrlaReferences/icons/hidpi/delete.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }