var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content-grid", [
    _c("div", { staticClass: "d-grid g-overview" }, [
      _c("div", { staticClass: "d-grid g-paper-title" }, [
        _c("h1", { domProps: { innerHTML: _vm._s(_vm.Title) } }),
        _vm._v(" "),
        _c("p", { staticClass: "small mb-0 text-paper-type" }, [
          _vm._v(
            "\n\t\t\t\t" + _vm._s(_vm.calcFormatAndDocumentOutput) + "\n\t\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-grid g-left" },
        [_c("paper-details"), _vm._v(" "), _c("writing-tips")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-grid g-right" },
        [_c("recent-paper-saves")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }