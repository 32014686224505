<!--
- Paper Edit > Generic Modal > Word Count 
-->

<template>
	<generic-modal-wrap modal-id="WordCount">
		<h2 class="nodal-title">
			Word Count
		</h2>

		<table class="table table-border table-condensed mb-0">
			<thead>
				<tr>
					<th>Section</th>
					<th class="text-end">
						Words
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(pages, section) in documentSections" :key="section">
					<td>
						{{ section }}
					</td>
					<td class="text-end">
						{{ pages }}
					</td>
				</tr>
				<tr :class="{'bg-success-100': $store.state.customer.themeId === $enums.Theme.REGULAR, 'bg-success-700': $store.state.customer.themeId === $enums.Theme.DARK}">
					<td>
						<strong>Total</strong>
					</td>
					<td class="text-end">
						<strong> {{ totalCount }} </strong>
					</td>
				</tr>
			</tbody>
		</table>

	</generic-modal-wrap>

</template>

<script lang="js">
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import WordCount from '@/helpers/word-count';

export default {
	name: 'ModalWordCount',
	data() {
		return {
			totalCount: 0,	// increments as each section is calculated
		}
	},
	computed:{
		documentSections(){
			if(this.$store.state.modals.showArray.includes('WordCount')){
				let returnDocumentSections = {};
				this.totalCount = 0;

				// Title Page
				let $titlePageElList = document.getElementsByClassName('fake-widget-title-page');
				if($titlePageElList.length > 0){
					let titlePageContent = $titlePageElList[0].textContent;
					if (titlePageContent.length > 0) {
						let titlePageCount = WordCount(titlePageContent);
						this.totalCount += titlePageCount;
						returnDocumentSections['Title Page'] = titlePageCount;
					}
				}

				// Table of Contents
				if (this.$store.state.paperEdit.meta.IncludeTableOfContents) {
					let $tableOfContentsEl = document.getElementById('ckTableOfContents');
					if($tableOfContentsEl){
						let tocPageCount = WordCount($tableOfContentsEl.textContent);
						this.totalCount += tocPageCount;
						returnDocumentSections['Table of Contents'] = tocPageCount;
					}
				}

				// Abstract
				if (this.$store.state.paperEdit.meta.IncludeAbstract && CKEDITOR.instances.ckPaperAbstract) {
					let abstractPageCount = WordCount(CKEDITOR.instances.ckPaperAbstract.getData());
					this.totalCount += abstractPageCount;
					returnDocumentSections['Abstract'] = abstractPageCount;
				}

				// Body
				let bodyPageCount = 0;
				
				if(CKEDITOR.instances.ckPaperBody){
					bodyPageCount = WordCount(CKEDITOR.instances.ckPaperBody.getData());
				}

				this.totalCount += bodyPageCount;
				returnDocumentSections['Body'] = bodyPageCount;

				// References
				let $referencePageEl = document.getElementById('ckPaperReferences');
				if($referencePageEl){
					let referencePageCount = WordCount($referencePageEl.textContent);
					this.totalCount += referencePageCount;
					returnDocumentSections['References'] = referencePageCount;
				}

				// Appendices
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					// only looking for appendices now
					if(ckInstance.$ckEditorType === config.enums.CkEditorType.APPENDIX){
						let appendixPageCount = WordCount(ckInstance.getData());
						this.totalCount += appendixPageCount;
						returnDocumentSections[ckInstance.$appendixLabel] = appendixPageCount;
					}
				});//e:forEach

				return returnDocumentSections;
			}

			return null;
		}
	},
	components:{
		GenericModalWrap
	},
}
</script>
