<!--
- Paper Edit > Generic Modal > Insert Special Characters 
-->

<template>
	<generic-modal-wrap modal-id="SpecialCharacters">
		<h2 class="nodal-title">
			Insert Special Character
		</h2>
		<ul class="character-list">
			<li v-for="character in specialCharacterList" :key="character">
				<a href="#" v-html="character" @click.prevent="insertCharacter(character);" title="Click to insert" />
			</li>
		</ul>
	</generic-modal-wrap>
</template>

<script>
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import InsertRawHtml from '@/helpers/insert-raw-html';

export default {
	name: 'ModalSpecialCharacters',
	data() {
		return {
		}
	},
	computed:{
		specialCharacterList(){
			return CKEDITOR.config.specialChars;
		},
	},
	methods: {
		insertCharacter(chracter){
			InsertRawHtml.insertText(chracter);
			this.isOpen = false;
		},
	},
	components:{
		GenericModalWrap
	},
	
}
</script>

<style lang="scss">
	@import './../../../assets/styles/vars_perrla.scss';
	@include color-mode(dark) {
		ul.character-list li a {	
			color: $dark-body-color;

			&:hover{
				color: $dark-body-color;
			}
		}
	}
	ul.character-list{
		list-style: none;
		margin:0;
		padding: 0;

		li {
			display: block;
			float:left;
			height: 30px;
			text-align: center;
			width: 30px;
			
			a {
				color: $body-color;
				display: block;
				font-size: $font-size-base;
				text-decoration: none;

				&:hover{
					background: $primary;
					color: $white;
					font-size: 1.125rem;
				}
			}
		}
	}
</style>