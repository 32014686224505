var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tCreate Research Paper\n\t"),
    ]),
    _vm._v(" "),
    _vm.paperType == _vm.$enums.PaperType.APA7_StudentPaper ||
    _vm.paperType == _vm.$enums.PaperType.APA7_ProfessionalPaper
      ? _c("ul", { staticClass: "nav nav-tabs nav-tabs-module mb-3" }, [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                class: ["nav-link", { "nav-link-active": _vm.isStudentType }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.setIsStudentTab(true)
                  },
                },
              },
              [_vm._v("Student")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                class: ["nav-link", { "nav-link-active": !_vm.isStudentType }],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.setIsStudentTab(false)
                  },
                },
              },
              [_vm._v("Professional")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      [
        _vm.paperType == _vm.$enums.PaperType.APA7_StudentPaper ||
        _vm.paperType == _vm.$enums.PaperType.APA7_ProfessionalPaper
          ? _c("div", [
              _vm.isStudentType
                ? _c("div", { key: "divStudentType" }, [
                    _c("p", [
                      _vm._v(
                        "Student Research Papers have a simpler format while still containing all of the elements students need."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Your paper will have a Title Page, Body section, and separate Reference page. You can add a Table of Contents or Abstract as needed."
                      ),
                    ]),
                  ])
                : _c("div", { key: "divProType" }, [
                    _c("p", [
                      _vm._v(
                        "Professional Research Papers are suitable for papers that will be submitted for publication."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "They have a Title Page, Abstract, Body section, and separate Reference page."
                      ),
                    ]),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("form-select", {
          attrs: {
            label: "Paper Format",
            "select-object": _vm.paperFormatSelectList,
          },
          model: {
            value: _vm.paperType,
            callback: function ($$v) {
              _vm.paperType = $$v
            },
            expression: "paperType",
          },
        }),
        _vm._v(" "),
        _c("form-input", {
          attrs: {
            label: "Title",
            placeholder: "(required)",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.REQUIRED,
          },
          on: { input: _vm.calcRunningHead },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        !_vm.isStudentType
          ? _c("form-input-running-head", {
              attrs: { "is-removeable": false },
              model: {
                value: _vm.runningHead,
                callback: function ($$v) {
                  _vm.runningHead = $$v
                },
                expression: "runningHead",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-input", {
          attrs: { label: _vm.calcAuthorFieldLabel },
          model: {
            value: _vm.author,
            callback: function ($$v) {
              _vm.author = $$v
            },
            expression: "author",
          },
        }),
        _vm._v(" "),
        _c("form-input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.paperType !=
                  _vm.$enums.PaperType.MLA9_AnnotatedBibliography &&
                _vm.paperType != _vm.$enums.PaperType.MLA9_DiscussionPost &&
                _vm.paperType != _vm.$enums.PaperType.MLA9_ReferenceList &&
                _vm.paperType != _vm.$enums.PaperType.MLA9_ResearchPaper,
              expression:
                "paperType != $enums.PaperType.MLA9_AnnotatedBibliography && paperType != $enums.PaperType.MLA9_DiscussionPost && paperType != $enums.PaperType.MLA9_ReferenceList && paperType != $enums.PaperType.MLA9_ResearchPaper",
            },
          ],
          attrs: { label: "Institution" },
          model: {
            value: _vm.institution,
            callback: function ($$v) {
              _vm.institution = $$v
            },
            expression: "institution",
          },
        }),
        _vm._v(" "),
        _vm.isStudentType
          ? _c(
              "div",
              [
                _c("form-input-autocomplete", {
                  attrs: {
                    label: "Course Number & Name",
                    placeholder: "Type or select a class",
                    "data-list": _vm.classListToSearch,
                    "inject-value": _vm.injectClassSearchValue,
                  },
                  on: { "selected-from-search": _vm.selectedFromSearch },
                }),
                _vm._v(" "),
                _c("form-input", {
                  attrs: { label: "Instructor" },
                  model: {
                    value: _vm.instructor,
                    callback: function ($$v) {
                      _vm.instructor = $$v
                    },
                    expression: "instructor",
                  },
                }),
                _vm._v(" "),
                _c("form-datepicker", {
                  attrs: {
                    id: "CRP_dueDate",
                    label: "Due Date",
                    "paper-format": _vm.calcPaperFormatForDatePicker,
                  },
                  on: {
                    "date-set": _vm.dueDateSet,
                    "date-clear": _vm.dueDateClear,
                  },
                }),
                _vm._v(" "),
                _vm.paperType == _vm.$enums.PaperType.APA7_StudentPaper
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isStudentRunningHead,
                                expression: "!isStudentRunningHead",
                              },
                            ],
                            key: "divNoStudentRunningHead",
                            staticClass: "form-group",
                          },
                          [
                            _c("label", [_vm._v("Running Head")]),
                            _vm._v(" "),
                            _c("div", { staticClass: "help-text" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\tNot a standard part of a Student Research Paper\n\t\t\t\t\t"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-grid" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-hover btn-hover-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.runningHeadToggle.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "svg bi-plus-lg" }),
                                  _vm._v(" Add running head\n\t\t\t\t\t\t"),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("form-input-running-head", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isStudentRunningHead,
                              expression: "isStudentRunningHead",
                            },
                          ],
                          key: "componentStudentRunningHead",
                          attrs: { "is-removeable": true },
                          on: { "running-head-toggle": _vm.runningHeadToggle },
                          model: {
                            value: _vm.runningHead,
                            callback: function ($$v) {
                              _vm.runningHead = $$v
                            },
                            expression: "runningHead",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isStudentType
          ? _c("div", [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("\n\t\t\t\t\tAuthor's Note\n\t\t\t\t")]),
                  _vm._v(" "),
                  _c("form-checkbox", {
                    attrs: { label: "Include an Author's Note" },
                    model: {
                      value: _vm.isAuthorNote,
                      callback: function ($$v) {
                        _vm.isAuthorNote = $$v
                      },
                      expression: "isAuthorNote",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.emitter.emit("closeRouteModal")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.createPaper.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate Research Paper\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }