<!--
- FreeTrialDiscount for Word Add-In
-- make api call to joey that sets a timer in the store for this user
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0">
			<main class="container-text mt-0 pt-3">

				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div>
							<h1 class="text-center">
								PERRLA
							</h1>
							<div>
								<h2 class="text-center">
									Preparing your discount.
								</h2>
							</div>
						</div>
					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>
	</div>
</template>

<script>
export default {
	name: 'FreeTrialDiscountAddIn',
	data() {
		return {
		}
	},
	mounted() {
		// the user needs to log in now, but i want to save something in local storage first?
		this.$store.commit('SET_REDIRECT_TO_AFTER_LOGIN', 'free-trial-discount');

		this.$router.push({
			name: 'Login',
		}).catch(()=>{});
	},
}
</script>
