<!--
- Paper Edit > Editor Tab > Drawer > Reference Wizard > Step 1/3
-->

<template>
	<div class="drawer">

		<div class="drawer-header">
			<tool-drawer-title
				title="Create a reference"
				close-to-route-name="EditorReferencesCitations" />

			<p class="org-size d-block">
				Step 1 of 3: Select a reference type
			</p>

			<nav-tabs class="mb-2">
				<nav-tab
					:to="{
						name:'EditorCreateReferenceStep1',
						params: {openReferenceTab: 'common'}
					}">
					Common
				</nav-tab>
				<nav-tab
					:to="{
						name:'EditorCreateReferenceStep1',
						params: {openReferenceTab: 'all'}
					}">
					All types
				</nav-tab>
				<nav-tab
					v-if="$store.state.references.recentTypeIds.length > 0"
					:to="{
						name:'EditorCreateReferenceStep1',
						params: {openReferenceTab: 'recent'}
					}">
					Recent
				</nav-tab>
			</nav-tabs>

			<div v-if="$route.params.openReferenceTab === 'common'" key="searchThirdParty">
				<reference-search-third-party :is-drawer="true" :is-first-search="true" />
			</div>
			<div v-else-if="$route.params.openReferenceTab === 'all'" key="searchAll">
				<reference-type-search :ref-types="refTypes" />
			</div>

			<hr class="mt-2 mb-0" />

		</div><!--drawer-header-->

		<div class="drawer-body">
			<create-reference-step-1-body :is-drawer="true" />
		</div><!--drawer-body-->
	</div>
</template>

<script>
import CreateReferenceStep1Body from '@/modules/CreateReferenceStep1/Body'
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';
import ReferenceSearchThirdParty from '@/modules/CreateReferenceStep1/SearchThirdParty';
import ReferenceTypes from '@/services/reference/types';
import ReferenceTypeSearch from '@/modules/CreateReferenceStep1/Search';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'ReferenceCreateStep1',
	computed:{
		refTypes(){
			return ReferenceTypes.refTypes;
		},
	},
	mounted() {
		this.$store.dispatch('referenceRules/deepClear').then(()=>{
			TDS_Open({
				drawerName: 'EditorReferencesCitations'	// set a main drawer here, Add From library can't refresh
			});
		});
	},
	components: {
		CreateReferenceStep1Body,
		NavTab,
		NavTabs,
		ReferenceSearchThirdParty,
		ReferenceTypeSearch,
		ToolDrawerTitle
	},
}
</script>


<style lang="scss" scoped>
	@import "../../../../../assets/styles/vars_perrla";

	@include color-mode(dark) {
		.drawer {
			.drawer-header{
				background-color: $dark-body-bg;
			}
		}
	}

	.drawer {
		.drawer-header {
			background-color: $body-bg;
		}//.drawer-header

		.drawer-body {
			.ref-select-type-wrp{
				ul {
					list-style-type: none;
					margin:0;
					padding:0;

					ul {
						margin-left:15px;
					}
				}
			}
		}//.drawer-body

	}//.drawer

</style>