import config from '@/config';

import IdentifyUser from '@/views/Cart/IdentifyUser';
// import LandingSubscribeToday from '@/views/Cart/LandingSubscribeToday'; -- 2023.06.14 commented out - probably an older file from when Dashbaord and Settings were in MVC
import PaymentSettings from '@/views/Cart/PaymentSettings';
import PaymentSettingsPrioritySupport from '@/views/Cart/PaymentSettingsPrioritySupport';
import Receipt from '@/views/Cart/Receipt';
import SubscriptionSettings from '@/views/Cart/SubscriptionSettings';

export default [
	{
		name: 'IdentifyUser',
		component: IdentifyUser,
		path: '/cart-step-1',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
		children: [
			{ path: '*', redirect: '/cart-step-1' },
		],
	},
	// {
	// 	name: 'LandingSubscribeToday',
	// 	component: LandingSubscribeToday,
	// 	path: '/landing-subscribe-today',
	// 	meta: {
	// 		layout: config.enums.Layout.PCOM,
	// 	},
	// },
	{
		name: 'PaymentSettings',
		component: PaymentSettings,
		path: '/cart-step-3',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'PaymentSettingsPrioritySupport',
		component: PaymentSettingsPrioritySupport,
		path: '/cart-priority-support',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
	
	{
		name: 'Receipt',
		component: Receipt,
		path: '/cart-step-4',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
	{
		name: 'SubscriptionSettings',
		component: SubscriptionSettings,
		path: '/cart-step-2',
		meta: {
			layout: config.enums.Layout.PCOM,
		},
	},
]