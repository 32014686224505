/*
- Expand Textarea By Id
-- method to expand a textarea form field after information has loaded inside it
-- 2022.09.22
*/

import _has from 'lodash/has';

export default {
	methods: {
		expandTextarea(elementRef){
			if(!_has(this.$refs, elementRef)){
				return false;
			}
			if(this.$refs[elementRef]){
				// Reset field height
				this.$refs[elementRef].style.height = 'inherit';

				// cheap vars so i don't have to calculate them all the time
				let borderWidth = 1;
				
				this.$refs[elementRef].style.height = ((borderWidth * 2) + this.$refs[elementRef].scrollHeight) + 'px';
			}
		},
	},
}
