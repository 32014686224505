<!--
- Single Reference Page Row
-->

<template>
	<div>
		<p class="hanging-indent-instance word-break">
			<a 
				class="fake-widget fake-widget-reference-row"
				href="#"
				title="Click to Edit Reference"
				@click.prevent="btnOpenEditReference"
				v-html="referenceObject.displayValue" />
		</p>

		<textarea
			:id="calcInstanceId"
			:value="annotationContentValue"
			autocomplete="off" 
			class="d-none" />
		
	</div>
</template>

<script>
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import PAnS_AddUpdate from '@/services/paper/annotations/addUpdate';
import PCKS_OnChange from '@/services/paper/ck/onChange';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_SaveDocument from '@/services/paper/saveDocument';
import Swal from 'sweetalert2';

export default {
	name: 'ReferencePageRow',
	props:{
		referenceObject:{
			required: true,
			type: Object,
		},
		referenceUniqueId:{
			required: true,
			type: String
		},
	},
	data() {
		return {
			annotationContentValue: '',
			displayAnnotationEditor: false,	// bool sets to true once 
			referenceValue:'',
		}
	},
	computed:{
		annotationData(){
			if(this.$store.state.paperEdit.annotations.isInclude){
				return this.$store.state.paperEdit.annotations.inPaper.find((annotationData)=>{
					return annotationData.referenceUniqueId.toUpperCase() === this.referenceObject.referenceUniqueID.toUpperCase();
				});
			}
			return null;
		},
		calcInstanceId(){
			return 'ck_annotation_' + this.referenceObject.referenceUniqueID.toUpperCase();
		},
		includeAnnotations() {
			return this.$store.state.paperEdit.annotations.isInclude;
		},
	},
	methods: {
		btnOpenEditReference(){
			if(this.referenceObject.isArchived){
				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'This reference has been archived.',
					// text: 'Archiving your reference will remove it from your library. It will be disabled in any papers where it was used.',
					icon: 'error',
					cancelButtonText: 'Ok',
					showCloseButton: false,
					showCancelButton: true,
					showConfirmButton: false,
					customClass:{
						cancelButton: 'btn btn-outline-success',
					},
				}).then((result) => {
					// if (result.value) {
					// }//e:if
				});//e:Swal

			} else {
				this.$router.push({
					name: 'EditorEditReference',
					params:{
						referenceUid: this.referenceUniqueId.toUpperCase(),
					}
				}).catch(()=>{});
			}
		},//e:btnOpenEditReference
		
		loadAnnotation(){
			let configCkAnnotation = configCkEditor.getSettings('ckAnnotations');

			if(this.annotationData){
				this.annotationContentValue = this.annotationData.content;
			} else {
				this.annotationContentValue = '';
			}

			let $this = this;

			configCkAnnotation.on = {
				blur(evt) {
					PAnS_AddUpdate({
						content: evt.editor.getData(),
						referenceUniqueId: $this.referenceObject.referenceUniqueID.toUpperCase(),
					}).then(()=>{
						PS_SaveDocument({
							isMetaDataChanges: true,
							isStealthSave: false,
							message: 'Annotation Blur',
						});
					});
				},
				
				change(evt) {
					PCKS_OnChange({
						editorName: evt.editor.name,
					});
				},//e:change

				focus(evt) {
					PCKS_OnFocus({
						editorName: evt.editor.name,
					});
				},//e:focus

				instanceReady(evt) {
					evt.editor.$ckEditorType = config.enums.CkEditorType.ANNOTATION;

					PCKS_wProofreaderInit({
						container: evt.editor.window.getFrame().$,
					}).then(()=>{
					});

				},
			}
			
			// add to window object of all annotations (i'm doing this because i can't put the ckInstance in the vuex store)
			CKEDITOR.replace(this.calcInstanceId, configCkAnnotation);

		},//e:loadAnnotation

	},
	created() {
		this.emitter.on('createNewAnnotation', ($opts)=>{
			if($opts.referenceUniqueID.toUpperCase() === this.referenceObject.referenceUniqueID.toUpperCase()){
				this.loadAnnotation();
			}
		});

	},
	mounted() {
		// this mount will happen at first load, and again after a reference is removed
		if(this.includeAnnotations){
			this.loadAnnotation();		
		} else {
			// currently annotations are off here - check if you are in an Annotated Bibliography
			if(this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.ANNOTATED_BIBLIOGRAPHY){
				this.$store.commit('paperEdit/annotations/IS_INCLUDE', true);
				this.loadAnnotation();
			}
		}//e:this.includeAnnotations
	},
	destroyed(){
		if(this.includeAnnotations && this.annotationData) {
			PAnS_AddUpdate({
				content: CKEDITOR.instances['ck_annotation_' + this.referenceObject.referenceUniqueID.toUpperCase()].getData(),
				referenceUniqueId: this.referenceObject.referenceUniqueID,
			});
		}
		
		this.emitter.off('createNewAnnotation');
	},

}
</script>
