var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.calcClasses }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.isChecked,
          expression: "isChecked",
        },
      ],
      staticClass: "form-check-input",
      attrs: { type: "checkbox", id: _vm.safeId },
      domProps: {
        checked: Array.isArray(_vm.isChecked)
          ? _vm._i(_vm.isChecked, null) > -1
          : _vm.isChecked,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.isChecked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isChecked = $$c
            }
          },
          function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
        ],
      },
    }),
    _vm._v(" "),
    _c("label", {
      staticClass: "form-check-label cursor-pointer",
      attrs: { for: _vm.safeId },
      domProps: { innerHTML: _vm._s(_vm.label) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }