var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.calcLevelClass,
      attrs: {
        "data-id": _vm.nodeData.id,
        id: _vm.calcOutlineNodeId,
        draggable: "true",
      },
    },
    [
      _c("div", { staticClass: "svg-wrp" }, [
        _c(
          "a",
          {
            staticClass: "svg-link svg-link-add float-end",
            attrs: { href: "#", title: "Add Outline Row" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnAddOutlineNode.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg bi-plus-lg" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "svg-link svg-link-toggle float-end",
            attrs: { href: "#", title: "Toggle Checked" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleIsActive.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "svg svg-link-toggle bi-check-lg" })]
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "outline-node-content" }, [
        _c("span", { staticClass: "prefix" }, [_vm._v(_vm._s(_vm.calcPrefix))]),
        _vm._v(" "),
        _c("span", {
          staticClass: "outline-node-text",
          domProps: { innerHTML: _vm._s(_vm.nodeData.content) },
        }),
      ]),
      _vm._v(" "),
      _vm.$store.state.paperEdit.config.renderCitationsAsFootnotes &&
      _vm.calcCitationOutput != ""
        ? _c("p", { class: ["outline-node-citation mb-0 float-end"] }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.calcCitationOutput) },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }