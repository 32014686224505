var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawer" },
    [
      _c("transition", { attrs: { name: "route-fade" } }, [
        _vm.showCloseWarning
          ? _c("div", { staticClass: "close-warning" }, [
              _c("h1", [_vm._v("Stop updating this reference?")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "If you stop now, the information you've entered on this page will be lost.  To finish updating the reference, click the green button at the bottom."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-grid gap-2" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-outline-danger",
                      attrs: { to: { name: "EditorAddFromLibrary" } },
                    },
                    [_vm._v("\n\t\t\t\t\tDon't update reference\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.showCloseWarning = false
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tKeep working\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-header" },
        [
          _c("tool-drawer-title", {
            attrs: { title: "Update Reference", "is-close-warning": true },
            on: {
              "show-warning": function ($event) {
                _vm.showCloseWarning = true
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-body" },
        [_c("convert-reference-step2-body", { attrs: { "is-drawer": true } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }