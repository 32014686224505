var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    class: [
      "outline-footnote-single cursor-pointer",
      { "outline-footnote-menu-open": _vm.isMenuOpen },
    ],
    attrs: {
      "citation-unique-ids-list": _vm.calcListOfUids,
      "footnote-index": _vm.footnoteIndex,
    },
    domProps: { innerHTML: _vm._s(_vm.footnoteContent) },
    on: {
      dblclick: function ($event) {
        $event.preventDefault()
        return _vm.btnEditFootnote.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }