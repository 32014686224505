var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h1",
    { staticClass: "clearfix tool-drawer-title" },
    [
      _vm.closeToRouteName
        ? _c(
            "router-link",
            {
              staticClass: "link-icon",
              attrs: {
                to: {
                  name: _vm.closeToRouteName,
                },
              },
            },
            [
              _c("span", {
                staticClass: "svg bi-x-lg",
                attrs: { title: "Close" },
              }),
            ]
          )
        : _vm.isCloseWarning
        ? _c(
            "a",
            {
              staticClass: "link-icon",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("show-warning")
                },
              },
            },
            [
              _c("span", {
                staticClass: "svg bi-x-lg",
                attrs: { title: "Close" },
              }),
            ]
          )
        : _vm.closeToRoute
        ? _c(
            "router-link",
            { staticClass: "link-icon", attrs: { to: _vm.closeToRoute } },
            [
              _c("span", {
                staticClass: "svg bi-chevron-left",
                attrs: { title: "Back" },
              }),
            ]
          )
        : _vm.isBrowserBack
        ? _c(
            "a",
            {
              staticClass: "link-icon",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnGoBack.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                staticClass: "svg bi-chevron-left",
                attrs: { title: "Back" },
              }),
            ]
          )
        : !_vm.isTitleOnly
        ? _c(
            "a",
            {
              staticClass: "link-icon",
              attrs: {
                href: "#",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Open Menu",
                "data-bs-placement": "left",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openSideMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                staticClass: "svg bi-list",
                attrs: { title: "Open Menu" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._v("\n\t" + _vm._s(_vm.title) + "\n\t\n\t"),
      !_vm.isTitleOnly ? _c("btn-close") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }