<!--
- Paper Edit > Editor Tab > Citation Context Menu Solo
-- Start of a shortcut feature based on Essayist
-- 
-->

<template>
	 <div :class="['citation-context-menu-wrp rounded', {'visible': isOpen , 'invisible': !isOpen}]" ref="citationContextMenu" v-hotkey="keymapCloseButton" v-on-clickaway="closeMenu">
		<div v-if="referencesInPaper.length > 0" key="divListReferencesInPaper">
			<div class="citation-context-menu-scroll" ref="listOfReferencesWrp">
				<div
					class="d-block"
					v-for="referenceObject in referencesInPaper"
					:key="referenceObject.referenceUniqueID">
					<a href="#" class="citation-context-menu-link" @click.prevent="gotoCitationCreate(referenceObject.referenceUniqueID)">
						<p>
							<strong v-html="referenceObject.citationDisplayValue"></strong> - <em> {{ referenceObject.primaryTitle }}</em>
						</p>
					</a>
				</div>
			</div>
			<!-- <div class="footer-wrp bg-danger">
				<p>FOOTER</p>
			</div> -->
		</div><!--divReferenceInPaper-->
		<div v-else key="divEmtyReferencesInPaper">
			<div class="empty-state-wrp p-2">
				<p>
					Create a new reference or add an existing one to your paper. <br />
					You can create citations once you've added a reference to your paper.
				</p>
			</div>
		</div><!--divEmtyReferencesInPaper-->

		
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import config from '@/config';
import { mixin as clickaway } from 'vue-clickaway';
import TDS_Open from '@/services/paper/toolDrawer/open';

const OFFSET_MARGIN = (96 /2); // 96px is the page margin, i'm dividing it by two because it the menu just looks cool in that middle margin space

export default {
	name: 'CitationContextMenuSolo',
	mixins: [clickaway],
	props:{
		isAnnotations:{
			default: false,
            type: Boolean
		},
	},
	data() {
		return {
            elCitationContextMenu: null,
			clickListener: null,
		}
	},
	computed:{
		isOpen(){
			return this.$store.state.paperEdit.isCitationContextMenuOpen;
		},
        keymapCloseButton(){
			return {
				'esc': () => {
					this.closeMenu();
				},
			}
		},//e:keymapCloseButton

		referencesInPaper(){
			return this.$store.state.paperEdit.referencesInPaper;
		},//e:referencesInPaper
	},
	methods: {
        closeMenu(){
			this.$store.commit('paperEdit/SET_IS_CITATION_CONTEXT_MENU_OPEN', false);

			if(this.clickListener){
				// loop through each ckinstance
				_forEach(CKEDITOR.instances, (ckInstance)=>{
					if(ckInstance.editable()){
						// remove the click listener
						ckInstance.editable().removeListener('click', this.clickListener);
					}
				});//e:forEach
				this.clickListener = null;
			}
        },//closeMenu
		
		gotoCitationCreate(referenceUniqueId){
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				TDS_Open({
					drawerName: 'OutlineReferencesCitations'
				}).then(()=>{
					this.$router.push({
						name: 'OutlineCitationAdd',
						params: {
							referenceUid: referenceUniqueId.toUpperCase()
						},
					}).catch(()=>{});
				});

			} else {
				TDS_Open({
					drawerName: 'EditorReferencesCitations'
				}).then(()=>{
					this.$router.push({
						name: 'EditorCitationAdd',
						params: {
							referenceUid: referenceUniqueId.toUpperCase()
						},
					}).catch(()=>{});
				});
				
			}
			

			this.closeMenu();
		},//e:gotoCitationCreate

		positionMenu($opts){
			// at this point the width and height are set, the menu is visible, it just needs to go in the right place on the screen

			// start fresh
			this.elCitationContextMenu.style.removeProperty('top');
			this.elCitationContextMenu.style.removeProperty('right');
			this.elCitationContextMenu.style.removeProperty('bottom');
			this.elCitationContextMenu.style.removeProperty('left');
			
			let cursor = $opts.editor.getSelection().getRanges().shift();
			
			let rects = cursor.getClientRects(false);
			let offsetCursorBottom = rects[rects.length - 1]['bottom'] || 0;
			let offsetCursorRight = rects[rects.length - 1]['right'] || 0;

			let elCkWrp = $opts.editor.container.$;
			
			if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
				// OUTLINE
				let documentOffsetY = 4 + (elCkWrp.getBoundingClientRect().top);
				this.elCitationContextMenu.style.top = documentOffsetY + offsetCursorBottom + 'px';
				this.elCitationContextMenu.style.left = offsetCursorRight + 'px';
			
			} else {
				// EDITOR
				let elPaperDocumentIn = document.getElementById("paperDocumentIn");

				// get this document container, in this case it's the scrollable paper area
				if(elPaperDocumentIn){
					// position the top / y axis

					// the top of the ckInstance + how far PaperDocumentIn is scrolled + height of header, tabs, paper editor wrappings
					let documentOffsetY = elCkWrp.getBoundingClientRect().top + elPaperDocumentIn.scrollTop - 218;

					this.elCitationContextMenu.style.top = documentOffsetY + offsetCursorBottom + 'px';
					
					// position the left / x axis // 
 					// is the menu on the screen vertically?
					if(this.elCitationContextMenu.getBoundingClientRect().bottom > window.innerHeight){
						// no - scroll the context menu into view
						this.elCitationContextMenu.scrollIntoView({
							block: "end",
							inline: "nearest"
						});
					}
					
					this.elCitationContextMenu.style.left = (elCkWrp.getBoundingClientRect().left - OFFSET_MARGIN) + offsetCursorRight + 'px';
					
					// is the menu on the screen horizontially
					if((this.elCitationContextMenu.getBoundingClientRect().right + OFFSET_MARGIN) > elPaperDocumentIn.getBoundingClientRect().right){	
						// no - use the right value now
						this.elCitationContextMenu.style.removeProperty('left');
						this.elCitationContextMenu.style.right = OFFSET_MARGIN + 'px';
					}//e:if

				}//e:if

			}//e:if

			// register a click event to close the menu 
			this.clickListener = $opts.editor.editable().on('click', () => {
				this.closeMenu();
			});

		},//e:positionMenu
	},
	created() {
		this.emitter.on('focusCitationContextMenu', () => {
			let aTagsInsideThisElement = this.elCitationContextMenu.querySelectorAll('a.citation-context-menu-link')
			if(aTagsInsideThisElement && aTagsInsideThisElement.length > 0){
				aTagsInsideThisElement[0].focus();
			}
		});//e:focusCitationContextMenu

        this.emitter.on('openCitationContextMenu', ($opts) => {
			if(!this.isOpen){
				this.$store.commit('paperEdit/SET_IS_CITATION_CONTEXT_MENU_OPEN', true)
			
				this.positionMenu({
					editor: $opts.editor,
				});
			}
		});//e:openCitationContextMenu
	},
	mounted() {
        this.$nextTick(()=>{
            this.elCitationContextMenu = this.$refs.citationContextMenu;
        });//e:nextTick
	},
    destroyed(){
		this.emitter.off('focusCitationContextMenu');
        this.emitter.off('openCitationContextMenu');
    },
}
</script>


<style lang="scss" scoped>
	// hex colors are copied from ckEditor's context menu

	@import "../../../assets/styles/_vars_perrla.scss";

	$single-row-height: 25.5px;	// measured in dev inspect console
	$number-of-rows-to-show: 4.5;	// showing half of the other one 

	.citation-context-menu-wrp {
		background: $body-bg;
		box-shadow: $dropdown-box-shadow;
		border: 1px solid #d1d1d1;
		display: block; 
		position: absolute;
		width: 450px;
		z-index: 10000;

		.citation-context-menu-scroll {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: $single-row-height * $number-of-rows-to-show;

			a.citation-context-menu-link {
				border-bottom: 1px solid #e9e9e9;
				color: $body-color;
				display: block;
				padding: 0.375rem;	// weird number but it's between 0.25 and 0.5, and it looks good on screen
				text-decoration: none;
				white-space: nowrap;

				&:hover,&:focus {
					background-color: #e9e9e9;
					text-decoration: none;
				}

				p {
					color: $body-color;
					font-size: 10px;
					font-family: $font-family-sans-serif;
					line-height: 1.25;
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		
		.empty-state-wrp p {
			color: $gray-600;
			font-size: 12px;
			font-family: $font-family-sans-serif;
			line-height: 1.5;
			margin-bottom: 0;
		}
	}
	
	
	
</style>

