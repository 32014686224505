<!--
- Class Edit > Recent Papers
-
-->

<template>
	<div class="position-relative mb-4">
		<div class="row">
			<div class="col">
				<h2>Recent Papers</h2>
			</div><!--col-->
		</div><!--row-->

		<div class="row">
			<div class="col-12">
				<!-- {{ classData }} -->
				<table v-if="filteredGridData && filteredGridData.length > 0" class="table table-borderless entity-grid mb-0">
					<thead>
						<tr>
							<sortable-grid-th 
								cell-text="Title" 
								sort-key="title"
								colspan="2"
								:is-title="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts" 
								@update-grid="updateGridSort" />

							<th class="table-spacer" />
							
							<th>
								Type
							</th>

							<th class="table-spacer" />

							<sortable-grid-th 
									cell-text="Last Modified" 
									sort-key="lastModifiedDate"
									:sort-order-by="sortOrderBy"
									:sorts="sorts" 
									@update-grid="updateGridSort" />
						</tr>
					</thead>
					<tbody>
						<paper-row
							v-for="paperData in filteredGridData"
							:class-id="paperData.classID"
							:paper-class-name="paperData.className"
							:paper-description="paperData.description"
							:paper-due-date="paperData.dueDate"
							:paper-encrypted-id="paperData.encryptedID"
							:paper-format-version-id="paperData.paperFormatVersionID"
							:paper-id="paperData.paperID"
							:paper-is-complete="paperData.isComplete"
							:paper-last-modified-date="paperData.lastModifiedDate"
							:paper-start-date="paperData.startDate"
							:paper-title="paperData.title"
							:paper-type="paperData.paperType"
							:key="paperData.paperID"
							context="ClassEdit" />
					</tbody>
				</table>
				<div v-else class="text-muted">
					<p>No recent papers found.</p>
				</div>
			</div><!--col-->
		</div><!--row-->

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import PaperRow from '@/components/EntityGrid/PaperRow';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ClassEditRecentPapers',
	props:{
	},
	data() {
		return {
			// filteredGridData: [],

			sortOrderBy: 'lastModifiedDate',
			sorts: {
				'title': 1,
				'className': 1,
				'lastModifiedDate': -1,
			}
		}
	},
	computed:{
		classData(){
			return this.$store.state.classes.openObject;
		},

		filteredGridData(){
			let filteredArray = [];
			let _data = _cloneDeep(this.$store.state.paperList.list);

			_data.forEach((paperData) =>{
				// console.log(paperData);
				// skip Word Add-In Papers and papers not part of this class
				// console.log(this.classData);
				if(!paperData.isOnlinePaper || paperData.classID !== this.classData.classID || !_includes(config.enums.Format, paperData.paperFormatVersionID)){
					return false;
				}

				filteredArray.push(paperData);
			});//e:forEach

			let uniqueDataArray = _uniqBy(filteredArray, 'paperID');

			// this.$nextTick(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			// });

			// now sort if user has modified the THs
			if(this.sorts[this.sortOrderBy] === 1){
				return _orderBy(uniqueDataArray, [uniqueDataArray => uniqueDataArray[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				return _orderBy(uniqueDataArray, [uniqueDataArray => uniqueDataArray[this.sortOrderBy].toLowerCase()], ['desc']);
			}
		},

	},
	methods: {
		updateGridSort($obj){
			this.sortOrderBy = $obj.sortKey;
			this.sorts = $obj.sorts;
		},
	},
	created() {
	},
	mounted() {
		// this.$store.commit('loaders/ADD_ID', 'App');
	},
	components: {
		PaperRow,
		SortableGridTh
	},
	destroyed(){
	},
}
</script>
