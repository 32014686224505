<!--
- Generic Modal Wrap
-- a hidden button is used on the test server
-- 2023.05.26
-->

<template>
	<transition name="nodal">
		<div class="nodal-mask" v-if="isOpen">
			<transition name="nodal" mode="out-in" appear>
				<div class="nodal-body">
					<!-- All Modal-->
					<button type="button" class="btn-close" @click.prevent="closeModal" v-hotkey="keymapCloseButton" v-if="isCloseButton">
						<span class="svg bi-x-lg" role="button" aria-label="Close" />
					</button>
					<!-- Only Test Server Alert-->
					<button type="button" class="btn-close btn-close-hidden" v-hotkey="keymapHiddenButton" v-else>
						<span class="svg" role="button" aria-label="Close" />
					</button>

					<!-- unique modal content -->
					<slot />
					
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>

export default {
	name: 'GenericModalWrap',
	props:{
		isCloseButton:{
			default: true,
			type: Boolean
		},
		modalId:{
			required: true,
			type: String,
		}
	},
	computed:{
		isOpen(){
			if(this.$store.state.modals.showArray.includes(this.modalId)){
				this.emitter.emit('GenericModalOpen',{
					modalId: this.modalId
				});
				return true;
			}
			return false;
		},
		keymapCloseButton(){
			return {
				'esc': () => {
					this.closeModal();
				},
			}
		},
		keymapHiddenButton(){
			return {
				'shift+p': () => {
					this.closeModal();
					this.$store.commit('SET_HAS_SEEN_TEST_SERVER_ALERT', true);
				},
			}
		},
	},
	methods: {
		closeModal(){
			this.$store.commit('modals/CLEAR_MODAL_DATA');
			this.$store.commit('modals/CLOSE', this.modalId);
			this.emitter.emit('GenericModalClose',{
				modalId: this.modalId
			});
		}
	},
}
</script>
