<!--
- Dashboard > Route Modal > Create Work
-->

<template>
	<div>
		<h2 class="nodal-title">
			Create a New Work Item
		</h2>

		<form>
			<div class="form-group mb-0 py-2">
				<label>Type of task</label>
				<div class="row">
					<div class="col">
						<div class="form-check form-check-inline">
							<input
								class="form-check-input"
								type="radio"
								id="CT_workType_ToDo"
								:value="$enums.WorkType.TODO"
								v-model="workType" /> 
							<label class="form-check-label" for="CT_workType_ToDo">
								To-Do
							</label>
						</div>
						<div class="form-check form-check-inline">
							<input
								class="form-check-input"
								type="radio"
								id="CT_workType_Assignment"
								:value="$enums.WorkType.ASSIGNMENT"
								v-model="workType" /> 
							<label class="form-check-label" for="CT_workType_Assignment">
								Assignment
							</label>
						</div>
					</div>
				</div>
			</div>				

			<form-input
				label="Name"
				placeholder="(required)"
				v-model="workName"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED" />

			<form-datepicker
				id="CT_dueDate"
				label="Due Date"
				:form-id="zFormId"
				:is-clearable="true"
				:paper-format="0"
				validate-is-all-date
				@date-set="dateDueSet" />

			<form-select
				label="Class"
				:form-id="zFormId"
				:is-class-select="true"
				v-model="associatedClassId" />

			<div class="row">
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="button" class="btn btn-outline-danger" @click.prevent="emitter.emit('closeRouteModal')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="submit" class="btn btn-success" @click.prevent="createTask">
							Create Task
						</button>
					</div>
				</div>
			</div><!--row-->
		</form>

	</div>

</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import FormDatepicker from '@/components/form/Datepicker';
import FormInput from '@/components/form/Input';
import FormSelect from '@/components/form/Select';

export default {
	name: 'DashboardCreateWork',
	data() {
		return {
			associatedClassId: 0,
			dueDate: '',
			workName: '',
			workType: 0,	// sets when mounted
			zFormId: 'formCreateTask',
		}
	},
	methods:{
		// _open(){
		// },

		createTask(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{

				// get class name if one is associated
				let className = '';
				if(this.associatedClassId !== 0){
					let thisClass = this.$store.state.classes.list.find((classData)=>{
						return classData.classID === parseInt(this.associatedClassId);
					});
					if(thisClass){
						className = thisClass.name
					}
				}

				// convert due date to server format if one is selcted
				let formattedDueDate = '';
				if(this.dueDate != ''){
					// for now just make sure all the fields are filled in - make this cleaner later
					let dateObj = new Date(this.dueDate);
					
					// if (isNaN(dateObj)){
					// 	console.log('BAD')
					// // 	throw SyntaxError("invalid date");
					// }
					// return false;
					
					formattedDueDate = dateObj.toISOString().split('T')[0] + 'T00:00:00.000Z';
				}

				let sendData = {
					"classID": parseInt(this.associatedClassId),
					"className": className,
					// "createdDate": "2022-01-04T23:01:08.924Z"
					// "description": "string",
					"dueDate": formattedDueDate,
					// "entityEncryptedID": "string",
					// "entityID": 0,
					"isComplete": false,
					"isRepeating": false,
					"name": this.workName,
					// "repeatEntityID": 0,
					// "startDate": "2022-01-04T23:01:08.924Z",
					"workType": this.workType,
				}

				// console.log('sendData');
				// console.log(sendData);

				this.$store.commit('loaders/ADD_ID', 'App');

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					url: 'v4/Work',
				}).then(()=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});
					this.emitter.emit('updateUpcomingWork');
				});
			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
			
		},//e:createTask

		dateDueSet(newDate){
			this.dueDate = newDate;
		},
	},
	mounted() {
		// refresh here to keep the modal open
		if(this.$route.name === 'DashboardCreateWork'){
			this.workType = parseInt(this.$route.params.workType);
			this.emitter.emit('openRouteModal', {
				modalRoute: 'DashboardCreateWork',
				modalParams: {
					workType: this.$route.params.workType
				}
			});
		}
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components:{
		FormDatepicker,
		FormInput,
		FormSelect,
	},
}
</script>
