var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c(
      "section",
      {
        staticClass: "section-text pt-0",
        staticStyle: {
          background:
            "url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff",
        },
      },
      [
        _c("main", { staticClass: "container-text mt-0 pt-3" }, [
          _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
            _c("div", { staticClass: "inset-body" }, [
              _c("img", {
                staticClass: "mx-auto d-block",
                attrs: {
                  src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                  width: "140px",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tIf you can't find our verification email or it was entered incorrectly, you can change the email for your Free Trial below.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "fw-bold" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tThe email you enter below will be the new email address you will use to log into PERRLA.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "mt-4" },
                [
                  _c("form-input", {
                    attrs: {
                      label: "Email",
                      type: "email",
                      "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
                      "form-id": _vm.zFormId,
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tHere's the information we have for your account so far:"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-4 fw-bold" }, [
                        _vm._v("Name:"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.firstName) +
                          " " +
                          _vm._s(_vm.lastName) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-4 fw-bold" }, [
                        _vm._v("Institution:"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.school) + " "),
                      _c("br"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "d-grid" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.btnUpdateEmail.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\tUpdate my email\n\t\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-5" }, [
      _c("h1", [_vm._v("PERRLA")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }