/*
- HelperGetCitationOutputForResearchNote
-- so far only used when you VIEW or EDIT a research note, mainly because turabian 9 uses Subsequent for that, maybe later this could be expanded
-- 2024.08.13
*/
import _has from 'lodash/has';
import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';

export default (researchNoteCitation) => {
    
    try {
        let citationOutputObject = JSON.parse(researchNoteCitation);

        switch(HelperGetReferenceFormatId()){
            case config.enums.Format.APA7:
                if(_has(citationOutputObject, 'apa7') && _has(citationOutputObject.apa7, 'first')){
                    return citationOutputObject.apa7.first;
                }
                break;
            case config.enums.Format.MLA9:
                if(_has(citationOutputObject, 'mla9') && _has(citationOutputObject.mla9, 'first')){
                    return citationOutputObject.mla9.first;
                }
                break;
            case config.enums.Format.Turabian9:
                if(_has(citationOutputObject, 'turabian9') && _has(citationOutputObject.turabian9, 'subsequent')){
                    return citationOutputObject.turabian9.subsequent;
                }
                break;
        }
    
    } catch(error) {
        console.log(error);
        return '';
    }
   
}
