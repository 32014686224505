var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tCreate a New Work Item\n\t"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      [
        _c("div", { staticClass: "form-group mb-0 py-2" }, [
          _c("label", [_vm._v("Type of task")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check form-check-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.workType,
                      expression: "workType",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "radio", id: "CT_workType_ToDo" },
                  domProps: {
                    value: _vm.$enums.WorkType.TODO,
                    checked: _vm._q(_vm.workType, _vm.$enums.WorkType.TODO),
                  },
                  on: {
                    change: function ($event) {
                      _vm.workType = _vm.$enums.WorkType.TODO
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "CT_workType_ToDo" },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\tTo-Do\n\t\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check form-check-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.workType,
                      expression: "workType",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "radio", id: "CT_workType_Assignment" },
                  domProps: {
                    value: _vm.$enums.WorkType.ASSIGNMENT,
                    checked: _vm._q(
                      _vm.workType,
                      _vm.$enums.WorkType.ASSIGNMENT
                    ),
                  },
                  on: {
                    change: function ($event) {
                      _vm.workType = _vm.$enums.WorkType.ASSIGNMENT
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "CT_workType_Assignment" },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\tAssignment\n\t\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("form-input", {
          attrs: {
            label: "Name",
            placeholder: "(required)",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.REQUIRED,
          },
          model: {
            value: _vm.workName,
            callback: function ($$v) {
              _vm.workName = $$v
            },
            expression: "workName",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "CT_dueDate",
            label: "Due Date",
            "form-id": _vm.zFormId,
            "is-clearable": true,
            "paper-format": 0,
            "validate-is-all-date": "",
          },
          on: { "date-set": _vm.dateDueSet },
        }),
        _vm._v(" "),
        _c("form-select", {
          attrs: {
            label: "Class",
            "form-id": _vm.zFormId,
            "is-class-select": true,
          },
          model: {
            value: _vm.associatedClassId,
            callback: function ($$v) {
              _vm.associatedClassId = $$v
            },
            expression: "associatedClassId",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.emitter.emit("closeRouteModal")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.createTask.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate Task\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }