<!--
- LandingPage
-- this is the '/' path of the router
-- mainly designed to direct traffic based on login state
-->

<template>
	<div>
		<p>LandingPage</p>
	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'LandingPage',
	mounted() {
		this.$nextTick(()=>{
			if(this.$store.state.api.token === ''){
				// Api Token is blank / hasn not been set
				if(config.isDev){
					this.$router.push({
						name: 'Login'
					}).catch(()=>{});
				} else {
					// i have to use a hard redirect here to get from Public to App
					window.location = config.appUrl + '/Public/#/login';
				} 
			} else {
				if(config.isDev){
					this.$router.push({
						name: 'Dashboard'
					}).catch(()=>{});
				} else {
					// i have to use a hard redirect here to get from Public to App
					window.location = config.appUrl + '/App/#/dashboard';
				} 
			}
		});
	},
}
</script>
