var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.filteredGridData && _vm.filteredGridData.length > 0
          ? _c("table", { staticClass: "table table-borderless entity-grid" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Title")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "table-spacer" }),
                  _vm._v(" "),
                  _c("th", [_vm._v("Modified")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "30px" } }, [
                    _vm._v("\n\t\t\t\t\t\t\t \t"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.filteredGridData, function (paperData) {
                  return _c("paper-row", {
                    key: paperData.paperID,
                    attrs: {
                      "paper-id": paperData.paperID,
                      "paper-is-complete": paperData.isComplete,
                      "paper-last-modified-date": paperData.lastModifiedDate,
                      "paper-title": paperData.title,
                      context: "PaperList",
                      "loader-id": "PaperList",
                    },
                  })
                }),
                1
              ),
            ])
          : _c(
              "div",
              {
                key: "divNoPaperData",
                staticClass: "text-muted text-center mt-5",
              },
              [_c("p", [_vm._v("No papers found.")])]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }