<!--
- Outline Editor > Tool Drawer > Citation Edit
-- not a real citation - this is the citation from the outline editor
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Edit Citation"
				close-to-route-name="OutlineReferencesCitations" />
		</div>

		<div class="drawer-body">
			<form>
				<citation-form
					:load-citation-data="findCitation"
					:paper-format-version-id="paperFormatVersionID"
					:reference-unique-id="referenceUniqueId"
					@enableCitationFormButton="isDisabled = false"
					@disableCitationFormButton="isDisabled = true" />

				<div class="row">
					<div class="col-6">
						<div class="d-grid">
							<router-link 
								:to="{
									name: 'OutlineReferencesCitations'
								}"
								class="btn btn-outline-danger">Cancel</router-link>
						</div>
					</div>
					<div class="col-6">
						<div class="d-grid">
							<button type="submit" class="btn btn-success" @click.prevent="btnUpdateCitation" :disabled="isDisabled">
								Update Citation
							</button>
						</div>
					</div>
				</div>

			</form>
			
		</div>
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import CitationForm from '@/modules/CitationForm';
import ErrS_Reroute from '@/services/app/error/reroute.js';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'OutlineCitationEdit',
	data() {
		return {
			isDisabled: true,
		}
	},
	computed:{
		findCitation(){
			// parse citation 
			let parsedCitation = '';
			try {
				parsedCitation = JSON.parse(this.$store.state.researchNotes.tempCitationData);
				// console.log(parsedCitation);
			} catch {
				return null;
			}

			return parsedCitation;
		},
		paperFormatVersionID(){
			// on load the paper hasn't loaded it's meta data yet
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
		referenceUniqueId(){
			if(this.findCitation){
				return this.findCitation.citationData.referenceUniqueID.toUpperCase();
			}
			
			return null;
		},

	},
	methods: {
		btnUpdateCitation(){
			// grab the temp citation data from citation form
			let newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);
			
			try {
				newCitationResult.citationData.citationUniqueID = this.$router.currentRoute.params.citationUniqueId.toUpperCase();
				newCitationResult.citationData.referenceUniqueID = this.findCitation.citationData.referenceUniqueID.toUpperCase();
			} catch {
				ErrS_Reroute({
					pushToName: 'OutlineReferencesCitations'
				});

				return false;	// on catch stop
			}
			
			// clear tempCitationData now that you cloned it
			this.$store.commit('paperEdit/citations/SET_TEMP_CITATION_DATA', {});

			// Update an Outline Citation
			let $citationElement = window.$ckPaperOutline.document.findOne('span[data-citation-unique-id="' + newCitationResult.citationData.citationUniqueID + '"]');

			if($citationElement){
				let citationDataBase64 = window.btoa(JSON.stringify(newCitationResult).replace(/[\u00A0-\u2666]/g, function(c) {
					return '&#' + c.charCodeAt(0) + ';';
				}));
				$citationElement.setAttribute('citation-data', citationDataBase64);
			}

			// save the outline here, the citation has been updated
			this.$nextTick(()=>{
				OS_SaveContent().then(()=>{
					this.$store.commit('researchNotes/SET_TEMP_CITATION_DATA', {});
					this.$router.push({
						name: 'OutlineReferencesCitations'
					}).catch(()=>{});
				});
			});
		},
	},
	components: {
		CitationForm,
		ToolDrawerTitle
	},
}
</script>
