var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "hanging-indent-instance word-break" }, [
      _c("a", {
        staticClass: "fake-widget fake-widget-reference-row",
        attrs: { href: "#", title: "Click to Edit Reference" },
        domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.btnOpenEditReference.apply(null, arguments)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("textarea", {
      staticClass: "d-none",
      attrs: { id: _vm.calcInstanceId, autocomplete: "off" },
      domProps: { value: _vm.annotationContentValue },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }