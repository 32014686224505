var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row mb-2" },
        [
          _c("h1", { staticClass: "float-start me-3 mb-0" }, [
            _vm._v("\n\t\t\tPapers\n\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown float-start me-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("ul", { staticClass: "dropdown-menu" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item hover hover-success",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnOpenCreateModalResearchPaper.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tResearch Paper\n\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item hover hover-success",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnOpenCreateModalDiscussionPost.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tDiscussion Post\n\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item hover hover-success",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnOpenCreateModalReferenceList.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tReference List\n\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item hover hover-success",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnOpenCreateModalAnnotatedBibliography.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tAnnotated Bibliography\n\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.$store.state.app.showBetaFeatures
            ? _c(
                "button",
                {
                  staticClass: "float-start me-3 btn btn-outline-warning",
                  attrs: { title: "Rebuild Papers", type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnRebuildGrid.apply(null, arguments)
                    },
                  },
                },
                [_c("span", { staticClass: "svg icons8-undo" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("grid-search", {
            staticClass: "ms-auto col-4",
            attrs: { "placeholder-text": "Search Papers" },
            on: { "update-grid": _vm.calcGridData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            ref: "divPaperListWrp",
            staticClass: "col-12 min-height overflow-y-auto position-relative",
          },
          [
            _vm.filteredGridData && _vm.filteredGridData.length > 0
              ? _c(
                  "table",
                  {
                    key: "tableHasPaperData",
                    staticClass:
                      "table table-borderless entity-grid table-fixed-header",
                  },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Title",
                              colspan: "2",
                              "sort-key": "title",
                              "entity-id": _vm.entityId,
                              "is-local-storage": true,
                              "is-title": true,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("th", [_vm._v("Type")]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("th", [_vm._v("Class")]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Due Date",
                              "sort-key": "dueDateDisplay",
                              "entity-id": _vm.entityId,
                              "is-local-storage": true,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Modified",
                              "set-width": "175px",
                              "sort-key": "lastModifiedDate",
                              "entity-id": _vm.entityId,
                              "is-local-storage": true,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "30px" } }, [
                            _vm._v("\n\t\t\t\t\t\t\t \t"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.filteredGridData, function (paperData) {
                        return _c("paper-row", {
                          key: paperData.paperID,
                          attrs: {
                            "class-id": paperData.classID,
                            "paper-class-name": paperData.className,
                            "paper-description": paperData.description,
                            "paper-due-date": paperData.dueDateDisplay,
                            "paper-encrypted-id": paperData.encryptedID,
                            "paper-format-version-id":
                              paperData.paperFormatVersionID,
                            "paper-id": paperData.paperID,
                            "paper-is-complete": paperData.isComplete,
                            "paper-last-modified-date":
                              paperData.lastModifiedDate,
                            "paper-start-date": paperData.startDate,
                            "paper-title": paperData.title,
                            "paper-type": paperData.paperType,
                            context: "PaperList",
                            "loader-id": "PaperList",
                          },
                          on: { "update-grid": _vm.calcGridData },
                        })
                      }),
                      1
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    key: "divNoPaperData",
                    staticClass: "text-muted text-center mt-5",
                  },
                  [_c("p", [_vm._v("No papers found.")])]
                ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("route-modal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle btn-success",
        attrs: {
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "svg bi-plus-lg me-1" }),
        _vm._v(" Create a new\n\t\t\t"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }