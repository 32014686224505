var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowRunningHead
    ? _c(
        "div",
        [
          _c(
            "router-link",
            {
              staticClass: "fake-widget clearfix",
              attrs: {
                title: "Click to Edit your Title Page",
                to: { name: "EditorTitlePage" },
              },
            },
            [
              _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
              _vm.$enums.Format.APA7
                ? _c(
                    "p",
                    {
                      key: "spanRunningHeadLeftApa7",
                      staticClass: "float-start",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.$store.state.paperEdit.meta.RunningHead) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "float-end" }, [
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                  _vm.$enums.Format.APA7 ||
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                  _vm.$enums.Format.Turabian9
                  ? _c("span", { key: "spanRunningHeadRightApa7Turabian9" }, [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.pageNumberOutput) + "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                _vm.$enums.Format.MLA9
                  ? _c("span", { key: "spanRunningHeadRightMla9" }, [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.calcLastName) +
                          " " +
                          _vm._s(_vm.pageNumberOutput) +
                          "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }