var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "nodal" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "nodal-mask",
      },
      [
        _c(
          "transition",
          { attrs: { name: "nodal", mode: "out-in", appear: "" } },
          [
            _c(
              "div",
              { staticClass: "nodal-body" },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "hotkey",
                        rawName: "v-hotkey",
                        value: _vm.keymap,
                        expression: "keymap",
                      },
                    ],
                    staticClass: "btn-close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeModal.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "svg bi-x-lg",
                      attrs: { role: "button", "aria-label": "Close" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("router-view"),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }