<!--
- Review a Single Genesis Reference
-- as of 2023.09.06 this is a modified copy of ReferenceEdit
-- used only to "resave" a reference if there isn't a Turabian9 Value from the engine
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Review Reference"
				close-to-route-name="EditorAddFromLibrary" />
		</div>
		
		<div class="drawer-body">
			<edit-reference-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import EditReferenceBody from '@/modules/EditReferenceBody';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditorReviewReference',
	components: {
		EditReferenceBody,
		ToolDrawerTitle
	}
}
</script>
