// Reminders

// import _forEach from 'lodash/forEach';
// import _has from 'lodash/has';
// import _isEmpty from 'lodash/isEmpty';

export default {
	namespaced: true,

	state: {
		list: [],		// Array: list of reminders for this customer, each entry is a single Reminder Object
	},
	
	mutations: {
		ADD_TO_LIST(state, value){
			state.list.push(value);
		},
		SET_LIST(state, value){
			state.list = value;
		},
	},
}
