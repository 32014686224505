var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tabDashboardWrp", staticClass: "d-grid" },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col" }, [_c("recent-papers")], 1),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [_c("class-list"), _vm._v(" "), _c("upcoming-work")],
        1
      ),
      _vm._v(" "),
      _c("route-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }