<!--
- Upcoming Work
-
-->

<template>
	<div class="col-6 position-relative">
		<div class="row">
			<div class="col">
				<h2 class="float-start">
					Upcoming Work
				</h2>

				<div class="dropdown float-end">
					<button class="btn dropdown-toggle btn-outline-success border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
						<span class="svg bi-plus-lg me-1" /> New Work
					</button>
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenWorkCreateModal(true)">
								To-Do
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenWorkCreateModal(false)">
								Assignment
							</a>
						</li>
					</ul>
				</div>

			</div>
		</div>

		<div class="row">
			<div class="col overflow-y-scroll" ref="tabDashboardUpcomingWorkListWrp">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header mb-0">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Name"
								colspan="2"
								sort-key="name"
								:is-title="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<sortable-grid-th 
								cell-text="Due Date"
								set-width="165px"
								sort-key="sortByDueDate" 
								:is-align-right="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />
						</tr>
					</thead>
					<tbody>
						<work-row
							v-for="workData in filteredGridData"
							:work-data-encrypted-id="workData.entityEncryptedID"
							:work-data-entity-id="workData.entityID"
							:work-data-due-date="workData.dueDate"
							:work-data-name="workData.name"
							:work-data-type="workData.workType"
							:key="workData.workID" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No upcoming work found.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import mixinResizer from '@/mixins/resizer';
import SortableGridTh from '@/components/SortableGridTh';
import WorkRow from '@/components/EntityGrid/WorkRow';

export default {
	name: 'UpcomingWork',
	mixins: [mixinResizer],
	data() {
		return {
			filteredGridData: [],

			sortOrderBy: 'sortByDueDate',
			sorts: {
				'name': 1,
				'sortByDueDate': 1,
			},

			rzRef: 'tabDashboardUpcomingWorkListWrp',
		}
	},
	methods: {
		btnOpenWorkCreateModal(isTodo){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'DashboardCreateWork',
				modalParams:{
					workType: (isTodo) ? '1' : '2',
				},
			});
		},
		
		calcGridData(isFirstLoad, classListResults){
			let _data = _cloneDeep(this.$store.state.work.list);
			let formattedWorkArray = [];

			// loop through each work item and prep for display
			_data.forEach((workData) =>{
				if(workData.isComplete){
					return false;
				}
				
				if(_has(workData, 'dueDate') && workData.dueDate) {
					workData.sortByDueDate = workData.dueDate;	// remove hh:mm:ss so you can sort down to the day
				} else {
					workData.sortByDueDate = '2000-01-01T00:00:00';
				}
				formattedWorkArray.push(workData);

			});//e:forEach
		
			if(this.sorts[this.sortOrderBy] === 1){
				this.filteredGridData = _orderBy(formattedWorkArray, [formattedWorkArray => formattedWorkArray[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				this.filteredGridData = _orderBy(formattedWorkArray, [formattedWorkArray => formattedWorkArray[this.sortOrderBy].toLowerCase()], ['desc']);
			}

			if(isFirstLoad){
				// first load from Sync - go to the next one in line
				this.emitter.emit('loadRecentPaperList', {
					classListResults: classListResults,
					upcomingWorkListResults: this.filteredGridData.length,
				});
			} else {
				this.$store.commit('loaders/REMOVE_ID', 'App');
			}

			this.$nextTick(()=>{
				this.emitter.emit('resizeWindow');
			});
		},//e:calcGridData

		updateGridSort($obj){
			this.sortOrderBy = $obj.sortKey;
			this.sorts = $obj.sorts;
			this.calcGridData();
		},
	},

	created() {
		this.emitter.on('loadUpcomingWorkList', ($opts)=>{
			this.calcGridData(true, $opts.classListResults);
		});

		// emits after a class is archived (i need to just do another /Sync call to make it go away)
		this.emitter.on('updateUpcomingWork', ()=>{
			AS_SyncData().then(()=>{
				this.calcGridData(false);
				this.emitter.emit('closeRouteModal')
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Work successfully created',
				});
			});
		});

	},
	components: {
		SortableGridTh,
		WorkRow
	},
	destroyed(){
		this.emitter.off('loadUpcomingWorkList');
	},
}
</script>
