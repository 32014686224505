<!--
- Close Warning
-- Fired when changes to a form (like Reference Create Step 2) is dirty and the route changes
-->

<template>
	<transition name="route-fade">
		<div v-if="showCloseWarning" class="close-warning">
			<tool-drawer-title :title="confirmTitle" :is-title-only="true" />

			<p>{{ confirmBody }}</p>
			<div class="d-grid gap-2">
				<button type="button" class="btn btn-outline-danger" @click.prevent="btnGoNextTo">
					{{ confirmGoNextText }}
				</button>
				<button type="button" class="btn btn-success" @click.prevent="showCloseWarning = false">
					Keep working
				</button>
			</div>
		</div>
	</transition>
</template>

<script>
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'CloseWarning',
	props:{
		closeId: {
			required: true,
			type: String,
		},
		confirmBody:{
			required: true,
			type: String,
		},
		confirmTitle:{
			required: true,
			type: String,
		},
		confirmGoNextText:{
			required: true,
			type: String,
		},
	},
	data() {
		return {
			nextTo: null,
			showCloseWarning: false,
		}
	},
	methods: {
		btnGoNextTo(){
			if(this.closeId === 'CloseEditorCreateStep3ThirdParty' || this.closeId === 'CloseOutlineCreateStep3ThirdParty'){
				this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');
			}
			this.nextTo();
		},
	},
	created() {
		this.emitter.on('showCloseId', ($opts) => {
			if($opts.closeId === this.closeId){
				this.showCloseWarning = true;
				this.nextTo = $opts.nextTo;
			}
		});
	},
	components: {
		ToolDrawerTitle
	},
	destroyed(){
		this.emitter.off('showCloseId');
	},
}
</script>
