<!--
- Cart Form Label and Input
-- Easier to start over without all the name maker bloat
-->

<template>
	<div class="form-input-cart">
		<label
			:class="['form-label', {'fst-italic':isInvalidShown}]"
			v-if="showLabel"
			v-html="calcLabelOutput" />

		<input
			autocomplete="off"
			ref="inputElement"
			:class="['form-control', {'is-invalid': isInvalidShown}]"	
			:disabled="isDisabled"
			:id="fullId"
			:maxlength="maxLength"
			:placeholder="placeholder"
			:role="role"
			:type="localType"
			:value="value"
			@blur="blurInput($event.target.value)"
			@input="typeInput($event.target.value)" />

		<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
			{{ invalidErrorMessage }}
		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputCart',
	mixins: [mixinId],
	props:{
		fieldId: {
			type: String,
		},
		formId: {
			type: String,
		},
		helpText: {
			type: String,
		},
		isDisabled: {
			default: false,
			type: Boolean,
		},
		isNumericOnly: {
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		maxLength: {
			type: Number,
		},
		
		placeholder:{
			default: '',
			type: String
		},
		role: {
			type: String,
		},
		showLabel:{
			default: true,
			type: Boolean,
		},
		type:{
			default: 'text',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			isShowSuggestion: false,
			localType: null,
		}
	},
	computed:{
		calcLabelOutput(){
			if(
				this.validateEnum === config.enums.Validation.EMAIL_REQUIRED || 
				this.validateEnum === config.enums.Validation.NUMERIC_REQUIRED ||
				this.validateEnum === config.enums.Validation.REQUIRED
			){
				return this.label + '*';
			}
			// if(this.thisValidation === )
			return this.label;
		},
		fullId(){
			if(this.fieldId){
				return this.fieldId;
			}
			
			return this.safeId;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},

		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.fullId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		blurInput(value){
			AS_ValidateForm({
				singleFieldId: this.fullId
			}).then(()=>{
				this.$emit('blur-input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:blurInput

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			// let _value;
			// 
			// 	_value = value.replace(/[^0-9]/g,'');
			// } else {
			// 	_value = value
			// }
			// console.log(_value);

			this.$emit('input', value);
		},//e:typeInput
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.fullId,
				validateEnum: this.validateEnum,
			})
		}
		
		this.$nextTick(()=>{
			if(this.$refs.inputElement){
				this.$refs.inputElement.addEventListener("keypress", (evt) => {
					if(this.isNumericOnly){
						// https://stackoverflow.com/questions/19966417/prevent-typing-non-numeric-in-input-type-number
						if (evt.which < 48 || evt.which > 57){
							evt.preventDefault();
						}
					}
				});
			}
		});//e:nextTick

		this.localType = this.type;
	},
}
</script>
