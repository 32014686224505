var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3 element-row" },
    [
      _c(
        "div",
        {
          class: [
            "dropstart",
            { "archived-reference": _vm.referenceObject.isArchived },
          ],
        },
        [
          _c("a", {
            staticClass: "svg bi-three-dots float-end",
            attrs: {
              href: "#",
              "data-bs-toggle": "dropdown",
              role: "button",
              "aria-expanded": "false",
              "aria-label": "More Options",
            },
          }),
          _vm._v(" "),
          _c("p", {
            directives: [
              { name: "add-blank-target", rawName: "v-add-blank-target" },
            ],
            ref: "referenceRowOutput",
            staticClass: "mb-1 reference-text word-break",
            domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleIsOpen.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            !_vm.referenceObject.isArchived
              ? _c(
                  "li",
                  { key: "liEditReference" },
                  [
                    _c(
                      "router-link",
                      {
                        class: ["dropdown-item hover hover-primary"],
                        attrs: {
                          to: {
                            name: "EditorEditReference",
                            params: {
                              referenceUid: _vm.upperCaseRid,
                            },
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "svg bi-pencil-fill" }),
                        _vm._v("\n\t\t\t\t\t\tEdit Reference\n\t\t\t\t"),
                      ]
                    ),
                  ],
                  1
                )
              : _c("li", { key: "liRestoreReference" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item hover hover-primary",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnRestoreReference.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "svg bi-arrow-counterclockwise",
                      }),
                      _vm._v("\n\t\t\t\t\tRestore this Reference\n\t\t\t\t"),
                    ]
                  ),
                ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-danger",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeRefFromPaper.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "svg bi-x-lg" }),
                  _vm._v("\n\t\t\t\t\tRemove Reference\n\t\t\t\t"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item hover hover-success",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.copyReferenceText.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "svg bi-copy" }),
                  _vm._v("\n\t\t\t\t\tCopy as text\n\t\t\t\t"),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.referenceObject.isArchived
        ? _c("p", { staticClass: "ms-3 mb-1 archived-reference-help-text" }, [
            _vm._v("\n\t\tThis reference has been archived.\n\t"),
          ])
        : _c(
            "div",
            { staticClass: "g-two-col-row" },
            [
              _c(
                "router-link",
                {
                  class: ["btn btn-sm btn-hover btn-hover-success ms-2"],
                  attrs: {
                    to: {
                      name: "EditorCitationAdd",
                      params: {
                        referenceUid: _vm.upperCaseRid,
                      },
                    },
                  },
                },
                [
                  _c("span", { staticClass: "svg bi-plus-lg" }),
                  _vm._v(" Add Citation\n\t\t"),
                ]
              ),
              _vm._v(" "),
              _vm.hasCitations
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-hover btn-hover-success",
                      attrs: { role: "button", type: "button" },
                      on: { click: _vm.toggleIsOpen },
                    },
                    [
                      _vm.isOpen
                        ? _c("span", [_vm._v("Hide Citations")])
                        : _c("span", [_vm._v("Show Citations")]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "slide-up-down",
        { attrs: { active: _vm.isOpen } },
        _vm._l(_vm.referenceObject.citations, function (citationObject) {
          return _c("citation-row", {
            key: citationObject.citationData.citationUniqueID,
            attrs: { "citation-object": citationObject },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }