<!--
- Generic Modal > Reminder Create 
-->

<template>
	<generic-modal-wrap modal-id="ReminderCreate">
		<h2 class="nodal-title">
			Create Reminder
		</h2>
		<p>* Required fields</p>

		<form>
			<form-input
				label="Reminder Name*"
				placeholder="(required)"
				v-model="reminderName"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED" />

			<form-datepicker
				id="CR_dueDate"
				label="Due Date*"
				:form-id="zFormId"
				:is-clearable="true"
				validate-is-all-date
				validate-is-required
				@date-set="dateDueSet" />
			
			<form-entity-association
				:entity-type-id="$enums.Entity.REMINDER"
				@entity-selected="entitySelected" />
			
			<br />

			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="$store.commit('modals/CLOSE', 'ReminderCreate')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="createReminder">
							Create Reminder
						</button>
					</div>
				</div>
			</div>
		</form>
	</generic-modal-wrap>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import dateFormat from "dateformat";
import FormDatepicker from '@/components/form/Datepicker';
import FormEntityAssociation from '@/components/form/EntityAssociation';
import FormInput from '@/components/form/Input';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalReminderCreate',
	data() {
		return {
			associatedEntityId: '0',
			associatedEntityTypeId: '0',
			dateDue: '',
			reminderName: '',
			zFormId: 'formCreateReminder',
		}
	},
	methods:{
		createReminder(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				let sendData = {
					"name": this.reminderName,
					"dueDate": this.dueDate,
					"isComplete": false,
					"associatedEntityTypeId": this.associatedEntityTypeId,
					"associatedEntityId": this.associatedEntityId
				}
				//console.log(sendData);

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					responseType: 'json',
					url: 'v4/Reminder',
				}).then(()=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.$store.commit('modals/CLOSE', 'ReminderCreate')

					this.emitter.emit('globalToasterOpen',{
						textContent: 'Reminder successfully created',
					});
					
					this.emitter.emit('reloadReminderList');

				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
			
		},//e:createReminder

		entitySelected(entitySelectedValues){
			if(entitySelectedValues.associatedEntityTypeId === '0' || entitySelectedValues.associatedEntityId === '0'){
				// if one is zero, make them both zero
				this.associatedEntityTypeId = '0';
				this.associatedEntityId = '0';

			} else {
				this.associatedEntityTypeId = entitySelectedValues.associatedEntityTypeId;
				this.associatedEntityId = entitySelectedValues.associatedEntityId;
			}
			
			// console.log(this.associatedEntityTypeId);
			// console.log(this.associatedEntityId);
		},

		dateDueSet(newDate){
			this.dueDate = dateFormat(newDate, 'isoDateTime');
		},
	},
	mounted() {
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components:{
		FormDatepicker,
		FormEntityAssociation,
		FormInput,
		GenericModalWrap
	},
}
</script>