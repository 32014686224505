var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c(
      "section",
      {
        staticClass: "section-text pt-0",
        staticStyle: {
          background:
            "url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff",
        },
      },
      [
        _c(
          "main",
          {
            staticClass:
              "container-text container-text-login-box-wrp mt-0 pt-3",
          },
          [
            _c("div", { staticClass: "white-inset login-box-wrp" }, [
              _c("div", { staticClass: "inset-body" }, [
                _c("img", {
                  staticClass: "mx-auto d-block",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                    width: "140px",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "form",
                  [
                    _vm.isLoginError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tThe email or password provided is incorrect\n\t\t\t\t\t\t"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("form-input", {
                      attrs: {
                        label: "Email",
                        "form-id": "formLogin",
                        type: "email",
                        "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                    _vm._v(" "),
                    _c("form-password", {
                      attrs: {
                        label: "Password",
                        "form-id": "formLogin",
                        "validate-enum": _vm.$enums.Validation.REQUIRED,
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "link-forgot-password" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "hover-underline",
                            attrs: {
                              to: {
                                name: "ForgotPassword",
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\tForgot password?\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { staticClass: "d-grid gap-2" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              attrs: { type: "submit" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.btnLogin.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tLog In\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-outline-primary btn-block",
                              attrs: {
                                to: {
                                  name: "FreeTrialStart",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tStart a Free Trial\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h1", [_vm._v("PERRLA")]),
      _vm._v(" "),
      _c("p", [_vm._v("Write perfect papers from anywhere.")]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("\n\t\t\t\t\t\t\tLog in to your account\n\t\t\t\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }