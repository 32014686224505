<!--
- Subscription Expired
-- redirects from PO's MVC code
-- https://trello.com/c/o2VSr4ca/333-subscription-expired-page
-- as of 2021.6.3 this view won't be redirected to so it's bascially commented out for now
-->

<template>
	<div class="layout-pcom">
		<section class="section-text">
			<main class="container-text mt-2">
				<div>
					<h3>Subscription Expired</h3>
					
					<p>Uh oh! It looks like your subscription expired!</p>

					<p>We hope you&#39;ll choose to renew. Thanksgiving will be weird without you. Okay, it might have been weird with you... we don&#39;t know... we have to write this text in advance. The point is, the best way to avoid any future awkwardness is for you to renew. Just renew and we can go back to the way things were when we were both young and in love with each other.</p>

					<div class="row">
						<div class="col">
							<div class="d-grid">
								<button type="button" @click.prevent="btnGoToCart" class="btn btn-success">
									Renew PERRLA
								</button>
							</div>
						</div>
						<div class="col">
							<div class="d-grid">
								<router-link
									:to="{
										name:'Logout'
									}"
									class="btn btn-outline-primary">
									Logout
								</router-link>
							</div>
						</div>
					</div>

					<!-- <a href="#" class="text-center mt-5 d-block mb-5" @click.prevent="btnRefreshAccount">Already renewed? Click here to refresh PERRLA.</a> -->
				</div>
			</main>
		</section>
	</div>
</template>

<script>
// import AS_SyncData from '@/services/app/syncData';
import config from '@/config';

export default {
	name: 'SubscriptionExpired',
	methods: {
		// btnRefreshAccount(){
		// 	AS_SyncData().then(()=>{
		// 		if(config.isLive){
		// 			window.location = 'https://perrlacomplete.com/App/#/settings/my-profile';
					
		// 		} else if(config.isTest){
		// 			window.location = 'https://testonline.perrla.com/App/#/settings/my-profile';

		// 		} else {
		// 			this.$router.push({
		// 				name: 'MyProfile'
		// 			}).catch(()=>{});
		// 		}
		// 	});
		// },//e:btnRefreshAccount

		btnGoToCart(){
			// console.log('btnGoToCart');
			this.$store.dispatch('cart/getNewCart').then(()=>{
				this.$router.push({
					name: 'SubscriptionSettings',
				}).catch(()=>{});
			});
		},//e:btnGoToCart

	},
	mounted() {
		// a user ends up here if they log in with an expired acount from LoginToAccount
		// this.$store.commit('loaders/ADD_ID', 'App');
		
		document.documentElement.setAttribute('data-bs-theme', 'light')
		document.body.classList.remove('theme-serrena');
		this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);
		this.$store.commit('loaders/REMOVE_ID', 'App');

		// // dont stop here, automatically get a new cart and go
		// this.$store.dispatch('cart/getNewCart').then(()=>{
		// 	this.$router.push({
		// 		name: 'SubscriptionSettings',
		// 	}).catch(()=>{});
		// });
	},
}
</script>
