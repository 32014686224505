var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "SpecialCharacters" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tInsert Special Character\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "character-list" },
        _vm._l(_vm.specialCharacterList, function (character) {
          return _c("li", { key: character }, [
            _c("a", {
              attrs: { href: "#", title: "Click to insert" },
              domProps: { innerHTML: _vm._s(character) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.insertCharacter(character)
                },
              },
            }),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }