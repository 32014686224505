var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "AddPaymentMethod" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tAdd Payment Method\n\t"),
      ]),
      _vm._v(" "),
      _c("modal-add-payment-method", { attrs: { "is-modal": true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }