<!--
- Citation Edit > Footnote Editor > Ribbon
-->

<template>
	<div class="rb-wrp clearfix">
		<ck-toolbar editor-type="footnote" />
	</div>
</template>

<script>
import CkToolbar from '@/components/CkToolbar';

export default {
	name: 'RibbonComponent',
	computed:{
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
	},
	components:{
		CkToolbar,
	},
}
</script>
