/*
- Appendices
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.appendices.x
*/

export default {
	namespaced: true,
	
	state:{
		inPaper: [],		// Array: each entry is an object for Appendix Data 
	},//e:state

	mutations: {
		ADD_TO_PAPER(state, objValue){
			state.inPaper.push(objValue);
		},
		CLEAR_IN_PAPER(state){
			state.inPaper = [];
		},
		IN_PAPER(state, value){
			state.inPaper = value;
		},
		REMOVE_FROM_PAPER_AT_INDEX(state, findAppendixIndex){
			state.inPaper.splice(findAppendixIndex, 1);
		},
		UPDATE_AT_INDEX(state, $opts){
			state.inPaper[$opts.findAppendixIndex].content = $opts.content;
		}
	},//e:mutations
}
