var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        mouseenter: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c(
        "nav",
        {
          staticClass: "left-menu left-menu-top",
          class: {
            "left-menu-closed": _vm.$root.isNavSmall,
            "left-menu-hover": _vm.isHover,
          },
        },
        [
          _c("div", { staticClass: "left-menu-inner" }, [
            _c("ul", { attrs: { id: "navWrp" } }, [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: [{ current: _vm.routeNameMatch === "Dashboard" }],
                      attrs: { to: { name: "Dashboard" }, tabindex: "-1" },
                    },
                    [
                      _c("span", { staticClass: "svg icons8-home-page" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-title" }, [
                        _vm._v("Home"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm.$store.state.app.showBetaFeatures
                ? _c(
                    "li",
                    { key: "liCalendarBeta" },
                    [
                      _c(
                        "router-link",
                        {
                          class: [
                            { current: _vm.routeNameMatch === "Calendar" },
                          ],
                          attrs: { to: { name: "Calendar" }, tabindex: "-1" },
                        },
                        [
                          _c("span", {
                            staticClass: "svg bi bi-calendar3 text-warning",
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "nav-title text-warning" },
                            [_vm._v("Calendar")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: [{ current: _vm.routeNameMatch === "ClassList" }],
                      attrs: { to: { name: "ClassList" }, tabindex: "-1" },
                    },
                    [
                      _c("span", { staticClass: "svg icons8-class" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-title" }, [
                        _vm._v("Classes"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: { current: _vm.routeNameMatch === "PaperList" },
                      attrs: { to: { name: "PaperList" }, tabindex: "-1" },
                    },
                    [
                      _c("span", { staticClass: "svg icons8-document" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-title" }, [
                        _vm._v("Papers"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: {
                        current: _vm.routeNameMatch === "ReferenceList",
                      },
                      attrs: { to: { name: "ReferenceList" }, tabindex: "-1" },
                    },
                    [
                      _c("span", { staticClass: "svg icons8-books" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-title" }, [
                        _vm._v("References"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      class: [
                        { current: _vm.routeNameMatch === "ReminderList" },
                      ],
                      attrs: { to: { name: "ReminderList" }, tabindex: "-1" },
                    },
                    [
                      _c("span", { staticClass: "svg icons8-notification" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "nav-title" }, [
                        _vm._v("Reminders"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "nav",
            {
              staticClass: "left-menu left-menu-bottom",
              class: {
                "left-menu-closed": _vm.$root.isNavSmall,
                "left-menu-hover": _vm.isHover,
              },
            },
            [
              _c("ul", [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        class: {
                          current: _vm.routeNameMatch === "ContactSupport",
                        },
                        attrs: {
                          to: { name: "ContactSupport" },
                          tabindex: "-1",
                        },
                      },
                      [
                        _c("span", { staticClass: "svg icons8-speech-bubble" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "nav-title" }, [
                          _vm._v("Contact Support"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: { href: "/Calendar/Index", title: "Calendar", tabindex: "-1" },
        },
        [
          _c("span", { staticClass: "svg icons8-calendar" }),
          _vm._v(" "),
          _c("span", { staticClass: "nav-title" }, [_vm._v("Calendar")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "/Assignments/Index",
            title: "Assignments",
            tabindex: "-1",
          },
        },
        [
          _c("span", { staticClass: "svg icons8-edit-property" }),
          _vm._v(" "),
          _c("span", { staticClass: "nav-title" }, [_vm._v("Assignments")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "/ToDos/Index", title: "To-Dos", tabindex: "-1" } },
        [
          _c("span", { staticClass: "svg icons8-to-do" }),
          _vm._v(" "),
          _c("span", { staticClass: "nav-title" }, [_vm._v("To-Dos")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com",
            title: "Help Center",
            tabindex: "-1",
          },
        },
        [
          _c("span", { staticClass: "svg icons8-help" }),
          _vm._v(" "),
          _c("span", { staticClass: "nav-title" }, [_vm._v("Help Center")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }