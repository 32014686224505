var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", [_vm._v("Countdown Timer")]),
      _vm._v(" "),
      _c("div", { staticClass: "countdown-wrapper" }, [
        _c("ul", [
          _c("li", [_c("span", { attrs: { id: "minutes" } })]),
          _vm._v(" "),
          _c("li", [_c("span", [_vm._v(":")])]),
          _vm._v(" "),
          _c("li", [_c("span", { attrs: { id: "seconds" } })]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }