<!--
- Paper Edit > Organize Tab > Research Notes > Create Reference Step 4
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>

		<create-reference-step-4-third-party-body :is-drawer="false" />
	</div>
</template>

<script>
import CreateReferenceStep4ThirdPartyBody from '@/modules/CreateReferenceStep4ThirdPartyBody'

export default {
	name: 'PaperTabResearchCreateReferenceStep4ThirdParty',
	components: {
		CreateReferenceStep4ThirdPartyBody,
	}
}
</script>
