<!--
- Cart > Transaction Preview > PromoCode
-- breaking out into it's own just for ease of use
-->

<template>
	<div>
		<div v-if="calcPromoCodeOutput" key="divDiscountApplied">
			<div class="row">
				<div class="col">
					<h4 class="text-body">
						Promo Code
					</h4>
				</div>
				<div class="col">
					<h4 :class="calcDiscountTextClasses">
						{{ calcDiscountDisplay }}
					</h4>
				</div>
			</div>
			<p class="org-size text-primary mt-2">
				{{ calcPromoCodeOutput }}
			</p>
		</div>
		<div v-else key="divNoDiscountApplied">
			<h4 class="text-body">
				Promo Code
			</h4>

			<div class="help-text ms-3 mb-3">
				PERRLA uses this field for Referral Rewards and for group sales. Be sure to click Apply to use your code.
			</div>

			<form>
				<div class="row">
					<div class="col-6">
						<input
							autocomplete="off"
							class="form-control"
							placeholder="Enter code here"
							type="text"
							v-model="promoCodeEntry" /> 
					</div>
					<div class="col-6">
						<button
							class="btn btn-outline-primary border-0"
							type="submit"
							@click.prevent="btnApplyPromoCodeEntry">
							Apply
						</button>
					</div>
				</div>
			</form>
		</div><!-- divDiscountApplied -->
	</div>
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import currencyFormat from '@/helpers/currency';

export default {
	name: 'PromoCode',
	data() {
		return {
			promoCodeEntry: '',	// this is just what is entered in the text field, this isn't really accepted yet
		}
	},
	computed:{
		calcDiscountDisplay(){
			let returnText = '';

			if(this.$store.state.cart.referringCustomerId === 0){
				returnText = '-';
				returnText += currencyFormat({
					value: this.$store.state.cart.discount
				});
			} else {
				returnText = '3 Months Added';				
			}
			return returnText;
			
		},
		calcDiscountTextClasses(){
			let returnArray = ['text-end'];
			if(this.$store.state.cart.referringCustomerId === 0){
				returnArray.push('text-danger');
			} else {
				returnArray.push('text-success');
			}
			return returnArray;
		},
		calcPromoCodeOutput(){
			let returnText = '';

			if(_isEmpty(this.$store.state.cart.promotionalCode)){
				if(this.$store.state.cart.referringCustomerId !== 0){
					returnText = '3FREE-' + this.$store.state.cart.referringCustomerId + ' applied!';
				}
			} else {
				returnText = this.$store.state.cart.promotionalCode.code + ' applied!';
				
				// calculate a promo code - just in case
				if(this.$store.state.cart.promotionalCode.codeType === 1 && this.$store.state.cart.promotionalCode.discountPercent !== 0){
					// apply a percent
					returnText += ' (' + this.$store.state.cart.promotionalCode.discountPercent + '%';
				} else if(this.$store.state.cart.promotionalCode.codeType === 3 && this.$store.state.cart.promotionalCode.dollarDiscount !== 0){
					// apply an amount
					if(this.$store.state.cart.promotionalCode.dollarDiscount !== 0){
						returnText += ' ($' + this.$store.state.cart.promotionalCode.dollarDiscount;
					}
				}
			
				returnText += ' discount)';
			}
			return returnText;
		},
		
	},
	methods: {
		btnApplyPromoCodeEntry(){
			this.$store.commit('loaders/ADD_ID', 'App');

			// assemble the current cart object to pass to the Promo Code Api
			this.$store.dispatch('cart/assembleObject').then((cartObject)=>{
				ApiS_MakeCall({
					body: cartObject,
					ignoreAuth: true,
					method: 'POST',
					params: {
						code: this.promoCodeEntry
					},
					responseType: 'json',
					url: 'v4/Purchase/PromoCode',
				}).then((responseDataNewCartObject)=>{
					// console.log('responseDataNewCartObject');
					// console.log(responseDataNewCartObject);

					if(this.promoCodeEntry.toUpperCase().includes('3FREE')){
						// Referral Rewards
						this.$store.commit('cart/SET_REFERRING_CUSTOMER_ID', responseDataNewCartObject.referringCustomerID);

					} else {
						// Discount Amount
						// check in what comes back from the API call - it should be the whole cart object
						this.$store.commit('cart/SET_PROMOTIONAL_CODE', responseDataNewCartObject.promotionalCode);
						this.$store.commit('cart/SET_REFERRING_CUSTOMER_ID', 0);	// force set to zero just incase

						this.$store.commit('cart/SET_DISCOUNT', Math.round(responseDataNewCartObject.discount * 100) / 100);
						this.$store.commit('cart/SET_GRAND_TOTAL', Math.round(responseDataNewCartObject.grandTotal * 100) / 100);
						this.$store.commit('cart/SET_SUBTOTAL', Math.round(responseDataNewCartObject.subTotal * 100) / 100);
						this.$store.commit('cart/SET_SUBTOTAL_DISPLAY', Math.round(responseDataNewCartObject.subTotal * 100) / 100);
					}

					this.$store.dispatch('cart/updateCartTotals').then(()=>{
						this.$store.commit('loaders/REMOVE_ID', 'App');
					});
				
				}).catch((error)=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});
			
			});//e:dispatch:cart/assembleObject
			
		},//e:btnApplyPromoCodeEntry
	},
}
</script>
