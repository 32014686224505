<!--
- Module > Convert Reference Step 1 > Body
-
-->

<template>
	<div>
		<div v-if="isConvertTypeAvailable" key="divYesConvertType">
			<p class="org-size">
				Step 1 of 2: Check the existing data
			</p>

			<section class="mb-4 sticky-top pt-2">
				<h3>Original Reference</h3>
				<p class="org-size font-reference" v-add-blank-target v-html="value" />
			</section>

			<div class="alert alert-info">
				<span class="svg bi-check-circle-fill float-start" />
				<p class="ps-3">
					Commonly used fields are outlined in blue.
				</p>
			</div>

			<reference-types
				v-if="Object.keys(editReference).length != 0" :reference-type-id="conversionResult.type"
				:reference-rules-prop="conversionResult.data" />

			<div class="mt-2 row justify-content-between">
				<div class="col">
					<div class="d-grid" v-if="$router.currentRoute.meta.module === $enums.Module.PAPER_EDIT" key="divModulePaperEdit">
						<router-link
							v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR"
							:to="{name:'EditorAddFromLibrary'}"
							class="btn btn-outline-primary"
							key="routerLinkTabEditor">Back</router-link>
						<router-link
							v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE"
							:to="{name:'OutlineAddFromLibrary'}"
							class="btn btn-outline-primary"
							key="routerLinkTabOutline">Back</router-link>
						<button
							v-else
							class="btn btn-outline-primary"
							key="routerLinkTabElse"
							type="button"
							@click.prevent="moduleModalBack">
							Back
						</button>
					</div>
					<div class="d-grid" v-else key="divModuleElse">
						<button type="button" class="btn btn-outline-primary" @click.prevent="moduleModalBack">
							Back
						</button>
					</div>
				</div>
				<div class="col text-end">
					<div class="d-grid">
						<button type="button" class="btn btn-success" @click.prevent="generateReference">
							Next
						</button>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!isConvertTypeAvailable" key="divNoConvertType">
			<h3>Problem updating this reference to APA 7</h3>
			<p>Unfortunately, not every single APA 6 reference type from our legacy software can be upgraded to the new APA 7th Edition reference engine.</p>
			<p>While you may not be able to update this reference, you should be able to recreate it more easily than before. If the work has a DOI or ISBN, you can search for it directly when creating the new reference.</p>
			<p>If you have questions or need help, our Support Team is more than happy to help! You can reach them at <a href="mailto:support@perrla.com?subject=Converting%20a%20legacy%20reference">support@perrla.com</a>.</p>
		
			<div class="mt-2 justify-content-between">
				<div v-if="isDrawer" key="divIfDrawer">
					<div class="d-grid">
						<router-link
							v-if="$router.currentRoute.meta.tab === $enums.Tab.EDITOR"
							:to="{name:'EditorAddFromLibrary'}"
							class="btn btn-outline-primary"
							key="routerLinkTabEditor">
							Back
						</router-link>
						<router-link
							v-else-if="$router.currentRoute.meta.tab === $enums.Tab.OUTLINE"
							:to="{name:'OutlineAddFromLibrary'}"
							class="btn btn-outline-primary"
							key="routerLinkTabOutline">
							Back
						</router-link>
					</div>
				</div>
				<div v-else class="d-grid">
					<button class="btn btn-outline-primary" @click.prevent="moduleModalBack" type="button">
						Back
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import conversionEngine from '@/services/reference/LegacyConversions/LegacyConversion';
import ReferenceTypes from '@/components/reference-rules-genesis/Types';

export default {
	name: 'ConvertReferenceStep1Body',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			isConvertTypeAvailable: true,
		}
	},
	computed:{
		conversionResult(){
			// don't do anything if editReference is empty
			if(Object.keys(this.editReference).length === 0){
				return {
					// type: 0,
					// data: {},
				};
			}
			let conversionResultReturn = conversionEngine.convert(this.editReference.legacyReferenceData);
		
			// some pending types don't have an easy convert feature - show a generic screen if so
			if(conversionResultReturn.type === 0){
				this.isConvertTypeAvailable = false;
			}

			this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', conversionResultReturn.data);
			this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', conversionResultReturn.type);

			return conversionResultReturn;
		},
		editReference(){
			return this.$store.state.paperEdit.editReference
		},
		value(){
			// APA6 references have a value property, no JSON parse needed
			return this.editReference.value;
		}
	},
	methods: {
		generateReference(){
			this.$store.dispatch('referenceRules/generateReferencePreview').then(()=>{
				this.$store.dispatch('referenceRules/generateReference');
			});
		},//e:generateReference

		moduleModalBack(){
			this.emitter.emit('closeRouteModal');
		},
	},
	components: {
		ReferenceTypes,
	}
}
</script>



<style lang="scss" scoped>
	@import "./../assets/styles/vars_perrla.scss";
	@include color-mode(dark) {
		.sticky-top {
			background: $dark-body-bg;
		}	
	}
	.sticky-top {
		background: $body-bg;
		border-bottom: 1px solid $gray-500;
		position: sticky;
		top: 0;
	}
</style>
