var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: {
            title: "Create a reference",
            "close-to-route-name": "EditorReferencesCitations",
          },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "org-size d-block" }, [
          _vm._v("\n\t\t\tStep 1 of 3: Select a reference type\n\t\t"),
        ]),
        _vm._v(" "),
        _c(
          "nav-tabs",
          { staticClass: "mb-2" },
          [
            _c(
              "nav-tab",
              {
                attrs: {
                  to: {
                    name: "EditorCreateReferenceStep1",
                    params: { openReferenceTab: "common" },
                  },
                },
              },
              [_vm._v("\n\t\t\t\tCommon\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "nav-tab",
              {
                attrs: {
                  to: {
                    name: "EditorCreateReferenceStep1",
                    params: { openReferenceTab: "all" },
                  },
                },
              },
              [_vm._v("\n\t\t\t\tAll types\n\t\t\t")]
            ),
            _vm._v(" "),
            _vm.$store.state.references.recentTypeIds.length > 0
              ? _c(
                  "nav-tab",
                  {
                    attrs: {
                      to: {
                        name: "EditorCreateReferenceStep1",
                        params: { openReferenceTab: "recent" },
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tRecent\n\t\t\t")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.$route.params.openReferenceTab === "common"
          ? _c(
              "div",
              { key: "searchThirdParty" },
              [
                _c("reference-search-third-party", {
                  attrs: { "is-drawer": true, "is-first-search": true },
                }),
              ],
              1
            )
          : _vm.$route.params.openReferenceTab === "all"
          ? _c(
              "div",
              { key: "searchAll" },
              [
                _c("reference-type-search", {
                  attrs: { "ref-types": _vm.refTypes },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("hr", { staticClass: "mt-2 mb-0" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "drawer-body" },
      [_c("create-reference-step-1-body", { attrs: { "is-drawer": true } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }