<!--
- Outline Tab > Research Notes Drawer > Reference Row
-->

<template>
	<div class="mb-3">
		<p class="reference-text word-break" v-add-blank-target v-html="referenceObject.displayValue" />

		<div class="row" v-if="searchTerm === ''">
			<div class="col">
				<div class="d-grid" v-if="filteredResearchNotesForThisReference && filteredResearchNotesForThisReference.length > 0" key="btnExpand">
					<button
						class="btn btn-sm btn-hover btn-hover-success"	
						type="button"
						@click.prevent="toggleIsExpanded">
						<span v-if="isExpanded" key="hideExpanded">Hide</span>
						<span v-else key="showExpanded">Show</span> research notes
					</button> 
				</div>
				
				<p class="text-muted ms-3" v-else key="elseNoExpand">
					Use the Research Tab to create research notes for this reference.
				</p>
				
			</div>
		</div><!--row-->

		<transition name="route-fade">
			<div
				v-if="isExpanded || searchTerm != ''"
				class="mt-2"
				key="researchNotesExpanded">
				
				<div v-if="filteredResearchNotesForThisReference && filteredResearchNotesForThisReference.length > 0">
					<research-note-row
						v-for="researchNoteData in filteredResearchNotesForThisReference"
						:key="researchNoteData.genesisResearchNoteUniqueId"
						:research-note-citation="researchNoteData.citation"
						:research-note-citation-output="researchNoteData.citationOutput"
						:research-note-genesis-reference-id="researchNoteData.genesisReferenceId"
						:research-note-genesis-research-note-id="researchNoteData.genesisResearchNoteId"
						:research-note-genesis-research-note-unique-id="researchNoteData.genesisResearchNoteUniqueId"
						:research-note-reference-unique-id="researchNoteData.referenceUniqueID"
						:research-note-text="researchNoteData.note" />
				</div>

				
			</div><!--researchNotesExpanded-->
		</transition>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import config from '@/config';
import ResearchNoteRow from '@/modules/ResearchNotes/RowView';
import RNS_CalcSortValue from '@/services/paper/researchNotes/calcSortValue';

export default {
	name: 'EditorResearchNotesReferenceRow',
	props:{
		referenceObject:{
			required: true,
			type: Object,
		},
		searchTerm:{
			default: '',
			type: String,
		},
		sortByEnum:{
			default: config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
			type: Number,
		},
	},
	data() {
		return {
			filteredGridData: [],	// research notes under this reference
			isExpanded: false,
		}
	},
	computed:{
		filteredResearchNotesForThisReference(){
			let searchedArray = [];

			let filterResearchNotesByReferenceId = this.$store.state.researchNotes.list.filter((researchNoteData)=>{
				return researchNoteData.referenceUniqueID.toUpperCase() === this.referenceObject.referenceUniqueID.toUpperCase();
			});

			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();
			
			if(_searchTerm){
				filterResearchNotesByReferenceId.forEach((researchNoteData)=>{
					if(researchNoteData.note.toLowerCase().includes(_searchTerm)){
						// search term found
						searchedArray.push(researchNoteData);
					}
				});//e:forEach

			} else {
				searchedArray = filterResearchNotesByReferenceId;

			}

			switch(this.sortByEnum){
				case config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST:
					return searchedArray.reverse();
				case config.enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST:
					return searchedArray;
				case config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST:
				case config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST:					
					// calculate a sort by score
					searchedArray = _cloneDeep(searchedArray); // clone so i can add a sortby score below

					// sort by citation location
					searchedArray.forEach((researchNoteData)=>{
						// console.log(researchNoteData)
						let sortByScore = 0;

						let parsedCitationData = '';
						if(researchNoteData.citation !== ''){
							parsedCitationData = JSON.parse(researchNoteData.citation);
						}
						// console.log(parsedCitationData.citationData);

						if(_has(parsedCitationData, 'citationData')){
							sortByScore = RNS_CalcSortValue({
								citationData: parsedCitationData.citationData,
								sortByEnum: this.sortByEnum,
							});
						}//e:if:_has
					
						// console.log(sortByScore);
						researchNoteData.sortByScore = sortByScore;
					});//e:forEach

					return _orderBy(searchedArray, ['sortByScore'], ['desc']);
			}

			
			return null;
		},//e:filteredResearchNotesForThisReference
	},
	methods:{
		toggleIsExpanded(){
			this.isExpanded = !this.isExpanded;
		},

	},
	components: {
		ResearchNoteRow,
	}
}
</script>

<style lang="scss" scoped>

	.reference-text{
		margin-bottom: 0rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}
</style>
