<!--
- Navigation Tabs > Navigatin Tab
-- Single Nav Tabs within a Row
-- these are the tabs of the admin site - with an underline
-->

<template>
	<li class="nav-item">
		<router-link :to="to" :class="['nav-link', {'nav-link-active': isActive}]">
			<slot />
		</router-link>
	</li>
</template>

<script>

export default {
	name: 'NavTab',
	props:{
		isActive:{
			default: false,
			type: Boolean,
		},
		to:{
			required: true,
			type: Object,
		}
	},
}
</script>