var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-grid g-details" },
    [
      _c("h2", [_vm._v("Details")]),
      _vm._v(" "),
      _vm.StartDate
        ? _c("div", { staticClass: "field-label" }, [
            _vm._v("\n\t\tStart Date\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.StartDate
        ? _c("div", { staticClass: "field-value" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.StartDate) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.DueDate
        ? _c("div", { staticClass: "field-label" }, [
            _vm._v("\n\t\tDue Date\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.DueDate
        ? _c("div", { staticClass: "field-value" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.DueDate) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.RequiredLength
        ? _c("div", { staticClass: "field-label" }, [
            _vm._v("\n\t\tLength\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.RequiredLength
        ? _c("div", { staticClass: "field-value" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.RequiredLength) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.paperEdit.openObject.classID != 0
        ? _c("div", { staticClass: "field-label" }, [_vm._v("\n\t\tClass\n\t")])
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.paperEdit.openObject.classID != 0
        ? _c("div", { staticClass: "field-value" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm.$store.state.paperEdit.openObject.className) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.truncatedDescription
        ? _c("div", { staticClass: "field-label" }, [
            _vm._v("\n\t\tDescription\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.truncatedDescription
        ? _c(
            "div",
            { staticClass: "field-value", attrs: { title: _vm.Description } },
            [_vm._v("\n\t\t" + _vm._s(_vm.truncatedDescription) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "g-row" }, [
        _c("div", { staticClass: "g-col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary me-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openModalPaperEdit.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t\tEdit Paper Details\n\t\t\t")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "g-col" }, [
          _vm.IsComplete
            ? _c(
                "button",
                {
                  key: "btnMarkIncomplete",
                  staticClass: "btn btn-outline-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.togglePaperComplete.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tMark Unfinished\n\t\t\t")]
              )
            : _c(
                "button",
                {
                  key: "btnMarkComplete",
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.togglePaperComplete.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tMark Paper Finished\n\t\t\t")]
              ),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-paper-edit"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }