<!--
- Dashboard > Recent Papers
-- The current Paper section shows the five oldest un-finished papers by default. You can also see the five most recently finished papers based on their due date
-->

<template>
	<div class="position-relative">
		<div class="row">
			<div class="col">
				<h2 class="float-start">
					Recent Papers
				</h2>
				
				<div class="dropdown float-end">
					<button class="btn dropdown-toggle btn-outline-success border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
						<span class="svg bi-plus-lg me-1" /> Create paper
					</button>
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalResearchPaper">
								Research Paper
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalDiscussionPost">
								Discussion Post
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalReferenceList">
								Reference List
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-success" href="#" @click.prevent="btnOpenCreateModalAnnotatedBibliography">
								Annotated Bibliography
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="row">
			<div class="col-12">

				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid mb-0">
					<thead>
						<tr>
							<sortable-grid-th 
								cell-text="Title" 
								sort-key="title"
								colspan="2"
								:is-title="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<th class="table-spacer" />

							<th>
								Type
							</th>

							<th class="table-spacer" />
							
							<sortable-grid-th 
								cell-text="Class" 
								sort-key="className"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<th class="table-spacer" />
						
							<sortable-grid-th 
								cell-text="Due Date" 
								sort-key="dueDate"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />
							
							<th class="table-spacer" />

							<sortable-grid-th 
								cell-text="Modified" 
								sort-key="lastModifiedDate"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<paper-row
							v-for="paperData in filteredGridData"
							:class-id="paperData.classID"
							:paper-class-name="paperData.className"
							:paper-description="paperData.description"
							:paper-due-date="paperData.dueDate"
							:paper-encrypted-id="paperData.encryptedID"
							:paper-format-version-id="paperData.paperFormatVersionID"
							:paper-id="paperData.paperID"
							:paper-is-complete="paperData.isComplete"
							:paper-last-modified-date="paperData.lastModifiedDate"
							:paper-start-date="paperData.startDate"
							:paper-title="paperData.title"
							:paper-type="paperData.paperType"
							:key="paperData.paperID"
							@update-grid="updateGrid"
							context="Dashboard" />
						<tr class="tr-padding-left-icon">
							<td colspan="6">
								<router-link
									class="btn btn-outline-primary border-0"
									:to="{name: 'PaperList'}">
									View all papers
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No recent papers found.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import PaperRow from '@/components/EntityGrid/PaperRow';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'RecentPapers',
	data() {
		return {
			filteredGridData: [],
			sortOrderBy: 'lastModifiedDate',
			sorts: {
				'className': 1,
				'dueDate': 1,
				'lastModifiedDate': -1,
				'title': 1,
			}
		}
	},
	methods: {
		btnOpenCreateModalAnnotatedBibliography(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateAnnotatedBibliography',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalDiscussionPost(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateDiscussionPost',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalReferenceList(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateReferenceList',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},
		btnOpenCreateModalResearchPaper(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'PaperListCreateResearchPaper',
				modalParams: {
					associatedClassId: '0',
				},
			});
		},

		calcFilterGridData($opts){
			let filteredArray = [];
			
			let _data = _cloneDeep(this.$store.state.paperList.list);	// clone so i can add a sortBy property 
			
			// loop through each paper and prep for display
			_data.forEach((paperData) =>{
				// do not include Add-In papers, or any non valid formats
				if(!paperData.isOnlinePaper || !_includes(config.enums.Format, paperData.paperFormatVersionID)){
					return false;
				}

				// console.log(paperData);
				
				if(_has(paperData, 'lastModifiedDate') && paperData.lastModifiedDate != '') {
					paperData.sortByLastModifiedDate = paperData.lastModifiedDate;
				} else {
					paperData.sortByLastModifiedDate = '2000-01-01';
				}

				// due date isn't required - if you get back the default server value just blank it out on display
				if(!_has(paperData, 'dueDate') || paperData.dueDate === '2099-01-01T00:00:00') {
					paperData.dueDate = '';
				}
				
				filteredArray.push(paperData);
			
			});//e:forEach

			// pre sort by most recent date (so i can trim the top 5)
			let gridData = _orderBy(_uniqBy(filteredArray, 'paperID'), 'sortByLastModifiedDate', 'desc');

			// show more papers in the list if the user doesn't have any Classes or Work
			if($opts && $opts.classListResults === 0 && $opts.upcomingWorkListResults === 0){
				gridData = gridData.splice(0,9);	
			} else {
				gridData = gridData.splice(0,5);
			}
			
			this.$store.commit('loaders/REMOVE_ID', 'App');

			// now sort if user has modified the THs
			if(this.sorts[this.sortOrderBy] === 1){
				this.filteredGridData = _orderBy(gridData, [gridData => gridData[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				this.filteredGridData =  _orderBy(gridData, [gridData => gridData[this.sortOrderBy].toLowerCase()], ['desc']);
			}

			// console.log(this.filteredGridData);
			
		},//e:calcFilterGridData
		
		updateGrid(){
			this.$store.commit('loaders/ADD_ID', 'App');
			this.$nextTick(()=>{
				this.calcFilterGridData();
			});
		},

		updateGridSort($obj){
			this.sortOrderBy = $obj.sortKey;
			this.sorts = $obj.sorts;

			if(this.sorts[this.sortOrderBy] === 1){
				this.filteredGridData = _orderBy(this.filteredGridData, [gridData => gridData[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				this.filteredGridData =  _orderBy(this.filteredGridData, [gridData => gridData[this.sortOrderBy].toLowerCase()], ['desc']);
			}
		},

	},
	created() {
		this.emitter.on('loadRecentPaperList', ($opts)=>{
			this.calcFilterGridData($opts);
		});

	},
	components: {
		PaperRow,
		SortableGridTh
	},
	destroyed(){
		this.emitter.off('loadRecentPaperList');
	},
}
</script>