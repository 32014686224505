<!--
- Classes > List

-->

<template>
	<div>
		<div class="row">
			<div class="col-8">
				<h1 class="float-start me-3">
					Classes
				</h1>
				<button class="btn btn-success float-start" type="button" @click.prevent="$store.commit('modals/OPEN', 'ClassCreate')">
					<span class="svg bi-plus-lg me-1" /> New Class
				</button>
			</div>
			<div class="col-4">
				<grid-search
					placeholder-text="Search Classes"
					@update-grid="calcGridData" />
			</div>
		</div>

		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header">
					<thead>
						<tr>
							<sortable-grid-th
								cell-text="Name"
								sort-key="title"
								:entity-id="$enums.Entity.CLASS" 
								:is-local-storage="true"
								:is-title="true"
								@update-grid="calcGridData" />

							<sortable-grid-th
								cell-text="Teacher"
								sort-key="teacherName"
								:entity-id="$enums.Entity.CLASS" 
								:is-local-storage="true"
								:is-title="false"
								@update-grid="calcGridData" />

							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<class-row
							v-for="classData in filteredGridData"
							:class-data-color-value="classData.colorValue"
							:class-data-encrypted-id="classData.encryptedID"
							:class-data-id="classData.classID"
							:class-data-is-archived="classData.isArchived"
							:class-data-name="classData.name"
							:class-data-teacher-name="classData.teacherName"
							:key="classData.classID"
							context="ClassList" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No classes found.</p>
				</div>

				<div class="row mb-2">
					<div class="col">
						<button
							class="btn btn-outline-primary border-0"
							type="button"
							@click.prevent="toggleArchived">
							<span v-if="isArchivedShown" key="spanHideArchived">
								Hide
							</span>
							<span v-else key="spanShowArchived">
								Show
							</span>
							Archived Classes
						</button>
					</div>
				</div>	

			</div>
		</div>

	</div>
</template>

<script lang="js">
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import AS_SyncData from '@/services/app/syncData';
import ClassRow from '@/components/EntityGrid/ClassRow';
import GridSearch from '@/components/EntityGrid/Search';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ClassList',
	data() {
		return {
			filteredGridData: [],
			isArchivedShown: false,
		}
	},
	computed:{
		entityId(){
			return config.enums.Entity.CLASS;
		},
	},
	methods: {
		calcGridData(){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.classes.list);

			_data.forEach((classData) =>{
				// it's possible for some classes to NOT have a teacher name
				if(!classData.teacherName){
					classData.teacherName = '';
				}

				if(this.isArchivedShown){
					if(this.$store.state.gridSorts.searchTerm){
						// search by term
						if(classData.name.toLowerCase().includes(this.$store.state.gridSorts.searchTerm)){
							searchedArray.push(classData);
						}
					} else {
						// not searching
						searchedArray.push(classData);
					}//e:_searchTerm
				} else {
					if(!classData.isArchived){
						if(this.$store.state.gridSorts.searchTerm){
							// search by term
							if(classData.name.toLowerCase().includes(this.$store.state.gridSorts.searchTerm)){
								searchedArray.push(classData);
							}
						} else {
							// not searching
							searchedArray.push(classData);
						}//e:_searchTerm
					}
				}
				
			});//e:forEach
			
			switch(this.$store.state.gridSorts.classListOrderBy){
				case 'title':
					if(this.$store.state.gridSorts.classListSorts['title'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'classID'), [(gridData) => gridData.name.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'classID'), [(gridData) => gridData.name.toLowerCase()], ['desc']);
					}
					break;
				case 'teacherName':
					if(this.$store.state.gridSorts.classListSorts['teacherName'] === 1){
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'classID'), [(gridData) => gridData.teacherName.toLowerCase()], ['asc']);
					} else {
						this.filteredGridData = _orderBy(_uniqBy(searchedArray, 'classID'), [(gridData) => gridData.teacherName.toLowerCase()], ['desc']);
					}
					break;
			}//e:switch

			this.$nextTick(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		},//e:calcGridData

		toggleArchived(){
			this.isArchivedShown = !this.isArchivedShown;
			this.calcGridData();
		},

	},
	created() {
		// emits after a class is archived (i need to just do another /Sync call to make it go away)
		this.emitter.on('updateClassList', ()=>{
			AS_SyncData().then(()=>{
				this.calcGridData(false);
			});
		});
	},
	mounted() {
		AS_SyncData({
		}).then(()=>{
			this.calcGridData();
		});
	},
	components: {
		ClassRow,
		GridSearch,
		SortableGridTh
	},
	destroyed(){
		this.emitter.off('updateClassList');
	}
}
</script>
