var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-grid" }, [
    _c("h2", [_vm._v("Recent Paper Saves")]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "divRecentPaperSavesListWrp", staticClass: "overflow-y-scroll" },
      [
        _c("table", { staticClass: "table table-fixed-header entity-grid" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.paperVersions, function (paperVersionData, index) {
              return _c("recent-paper-save-row", {
                key: index,
                attrs: { "paper-version-data": paperVersionData },
              })
            }),
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "215px" } }, [
          _vm._v("\n\t\t\t\t\t\tTime\n\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("\n\t\t\t\t\t\tAction\n\t\t\t\t\t")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "115px" } }, [
          _vm._v("\n\t\t\t\t\t\tOptions\n\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { width: "135px" } }, [_vm._v("\n\t\t\t\t\t\t  \t")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "32px" } }, [_vm._v("\n\t\t\t\t\t\t \t")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }