<!--
- Module > Create Reference Step 3 Third Party > Body

- Jun 2022 - 3plu ref need the ability to change RefTypes:
-- https://perrla.monday.com/boards/1892814374/pulses/2837254209
-- https://magical-design-system.webflow.io/add-in/refs-cits/reference-form
-- https://docs.google.com/document/d/1F89B6R9BX6aX3r16wseJHB3LZ2p5JI-wesMh_BtrErc/edit
-->

<template>
	<div>
		<h2 class="mb-3">
			Review and edit the information we found to make sure it's correct.
		</h2>

		<!-- Website (yellow) -->
		<div v-if="referenceTypeId === 3" class="alert alert-warning" key="alertWebite">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				References viewed online should use the reference type for the original work. For example, journal articles viewed online use the Journal reference type - not Webpage.
			</p>
		</div>

		<!-- Executive Orders (yellow) -->
		<div v-else-if="referenceTypeId === 17" class="alert alert-warning" key="alertExecutiveOrders">
			<span class="svg bi-exclamation-lg float-start" />
			<p class="ps-3">
				This reference type is for citing the formal publication of Executive Orders in the Code of Federal Regulations. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<!-- Common Fields (blue) -->
		<div v-else class="alert alert-info" key="alertWarning">
			<span class="svg bi-check-circle-fill float-start" />
			<p class="ps-3">
				Commonly used fields are outlined in blue.
			</p>
		</div>

		<reference-types
			:is-third-party-look-up="true"
			:reference-type-id="referenceTypeId" />

		<reference-output />

		<copy-json-data :data-to-display="JSON.stringify($store.state.referenceRules.rulesObjectData)" />

		<div class="row justify-content-between">
			<div class="col">
				<div class="d-grid">
					<a href="#" class="btn btn-outline-primary" @click.prevent="btnBack">
						Back
					</a>
				</div>
			</div>
			<div class="col text-end">
				<div class="d-grid">
					<button type="submit" class="btn btn-success" @click.prevent="generateReference" title="Next">
						Next
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CopyJsonData from '@/components/CopyJsonData'
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import ReferenceOutput from '@/components/ReferenceOutput';
import ReferenceTypes from '@/components/reference-rules-genesis/Types';

export default {
	name: 'CreateReferenceStep3ThirdPartyBody',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	computed:{
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
		referenceTypeId(){
			return this.$store.state.referenceRules.referenceTypeId;
		},
	},
	methods: {
		btnBack(){
			this.$router.go(-1);
		},//e:btnBack

		generateReference(){
			this.$store.dispatch('referenceRules/generateReferencePreview').then(()=>{
				this.$store.dispatch('referenceRules/generateReference');
			});
		},//e:generateReference
	},
	mounted() {
		// this.$store.commit('forms/MARK_CLEAN');
	},
	components: {
		CopyJsonData,
		ReferenceOutput,
		ReferenceTypes,
	},
}
</script>
