var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.$store.state.paperEdit.meta.IncludeAbstract
      ? _c(
          "div",
          {
            key: "divShowAbstractWrp",
            class: _vm.calcInstanceStyles,
            attrs: { id: "instancePaperAbstract" },
          },
          [
            _c("paper-running-head", { attrs: { "page-type": "abstract" } }),
            _vm._v(" "),
            _c(
              "h1",
              {
                class: [
                  "text-center",
                  {
                    "fw-normal":
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.MLA9,
                  },
                ],
              },
              [_vm._v("\n\t\t\t\tAbstract\n\t\t\t")]
            ),
            _vm._v(" "),
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.Turabian9
              ? _c("br")
              : _vm._e(),
            _vm._v(" "),
            _c("textarea", {
              staticClass: "d-none",
              attrs: { id: "ckPaperAbstract", autocomplete: "off" },
              domProps: { value: _vm.paperAbstractContent },
            }),
            _vm._v(" "),
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.APA7
              ? _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-3 col-form-label fst-italic text-end pe-0",
                      attrs: { for: "txtPaperAbstractKeywords " },
                    },
                    [_vm._v("Keywords:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-9 ps-0" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paperAbstractKeywords,
                          expression: "paperAbstractKeywords",
                        },
                      ],
                      ref: "txtPaperAbstractKeywords",
                      staticClass: "form-control form-control-hover-border",
                      attrs: {
                        id: "txtPaperAbstractKeywords",
                        rows: "1",
                        "aria-label": "Abstract Keywords",
                      },
                      domProps: { value: _vm.paperAbstractKeywords },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.paperAbstractKeywords = $event.target.value
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.Turabian9
              ? _c("page-footer", { attrs: { "page-type": "abstract" } })
              : _vm._e(),
          ],
          1
        )
      : _vm.$store.state.paperEdit.meta.PaperFormatVersionID !==
        _vm.$enums.Format.MLA9
      ? _c("div", { key: "divAddAbstract" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-page-group mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addAbstract.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tAdd Abstract \n\t\t\t\t")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }