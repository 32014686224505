<!--
- Reset Password
-
-->

<template>

	<div class="layout-pcom">
		<section class="section-text pt-0">
			<main class="container-text mt-0 pt-3">

				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
						<h2 class="mb-0">
							Password Reset
						</h2>
						
						<div v-if="isKeyInvalid" key="divInvaldKey">
							<p>The password reset key is invalid.</p>
							<p>Please contact support at <a href="mailto:support@perrla.com">support@perrla.com</a>.</p>
						</div>
						<div v-else-if="isKeyExpired" key="divExpiredKey">
							<p>The password reset key is expired.</p>
							<p>Please contact support at <a href="mailto:support@perrla.com">support@perrla.com</a>.</p>
						</div>
						
						<div v-else key="divElse">
							<div class="alert alert-danger" v-if="isPasswordError">
								Please enter matching passwords.
							</div>

							<form>
								<form-password
									label="New Password"
									v-model="passwordNew"
									:form-id="zFormId"
									:validate-enum="$enums.Validation.REQUIRED" />

								<form-password
									label="Confirm Password"
									v-model="passwordConfirm"
									:form-id="zFormId"
									:validate-enum="$enums.Validation.REQUIRED" />

								<div class="form-group">
									<div class="d-grid gap-2">
										<button type="submit" class="btn btn-success" @click.prevent="btnUpdateAccount">
											Update account
										</button>
									</div>
								</div>

							</form>
						</div>

					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>

	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
// import AS_SetLayout from '@/services/app/setLayout';
import AS_ValidateForm from '@/services/app/validateForm';
// import config from '@/config';
import FormPassword from '@/components/form/Password';
import Swal from 'sweetalert2';

export default {
	name: 'ResetPassword',
	data() {
		return {
			isKeyExpired: false,
			isKeyInvalid: false,
			isPasswordError: false,
			passwordConfirm: '',
			passwordNew: '',
			zFormId: 'formResetPassword',
		}
	},
	methods: {
		btnUpdateAccount(){
			this.$store.commit('loaders/ADD_ID', 'App');
			this.isPasswordError = false;

			// lazy check to make sure password fields match
			if(this.passwordConfirm !== this.passwordNew){
				this.isPasswordError = true;
				this.$store.commit('loaders/REMOVE_ID', 'App');
				return false;
			}

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				ApiS_MakeCall({
					body:{
						'id': this.$route.params.resetKey,
						'password': this.passwordNew
					},
					ignoreAuth: true,
					method: 'POST',
					responseType: 'text',
					url: 'v4/Customer/ResetPassword',
				}).then((responseData)=>{
					if(responseData === 'Reset'){
						this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
							formId: this.zFormId
						});

						// successfully reset
						Swal.fire({
							allowOutsideClick: false,
							buttonsStyling: false,
							title: 'Your password has been changed.',
							// text: 'Click below to go log in.',
							icon: 'success',
							confirmButtonText: 'Ok, thanks!',
							showCloseButton: false,
							showCancelButton: false,
							showConfirmButton: true,
							customClass:{
								confirmButton: 'btn btn-success',
							},
						}).then((result) => {
							if (result.value) {
								// this goes to login but should have a token to auto direct the user to the dashboard
								this.$router.push({
									name: 'Login'
								}).catch(()=>{});
							}
						});
					}

				}).catch(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				this.isLoginError = true;

			});//e:AS_ValidateForm

		},//e:btnUpdateAccount
	},
	created() {
		this.$store.commit('loaders/ADD_ID', 'App');
	},
	mounted() {
		// console.log('reset password mounted ');

		ApiS_MakeCall({
			ignoreAuth: true,
			method: 'GET',
			params:{
				'id': this.$route.params.resetKey,
			},
			responseType: 'text',
			url: 'v4/Customer/ForgotPassword',
		}).then((responseData)=>{
			// console.log('responseData');
			// console.log(responseData);

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});

			this.$store.commit('loaders/REMOVE_ID', 'App');

		}).catch((err)=>{
			if(err === 'Expired Key'){
				this.isKeyExpired = true;
			} else {
				this.isKeyInvalid = true;
			}
			this.$store.commit('loaders/REMOVE_ID', 'App');
		});
		




		// if(this.$store.state.api.token === ''){
		// 	this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
	
		// 	// force remove any theme classes - this view will not have a customer theme
		// document.documentElement.setAttribute('data-bs-theme', 'light')
		// 	document.body.classList.remove('theme-serrena');
			
		// 	this.$store.commit('loaders/REMOVE_ID', 'App');


		// } else {
		// 	AS_SetLayout().then(()=>{
		// 		// verify this is a legit reset key
				
		// 	});
		// }
		
	},
	components: {
		FormPassword
	}
}
</script>
