<!--
- Paper Edit > Outline Tab
-->

<template>
	<nav-tab-content :is-no-padding="true">
		<outline-filebar />

		<outline-ribbon />

		<div class="float-start w-100" :style="calcOutlineOutStyles" ref="outlineWrp" id="outlineWrp">
			<div :class="calcOutlineClasses" :style="calcOutlineInStyles">
				<citation-context-menu
					v-if="$store.state.customer.citationAutoInsert" />

				<textarea id="ckPaperOutline" :value="outlineFullHtmlLocal" autocomplete="off" class="d-none" /> 
				
				<outline-footnotes v-if="$store.state.paperEdit.config.renderCitationsAsFootnotes" />
			</div>
		</div>

		<tool-drawer :is-editor="false" />
		
		<outline-footnotes-dropdown />

	</nav-tab-content>
</template>

<script>
import CitationContextMenu from '@/views/Paper/TabEditor/CitationContextMenu';
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import mixinResizer from '@/mixins/resizer';
import Mousetrap from 'mousetrap';
import NavTabContent from '@/components/NavTabContent';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import OutlineFilebar from './TabOutline/Filebar';
import OutlineFootnotes from './TabOutline/Footnotes';
import OutlineFootnotesDropdown from './TabOutline/Footnotes/Dropdown';
import OutlineRibbon from './TabOutline/Ribbon';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import ToolDrawer from '@/components/ToolDrawer/ToolDrawer';

export default {
	name: 'TabOutline',
	mixins: [mixinResizer],
	data() {
		return {
			outlineFullHtmlLocal: '',

			rzRef: 'outlineWrp',
			rzHeightOffsetOutlineCkInstance: 241,
		}
	},
	computed:{
		calcOutlineClasses(){
			let returnString = 'outline-wrp overflow-y-auto ';
			if(this.isDrawerOpen){
				returnString += 'drawer-open ';
			}

			switch(parseInt(this.$store.state.paperEdit.meta.FontFamily)){
				case 1:
					returnString += 'font-family-arial';
					break;
				case 2:
					returnString += 'font-family-courier';
					break;
				case 4:
					returnString += 'font-family-trebuchet';
					break;
				case 5:
					returnString += 'font-family-verdana';
					break;
				default: 
					returnString += 'font-family-times-new-roman';
					break;
			}

			// console.log(returnString);
			return returnString;
		},
		calcOutlineInStyles(){
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				return {
					marginRight: this.$store.state.paperEdit.toolDrawer.openWidth + 'px',
				}
			}
			return {
				marginRight: this.$store.state.paperEdit.toolDrawer.closedWidth + 'px',
			}
		},
		calcOutlineOutStyles(){
			if(this.$store.state.paperEdit.toolDrawer.isOpen){
				return {
					marginRight: '-' + this.$store.state.paperEdit.toolDrawer.openWidth + 'px',
				}
			}
			return {
				marginRight: '-' + this.$store.state.paperEdit.toolDrawer.closedWidth + 'px',
			}
		},
	},
	methods:{
		setOutlineEditorHeight(){
			let outlineEditorInstance = document.getElementById(window.$ckPaperOutline.id + '_contents');
			if(outlineEditorInstance){
				let outlineFootnotesBlockHeight = 0;
				if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
					let outlineFootnotesBlockInstance = document.getElementById('outlineFootnotesBlock');
					if(outlineFootnotesBlockInstance){
						outlineFootnotesBlockHeight = outlineFootnotesBlockInstance.clientHeight;
					}
				}
				outlineEditorInstance.style.height = document.documentElement.clientHeight - outlineFootnotesBlockHeight -  this.rzHeightOffsetOutlineCkInstance + 'px';
			}
		},
	},
	created(){
		this.emitter.on('resizeWindow',()=>{
			this.setOutlineEditorHeight();
		});
		
		this.emitter.on('setOutlineEditorHeight',()=>{
			this.setOutlineEditorHeight();
		});
		
		// Print Document
		Mousetrap.bind(['command+p', 'ctrl+p'], () => {
			OS_SaveContent().then(()=>{
				let editor = CKEDITOR.instances['ckPaperOutline'];
				CKEDITOR.plugins.preview.createPreview( editor, function( previewWindow ) {
					var nativePreviewWindow = previewWindow.$;
	
					if ( CKEDITOR.env.gecko ) {
						nativePreviewWindow.print();
					} else {
						nativePreviewWindow.document.execCommand( 'Print' );
					}
	
					nativePreviewWindow.close();
				} );
			});
		
			return false;
		});

		// Save Document
		Mousetrap.bind(['command+s', 'ctrl+s'], () => {
			OS_SaveContent().then(()=>{
				window.$ckPaperOutline.resetDirty();
				this.$store.commit('paperEdit/outline/FULL_HTML', window.$ckPaperOutline.getData());
			});
			return false;
		});
		
		// allow Save Shortcut key to work when inside any field (like keywords for abstract)
		Mousetrap.prototype.stopCallback = () => {
			return false;
		}
	},
	mounted(){
		// refreshing page off the Editor Tab > force user back to it
		if(!this.$store.state.paperEdit.ck.isEditorLoadedOnce){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
		}

		this.$store.commit('loaders/ADD_ID', 'App');

		this.outlineFullHtmlLocal = this.$store.state.paperEdit.outline.fullHtml;

		var $this = this;

		let configCkPaperOutline = configCkEditor.getSettings('ckPaperOutline');

		configCkPaperOutline.on = {
			focus(evt) {
				PCKS_OnFocus({
					editorName: evt.editor.name,
				});
			},

			instanceReady(evt) {
				evt.editor.$ckEditorType = config.enums.CkEditorType.OUTLINE;

				PCKS_wProofreaderInit({
					container: evt.editor.window.getFrame().$,
				}).then(()=>{
					PS_BuildReferencesInPaper().then(()=>{
						$this.setOutlineEditorHeight();

						$this.$store.commit('loaders/REMOVE_ID', 'App');
					});
		
				});//e:PCKS_wProofreaderInit
			},
		}

		window.$ckPaperOutline = CKEDITOR.replace('ckPaperOutline', configCkPaperOutline);
	},
	beforeRouteLeave (to, from, next) {
		if(window.$ckPaperOutline) {
			if(window.$ckPaperOutline.checkDirty()) {
				OS_SaveContent().then(()=>{
					window.$ckPaperOutline.resetDirty();
					this.$store.commit('paperEdit/outline/FULL_HTML', window.$ckPaperOutline.getData());
					next();
				});
			} else {
				// console.log('window.$ckPaperOutline.getData()');
				// console.log(window.$ckPaperOutline.getData());
				this.$store.commit('paperEdit/outline/FULL_HTML', window.$ckPaperOutline.getData());
				next();
			}
		} else {
			// no outline instance, not sure how that could happen so just move to the next route
			next();
		}
	},
	components: {
		CitationContextMenu,
		NavTabContent,
		OutlineFilebar,
		OutlineFootnotes,
		OutlineFootnotesDropdown,
		OutlineRibbon,
		ToolDrawer
	},
	destroyed(){
		this.emitter.off('resizeWindow');
		this.emitter.off('setOutlineEditorHeight');

		Mousetrap.unbind(['command+p', 'ctrl+p']);
		Mousetrap.unbind(['command+s', 'ctrl+s']);

		window.$ckPaperOutline.focusManager.blur(true);
		window.$ckPaperOutline.destroy(true);
		window.$ckPaperOutline = null;
	},

}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";

	.g-outline-header {
		// align-items: center;
		column-gap: 1.5rem;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		
		
	}//.g-outline-header

</style>