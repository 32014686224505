var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["form-group", { row: _vm.isHorizontal }] }, [
    _vm.label
      ? _c("label", {
          class: [
            {
              "fst-italic": _vm.isInvalidShown,
              "col-sm-3 col-form-label": _vm.isHorizontal,
            },
          ],
          attrs: { for: _vm.safeId },
          domProps: { innerHTML: _vm._s(_vm.label) },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.helpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.helpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: { "col-sm-9": _vm.isHorizontal } }, [
      _c(
        "select",
        {
          class: ["form-select", { "is-invalid": _vm.isInvalidShown }],
          attrs: { id: _vm.safeId },
          domProps: { value: _vm.value },
          on: {
            change: function ($event) {
              return _vm.selectChange($event.target.value)
            },
          },
        },
        _vm._l(_vm.calcSelectObject, function (singleObject, key) {
          return _c(
            "option",
            {
              key: key,
              attrs: { disabled: singleObject.isDisabled },
              domProps: { value: singleObject.value },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(singleObject.name) + "\n\t\t\t")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.isInvalidShown
        ? _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t\t"),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }