var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.calcInstanceStyles, attrs: { id: _vm.calcInstanceIdWrp } },
      [
        _c("paper-running-head", { attrs: { "page-type": "appendix" } }),
        _vm._v(" "),
        _c(
          "h1",
          {
            class: [
              "text-center",
              {
                "fw-bold":
                  _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                  _vm.$enums.Format.APA7,
                "fw-normal":
                  _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                  _vm.$enums.Format.MLA9,
              },
            ],
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.appendixData.label) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c("textarea", {
          staticClass: "d-none",
          attrs: { id: _vm.calcInstanceId, autocomplete: "off" },
          domProps: { value: _vm.appendixContentValue },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-grid" }, [
      _c(
        "button",
        {
          staticClass:
            "btn btn-outline-danger btn-outline-white-bg btn-page-group mb-2",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.btnDeleteAppendixConfirm.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\t\tDelete " + _vm._s(_vm.appendixData.label) + "\n\t\t")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }