<!--
- References > List > Row
-- clickable title to open a document for editing
-->

<template>
	<!-- {'tr-entity-grid-row-success': isSelected} -->
	<tr :class="calcRowClasses" @mouseover="isHover = true" @mouseleave="isHover = false">
		<td class="td-entity-icon" v-if="$store.state.app.showBetaFeatures">
			<a href="#" v-show="isSelected" title="Archive Reference" @click.prevent="toggleSelectReference">
				<span class="svg bi-check-lg" />
			</a>
			<div v-show="!isSelected">
				<a href="#" class="svg" v-show="isHover" title="Mark Unfinished" @click.prevent="toggleSelectReference">
					<span class="svg icons8-unchecked-checkbox" />
				</a>
				<span :class="calcRefTypeIconClasses" v-show="!isHover" />
			</div>
		</td>

		<td class=" td-entity-title td-entity-click">
			<div v-html="outputReferenceValue" class="hanging-indent word-break" @click.prevent="gotoEditReference" title="Open Reference" />
		</td>
		<td class="table-spacer" />
		<td width="160px">
			{{ outputCreatedDate }}
		</td>
		<td class="td-more-menu">
			<div class="dropstart" v-if="!referenceData.isArchived">
				<a href="#" :class="['svg bi-three-dots-vertical float-end']" data-bs-toggle="dropdown" aria-expanded="false" aria-label="More Options" />
				<ul class="dropdown-menu">
					<li>
						<a class="dropdown-item hover hover-primary" @click.prevent="copyOutputReferenceValue" href="#">Copy Text</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-danger" @click.prevent="deleteReferenceShowConfirm" href="#">Archive Reference</a>
					</li>
				</ul>
			</div>
		</td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import CopyToClipboard from 'copy-to-clipboard';
import dateToLongString from '@/helpers/date-to-long-string';
import mixinId from '@/mixins/id';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import RS_GetValueOutput from '@/services/reference/getValueOutput';
import RS_Compress from '@/services/reference/compress';
import Swal from 'sweetalert2';

export default {
	name: 'ReferenceRow',
	mixins: [mixinId],
	props:{
		referenceData:{
			required: true,
			type: Object,
		},
	},
	data(){
		return {
			isSelected: false,
			isHover: false,
		}
	},
	computed:{
		calcRefTypeIconClasses(){
			let returnArray = ['svg'];
			
			switch(this.referenceData.referenceTypeID){
				case 5:		// Advertisement
				case 29:	// Newspaper
				case 35:	// Press Release
				case 38:	// Reviews
					returnArray.push('icons8-news');
					break;
				case 6:		// Ancient & Classic Works
					returnArray.push('icons8-greek-pillar-base');
					break;
				case 7:		// Anthology
				case 2:		// Book
				case 13:	// Conference & Symposium Materials
				case 16: 	// Dissertations & Theses
				case 21:	// Grants
				case 4:		// Gray Literature
				case 22:	// Informally Published Works
				case 27:	// Manuscript
				case 31:	// Pamphlets & Brochures
				case 36:	// Reference Work
				case 37:	// Religious Work
					returnArray.push('icons8-book');
					break;
				case 12:		// Charts, Infographics, & Maps
					returnArray.push('icons8-graph');
					break;
				case 23:	// Interview
				case 33: 	// Personal Communication
					returnArray.push('icons8-reporter');
					break;
				case 1:		// Journal
				case 26: 	// Magazine
					returnArray.push('icons8-magazine');
					break;
				case 8:		// Apparatus & Equipment
				case 15: 	// Datasets
				case 44:	// Tests, Scales, & Inventories
					returnArray.push('icons8-lab-items');
					break;
				case 40:	// Software & Mobile Apps
				case 11: 	// Blog
				case 30:	// Online Comment
				case 39:	// Social Media
				case 3:		// Webpage
					returnArray.push('icons8-internet-connection');
					break;
				case 9:		// Artwork
					returnArray.push('icons8-home-decorations');
					break;
				case 19:	// Film & Movies
				case 43: 	// Television
				case 47:	// Videos (online)
					returnArray.push('icons8-video');
					break;
				case 25:		// Lecture & Presentation Materials
					returnArray.push('icons8-lecturer');
					break;
				case 28:		// Music
					returnArray.push('icons8-musical-notes');
					break;
				case 34:	// Podcasts & Radio
				case 41: 	// Speech (Audio Recording)
					returnArray.push('icons8-microphone');
					break;
				case 10:	// Bills & Resolutions
				case 14:	// Constitutions & Charters
				case 17:	// Executive Orders
				case 18:	// Federal Testimony & Hearings
				case 20:	// Government Reports & Regulations
				case 24:	// International Conventions & Treaties
				case 32:	// Patent
				case 42:	// Statutes
				case 45:	// US Federal Court Cases
				case 46:	// US State Court Cases
					returnArray.push('icons8-law');
					break;
				default:
					// this could be legacy references or any genesis reference that hasn't yet matched a type
					// returnArray.push('icons8-book');
			}


			return returnArray
		},
		calcRowClasses(){
			let returnArray = ['tr-entity-grid-row']
			
			if(this.referenceData.isArchived){
				// archived, red and not clickable to view
				

				if(this.isSelected){
					returnArray.push('tr-entity-grid-danger');
				} else {
					returnArray.push('tr-entity-grid-danger-text');
				}

			} else {
				returnArray.push('tr-entity-grid-primary');

				if(this.isSelected){
					returnArray.push('tr-entity-grid-row-success');
				}
			}

			
			
			return returnArray;
		}, 

		isGenesisReference(){
			if(_has(this.referenceData, 'values')){
				return true;
			}
			return false;
		},
		outputCreatedDate(){
			if(_has(this.referenceData, 'createdDate')){
				return dateToLongString({
					dateValue: this.referenceData.createdDate,
					showTime: false,
				});
			}
			return '';
		},
		outputReferenceValue(){
			let refValue = RS_GetValueOutput({
				displayFormatVersionId: this.$store.state.references.displayFormatVersionId,
				referenceData: this.referenceData,
			});
			if(refValue){
				return refValue;
			}
			return '';
		},
	},
	methods: {
		copyOutputReferenceValue(){
			let $this = this;

			CopyToClipboard(this.outputReferenceValue, {
				format: 'text/html',
				onCopy(){
					$this.emitter.emit('globalToasterOpen',{
						textContent: 'Copied to clipboard',
					});
				},
			});
		},//e:copyOutputReferenceValue

		deleteReferenceShowConfirm(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'This will remove it from your library permanently.  Are you sure?',
				text: 'Archiving your reference will remove it from your library. It will be disabled in any papers where it was used.',
				icon: 'warning',
				cancelButtonText: 'Cancel',
				confirmButtonText: 'Archive Reference',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					// Genesis Reference (default)
					let fetchUrl = 'v3/GenesisReference?referenceID=' + this.referenceData.referenceID;

					if(!_has(this.referenceData, 'values')){
						// Non Genesis Reference
						fetchUrl = 'v3/Reference?referenceID=' + this.referenceData.referenceID;
					}

					ApiS_MakeCall({
						method: 'DELETE',
						url: fetchUrl,
					}).then(()=>{
						this.$store.commit('REFERENCE_REMOVE_FROM_LIBRARY', this.referenceData.referenceUniqueID.toUpperCase());

						PS_BuildReferencesInPaper().then(()=>{
							// dispatch to compress the referenceLibrary
							RS_Compress({
								isGenesis: this.isGenesisReference,
							}).then(()=>{
								this.$emit('update-grid');
								
								this.emitter.emit('globalToasterOpen',{
									textContent: 'Reference successfully deleted',
								});
							});
						});
					});//e:ApiS_MakeCall
				}//e:if
			});//e:Swal
		},//e:deletePaperShowConfirm

		gotoEditReference(){
			if(this.referenceData.isArchived){
				// click will check this row
				this.toggleSelectReference();
			} else {
				if(_has(this.referenceData, 'values')){
					// genesis reference
					this.$router.push({
						name:'ReferenceTabPapers',
						params:{
							referenceUid: this.referenceData.referenceUniqueID.toUpperCase()
						}
					}).catch(()=>{});

				} else {
					// legacy reference
					if(this.$isDev){
						alert('APA6/MLA8 references cant do that locally');
						console.log(this.referenceData);
					} else {
						// clearing local storage here will force a full resync if the user deletes or edits and APA6 reference then comes back
						localStorage.clear();
						this.$store.commit('loaders/ADD_ID', 'App');
						window.location = '/References/Edit/' + this.referenceData.referenceID;
					}

				}//e:if
			}//e:if
		},//e:gotoEditReference
		
		toggleSelectReference(){
			this.isSelected = !this.isSelected;
			if(this.isSelected) {
				this.$emit('add-reference-selection', {
					isLegacy: !this.isGenesisReference,
					referenceId: this.referenceData.referenceID,
				});
			} else {
				this.$emit('remove-reference-selection', {
					isLegacy: !this.isGenesisReference,
					referenceId: this.referenceData.referenceID,
				});
			}
		},//e:toggleSelectReference
	},
	created(){
		this.emitter.on('clearAllSelectedReferences', ()=>{
			this.isSelected = false;
		});
		this.emitter.on('selectAllReferences', ()=>{
			this.isSelected = true;
			this.$emit('add-reference-selection', {
				isLegacy: !this.isGenesisReference,
				referenceId: this.referenceData.referenceID,
			});
		});
	},
}
</script>
