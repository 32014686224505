<!--
- Generic Form Label and Input
-->
<template>
	<div class="form-select-v2 row mb-3">
	
		<div class="col-label">
			<label
				class="form-label form-label-v2"
				:for="safeId"
				v-html="label" />
		</div>

		<div class="col-value">
			<select :class="['form-select']" :value="value" :id="safeId" @change="selectChange($event.target.value)">
				<option v-for="(singleObject, key) in calcSelectObject" :key="key" :value="singleObject.value" :disabled="singleObject.isDisabled">
					{{ singleObject.name }}
				</option>
			</select>
		</div>

		

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import mixinId from '@/mixins/id';

export default {
	name: 'FormSelect',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		label:{
			type: String,
		},
		selectObject:{
			type: Array
		},
		value:{
			default: 0,
			type: [Number, String],
		},
	},
	computed:{
		calcSelectObject(){
			return this.selectObject;
		},
		
	},
	methods:{
		selectChange(value){
			// treat this like a form input blur, on change make sure it's still valid
			AS_ValidateForm({
				singleFieldId: this.safeId
			}).then(()=>{
				this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
					formId: this.formId,
				});
				// this.$emit('blur-input', value);
				this.$emit('input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		}//e:selectChange
	},
}
</script>
