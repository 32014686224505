<!--
- Cart > Organization Invite
-- from IdentifyUser
-- Joey is sending the email behind the scenes, the 'email' passed form the vuex store is just for display
-->

<template>
	<div class="p-5">
		<div class="row mb-4">
			<div class="col-12">
				<img src="https://perrla.s3.amazonaws.com/images/unicorn-rainbow.png" class="img-max mx-auto d-block mb-4" role="presentation" />

				<h1 class="text-center mt-4 mb-0 text-large d-none d-md-block">
					You've been invited to use PERRLA for free!
				</h1>
			</div>
		</div>

		<div class="row">
			<div class="col-8 mx-auto">
				<div class="org-size">
					<p>
						You&#39;ve already been invited to join PERRLA as part of an organization or school.
					</p>
					<p>
						We just sent your email invitation to &quot;{{ emailAddress }}.&quot;
					</p>
					<p>
						Please check your inbox for an invitation from &quot;support@perrla.com.&quot; &nbsp;Follow the instructions in the email to activate your PERRLA&nbsp;account.
					</p>
					<p>
						<router-link
							:to="{
								name: 'ContactSupport'
							}">
							Can&#39;t find the invitation email?&nbsp;Click here to email our Customer Support team.
						</router-link>
						
					</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'OrganizationInvite',
	computed:{
		emailAddress(){
			return this.$store.state.customer.email
		}
	},
	mounted() {
		// blank token - user isn't logged in yet
		this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
			
		// force remove any theme classes - this view will not have a customer theme
		document.documentElement.setAttribute('data-bs-theme', 'light')
		document.body.classList.remove('theme-serrena');
		document.body.classList.remove('bg-simple');

		this.$store.commit('loaders/REMOVE_ID', 'App');

	},
}
</script>
