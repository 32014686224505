var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.$store.state.paperEdit.meta.IncludeTableOfContents
      ? _c(
          "div",
          {
            key: "divShowTocWrp",
            class: _vm.calcInstanceStyles,
            attrs: { id: "instancePaperToc" },
          },
          [
            _c("paper-running-head", { attrs: { "page-type": "toc" } }),
            _vm._v(" "),
            _c(
              "h1",
              {
                class: [
                  "text-center",
                  {
                    "fw-bold":
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.APA7,
                    "fw-normal":
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.MLA9,
                  },
                ],
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.tocTitleDisplay) + "\n\t\t")]
            ),
            _vm._v(" "),
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.Turabian9
              ? _c("br")
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              attrs: {
                id: "ckTableOfContents",
                title:
                  "The page numbers will be included when you export or print your paper.",
              },
              domProps: {
                innerHTML: _vm._s(_vm.$store.state.paperEdit.toc.content),
              },
            }),
            _vm._v(" "),
            _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
            _vm.$enums.Format.Turabian9
              ? _c("page-footer", { attrs: { "page-type": "toc" } })
              : _vm._e(),
          ],
          1
        )
      : _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.APA7 ||
        _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
          _vm.$enums.Format.Turabian9
      ? _c("div", { key: "divAddToc" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-page-group",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addToc.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tAdd Table of Contents\n\t\t\t")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }