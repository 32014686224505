var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm.filteredGridData && _vm.filteredGridData.length > 0
          ? _c(
              "table",
              { staticClass: "table table-borderless entity-grid mb-0" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Name",
                          colspan: "2",
                          "sort-key": "name",
                          "is-title": true,
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                      _vm._v(" "),
                      _c("sortable-grid-th", {
                        attrs: {
                          "cell-text": "Due Date",
                          "set-width": "165px",
                          "sort-key": "sortByDueDate",
                          "is-align-right": true,
                          "sort-order-by": _vm.sortOrderBy,
                          sorts: _vm.sorts,
                        },
                        on: { "update-grid": _vm.updateGridSort },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.filteredGridData, function (workData) {
                    return _c("work-row", {
                      key: workData.workID,
                      attrs: {
                        "work-data-encrypted-id": workData.entityEncryptedID,
                        "work-data-entity-id": workData.entityID,
                        "work-data-due-date": workData.dueDate,
                        "work-data-name": workData.name,
                        "work-data-type": workData.workType,
                      },
                    })
                  }),
                  1
                ),
              ]
            )
          : _c("div", { staticClass: "text-muted" }, [
              _c("p", [_vm._v("No upcoming work found.")]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", [_vm._v("\n\t\t\t\tUpcoming Work\n\t\t\t")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }