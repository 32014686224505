<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Abstract" />
		</div><!--drawer-header-->
		
		<div class="drawer-body">
			<p>The abstract should give a succinct description of your paper in under 250 words.</p>

			<div v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA8 || $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9" key="divMla">
				<p>PERRLA will create a place to type your abstract following MLA styles.</p>
			</div>
			<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7" key="divApa7">
				<p>They are often followed by a keywords section.</p>
				<p>PERRLA will create a place to type your abstract and a place to list keywords following the latest APA 7th Edition standards.</p>
			</div>
			<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA6" key="divApa6">
				<p>They are often followed by a keywords section.</p>
				<p>PERRLA will create a place to type your abstract and a place to list keywords following the latest APA&nbsp;6th Edition standards.</p>
			</div>
			<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" key="divTurabian9">
				<p>They are often followed by a keywords section.</p>
				<p>PERRLA will create a place to type your abstract following the latest Turabian 9th Edition standards.</p>
			</div>
		
			<div class="d-grid">
				<button type="button" class="btn btn-success" @click.prevent="addAbstract" v-show="!IncludeAbstract">
					Add an Abstract
				</button>
				<button type="button" class="btn btn-outline-danger" @click.prevent="removeAbstract" v-show="IncludeAbstract">
					Remove Abstract
				</button>
			</div>
							
			<div class="text-empty mt-5">
				<p><strong>Writing tips for abstracts</strong></p>
				<p>Abstracts are a brief summary of your paper&#39;s content that lets the reader know what you are going to discuss.</p>
				<p>It should always be a single paragraph that is 150 &ndash; 250 words long.</p>
				<p>Good abstracts should be brief, clear, and non-biased (a report on the paper, not an opinion about it).</p>

				<p v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7">
					You can add keywords to help your paper be more easily discovered by other researchers or students.
				</p>
				<p>You can also find more information about Abstracts in <a href="https://perrla.zendesk.com/hc/en-us/categories/360001366714-PERRLA-Add-in-BETA-" target="_blank" rel="noopener noreferrer">PERRLA&#39;s Help Center</a>.</p>
			</div>
		
			<br /><br />
		</div><!--drawer-body-->
	</div>
</template>

<script>
import _has from 'lodash/has';
import PS_SaveDocument from '@/services/paper/saveDocument';
import Swal from 'sweetalert2';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'PaperSectionsAbstract',
	computed:{
		IncludeAbstract(){
			return this.$store.state.paperEdit.meta.IncludeAbstract;
		},
	},
	methods: {
		addAbstract(){
			this.emitter.emit('addAbstract');
		},//e:addAbstract
		
		removeAbstract(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Are you sure?',
				text: 'This will remove your abstract and can not be undone.',
				icon: 'warning',
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Delete',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-outline-danger',
					confirmButton: 'btn btn-success me-2',
				},
			}).then((result) => {
				if (result.value) {
					this.$store.commit('paperEdit/meta/SET_IncludeAbstract', false);
	
					this.$store.commit('paperEdit/abstract/CONTENT', '');
					this.$store.commit('paperEdit/abstract/KEYWORDS', '');

					if(CKEDITOR.instances.ckPaperAbstract){
						CKEDITOR.instances.ckPaperAbstract.focusManager.blur(true);
						CKEDITOR.instances.ckPaperAbstract.destroy(true);
					}

					// Save the document
					PS_SaveDocument({
						isMetaDataChanges: true,
						message: 'Removed Abstract',
					}).then(()=>{
						// force focus on the paper body ckInstance
						if(_has(CKEDITOR.instances, 'ckPaperBody')){
							CKEDITOR.instances.ckPaperBody.focus();
						}

						this.emitter.emit('globalToasterOpen', {
							textContent: 'Abstract removed',
							variant: 'danger'
						});
					}).catch((error)=>{
						console.log(error);
					});

				}//e:if:result.value
			});//e:Swal
		},//e:removeAbstract
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorAbstract'
		});
	},
	components: {
		ToolDrawerTitle
	}
}
</script>
