var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "StalePaper", "is-close-button": false } },
    [
      _c("span", {
        staticClass: "svg icons8-error-cloud text-danger text-center",
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "nodal-title text-center" }, [
        _vm._v("\n\t\t\tWe saved some changes to your paper!\n\t\t"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "org-size" }, [
        _vm._v(
          "Being cautious – we saved some previously unsaved changes to your paper."
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "org-size" }, [
        _c("strong", [_vm._v("If you didn’t want to save those changes")]),
        _vm._v(
          ", no worries. We make it easy to revert to a previously saved version of this paper. You can see and restore your paper versions in the "
        ),
        _c("em", [_vm._v("Overview")]),
        _vm._v(" tab while working on your paper."),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "org-size" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://perrla.zendesk.com/hc/en-us/articles/12494596430107-How-Paper-Versioning-works-in-PERRLA-Online",
              target: "_blank",
            },
          },
          [
            _vm._v(
              "Click here to learn more about paper versioning with PERRLA Online"
            ),
          ]
        ),
        _vm._v("."),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container text-center mt-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnShowPaperSaves.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Show Paper Saves\n                    "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-success",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnIgnoreChanges.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Ignore Changes\n                    "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnSaveChanges.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        Save Changes\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }