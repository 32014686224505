<!--
- Outline Editor > Tool Drawer > Reference Wizard > Step 2/3
-->
<template>
	<div class="drawer">
		<close-warning
			close-id="CloseReferenceCreateStep2Apa7"
			confirm-title="Stop creating this reference?"
			confirm-body="If you stop now, the information you've entered on this page will be lost.  To finish making the reference, click the green button at the bottom."
			confirm-go-next-text="Don't create reference" />

		<div class="drawer-header">
			<tool-drawer-title
				:title="referenceTypeName"
				:close-to-route="{
					name: 'OutlineCreateReferenceStep1',
					params:{
						openReferenceTab: $route.params.openReferenceTab
					}
				}" />
		</div>
		
		<div class="drawer-body">
			<create-reference-step-2-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import CloseWarning from '@/views/Paper/TabEditor/ToolDrawer/CloseWarning';
import CreateReferenceStep2Body from '@/modules/CreateReferenceStep2Body'
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'ReferenceCreateStep2Apa7',
	computed: {
		referenceTypeName(){
			return this.$store.state.referenceRules.referenceTypeName;
		},
	},
	mounted() {
		// check for blank referenceTypeId on create only (webpack hot reload without a selected reference type)
		if(this.referenceTypeId === 0){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
			return;
		}
	},
	beforeRouteLeave(to, from, next) {
		// check if the reference wizard is dirty
		let findReferenceWizardForm = this.$store.state.forms.list.find((formData)=>{
			return formData.formId === 'formReferenceWizard';
		});
		if(findReferenceWizardForm && to.name !== 'OutlineCreateReferenceStep3'){
			// a reference wizard form exisit, lets check it
			if(findReferenceWizardForm.isDirty){
				this.emitter.emit('showCloseId', {
					closeId: 'CloseReferenceCreateStep2Apa7',
					nextTo: next,
				});
			} else {
				next();	
			}
		} else {
			next();
		}
	},
	components: {
		CloseWarning,
		CreateReferenceStep2Body,
		ToolDrawerTitle
	}
}
</script>



