var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", [_vm._v("Associate with...")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.associatedEntityTypeId,
                expression: "associatedEntityTypeId",
              },
            ],
            staticClass: "form-select",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.associatedEntityTypeId = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.calcAssociatedEntityTypeOptions, function (singleOption) {
            return _c(
              "option",
              {
                key: singleOption.name,
                domProps: { value: singleOption.value },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(singleOption.name) +
                    "\n                    "
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-9" }, [
        _vm.associatedEntityTypeId != "0"
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.associatedEntityId,
                    expression: "associatedEntityId",
                  },
                ],
                staticClass: "form-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.associatedEntityId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.associatedEntityIdSelectChange,
                  ],
                },
              },
              _vm._l(_vm.calcAssociatedEntityOptions, function (singleOption) {
                return _c(
                  "option",
                  {
                    key: singleOption.name,
                    domProps: { value: singleOption.value },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(singleOption.name) +
                        "\n                    "
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }