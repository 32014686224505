<!-- StalePaper.vue -->

<!--
- Paper Edit > Generic Modal > Stale Paper
-->

<template>
	<generic-modal-wrap modal-id="StalePaper" :is-close-button="false">
        <span class="svg icons8-error-cloud text-danger text-center" />

		<h2 class="nodal-title text-center">
			We saved some changes to your paper!
		</h2>
        
        <p class="org-size">Being cautious &ndash; we saved some previously unsaved changes to your paper.</p>
        <p class="org-size"><strong>If you didn&rsquo;t want to save those changes</strong>, no worries. We make it easy to revert to a previously saved version of this paper. You can see and restore your paper versions in the&nbsp;<em>Overview</em>&nbsp;tab while working on your paper.</p>
        <p class="org-size"><a href="https://perrla.zendesk.com/hc/en-us/articles/12494596430107-How-Paper-Versioning-works-in-PERRLA-Online" target="_blank">Click here to learn more about paper versioning with PERRLA Online</a>.</p>
        
		<div class="container text-center mt-3">
            <div class="row">
                <div class="col d-grid">
                    <button
                        class="btn btn-outline-primary"
                        type="button"
                        @click.prevent="btnShowPaperSaves">
                        Show Paper Saves
                    </button>
                </div>
                <div class="col d-grid">
                    <button
                        class="btn btn-outline-success"
                        type="button"
                        @click.prevent="btnIgnoreChanges">
                        Ignore Changes
                    </button>
                </div>
                <div class="col d-grid">
                    <button
                        class="btn btn-success"
                        type="button"
                        @click.prevent="btnSaveChanges">
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
        
	</generic-modal-wrap>
</template>

<script lang="js">
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import GenericModalWrap from '@/components/modals/GenericWrap';
// import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_InjectContent from '@/services/paper/injectContent';
// import PS_SaveDocument from '@/services/paper/saveDocument';

export default {
	name: 'ModalStalePaper',
	computed:{
		passData(){
			if(!_isEmpty(this.$store.state.modals.passData)){
				return this.$store.state.modals.passData;
			}
			return null;
		},
	},
    methods:{
        btnIgnoreChanges(){
            // loads from server


            // save both version, ignored and restored from local (stagger saves)
            // first save ignored message: 'save of ignored local changes' (shows up 2nd in version list)
            // then save what actually is in local storage: 'save loaded from server' (shows up at the top of the version list)


            this.$store.commit('loaders/ADD_ID', 'App');

            ApiS_MakeCall({
				method: 'GET',
				params: {
					contentType: config.enums.DownloadPaperContentType.COMPRESSED,
					encryptedId: this.$store.state.paperEdit.openObject.encryptedID,
					platform: 1, // just Online Papers here
				},
				responseType: 'text',
				url: 'v4/Paper',
			}).then((responseData)=>{
				PS_InjectContent({
					compressedPaperData: responseData,
				}).then(()=>{
                    this.$store.commit('paperEdit/localVersion/SET_IS_FORCE_SAVE_AFTER_LOADING', true);

                    // this.$store.commit('loaders/ADD_ID', 'App');

                    // PS_SaveDocument({
                    //     message: 'Saved paper - ignored from local',
                    // }).then(()=>{
                        
                    //     this.$store.commit('modals/CLOSE', 'StalePaper');
                    //     this.$store.commit('loaders/REMOVE_ID', 'App');
                        
                    // });//e:PS_SaveDocument
                });
			});//e:ApiS_MakeCall
            
        },//e:btnIgnoreChanges

        btnSaveChanges(){
            // loads from local & saves to server automatically
            if(this.passData && _has(this.passData, 'localContentToLoad')){
                this.$store.commit('loaders/ADD_ID', 'App');

                PS_InjectContent({
                    compressedPaperData: this.passData.localContentToLoad,
                }).then(()=>{
                    this.$store.commit('paperEdit/localVersion/SET_IS_FORCE_SAVE_AFTER_LOADING', true);
                });//e:PS_InjectContent
            }
        },//e:btnSaveChanges

        btnShowPaperSaves(){
            // goes to Paper Saves in Overview without saving any of the paper content
            if(this.passData && _has(this.passData, 'localContentToLoad')){
                this.$store.commit('loaders/ADD_ID', 'App');
                            
                PS_InjectContent({
                    compressedPaperData: this.passData.localContentToLoad,
                }).then(()=>{
                    this.$store.commit('paperEdit/localVersion/SET_IS_FORCE_SAVE_AFTER_LOADING', true);

                    this.$router.push({
                        name: 'PaperTabOverview'
                    }).catch(()=>{});
                    
                });//e:PS_InjectContent
            } else {
                this.$router.push({
                    name: 'PaperTabOverview'
                }).catch(()=>{});
            }
        },//e:btnShowPaperSaves
    },
	components:{
		GenericModalWrap
	},
}
</script>

<style lang="scss" scoped>
    span.svg {
        font-size: 8.2rem;
        line-height: 1;
    }
</style>
