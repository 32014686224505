/*
- Create Document
-- for create paper modals
*/

export default {
	data() {
		return {
			associatedClassId: 0,
			associatedClassName: '',
			fillInTeacherName: '',
		}
	},
	created(){
		// $emits after 'syncData' is done from PaperList.vue
		this.emitter.on('checkClassAssociationInCreateDocumentModal', ()=>{
			// console.log('checkClassAssociationInCreateDocumentModal 3');

			let associatedClassId = parseInt(this.$route.params.associatedClassId) || 0;
			// console.log(associatedClassId);
			
			if(associatedClassId != 0){
				// look this class up by id
				let thisClassData = this.$store.state.classes.list.find((classData)=>{
					return classData.classID === associatedClassId;
				});
				if(thisClassData){
					// class found
					this.associatedClassName = thisClassData.name;
					this.associatedClassId = thisClassData.classID;
					this.fillInTeacherName = thisClassData.teacherName;
					
					// Research Papers will inject the class name into the form as text
					if(this.courseName){
						this.courseName.data = thisClassData.name;
					}
				}
				// console.log('thisClassData');
				// console.log(thisClassData);
			}
		});
	},
	destroyed(){
		this.emitter.off('checkClassAssociationInCreateDocumentModal');
	},
}
