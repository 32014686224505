var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "nav-header" }, [
    _c(
      "a",
      {
        staticClass: "logo-link ps-3 me-4",
        attrs: { title: "PERRLA, LLC", href: _vm.calcHomeUrl },
      },
      [
        _c("img", {
          staticClass: "me-2",
          attrs: {
            src: "///perrla.s3.amazonaws.com/images/logo-p-small.png",
            alt: "PERRLA, LLC",
          },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("PERRLA")]),
        _vm._v(" "),
        _c("h1", [_vm._v("PERRLA")]),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "navbar-nav me-auto d-none d-sm-flex" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "///perrla.com/#/how-it-works" },
          },
          [_vm._v("How it works")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "///perrla.com/#/why-perrla" },
          },
          [_vm._v("Why PERRLA")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "///perrla.com/#/pricing" },
          },
          [_vm._v("Pricing")]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "https://perrla.zendesk.com/hc/en-us" },
          },
          [_vm._v("Help")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }