var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row mb-2" },
        [
          _c("h1", { staticClass: "float-start me-3 mb-0" }, [
            _vm._v("\n\t\t\tReminders\n\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success float-start me-3",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$store.commit("modals/OPEN", "ReminderCreate")
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus-lg me-1" }),
              _vm._v(" New Reminder\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c("grid-search", {
            staticClass: "ms-auto col-4",
            attrs: { "placeholder-text": "Search Reminders" },
            on: { "update-grid": _vm.calcGridData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-12 min-height overflow-y-auto position-relative",
          },
          [
            _vm.filteredGridData.length > 0
              ? _c(
                  "table",
                  {
                    staticClass:
                      "table table-borderless entity-grid table-fixed-header",
                  },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Title",
                              colspan: "2",
                              "sort-key": "name",
                              "entity-id": _vm.$enums.Entity.REMINDER,
                              "is-local-storage": true,
                              "is-title": true,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("th", [_vm._v("Association")]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Due Date",
                              "set-width": "175px",
                              "sort-key": "dueDate",
                              "entity-id": _vm.$enums.Entity.REMINDER,
                              "is-local-storage": true,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.filteredGridData, function (reminderData) {
                          return _c("reminder-row", {
                            key: reminderData.reminderID,
                            attrs: {
                              "reminder-associated-entity-id":
                                reminderData.associatedEntityID,
                              "reminder-associated-entity-type-id":
                                reminderData.associatedEntityTypeID,
                              "reminder-class-id": reminderData.classID,
                              "reminder-due-date": reminderData.dueDate,
                              "reminder-encrypted-id":
                                reminderData.reminderEncryptedID,
                              "reminder-id": reminderData.reminderID,
                              "reminder-is-complete": reminderData.isComplete,
                              "reminder-name": reminderData.name,
                            },
                          })
                        }),
                        _vm._v(" "),
                        _c("tr", { staticClass: "tr-padding-left-icon" }, [
                          _c("td", { attrs: { colspan: "6" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-primary border-0",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnToggleShowCompleted.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm.isShowCompleted
                                  ? _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tHide Completed Items\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tShow Completed Items\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]
                )
              : _c("div", { staticClass: "text-muted text-center mt-5" }, [
                  _c("p", [_vm._v("No reminders found.")]),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("route-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }