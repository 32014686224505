<!--
- Reference Edit > Papers Tab
-->
<template>
	<nav-tab-content>
		<div class="row">
			<div class="col-12 position-relative">
				<table class="table table-borderless entity-grid" v-if="filteredGridData && filteredGridData.length > 0">
					<thead>
						<tr>
							<sortable-grid-th 
								cell-text="Title" 
								colspan="2"
								sort-key="title"
								:is-title="true"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<th class="table-spacer" />
							<th>
								Type
							</th>
							
							<th class="table-spacer" width="4px" />

							<sortable-grid-th 
								cell-text="Class" 
								sort-key="className"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />

							<th class="table-spacer" />
						
							<sortable-grid-th 
								cell-text="Due Date" 
								sort-key="dueDate"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />
							
							<th class="table-spacer" width="4px" />

							<sortable-grid-th 
								cell-text="Modified" 
								sort-key="lastModifiedDate"
								:sort-order-by="sortOrderBy"
								:sorts="sorts"
								@update-grid="updateGridSort" />
								
							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<paper-row
							v-for="paperData in filteredGridData"
							:paper-class-name="paperData.className"
							:paper-description="paperData.description"
							:paper-due-date="paperData.dueDate"
							:paper-encrypted-id="paperData.encryptedID"
							:paper-format-version-id="paperData.paperFormatVersionID"
							:paper-id="paperData.paperID"
							:paper-is-complete="paperData.isComplete"
							:paper-last-modified-date="paperData.lastModifiedDate"
							:paper-start-date="paperData.startDate"
							:paper-title="paperData.title"
							:paper-type="paperData.paperType"
							:key="paperData.paperID"
							context="ReferenceEdit" />
					</tbody>
				</table>
				<div class="text-muted" v-else>
					<p class="org-size">
						No papers found for this Reference.
					</p>
				</div>
			</div>
		</div>
	</nav-tab-content>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import mixinResizer from '@/mixins/resizer';
import NavTabContent from '@/components/NavTabContent';
import PaperRow from '@/components/EntityGrid/PaperRow';
import RS_LoadByRoute from '@/services/reference/loadByRoute';
import SortableGridTh from '@/components/SortableGridTh';

export default {
	name: 'ReferenceEditTabPapers',
	mixins: [mixinResizer],
	data(){
		return{
			filteredGridData: [],

			sortOrderBy: 'lastModifiedDate',
			sorts: {
				'className': 1,
				'dueDate': 1,
				'lastModifiedDate': -1,
				'title': 1,
			},
			rzRef: 'tabContentScroll',
		}
	},
	computed:{
		entityId(){
			return config.enums.Entity.PAPER;
		},
	},
	methods:{
		calcGridData(){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.paperList.list);	// clone so i can add a sortBy property 

			// loop through each paper and prep for display
			_data.forEach((paperData) =>{
				// do not include Add-In papers, or any non valid formats
				if(!paperData.isOnlinePaper || !_includes(config.enums.Format, paperData.paperFormatVersionID)){
					return false;
				}
				
				// due date isn't required - if you get back the default server value just blank it out on display
				if(!_has(paperData, 'dueDate') || paperData.dueDate === '2099-01-01T00:00:00') {
					paperData.dueDate = '';
				}
				
				// rollbar error here for some users, so add an extra if just incase
				if(_has(paperData, 'genesisReferences')){
					if(paperData.genesisReferences.includes(this.$store.state.referenceEdit.openObject.referenceID)){
						let _paperData = _cloneDeep(paperData);
						if(paperData.lastModifiedDate) {
							paperData.sortByLastModifiedDate = paperData.lastModifiedDate;	// remove hh:mm:ss so you can sort down to the day
						} else {
							paperData.sortByLastModifiedDate = '2000-01-01';
						}
						searchedArray.push(_paperData);
					}
				}
			});//e:forEach

			if(this.sorts[this.sortOrderBy] === 1){
				this.filteredGridData = _orderBy(searchedArray, [searchedArray => searchedArray[this.sortOrderBy].toLowerCase()], ['asc']);
			} else {
				this.filteredGridData = _orderBy(searchedArray, [searchedArray => searchedArray[this.sortOrderBy].toLowerCase()], ['desc']);
			}

			this.$nextTick(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		},//e:calcGridData

		updateGridSort($obj){
			this.sortOrderBy = $obj.sortKey;
			this.sorts = $obj.sorts;
			this.calcGridData();
		},
	},
	// created() {},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App');

		RS_LoadByRoute().then(()=>{
			this.calcGridData();
		});
	},
	components:{
		NavTabContent,
		PaperRow,
		SortableGridTh
	},
}

</script>

