var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c(
      "section",
      {
        staticClass: "section-text pt-0",
        staticStyle: {
          background:
            "url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff",
        },
      },
      [
        _c("main", { staticClass: "container-text mt-0 pt-3" }, [
          _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
            _c("div", { staticClass: "inset-body" }, [
              _c("img", {
                staticClass: "mx-auto d-block",
                attrs: {
                  src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                  width: "140px",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Thanks for signing up for a Free Trial " +
                    _vm._s(_vm.firstName) +
                    "!"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "We just sent you an email with a 6-digit verification code. Enter the code from the email below."
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                { staticClass: "mt-4" },
                [
                  _c("form-input", {
                    attrs: {
                      label: "6-Digit Verification Code",
                      type: "text",
                      "form-id": _vm.zFormId,
                      "validate-enum": _vm.$enums.Validation.NUMERIC_REQUIRED,
                    },
                    model: {
                      value: _vm.code,
                      callback: function ($$v) {
                        _vm.code = $$v
                      },
                      expression: "code",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "d-grid" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.btnVerifyAccount.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\tVerify My Account\n\t\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "FreeTrialAlternateEmail",
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\tIf you didn't receive the email or need to change the email address, click here.\n\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\tHere's the information we have for your account so far:"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-4 fw-bold" }, [
                        _vm._v("Name:"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.firstName) +
                          " " +
                          _vm._s(_vm.lastName) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-4 fw-bold" }, [
                        _vm._v("Account Email:"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.email) + " "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "ms-4 fw-bold" }, [
                        _vm._v("Institution:"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.school) + " "),
                      _c("br"),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "section-text" }, [
      _c("main", { staticClass: "container-text mt-2" }, [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v("\n\t\t\t\tAnswers to a few common questions\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("What do I need to do now?")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("h4", [_vm._v("What if I didn't get the verification email?")]),
        _vm._v(" "),
        _c("p", { staticClass: "small" }, [
          _vm._v(
            '\n\t\t\t\tFirst, you\'ll want to check your spam or junk folders. The verification email will be sent from "support@perrla.com".\n\t\t\t'
          ),
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "small mb-5" },
          [
            _vm._v(
              "\n\t\t\t\tIf you still can't find it, you can use a different email address by \n\t\t\t\t"
            ),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "FreeTrialAlternateEmail",
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tclicking here\n\t\t\t\t")]
            ),
            _vm._v(
              ". If you continue to have trouble, email our Customer Support team at support@perrla.com. Be sure to tell them the email address you are trying to use for your Free Trial.\n\t\t\t"
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", [_vm._v("Why do I have to verify my email?")]),
        _vm._v(" "),
        _c("p", { staticClass: "small mb-5" }, [
          _vm._v(
            "\n\t\t\t\tWe communicate with all our users primarily through email. This helps us ensure we are emailing the correct address when we send updates, account information, or reply to any Support questions.\n\t\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("What if I've used PERRLA before?")]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "small" },
          [
            _vm._v(
              "\n\t\t\t\tIf you've used PERRLA before, then we recommend you try to\n\t\t\t\t\t"
            ),
            _c("router-link", { attrs: { to: { name: "Login" } } }, [
              _vm._v(
                "\n\t\t\t\t\t\tlog in using that account information\n\t\t\t\t\t"
              ),
            ]),
            _vm._v(
              ".\n\t\t\t\tAll of the papers and references you've already created will be located in your previous account.\n\t\t\t"
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("h4", [_vm._v("Have more questions?")]),
        _vm._v(" "),
        _c("p", { staticClass: "small" }, [
          _vm._v("\n\t\t\t\tWe have more answers!\n\t\t\t"),
        ]),
        _vm._v(" "),
        _vm._m(3),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-5" }, [
      _c("h1", [_vm._v("PERRLA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "small mb-5" }, [
      _vm._v(
        "\n\t\t\t\tTo start using PERRLA, you'll need to enter the 6-digit verification code that we have emailed you. You can copy and paste the code into the field above. Then, click the "
      ),
      _c("em", [_vm._v("Verify My Account")]),
      _vm._v(" button.\n\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "small mb-5" }, [
      _vm._v(
        "\n\t\t\t\tYou can reset your password on the log in page if you've forgotten it. If you don't have access to that email or can't remember your account, you can "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@perrla.com?subject=I%20can%27t%20access%20my%20PERRLA%20Account",
          },
        },
        [_vm._v("email our Customer Support team")]
      ),
      _vm._v(" for help locating your account.\n\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "small" }, [
      _vm._v("\n\t\t\t\tCheck out our "),
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v("Help Center")]
      ),
      _vm._v(
        " for more articles and videos on how to use PERRLA. Or, reach out to our "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@perrla.com",
            id: "https-www.perrlacomplete.com-ContactSupport",
          },
        },
        [_vm._v("Customer Support team")]
      ),
      _vm._v(
        " directly. They are available 7 days a week until 10pm Central.\n\t\t\t"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }