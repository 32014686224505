var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c("p", {
        directives: [
          { name: "add-blank-target", rawName: "v-add-blank-target" },
        ],
        staticClass: "reference-text word-break",
        domProps: { innerHTML: _vm._s(_vm.referenceObject.displayValue) },
      }),
      _vm._v(" "),
      _vm.searchTerm === ""
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _vm.filteredResearchNotesForThisReference &&
              _vm.filteredResearchNotesForThisReference.length > 0
                ? _c("div", { key: "btnExpand", staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-hover btn-hover-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleIsExpanded.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.isExpanded
                          ? _c("span", { key: "hideExpanded" }, [
                              _vm._v("Hide"),
                            ])
                          : _c("span", { key: "showExpanded" }, [
                              _vm._v("Show"),
                            ]),
                        _vm._v(" research notes\n\t\t\t\t"),
                      ]
                    ),
                  ])
                : _c(
                    "p",
                    { key: "elseNoExpand", staticClass: "text-muted ms-3" },
                    [
                      _vm._v(
                        "\n\t\t\t\tUse the Research Tab to create research notes for this reference.\n\t\t\t"
                      ),
                    ]
                  ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "route-fade" } }, [
        _vm.isExpanded || _vm.searchTerm != ""
          ? _c("div", { key: "researchNotesExpanded", staticClass: "mt-2" }, [
              _vm.filteredResearchNotesForThisReference &&
              _vm.filteredResearchNotesForThisReference.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.filteredResearchNotesForThisReference,
                      function (researchNoteData) {
                        return _c("research-note-row", {
                          key: researchNoteData.genesisResearchNoteUniqueId,
                          attrs: {
                            "research-note-citation": researchNoteData.citation,
                            "research-note-citation-output":
                              researchNoteData.citationOutput,
                            "research-note-genesis-reference-id":
                              researchNoteData.genesisReferenceId,
                            "research-note-genesis-research-note-id":
                              researchNoteData.genesisResearchNoteId,
                            "research-note-genesis-research-note-unique-id":
                              researchNoteData.genesisResearchNoteUniqueId,
                            "research-note-reference-unique-id":
                              researchNoteData.referenceUniqueID,
                            "research-note-text": researchNoteData.note,
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }