<!--
- Dashboard > Route Modal > Create Reference List
-->
<template>
	<div>
		<module-create-reference-list />
	</div>
</template>

<script>
import ModuleCreateReferenceList from '@/modules/paper/CreateReferenceList'

export default {
	name: 'PaperListCreateReferenceList',
	components: {
		ModuleCreateReferenceList
	}
}
</script>
