<!--
- Paper Edit > Organize Tab > Research Notes > Add Reference From Library
-->
<template>
	<generic-modal-wrap modal-id="ResearchNotesAddReferenceFromLibrary">
		<h2 class="nodal-title">
			Add reference from library
		</h2>

		<add-reference-from-library-header :is-drawer="false" />

		<add-reference-from-library-body :is-drawer="false" />

		<div class="row">
			<div class="col">
				<div class="d-grid mt-2">
					<button type="submit" class="btn btn-success" @click.prevent="$store.commit('modals/CLOSE', 'ResearchNotesAddReferenceFromLibrary')">
						Finished
					</button>
				</div>
			</div>
		</div><!--row-->
	</generic-modal-wrap>
</template>

<script>
import AddReferenceFromLibraryBody from '@/modules/AddReferenceFromLibrary/Body';
import AddReferenceFromLibraryHeader from '@/modules/AddReferenceFromLibrary/Header';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'OrganizeAddReferenceFromLibrary',
	components: {
		AddReferenceFromLibraryBody,
		AddReferenceFromLibraryHeader,
		GenericModalWrap
	}
}
</script>
