<!--
- Research Notes Module > Research Note Row
-- Live version with Edits and Deletes
-- can be called from ReferenceEdit or PaperEdit
-->

<template>
	<div
		:class="['research-note-row clearfix p-2 mb-2', {'sortable-row-pre-delete': isPreDelete}]"
		@click.prevent="setToEditMode($event.target)"
		@mouseover="isHover = true"
		@mouseleave="isHover = false">
		<div v-if="isPreDelete" class="sortable-row-pre-delete-content">
			<p>
				This research note has been deleted.
				<a href="#" class="float-end" title="Undo Delete" @click.prevent="btnUndoDelete">
					<span class="svg icons8-undo" />
				</a>
			</p>
		</div>
		
		<div v-else>
			<a
				v-if="!isEditNote"
				href="#"
				:class="['btn-delete-research-note float-end', {'invisible':!isHover}]" title="Delete Research Note"
				@click.prevent="preDeleteResearchNote">
				<div class="svg bi-x-lg" />
			</a>

			<div
				v-html="researchNoteText"
				v-show="!isEditNote"
				class="research-note-text"
				title="Click to Edit Research Note"
				@click.prevent="editResearchNote" />

			<a
				v-if="!isEditNote && calcCitationOutput == ''"
				href="#"
				title="Add Citation"
				key="aAddCitationBeta"
				:class="['research-note-citation btn btn-hover btn-hover-success-underline p-0 float-end', {'invisible':!isHover}]"
				@click.prevent="openAddEditCitation(false)">
				<span class="svg bi-plus-lg" /> Add citation
			</a>

			<a
				v-if="!isEditNote && calcCitationOutput != ''"
				href="#"
				title="Edit Citation"
				key="aEditCitation"
				:class="['research-note-citation btn btn-hover btn-hover-success-underline p-0 float-end']"
				@click.prevent="openAddEditCitation(true)">
				<span v-html="calcCitationOutput" />
			</a>

			<textarea
				v-show="isEditNote"
				class="form-control"
				ref="editTextArea"
				rows="2"
				v-model="editTextareaValue"
				@blur="blurEditTextarea"
				@input="typeInput($event.target.value)"
				@keypress.shift.enter.prevent="updateResearchNote"
				@keypress.enter.exact.prevent="updateResearchNote"
				@keyup.esc="blurEditTextarea" />
		</div>
	</div>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import HelperGetCitationOutputForResearchNote from '@/helpers/get-citation-output-for-research-note';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';

const stoUndoTime = 3000;

export default {
	name: 'ResearchNoteRowLive',
	mixins: [mixinExpandTextareaById],
	props:{
		researchNoteCitation:{
			required: true,
			type: String,
		},
		researchNoteGenesisReferenceId:{
			required: true,
			type: Number
		},
		researchNoteReferenceUniqueId:{
			required: true,
			type: String,
		},
		researchNoteGenesisResearchNoteId:{
			required: true,
			type: Number,
		},
		researchNoteGenesisResearchNoteUniqueId:{
			required: true,
			type: String,
		},
		
		researchNoteText:{
			required: true,
			type: String,
		},
	},
	computed:{
		calcCitationOutput(){
			if(this.researchNoteCitation){
				return HelperGetCitationOutputForResearchNote(this.researchNoteCitation);
			}

			return '';
		},

	},
	data() {
		return {
			editTextareaValue: '',
			isEditNote: false,	// toggles Text vs Textarea
			isHover: false,
			isPreDelete: false,
			stoUndoDelete: null,         // setTimeout to undo this delete
		}
	},
	methods:{
		blurEditTextarea(){
			this.isEditNote = false;
		},//e:blurEditTextarea

		btnUndoDelete(){
			clearTimeout(this.stoUndoDelete);
			this.isPreDelete = false;
		},

		editResearchNote(){
			this.isEditNote = true;

			// convert br tags to line breaks to inject into the textarea
			this.editTextareaValue = this.researchNoteText.replace(/<br[^>]*>/gi, '\n');

			this.$nextTick(()=>{
				if(this.$refs.editTextArea){
					this.$refs.editTextArea.focus();
					this.expandTextarea('editTextArea');
				}
			});
		},//e:editResearchNote

		deleteResearchNote(){
			// console.log('actual deleteResearchNote');

			ApiS_MakeCall({
				method: 'DELETE',
				params: {
					'id': this.researchNoteGenesisResearchNoteId,
				},
				url: 'v3/GenesisResearchNote',
			}).then(()=>{
				
				this.$store.commit('researchNotes/REMOVE_BY_UNIQUE_ID', this.researchNoteGenesisResearchNoteUniqueId); 

				// emit back up to the parent to remove
				this.$emit('update-grid');

			});//e:dispatch:api/makeCall
		},//e:deleteResearchNote

		openAddEditCitation(isEditCitation){
			if(isEditCitation){
				let parseCitationData = JSON.parse(this.researchNoteCitation);

				if(_has(parseCitationData.citationData, 'citationUniqueID')){
					this.$store.commit('modals/OPEN', 'ResearchNotesModalsCitationEdit');
					this.$store.commit('modals/SET_MODAL_DATA', {
						citationUniqueId: parseCitationData.citationData.citationUniqueID.toUpperCase(),
						referenceUniqueId: this.researchNoteReferenceUniqueId.toUpperCase(),
						researchNoteUniqueId: this.researchNoteGenesisResearchNoteUniqueId.toUpperCase(),
					});
				}

			} else {
				this.$store.commit('modals/OPEN', 'ResearchNotesModalsCitationAdd');

				this.$store.commit('modals/SET_MODAL_DATA', {
					referenceUniqueId: this.researchNoteReferenceUniqueId.toUpperCase(),
					researchNoteUniqueId: this.researchNoteGenesisResearchNoteUniqueId.toUpperCase(),
				});
				
			}

		},//e:openAddEditCitation

		preDeleteResearchNote(){
			this.isPreDelete = true;
			
			clearTimeout(this.stoUndoDelete);

			this.stoUndoDelete =  setTimeout(()=>{
				this.deleteResearchNote();
			}, stoUndoTime)

		},//e:preDeleteResearchNote
		
		// fires when clicking on this entire component
		setToEditMode(eventTarget){
			if (!this.isEditNote && !eventTarget.classList.contains('research-note-citation') && !eventTarget.classList.contains('svg') && eventTarget.tagName.toLowerCase() !== 'span') {
				this.editResearchNote()	
			}
		},

		typeInput(value){
			this.$emit('input', value);
			this.expandTextarea('editTextArea');
		},//e:typeInput

		updateResearchNote(){
			// check if user just deleted content from the note while editing
			if(this.editTextareaValue.trim() === ''){
				this.isEditNote = false;
				return false;
			}

			// convert line breaks in the textarea to br tags for html injection
			let _updatedNoteText = this.editTextareaValue.replace(/(?:\r|\n|\r\n)/g, '<br/>');
			console.log(_updatedNoteText);
			let sendData = {
				'GenesisReferenceID': this.researchNoteGenesisReferenceId,
				'GenesisResearchNoteID': this.researchNoteGenesisResearchNoteId,
				'Note': _updatedNoteText,
				'Citation': this.researchNoteCitation,
			}

			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				body: sendData,
				method: 'PUT',
				responseType: 'json',
				url: 'v3/GenesisResearchNote',
			}).then((responseData)=>{
				// update the vuex store (and local storage) for this Research Note Text
				this.$store.commit('researchNotes/UPDATE_TEXT_BY_UNIQUE_ID', {
					newNoteText: responseData.note,
					researchNoteUniqueId: this.researchNoteGenesisResearchNoteUniqueId,
				}); 
				
				this.isEditNote = false;

				this.$store.commit('loaders/REMOVE_ID', 'App');

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Changes saved',
				});

			});//e:ApiS_MakeCall
		},//e:updateResearchNote

	},
}
</script>


<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla.scss";
	
	@include color-mode(dark) {
		.research-note-row{
			background: $extra-dark;
			border: 1px solid $extra-dark;

			&:hover{
				border-color: $secondary-500;
			}
			&.sortable-row-pre-delete{
				background: $danger-500;

				&:hover {
					border-color: $danger-100;
				}
				
				.sortable-row-pre-delete-content {
					.svg {
						color: $danger-100;
					}
				}
			}
		}
	}
	
	.research-note-row {
		background-color: $card-bg;
		border: 1px solid $card-bg;
		border-radius: $border-radius;
		margin-bottom: 0.75rem;
		padding: 0.5rem 0.5rem 1rem;

		&:hover{
			border-color: $primary-300;
			cursor: pointer;
		}
		
	
		.research-note-text {
			font-size: 1rem;
			line-height: 1.5rem;
			padding-bottom: 0.5rem;
		}

		.research-note-citation {
			font-size: 0.8rem;
			font-weight: 400;
			line-height: 0.8rem;
    
		}

		&.sortable-row-pre-delete{
			background: $danger-100;

			&:hover {
				border-color: $danger-300;
			}
			
			.sortable-row-pre-delete-content {
				p {
					font-size:14px;
					line-height: 21px;
				}
				.svg {
					color: $danger-700;
					font-size: 16px;
					line-height: 0;
				}
			}
		}

		.btn-delete-research-note{
			margin: 0 0 0.5rem 0.5rem;
			.svg {
				color: $danger;
				font-size: 18px;
				line-height: 0;
				
				&:hover{
					color: $danger-300;
				}
			}
		}

	}
</style>

