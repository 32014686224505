/*
- Auto Save
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.autoSave.x
*/
import _forEach from 'lodash/forEach';
import config from '@/config';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import PS_SaveDocument from '@/services/paper/saveDocument';
import router from '@/router';

export default {
	namespaced: true,
	
	state:{
		sinTimer: null,
		timerMs: 0,	// millseconds of autosave increment - gets converted during /Sync
		
	},//e:state

	mutations: {
		CLEAR_TIMER(state){
			if(state.sinTimer){
				clearInterval(state.sinTimer);
				state.sinTimer = null;
			}
		},
		SET_TIMER_MS(state, value) {
			state.timerMs = value;
		},
		START_TIMER(state) {
			if(!state.sinTimer && state.timerMs > 0){
				// no other timer has started AND user has an autosave value
				state.sinTimer = setInterval(()=>{
					let isNeedToSave = false;

					// loop trough all ck instances on the page
					_forEach(CKEDITOR.instances, (ckInstance)=>{
						if(ckInstance.checkDirty()){
							isNeedToSave = true;
							return false;	// only need one
						}
					});//e:forEach
					
					if(isNeedToSave){
						if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
							PS_SaveDocument({
								isAutoSave: true,	
								message: 'Saved paper - auto-save',
							});
						} else if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
							OS_SaveContent();
						}
					}
				}, state.timerMs);
			}
		},
	},//e:mutations

}