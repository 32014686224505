var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      [
        _c("h2", [_vm._v("Reference Data - " + _vm._s(_vm.referenceTypeName))]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.editReference && _vm.editReference.referenceTypeID
          ? _c("reference-types", {
              attrs: {
                "reference-type-id": _vm.editReference.referenceTypeID,
                "reference-rules-string": _vm.editReference.data,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("reference-output"),
        _vm._v(" "),
        _c("copy-json-data", {
          attrs: {
            "data-to-display": JSON.stringify(
              _vm.$store.state.referenceRules.rulesObjectData
            ),
          },
        }),
        _vm._v(" "),
        _c("h2", { staticStyle: { "margin-top": "32px" } }, [
          _vm._v("\n\t\t\tReference Information\n\t\t"),
        ]),
        _vm._v(" "),
        _vm.nickname != ""
          ? _c("form-input", {
              attrs: {
                label: "Nickname",
                "help-text": "Optional. Shows in Library & Panel.",
                "pendo-grab-text": "Txt_RefEdit_Nickname",
              },
              model: {
                value: _vm.nickname,
                callback: function ($$v) {
                  _vm.nickname = $$v
                },
                expression: "nickname",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-textarea", {
          attrs: {
            label: "Description",
            "is-autosize": true,
            "help-text": "Add a private description to your Reference.",
            "pendo-grab-text": "Txt_RefEdit_Description",
          },
          model: {
            value: _vm.note,
            callback: function ($$v) {
              _vm.note = $$v
            },
            expression: "note",
          },
        }),
        _vm._v(" "),
        _c("form-tags", {
          attrs: { "pendo-grab-text": "Txt_RefEdit_Tags" },
          model: {
            value: _vm.tags,
            callback: function ($$v) {
              _vm.tags = $$v
            },
            expression: "tags",
          },
        }),
        _vm._v(" "),
        _vm.showDeleteReference
          ? _c(
              "div",
              { key: "divFooterButtonsReferenceList", staticClass: "row mt-2" },
              [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.deleteReferenceConfirm.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tArchive Reference\n\t\t\t\t\t")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.updateReference.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tUpdate Reference\n\t\t\t\t\t")]
                    ),
                  ]),
                ]),
              ]
            )
          : _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "mt-2 btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.updateReference.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tUpdate Reference\n\t\t\t")]
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info" }, [
      _c("span", { staticClass: "svg bi-check-circle-fill float-start" }),
      _vm._v(" "),
      _c("p", { staticClass: "ps-3" }, [
        _vm._v("\n\t\t\t\tCommonly used fields are outlined in blue.\n\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }