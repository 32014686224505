<!--
- Paper Drawer > Title Page
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Title Page" />
		</div>
		
		<div class="drawer-body">
			<form>
				<form-input
					label="Title"
					v-model="title"
					@input="calcRunningHead"
					:form-id="zFormId"
					:validate-enum="$enums.Validation.REQUIRED" />

				<form-input
					v-if="showResearchPaperFields"
					label="Author"
					:form-id="zFormId"
					v-model="author" />

				<form-input
					v-if="showResearchPaperFields && $store.state.paperEdit.meta.PaperFormatVersionID !== $enums.Format.MLA9"
					label="Institution"
					:form-id="zFormId"
					v-model="institution" />

				<div v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9">
					<form-input
						label="Instructor"
						:form-id="zFormId"
						v-model="instructor" />

					<form-input
						label="Course Number &amp; Name"
						:form-id="zFormId"
						v-model="courseName" />
					
					<form-datepicker
						label="Date"
						id="TP_mla9_dueDate"
						:form-id="zFormId"
						:paper-format="$enums.Format.MLA9"
						@date-set="dueDateSet"
						@date-clear="dueDateClear" />
				</div>
							
				
				<div v-if="isStudentPaper">
					<div v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7">
						<form-input
							label="Course Number &amp; Name"
							:form-id="zFormId"
							v-model="courseName" />

						<form-input
							label="Instructor"
							:form-id="zFormId"
							v-model="instructor" />

						<form-datepicker
							label="Date"
							id="TP_apa7_dueDate"
							:form-id="zFormId"
							:paper-format="$enums.Format.APA7"
							@date-set="dueDateSet"
							@date-clear="dueDateClear" />
					</div>
					<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9">
						<form-input
							label="Course Number &amp; Name"
							:form-id="zFormId"
							v-model="courseName" />

						<form-input
							label="Instructor"
							:form-id="zFormId"
							v-model="instructor" />

						<form-datepicker
							label="Date"
							id="TP_turabian9_dueDate"
							:form-id="zFormId"
							:paper-format="$enums.Format.Turabian9"
							@date-set="dueDateSet"
							@date-clear="dueDateClear" />
					</div>
				</div>
				
				<div v-if="$store.state.paperEdit.meta.DocumentType === $enums.DocumentType.RESEARCH_PAPER && $store.state.paperEdit.meta.PaperFormatVersionID !== $enums.Format.MLA9 && $store.state.paperEdit.meta.PaperFormatVersionID !== $enums.Format.Turabian9">
					<form-input-running-head
						v-if="showRunningHead" 
						:form-id="zFormId"
						:is-removeable="true"
						@running-head-toggle="runningHeadToggle"
						v-model="runningHead" />

					<div class="form-group" v-show="!showRunningHead">
						<label>Running Head</label>
						<div class="help-text">
							Not a standard part of a Student Research Paper
						</div>

						<div class="d-grid gap-2">
							<button type="button" class="btn btn-hover btn-hover-success" @click.prevent="runningHeadToggle">
								<span class="svg bi-plus-lg" /> Add running head
							</button>
						</div>
					</div>
				</div><!--v-if-->

				<div class="d-grid">
					<button
						type="submit"
						class="btn btn-success mt-2"
						:disabled="!isSubmitClickable"
						@click.prevent="updateTitlePage">
							Update Title 
							<span v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7">Page</span>
							<span v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9">Section</span>
							<span v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9">Page</span>
					</button>
				</div>
			</form>

			
		</div><!--.drawer-body-->
	</div>
</template>

<script>
import AS_LoadDatepicker from '@/services/app/loadDatepicker';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormDatepicker from '@/components/form/Datepicker';
import FormInputRunningHead from '@/components/form/InputRunningHead';
import PS_SaveDocument from '@/services/paper/saveDocument';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';
import TruncateRunningHead from '@/helpers/truncate-running-head';

export default {
	name: 'PaperDrawerTitlePage',
	computed:{
		isStudentPaper(){
			if(
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.APA7_StudentPaper ||
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.MLA9_ResearchPaper || 
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.Turabian9_ResearchPaper
			){
				return true;
			}
			return false;
		},
		isSubmitClickable(){
			let findIndex = this.$store.state.forms.list.findIndex((formData)=>{
				return formData.formId === this.zFormId;
			});

			if(findIndex !== -1){
				return this.$store.state.forms.list[findIndex].isDirty;
			}
			return false;
		},
		showResearchPaperFields(){
			if(
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.APA7_StudentPaper ||
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.APA7_ProfessionalPaper ||
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.MLA9_ResearchPaper || 
				this.$store.state.paperEdit.meta.PaperType == config.enums.PaperType.Turabian9_ResearchPaper
			){
				return true;
			}
			return false;
		},
	},
	data() {
		return {
			author: '',
			courseName: '',
			dueDate: '',
			institution: '',
			instructor: '',
			isRunningHeadLinked: true,	// defaults to true, if user types in Running Head make this false
			runningHead: '',
			showRunningHead: false,	// boolean to show or hide the running head field
			title:'',
			zFormId: 'formTitlePage'
		}
	},
	methods: {
		// Professional Papers calc the running head as the title types
		calcRunningHead(){
			if(!this.isRunningHeadLinked){
				return false;
			}
			this.runningHead = TruncateRunningHead(this.title);
		},
		
		dueDateClear(){
			this.dueDate = '';
		},

		dueDateSet(newDate){
			this.dueDate = newDate;
		},
	
		loadTitlePageDrawer(){
			// Clone data from store so i can use v-model
			this.author = this.$store.state.paperEdit.meta.StudentName;
			this.courseName = this.$store.state.paperEdit.meta.CourseName;
			this.dueDate = this.$store.state.paperEdit.meta.PublishedDate;
			this.institution = this.$store.state.paperEdit.meta.Institution;
			this.instructor = this.$store.state.paperEdit.meta.Teacher;
			this.title = this.$store.state.paperEdit.meta.Title;
			
			// Student Paper could have a Running Head Added (optional)
			if(this.$store.state.paperEdit.meta.RunningHead !== ''){
				this.showRunningHead = true;

				// calc and link running head
				let tryToCalcRunningHead = TruncateRunningHead(this.title);

				if(tryToCalcRunningHead === this.$store.state.paperEdit.meta.RunningHead){
					this.isRunningHeadLinked = true;
					this.runningHead = tryToCalcRunningHead;
				} else {
					this.isRunningHeadLinked = false;
					this.runningHead = this.$store.state.paperEdit.meta.RunningHead;
				}
			}

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});

			// init datepicker 
			this.$nextTick(()=>{
				if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
					AS_LoadDatepicker({
						id: 'TP_apa7_dueDate',
						loadDate: this.dueDate,
						isRefWizard: false,
						paperFormat: config.enums.Format.APA7,
					});
				} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
					AS_LoadDatepicker({
						id: 'TP_mla9_dueDate',
						loadDate: this.dueDate,
						isRefWizard: false,
						paperFormat: config.enums.Format.MLA9,
					});
				} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					AS_LoadDatepicker({
						id: 'TP_turabian9_dueDate',
						loadDate: this.dueDate,
						isRefWizard: false,
						paperFormat: config.enums.Format.Turabian9,
					});
				}
			});
		},//e:loadTitlePageDrawer

		// Student Papers can add an optional running head
		runningHeadToggle(){
			this.showRunningHead = !this.showRunningHead;
			
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.zFormId,
			});

			if(this.showRunningHead){
				this.calcRunningHead();
			} else{
				// this.isRunningHeadLinked = false;
				this.runningHead = '';
			}
		},

		updateTitlePage(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{

				if(this.showRunningHead) {
					this.runningHead = TruncateRunningHead(this.runningHead);
				} else {
					this.runningHead = '';
				}
				
				// Store loaded as meta data, update them to whatever the form field have
				this.$store.commit('paperEdit/meta/SET_StudentName', this.author);
				this.$store.commit('paperEdit/meta/SET_CourseName', this.courseName);
				this.$store.commit('paperEdit/meta/SET_FileName', this.title.replace(/[^a-zA-Z0-9-_\.]/g, ''));	// https://stackoverflow.com/questions/33771154/javascript-how-to-remove-illegal-url-characters-from-a-file-name
				this.$store.commit('paperEdit/meta/SET_PublishedDate', this.dueDate);
				this.$store.commit('paperEdit/meta/SET_Teacher', this.instructor);
				this.$store.commit('paperEdit/meta/SET_RunningHead', this.runningHead);
				this.$store.commit('paperEdit/meta/SET_Title', this.title);

				if(this.showResearchPaperFields && this.$store.state.paperEdit.meta.PaperFormatVersionID !== config.enums.Format.MLA9){
					this.$store.commit('paperEdit/meta/SET_Institution', this.institution);
				} else {
					this.$store.commit('paperEdit/meta/SET_Institution', '');
				}

				this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
					formId: this.zFormId
				});

				PS_SaveDocument({
					isMetaDataChanges: true,
					message: 'Updated title page',
				}).then((response)=>{
					// console.log('Save Document done from title page tool drawer')
					this.emitter.emit('globalToasterOpen', {
						textContent: 'Title page updated',
					});
				}).catch((error)=>{	
					console.log("Error in TitlePage > saveDocument");
					console.log(error);
				});

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
			
		},//e:updateTitlePage
	},
	created() {
		// incase user refreshed on title page drawer - this will make sure the data loads after the paper does
		this.emitter.on('loadTitlePageDrawer', ()=>{
			this.loadTitlePageDrawer();
		});

		this.emitter.on('setRunningHeadLink', (isLinked)=>{
			this.isRunningHeadLinked = isLinked;
			this.calcRunningHead();
		});
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorTitlePage'
		}).then(()=>{
			this.loadTitlePageDrawer();
		});
	},
	destroyed(){
		this.emitter.off('loadTitlePageDrawer');
		this.emitter.off('setRunningHeadLink');
	},
	components:{
		FormDatepicker,
		FormInput,
		FormInputRunningHead,
		ToolDrawerTitle,
	}
}
</script>

