var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-3 py-2 settings-wrp" }, [
    _vm._m(0),
    _vm._v(" "),
    !_vm.isSuscriptionExpired
      ? _c(
          "div",
          {
            class: [
              "d-grid g-settings-header mb-3",
              {
                "g-settings-free-trial":
                  _vm.$store.state.customer.role ===
                  _vm.$enums.Status.FREE_TRIAL,
              },
            ],
          },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  to: {
                    name: "Dashboard",
                  },
                },
              },
              [
                _c("span", { staticClass: "svg icons8-monitor" }),
                _vm._v(" "),
                _c("div", { staticClass: "button-text" }, [
                  _c("span", { staticClass: "fw-bolder" }, [
                    _vm._v("PERRLA Online:"),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n\t\t\t\t\tGo to our web-based application for writing papers\n\t\t\t"
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary header-button",
                attrs: {
                  href: _vm.calcUrlToEnv(
                    _vm.$enums.URL_TO_ENV.PERRLA,
                    "word-setup"
                  ),
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [
                _c("span", { staticClass: "svg icons8-microsoft-word" }),
                _vm._v(" "),
                _vm._m(1),
              ]
            ),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  to: {
                    name: "ReferralRewards",
                  },
                },
              },
              [
                _c("span", { staticClass: "svg icons8-friends" }),
                _vm._v(" "),
                _c("div", { staticClass: "button-text" }, [
                  _c("span", { staticClass: "fw-bolder" }, [
                    _vm._v("Refer A Friend:"),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n\t\t\t\t\tHelp your friends use PERRLA & get 3 months of PERRLA free\n\t\t\t"
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "nav-tabs",
            [
              _c("nav-tab", { attrs: { to: { name: "MyProfile" } } }, [
                _vm._v("\n\t\t\t\t\tMy Profile\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _vm.isSuscriptionExpired
                ? _c("nav-tab", { attrs: { to: { name: "YourPapers" } } }, [
                    _vm._v("\n\t\t\t\t\tYour Papers\n\t\t\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isSuscriptionExpired &&
              _vm.$store.state.customer.role !== _vm.$enums.Status.FREE_TRIAL
                ? _c("nav-tab", { attrs: { to: { name: "AccountBilling" } } }, [
                    _vm._v("\n\t\t\t\t\tAccount & Billing\n\t\t\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isSuscriptionExpired
                ? _c("nav-tab", { attrs: { to: { name: "PerrlaOnline" } } }, [
                    _vm._v("\n\t\t\t\t\tApp Settings\n\t\t\t\t"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-row mb-2" }, [
      _c("h1", { staticClass: "float-start me-3 mb-0" }, [
        _vm._v("\n\t\t\tPERRLA Settings\n\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "button-text" }, [
      _c("span", { staticClass: "fw-bolder" }, [_vm._v("PERRLA for Word:")]),
      _c("br"),
      _vm._v(
        "\n\t\t\t\tInstructions to install our Microsoft Word Add-in\n\t\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-primary header-button",
        attrs: {
          href: "https://perrla.zendesk.com/hc/en-us/articles/8198438301595",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("span", { staticClass: "svg icons8-chrome" }),
        _vm._v(" "),
        _c("div", { staticClass: "button-text" }, [
          _c("span", { staticClass: "fw-bolder" }, [
            _vm._v("Chrome Extension:"),
          ]),
          _c("br"),
          _vm._v(
            "\n\t\t\t\tInstructions to install our Google Chrome Extension\n\t\t\t"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }