<!--
- Input with Autocomplete
-- based on https://codepen.io/graysenpack/pen/JjQXXZO
-- 2024.08.13
-->

<template>
	<div class="form-group form-group-droplist">
        <label
			:for="safeId"
			v-if="label"
			v-html="label" />
            
            <input
				autocomplete="off"
				class="form-control"	
				:id="safeId"
				:placeholder="placeholder"
                v-model="searchValue"
                @focus="isShowDroplist = true"
                @input="typeInput"
                @keydown.enter.prevent="blurSearchField"
                @keydown.down="focusItemByIndexOfDisplayedData"
                @keydown.esc="closeDroplist"
                @keydown.tab="blurSearchField"
				v-on-clickaway="blurSearchField" />
                
            <div class="droplist p-1 rounded-bottom-2" v-if="isShowDroplist && filteredDataList.length > 0">

                <a
                    v-for="dataOption in filteredDataList"
                    tabindex="-1"
                    class="px-2 py-1"
                    :key="dataOption.id"
                    :id="safeId + '_' + dataOption.id"
                    @keydown.esc="closeDroplist"
                    @keydown.down="selectNextItemDisplayed"
                    @keydown.up="selectPrevousItemDisplayed"
                    href="#"
                    @click.prevent="selectOption(dataOption.id)">
                        {{ dataOption.name }}
                </a>
                
            </div><!--droplist-->

	</div>
</template>

<script>
import _has from 'lodash/has';
import { mixin as clickaway } from 'vue-clickaway';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputAutocomplete',
    mixins: [clickaway, mixinId],
	props:{
        dataList:{
            default: [],
            type: Array,
        },
        injectValue:{
            default: '',
            type: String,
        },
        label:{
			type: String,
		},
        placeholder:{
			default: '',
			type: String
		},
	},
	data() {
		return {
            focusedIndex: 0,    // used when a user traverses the data list with the arrow keys
            isShowDroplist: false,
            searchValue: '',
            isSearching: true,   // when a user selects an item, then clicks back in they havn't technically searched, so until the text changes don't monkey with this value
            selectedOptionId: 0,
		}
	},
	computed:{
        filteredDataList(){
            let searchedArray = [];

            if(this.isSearching){
                this.dataList.forEach((optionData)=>{
                    if(this.searchValue === ''){
                        searchedArray.push(optionData);
                    } else {
                        if(optionData.name.toLowerCase().includes(this.searchValue.toLowerCase()) || optionData.name === this.searchValue.toLowerCase()){
                            searchedArray.push(optionData);
                        }
                    }//e:if:else
                });//e:forEach
            } else {
                searchedArray = this.dataList;
            }

            return searchedArray;
        },
	},
	methods: {
        blurSearchField(){
            this.isShowDroplist = false;
            this.focusedIndex = 0;

            if(this.selectedOptionId === 0){
                // reset everything, nothing real was selected?
                // this.searchValue = '';
                this.$emit('selected-from-search', 0);
                this.$emit('selected-from-search', {
                    selectedText: this.searchValue,
                    selectedId: 0
                });
            }
        },

        closeDroplist(){
            this.focusedIndex = 0;
            this.isShowDroplist = false;
        },
       
        focusItemByIndexOfDisplayedData(){
            if(this.filteredDataList.length > this.focusedIndex){
                if(_has(this.filteredDataList[this.focusedIndex], 'id')){
                    const idToLookUp = this.safeId + '_' + this.filteredDataList[this.focusedIndex].id;
                    let elementToSelect = document.getElementById(idToLookUp);
                    if(elementToSelect){

                        elementToSelect.focus();
                    }
                }
            }
        },//e:focusItemByIndexOfDisplayedData

        selectNextItemDisplayed(){
            if(this.focusedIndex < (this.filteredDataList.length - 1)){
                this.focusedIndex++;
                this.focusItemByIndexOfDisplayedData();
            }
        },//e:selectNextItemDisplayed
        
        selectPrevousItemDisplayed(){
            if(this.focusedIndex > 0){
                this.focusedIndex--;
            }
            this.focusItemByIndexOfDisplayedData();
        },//e:selectPrevousItemDisplayed

        selectOption(optionId){
            // look up this id in the list 
            let selectedOptionData = this.dataList.find((classData)=>{
                return classData.id === optionId;
            });
            if(selectedOptionData){
                this.selectedOptionId = optionId;
                this.isShowDroplist = false;
                this.searchValue = selectedOptionData.name;
                this.isSearching = false;

                // emit back up to the parent the id that was selected
                this.$emit('selected-from-search', {
                    selectedText: selectedOptionData.name,
                    selectedId: optionId
                });
            }
        },
        typeInput(){
            this.selectedOptionId = 0;
            this.isSearching = true;
		},//e:typeInput
	},
    watch:{
        injectValue(newValue){
            this.searchValue = newValue;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/vars_perrla";

@include color-mode(dark) {
    .form-group-droplist {
        .droplist {
            background: $gray-800;
            border-color: $extra-dark;
            a {
                color: $dark-body-color;
                
                &:hover,&:focus {
                   background-color: $gray-900;
                }
            }
        }    
    }
}

.form-group-droplist {
    position: relative;

    .droplist {
        background: $body-bg;
        border: 1px solid $secondary-100;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 100;
        max-height: 350px;
        overflow-y: auto;
        a {
            color: $body-color;
            display: block;
            text-decoration: none;
            

            &:hover,&:focus {
                background-color: $gray-200;
                text-decoration: none;
            }
        }
    }
}

</style>