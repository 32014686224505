<!--
- ReferralRewards
-
-->

<template>
	<div class="px-3 py-2 org-size">
		<h1 class="mt-0 mb-4">
			Referral Rewards
		</h1>
		
        <p><strong>Extend your PERRLA subscription for free by simply recommending PERRLA!</strong></p>

        <p>The program is as easy as 1-2-3...</p>

        <ol>
            <li>Tell Others About PERRLA</li>
            <li>Give them your Referral Promo Code</li>
            <li>When they purchase using your Promo Code, you both get an extra 3 months added to your PERRLA subscriptions!</li>
        </ol>

        <h2 class="text-danger">
            Here is your unique Referral Promo Code: <span class="text-body" v-if="$store.state.customer.id">3FREE-{{ $store.state.customer.id }}</span>
        </h2>
        
        <p>Send this information to a friend. Copy this text and paste it into an email or social media post:</p>

        <div class="bg-light p-3 rounded-1 text-dark mb-2">
            <p class="mb-0">I use PERRLA to write my APA/MLA papers. It saves time, reduces stress, and helps me earn the best possible grade. Use my Referral Promo Code <span v-if="$store.state.customer.id">(3FREE-{{ $store.state.customer.id }})</span> when you purchase to get an extra 3 months added to your subscription. Check out PERRLA here: www.perrla.com</p>
        </div>

        <p class="text-muted">Note: The Referral Rewards program is an automated process. In order for you to get credit, the purchaser MUST enter your Referral Promo Code at the time of their purchase. We cannot manually provide credit after-the-purchase.</p>

        <p><strong>Your Current PERRLA Subscription Expiration Date is:  <span class="text-body" v-if="displayedExpirationDate">{{ displayedExpirationDate }}</span></strong></p>

	</div>
</template>

<script>
import AS_SyncData from '@/services/app/syncData';
import dateFormat from "dateformat";
import { isAfter } from 'date-fns';
import { parseISO } from 'date-fns';

export default {
	name: 'ReferralRewards',
    data(){
        return {
            displayedExpirationDate: '',
        }
    },
	mounted(){
		AS_SyncData({
			
		}).then(()=>{
            let useExpirationDate;

            if(this.$store.state.customer.organizationId === 0){
                useExpirationDate = this.$store.state.customer.expirationDate;
            } else {
                // IN an org - use whichever expiration date is later
                if(isAfter(this.$store.state.customer.expirationDate, parseISO(this.$store.state.customer.organizationExpirationDate))){
                    useExpirationDate =  this.$store.state.customer.expirationDate;
                } else {
                    useExpirationDate =  this.$store.state.customer.expirationDate;
                }
            }
            
            this.displayedExpirationDate =  dateFormat(useExpirationDate, 'mmmm d, yyyy');

			this.$store.commit('loaders/REMOVE_ID', 'App');
		});
	},
}
</script>
