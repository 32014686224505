/*
- Outline
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.outline.x
*/
import store from '@/store';

export default {
	namespaced: true,
	
	state:{
		activeNodes: [ // tracks outline nodes actually added to a paper, toggles somewhere
			//{
			//	nodeId:
			//	paperId: 
			//}
		],	
		citationObjects: [	// Array - each item is fake citation data for each citation in this outline
			// {
			//	citationUniqueId: '111-111',
			// 	dataBase64: 'eyJhcGE3Ijp7ImZpcnN0Ijoi', // long base 64 version of this citation data
			//	referenceUniqueId: '111-111'
			// }
		],
		duplicateContent: [],	// fires whenever a double of the <outline> tag is parsed when a paper loads, it's so i can write the content 
		fullHtml: '',		// html string
		isUnsaved: false,	// Bool: sets to true anytime the paper outline saves - it becomes false again when it's ck instance changes
	},//e:state

	mutations: {
		ADD_ACTIVE_NODE(state, nodeId){
			let findIndex = state.activeNodes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === nodeId;
			});
			if(findIndex === -1){
				state.activeNodes.push({
					nodeId: nodeId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			}
		},
		ADD_CITATION_OBJECT(state, $opts){
			let findIndex = state.citationObjects.findIndex((citationObject)=>{
				return citationObject.citationUniqueId === $opts.citationUniqueId;
			});
			if(findIndex === -1){
				state.citationObjects.push({
					citationUniqueId: $opts.citationUniqueId,
					dataBase64: $opts.dataBase64,
					referenceUniqueId: $opts.referenceUniqueId
				});
			}
		},
		PUSH_TO_DUPLICATE_CONTENT(state, duplicateContent){
			// only push if the duplicate content hasn't already been logged here
			if(!state.duplicateContent.includes(duplicateContent)){
				state.duplicateContent.push(duplicateContent);
			}
		},
		REMOVE_CITATION_OBJECT_AT_INDEX(state, indexToRemove){
			state.citationObjects.splice(indexToRemove, 1);
		},
		FULL_HTML(state, value) {
			state.fullHtml = value;
		},
		SET_FULL_CITATION_OBJECTS(state, value) {
			state.citationObjects = value;
		},
		SET_IS_UNSAVED(state, value) {
			state.isUnsaved = value;
		},
		TOGGLE_ACTIVE_NODE(state, nodeId){
			let findIndex = state.activeNodes.findIndex((outlineNodeData)=>{
				return outlineNodeData.nodeId === nodeId && outlineNodeData.paperId === store.state.paperEdit.openObject.paperID;
			});

			if(findIndex === -1){
				state.activeNodes.push({
					nodeId: nodeId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			} else {
				state.activeNodes.splice(findIndex, 1);	
			}
		},
	},//e:mutations
}
