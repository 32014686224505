<template>
	<div class="d-grid" ref="tabDashboardWrp">
		<div class="row mb-4">
			<div class="col">
				<recent-papers />
			</div>
		</div>
		<div class="row">
			<class-list />
			<upcoming-work />
		</div>

		<route-modal />
	</div>
</template>

<script>
import AS_SetLayout from '@/services/app/setLayout';
import AS_SyncData from '@/services/app/syncData';
import ClassList from './Dashboard/ClassList.vue';
import mixinResizer from '@/mixins/resizer';
import RecentPapers from './Dashboard/RecentPapers.vue';
import RouteModal from '@/components/modals/RouteModal';
import UpcomingWork from './Dashboard/UpcomingWork.vue';

export default {
	name: 'DashboardView',
	mixins: [mixinResizer],
	data() {
		return {
			rzRef: 'tabDashboardWrp',
		}
	},
	mounted() {	
		AS_SyncData().then(()=>{
			AS_SetLayout().then(()=>{
				// start by loading the Class List
				this.emitter.emit('loadClassList');
			});
		});
	},
	components: {
		ClassList,
		RecentPapers,
		RouteModal,
		UpcomingWork
	}
}
</script>
