import _has from 'lodash/has';
import AS_InitTooltips from '@/services/app/initTooltips';
import AS_SetLayout from '@/services/app/setLayout';
import config from '@/config';
import store from '@/store'
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// modules
import account from "./modules/account";
import calendar from "./modules/calendar";
import cart from "./modules/cart";
import classModule from "./modules/class";
import dashboardModule from "./modules/dashboard";
import paperModule from "./modules/paper";
import referenceModule from "./modules/reference";
import reminderModule from "./modules/reminder";
import settings from "./modules/settings";

// regular paths
import ContactSupport from '@/views/ContactSupport';
import CountDown from '@/views/CountDown';
import DataDecoder from '@/views/DataDecoder';
import ErrorTests from '@/views/ErrorTests';
import ForgotPassword from '@/views/ForgotPassword';
import FreeTrialAlternateEmail from '@/views/FreeTrialAlternateEmail';
import FreeTrialDiscount from '@/views/FreeTrialDiscount';
import FreeTrialDiscountAddIn from '@/views/FreeTrialDiscountAddIn';
import FreeTrialStart from '@/views/FreeTrialStart';
import FreeTrialVerify from '@/views/FreeTrialVerify';
import LandingPage from '@/views/LandingPage';
import Login from '@/views/Login';
import LoginToAccount from '@/views/LoginToAccount';
import Logout from '@/views/Logout';
import OrganizationInvite from '@/views/OrganizationInvite';
import ResetPassword from '@/views/ResetPassword';
import RegistrationSuccess from '@/views/RegistrationSuccess';
import StoreOrganizationInvite from '@/views/Cart/OrganizationInvite.vue';



const router = new VueRouter({
	routes: [
		{
			path: '/',
			component: LandingPage
		},
		{
			path: '*',
			component: LandingPage
		},
		
		...account,
		...calendar,
		...cart,
		...classModule,
		...dashboardModule,
		...paperModule,
		...referenceModule,
		...reminderModule,
		...settings,
		{
			name: 'ContactSupport',
			component: ContactSupport,
			path: '/contact-support',
		},
		{
			name: 'CountDown',
			component: CountDown,
			path: '/count-down',
		},
		{
			name: 'DataDecoder',
			component: DataDecoder,
			path: '/data-decoder',
		},
		
		{
			name: 'ErrorTests',
			component: ErrorTests,
			path: '/error-tests',
		},
		{
			name: 'ForgotPassword',
			component: ForgotPassword,
			path: '/forgot-password',
		},
		{
			name: 'FreeTrialAlternateEmail',
			component: FreeTrialAlternateEmail,
			path: '/free-trial-alternate-email',
		},
		{
			name: 'FreeTrialDiscount',
			component: FreeTrialDiscount,
			path: '/free-trial-discount',
		},
		{
			name: 'FreeTrialDiscountAddIn',
			component: FreeTrialDiscountAddIn,
			path: '/free-trial-discount-add-in',
		},
		{
			name: 'FreeTrialStart',
			component: FreeTrialStart,
			path: '/free-trial-start',
		},
		{
			name: 'FreeTrialVerify',
			component: FreeTrialVerify,
			path: '/free-trial-verify/:verifyId',
			props: true,
		},
		{
			name: 'Login',
			component: Login,
			path: '/login',
		},
		// from p.com to MPH in the SPA
		{
			name: 'LoginToAccount',
			component: LoginToAccount,
			path: '/login-to-account',
		},
		
		{
			name: 'Logout',
			component: Logout,
			path: '/logout',
		},
		{
			name: 'OrganizationInvite',
			component: OrganizationInvite,
			path: '/organization-invite/:mode/:encryptedUserId',
			props: true,
		},
		{
			name: 'ResetPassword',
			component: ResetPassword,
			path: '/reset-password/:resetKey',
			props: true,
		},
		{
			name: 'RegistrationSuccess',
			component: RegistrationSuccess,
			path: '/registration-success',
		},
		{
			name: 'StoreOrganizationInvite',
			component: StoreOrganizationInvite,
			path: '/store-organization-invite',
			// props: true,
		},
		
	]
});

router.afterEach((to, from) => {

	store.commit('forms/SET_VALIDATION', []);
	store.commit('forms/MARK_ALL_CLEAN');
	store.commit('modals/CLOSE_ALL');
	
	// force remove loaders that may hang on, eventually this will be a REMOVE_ALL call
	store.commit('loaders/REMOVE_ID', 'EditorTabToolDrawer');

	if(window.newrelic){
		newrelic.setPageViewName(to.path);
	}
	
	AS_SetLayout().then(()=>{
		// For now only apply changes inside the Editor Tab
		if (to.path.includes('outline') || to.path.includes('editor')) {
			// close any open sidemenus
			store.commit('paperEdit/toolDrawer/IS_SIDEMENU_OPEN', false);
			window.$vm.emitter.emit('toasterClose');

			window.scrollTo(0,0);

			if(typeof zE === 'function'){
				zE(function() {
					if (to.matched.some(record => record.meta.hideZendesk)) {
						zE.hide();
					} else {
						zE.show();
					}
				});
			}
			
		} else {
			setTimeout(()=>{
				if(typeof zE === 'function'){
					zE(function() {
						zE.hide();
					}); 
				}
			}, 1200);
		}//e:to.path.includes('editor')

		AS_InitTooltips();

	});//e:AS_SetLayout
});//e:router.afterEach

router.beforeEach((to, from, next) => {
	// auto foward org invites that might be missing a parma
	// case is an org user that has already recevied the email containing a link
	// Public/#/organization-invite/create/MjAxNjE=
	if(to.fullPath.includes('organization-invite')){
		// console.log('contains org invite');
		if(!_has(to.params, 'mode')){
			// mode is missing as a $route param
			let splitParamsRoute  = to.params.pathMatch.split('/');
			if(config.isDev){
				alert('can not test this locally');
			} else {
				window.location = config.appUrl + '/Public/#/organization-invite/create/' + splitParamsRoute[splitParamsRoute.length - 1];
			}
		} else {
			next();
		}
	} else {
		// hide any open tooltips
		const firstTooltip = document.querySelector('.tooltip.show');
		if(firstTooltip){
			firstTooltip.classList.remove('show');
		}
		next();
	}

});


export default router;
