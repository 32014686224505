<!--
- Paper Edit > Write Tab > Running Head is on several pages
-- include this on every page and use paper formatting to show/hide various elements
-->

<template>
	<div v-if="isShowRunningHead">
		<router-link
			class="fake-widget clearfix"
			title="Click to Edit your Title Page"
			:to="{name:'EditorTitlePage'}">

			<p class="float-start" v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7" key="spanRunningHeadLeftApa7">
				{{ $store.state.paperEdit.meta.RunningHead }}
			</p>

			<p class="float-end">
				<span v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7 || $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" key="spanRunningHeadRightApa7Turabian9">
					{{ pageNumberOutput }}
				</span>
				<span v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9" key="spanRunningHeadRightMla9">
					{{ calcLastName }} {{ pageNumberOutput }}
				</span>
			</p>
		</router-link>
	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'RunningHead',
	props:{
		pageType: {
            required: true,
            type: String
        }
	},
	computed:{
		calcLastName(){
			let nameAsArray = this.$store.state.paperEdit.meta.StudentName.split(' ');
			return nameAsArray[nameAsArray.length - 1] + ' ';
		},
		isShowRunningHead(){
			// this is the logic center of this component - it can show up depending on Paper Type (APA7) and Page Type (body)
			
			// make sure DocumentType & PaperFormatVersionID are set from parseHiddenTags
			if(this.$store.state.paperEdit.meta.DocumentType && this.$store.state.paperEdit.meta.PaperFormatVersionID){

				// check this document type
				if(this.$store.state.paperEdit.meta.DocumentType === config.enums.DocumentType.DISCUSSION_POST){
					// Discussion Posts should never have a running head
					return false;

				} else {
					// check each page type, these are in order of how they appear in the document view (resist the urge to alphabetize these)
					switch(this.pageType){
						case 'title':
							if(
								this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 ||
								this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9
							){
								return true;
							}
							break;

						case 'abstract':
							if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
								return true;
							}
							break;

						case 'toc':
							if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
								return true;
							}
							break;

						case 'body':
							if(
								this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9
							){
								return false;
							}
							return true;

						case 'references':
							return true;

						case 'appendix':
							return true;
							
					}
				}
			
			}//e:if:DocumentType

			return false;
		},
		pageNumberOutput(){
			// document type plays a role here too
			let counter;

			switch(this.pageType){
				case 'title':
					if(
						this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 ||
						this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9
					){
						return '1';
					}
					break;

				case 'abstract':
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						return '2';
					}
					break;

				case 'toc':
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						if(this.$store.state.paperEdit.meta.IncludeAbstract) {
							return '3';
						} else {
							return '2';
						}
					}
					break;

				case 'body':
					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
						return '1';
					} else {
						counter = 2;	// title will be on so start at 2
						if(this.$store.state.paperEdit.meta.IncludeAbstract) {
							counter++;
						} 
						if(this.$store.state.paperEdit.meta.IncludeTableOfContents) {
							counter++;
						} 
						return counter;
					}
			}
			return '#';
		},
	},
}
</script>
