var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-3 py-2 org-size" }, [
    _c("h1", { staticClass: "mt-0 mb-4" }, [
      _vm._v("\n\t\t\tReferral Rewards\n\t\t"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("p", [_vm._v("The program is as easy as 1-2-3...")]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("h2", { staticClass: "text-danger" }, [
      _vm._v("\n            Here is your unique Referral Promo Code: "),
      _vm.$store.state.customer.id
        ? _c("span", { staticClass: "text-body" }, [
            _vm._v("3FREE-" + _vm._s(_vm.$store.state.customer.id)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Send this information to a friend. Copy this text and paste it into an email or social media post:"
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bg-light p-3 rounded-1 text-dark mb-2" }, [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "I use PERRLA to write my APA/MLA papers. It saves time, reduces stress, and helps me earn the best possible grade. Use my Referral Promo Code "
        ),
        _vm.$store.state.customer.id
          ? _c("span", [
              _vm._v("(3FREE-" + _vm._s(_vm.$store.state.customer.id) + ")"),
            ])
          : _vm._e(),
        _vm._v(
          " when you purchase to get an extra 3 months added to your subscription. Check out PERRLA here: www.perrla.com"
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "text-muted" }, [
      _vm._v(
        "Note: The Referral Rewards program is an automated process. In order for you to get credit, the purchaser MUST enter your Referral Promo Code at the time of their purchase. We cannot manually provide credit after-the-purchase."
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("strong", [
        _vm._v("Your Current PERRLA Subscription Expiration Date is:  "),
        _vm.displayedExpirationDate
          ? _c("span", { staticClass: "text-body" }, [
              _vm._v(_vm._s(_vm.displayedExpirationDate)),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Extend your PERRLA subscription for free by simply recommending PERRLA!"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("Tell Others About PERRLA")]),
      _vm._v(" "),
      _c("li", [_vm._v("Give them your Referral Promo Code")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "When they purchase using your Promo Code, you both get an extra 3 months added to your PERRLA subscriptions!"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }