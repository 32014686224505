/*
- Word Count
-- 2022.09.22
*/
export default (text) => {
	let cleanText = text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
	let words = cleanText.split(/\s+/);

	for (let wordIndex = words.length - 1; wordIndex >= 0; wordIndex--) {
		if (words[wordIndex].match(/^([\s\t\r\n]*)$/)) {
			words.splice(wordIndex, 1);
		}
	}
	//console.log(words);
	return (words.length);
}