var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    { attrs: { "is-no-padding": true } },
    [
      _c("outline-filebar"),
      _vm._v(" "),
      _c("outline-ribbon"),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "outlineWrp",
          staticClass: "float-start w-100",
          style: _vm.calcOutlineOutStyles,
          attrs: { id: "outlineWrp" },
        },
        [
          _c(
            "div",
            { class: _vm.calcOutlineClasses, style: _vm.calcOutlineInStyles },
            [
              _vm.$store.state.customer.citationAutoInsert
                ? _c("citation-context-menu")
                : _vm._e(),
              _vm._v(" "),
              _c("textarea", {
                staticClass: "d-none",
                attrs: { id: "ckPaperOutline", autocomplete: "off" },
                domProps: { value: _vm.outlineFullHtmlLocal },
              }),
              _vm._v(" "),
              _vm.$store.state.paperEdit.config.renderCitationsAsFootnotes
                ? _c("outline-footnotes")
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("tool-drawer", { attrs: { "is-editor": false } }),
      _vm._v(" "),
      _c("outline-footnotes-dropdown"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }