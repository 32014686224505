var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group form-group-droplist" }, [
    _vm.label
      ? _c("label", {
          attrs: { for: _vm.safeId },
          domProps: { innerHTML: _vm._s(_vm.label) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchValue,
          expression: "searchValue",
        },
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.blurSearchField,
          expression: "blurSearchField",
        },
      ],
      staticClass: "form-control",
      attrs: {
        autocomplete: "off",
        id: _vm.safeId,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.searchValue },
      on: {
        focus: function ($event) {
          _vm.isShowDroplist = true
        },
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.searchValue = $event.target.value
          },
          _vm.typeInput,
        ],
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return _vm.blurSearchField.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            return _vm.focusItemByIndexOfDisplayedData.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.closeDroplist.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            )
              return null
            return _vm.blurSearchField.apply(null, arguments)
          },
        ],
      },
    }),
    _vm._v(" "),
    _vm.isShowDroplist && _vm.filteredDataList.length > 0
      ? _c(
          "div",
          { staticClass: "droplist p-1 rounded-bottom-2" },
          _vm._l(_vm.filteredDataList, function (dataOption) {
            return _c(
              "a",
              {
                key: dataOption.id,
                staticClass: "px-2 py-1",
                attrs: {
                  tabindex: "-1",
                  id: _vm.safeId + "_" + dataOption.id,
                  href: "#",
                },
                on: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.closeDroplist.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _vm.selectNextItemDisplayed.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _vm.selectPrevousItemDisplayed.apply(
                        null,
                        arguments
                      )
                    },
                  ],
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectOption(dataOption.id)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(dataOption.name) +
                    "\n                "
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }