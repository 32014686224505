/*
- Meta
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.meta.x
*/
import config from '@/config';
import toBoolean from 'validator/lib/toBoolean';	//https://www.npmjs.com/package/validator

export default {
	namespaced: true,
	
	state:{
		ClassID: '',
		CourseName: '',
		CustomerID: '',
		Description: '',
		DocumentType: '',
		DueDate: '',
		FileName: '',
		FontFamily: config.enums.FontFamily.TIMES_NEW_ROMAN,
		IncludeAbstract: '',
		IncludeAuthorNote: '',
		IncludeTableOfContents: '',
		Institution: '',
		IsComplete: false,
		PaperFormatID: '',
		PaperFormatVersionID: '',
		PaperID: '',
		PaperType: '',
		PaperUniqueID: '',
		PublishedDate: '',
		RequiredLength: '',
		RunningHead: '',
		StartDate: '',
		StudentName: '',
		Teacher: '',
		Title: '',
		UseNewCitationMode: '',
	},//e:state

	mutations: {
		SET_ClassID(state, value) {
			state.ClassID = value;
		},
		SET_CourseName(state, value) {
			state.CourseName = value;
		},
		SET_CustomerID(state, value) {
			state.CustomerID = value;
		},
		SET_Description(state, value) {
			state.Description = value;
		},
		SET_DocumentType(state, value) {
			state.DocumentType = value;
		},
		SET_DueDate(state, value) {
			state.DueDate = value;
		},
		SET_FileName(state, value) {
			state.FileName = value;
		},
		SET_FontFamily(state, value) {
			state.FontFamily = value;
		},
		SET_IncludeAbstract(state, value) {
			if(typeof value === 'boolean'){
				state.IncludeAbstract = value;
			} else {
				state.IncludeAbstract = toBoolean(value);
			}
		},
		SET_IncludeAuthorNote(state, value) {
			if(typeof value === 'boolean'){
				state.IncludeAuthorNote = value;
			} else {
				state.IncludeAuthorNote = toBoolean(value);
			}
		},
		SET_IncludeTableOfContents(state, value) {
			if(typeof value === 'boolean'){
				state.IncludeTableOfContents = value;
			} else {
				state.IncludeTableOfContents = toBoolean(value);
			}
		},
		SET_Institution(state, value) {
			state.Institution = value;
		},
		SET_IsComplete(state, value) {
			if(typeof value === 'boolean'){
				state.IsComplete = value;
			} else {
				state.IsComplete = toBoolean(value);
			}
		},
		SET_PaperFormatID(state, value) {
			state.PaperFormatID = parseInt(value);
		},
		SET_PaperFormatVersionID(state, value) {
			state.PaperFormatVersionID = parseInt(value);
		},
		SET_PaperID(state, value) {
			state.PaperID = parseInt(value);
		},
		SET_PaperType(state, value) {
			state.PaperType = parseInt(value);
		},
		SET_PaperUniqueID(state, value) {
			state.PaperUniqueID = value;
		},
		SET_PublishedDate(state, value) {
			state.PublishedDate = value;
		},
		SET_RequiredLength(state, value) {
			state.RequiredLength = value;
		},
		SET_RunningHead(state, value) {
			state.RunningHead = value;
		},
		SET_StartDate(state, value) {
			state.StartDate = value;
		},
		SET_StudentName(state, value) {
			state.StudentName = value;
		},
		SET_Teacher(state,value){
			state.Teacher = value;
		},
		SET_Title(state,value){
			state.Title = value;
		},
		SET_UseNewCitationMode(state, value){
			if(typeof value === 'boolean'){
				state.UseNewCitationMode = value;
			} else {
				state.UseNewCitationMode = toBoolean(value);
			}
		},
	},//e:mutations
}
