<!--
- Registration Success
-- users get here after verifing their email from creating a free trial
-->

<template>
	<div class="layout-pcom">
		
		<div class="hero text-center hero-dark hero-main hero-text-shadow" :style="getStyle">
			<h1>Getting started with PERRLA</h1>
		</div>

		<section class="section-text pt-0">
			<main class="container-text mt-5">
				<div class="text-center ">
					<h2>How would you like to write papers with PERRLA?</h2>
				</div>
				<div class="row mb-5">
					<div class="col-6 text-center">
						<div class="d-grid">
							<router-link
								:to="{
									name: 'Dashboard'
								}"
								class="btn btn-primary btn-lg btn-extra-padding">
								I want to try the <br />
								PERRLA Online Editor
							</router-link>
						</div>
					</div>
					<div class="col-6 text-center">
						<div class="d-grid">
							<a href="https://www.perrla.com/#/word-setup" class="btn btn-primary btn-lg btn-extra-padding">
								I want to write with <br />
								Microsoft Word
							</a>
						</div>
					</div>
				</div>

				<p>
					<a href="https://perrla.zendesk.com/hc/en-us/articles/360034402514-PERRLA-Online-vs-PERRLA-for-Word-Add-in-" target="_blank" rel="noopener noreferrer">
						Need help deciding? Check out this article to see how PERRLA best fits your writing style.
					</a>
				</p>

				<p>
					Every PERRLA subscription & free trial comes with both PERRLA Online & PERRLA for Word - so you can use either at any time. Choose which one you'd like to try out first.
				</p>

			</main>

			
		</section>
	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'RegistrationSuccess',
	props:{

	},
	data() {
		return {
		}
	},
	computed:{
		getStyle(){
			let styleString = '';
			styleString += 'background-image: linear-gradient(180deg, rgba(9, 49, 82, 0.8), rgba(4, 71, 73, 0.75)), url("/static/images/mac-desktop2.jpeg");';
			styleString += 'background-position: 0px 0px, 50% 50%;';
			styleString += 'background-size: auto, cover;';
			styleString += 'padding-top: 75px;';
			styleString += 'padding-bottom: 75px;';
			return styleString;
		}//e:getStyle
	},
	mounted() {
		// force this view to stay in the PO layout never in dark mode
		this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);
		document.documentElement.setAttribute('data-bs-theme', 'light')
	},
}
</script>
