var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "float-start rb-btn-heading-styles-wrp" }, [
    _c("div", { staticClass: "dropdown" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "dropdownMenuTextHeadingStyle" },
        },
        [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("P")
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "rb-icon-dropdown rb-icon-paragraph",
                }),
                _vm._v("\n\t\t\t\t\tParagraph\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("H1")
                  },
                },
              },
              [
                _c("span", { staticClass: "rb-icon-dropdown rb-icon-h1" }),
                _vm._v("\n\t\t\t\t\tHeading 1\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("H2")
                  },
                },
              },
              [
                _c("span", { staticClass: "rb-icon-dropdown rb-icon-h2" }),
                _vm._v("\n\t\t\t\t\tHeading 2\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("H3")
                  },
                },
              },
              [
                _c("span", { staticClass: "rb-icon-dropdown rb-icon-h3" }),
                _vm._v("\n\t\t\t\t\tHeading 3\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("H4")
                  },
                },
              },
              [
                _c("span", { staticClass: "rb-icon-dropdown rb-icon-h4" }),
                _vm._v("\n\t\t\t\t\tHeading 4\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("H5")
                  },
                },
              },
              [
                _c("span", { staticClass: "rb-icon-dropdown rb-icon-h5" }),
                _vm._v("\n\t\t\t\t\tHeading 5\n\t\t\t\t"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.applyStyle("Blockquote")
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "rb-icon-dropdown rb-icon-blockquote",
                }),
                _vm._v("\n\t\t\t\t\tBlockquote\n\t\t\t\t"),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "rb-btn",
        attrs: {
          href: "#",
          type: "button",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
          id: "dropdownMenuTextHeadingStyle",
        },
      },
      [
        _c("div", { staticClass: "rb-icon rb-icon-heading-style" }),
        _vm._v("\n\t\t\tText &"),
        _c("br"),
        _vm._v("Heading Style\n\t\t"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }