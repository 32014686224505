var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instance-references" },
    [
      _c("br"),
      _vm._v(" "),
      _c(
        "h1",
        {
          class: [
            "text-center",
            {
              "fw-bold":
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                _vm.$enums.Format.APA7,
              "fw-normal":
                _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                _vm.$enums.Format.MLA9,
            },
          ],
        },
        [_vm._v("\n\t\t\t" + _vm._s(_vm.referenceTitleDisplay) + "\n\t\t")]
      ),
      _vm._v(" "),
      _vm._l(_vm.referenceObjectsInPaper, function (referenceObject) {
        return _c("reference-page-row", {
          key: referenceObject.referenceUniqueID,
          attrs: {
            "reference-object": referenceObject,
            "reference-unique-id": referenceObject.referenceUniqueID,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }