var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.calcPromoCodeOutput
      ? _c("div", { key: "divDiscountApplied" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("h4", { class: _vm.calcDiscountTextClasses }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.calcDiscountDisplay) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "org-size text-primary mt-2" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.calcPromoCodeOutput) + "\n\t\t"),
          ]),
        ])
      : _c("div", { key: "divNoDiscountApplied" }, [
          _c("h4", { staticClass: "text-body" }, [
            _vm._v("\n\t\t\tPromo Code\n\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "help-text ms-3 mb-3" }, [
            _vm._v(
              "\n\t\t\tPERRLA uses this field for Referral Rewards and for group sales. Be sure to click Apply to use your code.\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.promoCodeEntry,
                      expression: "promoCodeEntry",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    autocomplete: "off",
                    placeholder: "Enter code here",
                    type: "text",
                  },
                  domProps: { value: _vm.promoCodeEntry },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.promoCodeEntry = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary border-0",
                    attrs: { type: "submit" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnApplyPromoCodeEntry.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\t\t\tApply\n\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "text-body" }, [
        _vm._v("\n\t\t\t\t\tPromo Code\n\t\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }