<!--
- Single Reference Row in a list of refs in this paper
-->

<template>
	<div class="mb-3 element-row">
		<div :class="['dropstart', {'archived-reference': referenceObject.isArchived}]">
			<a href="#" class="svg bi-three-dots float-end" data-bs-toggle="dropdown" role="button" aria-expanded="false" aria-label="More Options" />

			<p class="mb-1 reference-text word-break" v-html="referenceObject.displayValue" @click.prevent="toggleIsOpen" v-add-blank-target ref="referenceRowOutput" />
			
			<ul class="dropdown-menu">
				<li v-if="!referenceObject.isArchived" key="liEditReference">
					<router-link
						:class="['dropdown-item hover hover-primary']"
						:to="{
							name: 'EditorEditReference',
							params:{
								referenceUid: upperCaseRid
							}
						}">
							<span class="svg bi-pencil-fill" />
							Edit Reference
					</router-link>
				</li>
				<li v-else key="liRestoreReference">
					<a class="dropdown-item hover hover-primary" href="#" @click.prevent="btnRestoreReference">
						<span class="svg bi-arrow-counterclockwise" />
						Restore this Reference
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-danger" href="#" @click.prevent="removeRefFromPaper">
						<span class="svg bi-x-lg" />
						Remove Reference
					</a>
				</li>
				<li>
					<a class="dropdown-item hover hover-success" href="#" @click.prevent="copyReferenceText">
						<span class="svg bi-copy" />
						Copy as text
					</a>
				</li>
			</ul>
		</div>

		<p class="ms-3 mb-1 archived-reference-help-text" v-if="referenceObject.isArchived">
			This reference has been archived.
		</p>

		<div class="g-two-col-row" v-else>
			<router-link
				:class="['btn btn-sm btn-hover btn-hover-success ms-2']"
				:to="{
					name: 'EditorCitationAdd',
					params: {
						referenceUid: upperCaseRid
					},
				}">
				<span class="svg bi-plus-lg" /> Add Citation
			</router-link>

			<button
				v-if="hasCitations"
				class="btn btn-sm btn-hover btn-hover-success"
				role="button"
				type="button"
				@click="toggleIsOpen">
				<span v-if="isOpen">Hide Citations</span>
				<span v-else>Show Citations</span>
			</button>
		</div><!--g-two-col-row-->
		
		<!-- <div class="d-grid" v-else>
			<router-link
				:class="['btn btn-sm btn-hover btn-hover-success ms-2']"
				:to="{
					name: 'EditorCitationAdd',
					params: {
						referenceUid: upperCaseRid
					},
				}">
				<span class="svg bi-plus-lg" /> Add Citation
			</router-link>
		</div> -->

		<slide-up-down :active="isOpen">
			<citation-row
				v-for="citationObject in referenceObject.citations"
				:citation-object="citationObject"
				:key="citationObject.citationData.citationUniqueID" />
		</slide-up-down>

	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import CitationRow from './CitationRow';
import CopyToClipboard from 'copy-to-clipboard';
import PS_RemoveReferenceFromPaper from '@/services/paper/removeReferenceFromPaper';
import SlideUpDown from '@/components/VueSlideUpDown.vue';

export default {
	name: 'ReferenceRow',
	props:{
		referenceObject:{
			required: true,
			type: Object,
		},
		referenceUniqueId:{
			required: true,
			type: String,
		},
	},
	data() {
		return {
			isHover: false,
		}
	},
	computed:{
		hasCitations(){
			if(_has(this.referenceObject , 'citations') && this.referenceObject.citations.length > 0){
				return true;
			}
			return false;
		},
		isOpen(){
			if(this.$store.state.paperEdit.openReferenceUID.toUpperCase() === this.referenceUniqueId){
				return true;
			}
			return false;
		},
		upperCaseRid(){
			return this.referenceUniqueId.toUpperCase();
		},
	},
	methods: {
		btnRestoreReference(){
			let restoreReferenceIdsObject = {
				'iDs': [],
				'legacyIDs': [],
			}

			if(_has(this.referenceObject, 'values')){
				// genesis reference
				restoreReferenceIdsObject['iDs'].push(this.referenceObject.referenceID);
			} else {
				// legacy reference
				restoreReferenceIdsObject['legacyIDs'].push(this.referenceObject.referenceID);
			}
			
			// console.log('restoreReferenceIdsObject');
			// console.log(restoreReferenceIdsObject);

			ApiS_MakeCall({
				body: restoreReferenceIdsObject,
				method: 'PUT',
				url: 'v4/GenesisReference/Restore',
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');
				this.$store.commit('REFERENCE_LIBRARY', []);
				this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
				this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
				this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
				this.$store.commit('LAST_SYNC_DATE', '');
				
				this.selectedGenesisReferenceIds = [];
				this.selectedLegacyReferenceIds = [];

				// nexttick so the vuex store can get all the updates, before doing a full resync
				this.$nextTick(()=>{

					AS_SyncData({
						isStealthSync: true

					}).then(()=>{
						window.location.reload();
					});
				});
			});//e:ApiS_MakeCall

		},//e:btnRestoreReference
		
		copyReferenceText(){
			let $this = this;
			
			// leave these as HTML tags instead of plain text - the user needs text formatted
			CopyToClipboard(this.referenceObject.displayValue, {
				format: 'text/html',
				onCopy(){
					$this.emitter.emit('globalToasterOpen',{
						textContent: 'Copied to clipboard',
					});
				},
			});
		},//e:copyReferenceText
		
		removeRefFromPaper(){
			let doesReferenceHaveGroupedCitation = false;

			// is this reference part of a grouped citation from a different reference?
			if(this.referenceObject.citations.length > 0){
				_forEach(this.referenceObject.citations, (citationObject)=>{
					if(_has(citationObject, 'groupUniqueID')){
						doesReferenceHaveGroupedCitation = true;
						return false;	// stop looping after you find one
					}
				});//e:_forEach
			}//e:if
			
			let $firstCitationEl = null;

			_forEach(CKEDITOR.instances, (ckInstance)=>{
				$firstCitationEl = ckInstance.document.findOne('span[data-reference-unique-id="' + this.referenceUniqueId + '"]');
				if($firstCitationEl){
					return false;	// stop looking when you find one
				}
			});
			
			if(doesReferenceHaveGroupedCitation || $firstCitationEl){
				// Active Citations - confirm delete
				// $emit back up to parent (References and Citations, it has a confirm takeover)
				this.emitter.emit('showRefDeleteWaring', {
					reference: this.referenceObject,
				});
			} else {
				// No active citations - just remove the reference from the paper
				PS_RemoveReferenceFromPaper({
					hasActiveCitations: false,
					isDrawer: true,
					reference: this.referenceObject,
				}).then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});
			}
		},//e:removeRefFromPaper

		toggleIsOpen(){
			if(!this.hasCitations){
				return false;
			}
			
			if(this.isOpen){
				this.$store.commit('paperEdit/SET_OPEN_REFERENCE_UID', '');	
			} else {
				this.$store.commit('paperEdit/SET_OPEN_REFERENCE_UID', this.referenceUniqueId);	
			}
		},//e:toggleIsOpen
	},
	mounted(){
		if(this.isOpen){
			this.$refs.referenceRowOutput.scrollIntoView();
		}
	},
	components: {
		CitationRow,
		SlideUpDown,
	},
}
</script>
