/*
- Research Notes
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.researchNotes.x
*/
import store from '@/store';

export default {
	namespaced: true,
	
	state:{
		// local storage states
		activeResearchNotes: [	// tracks notes actually added to a paper, toggles in ResearchNoteRow of the Paper Editor Drawer
			//{
			//	noteId:
			//	paperId: 
			//}
		],			
		activeOutlineResearchNotes: [
			//{
			//	noteId:
			//	paperId: 
			//}
		],		// tracks notes actually added to the outline in the Organize Tab

				
	},//e:state

	mutations: {
		RESEARCH_NOTE_ADD_ACTIVE(state, noteId){
			let findIndex = state.activeResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeResearchNotes.push({
					noteId: noteId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			}
		},
		RESEARCH_NOTE_TOGGLE_ACTIVE(state, noteId){
			let findIndex = state.activeResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeResearchNotes.push({
					noteId: noteId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			} else {
				state.activeResearchNotes.splice(findIndex, 1);	
			}
		},

		OUTLINE_RESEARCH_NOTE_ADD_ACTIVE(state, noteId){
			let findIndex = state.activeOutlineResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeOutlineResearchNotes.push({
					noteId: noteId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			}
		},
		OUTLINE_RESEARCH_NOTE_TOGGLE_ACTIVE(state, noteId){
			let findIndex = state.activeOutlineResearchNotes.findIndex((researchNoteData)=>{
				return researchNoteData.noteId === noteId;
			});
			if(findIndex === -1){
				state.activeOutlineResearchNotes.push({
					noteId: noteId,
					paperId: store.state.paperEdit.openObject.paperID
				});
			} else {
				state.activeOutlineResearchNotes.splice(findIndex, 1);	
			}
		},
	},//e:mutations
}
