<!--
- Reference List > Modal > Create Reference Step 4 Third Party
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>
		
		<create-reference-step-4-third-party-body :is-drawer="false" />
	</div>
</template>

<script>
import CreateReferenceStep4ThirdPartyBody from '@/modules/CreateReferenceStep4ThirdPartyBody'

export default {
	name: 'ReferenceListCreateReferenceStep4ThirdParty',
	components: {
		CreateReferenceStep4ThirdPartyBody,
	}
}
</script>
