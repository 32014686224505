/*
- Loaders
-- ex: this.$store.state.loaders.x
*/
export default {
	namespaced: true,
	state:{
		showArray: [],		// Array of loader ids (strings) that are shown (each loader component deals with this on it's own)
	},//e:state

	mutations: {
		ADD_ID(state, loaderId){
			if(!state.showArray.includes(loaderId)){
				state.showArray.push(loaderId);
			}
		},
		REMOVE_ID(state, loaderId){
			if(state.showArray.includes(loaderId)){
				state.showArray.splice(state.showArray.indexOf(loaderId), 1);
			}
		},
	},//e:mutations
}
