<!--
- Animated Ellipsis
-- Uses a timer to animate 1,2,3 periods at the end of test 
-- 2022.09.22
-->

<template>
	<span>{{ dotOutput }}</span>
</template>

<script>

export default {
	name: 'AnimatedEllipsis',
	data() {
		return {
			dotOutput: '.',
			dotUpdateTimer: null,
		}
	},
	mounted() {
		this.dotUpdateTimer = setInterval(()=>{
			if(this.dotOutput.length <= 1){
				this.dotOutput = '..';
			} else if(this.dotOutput.length === 2){
				this.dotOutput = '...';
			} else {
				this.dotOutput = '.';
			}
		}, 750);
	},
	destroyed(){
		clearInterval(this.dotUpdateTimer);
	},
}
</script>
