var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: {
            title: "Edit Reference",
            "close-to-route-name": "OutlineReferencesCitations",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "drawer-body" },
      [_c("edit-reference-body", { attrs: { "is-drawer": true } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }