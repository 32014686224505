/*
- Work
-- ex: this.$store.state.work.x
*/
export default {
	namespaced: true,

	state:{
		list: [],		// Array
	},//e:state

	mutations: {
		SET_LIST(state, list){
			state.list = list;
		},
	},//e:mutations
}
