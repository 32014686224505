/*
- Insert Raw HTML
-- Inserts raw html into the editor (table and figure inserts from the tool drawer)
-- 2022.09.22
*/

import store from '@/store';

export default {
	insertBlock(rawHtml){
		let editor = CKEDITOR.instances[window.$vm.$store.state.paperEdit.ck.editorLastFocused];
		
		if(!editor){
			return false;
		}
	
		// START get cursor
		let sel = editor.getSelection();
		let element = sel.getStartElement();
		sel.selectElement(element);

		// element here needs to be after any other element, if i insert into an image, table should insert after the entire image element <figure>
		let insertElement = CKEDITOR.dom.element.createFromHtml(rawHtml);

		// is user inserting inside any figure element?
		let commonParentElement = element.getCommonAncestor(element.getParent());
		
		// array if element names that will trigger code to not nest elements
		const nestedElementNames = ['caption', 'figure', 'figcaption', 'tr', 'td'];
		
		if(nestedElementNames.indexOf(commonParentElement.getName()) === -1){
			// Not Inside Something

			// find our if there is anything real in this element
			if(element.getText().length <= 1){
				// inserting in an empty place, it's ok to replace it
				editor.insertElement(insertElement);
			} else {
				// inserting in a space that isn't empty, just go after
				insertElement.insertAfter(element);
			}
		} else {
			// Yes Inserting inside something
			if((commonParentElement.getName() === 'figure' || commonParentElement.getName() === 'figcaption')){
				// console.log('Inside Image');
				while (element.getName() !== 'figure') {
					element = element.getParent();
				}
			} else if((commonParentElement.getName() === 'tr' || commonParentElement.getName() === 'td')){
				// console.log('Inside Table');
				while (element.getName() !== 'table') {
					// console.log(element.getName());
					element = element.getParent();
				}
			} else {
				// console.log('Inside something else, not an image or table')
			}
		
			insertElement.insertAfter(element);
		}
	},

	insertText(rawText){
		let editor = CKEDITOR.instances[store.state.paperEdit.ck.editorLastFocused];
		
		if(!editor){
			return false;
		}
		editor.insertHtml(rawText);
	}
}