/*
- inactivityAlert
-- timer to track auto log out / session expire
-- ex: this.$store.state.paperEdit.inactivityAlert.x
*/
import _forEach from 'lodash/forEach';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';

const logOutTimeMs = 3600000;	// 60 * 60 * 1000 = 60 minutes
// const logOutTimeMs = 180000;	// 3 minutes

export default {
	namespaced: true,
	
	state: {
		stoTimer: null,
	},
	
	mutations: {
		START_TIMER(state, value) {
			if(state.stoTimer){
				clearTimeout(state.stoTimer);
			}
			state.stoTimer = setTimeout(()=>{
				// write the initial request local logs
				store.dispatch('logs/write', {
					message: 'Inactivity timer modal shown.',
				});

				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'Are you still working?',
					text: `If you're finished working with PERRLA for now, it's a good idea to close PERRLA Online in your browser. This will keep everything up to date and safe.`,
					iconHtml: '<span class="svg icons8-snooze"/>',
					cancelButtonText: `I'm finished`,
					confirmButtonText: `I'm still working`,
					showCloseButton: false,
					showCancelButton: true,
					showConfirmButton: true,
					customClass:{
						cancelButton: 'btn btn-danger',
						confirmButton: 'btn btn-outline-primary me-2',
						icon: 'custom-icon'
					},
				}).then((result) => {
					store.commit('paperEdit/SET_IS_DOCUMENT_SAVED', true);
					
					store.commit('paperEdit/outline/SET_IS_UNSAVED', false);

					if (result.value) {
						// I'm still working - reload the paper from the server
						window.location.reload();
						
					} else {
						// I'm finished
						// reset all ck instances so they are clean
						_forEach(CKEDITOR.instances, (ckInstance)=>{
							ckInstance.resetDirty();
						});

						router.push({
							name: 'Dashboard'
						}).catch(()=>{});
					}
				});
			}, logOutTimeMs);
		},
	},

	actions: {
		endTimer({state}) {
			return new Promise((resolve) => {
				// console.log('endTimer');
				clearTimeout(state.stoTimer);
				resolve();
			});//e:Promise
		},
		startTimer({commit}) {
			return new Promise((resolve) => {
				// console.log('startTimer');
				commit('START_TIMER');
				resolve();
			});//e:Promise
		},
		
	},

}