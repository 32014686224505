var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h2",
        { staticClass: "nodal-title" },
        [
          _c(
            "router-link",
            {
              staticClass: "link-icon",
              attrs: {
                to: {
                  name: "PaperTabResearchCreateReferenceStep1",
                  params: {
                    openReferenceTab: _vm.$route.params.openReferenceTab,
                  },
                },
              },
            },
            [
              _c("span", {
                staticClass: "svg bi-chevron-left",
                attrs: { title: "Back" },
              }),
            ]
          ),
          _vm._v("\n\n\t\tCreate a reference\n\t"),
        ],
        1
      ),
      _vm._v(" "),
      _c("create-reference-step-2-body", { attrs: { "is-drawer": false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }