<!--
- Module > Payment Settings
-- if Settings > Account & Billing : modal
-- if Cart > Payment Settings : in place panel
-->

<template>
	<div>
		<h3 class="mt-0">
			Enter a payment method
		</h3>
		<div class="help-text">
			* Required fields
		</div>

		<div class="row mt-3">
			<div class="col-6">
				<div class="d-grid">
					<button
						:class="['btn', {'btn-primary': isCreditCard, 'btn-outline-primary': !isCreditCard}]"
						type="button"
						@click.prevent="$store.commit('cart/SET_PAYMENT_TYPE_ID', $enums.CartPaymentType.CARD)">
						Credit Card
					</button>
				</div>
			</div>
			<div class="col-6">
				<div class="d-grid">
					<button
						:class="['btn', {'btn-primary': !isCreditCard, 'btn-outline-primary': isCreditCard}]"
						type="button"
						@click.prevent="$store.commit('cart/SET_PAYMENT_TYPE_ID', $enums.CartPaymentType.ECHECK)">
						E-Check
					</button>
				</div>
			</div>
		</div>
		<form>
			<div class="row mt-4">
				<div class="col-6">
					<form-input-cart
						label="First Name"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED"
						v-model="firstName" />
				</div>
				<div class="col-6">
					<form-input-cart
						label="Last Name"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED"
						v-model="lastName" />
				</div>
			</div>

			<!-- Credit Card fields 1 -->
			<div class="row mt-3" v-if="isCreditCard">
				<div class="col">
					<form-input-cart-credit-card
						label="Card Number"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED"
						v-model="cardNumber" />
				</div>
			</div>

			<!-- Credit Card fields 2 -->
			<div class="row mt-3" v-if="isCreditCard">
				<div class="col-6">
					<div class="form-input-cart">
						<label class="form-label">
							Expiration Date *
						</label>
					</div>

					<div class="row">
						<div class="col-6">
							<form-input-cart
								label="MM"
								placeholder="MM"
								:form-id="zFormId"
								:is-numeric-only="true"
								:max-length="2"
								:show-label="false"
								:validate-enum="$enums.Validation.NUMERIC_REQUIRED"
								v-model="expirationMonth" />
						</div>
						<div class="col-6">
							<form-input-cart
								label="YYYY"
								placeholder="YYYY"
								:form-id="zFormId"
								:is-numeric-only="true"
								:max-length="4"
								:show-label="false"
								:validate-enum="$enums.Validation.NUMERIC_REQUIRED"
								v-model="expirationYear" />
						</div>
					</div>
				</div>
			
				<div class="col-6">
					<form-input-cart
						label="CVV Number"
						:form-id="zFormId"
						:is-numeric-only="true"
						:max-length="4"
						:validate-enum="$enums.Validation.NUMERIC_REQUIRED"
						v-model="verificationCode" />
				</div>
			</div>
			
			<!-- E-Check fieds 1 -->
			<div class="row mt-3" v-if="!isCreditCard">
				<div class="col">
					<form-input-cart
						label="Bank Name"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED"
						v-model="bankName" />
				</div>
			</div>

			<!-- E-Check fieds 2 -->
			<div class="row mt-3" v-if="!isCreditCard">
				<div class="col">
					<form-input-cart
						label="Account Number"
						:form-id="zFormId"
						:is-numeric-only="true"
						:validate-enum="$enums.Validation.NUMERIC_REQUIRED"
						v-model="accountNumber" />
				</div>
			</div>
			
			<!-- E-Check fieds 3 -->
			<div class="row mt-3" v-if="!isCreditCard">
				<div class="col">
					<form-input-cart
						label="Routing Number"
						:form-id="zFormId"
						:is-numeric-only="true"
						:validate-enum="$enums.Validation.NUMERIC_REQUIRED"
						v-model="routingNumber" />
				</div>
			</div>

			<!-- Last Row - BOTH -->
			<div class="row mt-3">
				<div class="col-6" v-if="!isCreditCard">
					<label class="form-label">
						Account Type*
					</label>

					<select class="form-select" v-model="accountType">
						<option value="1">
							Checking Account
						</option>
						<option value="2">
							Savings Account
						</option>
					</select>
				</div>
				<div class="col-6">
					<form-input-cart
						field-id="_PID_CartZipCode"
						label="Zip Code"
						:form-id="zFormId"
						:is-disabled="isNonUsAddress"
						:is-numeric-only="true"
						:max-length="5"
						v-model="zipCode" />

					<div class="form-check ms-3 mt-1">
						<input class="form-check-input" type="checkbox" :value="true" id="chkIsNonUsAddress" v-model="isNonUsAddress" />
						<label class="form-check-label" for="chkIsNonUsAddress">
							Non-US Billing Address
						</label>
					</div>
				</div>
			</div>

			<div class="row mt-3" v-if="isModal">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="$store.commit('modals/CLOSE', 'AddPaymentMethod')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button
							class="btn btn-success"
							type="submit"
							@click.prevent="btnAddPaymentMethod">
							Update Payment Method
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInputCart from '@/components/form/InputCart';
import FormInputCartCreditCard  from '@/components/form/InputCartCreditCard';

export default {
	name: 'PaymentSettings',
	data() {
		return {
			zFormId: 'formCartPaymentSettings',
		}
	},
	props:{
		isModal:{
			default: false,
			type: Boolean,
		}
	},
	computed:{
		accountNumber:{
			get(){
				return this.$store.state.cart.accountNumber
			},
			set(newValue){
				this.$store.commit('cart/SET_ACCOUNT_NUMBER', newValue);
			}
		},
		accountType:{
			get(){
				return this.$store.state.cart.accountType
			},
			set(newValue){
				this.$store.commit('cart/SET_ACCOUNT_TYPE', newValue);
			}
		},
		bankName:{
			get(){
				return this.$store.state.cart.bankName
			},
			set(newValue){
				this.$store.commit('cart/SET_BANK_NAME', newValue);
			}
		},

		firstName:{
			get(){
				return this.$store.state.cart.firstName
			},
			set(newValue){
				this.$store.commit('cart/SET_FIRST_NAME', newValue);
			}
		},
		isCreditCard(){
			if(this.$store.state.cart.paymentTypeId === config.enums.CartPaymentType.CARD){
				return true;
			}

			return false;
		},
		lastName:{
			get(){
				return this.$store.state.cart.lastName
			},
			set(newValue){
				this.$store.commit('cart/SET_LAST_NAME', newValue);
			}
		},
		cardNumber:{
			get(){
				return this.$store.state.cart.cardNumber
			},
			set(newValue){
				this.$store.commit('cart/SET_CARD_NUMBER', newValue);
			}
		},
		expirationMonth:{
			get(){
				return this.$store.state.cart.expirationMonth
			},
			set(newValue){
				this.$store.commit('cart/SET_EXPIRATION_MONTH', newValue);
			}
		},
		expirationYear:{
			get(){
				return this.$store.state.cart.expirationYear
			},
			set(newValue){
				this.$store.commit('cart/SET_EXPIRATION_YEAR', newValue);
			}
		},
		isNonUsAddress:{
			get(){
				return this.$store.state.cart.isNonUsAddress
			},
			set(newValue){
				this.$store.commit('cart/SET_IS_NON_US_ADDRESS', newValue);
				if(newValue){
					this.zipCode = ''; // if true then zip code should get blanked out
					let txtZipCode = document.getElementById('_PID_CartZipCode');
					if(txtZipCode){
						txtZipCode.classList.remove('is-invalid');
					}
					let txtZipCodeError = document.getElementById('_PID_CartZipCodeError');
					if(txtZipCodeError){
						txtZipCodeError.remove();
					}
					
				}
			}
		},
		routingNumber:{
			get(){
				return this.$store.state.cart.routingNumber
			},
			set(newValue){
				this.$store.commit('cart/SET_ROUTING_NUMBER', newValue);
			}
		},
		verificationCode:{
			get(){
				return this.$store.state.cart.verificationCode
			},
			set(newValue){
				this.$store.commit('cart/SET_VERIFICATION_CODE', newValue);
			}
		},
		zipCode:{
			get(){
				return this.$store.state.cart.zipCode
			},
			set(newValue){
				this.$store.commit('cart/SET_ZIP_CODE', newValue);
			}
		},
	},
	watch:{
		zipCode(newValueObj){
			// https://stackoverflow.com/questions/28821804/how-can-i-quickly-determine-the-state-for-a-given-zipcode
			if(newValueObj && newValueObj.length === 5){
				// Ensure we don't parse strings starting with 0 as octal values 
				const zipcode = parseInt(newValueObj, 10);
				
				// check if this is a TN zip code
				if (zipcode >= 37000 && zipcode <= 38599) {
					this.$store.commit('cart/SET_CHARGE_TAX', true);
					this.$store.dispatch('cart/updateCartTotals');
				} else {
					this.$store.commit('cart/SET_CHARGE_TAX', false);
					this.$store.dispatch('cart/updateCartTotals');
				}
			} else {
				this.$store.commit('cart/SET_CHARGE_TAX', false);
				this.$store.dispatch('cart/updateCartTotals');
			}
		}
	},
	methods: {
		btnAddPaymentMethod(){
			// console.log('btnAddPaymentMethod');
		
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				let sendBody;

				if(this.isCreditCard){
					sendBody = {
						cardNumber:  this.cardNumber.replace(/\s/g, ''),
						expirationMonth: this.expirationMonth.padStart(2, '0'),
						expirationYear: this.expirationYear.padStart(4, '0'),
						name: 'Credit card ending in ' + this.cardNumber.slice(this.cardNumber.length - 4, this.cardNumber.length),
						paymentMethodID: 0, //?
						paymentTypeID: config.enums.CartPaymentType.CARD,
						verificationCode: this.verificationCode.padEnd(3, '0'),
						zipCode: this.zipCode,
					}
				} else {
					sendBody = {
						accountNumber:  this.accountNumber,	// for testing: can be any random number, just mske it over 4 charaters
						accountType:  this.accountType,
						bankName:  this.bankName,
						name: 'Checking account ending in ' + this.accountNumber.slice(this.accountNumber.length - 4, this.accountNumber.length),
						nameOnCard: '',
						paymentMethodID: 0, //?
						paymentTypeID: config.enums.CartPaymentType.ECHECK,
						routingNumber:  this.routingNumber, // for testing: 064103529
						zipCode: this.zipCode,
					}
				}
				// console.log('sendBody');
				// console.log(sendBody);

				ApiS_MakeCall({
					body: sendBody,
					ignoreAuth: (this.$store.state.customer.id == '0') ? true : false, // changes true or false based on if user is new or updating 
					method: 'POST',
					responseType: 'json',
					url: 'v4/PaymentMethod',
				}).then(()=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					// this should refresh the page which will close the modal and show the payment method
					// this.$router.go();
					window.location.reload();

				}).catch((error)=>{
					// there was an error - clear the payment just incase
					this.$store.commit('loaders/REMOVE_ID', 'App');
					
					this.$store.commit('cart/CLEAR_PAYMENT_METHOD');
				
					this.emitter.emit('globalToasterOpen', {
						body: error.message,
						isSticky: true,
						title: 'Error',
						type: 'danger'
					});
				});//e:ApiS_MakeCall
			}).catch(()=>{});//e:AS_ValidateForm
		},
	},
	mounted(){
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components: {
		FormInputCart,
		FormInputCartCreditCard,
	},
}
</script>
