/*
- dateToLongString
-- takes an isoString and returns a long format string
-- used in a table row to show Due Date (example)
-- 2023.01.02
*/

import _has from 'lodash/has';
import config from '@/config';
import dateFormat from "dateformat";

export default ($opts) => {
	if(!$opts.dateValue){
		return '';
	}
	
	const _$opts = $opts || {};
	const _forceYear = (_has(_$opts, 'forceYear')) ? _$opts.forceYear : false;
	const dateObj = new Date($opts.dateValue);


	if($opts.showTime){
		if(dateObj.getFullYear() === config.today.getFullYear()){
			return dateFormat(dateObj, 'mmm. d @ h:MM tt');
		} else {
			return dateFormat(dateObj, 'mmm. d, yyyy @ h:MM tt');
		}
	} else {
		if(_forceYear){
			return dateFormat(dateObj, 'mmm. d, yyyy');

		} else {
			if(dateObj.getFullYear() === config.today.getFullYear()){
				return dateFormat(dateObj, 'mmm. d');
			} else {
				return dateFormat(dateObj, 'mmm. d, yyyy');
			}
		}
	}
}