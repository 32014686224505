/*
- Sunset References
-- these would be APA6 and MLA8 parts only 
-- ex: this.$store.state.sunset.x
*/

const state = {
	focusIdOnLoad: '',	// focus on a specific reference control input or namemaker
	referenceDefaultName: '',	// default name for a reference - create ref step 2
	referenceDefaultValue: '',	// default value for a reference - create ref step 2
	referenceRules: [],			// reference rules engine
	referenceTypeId: 0,			// selected ref type
}

const mutations = {
	focusIdOnLoad(state, focusIdOnLoad) {
		state.focusIdOnLoad = focusIdOnLoad;
	},
	referenceDefaultName(state, referenceDefaultName) {
		state.referenceDefaultName = referenceDefaultName;
	},
	referenceDefaultValue(state, referenceDefaultValue) {
		state.referenceDefaultValue = referenceDefaultValue;
	},
	referenceTypeId(state, referenceTypeId) {
		state.referenceTypeId = referenceTypeId;
	},
	SET_REFERENCE_RULES(state, value) {
		state.referenceRules = value;
	},
}

export default {
	namespaced: true,
	state,
	mutations
}