<!--
- Single Reference Row in a list of refs in this paper
-->

<template>
	<div class="mb-3 element-row">
		<div :class="['dropstart ps-2', {'archived-reference': referenceObject.isArchived}]">
			
			<a href="#" class="svg bi-three-dots float-end" data-bs-toggle="dropdown" role="button" aria-expanded="false" aria-label="More Options" />

			<p class="mb-1 word-break" v-html="referenceObject.displayValue" v-add-blank-target ref="referenceRowOutput" />
			
			<ul class="dropdown-menu">
				<li v-if="!referenceObject.isArchived" key="liEditReference">
					<router-link
						:class="['dropdown-item hover hover-primary']"
						:to="{
							name: 'OutlineEditReference',
							params:{
								referenceUid: upperCaseRid
							}
						}">
							<span class="svg bi-pencil-fill" />
							Edit Reference
					</router-link>
				</li>
				<li>
					<a class="dropdown-item hover hover-danger" href="#" @click.prevent="removeRefFromPaper">
						<span class="svg bi-x-lg" />
						Remove Reference
					</a>
				</li>
			</ul>
		</div>

		<p class="ms-3 mb-1 archived-reference-help-text" v-if="referenceObject.isArchived">
			This reference has been archived.
		</p>

		<div class="d-grid" v-else>
			<router-link
				:class="['btn btn-sm btn-hover btn-hover-success ms-2']"
				:to="{
					name: 'OutlineCitationAdd',
					params: {
						referenceUid: upperCaseRid
					},
				}">
				<span class="svg bi-plus-lg" /> Add Citation
			</router-link>
		</div>
		
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import PS_RemoveReferenceFromPaper from '@/services/paper/removeReferenceFromPaper';

export default {
	name: 'ReferenceRow',
	props:{
		referenceObject:{
			required: true,
			type: Object,
		},
		referenceUniqueId:{
			required: true,
			type: String,
		},
	},
	data() {
		return {
			isHover: false,
		}
	},
	computed:{
		upperCaseRid(){
			return this.referenceUniqueId.toUpperCase();
		},
	},
	methods: {
		removeRefFromPaper(){
			let doesReferenceHaveGroupedCitation = false;

			// is this reference part of a grouped citation from a different reference?
			if(this.referenceObject.citations.length > 0){
				_forEach(this.referenceObject.citations, (citationObject)=>{
					if(_has(citationObject, 'groupUniqueID')){
						doesReferenceHaveGroupedCitation = true;
						return false;	// stop looping after you find one
					}
				});//e:_forEach
			}//e:if
			
			let $firstCitationEl = null;

			_forEach(CKEDITOR.instances, (ckInstance)=>{
				$firstCitationEl = ckInstance.document.findOne('span[data-reference-unique-id="' + this.referenceUniqueId + '"]');
				if($firstCitationEl){
					return false;	// stop looking when you find one
				}
			});
			
			if(doesReferenceHaveGroupedCitation || $firstCitationEl){
				// Active Citations - confirm delete
				// $emit back up to parent (References and Citations, it has a confirm takeover)
				this.emitter.emit('showRefDeleteWaring', {
					reference: this.referenceObject,
				});
			} else {
				// No active citations - just remove the reference from the paper
				PS_RemoveReferenceFromPaper({
					hasActiveCitations: false,
					isDrawer: true,
					reference: this.referenceObject,
				}).then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});
			}
		},//e:removeRefFromPaper

	},
	mounted(){
		
	},
	components: {
		
	},
}
</script>
