<!--
- Create Step 1 > All Tab > Search
-- this should really just act as a component to gather the search term and put in the vuex store
-- this.$store.state.referenceTypeSearchTerm
-->

<template>
	<div>
		<h3>Search for reference type</h3>

		<div class="d-flex">
			<div class="w-100">
				<input
					class="form-control"
					aria-label="Search for reference type"
					autocomplete="off"
					type="text"
					v-model="searchTerm" />
			</div>
			<div class="flex-shrink-1" v-show="searchTerm != ''">
				<button type="button" class="btn btn-link hover-underline" @click.prevent="clearSearch">
					Clear
				</button>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ReferenceCreateStep1Search',
	props:{
		refTypes: Array
	},
	computed:{
		searchTerm:{
			get(){
				return this.$store.state.referenceTypeSearchTerm;
			},
			set(value){
				let _searchTerm = value.toLowerCase();

				// clean this term (alpha numeric only)
				_searchTerm = _searchTerm.replace(/[^0-9a-z]/gi, ' ');
				this.$store.commit('REFERENCE_TYPE_SEARCH_TERM_SET', _searchTerm);
			}
		},
	},
	methods: {
		clearSearch(){
			this.searchTerm = '';
		},//e:clearSearch
	},
}
</script>
