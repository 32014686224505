var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "org-size" }, [
        _vm._v("\n\t\tStep 3 of 3: Confirm your reference\n\t"),
      ]),
      _vm._v(" "),
      _c("reference-output"),
      _vm._v(" "),
      _c("form", [
        _c(
          "section",
          { staticClass: "mb-5" },
          [
            _c("h3", [_vm._v("Optional Content")]),
            _vm._v(" "),
            _c("form-textarea", {
              attrs: {
                "help-text": "Add a private description to your Reference.",
                label: "Description",
                "pendo-grab-text": "Txt_RefCreate_Description",
                "form-id": _vm.zFormId,
                "is-autosize": true,
              },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _vm._v(" "),
            _c("form-tags", {
              attrs: {
                "pendo-grab-text": "Txt_RefCreate_Tags",
                "form-id": _vm.zFormId,
              },
              model: {
                value: _vm.tags,
                callback: function ($$v) {
                  _vm.tags = $$v
                },
                expression: "tags",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-between" }, [
          _c("div", { staticClass: "col" }, [
            _vm.$router.currentRoute.meta.module ===
            _vm.$enums.Module.PAPER_EDIT
              ? _c(
                  "div",
                  { key: "divModulePaperEdit", staticClass: "d-grid" },
                  [
                    _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.EDITOR
                      ? _c(
                          "router-link",
                          {
                            key: "routerLinkTabEditor",
                            staticClass: "btn btn-outline-primary",
                            attrs: {
                              to: {
                                name: "EditorCreateReferenceStep2",
                                params: {
                                  openReferenceTab:
                                    _vm.$route.params.openReferenceTab,
                                  referenceTypeSlug:
                                    _vm.$route.params.referenceTypeSlug,
                                },
                              },
                            },
                          },
                          [_vm._v("Back")]
                        )
                      : _vm.$router.currentRoute.meta.tab ===
                        _vm.$enums.Tab.OUTLINE
                      ? _c(
                          "router-link",
                          {
                            key: "routerLinkTabOutline",
                            staticClass: "btn btn-outline-primary",
                            attrs: {
                              to: {
                                name: "OutlineCreateReferenceStep2",
                                params: {
                                  openReferenceTab:
                                    _vm.$route.params.openReferenceTab,
                                  referenceTypeSlug:
                                    _vm.$route.params.referenceTypeSlug,
                                },
                              },
                            },
                          },
                          [_vm._v("Back")]
                        )
                      : _c(
                          "button",
                          {
                            key: "routerLinkTabElse",
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.moduleModalBack.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t\tBack\n\t\t\t\t\t")]
                        ),
                  ],
                  1
                )
              : _c("div", { key: "divModuleElse", staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.moduleModalBack.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tBack\n\t\t\t\t\t")]
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col text-end" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.createReference.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }