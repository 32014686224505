var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer drawer-add-from-library" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: {
            title: "Add Ref from Library",
            "close-to-route-name": "OutlineReferencesCitations",
          },
        }),
        _vm._v(" "),
        _c("add-reference-from-library-header", {
          attrs: { "is-drawer": true },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "drawer-body" },
      [_c("add-reference-from-library-body", { attrs: { "is-drawer": true } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-footer" }, [
      _c(
        "div",
        { staticClass: "d-grid" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-success",
              attrs: { to: { name: "OutlineReferencesCitations" } },
            },
            [_vm._v("\n\t\t\t\tFinished\n\t\t\t")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }