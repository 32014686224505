<!--
- Generic Form Label and Input
-->
<template>
	<div :class="['form-group', {'row':isHorizontal}]">

		<label
			:class="[{'fst-italic':isInvalidShown, 'col-sm-3 col-form-label': isHorizontal}]"
			:for="safeId"
			v-if="label"
			v-html="label" />

		<div class="help-text mb-1" v-if="helpText" v-html="helpText" />

		<div :class="{'col-sm-9': isHorizontal}">
			<select :class="['form-select', {'is-invalid':isInvalidShown}]" :value="value" :id="safeId" @change="selectChange($event.target.value)">
				<option v-for="(singleObject, key) in calcSelectObject" :key="key" :value="singleObject.value" :disabled="singleObject.isDisabled">
					{{ singleObject.name }}
				</option>
			</select>

			<div class="invalid-feedback" v-if="isInvalidShown">
				{{ invalidErrorMessage }}
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import mixinId from '@/mixins/id';

export default {
	name: 'FormSelect',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isClassSelect: {
			default: false,
			type: Boolean
		},
		isCommonUse: {
			default: false,
			type: Boolean
		},
		isHorizontal:{
			default: false,
			type: Boolean,
		},
		helpText: {
			type: String,
		},
		label:{
			type: String,
		},
		selectObject:{
			type: Array
		},
		type:{
			default: 'text',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value:{
			default: 0,
			type: [Number, String],
		},
	},
	data(){
		return {
			isInvalid: false,
		}
	},
	computed:{
		calcSelectObject(){
			if(this.isClassSelect){
				// use the classList to build an object in the proper layout
				let returnArray = [{
					value: "0",
					name: "-- NONE --",
				}];

				let _data = _cloneDeep(this.$store.state.classes.list);

				_data.forEach((classData) =>{
					if(!classData.isArchived){
						returnArray.push({
							value: classData.classID,
							name: classData.name,
						});
					}
				});//e:forEach
				
				return returnArray;
			}
			
			return this.selectObject;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.safeId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		selectChange(value){
			// treat this like a form input blur, on change make sure it's still valid
			AS_ValidateForm({
				singleFieldId: this.safeId
			}).then(()=>{
				this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
					formId: this.formId,
				});
				// this.$emit('blur-input', value);
				this.$emit('input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		}//e:selectChange
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.safeId,
				validateEnum: this.validateEnum,
			})
		}

	},
}
</script>
