<!--
- Paper Edit > Write Tab > Title Page
-->

<template>
	<div>
		<div :class="calcInstanceStyles">

			<paper-running-head page-type="title" />

			<a href="#" v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7" class="fake-widget fake-widget-title-page text-center" @click.prevent="openTitlePageDrawer" title="Click to Edit your Title Page" key="titleWidgetAPA7">
				<br /><br /><br />
				<p class="fw-bold">
					{{ Title }}
				</p>
				<br /><br />
				<p>{{ StudentName }}</p>
				<p>{{ Institution }}</p>
				<p>{{ CourseName }}</p>
				<p>{{ Teacher }}</p>
				<p>{{ PublishedDate }}</p>
				<br /><br /><br />
			</a>

			<a href="#" v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9" class="fake-widget fake-widget-title-page" @click.prevent="openTitlePageDrawer" title="Click to Edit your Title Page" key="titleWidgetMLA9">
				<p>{{ StudentName }}</p>
				<p>{{ Teacher }}</p>
				<p>{{ CourseName }}</p>
				<p>{{ PublishedDate }}</p>
			</a>

			<a href="#" v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" class="fake-widget fake-widget-title-page text-center" @click.prevent="openTitlePageDrawer" title="Click to Edit your Title Page" key="titleWidgetTurabian9">
				<br /><br /><br /><br /><br /><br />

				<p class="fw-bold" v-html="calcSplitTitle" />
				
				<br /><br /><br /><br /><br /><br />
				<p>{{ StudentName }}</p>
				<p>{{ Institution }}</p>
				<p>{{ CourseName }}</p>
				<p>{{ Teacher }}</p>
				<p>{{ PublishedDate }}</p>
				<br /><br /><br />
			</a>
			
			<div v-if="IncludeAuthorNote">
				<br /><br /><br />
				<p class="fw-bold text-center">
					Author Note
				</p>

				<textarea id="ckPaperAuthorNote" v-model="paperAuthorNoteContent" autocomplete="off" class="d-none" />
			</div>
		</div>
	</div>
</template>

<script>
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import PaperRunningHead from './RunningHead';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';

export default {
	name: 'EditorTitlePage',
	data() {
		return {
			paperAuthorNoteContent: '',
		}
	},
	computed:{
		calcInstanceStyles(){
			let returnClassList = [
				'instance-title-page',
			]

			// MLA9 Title page isn't separated, so it wont' have a page-group
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnClassList.push('page-group', 'page-group-min-height', 'page-group-apa7');
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnClassList.push('page-group', 'page-group-min-height', 'page-group-turabian9');
			}
			return returnClassList;
		},
		calcSplitTitle(){
			// The title and subtitle are broken into separate lines at the semi-colon. If a student puts in a title that contains a sub-title, we need to automatically break it onto a second line.
			let savedTitle = this.$store.state.paperEdit.meta.Title;
			let arraySplit = savedTitle.split(': ');

			let returnTitle = '';
			
			if(arraySplit.length <= 1){
				// not a real split, make the whole thing a title
				returnTitle = savedTitle;
			} else {
				// there is a split, start us off
				returnTitle = arraySplit[0] + ': <br />';

				// loop through each split array (incase user added more than one)
				arraySplit.forEach((titlePart, index)=>{
					if(index > 0){
						returnTitle += titlePart
					}
				});
			}
		
			return returnTitle;
		},
		CourseName(){
			return this.$store.state.paperEdit.meta.CourseName;
		},
		IncludeAuthorNote(){
			if(this.$store.state.paperEdit.meta.PaperType === config.enums.PaperType.APA7_ProfessionalPaper){
				return this.$store.state.paperEdit.meta.IncludeAuthorNote;
			} else {
				return false;
			}
		},
		Institution(){
			return this.$store.state.paperEdit.meta.Institution
		},
		PublishedDate(){
			return this.$store.state.paperEdit.meta.PublishedDate;
		},
		StudentName(){
			return this.$store.state.paperEdit.meta.StudentName;
		},
		Teacher(){
			return this.$store.state.paperEdit.meta.Teacher;
		},
		Title(){
			return this.$store.state.paperEdit.meta.Title;
		},
	},
	methods: {
		openTitlePageDrawer(){
			if(this.$route.name !== 'EditorTitlePage'){
				this.$router.push({
					name:'EditorTitlePage'
				}).catch(()=>{});
			}
		},
		loadAuthorNote(){
			this.paperAuthorNoteContent = this.$store.state.paperEdit.authorsNote.content;

			if(CKEDITOR.instances.ckPaperAuthorNote){
				// Ck Instance - set the content (i don't think this can happen but its good to have)
				CKEDITOR.instances.ckPaperAuthorNote.setData(this.paperAuthorNoteContent);
			} else {
				// No Ck Instance - make one
				var $this = this;

				let configCkPaperAuthorNote = configCkEditor.getSettings('ckPaperAuthorNote');

				configCkPaperAuthorNote.on = {
					focus(evt) {
						PCKS_OnFocus({
							editorName: evt.editor.name,
						});
					},//e:focus

					instanceReady(evt) {
						evt.editor.$ckEditorType = config.enums.CkEditorType.AUTHORS_NOTE;

						CKEDITOR.on('dialogDefinition', function (ev) {
							ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
						});

						$this.emitter.emit('loadCkAbstract');

						PCKS_wProofreaderInit({
							container: evt.editor.window.getFrame().$,
						}).then(()=>{
						});

					},//e:instanceReady
				}//e:on
				
				CKEDITOR.replace('ckPaperAuthorNote', configCkPaperAuthorNote);
			}
		},//e:loadAuthorNote
	},
	created(){
		this.emitter.on('loadCkTitlePage', ()=>{
			if(this.IncludeAuthorNote){
				this.loadAuthorNote();	
			} else {
				this.emitter.emit('loadCkAbstract');
			}
		});
	},
	destroyed(){
		this.emitter.off('loadCkTitlePage');
		
		if(CKEDITOR.instances.ckPaperAuthorNote){
			this.$store.commit('paperEdit/authorsNote/CONTENT', CKEDITOR.instances.ckPaperAuthorNote.getData());
			CKEDITOR.instances.ckPaperAuthorNote.focusManager.blur(true);
			CKEDITOR.instances.ckPaperAuthorNote.destroy(true);
		} else {
			this.$store.commit('paperEdit/authorsNote/CONTENT', '');
		}
	},
	components:{
		PaperRunningHead,
	},
}
</script>


<style lang="scss">
	@import "../../../assets/styles/vars_perrla.scss";

	.instance-title-page {
		.cke {
			border: 1px dashed $gray-300 !important;
			margin-left: 36px;
		}
	}
</style>
