var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-5" }, [
    !_vm.$isLive
      ? _c("div", { staticClass: "alert alert-danger" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "h1",
      { staticClass: "text-center mt-4 mb-0 text-large d-none d-md-block" },
      [_vm._v("\n\t\tSubscribe to PERRLA\n\t")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "boxset-wrp" },
      [
        _c(
          "transition",
          { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
          [
            _vm.isSignIn
              ? _c("div", { key: "divSignIn", staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-divider-right pt-2" },
                    [
                      _c("h2", { staticClass: "text-center mb-3" }, [
                        _vm._v("\n\t\t\t\t\t\tSign in\n\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        [
                          _vm.isLoginError
                            ? _c("div", { staticClass: "alert alert-danger" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tThe email or password provided is incorrect\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("form-input", {
                            attrs: {
                              label: "Email",
                              "form-id": _vm.zFormIdLogin,
                              "is-horizontal": true,
                              "validate-enum":
                                _vm.$enums.Validation.EMAIL_REQUIRED,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _vm._v(" "),
                          _c("form-password", {
                            attrs: {
                              label: "Password",
                              "form-id": _vm.zFormIdLogin,
                              "is-horizontal": true,
                              "validate-enum": _vm.$enums.Validation.REQUIRED,
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "link-forgot-password" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "hover-underline",
                                  attrs: {
                                    to: {
                                      name: "ForgotPassword",
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tForgot password?\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-grid gap-2 mt-4" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { type: "submit" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnSignIn.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tSign in\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.isSignIn = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tCreate a new account\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 pt-2 org-size ps-3" },
                    [
                      _c("img", {
                        staticClass: "mx-auto d-none d-md-block",
                        attrs: {
                          src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                          width: "140px",
                          role: "presentation",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "h1",
                        { staticClass: "d-none d-md-block text-center" },
                        [_vm._v("\n\t\t\t\t\t\tWelcome back!\n\t\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c("strong", [
                            _vm._v("Having trouble accessing your account?"),
                          ]),
                          _c("br"),
                          _vm._v("\n\t\t\t\t\t\tYou can "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "ForgotPassword",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\treset your account password here\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(".\n\t\t\t\t\t"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\tIf that doesn't work, reach out to our Support team. You can reach them via email at "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:support@perrla.com?subject=I%27ve%20got%20a%20question",
                              },
                            },
                            [_vm._v("support@perrla.com")]
                          ),
                          _vm._v(", or \n\t\t\t\t\t\t"),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "ContactSupport",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tcontact them here\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(".\n\t\t\t\t\t"),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _c("div", { key: "divRegister", staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-divider-right pt-2" },
                    [
                      _c(
                        "form",
                        [
                          _c("h2", { staticClass: "text-center mb-3" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tCreate your account\n\t\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("form-input", {
                            attrs: {
                              label: "First Name",
                              "form-id": _vm.zFormIdSubcribe,
                              "is-horizontal": true,
                              "validate-enum": _vm.$enums.Validation.REQUIRED,
                            },
                            model: {
                              value: _vm.firstName,
                              callback: function ($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName",
                            },
                          }),
                          _vm._v(" "),
                          _c("form-input", {
                            attrs: {
                              label: "Last Name",
                              "form-id": _vm.zFormIdSubcribe,
                              "is-horizontal": true,
                              "validate-enum": _vm.$enums.Validation.REQUIRED,
                            },
                            model: {
                              value: _vm.lastName,
                              callback: function ($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName",
                            },
                          }),
                          _vm._v(" "),
                          _c("form-input", {
                            attrs: {
                              label: "Email",
                              "form-id": _vm.zFormIdSubcribe,
                              "is-horizontal": true,
                              "validate-enum":
                                _vm.$enums.Validation.EMAIL_REQUIRED,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _vm._v(" "),
                          _c("form-password", {
                            attrs: {
                              label: "Password",
                              "form-id": _vm.zFormIdSubcribe,
                              "is-horizontal": true,
                              "validate-enum": _vm.$enums.Validation.REQUIRED,
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-grid gap-2 mt-4 mb-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { type: "submit" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnCreateAccount.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tCreate my account\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.isSignIn = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tSign in to an existing account\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "org-size" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tWant to try PERRLA before you buy?\n\t\t\t\t\t\t\t"
                            ),
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnGoToFreeTrial.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Click here to start a 7-day Free Trial."
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col pt-2 org-size ps-3" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v("What's included in a PERRLA subscription?"),
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\t\tEvery PERRLA Subscription includes access to:"
                      ),
                      _c("br"),
                      _vm._v("\n\t\t\t\t\t\t- PERRLA's Microsoft Word Add-in"),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\t\t- Web-based PERRLA Online Application"
                      ),
                      _c("br"),
                      _vm._v("\n\t\t\t\t\t\t- Google Chrome Extension"),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\t\t- Access to our amazing Customer Support Team"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\t\t\t\t\t\t- Immediate access to all new features & improvements\n\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("strong", [
                          _vm._v(
                            "I've used PERRLA before. Should I create a new account?"
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\tPlease don't. Your existing PERRLA account has all of your papers, references, and work saved in it. We recommend you \n\n\t\t\t\t\t\t"
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Login",
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tlog into your existing account\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n\t\t\t\t\t\tand renew your subscription from there. Plus, it could save you some money!\n\t\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("strong", [
                          _vm._v(
                            "What if I have trouble getting started with PERRLA?"
                          ),
                        ]),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\tWe have an amazing US-based Support team standing by. They work every day until 10pm Central ready to help. You can reach them via email at "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:support@perrla.com?subject=I%27ve%20got%20a%20question",
                            },
                          },
                          [_vm._v("support@perrla.com")]
                        ),
                        _vm._v(", or \n\n\t\t\t\t\t\t"),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "ContactSupport",
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tcontact them here\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(".\n\t\t\t\t\n\t\t\t\t\t"),
                      ],
                      1
                    ),
                  ]),
                ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("main", [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v("\n\t\t\t\tPricing\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("section", [
          _c("div", { staticClass: "container d-none d-md-block" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-4 align-self-center text-center" },
                [
                  _c("main", { staticClass: "pricing-padding-top" }, [
                    _c("h3", [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t7-day Free Trial\n\t\t\t\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-grid" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-primary btn-lg btn-shadow d-block text-uppercase",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.btnGoToFreeTrial.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\t\tStart a Free Trial\n\t\t\t\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _vm._m(7),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _vm._m(8),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _vm._m(9),
    _vm._v(" "),
    _c("div", { staticClass: "divider" }),
    _vm._v(" "),
    _vm._m(10),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fw-bold p-2" }, [
      _vm._v(
        "\n\t\t\tThis is testing environment, to make a purchase, please visit: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.perrlacomplete.com/Public/#/cart-step-1",
          },
        },
        [_vm._v("https://www.perrlacomplete.com/Public/#/cart-step-1")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "price py-3" }, [
      _vm._v("\n\t\t\t\t\t\t\t\t\t$0"),
      _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 align-self-center text-center" }, [
      _c("main", [
        _c("div", { staticClass: "best-value-text" }, [
          _vm._v("\n\t\t\t\t\t\t\t\t\tBEST VALUE\n\t\t\t\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("h3", { staticStyle: { "font-weight": "800" } }, [
          _vm._v("\n\t\t\t\t\t\t\t\t\tAnnual Subscription\n\t\t\t\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "price text-success py-3" }, [
          _vm._v("\n\t\t\t\t\t\t\t\t\t$79.95"),
          _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 align-self-center text-center" }, [
      _c("main", { staticClass: "pricing-padding-top" }, [
        _c("h3", [
          _vm._v("\n\t\t\t\t\t\t\t\t\t6-month Subscription\n\t\t\t\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "price py-3" }, [
          _vm._v("\n\t\t\t\t\t\t\t\t\t$49.95"),
          _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-none d-sm-block d-md-none" }, [
      _c("table", { staticClass: "table table-borderless table-price-grid" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "text-center" }, [
              _vm._v("\n\t\t\t\t\t\t\t\t\t7-day  Free Trial\n\t\t\t\t\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "price py-3 text-center" }, [
                _vm._v("\n\t\t\t\t\t\t\t\t\t\t$0"),
                _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-center fw-bolder" }, [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\tAnnual Subscription\n\t\t\t\t\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "price text-success py-3 text-center" }, [
                _vm._v("\n\t\t\t\t\t\t\t\t\t\t$79.95"),
                _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "text-center" }, [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t6-month Subscription\n\t\t\t\t\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "price py-3 text-center" }, [
                _vm._v("\n\t\t\t\t\t\t\t\t\t\t$49.95"),
                _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-block d-sm-none mt-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 align-self-center text-center" }, [
          _c("main", [
            _c(
              "h2",
              { staticStyle: { "font-size": "32px", "font-weight": "400" } },
              [_vm._v("\n\t\t\t\t\t\t\t\t\t7-day Free Trial\n\t\t\t\t\t\t\t\t")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "price" }, [
              _vm._v("\n\t\t\t\t\t\t\t\t\t$0"),
              _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 align-self-center text-center" }, [
          _c("main", { staticStyle: { position: "relative" } }, [
            _c(
              "h1",
              { staticStyle: { "font-size": "32px", "font-weight": "700" } },
              [
                _vm._v(
                  "\n\t\t\t\t\t\t\t\t\tAnnual Subscription\n\t\t\t\t\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "best-value-text d-none d-lg-block" }, [
              _vm._v("\n\t\t\t\t\t\t\t\t\tBEST VALUE\n\t\t\t\t\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "price text-success" }, [
              _vm._v("\n\t\t\t\t\t\t\t\t\t$79.95"),
              _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ol-12 align-self-center text-center" }, [
          _c("main", [
            _c(
              "h2",
              { staticStyle: { "font-size": "32px", "font-weight": "400" } },
              [
                _vm._v(
                  "\n\t\t\t\t\t\t\t\t\t6-month Subscription\n\t\t\t\t\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "price" }, [
              _vm._v("\n\t\t\t\t\t\t\t\t\t$49.95"),
              _c("span", { staticClass: "usd" }, [_vm._v("USD")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("main", { staticClass: "org-size" }, [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v("\n\t\t\t\tPERRLA handles all the formatting.\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "img-fluid mb-2",
          attrs: {
            src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/handle-formatting.png",
            role: "presentation",
          },
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "PERRLA formats your entire document for you, automatically – including "
          ),
          _c("strong", [_vm._v("page margins")]),
          _vm._v(", "),
          _c("strong", [_vm._v("text styles")]),
          _vm._v(", "),
          _c("strong", [_vm._v("paragraph spacing")]),
          _vm._v(", "),
          _c("strong", [_vm._v("headers")]),
          _vm._v(", and "),
          _c("strong", [_vm._v("section breaks")]),
          _vm._v("."),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Create a paper for all your assignments: "),
          _c("strong", [_vm._v("Research Papers")]),
          _vm._v(", "),
          _c("strong", [_vm._v("Discussion Posts")]),
          _vm._v(", "),
          _c("strong", [_vm._v("Reference Lists")]),
          _vm._v(", and "),
          _c("strong", [_vm._v("Annotated Bibliographies ")]),
          _vm._v("for the APA 7 and MLA 9 formats."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("div", { staticClass: "container---grid" }, [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v("\n\t\t\t\tHear what "),
          _c("em", [_vm._v("real")]),
          _vm._v(" students have to say about PERRLA\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "video square w-video w-embed" }, [
          _c("div", { staticClass: "ratio ratio-1x1" }, [
            _c("iframe", {
              staticStyle: {
                width: "100%",
                height: "100%",
                "pointer-events": "auto",
              },
              attrs: {
                allow: "autoplay; encrypted-media",
                allowfullscreen: "",
                src: "https://www.youtube-nocookie.com/embed/taKK3OGfXSw?rel=0&controls=1&autoplay=1&mute=1&start=0",
                title: "What real students say about PERRLA",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-content" }, [
          _c("p", [
            _vm._v(
              "\n\t\t\t\t\tEvery year students face the anxiety and stress of writing papers. Helping students get through the challenges of writing papers is our mission.\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n\t\t\t\t\tWe get a lot of satisfaction helping students overcome those fears and become successful writers so they can get through school to start the next chapter in their lives.\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "http://perrla.com/#/testimonials" } }, [
            _vm._v("\n\t\t\t\t\tSee more testimonials.\n\t\t\t\t"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("main", { staticClass: "org-size" }, [
        _c("h2", { staticClass: "text-center mb-3" }, [
          _vm._v("\n\t\t\t\tSome of our most popular features:\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [
              _vm._v(
                "Automatically retrieve reference data for journal articles, books, and websites"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Google Chrome Web")]),
            _vm._v(" Extension lets you create references from any website"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Reference Wizard")]),
            _vm._v(
              " guides you step-by-step to create all other reference types"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v("Create perfect citations for every type of reference"),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Automatically generates & alphabetizes your references section"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Save your references for use in future papers in the Reference Library"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Create research papers, discussion posts, reference lists, and annotated bibliographies"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Insert tables, figures, abstracts, tables of content, section headings, & appendices"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "US-based Customer Support available 7 days a week until 10pm Central"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("h3", { staticClass: "text-center" }, [
        _vm._v(
          "\n\t\t\tEvery PERRLA subscription includes PERRLA for Word & PERRLA Online!\n\t\t"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("main", [
            _c("div", { staticClass: "text-center" }, [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("h4", [_vm._v("PERRLA for Word")]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-start mb-0" }, [
              _vm._v(
                "\n\t\t\t\t\t\tPERRLA's Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.\n\t\t\t\t\t"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("main", [
            _c("div", { staticClass: "text-center" }, [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png",
                  role: "presentation",
                },
              }),
              _vm._v(" "),
              _c("h4", [_vm._v("PERRLA Online")]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-start mb-0" }, [
              _vm._v(
                "\n\t\t\t\t\t\tPERRLA Online is our web-based application that allows you to write APA & MLA papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper's research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.\n\t\t\t\t\t"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Latest APA & MLA formats")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tPERRLA is 100% compatible with APA 7, APA 6, MLA 9, and MLA 8 formatting guidelines. We monitor all APA & MLA updates to make sure PERRLA is always up-to-date!\n\t\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Google Chrome Extension")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tOur Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.\n\t\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Formats your entire paper")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tPERRLA formats your references, page margins, headings & text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don’t have to.\n\t\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Auto-fill Reference Wizard")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tUse our Auto-fill tool to search for books, journal articles, & websites without any typing. Manually create every other reference type with our guided Reference Wizard.\n\t\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("Reference Syncing")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tPERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.\n\t\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h4", [_vm._v("PERRLA is always up-to-date")]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\tUpdating software is a pain. PERRLA updates itself automatically without you having to do a thing.\n\t\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 col-sm-6 col-md-4 mx-md-auto g-start-md-5",
            },
            [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("US-based Support")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tOur Customer Support team is serious about helping students. They’re available "
                  ),
                  _c("strong", [_vm._v("7 days a week until 10pm Central")]),
                  _vm._v(".\n\t\t\t\t\t\t"),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }