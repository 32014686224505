<!--
- Reminder List > Row
-- clickable title to open a document for editing
-->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-primary', {'tr-entity-grid-row-success': isComplete}]" @mouseover="isHover = true" @mouseleave="isHover = false">
		<td class="td-entity-icon">
			<a href="#" v-show="isComplete" title="Mark Finished" @click.prevent="toggleReminderComplete">
				<span class="svg bi-check-lg" />
			</a>
			<div v-show="!isComplete">
				<a href="#" class="svg" v-show="isHover" title="Mark Unfinished" @click.prevent="toggleReminderComplete">
					<span class="svg icons8-unchecked-checkbox" />
				</a>

				<span class="svg icons8-notification" v-show="!isHover" />
			</div>
		</td>

		<td class="td-entity-title td-entity-click" @click.prevent="gotoGridElement" title="Open Reminder">
			{{ reminderName }}
		</td>

		<td class="table-spacer" />

		<td :style="calcClassColorStyle">
			{{ calcAssociationOutput }}
		</td>

		<td class="table-spacer" />

		<td width="160px">
			{{ outputDueDate }}
		</td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import ClassColorParse from '@/helpers/class-color-parse';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';

export default {
	name: 'ReminderRow',
	props:{
		reminderAssociatedEntityId:{
			type: Number|String,
		},
		reminderAssociatedEntityTypeId:{
			type: Number|String,
		},
		reminderClassId:{
			required: true,
			type: Number,
		},
		reminderDueDate:{
			required: true,
			type: String,
		},
		reminderEncryptedId:{
			required: true,
			type: String,
		},
		reminderId:{
			required: true,
			type: Number,
		},
		reminderName:{
			required: true,
			type: String,
		},
		reminderIsComplete:{
			required: true,
			type: Boolean,
		},
		
	},
	data() {
		return {
			isComplete: this.reminderIsComplete,
			isHover: false,
		}
	},
	computed:{
		// move this out to a helper once you get more entity types
		calcAssociationOutput(){
			// console.log(this.reminderAssociatedEntityTypeId);

			// if either Assoc is 0 then nothing will get output here
			if(this.reminderAssociatedEntityTypeId !== 0 && this.reminderAssociatedEntityId !== 0){
				let returnString = '';
				let findEntity;

				switch(this.reminderAssociatedEntityTypeId){
					case config.enums.Entity.ASSIGNMENT:
						returnString = 'Assignment';

						findEntity = this.$store.state.work.list.find((workData)=>{
							return workData.entityID === this.reminderAssociatedEntityId
						});

						if(findEntity && _has(findEntity, 'name')){
							returnString += ' - ' + findEntity.name;
						} else {
							return config.emptyText;
						}
						break;

					case config.enums.Entity.CLASS:
						returnString = 'Class';

						findEntity = this.$store.state.classes.list.find((paperData)=>{
							return paperData.classID === this.reminderAssociatedEntityId
						});
						// console.log('findEntity');
						// console.log(findEntity);
						if(findEntity && _has(findEntity, 'name')){
							returnString += ' - ' + findEntity.name;
						} else {
							return config.emptyText;
						}
						break;

					case config.enums.Entity.PAPER:
						returnString = 'Paper';
						
						findEntity = this.$store.state.paperList.list.find((paperData)=>{
							return paperData.paperID === this.reminderAssociatedEntityId
						});

						if(findEntity && _has(findEntity, 'title')){
							returnString += ' - ' + findEntity.title;
						} else {
							return config.emptyText;
						}
						break;

					case config.enums.Entity.TODO:
						returnString = 'To Do';

						findEntity = this.$store.state.work.list.find((workData)=>{
							return workData.entityID === this.reminderAssociatedEntityId
						});

						if(findEntity && _has(findEntity, 'name')){
							returnString += ' - ' + findEntity.name;
						} else {
							return config.emptyText;
						}
						break;

				}//e:switch

				return returnString;
			}//e:if

			return config.emptyText;
			
		},//e:calcAssociationOutput

		calcClassColorStyle(){
			if(this.reminderClassId !== 0){
				let thisClassData = this.$store.state.classes.list.find((classData)=>{
					return classData.classID === this.reminderClassId;
				});
				
				if(thisClassData){
					return ClassColorParse({
						'in': thisClassData.colorValue,
						'isOutBlock': false,
					});
				}//e:if
			}//e:if
			return null;
		},
		
		outputDueDate(){
			return dateToLongString({
				dateValue: this.reminderDueDate,
				showTime: false,
			});
		},//e:outputDueDate
	},
	methods: {
		gotoGridElement(){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReminderListSingle',
				modalParams: {
					encryptedId: this.reminderEncryptedId
				},
			});//e:openRouteModal
		},//e:gotoGridElement

		toggleReminderComplete(){
			ApiS_MakeCall({
				method: 'PUT',
				params:{
					'id': this.reminderId,
					'isComplete': !this.isComplete
				},
				url: 'v4/Reminder/MarkComplete',
			}).then(()=>{
				this.isComplete = !this.isComplete;

			});//e:ApiS_MakeCall
		},//e:toggleReminderComplete
				
	},
}
</script>

<!-- 
<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";
	
</style> -->