/*
- Generate / Assign Safe Ids
-- i don't care about the id but need it for label/input focus
-- 2022.09.22
*/

export default {
	computed: {
		// generate a safe id based on vue's generated _uid with PID (PERRLA ID)
		safeId() {
			return `_PID_${Math.floor(Math.random() * 333333)}`;
		},
	},
}
