var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-select-v2 row mb-3" }, [
    _c("div", { staticClass: "col-label" }, [
      _c("label", {
        staticClass: "form-label form-label-v2",
        attrs: { for: _vm.safeId },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-value" }, [
      _c(
        "select",
        {
          class: ["form-select"],
          attrs: { id: _vm.safeId },
          domProps: { value: _vm.value },
          on: {
            change: function ($event) {
              return _vm.selectChange($event.target.value)
            },
          },
        },
        _vm._l(_vm.calcSelectObject, function (singleObject, key) {
          return _c(
            "option",
            {
              key: key,
              attrs: { disabled: singleObject.isDisabled },
              domProps: { value: singleObject.value },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(singleObject.name) + "\n\t\t\t")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }