var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "PaperEdit" } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tEdit Details\n\t"),
      ]),
      _vm._v(" "),
      _c("form-datepicker", {
        attrs: {
          id: "PE_startDate",
          label: "Start Date",
          "is-clearable": true,
          "paper-format": 0,
        },
        on: { "date-clear": _vm.dateStartClear, "date-set": _vm.dateStartSet },
      }),
      _vm._v(" "),
      _c("form-datepicker", {
        attrs: {
          id: "PE_dueDate",
          label: "Due Date",
          "is-clearable": true,
          "paper-format": 0,
        },
        on: { "date-clear": _vm.dateDueClear, "date-set": _vm.dateDueSet },
      }),
      _vm._v(" "),
      _c("form-textarea", {
        attrs: { label: "Description", "is-autosize": true },
        model: {
          value: _vm.description,
          callback: function ($$v) {
            _vm.description = $$v
          },
          expression: "description",
        },
      }),
      _vm._v(" "),
      _c("form-select", {
        attrs: { label: "Class", "is-class-select": true },
        model: {
          value: _vm.associatedClassId,
          callback: function ($$v) {
            _vm.associatedClassId = $$v
          },
          expression: "associatedClassId",
        },
      }),
      _vm._v(" "),
      _c("form-input", {
        attrs: { label: "Length" },
        model: {
          value: _vm.requiredLength,
          callback: function ($$v) {
            _vm.requiredLength = $$v
          },
          expression: "requiredLength",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$store.commit("modals/CLOSE", "PaperEdit")
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tCancel\n\t\t\t\t")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.updatePaper.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tUpdate Paper Details\n\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }