<!--
- OutlineFootnotesRow
-- A single row for a reference in the Paper Footnotes
-->

<template>
	
	<div
		:class="['outline-footnote-single cursor-pointer', {'outline-footnote-menu-open':isMenuOpen}]"
		:citation-unique-ids-list="calcListOfUids"
		:footnote-index="footnoteIndex"
		@dblclick.prevent="btnEditFootnote"
		v-html="footnoteContent" />

</template>

<script>
import _has from 'lodash/has';
import config from '@/config';

export default {
	name: 'OutlineFootnotesRow',
	props:{
		footnoteData:{
			required: true,
			type: Object,
		},
		footnoteIndex:{
			required: true,
			type: Number,
		},
	},
	computed:{
		calcListOfUids (){
			// 
			if(
				_has(this.footnoteData, 'citationDataObject') &&
				_has(this.footnoteData.citationDataObject, 'citationData') &&
				_has(this.footnoteData.citationDataObject.citationData, 'citationUniqueID')){
				return this.footnoteData.citationDataObject.citationData.citationUniqueID;
			}
			return '';
		},
		footnoteContent(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				if(_has(this.footnoteData, 'citationDataObject') && _has(this.footnoteData.citationDataObject, 'turabian9')){
					return (this.footnoteIndex + 1) + '.&nbsp;' + this.footnoteData.citationDataObject.turabian9.first;
				}
			}

			return '';
		},

		isMenuOpen(){
			if(
				_has(this.footnoteData, 'citationDataObject') &&
				_has(this.footnoteData.citationDataObject, 'citationData') &&
				_has(this.footnoteData.citationDataObject.citationData, 'citationUniqueID') &&
				this.$store.state.paperEdit.footnotes.contextMenuOpenOnCitationFindById === this.footnoteData.citationDataObject.citationData.citationUniqueID){
				return true;
			}
			return false;
		},
	},
	methods:{
		btnEditFootnote(){
			// console.log('btnEditFootnote');
			this.$store.commit('researchNotes/SET_TEMP_CITATION_DATA', JSON.stringify(this.footnoteData.citationDataObject));
			
			if(this.$route.name === 'OutlineCitationEdit' && this.$route.params.citationUniqueId === this.footnoteData.citationDataObject.citationData.citationUniqueID){
				// don't change route - user is already 'there'

			} else {
				this.$router.push({
					name: 'OutlineCitationEdit',
					params: {
						citationUniqueId: this.footnoteData.citationDataObject.citationData.citationUniqueID,
					}
				}).catch(()=>{});
			}
		

		},//e:btnEditFootnote
	},
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/_vars_perrla.scss";

.outline-footnote-single{
	color: $body-color;
	font-family: $font-family-paper;
	font-size: 10pt;
	line-height: 2;
	position: relative;
	text-indent: $paper-indent-width;

	&:hover, &.outline-footnote-menu-open{
		background-color: $secondary-100;
	}
	&.outline-footnote-single-selected {
		border: 1px solid $primary;
	}

}
</style>