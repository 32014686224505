var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", {
          attrs: { title: "Create a reference", "is-browser-back": true },
        }),
        _vm._v(" "),
        _c("create-reference-step-2-third-party-header", {
          attrs: { "is-drawer": true },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "scrollableBody", staticClass: "drawer-body" },
      [
        _c("create-reference-step-2-third-party-body", {
          attrs: { "is-drawer": true },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }