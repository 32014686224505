<!--
- Outline Footnotes Dropdown
-- Right Click on each footnote row
-- make these look like Ck Context Menu Items
-->

<template>
	
	<div class="footnote-dropdown" v-show="isOpen" v-on-clickaway="closeMenu">
		<a class="footnote-dropdown-item" href="#" @click.prevent="showCitationInOutline">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/showinpaper.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Show in Outline
			</span>
		</a>

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnEditFootnote">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/edit.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Edit Footnote Citation
			</span>
		</a>

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnDeleteCitation">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/delete.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Delete Citation
			</span>
		</a>

	</div>
</template>

<script>
import _has from 'lodash/has';
import { mixin as clickaway } from 'vue-clickaway';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';

export default {
	name: 'OutlineFootnotesDropdown',
	mixins: [clickaway],
	computed:{
		citationElementInPaper(){
			return this.$store.state.paperEdit.citations.elementInPaper;
		},
	},
	data(){
		return {
			firstCitationUniqueId: null,
			isOpen: false,
		}
	},
	methods: {
		btnDeleteCitation(){
			this.setFirstCitationUniqueId();

			if(this.firstCitationUniqueId){
				this.$store.dispatch('paperEdit/citations/setCitationElementIfActive', {
					citationUniqueId: this.firstCitationUniqueId,
				}).then(()=>{
					// let saveMessage = '';	// resolves back so SaveDocument has a message of what was deleted

					// Single

					// remove citation from vuex store
					let findCitationIndex = this.$store.state.paperEdit.outline.citationObjects.findIndex((citationObject)=>{
						return citationObject.citationDataObject.citationData.citationUniqueID.toUpperCase() === this.firstCitationUniqueId.toUpperCase();
					});
					if(findCitationIndex !== -1){
						this.$store.commit('paperEdit/outline/REMOVE_CITATION_OBJECT_AT_INDEX', findCitationIndex);

						// remove the ck element
						if (this.citationElementInPaper.getParent()) {
							// saveMessage = this.citationElementInPaper.getHtml();

							if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
								let $elToInsert = new CKEDITOR.dom.element('sup');
								$elToInsert.setText('CITATION DELETED');
								$elToInsert.replace(this.citationElementInPaper.getParent());
							}
						
						} else {
							console.log('There was a problem removing the citation from this paper');
						}
						this.$nextTick(()=>{
							PS_BuildReferencesInPaper().then(()=>{
								OS_SaveContent().then(()=>{
									this.emitter.emit('setOutlineEditorHeight');
									this.closeMenu();
								});
							});//e:PS_BuildReferencesInPaper
						});//e:nextTick

					}//e:if

				});//e:dispatch:paperEdit/citations/setCitationElementIfActive

			} else {
				this.closeMenu();

			}
		},//e:btnDeleteCitation

		btnEditFootnote(){
			// console.log('btnEditFootnote');

			this.setFirstCitationUniqueId();

			let $findThisCitationObject = this.$store.state.paperEdit.outline.citationObjects.find((citationObject)=>{
				return citationObject.citationDataObject.citationData.citationUniqueID === this.firstCitationUniqueId;
			});
			if($findThisCitationObject && _has($findThisCitationObject, 'citationElement')){
				let citationData = $findThisCitationObject.citationElement.$.getAttribute('citation-data') || '';
				
				this.$store.commit('researchNotes/SET_TEMP_CITATION_DATA', window.atob(citationData));

				if(this.firstCitationUniqueId){
					this.$router.push({
						name: 'OutlineCitationEdit',
						params: {
							citationUniqueId: this.firstCitationUniqueId,
						}
					}).catch(()=>{});

					this.$nextTick(()=>{
						this.closeMenu();
					});

				} else {
					this.closeMenu();

				}

			} else {
				this.closeMenu();

			}
		},//e:btnEditFootnote

		closeMenu(){
			this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_OPEN_ON_CITATION_FIND_BY_ID', '');
			this.isOpen = false;
		},//e:closeMenu

		positionMenu(citationUniqueIdsList){
			let $footnoteRow = document.querySelector('div[citation-unique-ids-list="' + citationUniqueIdsList + '"]');

			if($footnoteRow){
				this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_OPEN_ON_CITATION_FIND_BY_ID', citationUniqueIdsList);
			
				let rectWrp = $footnoteRow.getBoundingClientRect();
				let rectDropdown = this.$el.getBoundingClientRect();

				// renders off the screen in the outline - need to calc based on the height of the dropdown menu
				this.$el.style.top = (rectWrp.bottom - rectDropdown.height) + 'px';	
				this.$el.style.left = (rectWrp.right - 40) + 'px';
			}
		},

		setFirstCitationUniqueId(){
			let $outlineFootnotesBlock = document.getElementById('outlineFootnotesBlock');

			if($outlineFootnotesBlock){
				let $openFootnoteElement = $outlineFootnotesBlock.querySelector('div.outline-footnote-menu-open');

				if($openFootnoteElement){
					let citationUniqueIdsList = $openFootnoteElement.getAttribute('citation-unique-ids-list');
					this.firstCitationUniqueId = citationUniqueIdsList.split(',')[0];
				}
			}

		},//e:setFirstCitationUniqueId

		showCitationInOutline(){
			this.setFirstCitationUniqueId();
			
			if(this.firstCitationUniqueId){
				this.$store.dispatch('paperEdit/citations/setCitationElementIfActive', {
					citationUniqueId: this.firstCitationUniqueId,
				}).then(()=>{
					let $thisEditor = this.citationElementInPaper.getEditor(false);

					if($thisEditor){
						this.closeMenu();
						this.citationElementInPaper.$.scrollIntoView();
						$thisEditor.getSelection().selectElement(this.citationElementInPaper)
					}
				});
			} else {
				this.closeMenu();
			}//e:if.this.firstCitationUniqueId
		},//e:showCitationInOutline
	},
	created(){
		
		this.emitter.on('closeAllFootnoteContextMenus', ()=>{
			this.closeMenu();
		});

		this.emitter.on('showOutlineFootnoteContextMenu', ($opts)=>{
			// console.log('showOutlineFootnoteContextMenu emitter');
			
			let $outlineFootnotesBlock = document.getElementById('outlineFootnotesBlock');

			if($outlineFootnotesBlock){
				let $openFootnoteElement = $outlineFootnotesBlock.querySelector('div.outline-footnote-menu-open');
				
				if($openFootnoteElement){
					// one open already - either move it (already open on different row) or close it (already open on this row)

					// is this menu open on the same row or a different one?
					if($opts.citationUniqueIdsList === this.$store.state.paperEdit.footnotes.contextMenuOpenOnCitationFindById){
						// same row click - close the menu
						this.closeMenu();
					} else {
						// different row click - just move it to the right new place
						this.positionMenu($opts.citationUniqueIdsList);
					}

				} else {
					// not one open 
					this.isOpen = true;
					this.$nextTick(()=>{
						this.positionMenu($opts.citationUniqueIdsList);
					});

				}//e:if:openFootnoteElement

			}//e:if:$outlineFootnotesBlock
		
		});//e:showOutlineFootnoteContextMenu

	},
	destroyed(){
		this.emitter.off('closeAllFootnoteContextMenus')
		this.emitter.off('showOutlineFootnoteContextMenu');
	}
}
</script>


<style lang="scss" scoped>
@import "../../../../assets/styles/_vars_perrla.scss";

$row-height: 25px;	// height of a single row inside the dropdown

.footnote-dropdown{
	border: 1px solid #e9e9e9;
	width: 165px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;

	.footnote-dropdown-item {
		background: #fff;
		color: $body-color;
		display: block;
		overflow: hidden;
		text-decoration: none;
		
		.footnote-dropdown-item-color-block {
			background: #f8f8f8;
			display: block;
			float:left;
			height: $row-height;
			margin-right: 5px;
			width: $row-height;

			img {
				margin-left: 5px;	// seems like (row-height - 16px) / 2
				height: 16px;
				width: 16px;
			}
		}

		.footnote-dropdown-item-text{
			display: block;
			font-size: 12px;
			font-family: Arial, Helvetica, Tahoma, Verdana, Sans-Serif;
			line-height: $row-height;
			text-indent: 0;
		}

		&:hover {
			.footnote-dropdown-item-color-block, .footnote-dropdown-item-text{
				background: #e9e9e9;
			}
		}

	}//.footnote-dropdown-item

	.footnote-dropdown-separator {
		background-color: #d1d1d1;
		display: block;
		height: 1px
	}
	


}//.footnote-dropdown


</style>