<!--
- Create Reference > Step 2 Third Party > Body
-- Filtering at the top via dropdown
-- Then rows of results
-->

<template>
	<div>
		<duplicate-reference-confirm v-if="$store.state.referenceDuplicate.isShowWarning" />

		<div v-if="thirdPartyReferenceResults && thirdPartyReferenceResults.length > 0">
			<div class="dropdown">
				<a href="#" class="filter-results-trigger" data-bs-toggle="dropdown" role="button" aria-expanded="false" id="dropdownMenu3plu">
					<span class="dropdown-toggle">Showing {{ filteredByText }}</span>
				</a>

				<ul class="dropdown-menu" aria-labelledby="dropdownMenu3plu">
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="filterResults()">
							All results
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="filterResults('book')">
							Book &amp; Chapters only
						</a>
					</li>
					<li>
						<a class="dropdown-item hover hover-primary" href="#" @click.prevent="filterResults('journal')">
							Journal Articles only
						</a>
					</li>
				</ul>
			</div>

			<third-party-result-row
				v-for="(ref, index) in filteredThirdPartyResults"
				:is-drawer="isDrawer"
				:key="index"
				:third-party-reference="ref" />
		</div>
	</div>
</template>

<script>
import DuplicateReferenceConfirm from '@/modules/DuplicateReferenceConfirm';
import ThirdPartyResultRow from '@/modules/CreateReferenceStep2ThirdParty/ResultRow';

export default {
	name: 'CreateReferenceStep2ThirdPartyBody',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	computed:{
		thirdPartyReferenceResults(){
			return this.$store.state.thirdPartyLookup.referenceResults;
		}
	},
	data() {
		return {
			filteredThirdPartyResults: [],
			filteredByText: '',
			showManualCreateButton: false,
		}
	},
	methods: {
		filterResults(referenceTypeToShow){
			if(!referenceTypeToShow){
				// none passed - so reset to all
				// console.log('Reset to all');
				this.filteredByText = 'all results';
				this.filteredThirdPartyResults = this.thirdPartyReferenceResults;
			} else if(referenceTypeToShow === 'book'){
				// console.log('Filter Book');
				this.filteredByText = 'Books & Chapters';
				this.filteredThirdPartyResults = this.thirdPartyReferenceResults.filter((result)=>{
					return result.id == 2;	// book is reference id 2 from types.json
				});
			} else if(referenceTypeToShow === 'journal'){
				// console.log('Filter Journal');
				this.filteredByText = 'Journal Articles';
				this.filteredThirdPartyResults = this.thirdPartyReferenceResults.filter((result)=>{
					return result.id == 1;	// journal is reference id 1 from types.json
				});
			}
		},//e:filterResults
	},
	created(){
		// emits when user searches a second time (to keep these results showing all after a search)
		this.emitter.on('thirdPartySearchComplete', ()=>{
			this.filterResults();
		});
	},
	mounted(){
		this.$nextTick(()=>{
			this.filterResults();
		});
	},
	components:{
		DuplicateReferenceConfirm,
		ThirdPartyResultRow,
	},
	destroyed(){
		this.emitter.off('thirdPartySearchComplete');
	}
}
</script>

<style lang="scss" scoped>
	@import '../../assets/styles/vars_perrla.scss';
	@include color-mode(dark) {
		a.filter-results-trigger{
			color: $dark-body-color;
		}
	}

	a.filter-results-trigger{
		font-size: $font-size-base;
		color:$body-color;
		display: block;
		text-decoration: none;
	}
</style>