var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alert alert-info mb-4" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-clipboard btn-sm btn-primary float-end",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.copyCitationPreview.apply(null, arguments)
          },
        },
      },
      [_vm._v("\n\t\tCopy\n\t")]
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Live Preview")]),
    _vm._v(" "),
    _c("p", [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.citationOutput) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }