<!--
- Overview Tab > Details
-
-->

<template>
	<div class="d-grid g-details">
		<h2>Details</h2>	
		<div class="field-label" v-if="StartDate">
			Start Date
		</div>
		<div class="field-value" v-if="StartDate">
			{{ StartDate }}
		</div>

		<div class="field-label" v-if="DueDate">
			Due Date
		</div>
		<div class="field-value" v-if="DueDate">
			{{ DueDate }}
		</div>
		
		<div class="field-label" v-if="RequiredLength">
			Length
		</div>
		<div class="field-value" v-if="RequiredLength">
			{{ RequiredLength }}
		</div>

		<div class="field-label" v-if="$store.state.paperEdit.openObject.classID != 0">
			Class
		</div>
		<div class="field-value" v-if="$store.state.paperEdit.openObject.classID != 0">
			{{ $store.state.paperEdit.openObject.className }}
		</div>
	
		<div class="field-label" v-if="truncatedDescription">
			Description
		</div>
		<div class="field-value" v-if="truncatedDescription" :title="Description">
			{{ truncatedDescription }}
		</div>

		<div class="g-row">
			<div class="g-col">
				<button type="button" class="btn btn-outline-primary me-2" @click.prevent="openModalPaperEdit">
					Edit Paper Details
				</button>
			</div>
			<div class="g-col">
				<button v-if="IsComplete" type="button" class="btn btn-outline-success" @click.prevent="togglePaperComplete" key="btnMarkIncomplete">
					Mark Unfinished
				</button>
				<button v-else type="button" class="btn btn-success" @click.prevent="togglePaperComplete" key="btnMarkComplete">
					Mark Paper Finished
				</button>
			</div>
		</div>
		<modal-paper-edit />
		
	</div><!--g-details-->
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import dateToLongString from '@/helpers/date-to-long-string';
import PS_SaveDocument from '@/services/paper/saveDocument';
import ModalPaperEdit from './modals/PaperEdit';

export default {
	name: 'PaperDetails',
	computed:{
		CourseName(){
			return this.$store.state.paperEdit.meta.CourseName;
		},
		Description(){
			return this.$store.state.paperEdit.meta.Description;
		},
		IsComplete(){
			return this.$store.state.paperEdit.meta.IsComplete;
		},
		RequiredLength(){
			return this.$store.state.paperEdit.meta.RequiredLength;
		},
		StartDate(){
			if(this.$store.state.paperEdit.meta.StartDate != ''){
				return dateToLongString({
					dateValue: this.$store.state.paperEdit.meta.StartDate,
					forceYear: true,
					showTime: false,
				});
			}
			return '';
		},
		DueDate(){
			// comes in as the default for some reason, so just check for that value to skip
			if(this.$store.state.paperEdit.meta.DueDate != '' && this.$store.state.paperEdit.meta.DueDate !== '01/01/2099'){
				return dateToLongString({
					dateValue: this.$store.state.paperEdit.meta.DueDate,
					forceYear: true,
					showTime: false,
				});
			}
			
			return '';
		},
		truncatedDescription(){
			if(this.$store.state.paperEdit.meta.Description.length <= 100){
				return this.$store.state.paperEdit.meta.Description;
			}
			return this.$store.state.paperEdit.meta.Description.slice(0, 100) + '...';
		},
	},
	methods:{
		openModalPaperEdit(){
			this.$store.commit('modals/OPEN', 'PaperEdit');
		},

		togglePaperComplete(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let _isComplete = !this.IsComplete;

			ApiS_MakeCall({
				method: 'GET',
				params:{
					'isComplete': _isComplete,
					'paperId': this.$store.state.paperEdit.meta.PaperID
				},
				url: 'v3/MarkPaperComplete',
			}).then(()=>{
				this.$store.commit('paperEdit/meta/SET_IsComplete', _isComplete);

				PS_SaveDocument({
					message: (_isComplete) ? 'Marked paper complete' : 'Marked paper incomplete',
					updateMetaTags:[
						{'IsComplete': _isComplete},
					]
				});
			});//e:ApiS_MakeCall
		},
	},
	components:{
		ModalPaperEdit,
	}
}
</script>

<style lang="scss" scoped>
	@import "../../../assets/styles/_vars_perrla.scss";
	.g-details {
		grid-column: 1 / span 2;
		grid-column-gap: 1rem;
		grid-row: 2;
		grid-row-gap: 1rem;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		align-self: start;
		margin-bottom: 2rem;

		h2{
			grid-column-start: span 3;
			grid-row: 1;
		}

		.field-label, .field-value {
			display: grid;
			font-size: 1rem;
			line-height: 1.2rem;
			margin-bottom: 0.25rem;
		}

		.field-label {
			grid-column: 1;
			font-weight: $font-weight-normal;
		}
		.field-value {
			grid-column: 2 / span 2;
			font-weight: $font-weight-light;
		}

		.g-row{
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1rem;
			grid-column: 1 / span 3;
			justify-self: stretch;
			
			.g-col {
				display: grid;
				// grid-column: 1;

			}
		}
	}//.g-details 
</style>