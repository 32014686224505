/*
- Footnotes
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.footnotes.x
*/

import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _uniqBy from 'lodash/uniqBy';

export default {
	namespaced: true,
	
	state:{
		contextMenuOpenOnCitationFindById: '',	  	// string of what citation-unique-ids-list property will be on each Footnote/Row
		contextMenuSelectedOnFindById: '',	// string of what citation-unique-ids-list property will be on each Footnote/Row

		inPaper: [		// Array: each entry is an object for Footnote Data 
			// {
			// 	content: '<p>BEFORE@@@AFTER</p>',	// @@@ is where the citation will appear, with content before and after it the user can enter
			//  findById: '',						// this could be a single citation UID or a group citation UID
			// }
		],
		
		

	},//e:state

	mutations: {
		ADD_TO_PAPER(state, insertValue){
			state.inPaper.push(insertValue);
		},
		CLEAN_UNIQUE(state){
			state.inPaper = _uniqBy(state.inPaper, 'findById');
		},
		REMOVE_AT_CITATION_UID(state, citationUniqueId){
			let findFootnoteIndex = state.inPaper.findIndex((footnoteData)=>{
				return footnoteData.findById === citationUniqueId
			});
			if(findFootnoteIndex > -1){
				state.inPaper.splice(findFootnoteIndex, 1);
			}
		},

		SET_CONTEXT_MENU_OPEN_ON_CITATION_FIND_BY_ID(state, value){
			state.contextMenuOpenOnCitationFindById = value;
		},
		SET_CONTEXT_MENU_SELECT_ON_CITATION_FIND_BY_ID(state, value){
			state.contextMenuSelectedOnFindById = value;
		},

		// used to check in the full list of footnotes in buildHtml
		SET_FULL_IN_PAPER(state, arrayOfNewFootnotes){
			state.inPaper = arrayOfNewFootnotes;
		},//e:SET_FULL_IN_PAPER

		UPDATE_CONTENT_AT_INDEX(state, $opts){
			if(state.inPaper[$opts.footnoteIndex]){
				state.inPaper[$opts.footnoteIndex].content = $opts.newContent;
			}
		},
		
		
	},//e:mutations
	
	actions:{
		addToPaper({commit}, $opts) {
			return new Promise((resolve) => {
				// console.log('action addToPaper');
				// console.log($opts);
				
				// i'm adding a footnote object i will have $opts:
				// content
				// findById
				let footnoteObject = {
					content: (_has($opts, 'content')) ? $opts.content : '<p>@@@</p>',
					findById: $opts.findById,
					isOpen: false,
					isSelected: false,
				};

				commit('ADD_TO_PAPER', footnoteObject);

				resolve();
			});//e:Promise
		},//e:addToPaper
		
		updateContentAtCitationUid({commit, state}, $opts) {
			return new Promise((resolve) => {
				// console.log('updateContentAtCitationUid');
				
				// find out which footnote index i'm updating
				let findFootnoteIndex = state.inPaper.findIndex((footnoteData) =>{
					return footnoteData.findById === $opts.findById
				});
				
				// console.log('findFootnoteIndex');
				// console.log(findFootnoteIndex);

				if(findFootnoteIndex > -1){
					// let x = `<p>1.&nbsp;20 enemy's forebearers confusticate room`;
					var re = new RegExp((findFootnoteIndex + 1).toString() + '.', "g");

					let footnoteContentToSave = $opts.newContent.replace(re, "");
					footnoteContentToSave = footnoteContentToSave.replace(/&nbsp;/g, "");
				
					commit('UPDATE_CONTENT_AT_INDEX', {
						footnoteIndex: findFootnoteIndex,
						newContent: footnoteContentToSave,
					});
				}

				resolve();
			});//e:Promise
		},//e:addToPaper
	},//e:actions
}