var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "org-size modal-top-title" }, [
        !_vm.isDrawer
          ? _c(
              "a",
              {
                staticClass: "link-icon",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$router.go(-1)
                  },
                },
              },
              [
                _c("span", {
                  staticClass: "svg bi-chevron-left",
                  attrs: { title: "Back" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v("\n\t\tStep 2 of 3: Select a reference to use\n\t"),
      ]),
      _vm._v(" "),
      _c("reference-search-third-party", {
        attrs: { "is-drawer": _vm.isDrawer, "is-first-search": false },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "route-fade" } }, [
        _vm.showManualCreateButton
          ? _c("div", [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary mt-1",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\tCan't find it? Create a reference manually...\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-2 mb-0" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }