import config from '@/config';
import PaperTabEditor from '@/views/Paper/TabEditor';

import EditorAddFromLibrary from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/AddFromLibrary';
import EditorAbstract from '@/views/Paper/TabEditor/ToolDrawer/Abstract';
import EditorAppendices from '@/views/Paper/TabEditor/ToolDrawer/Appendices';
import EditorCitationAdd from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CitationAdd';
import EditorCitationEdit from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CitationEdit';
import EditorConvertReferenceStep1 from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/ConvertStep1';
import EditorConvertReferenceStep2 from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/ConvertStep2';
import EditorCreateCleanCopy from '@/views/Paper/TabEditor/ToolDrawer/CreateCleanCopy';
import EditorCreateReferenceStep1 from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep1';
import EditorCreateReferenceStep2 from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep2';
import EditorCreateReferenceStep3 from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep3';
import EditorCreateStep2ThirdParty from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep2ThirdParty';
import EditorCreateStep3ThirdParty from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep3ThirdParty';
import EditorCreateStep4ThirdParty from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/CreateStep4ThirdParty';
import EditorEditReference from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/EditReference';
import EditorInsertFigure from '@/views/Paper/TabEditor/ToolDrawer/InsertFigure';
import EditorInsertTable from '@/views/Paper/TabEditor/ToolDrawer/InsertTable';
import EditorReferencesCitations from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations';
import EditorResearchNotes from '@/views/Paper/TabEditor/ToolDrawer/ResearchNotes';
// import EditorLastOpenDrawerRedirect from '@/views/Paper/TabEditor/ToolDrawer/LastOpenDrawerRedirect'
import EditorOutline from '@/views/Paper/TabEditor/ToolDrawer/Outline';
import EditorPaperReview from '@/views/Paper/TabEditor/ToolDrawer/PaperReview';
import EditorReviewReference from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/ReviewReference';
import EditorTableOfContents from '@/views/Paper/TabEditor/ToolDrawer/TableOfContents';
import EditorTitlePage from '@/views/Paper/TabEditor/ToolDrawer/TitlePage';

const moduleEnum = config.enums.Module.PAPER_EDIT;
const tabEnum = config.enums.Tab.EDITOR;

export default [
	{
		path: 'editor',
		component: PaperTabEditor,
		name: 'PaperTabEditor',
		meta: {
			layout: config.enums.Layout.PO,
			module: moduleEnum,
			tab: tabEnum
		},
		children: [
			{
				name: 'EditorAbstract',
				component: EditorAbstract,
				path: 'abstract',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorAppendices',
				component: EditorAppendices,
				path: 'appendices',
				meta: {
					hideZendesk: false,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorAddFromLibrary',
				component: EditorAddFromLibrary,
				path: 'reference-add-from-library',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCitationAdd',
				component: EditorCitationAdd,
				path: 'citation-add/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCitationEdit',
				component: EditorCitationEdit,
				path: 'citation-edit/:citationUid/:openCitationTab',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorConvertReferenceStep1',
				component: EditorConvertReferenceStep1,
				path: 'convert-reference-1',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorConvertReferenceStep2',
				component: EditorConvertReferenceStep2,
				path: 'convert-reference-2',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			
			{
				name: 'EditorCreateCleanCopy',
				component: EditorCreateCleanCopy,
				path: 'create-clean-copy',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateReferenceStep1',
				component: EditorCreateReferenceStep1,
				path: 'create-reference-1/:openReferenceTab',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateReferenceStep2',
				component: EditorCreateReferenceStep2,
				path: 'create-reference-2/:openReferenceTab/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateReferenceStep3',
				component: EditorCreateReferenceStep3,
				path: 'create-reference-3/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateStep2ThirdParty',
				component: EditorCreateStep2ThirdParty,
				path: 'create-reference-2-3plu',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateStep3ThirdParty',
				component: EditorCreateStep3ThirdParty,						
				path: 'create-reference-3-3plu/:referenceTypeSlug',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorCreateStep4ThirdParty',
				component: EditorCreateStep4ThirdParty,
				path: 'create-reference-4-3plu',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorEditReference',
				component: EditorEditReference,
				path: 'reference-edit/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorInsertFigure',
				component: EditorInsertFigure,
				path: 'insert-figure',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorInsertTable',
				component: EditorInsertTable,
				path: 'insert-table',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorReferencesCitations',
				component: EditorReferencesCitations,
				path: 'references-citations',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorReviewReference',
				component: EditorReviewReference,
				path: 'reference-review-genesis/:referenceUid',
				props: true,
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorOutline',
				component: EditorOutline,						
				path: 'outline',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorPaperReview',
				component: EditorPaperReview,
				path: 'paper-review',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorResearchNotes',
				component: EditorResearchNotes,
				path: 'research',
				meta: {
					hideZendesk: true,
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorTableOfContents',
				component: EditorTableOfContents,
				path: 'table-of-contents',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
			{
				name: 'EditorTitlePage',
				component: EditorTitlePage,
				path: 'title-page',
				meta: {
					layout: config.enums.Layout.PO,
					module: moduleEnum,
					tab: tabEnum
				},
			},
		],
	},
]