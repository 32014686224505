<!--
- Create Step 1 > Body
-- Two Tabs for ALL and COMMON
-->

<template>
	<div class="ref-select-type-wrp my-2">
		<div v-if="$route.params.openReferenceTab === 'common'" key="tabContentCommon">
			
			<duplicate-reference-confirm v-if="$store.state.referenceDuplicate.isShowWarning" />
			
			<div v-else key="divNoDuplicateReferenceWarning">
				<h3>Manually create a reference</h3>
				<reference-type-select-row
					v-for="referenceType in refTypesByCommon"
					:is-drawer="isDrawer"
					:key="referenceType.id"
					:reference-type-description="referenceType.desc"
					:reference-type-formats="referenceType.formats"
					:reference-type-slug="referenceType.slug"
					:reference-type-title="referenceType.title" />
			</div><!--divNoDuplicateReferenceWarning-->
		</div><!--common-->

		<div v-else-if="$route.params.openReferenceTab === 'all'" key="tabContentAll">
			<!-- SHOW ALL -->
			<div v-show="referenceTypeSearchTerm === ''">
				<div v-for="(referenceTypes,refTypeCategory) in refTypesByCategory" :key="referenceTypes.id" class="ref-select-type-wrp">
					<h3>
						{{ refTypeCategory }}
					</h3>
					<div class="my-2">
						<reference-type-select-row
							v-for="referenceType in referenceTypes"
							:is-drawer="isDrawer"
							:key="referenceType.id"
							:reference-type-description="referenceType.desc"
							:reference-type-formats="referenceType.formats"
							:reference-type-slug="referenceType.slug"
							:reference-type-title="referenceType.title" />
					</div>
				</div>
			</div>

			<!-- SHOW SEARCH RESULTS -->
			<div v-show="referenceTypeSearchTerm !== ''">
				<reference-type-select-row
					v-for="referenceType in filteredReferenceTypes"
					:is-drawer="isDrawer"
					:key="referenceType.id"
					:reference-type-description="referenceType.desc"
					:reference-type-formats="referenceType.formats"
					:reference-type-slug="referenceType.slug"
					:reference-type-title="referenceType.title" />
			</div>
		</div><!--all-->

		<div v-if="$route.params.openReferenceTab === 'recent'" key="tabContentRecent">
			<h3>Recently created references</h3>
			<reference-type-select-row
				select-row v-for="referenceType in refTypesByRecent"
				:is-drawer="isDrawer"
				:key="referenceType.id"
				:reference-type-description="referenceType.desc"
				:reference-type-formats="referenceType.formats"
				:reference-type-slug="referenceType.slug"
				:reference-type-title="referenceType.title" />
		</div><!--recent-->

	</div><!--ref-select-type-wrp-->
</template>

<script>
import _has from 'lodash/has';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import DuplicateReferenceConfirm from '@/modules/DuplicateReferenceConfirm';
import ReferenceTypesData from '@/services/reference/types';
import ReferenceTypeSelectRow from '@/modules/CreateReferenceStep1/ReferenceTypeSelectRow';

export default {
	name: 'CreateReferenceStep1Body',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			refTypesByCategory:	{},	// object built with Categories: displayed only in All when not searching
			refTypesByCommon: [], 	// array of most common ref types: displayed only in Most Common
		}
	},
	computed:{
		filteredReferenceTypes(){
			// split into an array and filter out blank entries (from clean up)
			let _searchTermArray = this.referenceTypeSearchTerm.split(' ').filter((el)=>{
				return el != '';
			});

			// create two arrays to simulate weighted results
			let matchDisplayTitle = [];
			let matchMetaData = [];

			this.refTypes.forEach((refType)=>{
				if(refType.title.toLowerCase().includes(this.referenceTypeSearchTerm)){
					//match title
					matchDisplayTitle.push(refType);

				} else {
					// match metadata
					let _lookupWordsArray = refType.metadata.trim().split(' ');
					_searchTermArray.forEach((searchTerm)=>{
						if(searchTerm){
							_lookupWordsArray.forEach((lookupTerm)=>{
								if(lookupTerm.toLowerCase().includes(searchTerm.toLowerCase())){
									matchMetaData.push(refType);
								}
							});
						}
					});//e:forEach

				}//e:if:else
			});//e:forEach

			// combine match arrays
			let returnSearchResults = matchDisplayTitle.concat(matchMetaData);

			// remove duplicates & return
			return _uniqBy(returnSearchResults, 'id');
		},
		referenceTypeSearchTerm(){
			return this.$store.state.referenceTypeSearchTerm;
		},
		refTypes(){
			return ReferenceTypesData.refTypes;
		},
		refTypesByRecent(){
			let returnObject = [];

			this.refTypes.forEach((refType)=>{
				if(this.$store.state.references.recentTypeIds.includes(refType.id)){
					returnObject.push(refType);
				}
			});
			
			return returnObject;
		},
	},
	watch:{
		$route (to){
			this.loadActiveTab(to);
		}
	},
	methods: {
		// for when the user is adding a Turabian9 Reference, there isn't a turabian9 citation value (an older ref before Turabian9 was in the engine), and publishers are missing information
		// ideally this should take them to the Edit Reference type screen where they just update (which calls the Engine again)
		btnReviewReference(){
			let thisReferenceFind = this.$store.state.referenceLibraryGenesis.find((ref) => {
				return ref.referenceUniqueID.toUpperCase() == this.$store.state.referenceDuplicate.referenceObject.referenceUniqueID.toUpperCase()
			});

			if(thisReferenceFind){
				this.$store.commit('paperEdit/SET_EDIT_REFERENCE', thisReferenceFind);

				if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
					this.$router.push({
						name:'EditorReviewReference',
						params: {
							referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase()
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
					this.$router.push({
						name:'OutlineReviewReference',
						params: {
							referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase()
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
					// console.log('from research notes tab')
					this.$store.commit('modals/CLOSE', 'ResearchNotesAddReferenceFromLibrary');

					this.emitter.emit('openRouteModal', {
						modalRoute: 'PaperTabResearchReviewReference',
						modalParams: {
							referenceUid: thisReferenceFind.referenceUniqueID.toUpperCase(),
						},
					});
				}
			}
		},//e:btnReviewReference

		buildMostCommonRefTypes(){
			// loop through all reference types
			this.refTypes.forEach((refType)=>{
				if(_has(refType, 'isCommon') && refType.isCommon){
					// this is a common reference 
					this.refTypesByCommon.push(refType);
				}//e:_has
			});//e:forEach
		},//e:buildMostCommonRefTypes

		// reloads data based on $route.params.openReferenceTab
		loadActiveTab(routeObject){
			if(routeObject.params.openReferenceTab === 'common'){
				if(this.refTypesByCommon.length === 0){
					this.buildMostCommonRefTypes();
				}

			} else if(routeObject.params.openReferenceTab === 'all'){
				this.refTypesByCategory = {};

				// loop through all reference types
				this.refTypes.forEach((refType)=>{
			
					// add this refType under the right category
					if(!_has(this.refTypesByCategory, refType.category)){
						this.refTypesByCategory[refType.category] = [];
					}
					if(!_has(this.refTypesByCategory[refType.category], refType)){
						this.refTypesByCategory[refType.category].push(refType);
					}

				});//e:forEach
			}
		},//e:loadActiveTab
	},
	mounted() {
		// clear store items like you're starting over from this page
		this.$store.dispatch('referenceRules/deepClear').then(()=>{
			this.$store.commit('referenceDuplicate/HIDE_WARNING');
			this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');
			this.loadActiveTab(this.$route);
		});
	},
	components: {
		DuplicateReferenceConfirm,
		ReferenceTypeSelectRow,
	},
}
</script>
