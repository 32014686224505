<!--
- Form Label and Input
- Special Case for Running Head APA7
-->
<template>
	<div class="form-group">
		<label :class="[{'fst-italic':isInvalid}]" :for="safeId">
			Running Head
			<span class="label-action">
				<a href="#" class="hover-underline" @click.prevent="resetRunningHeadLink" v-show="value.length > 0 && !isRunningHeadLinked">Reset text</a>
			</span>
		</label>
		<div v-if="isRemoveable" class="d-grid">
			<button type="button" class="btn btn-hover btn-hover-danger" @click.prevent="$emit('running-head-toggle')">
				<span class="svg bi-x-lg" /> Remove running head
			</button>
		</div>

		<input
			autocomplete="off"
			placeholder="(required)"
			type="text"
			:class="['form-control', {'is-invalid':isInvalid}]"
			:id="safeId"
			:value="value"
			@blur="blurInput($event.target.value)"
			@input="formInput($event.target.value)" /> 
				
		<div class="invalid-feedback" v-if="isInvalid">
			{{ invalidErrorMessage }}
		</div>
	</div>
</template>

<script>
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputRunningHead',
	mixins:[mixinId],
	props:{
		formId: {
			type: String,
		},
		isLoadFocus: {
			default: false,
			type: Boolean
		},
		isRemoveable: {
			default: false,
			type: Boolean
		},
		value: String,
	},
	data(){
		return {
			invalidErrorMessage: '',
			isInvalid: false,
			isRunningHeadLinked: true,	// defaults to true, if user types in Running Head make this false
		}
	},
	methods:{
		blurInput(value){
			this.isInvalid = false;

			if(value == '') {
				// 1 - Validate Required on Blur
				this.isInvalid = true;
				this.invalidErrorMessage = 'Please enter Running Head';
			} else {
				// 2 - Check for 50 Character Length if running head is no longer linked
				if(!this.isRunningHeadLinked) {
					// get character count
					if(this.value.length >= 50){
						this.isInvalid = true;
						this.invalidErrorMessage = 'APA 7 suggests no more than 50 characters. Currently: ' + this.value.length + ' characters';
					}
				}
			}
			
			// If everything passed, then emit the blur event up to the parent component
			if(!this.isInvalid) {
				this.$emit('blur-input', value);
			}
		},//e:blurInput
		
		formInput(value){
			this.$emit('input', value);
			this.isRunningHeadLinked = false;
			
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.emitter.emit('setRunningHeadLink', false); // emits up to the parent CreatePaperApa7
			
			// Check for 50 Character Length if running head is no longer linked - the nextTick is value.length will be correct
			this.$nextTick(()=>{
				if(!this.isRunningHeadLinked) {
					// get character count
					if(this.value.length > 50){
						this.isInvalid = true;
						this.invalidErrorMessage = 'APA 7 suggests no more than 50 characters. Currently: ' + this.value.length + ' characters';
					} else {
						this.isInvalid = false;
					}
				}
			});
		},

		resetRunningHeadLink(){
			this.isRunningHeadLinked = true;
			this.emitter.emit('setRunningHeadLink', true); // emits up to the parent CreatePaperApa7
		},
	},
	mounted(){
		if(this.isLoadFocus){
			this.$refs.focusElement.focus();
		}
	},
}
</script>
