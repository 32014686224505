<!--
- Outline Editor > Tool Drawer > Create Step 3 Third Party Lookup
-->

<template>
	<div class="drawer">
		<close-warning
			close-id="CloseOutlineCreateStep3ThirdParty"
			confirm-title="Stop creating this reference?"
			confirm-body="If you stop now, the information you've entered on this page will be lost.  To finish making the reference, click the green button at the bottom."
			confirm-go-next-text="Don't create reference" />

		<div class="drawer-header">
			<tool-drawer-title :title="referenceTypeName" :is-browser-back="true" />
		</div>
		
		<div class="drawer-body">
			<create-reference-step-3-third-party-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import CloseWarning from '@/views/Paper/TabEditor/ToolDrawer/CloseWarning';
import CreateReferenceStep3ThirdPartyBody from '@/modules/CreateReferenceStep3ThirdPartyBody'
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'OutlineCreateStep3ThirdParty',
	computed: {
		referenceTypeName(){
			// commit from Step1 Row when selecting type
			return this.$store.state.referenceRules.referenceTypeName;
		},
	},
	beforeRouteLeave(to, from, next) {
		// check if the reference wizard is dirty
		let findReferenceWizardForm = this.$store.state.forms.list.find((formData)=>{
			return formData.formId === 'formReferenceWizard';
		});
		if(findReferenceWizardForm && to.name !== 'OutlineCreateStep4ThirdParty'){
			// a reference wizard form exisit, lets check it
			if(findReferenceWizardForm.isDirty){
				this.emitter.emit('showCloseId', {
					closeId: 'CloseOutlineCreateStep3ThirdParty',
					nextTo: next,
				});
			} else {
				next();	
			}
		} else {
			next();
		}
	},
	components: {
		CloseWarning,
		CreateReferenceStep3ThirdPartyBody,
		ToolDrawerTitle
	},
}
</script>
