var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Appendix" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c("p", [
        _vm._v(
          "Appendices are used to add supplementary material to your paper and are always added at the end of your paper."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Appendix Titles should describe the content contained in the appendix and should use title case capitalization (capitalizing all major words)."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-success",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnAddAppendix.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tAdd an Appendix\n\t\t\t")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-empty mt-5" }, [
      _c("p", [
        _vm._v(
          "Appendices are labeled based on the number of appendices in the paper. A single appendix is labeled “Appendix,” while multiple appendices are labeled “Appendix A,” “Appendix B,” etc. PERRLA handles the appendix labels for you."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "If you delete appendices or refresh their labels, be sure any appendix callouts in the body of your paper match the proper appendix."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("You can learn more about Appendices in "),
        _c(
          "a",
          {
            attrs: {
              href: "https://perrla.zendesk.com/hc/en-us/articles/360004129633-Adding-an-Appendix-to-your-Paper-Online",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v("PERRLA's Help Center")]
        ),
        _vm._v("."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }