var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showReferencePage
    ? _c(
        "div",
        {
          class: _vm.calcInstanceStyles,
          attrs: { id: "instanceReferencePage" },
        },
        [
          _c("paper-running-head", { attrs: { "page-type": "references" } }),
          _vm._v(" "),
          _c("h1", { class: _vm.calcHeadingClasses }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.referenceTitleDisplay) + "\n\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "ckPaperReferences" } },
            _vm._l(_vm.referenceObjectsInPaper, function (referenceObject) {
              return _c("reference-page-row", {
                key: referenceObject.referenceUniqueID,
                attrs: {
                  "reference-object": referenceObject,
                  "reference-unique-id": referenceObject.referenceUniqueID,
                },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }