<!--
- Btn Close Drawer
-
-->

<template>
	<a href="#" @click.prevent="closeDrawer" class="float-end" data-bs-toggle="tooltip" data-bs-title="Close Tool Drawer" data-bs-placement="left">
		<span class="svg bi-chevron-right" />
	</a>
</template>

<script>
export default {
	name: 'BtnCloseDrawer',
	methods: {
		closeDrawer(){
			this.$store.commit('paperEdit/toolDrawer/IS_OPEN', false);
			
			if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
				this.$router.push({
					name: 'PaperTabEditor'
				}).catch(()=>{});
			} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
				this.$router.push({
					name: 'PaperTabOutline'
				}).catch(()=>{});
			}
			
		},//e:closeDrawer
	},
}
</script>
