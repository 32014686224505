var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand d-flex fixed-top d-print-none" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.showUserDropdown
          ? _c("ul", { staticClass: "navbar-nav ms-auto" }, [
              _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      href: "#",
                      role: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _c("span", { attrs: { id: "userFullName" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.$store.state.customer.fullName) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          key: "settingsElse",
                          staticClass: "dropdown-item",
                          attrs: {
                            to: {
                              name: "MyProfile",
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\tSettings\n\t\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "Logout" } },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\tLogout\n\t\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "leego-lynk ms-2 me-2" }, [
      _c("img", {
        staticClass: "leego-img me-2",
        attrs: {
          src: "///perrla.s3.amazonaws.com/images/logo-p-small.png",
          alt: "PERRLA, LLC",
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "prego-text" }, [_vm._v("PERRLA")]),
      _vm._v(" "),
      _c("span", { staticClass: "leego-text" }, [_vm._v("PERRLA")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }