<!--
- Side Panel in Paper Drawer
-->
<template>
	<div :class="['sidemenu-wrp d-flex align-items-stretch', {'open': $store.state.paperEdit.toolDrawer.isSideMenuOpen}]">
		<div class="w-100 d-block">
			<a href="#" @click.prevent="closeMenu" class="close-sidemenu">
				<span class="svg bi-x-lg" title="Close Menu" /> CLOSE
			</a>
			
			<hr />

			<div v-if="isEditor" key="divEditorNavitation">
				<router-link :to="{name:'EditorTitlePage'}" class="router-link">
					Title <span v-if="documentType === $enums.DocumentType.RESEARCH_PAPER && ($store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA6 || $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7)">Page</span>
				</router-link>

				<router-link
					v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" 
					class="router-link"
					key="linkAbstract" 
					:to="{name:'EditorAbstract'}">Abstract</router-link>
				<a
					href="#"
					v-else 
					class="router-link disabled"
					key="linkNoAbstract"
					@click.prevent="">Abstract</a>

				<router-link
					v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" 
					class="router-link"
					key="linkToc" 
					:to="{name:'EditorTableOfContents'}">Table of Contents</router-link>
				<a
					href="#" v-else 
					class="router-link disabled"
					key="linkNoToc"
					@click.prevent="">Table of Contents</a>

				<div v-if="showAppendixLink">
					<router-link
						v-if="documentType == $enums.DocumentType.RESEARCH_PAPER" 
						class="router-link"
						key="linkAppendix" 
						:to="{name:'EditorAppendices'}">Appendix</router-link>
					<a
						href="#" v-else 
						class="router-link disabled"
						key="linkNoAppendix"
						@click.prevent="">Appendix</a>
				</div>

				<hr />

				<router-link :to="{name:'EditorReferencesCitations'}" class="router-link">
					References &amp; Citations
				</router-link>
				<router-link :to="{name:'EditorResearchNotes'}" class="router-link">
					Research Notes
				</router-link>
				<router-link :to="{name:'EditorOutline'}" class="router-link">
					Outline
				</router-link>

				<hr />
				
				<router-link
					v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" 
					class="router-link"
					key="linkInsertFigure" 
					:to="{name:'EditorInsertFigure'}">Insert Figure</router-link>
				<a
					href="#" v-else 
					class="router-link disabled"
					key="linkNoInsertFigure"
					@click.prevent="">Insert Figure</a>

				<router-link
					v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" 
					class="router-link"
					key="linkInsertTable" 
					:to="{name:'EditorInsertTable'}">Insert Table</router-link>
				<a
					href="#" v-else 
					class="router-link disabled"
					key="linkNoInsertTable"
					@click.prevent="">Insert Table</a>
			
				<hr />
				
				<router-link
					v-if="documentType == $enums.DocumentType.DISCUSSION_POST || documentType == $enums.DocumentType.RESEARCH_PAPER" 
					class="router-link"
					key="linkPaperReview" 
					:to="{name:'EditorPaperReview'}">Paper Review</router-link>
				<a
					href="#" v-else 
					class="router-link disabled"
					key="linkNoPaperReview"
					@click.prevent="">Paper Review</a>
			</div><!--divEditorNavitation-->

			<div v-else key="divOutlineNavitation">
				<router-link :to="{name:'OutlineReferencesCitations'}" class="router-link">
					References &amp; Citations
				</router-link>
				<router-link :to="{name:'OutlineResearchNotes'}" class="router-link">
					Research Notes
				</router-link>
			</div><!--divOutlineNavitation-->

		</div>
	</div>
</template>

<script>
import config from '@/config';

export default {
	name: 'SideMenu',
	props:{
		isEditor:{
			default: false,
			type: Boolean
		},
	},
	computed:{
		documentType(){
			return this.$store.state.paperEdit.meta.DocumentType;
		},
		showAppendixLink(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				return true;
			}
			return false;
		},
	},
	methods: {
		closeMenu(){
			this.$store.commit('paperEdit/toolDrawer/IS_SIDEMENU_OPEN', false);
		}
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla.scss";

	// Side Menu inside Paper Drawer
	$menu-width: 225px;
	@include color-mode(dark) {
		div.sidemenu-wrp {
			background: $dark-body-bg;	
			&.open {
				border-left-color: $secondary-500;
				border-right-color: $secondary-500;

				a {
					color: $dark-body-color;

					&.close-sidemenu {
						background-color: $gray-800;
					}
					&.router-link {
					
						&.disabled, &.disabled:hover {
							background: $gray-900 !important;
							color: $gray-700 !important;
						}
					}
				}
			}
		}
	}

	div.sidemenu-wrp {
		background: $body-bg;
		bottom: 0;
		left: -$menu-width;
		position: absolute;
		top: 0;
		transition: left 120ms;
		width: $menu-width;
		z-index: 2000;
		
		&.open {
			border-left: 1px solid $secondary-300;
			border-right: 1px solid $gray-400;
			box-shadow: $box-shadow;
			left:0;
		}

		a {
			color: $body-color;
			display: block;
			padding: 0.5rem;
			text-decoration: none;

			&:hover, &.router-link-active{
				background: $primary !important;
				color:#fff !important;
			}
			
			&.close-sidemenu {
				background-color: $gray-100;
				font-size: $font-size-base;

				.svg {
					font-size: $font-size-base;
				}
			}
			&.router-link {
				font-size: $font-size-base;
				
				&.disabled, &.disabled:hover {
					background: $body-bg !important;
					color: $gray-300 !important;
					cursor: not-allowed !important;
				}
			}
		}//a

		hr{
			color: $secondary-300;
			margin: 0;
			opacity: 1;
		}

	}//e:div.sidemenu-wrp
</style>