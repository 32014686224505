<!--h
- Reference Edit > Research Notes Tab
-->
<template>
	<nav-tab-content>

		<div class="d-grid g-research-notes-header">
			<button
				class="btn btn-sm btn-hover btn-hover-success"	
				:disabled="searchTerm !== ''"
				type="button"
				@click.prevent="btnAddResearchNote">
				+ Add research note	
			</button>

			<div class="d-grid g-sort-and-search">
				<div class="dropdown">
					<a href="#" class="hover-underline text-body" data-bs-toggle="dropdown" role="button" aria-expanded="false" id="dropdownMenuResearchNotes">
						<span class="dropdown-toggle">Sorted by: <span class="fw-bold">{{ sortModeTextOutput }}</span></span>
					</a>

					<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuResearchNotes">
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST)">
								Created Date (newest first)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST)">
								Created Date (oldest first)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST)">
								Citation (first to last)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST)">
								Citation (last to first)
							</a>
						</li>
						
					</ul>
				</div>

				<div class="d-flex">
					<div class="w-100">
						<input
							class="form-control"
							placeholder="Search research notes"
							type="text"
							v-model="searchTerm"
							v-debounce:500ms="calcGridData" />
					</div>
					<div class="flex-shrink-1" v-show="searchTerm && searchTerm.length > 0"> 
						<button class="btn btn-link hover-underline" @click.prevent="clearSearch" type="button">
							Clear
						</button>
					</div>
				</div> 
			</div>
		</div><!--g-research-notes-header-->
		

		<div class="row">
			<div class="col">
				<!-- {{ filteredGridData }} -->
				<transition name="route-fade">
					<div v-if="isAddingNew">
						<textarea
							class="form-control mb-3"
							ref="newTextArea"
							rows="2"
							v-model="newNoteTextareaValue"
							@input="typeInput($event.target.value)"
							@keypress.shift.enter.prevent="saveNewNote(true)"
							@keypress.enter.exact.prevent="saveNewNote(false)"
							@keyup.esc="cancelNewNote" />
							<!-- @blur="saveNewNote" -->
							
					</div>
				</transition>
				
				<div v-if="filteredGridData.length > 0" key="divSearchResults">
					<research-note-row
							v-for="researchNoteData in filteredGridData"
							:key="researchNoteData.genesisResearchNoteUniqueId"
							:research-note-citation="researchNoteData.citation"
							:research-note-citation-output="researchNoteData.citationOutput"
							:research-note-genesis-reference-id="researchNoteData.genesisReferenceId"
							:research-note-genesis-research-note-id="researchNoteData.genesisResearchNoteId"
							:research-note-genesis-research-note-unique-id="researchNoteData.genesisResearchNoteUniqueId"
							:research-note-reference-unique-id="researchNoteData.referenceUniqueID"
							:research-note-text="researchNoteData.note"
							@update-grid="calcGridData" />
				</div>
				<div v-else-if="filteredGridData.length === 0 && searchTerm != ''" key="divNoSearchResults">
					<p class="fw-light org-size pt-5 text-center">
						No results - try searching for something else
					</p>
				</div>
				
			</div>
		</div><!-- row -->

	
		<modal-research-note-citation-add />
		<modal-research-note-citation-edit @update-grid="calcGridData" />
		
	</nav-tab-content>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _orderBy from 'lodash/orderBy';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import ModalResearchNoteCitationAdd from '@/modules/ResearchNotes/modals/CitationAdd';
import ModalResearchNoteCitationEdit from '@/modules/ResearchNotes/modals/CitationEdit';
import NavTabContent from '@/components/NavTabContent';
import ResearchNoteRow from '@/modules/ResearchNotes/RowLive';
import RNS_CalcSortValue from '@/services/paper/researchNotes/calcSortValue';
import RS_LoadByRoute from '@/services/reference/loadByRoute';
import RS_UpdateAfterSync from '@/services/reference/updateAfterSync';

export default {
	name: 'TabResearchNotes',
	mixins: [mixinExpandTextareaById],
	data() {
		return {
			filteredGridData: [],
			isAddingNew: false,
			newNoteTextareaValue: '',
			searchTerm: '',
			sortByEnum: config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
		}
	},
	computed:{
		parentReferenceObject(){
			let findReference = this.$store.state.referenceLibraryGenesis.find((reference)=>{
				return reference.referenceUniqueID.toUpperCase() === this.$router.currentRoute.params.referenceUid.toUpperCase();
			});
			if(findReference){
				return findReference;
			}
			return null;
		},//e:parentReferenceObject

		sortModeTextOutput(){
			switch(this.sortByEnum){
				case config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST:
					return 'Created Date (newest first)';
				case config.enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST:
					return 'Created Date (oldest first)';
				case config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST:
					return 'Citation (first to last)';
				case config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST:
					return 'Citation (last to first)';
			}
			return ''
		},
	},
	methods: {
		btnAddResearchNote(){
			// if sort was NOT by date, make it by date now
			if(this.sortByEnum !== config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST){
				this.sortByEnum = config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST;
				this.calcGridData();
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Sort changed to Created Date (newest first)',
					variant: 'warning'
				});
			}
			
			this.isAddingNew = true;

			this.$nextTick(()=>{
				this.$refs.newTextArea.focus();
			});
		},//e:btnAddResearchNote

		calcGridData(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let searchedArray = [];

			let researchNotesForThisReference = this.$store.state.researchNotes.list.filter((researchNoteData)=>{
				return researchNoteData.referenceUniqueID.toUpperCase() === this.$router.currentRoute.params.referenceUid;
			});
			
			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();

			if(_searchTerm){
				// loop through all notes from this reference
				researchNotesForThisReference.forEach((researchNoteData)=>{
					if(researchNoteData.note.toLowerCase().includes(_searchTerm)){
						searchedArray.push(researchNoteData);
					}
				});//e:forEach
			} else {
				searchedArray = researchNotesForThisReference;	
			}

			switch(this.sortByEnum){
				case config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST:
					this.filteredGridData = searchedArray.reverse();
					break;
				case config.enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST:
					this.filteredGridData = searchedArray;
					break;
				case config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST:
				case config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST:
					// calculate a sort by score
					searchedArray = _cloneDeep(searchedArray); // clone so i can add a sortby score below
					searchedArray.forEach((researchNoteData)=>{
						// console.log(researchNoteData)

						let parsedCitationData = '';
						if(researchNoteData.citation !== ''){
							parsedCitationData = JSON.parse(researchNoteData.citation);
						}
						// console.log(parsedCitationData.citationData);
						
						let sortByScore = 0;

						if(_has(parsedCitationData, 'citationData')){
							sortByScore = RNS_CalcSortValue({
								citationData: parsedCitationData.citationData,
								sortByEnum: this.sortByEnum,
							});
						}//e:if:_has
					
						researchNoteData.sortByScore = sortByScore;
					});//e:forEach

					this.filteredGridData =  _orderBy(searchedArray, ['sortByScore'], ['desc']);
					break;
			}

			
			

			// 	// sort by citation location
			
			

			this.$store.commit('loaders/REMOVE_ID', 'App');
		},//e:calcGridData
		
		cancelNewNote(){
			this.isAddingNew = false;
			this.newNoteTextareaValue = '';
		},

		clearSearch(){
			this.searchTerm = '';
			this.calcGridData();
		},

		saveNewNote(isAddNewAfter){
			let _newNoteTextareaValue = this.newNoteTextareaValue.trim();

			// empty content for new research note
			if(_newNoteTextareaValue === ''){
				this.cancelNewNote();
				return false;
			}

			let sendData = {
				'Citation': '',
				"GenesisReferenceID": this.parentReferenceObject.referenceID,
				"Note": _newNoteTextareaValue,
			}
			
			// console.log('sendData');
			// console.log(sendData);

			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				body: sendData,
				method: 'POST',
				responseType: 'json',
				url: 'v3/GenesisResearchNote',
			}).then((responseData)=>{
				// console.log('responseData');
				// console.log(responseData);

				let addResearchNoteData = {
					citation: '',
					citationOutput: '',
					genesisReferenceId: responseData.genesisReferenceID,
					genesisResearchNoteId: responseData.genesisResearchNoteID,
					genesisResearchNoteUniqueId: responseData.genesisResearchNoteUniqueID,
					note: responseData.note,
					referenceUniqueID: this.$router.currentRoute.params.referenceUid.toUpperCase(),
				}

				this.$store.commit('researchNotes/ADD_TO_LIST', addResearchNoteData);

				this.isAddingNew = false;
				this.newNoteTextareaValue = '';

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Research note created',
				});
				
				this.calcGridData();

				this.$nextTick(()=>{
					if(isAddNewAfter){
						this.btnAddResearchNote();
					}
				});
			
			});//e:ApiS_MakeCall
		
		},//e:saveNewNote
		
		setSortBy(setToEnum){
			this.sortByEnum = setToEnum;
			this.calcGridData();
		},//e:setSortBy

		typeInput(value){
			this.$emit('input', value);
			this.expandTextarea('newTextArea');
		},//e:typeInput

	},

	components:{
		ModalResearchNoteCitationAdd,
		ModalResearchNoteCitationEdit,
		NavTabContent,
		ResearchNoteRow
	},
	mounted(){
		this.$store.commit('loaders/ADD_ID', 'App');

		RS_LoadByRoute().then(()=>{
			RS_UpdateAfterSync().then(()=>{
				this.calcGridData();
			});
		});
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/vars_perrla";

	.g-research-notes-header {
		column-gap: 1.5rem;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
			
		button {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			grid-row-start: 2;
		}

		@include media-breakpoint-up(md) { 
			grid-template-columns: 25% auto;
			margin-bottom: 1rem;
			
			button {
				grid-row-start: 1;
			}
		}
	
		.g-sort-and-search{
			align-items: center;
			column-gap: 0.75rem;
			grid-template-columns: auto;
			grid-template-rows: auto auto;

			@include media-breakpoint-up(md) { 
				grid-template-columns: max-content minmax(33%, 200px);
				grid-template-rows: auto;	
				justify-content: end;
			}

			button {
				margin: 0;
			}

			p {
				font-size: 1rem;
				text-align: right;
			}
		}//.g-sort-and-search

	}//.g-research-notes-header
</style>