<!--
- Paper Edit > Organize Tab > Research Notes > Create Reference Step 2
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>
		
		<create-reference-step-3-body :is-drawer="false" />

	</div>
</template>

<script>
import CreateReferenceStep3Body from '@/modules/CreateReferenceStep3Body'

export default {
	name: 'PaperTabResearchCreateReferenceStep3',
	components: {
		CreateReferenceStep3Body,
	}
}
</script>
