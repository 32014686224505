<!--
- Reference Convert Step 2
- APA7
-->

<template>
	<div class="drawer">
		<!-- Close Warning -->
		<transition name="route-fade">
			<div v-if="showCloseWarning" class="close-warning">
				<h1>Stop updating this reference?</h1>
				<p>If you stop now, the information you've entered on this page will be lost.  To finish updating the reference, click the green button at the bottom.</p>
				<div class="d-grid gap-2">
					<router-link :to="{name:'EditorAddFromLibrary'}" class="btn btn-outline-danger">
						Don't update reference
					</router-link>
					<button type="button" class="btn btn-success" @click.prevent="showCloseWarning = false">
						Keep working
					</button>
				</div>
			</div>
		</transition>

		<div class="drawer-header">
			<tool-drawer-title title="Update Reference" :is-close-warning="true" @show-warning="showCloseWarning = true" />
		</div>
		
		<div class="drawer-body">
			<convert-reference-step2-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import ConvertReferenceStep2Body from '@/modules/ConvertReferenceStep2Body';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'ConvertApa7Step2',
	data() {
		return {
			showCloseWarning: false,
		}
	},
	computed:{
		editReference(){
			return this.$store.state.paperEdit.editReference
		},
	},
	methods: {
		
	},
	mounted() {
		// // // set values from store object to editable fields
		// this.nickname = (this.editReference.name === 'empty') ? '' : this.editReference.name;
		// this.description = (this.editReference.note === 'empty') ? '' : this.editReference.note;
		// this.tags = (this.editReference.tags === 'empty') ? '' : this.editReference.tags;

	},
	components: {
		ConvertReferenceStep2Body,
		ToolDrawerTitle
	}
}
</script>

