/*
- URL to Env
-- easier way to calculate a url that goes to perrla.com or perrlacomplete.com depending on env state  
-- 2023.01.06
*/
import config from '@/config';

export default {
	methods: {
		calcUrlToEnv(urlToEnvEnum, appendPath){
			if(urlToEnvEnum === config.enums.URL_TO_ENV.PERRLA){
				if(config.isDev || config.isTest){
					return '///test.perrla.com/#/' + appendPath;					
				}
				return '///www.perrla.com/#/' + appendPath;

			} else if(urlToEnvEnum === config.enums.URL_TO_ENV.PERRLA_COMPLETE){
				if(config.isDev || config.isTest){
					return '///testonline.perrla.com/' + appendPath;
				}
				return '///www.perrlacomplete.com/' + appendPath;
				
			}
			
			return '';
		}
	},
}