var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-grid g-writing-tips" }, [
    _c("h2", [_vm._v("Writing Tips")]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "divWritingTipsWrp", staticClass: "overflow-y-scroll" },
      _vm._l(_vm.showWritingTips, function (tipData, index) {
        return _c("writing-tip-row", {
          key: index,
          attrs: { "tip-data": tipData },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }