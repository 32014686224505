/*
- HelperDateIsReferenceWizardValid
-- makes sure a dateString that goes in the reference wizard is valid
-- MM/YYYY or DD/MM/YYYY only
--2023.06.16
*/
import _forEach from 'lodash/forEach';

export default (dateStrig) => {
	// console.log('HelperDateIsReferenceWizardValid');

	// split the string into an array of bits
	let dateArray = dateStrig.trim().split('/');

	let isStringValid = true; // by default this will be valid

	// loop through each bit in this array (should only be 1-3)
	_forEach(dateArray, (dateBit)=>{
		if(dateBit.length === 0){
			isStringValid = false;
			return false;	// if you find an empty one, stop looping the whole thing is invalid
		}
	});//e:_forEach

	return isStringValid; 
}