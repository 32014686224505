<!--
- Paper Edit > Organize Tab > Research Notes > Convert Reference Step 1
-->
<template>
	<div>
		<h2 class="nodal-title">
			Update Reference
		</h2>
		
		<convert-reference-step1-body :is-drawer="false" />

	</div>
</template>

<script>
import ConvertReferenceStep1Body from '@/modules/ConvertReferenceStep1Body';

export default {
	name: 'OrganizeConvertReferenceStep1',
	components: {
		ConvertReferenceStep1Body,
	}
}
</script>
