var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tCreate a reference\n\t"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "org-size d-block" }, [
        _vm._v("\n\t\tStep 1 of 3: Select a reference type\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "nav-tabs",
        { staticClass: "mb-2" },
        [
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "ReferenceListCreateReferenceStep1",
                  params: { openReferenceTab: "common" },
                },
              },
            },
            [_vm._v("\n\t\t\tCommon\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "nav-tab",
            {
              attrs: {
                to: {
                  name: "ReferenceListCreateReferenceStep1",
                  params: { openReferenceTab: "all" },
                },
              },
            },
            [_vm._v("\n\t\t\tAll types\n\t\t")]
          ),
          _vm._v(" "),
          _vm.$store.state.references.recentTypeIds.length > 0
            ? _c(
                "nav-tab",
                {
                  attrs: {
                    to: {
                      name: "ReferenceListCreateReferenceStep1",
                      params: { openReferenceTab: "recent" },
                    },
                  },
                },
                [_vm._v("\n\t\t\tRecent\n\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.params.openReferenceTab === "common"
        ? _c(
            "div",
            { key: "searchThirdParty" },
            [
              _c("reference-search-third-party", {
                attrs: { "is-drawer": false, "is-first-search": true },
              }),
            ],
            1
          )
        : _vm.$route.params.openReferenceTab === "all"
        ? _c(
            "div",
            { key: "searchAll" },
            [
              _c("reference-type-search", {
                attrs: { "ref-types": _vm.refTypes },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("create-reference-step-1-body", { attrs: { "is-drawer": false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }