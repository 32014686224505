<!--
- Class Edit > Details
-
-->

<template>
	<div class="org-size">
		<h2>Class Details</h2>
		
		<p class="mb-1">
			<strong>Professor</strong><br />
			<span class="ms-3">{{ classData.teacherName }}</span>
		</p>

		<p class="mb-1">
			<strong>Email</strong><br />
			<span class="ms-3">{{ classData.teacherEmail }}</span>
		</p>

		<br />

		<div class="d-grid">
			<!-- <button type="button" class="btn btn-outline-primary mb-2" @click.prevent="btnEditClass">
				Edit Class
			</button> -->
			<button type="button" class="btn btn-outline-danger" @click.prevent="btnArchiveClass">
				Archive Class
			</button>
		</div>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';

export default {
	name: 'ClassEditDetails',
	props:{
	},
	data() {
		return {
		}
	},
	computed:{
		classData(){
			return this.$store.state.classes.openObject;
		},
	},
	methods: {
		btnArchiveClass(){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			ApiS_MakeCall({
				params:{
					'id': this.classData.classID,
				},
				method: 'PUT',
				url: 'v4/Class/Archive',
			}).then(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Class successfully archived',
				});
				this.$router.push({
					name: 'Dashboard'
				}).catch(()=>{});
			});

		},
		btnEditClass(){
			alert('btnEditClass');
		},
	},
	created() {
	},
	mounted() {
	},
	components: {
	}
}
</script>
