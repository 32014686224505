<!--
- Paper Edit > Write Tab > Page Number Footer
- For now only for Turabian9
- don't confuse this with Footer.vue which is for WordCount
-->

<template>
	<div>
		<p class="text-center">
			{{ pageNumberOutput }}
		</p>
	</div>
</template>

<script>

export default {
	name: 'PageFooter',
	props:{
		pageType: {
            required: true,
            type: String
        }
	},
	computed:{
		pageNumberOutput(){
			switch(this.pageType){
				case 'abstract':
					return 'ii';
				case 'toc':
					if(this.$store.state.paperEdit.meta.IncludeAbstract){
						return 'iii';
					} else {
						return 'ii';
					}
			}
			return '';
		},
	},
}
</script>

<style lang="scss" scoped>
	@import "../../../assets/styles/_vars_perrla.scss"; 

	p {
		// font-size: 10pt !important;
		position: absolute;
		right: 0;
		bottom: divide($page-margin, 2);
		left: 0;
	}
</style>