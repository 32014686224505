<!--
- Create Reference > Step 2 > Result Row (selection to step 3)
-
-->

<template>
	<div class="third-party-row d-flex">
		<div class="img-wrp">
			<img :src="calcImgSrc" role="presentation" />
		</div>
		<div class="row-content">
			<p>
				<span v-if="thirdPartyReference.subTitle === ''" key="noSubtitle">
					<span class="row-title">
						{{ thirdPartyReference.title }}
					</span>
				</span>
				<span v-else key="yesSubtitle">
					<span class="row-subtitle">"{{ thirdPartyReference.title }}"</span>	
					in <span class="row-title">{{ thirdPartyReference.subTitle }}</span>
				</span>
				
				({{ thirdPartyReference.typeLabel }})

				<br />

				{{ thirdPartyReference.authorInfo }} <br v-if="thirdPartyReference.authorInfo" />

				{{ thirdPartyReference.publisherInfo }} <br v-if="thirdPartyReference.publisherInfo" />
			</p>
			<div class="d-grid">
				<button type="button" class="btn btn-sm btn-hover btn-hover-success ms-2" @click.prevent="selectReferenceType">
					<span class="svg bi-plus-lg" /> Use this work
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import config from '@/config';
import Convert3pluTypeToUrl from '@/helpers/convert-3plu-type-to-url';

export default {
	name: 'CreateReferenceStep2ThirdPartyResultRow',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		},
		thirdPartyReference:{
			required: true,
			type: Object,
		}
	},
	data() {
		return {
			calcImgSrc: '',
		}
	},
	methods: {
		selectReferenceType(){
			this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', this.thirdPartyReference.id);

			this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', this.thirdPartyReference.typeLabel);

			this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', this.thirdPartyReference.referenceData);
			
			// construct slug from result
			let calcReferenceTypeSlug = Convert3pluTypeToUrl(this.thirdPartyReference);
			
			if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
				if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
					this.$router.push({
						name:'EditorCreateStep3ThirdParty',
						params:{
							referenceTypeSlug: calcReferenceTypeSlug
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
					this.$router.push({
						name:'OutlineCreateStep3ThirdParty',
						params:{
							referenceTypeSlug: calcReferenceTypeSlug
						}
					}).catch(()=>{});
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
					this.$router.push({
						name: 'PaperTabResearchCreateReferenceStep3ThirdParty',
						params:{
							referenceTypeSlug: calcReferenceTypeSlug
						}
					}).catch(()=>{});
				}
			} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
				this.$router.push({
					name: 'ReferenceListCreateReferenceStep3ThirdParty',
					params:{
						referenceTypeSlug: calcReferenceTypeSlug
					}
				}).catch(()=>{});
			}
		},//e:selectReferenceType
	},
	mounted() {
		let $this = this;

		if(this.thirdPartyReference.imageUrl === '') {
			
			if(this.thirdPartyReference.id === 2){
				// Journal
				this.calcImgSrc = 'https://perrla.s3.amazonaws.com/icons/icons8-news.svg';
			} else {
				// Else but it's Book
				this.calcImgSrc = 'https://perrla.s3.amazonaws.com/icons/icons8-book.svg';
			}
		} else {
			// test if the image is really out there
			var img = new Image();
			img.onload = function(){
				$this.calcImgSrc = $this.thirdPartyReference.imageUrl;
			}; 
			img.onerror = function(){
				if($this.thirdPartyReference.id === 2){
					// Journal
					$this.calcImgSrc = 'https://perrla.s3.amazonaws.com/icons/icons8-news.svg';
				} else {
					// Else but it's Book
					$this.calcImgSrc = 'https://perrla.s3.amazonaws.com/icons/icons8-book.svg';
				}

			};
			img.src = $this.thirdPartyReference.imageUrl;
		}
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";

	.third-party-row {
		margin-bottom: 0.5rem;
		padding: 1rem 0 0.5rem 0;
	
		.img-wrp{
			height: 100px;
			width: 70px;
			margin-right: 8px;

			img{
				display: inline-block;
				width:100%;
			}

		}

		.row-content {
			flex: 1;

			p {
				&.row-title {
					font-style: italic;
					font-weight: $font-weight-bold;
				}
				&.row-subtitle {
					font-weight: $font-weight-bold;
				}
				& {
					font-size: $font-size-base;
					font-weight: $font-weight-normal;
					margin-bottom: 0;
					padding-left: 32px;
					text-indent: -32px;
				}
			}
		}
		
	}
</style>