<!--
- Regular Form Inputs (ex: Creating a Paper > Title)
-- also Horizontal Form like Contact Support (in PO)
-- 2023.07.12
-->

<template>
	<div :class="['form-group', {'row': isHorizontal}]">
		<div :class="{'col-sm-3': isHorizontal}">
			<label
				:class="{'fst-italic':isInvalidShown}"
				:for="safeId"
				v-html="label" />
		</div>

		<div :class="{'col-sm-9': isHorizontal}">
			<div class="help-text mb-1" v-if="helpText" v-html="helpText" />

			<input
				autocomplete="off"
				:class="['form-control', {
					'is-invalid': isInvalidShown,
					}]"
				ref="focusElement"
				:id="safeId"
				:pendo-grab-text="pendoGrabText"
				:placeholder="placeholder"
				:type="localType"
				:value="value"
				:role="role"
				@blur="debouncedValidateFormField"
				@input="typeInput($event.target.value)" /> 
				<!-- v-debounce:750ms="debouncedValidateFormField" -->

			<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
				{{ invalidErrorMessage }}
			</div>
			
		</div><!--col-sm-9-->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInput',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isHorizontal:{	// contact support is a horizontal form but the default wil be false for all other form inputs
			default: false,
			type: Boolean,
		},
		isLoadFocus: {
			default: false,
			type: Boolean
		},
		helpText: {
			type: String,
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		role: {
			type: String,
		},
		type:{
			default: 'text',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			localType: null,
		}
	},
	computed:{
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.safeId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		debouncedValidateFormField(){
			AS_ValidateForm({
				singleFieldId: this.safeId
			}).then(()=>{
				// validation passed
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:debouncedValidateFormField

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.$emit('input', value);
		},//e:typeInput
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.safeId,
				validateEnum: this.validateEnum,
			})
		}

		if(this.isLoadFocus && this.$refs.focusElement){
			this.$refs.focusElement.focus();
		}

		this.localType = this.type;
	},
}
</script>
