<!--
- Count Down
-- Developer Tools only

-->

<template>
	<div>
		<h1>Countdown Timer</h1>

		<div class="countdown-wrapper">
			<ul>
				<li><span id="minutes" /></li>
				<li><span>:</span></li>
				<li><span id="seconds" /></li>
			</ul>
		</div>
	</div>
</template>

<script>

export default {
	name: 'CountDown',
	mounted() {
		(function () {
			var hour = 1000 * 60 * 60;
		
			var countDownToReach = 3599;	//  3599 a full hour of seconds -1 for the first second
			
			if(document.getElementById("minutes") && document.getElementById("seconds")){
				document.getElementById("minutes").innerText = Math.floor((countDownToReach % (hour)) / 60);
				document.getElementById("seconds").innerText = Math.floor(countDownToReach % 60);

				// start a timer to run every second
				var stoCountdown = setInterval(function() {    
					countDownToReach--;
					
					// calculate minutes
					var calcMins = Math.floor((countDownToReach % (hour)) / 60);
					if(calcMins < 10){
						document.getElementById("minutes").innerText = '0' + calcMins;
					} else {
						document.getElementById("minutes").innerText = calcMins;
					}
					
					// calculate seconds
					var calcSecs = Math.floor(countDownToReach % 60);
					if(calcSecs < 10){
						document.getElementById("seconds").innerText = '0' + calcSecs;
					} else {
						document.getElementById("seconds").innerText = calcSecs;
					}

					if(calcSecs === 0 && calcMins === 0){
						clearInterval(stoCountdown);
					}
				}, 1000);
			}
		}());
	},
}
</script>


<style lang="scss" scoped>
.countdown-wrapper{
	border: 1px solid #212529;
	text-align: center;
	width: 50%;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	display: inline-block;
	list-style-type: none;
	text-transform: uppercase;
}

li span {
	color: #212529;
	display: block;
	font-size: 4.5rem;
}



</style>