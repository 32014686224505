/*
- HelperRemoveHtmlEntities
- remove entities that may have came from a 3plu
- example: https://doi.org/10.48084/etasr.4600 - Jounral Title has an & which renders as &amp;
-- 2023.06.12
*/
export default (htmlInput) => {
	let element = document.createElement('div');
	element.innerHTML = htmlInput;
	let cleanTitle = element.textContent;
	element.textContent = '';
	return cleanTitle;
}