<!--
- Paper Edit > Overview > Edit Class
-->

<template>
	<generic-modal-wrap modal-id="PaperEdit">
		<h2 class="nodal-title">
			Edit Details
		</h2>
		<form-datepicker
			id="PE_startDate"
			label="Start Date"
			:is-clearable="true"
			:paper-format="0"
			@date-clear="dateStartClear"
			@date-set="dateStartSet" />

		<form-datepicker
			id="PE_dueDate"
			label="Due Date"
			:is-clearable="true"
			:paper-format="0"
			@date-clear="dateDueClear"
			@date-set="dateDueSet" />

		<form-textarea
			label="Description"
			:is-autosize="true"
			v-model="description" />
		
		<form-select
			label="Class"
			:is-class-select="true"
			v-model="associatedClassId" />

		<form-input
			label="Length"
			v-model="requiredLength" />

		<div class="row">
			<div class="col-6">
				<div class="d-grid">
					<button type="button" class="btn btn-outline-danger" @click.prevent="$store.commit('modals/CLOSE', 'PaperEdit')">
						Cancel
					</button>
				</div>
			</div>
			<div class="col-6">
				<div class="d-grid">
					<button type="submit" class="btn btn-success" @click.prevent="updatePaper">
						Update Paper Details
					</button>
				</div>
			</div>
		</div>

	</generic-modal-wrap>
</template>

<script lang="js">
import AS_LoadDatepicker from '@/services/app/loadDatepicker';
import FormDatepicker from '@/components/form/Datepicker';
import FormInput from '@/components/form/Input';
import FormSelect from '@/components/form/Select';
import FormTextarea from '@/components/form/Textarea';
import PS_SaveDocument from '@/services/paper/saveDocument';

import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalPaperEdit',
	computed:{
		dataDueDate(){
			return this.$store.state.paperEdit.meta.DueDate;
		},
		dataStartDate(){
			return this.$store.state.paperEdit.meta.StartDate;
		},
	},
	data() {
		return {
			associatedClassId: 0,
			description:'',
			dueDate: '',
			requiredLength: '',
			startDate: '',
		}
	},
	methods: {
		dateDueClear(){
			this.dueDate = '01/01/2099';
		},
		dateDueSet(newDate){
			this.dueDate = newDate;
		},
		
		dateStartClear(){
			this.startDate = '01/01/2099';
		},
		dateStartSet(newDate){
			this.startDate = newDate;
		},

		updatePaper(){
			let getAssociatedCourseData = this.$store.state.classes.list.find((classData)=>{
				return classData.classID == this.associatedClassId;
			});
			
			let calcCourseName = '';
			
			if(getAssociatedCourseData){
				calcCourseName = getAssociatedCourseData.name;
			} 

			this.$store.commit('loaders/ADD_ID', 'App');

			this.$store.commit('paperEdit/meta/SET_ClassID', this.associatedClassId);
			this.$store.commit('paperEdit/meta/SET_CourseName', calcCourseName);
			this.$store.commit('paperEdit/meta/SET_Description', this.description);
			this.$store.commit('paperEdit/meta/SET_DueDate', this.dueDate);
			this.$store.commit('paperEdit/meta/SET_StartDate', this.startDate);
			this.$store.commit('paperEdit/meta/SET_RequiredLength', this.requiredLength);

			PS_SaveDocument({
				isMetaDataChanges: true,
				message: 'Updated paper details',
				updateMetaTags:[
					{'CourseName': calcCourseName},
					{'ClassID': this.associatedClassId},
					{'DueDate': this.dueDate},
					{'Description': this.description},
					{'RequiredLength': this.requiredLength},
					{'StartDate': this.startDate},
				]
			}).then(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				this.$store.commit('modals/CLOSE', 'PaperEdit');
			});
		},//e:updatePaper
		
	},
	created(){
		this.emitter.on('GenericModalOpen', ($opts)=>{
			if($opts.modalId === 'PaperEdit'){
				// next tick here to get the dates to load
				this.associatedClassId = this.$store.state.paperEdit.meta.ClassID || '0';
				this.description = this.$store.state.paperEdit.meta.Description;
				this.dueDate = this.dataDueDate;
				this.requiredLength = this.$store.state.paperEdit.meta.RequiredLength;
				this.startDate = this.dataStartDate;

				this.$nextTick(()=>{
					AS_LoadDatepicker({
						id: 'PE_startDate',
						loadDate: this.startDate,
						isPaperEdit: true,
						isRefWizard: false,
						paperFormat: this.$store.state.paperEdit.meta.PaperFormatVersionID,
					});

					AS_LoadDatepicker({
						id: 'PE_dueDate',
						loadDate: this.dueDate,
						isPaperEdit: true,
						isRefWizard: false,
						paperFormat: this.$store.state.paperEdit.meta.PaperFormatVersionID,
					});

				
				});
			}
		});
	},
	components:{
		FormDatepicker,
		FormInput,
		FormSelect,
		FormTextarea,
		GenericModalWrap,
	},
	destroyed(){
		this.emitter.off('GenericModalOpen');
	},
}
</script>
