<!--
- Settings > MyProfile
-
-->

<template>
	<nav-tab-content>
		<div class="row">
			<div class="col">

				<div v-if="($store.state.customer.role === $enums.Status.FREE_TRIAL)" class="mb-4" key="divFreeTrial">
					<h3>Your PERRLA Free Trial</h3>

					<form-input-v2-display
						display-class="text-danger"
						label="Subscription"
						:value="freeTrialOutput" />

					<form-row-slot>
						<div class="d-grid">
							<button
								class="btn btn-success"
								type="button"
								@click.prevent="btnGoToSubscriptionSettings">
								Subscribe to PERRLA now
							</button>
						</div>
					</form-row-slot>
				</div><!--divFreeTrial-->

				<div v-else-if="isSuscriptionExpired" class="mb-4" key="divExpired">
					<h3>Subscription</h3>
					
					<form-input-v2-display
						display-class="text-danger"
						label="Subscription"
						:value="expiredDateOutput" />

					<form-row-slot>
						<div class="d-grid">
							<button
								class="btn btn-success"
								type="button"
								@click.prevent="btnGoToSubscriptionSettings">
								Renew your PERRLA Subscription
							</button>
						</div>
					</form-row-slot>
					
					<br />
				</div><!--divExpired-->

				<div v-else-if="isCancelled" class="mb-4" key="divCancelled">
					<h3>Subscription</h3>
					
					<form-input-v2-display
						:is-full-width="true"
						display-class="text-danger"
						label="Subscription"
						value="Your subscription was cancelled. You have full access to your PERRLA until your current subscription expires." />

					<form-input-v2-display
						label="Expiration Date"
						:is-full-width="true"
						:value="expiredDateOutput" />

					<form-row-slot>
						<div class="d-grid">
							<button
								v-if="$store.state.customer.isRecurring"
								class="btn btn-outline-primary"
								key="buttonRestore"
								type="button"
								@click.prevent="btnRestoreSubscription">
								Restore My Subscription
							</button>
							<button
								v-else
								class="btn btn-outline-primary"
								key="buttonRenew"
								type="button"
								@click.prevent="btnGoToSubscriptionSettings">
								Renew your PERRLA Subscription
							</button>
						</div>
					</form-row-slot>
					
					<br />
				</div><!--divCancelled-->
				
				<form v-if="!isSuscriptionExpired">
					<h3>Personal Information</h3>

					<form-input-v2
						label="First Name"
						v-model="firstName"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED" />

					<form-input-v2
						label="Last Name"
						v-model="lastName"
						:form-id="zFormId"
						:validate-enum="$enums.Validation.REQUIRED" />

					<div v-if="$store.state.customer.organizationId === 0" key="divInputEmailSingle">
						<form-input-v2
							label="Email"
							v-model="email"
							:form-id="zFormId"
							:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
					</div>
					<div v-else key="divInputEmailAddMore">
						<form-emails-v2
							label="Email"
							:form-id="zFormId"
							:org-email="email" />
					</div>

					<form-row-slot>
						<div class="d-grid">
							<button 
								class="btn btn-outline-primary border-0 text-start"
								type="button"
								@click.prevent="btnResetPassword">
								Reset my PERRLA Password
							</button>
						</div>
					</form-row-slot>

					<h3>Institutional Information</h3>

					<form-input-v2
						label="Institution Name"
						:form-id="zFormId"
						v-model="institution" />

					<form-select-v2
						label="Preferred Format"
						:form-id="zFormId"
						:select-object="preferredFormatList"
						v-model="preferredFormatVersionID" />
						
					<form-row-slot>
						<div class="d-grid">
							<button 
								class="btn btn-success"
								:disabled="!isSubmitClickable"
								type="submit"
								@click.prevent="btnUpdateProfile">
								Update Profile
							</button>
						</div>
					</form-row-slot>
				</form>
			</div><!--col-->
		</div><!--row-->
	</nav-tab-content>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import { differenceInDays } from 'date-fns'
import FormEmailsV2 from '@/components/form/EmailsV2';
import FormInputV2 from '@/components/form/InputV2';
import FormInputV2Display from '@/components/form/InputV2Display';
import FormRowSlot from '@/components/form/RowSlot';
import FormSelectV2 from '@/components/form/SelectV2';
import NavTabContent from '@/components/NavTabContent';

export default {
	name: 'SettingsMyProfile',
	data() {
		return {
			email: '',
			firstName: '',
			institution: '',
			lastName: '',

			preferredFormatVersionID: '',
			preferredFormatList: [
				{
					value: config.enums.Format.APA7.toString(),
					name: 'APA 7',
				},
				{
					value: config.enums.Format.MLA9.toString(),
					name: 'MLA 9',
				},
				{
					value: config.enums.Format.Turabian9.toString(),
					name: 'Turabian 9',
				},
			],

			zFormId: 'formMyProfile',
		}
	},
	computed:{
		expiredDateOutput(){
			let returnString = '';

			if(this.$store.state.customer.expirationDate){
				if(this.$store.state.customer.expirationDate > config.today){
					returnString = 'Your current subscription will expire on ';
				} else {
					returnString = 'Your PERRLA expired on ';
				}
				
				returnString += dateToLongString({
					dateValue: this.$store.state.customer.expirationDate,
					forceYear: true,
					showTime: false,
				});
			}
			return returnString;
		},
		
		freeTrialOutput(){
			// If a user's expiration date is within 14 days, show text to the right of the Create New button: "Your subscription expires in [days] days."
			if(this.$store.state.customer.expirationDate !== ''){
				let expirationDate = new Date(this.$store.state.customer.expirationDate);

				let diferenceInDays = differenceInDays(expirationDate.getTime(), config.today.getTime());

				if(diferenceInDays === 0){
					return 'Your PERRLA Free Trial expires today!';

				} else if(diferenceInDays > 0){
					let returnString = diferenceInDays + ' day'
					if(diferenceInDays > 1){
						returnString += 's';
					}
					return  'Your subscription expires in ' + returnString +'.';

				} else if(diferenceInDays < 0){
					return 'Your PERRLA Free Trial has expired.';
				}
			}
			
			return '';
		},
		isCancelled(){
			return this.$store.state.customer.isCancelled;
		},
		isSubmitClickable(){
			let findIndex = this.$store.state.forms.list.findIndex((formData)=>{
				return formData.formId === this.zFormId;
			});

			if(findIndex !== -1){
				return this.$store.state.forms.list[findIndex].isDirty;
			}
			return false;
		},
		isSuscriptionExpired(){
			if(this.$store.state.customer.expirationDate && (this.$store.state.customer.expirationDate < config.today)){
				return true;
			}
			return false;
		},
	},
	methods: {
		btnGoToSubscriptionSettings(){
			this.$store.dispatch('cart/getNewCart').then(()=>{
				this.$router.push({
					name: 'SubscriptionSettings',
				}).catch(()=>{});
			});
		},//e:btnGoToSubscriptionSettings

		btnResetPassword(){
			ApiS_MakeCall({
				method: 'GET',
				params:{
					customerId: this.$store.state.customer.id
				},
				responseType: 'text',
				url: 'v4/Customer/ForgotPassword/Link',
			}).then((responseDataUrl)=>{
				window.location = responseDataUrl;
				
			});//e:ApiS_MakeCall
		},//e:btnResetPassword

		btnRestoreSubscription(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				method: 'PUT',
				params: {
					customerId: this.$store.state.customer.id
				},
				responseType: 'none',
				url: 'v4/Customer/Subscription',
			}).then(()=>{
				// this.$router.go();
				window.location.reload();
			});//e:ApiS_MakeCall
		},//e:btnRestoreSubscription
		

		btnUpdateProfile(){
			this.$store.commit('loaders/ADD_ID', 'App');

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				ApiS_MakeCall({
					body: {
						"alternativeEmails": this.$store.state.customer.alternativeEmails,
						"email": this.email,
						"firstName": this.firstName,
						"lastName": this.lastName,
						"institution": this.institution,
						"preferredFormatVersionID": this.preferredFormatVersionID,
					},
					method: 'PUT',
					responseType: 'text',
					url: 'v4/Customer',
				}).then((responseData)=>{
					// console.log('responseData');
					// console.log(responseData);
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.emitter.emit('globalToasterOpen',{
						textContent: 'Profile successfully updated',
					});

					this.$store.commit('loaders/REMOVE_ID', 'App');
				});//e:ApiS_MakeCall
				
			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});//e:AS_ValidateForm
		},//e:btnUpdateProfile
	},
	mounted() {
		// start off with a full sync - this will update the vuex store for this customer
		AS_SyncData().then(()=>{
			if(this.$store.state.customer.expirationDate && (new Date(this.$store.state.customer.expirationDate) < config.today)){
				// Account Expired
				this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
				document.documentElement.setAttribute('data-bs-theme', 'light')
				document.body.classList.remove('theme-serrena');

			} else {
				// Account is Fine for now
				this.email = this.$store.state.customer.email;
				this.firstName = this.$store.state.customer.firstName;
				this.institution = this.$store.state.customer.institution;
				this.lastName = this.$store.state.customer.lastName;

				this.preferredFormatVersionID = this.$store.state.customer.preferredFormatVersionID;

			}

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});

			this.$store.commit('loaders/REMOVE_ID', 'App');
		});	//e:AS_SyncData
	},
	beforeRouteLeave (to, from, next) {
		if(to.name !== 'AccountBilling' && to.name !== 'PerrlaOnline'){
			// before you leave this route, make sure the sidebar turns back on
			this.$store.commit('app/SET_IS_SIDEBAR_SHOWN', true);
		}
	
		next();
	},
	components: {
		NavTabContent,
		FormEmailsV2,
		FormInputV2,
		FormInputV2Display,
		FormRowSlot,
		FormSelectV2,
	}
}
</script>
