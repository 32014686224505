<!--
- StatusDot

-->

<template>
	<div :class="['flex-shrink-1 float-end pe-2 paper-status-wrp', {'cursor-pointer': isClickable}]">
		<div class="paper-status-dot" :title="calcPaperStatusTextOutput" @click.prevent="clickOnStatusDot">
			<div :class="calcPaperStatusDotClasses" />
		</div>
		<p class="paper-status-text text-success me-2" v-if="showPaperSavedText">
			Paper Saved
		</p>
	</div>
	
</template>

<script>
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';

export default {
	name: 'StatusDot',
	data() {
		return {
			showPaperSavedText: false,
			stoShowPaperSavedText: null, // a timer that tracks when to change the text next to the indicator dot
			isPaperSaving: false,	// BOOL - fires as an emit from SaveDocument before it starts actually trying to process the save
		}
	},
	computed:{
		calcPaperStatusDotClasses(){
			let returnArray = [''];
			
			if(this.isPaperSaving){
				returnArray.push('spinner-border text-success text-middle');
			} else {
				if(this.$store.state.paperEdit.isDocumentSaved){
					returnArray.push('bi bi-circle-fill text-success');
				} else if(this.$store.state.paperEdit.localVersion.isLocalStorageFresh){
					returnArray.push('bi bi-circle-fill text-warning');
				} else {
					returnArray.push('spinner-border text-middle');
				}
			}
			

			return returnArray;
		},//e:calcPaperStatusDotClasses

		calcPaperStatusTextOutput(){
			if(this.isPaperSaving){
				return 'Saving';
			} else {
				if(this.$store.state.paperEdit.isDocumentSaved){
					return 'Up to date';
				} else if(this.$store.state.paperEdit.localVersion.isLocalStorageFresh){
					return 'Changes saved locally';
				} else {
					return 'Unsaved changes';
				}
			}
		},//e:calcPaperStatusTextOutput

		isClickable(){
			if(!this.$store.state.paperEdit.isDocumentSaved){
				return true;
			}
			return false;
		},//e:isClickable
	},
	methods: {
		clickOnStatusDot(){
			if(this.isClickable){
				this.isPaperSaving = true;
				
				PS_BuildReferencesInPaper().then(()=>{
					PS_SaveDocument({
						message: 'Saved paper - manual save',
					}).then(()=>{
						this.emitter.emit('startPaperSavedTextTimer');
					});//e:PS_SaveDocument
				});//e:PS_BuildReferencesInPaper
			}
		},//e:clickOnStatusDot
	},
	created() {
		this.emitter.on('setSaveStatusToSaving', ()=>{
			this.isPaperSaving = true;

		});//e:setSaveStatusToSaving

		this.emitter.on('startPaperSavedTextTimer', ()=>{
			if(this.showPaperSavedText){
				clearTimeout(this.showPaperSavedText);
			}
			
			this.showPaperSavedText = true;
			this.isPaperSaving = false;

			this.showPaperSavedText = setTimeout(()=>{
				this.showPaperSavedText = false;
			}, 2448);
		});//e:startPaperSavedTextTimer
	},
	destroyed(){
		this.emitter.off('setSaveStatusToSaving');
		this.emitter.off('startPaperSavedTextTimer');
	},
}
</script>

<style lang="scss" scoped>
	@import "./../../../../assets/styles/vars_perrla.scss";
	
	@include color-mode(dark) {
	}

	div.paper-status-wrp {
		height: 19px;
		// overflow: hidden;
		display: block;

		div.paper-status-dot{
			display: block;
			font-size: 0.8rem;
			float:right;

			.spinner-border{
				--bs-spinner-width: 0.75rem;
				--bs-spinner-height: 0.75rem;
				--bs-spinner-border-width: 0.1em;
			}

		}
		
		p.paper-status-text{
			display: block;
			font-size: 0.8rem;
			float:right;
			margin: 0;
		}

	}
</style>