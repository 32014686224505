<!--
- Paper Edit > Editor Tab > Drawer
-- Edit a single genesis reference
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Edit Reference"
				close-to-route-name="EditorReferencesCitations" />
		</div>
		
		<div class="drawer-body">
			<edit-reference-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import EditReferenceBody from '@/modules/EditReferenceBody';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditReference',
	components: {
		EditReferenceBody,
		ToolDrawerTitle,
	}
}
</script>

