<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Table of Contents" />
		</div><!--drawer-header-->
		
		<div class="drawer-body">
			<p>{{ calcEditionFormat }} does not offer guidelines for creating a Table of Contents.</p>
			<p>PERRLA creates the Table of Contents by following the rules available in the {{ calcEditionFormat }} Manual for other text sections.</p>
			<p>The Table of Contents is built from the headings you add to your paper using PERRLA&#39;s&nbsp;<em>Text &amp;&nbsp;Heading Styles</em>&nbsp;menu button.</p>

			<div class="d-grid gap-2">
				<button type="button" class="btn btn-success" @click.prevent="addToc" v-show="!IncludeTableOfContents">
					Add Table of Contents
				</button>
				<button type="button" class="btn btn-outline-danger" @click.prevent="removeToc" v-show="IncludeTableOfContents">
					Remove Table of Contents
				</button>
				<button type="button" class="btn btn-success" @click.prevent="refreshToc" v-show="IncludeTableOfContents">
					Refresh Table of Contents
				</button>
			</div>

			<br /><br />

		</div><!--drawer-body-->
	</div>
</template>

<script>
import _has from 'lodash/has';
import ParseDocFormatType from '@/helpers/parse-doc-format-type';
import PS_SaveDocument from '@/services/paper/saveDocument';
import TDS_Open from '@/services/paper/toolDrawer/open';
import TOCS_AddToPaper from '@/services/paper/toc/addToPaper';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';
import Vue from 'vue';

export default {
	name: 'PaperSectionsToc',
	computed:{
		calcEditionFormat(){
			return ParseDocFormatType({
				paperFormatVersionID: this.$store.state.paperEdit.meta.PaperFormatVersionID,
				paperTypeEnum: this.$store.state.paperEdit.meta.PaperType,
				isFormatOnlyReturn: true,
			});
		},
		IncludeTableOfContents(){
			return this.$store.state.paperEdit.meta.IncludeTableOfContents;
		},
	},
	methods: {
		addToc(){
			TOCS_AddToPaper().then(()=>{
				this.emitter.emit('globalToasterOpen', {
					textContent: 'TOC added',
				});
			});
		},//e:addToc
		
		refreshToc(){
			this.$store.dispatch('paperEdit/toc/generateContent').then(()=>{
				this.emitter.emit('globalToasterOpen', {
					textContent: 'TOC refreshed',
				});
			});//e:dispatch:paperEdit/toc/generateContent
		},//e:refreshToc

		removeToc(){
			this.$store.commit('paperEdit/meta/SET_IncludeTableOfContents', false);

			PS_SaveDocument({
				isMetaDataChanges: true,
				message: 'Removed Table of Contents',
			}).then(()=>{
				Vue.nextTick(()=>{
					// scroll new toc page into view
					let $newInstnaceWrp = document.getElementById('instancePaperToc');
					if($newInstnaceWrp){
						$newInstnaceWrp.scrollIntoView('smooth');
					}

					// force focus on the paper body ckInstance
					if(_has(CKEDITOR.instances, 'ckPaperBody')){
						CKEDITOR.instances.ckPaperBody.focus();
					}

					this.emitter.emit('globalToasterOpen', {
						textContent: 'TOC removed',
						variant: 'danger'
					});
				});
			});
		},//e:removeToc
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorTableOfContents'
		});
	},
	components: {
		ToolDrawerTitle,
	}
}
</script>
