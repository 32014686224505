/*
- Reference Edit
-- also applies to editing a reference inside a paper!!!
-- ex: this.$store.state.referenceEdit.x
*/
export default {
	namespaced: true,

	state: {
		// local storage states

		// non local storage states
		openObject:	{},	// the reference open that is being edited
	},//e:state

	mutations: {
		SET_OPEN_OBJECT(state, value){
			state.openObject = value;
		},
	},//e:mutations
}