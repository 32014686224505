<!--
- Paper Edit > Write Tab > Abstract
-->

<template>
	<div>
		<div v-if="$store.state.paperEdit.meta.IncludeAbstract" key="divShowAbstractWrp" :class="calcInstanceStyles" id="instancePaperAbstract">
			<paper-running-head page-type="abstract" />

            <h1 :class="['text-center', {'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9}]">
				Abstract
			</h1>
			
			<br v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" />

			<textarea id="ckPaperAbstract" :value="paperAbstractContent" class="d-none" autocomplete="off" />

			<div class="form-group row" v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7">
				<label for="txtPaperAbstractKeywords " class="col-3 col-form-label fst-italic text-end pe-0">Keywords:</label>
				<div class="col-9 ps-0">
					<textarea class="form-control form-control-hover-border" id="txtPaperAbstractKeywords" ref="txtPaperAbstractKeywords" v-model="paperAbstractKeywords" rows="1" aria-label="Abstract Keywords" />
				</div>
			</div>

			<page-footer v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" page-type="abstract" />
			
		</div><!--divShowAbstractWrp-->

		<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID !== $enums.Format.MLA9" key="divAddAbstract">
			<div class="d-grid">
				<button class="btn btn-success btn-page-group mb-2" type="button" @click.prevent="addAbstract">
					Add Abstract 
				</button>
			</div>
		</div><!--divAddAbstract-->

	</div>
</template>

<script>
import config from '@/config';
import configCkEditor from '@/ck/config-ck-editor';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import PageFooter from '@/views/Paper/TabEditor/PageFooter';
import PaperRunningHead from '@/views/Paper/TabEditor/RunningHead';
import PCKS_OnChange from '@/services/paper/ck/onChange';
import PCKS_OnFocus from '@/services/paper/ck/onFocus';
import PCKS_NumberFigureCaptions from '@/services/paper/ck/numberFigureCaptions'
import PCKS_NumberTableCaptions from '@/services/paper/ck/numberTableCaptions'
import PCKS_wProofreaderDestroy from '@/services/paper/ck/wProofreaderDestroy';
import PCKS_wProofreaderInit from '@/services/paper/ck/wProofreaderInit';
import PS_SaveDocument from '@/services/paper/saveDocument';

export default {
	name: 'PaperAbstract',
	mixins: [mixinExpandTextareaById],
	data() {
		return {
			isGrammarlyChecked: true,	// should only happen once during first focus
			paperAbstractKeywords: '',
			paperAbstractContent: '',
		}
	},
	computed:{
		calcInstanceStyles(){
			let returnClassList = [
				'instance-abstract',
			]
			// MLA9 Abstract isn't separated, so it wont' have a page-group
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnClassList.push('page-group page-group-min-height', 'page-group-apa7');
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnClassList.push('page-group page-group-min-height', 'page-group-turabian9');
			}
			
			return returnClassList;
		},
	},
	methods: {
		addAbstract(){
			this.$store.commit('paperEdit/meta/SET_IncludeAbstract', true);

			PS_SaveDocument({
				isMetaDataChanges: true,
				message: 'Added Abstract',
			}).then((response)=>{
				this.paperAbstractContent = '';

				this.emitter.emit('globalToasterOpen', {
					textContent: 'Abstract added',
				});

				// nexttick is needed since this container show/hides the textarea for ckeditor to render
				this.$nextTick(()=>{
					this.loadAbstract(true);
				});
			});
		},//e:addAbstract

		loadAbstract(isNewAbstract) {
			this.paperAbstractContent = this.$store.state.paperEdit.abstract.content;
			this.paperAbstractKeywords = this.$store.state.paperEdit.abstract.keywords;
			
			// ck4 load as normal
			if(CKEDITOR.instances.ckPaperAbstract){
				// Ck Instance - set the content
				CKEDITOR.instances.ckPaperAbstract.setData(this.paperAbstractContent);
				
			} else {
				// No Ck Instance - make one
				var $this = this;

				let configCkPaperAbstract = configCkEditor.getSettings('ckPaperAbstract');
				configCkPaperAbstract.on = {
					change(evt) {
						PCKS_OnChange({
							editorName: evt.editor.name,
						});
					},//e:change
					
					focus(evt) {
						PCKS_OnFocus({
							editorName: evt.editor.name,
						});

						if(config.useWProofreader && $this.isGrammarlyChecked){
							setTimeout(() => {
								let $instancePaperAbstract = document.getElementById('instancePaperAbstract');
								let grammarlyExtensionElements = $instancePaperAbstract.getElementsByTagName('grammarly-extension');

								if(grammarlyExtensionElements.length > 0){
									// console.log('Grammarly ON');
									PCKS_wProofreaderDestroy();
								}
								$this.isGrammarlyChecked = false;
							}, 500);
						}

					},//e:focus

					instanceReady(evt) {
						evt.editor.$ckEditorType = config.enums.CkEditorType.ABSTRACT;

						PCKS_wProofreaderInit({
							container: evt.editor.window.getFrame().$,
						}).then(()=>{
							PCKS_NumberTableCaptions({
								ckEditor: evt.editor
							}).then(()=>{
								PCKS_NumberFigureCaptions({
									ckEditor: evt.editor
								}).then(()=>{

									CKEDITOR.on('dialogDefinition', function (ev) {
										ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
									});

									if(!isNewAbstract){
										$this.emitter.emit('loadCkBody');
									}

									$this.expandTextarea('txtPaperAbstractKeywords');

									// focus a new instance when created from button click (remove this when you have it loading from the paper content)
									if(isNewAbstract){
										evt.editor.focus();

										// scroll new abstract page into view
										let $newInstnaceWrp = document.getElementById('instancePaperAbstract');
										if($newInstnaceWrp){
											$newInstnaceWrp.scrollIntoView('smooth');
										}
									}

								});//e:PCKS_NumberFigureCaptions

							});//e:PCKS_NumberTableCaptions

						});//e:PCKS_wProofreaderInit
							
					},//e:instanceReady
				}//e:on
				
				CKEDITOR.replace('ckPaperAbstract', configCkPaperAbstract);
			}
		},//e:loadAbstract()
	},
	created() {
		this.emitter.on('addAbstract', ()=>{
            this.addAbstract();
		});
		
		// should $emit from TabEditor after PS_LoadByRoute is done
		this.emitter.on('loadCkAbstract', () => {
			if(this.$store.state.paperEdit.meta.IncludeAbstract){
				this.loadAbstract(false);	
			} else {
				this.emitter.emit('loadCkBody');
			}
		});
	},
	destroyed(){
		// store what "was" in the paper editor abstract before leaving the Editor tab
		this.$store.commit('paperEdit/abstract/CONTENT', (CKEDITOR.instances.ckPaperAbstract) ? CKEDITOR.instances.ckPaperAbstract.getData() : '');
		this.$store.commit('paperEdit/abstract/KEYWORDS', (CKEDITOR.instances.ckPaperAbstract) ? this.paperAbstractKeywords : '');

		if(CKEDITOR.instances.ckPaperAbstract){
			CKEDITOR.instances.ckPaperAbstract.focusManager.blur(true);
			CKEDITOR.instances.ckPaperAbstract.destroy(true);
		}

		this.emitter.off('addAbstract');
		this.emitter.off('loadCkAbstract');
	},

	components: {
		PageFooter,
		PaperRunningHead,
	}
}
</script>

<style lang="scss" scoped>
	@import "../../../assets/styles/vars_perrla.scss";
	@include color-mode(dark) {
		.instance-abstract {
			textarea {
				border: none;
				outline: none;
				background: #fff;
				color: $body-color;
			}
		}
	}
	.instance-abstract {
		label {
			color:$body-color;
			font-size: $paper-font-size;
		}

		textarea {
			border: none;
			outline: none;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			font-size: $paper-font-size;
			padding-top: 7px;
		}
		.form-control-hover-border {
			&:hover {
				background: $secondary-100;
			}
		}
	}
</style>