var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "container-fluid section px-4" }, [
    _c("div", { staticClass: "row d-none d-md-flex" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c("h4", [_vm._v("Support")]),
        _vm._v(" "),
        _c("ul", [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ContactSupport",
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tContact Support\n\t\t\t\t\t")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(3),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "grid" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", [
          _vm._v("Copyright © 2022 PERRLA, LLC. All Rights Reserved. "),
          _c("i", [_vm._v(_vm._s(_vm.$version))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("h4", [_vm._v("Company")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/about" } }, [
            _vm._v("\n\t\t\t\t\t\tAbout PERRLA\n\t\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/testimonials" } }, [
            _vm._v("\n\t\t\t\t\t\tTestimonials\n\t\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/group-sales" } }, [
            _vm._v("\n\t\t\t\t\t\tGroup Sales\n\t\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { attrs: { href: "///perrla.com/#/student-stress-relief" } },
            [_vm._v("\n\t\t\t\t\t\tStress-Relief Toolkit\n\t\t\t\t\t")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "https://perrla.zendesk.com/hc/en-us" } }, [
        _vm._v("Help Center"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us/categories/360000322513-Writing-Papers-",
          },
        },
        [_vm._v("Writing Tips & Guides")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-3" }, [
      _c("h4", [_vm._v("Legal")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/privacy-policy" } }, [
            _vm._v("\n\t\t\t\t\t\tPrivacy Policy\n\t\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/terms-and-conditions" } }, [
            _vm._v("\n\t\t\t\t\t\tTerms & Conditions\n\t\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "///perrla.com/#/trademarks" } }, [
            _vm._v("\n\t\t\t\t\t\tDisclaimers\n\t\t\t\t\t"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }