<!--
- Paper Edit > Organize Tab > Research Notes > Create Reference Step 3
-->
<template>
	<div>
		<h2 class="nodal-title">
			Create a reference
		</h2>
		
		<create-reference-step-3-third-party-body :is-drawer="false" />
	</div>
</template>

<script>
import CreateReferenceStep3ThirdPartyBody from '@/modules/CreateReferenceStep3ThirdPartyBody'

export default {
	name: 'PaperTabResearchCreateReferenceStep3ThirdParty',
	components: {
		CreateReferenceStep3ThirdPartyBody,
	}
}
</script>
