<!--
- Reference Edit
-
-->
<template>
	<div>
		<div class="row">
			<div class="col">
				<h1 class="float-start">
					<router-link :to="{name:'ReferenceList'}" class="hover-underline">
						References
					</router-link>
				</h1>
				<div class="float-end">
					<div class="dropdown float-end">
						<a class="btn-dropdown dropdown-toggle me-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							{{ preferredFormatVersionIdOutput }}
						</a>

						<ul class="dropdown-menu dropdown-menu-end">
							<li>
								<a
									:class="[
										'dropdown-item', {
											'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.APA7,
											'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.APA7
										}
									]"
									href="#"
									@click.prevent="setPreferredFormatVersionId($enums.Format.APA7)">
									APA 7
								</a>
							</li>
							<li>
								<a
									:class="[
										'dropdown-item', {
											'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.MLA9,
											'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.MLA9
										}
									]"
									href="#"
									@click.prevent="setPreferredFormatVersionId($enums.Format.MLA9)">
									MLA 9
								</a>
							</li>
							<li>
								<a
									:class="[
										'dropdown-item', {
											'dropdown-item-selected': $store.state.references.displayFormatVersionId === $enums.Format.Turabian9,
											'hover hover-primary': $store.state.references.displayFormatVersionId !==  $enums.Format.Turabian9
										}
									]"
									href="#"
									@click.prevent="setPreferredFormatVersionId($enums.Format.Turabian9)">
									Turabian 9
								</a>
							</li>
						</ul>
						
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<button class="btn btn-clipboard btn-sm btn-outline-primary float-end" type="button" @click.prevent="copyOutputReferenceValue">
					Copy Reference Text
				</button>
				<p class="reference-value-heading ms-3 me-3 mb-3 max-width-900" v-add-blank-target v-html="outputReferenceValue" />

				<nav-tabs>
					<nav-tab
						:to="{
							name:'ReferenceTabPapers'
						}">
						Papers
					</nav-tab>
					

					<nav-tab
						:to="{
							name:'ReferenceTabCitations'
						}">
						Citations
					</nav-tab>

					<nav-tab
						:to="{
							name:'ReferenceTabResearchNotes'
						}">
						Research
					</nav-tab>


					<nav-tab
						:to="{
							name:'ReferenceTabDetails'
						}">
						Details
					</nav-tab>
					

				</nav-tabs>

				<transition name="route-fade" mode="out-in" appear>
					<router-view />
				</transition>
			
			</div>
		</div>
	</div>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';
import AS_SyncData from '@/services/app/syncData';
import CopyToClipboard from 'copy-to-clipboard';
import NavTab from '@/components/NavTab';
import NavTabs from '@/components/NavTabs';
import RS_GetValueOutput from '@/services/reference/getValueOutput';

export default {
	name: 'ReferenceEdit',
	data(){
		return {
			
		}
	},
	computed:{
		preferredFormatVersionIdOutput(){
			switch(this.$store.state.references.displayFormatVersionId){
				case config.enums.Format.MLA9:
					return 'MLA 9';
				case config.enums.Format.Turabian9:
					return 'Turabian 9';
			}
			return 'APA 7';
		},
		outputReferenceValue(){
			let refValue = RS_GetValueOutput({
				displayFormatVersionId: this.$store.state.references.displayFormatVersionId,
				referenceData: this.referenceData,
			});
			if(refValue){
				return refValue;
			}
			return '';
		},
		referenceData(){
			return this.$store.state.referenceEdit.openObject;
		},
	},
	methods: {
		copyOutputReferenceValue(){
			let $this = this;
			
			// calculate this again incase there is a nickname
			let fullOutputReferenceValue = RS_GetValueOutput({
				displayFormatVersionId: this.$store.state.references.displayFormatVersionId,
				referenceData: this.referenceData,
			});

			CopyToClipboard(fullOutputReferenceValue, {
				format: 'text/html',
				onCopy(){
					$this.emitter.emit('globalToasterOpen',{
						textContent: 'Copied to clipboard',
					});
				},
			});
		},//e:copyOutputReferenceValue

		setPreferredFormatVersionId(enumUpdateTo){
			this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', enumUpdateTo);
		},//e:setPreferredFormatVersionId
	},
	mounted(){
		AS_SyncData({
			isStealthSync: true
		}).then(()=>{
			this.$store.commit('references/SET_DISPLAY_FORMAT_VERSION_ID', this.$store.state.customer.preferredFormatVersionID);
		});
		
	},
	components: {
		NavTab,
		NavTabs,
	},
	destroyed(){
		this.$store.commit('loaders/REMOVE_ID', 'App');
	},
}
</script>

<style lang="scss" scoped>
	.reference-value-heading {
		font-size: 19.2px;
		line-height: 32px;
	}
</style>