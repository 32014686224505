<!--
- Paper Edit > Write Tab > Table of Contents
-
-->

<template>
	<div>
		<div v-if="$store.state.paperEdit.meta.IncludeTableOfContents" key="divShowTocWrp" :class="calcInstanceStyles" id="instancePaperToc">
			
			<paper-running-head page-type="toc" />
			
			<h1 :class="['text-center', {'fw-bold': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7, 'fw-normal': $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.MLA9}]">
				{{ tocTitleDisplay }}
			</h1>
			
			<br v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" />
			
			<div v-html="$store.state.paperEdit.toc.content" id="ckTableOfContents" title="The page numbers will be included when you export or print your paper." />
			
			<page-footer v-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" page-type="toc" />

		</div>

		<div v-else-if="$store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.APA7 || $store.state.paperEdit.meta.PaperFormatVersionID === $enums.Format.Turabian9" key="divAddToc">
			<div class="d-grid">
				<button class="btn btn-success btn-page-group" type="button" @click.prevent="addToc">
					Add Table of Contents
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import config from '@/config';
import PageFooter from '@/views/Paper/TabEditor/PageFooter';
import PaperRunningHead from './RunningHead';
import TOCS_AddToPaper from '@/services/paper/toc/addToPaper';

export default {
	name: 'PaperToc',
	computed:{
		calcInstanceStyles(){
			let returnClassList = [
				'instance-toc',
			]
			// MLA9 Abstract isn't separated, so it wont' have a page-group
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
				returnClassList.push('page-group', 'page-group-min-height', 'page-group-apa7');
			} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
				returnClassList.push('page-group', 'page-group-min-height', 'page-group-turabian9');
			}
			
			return returnClassList;
		},
		tocTitleDisplay(){
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID && this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 ){
				return 'Contents';
			}
			return 'Table of Contents';
		},
	},
	methods: {
		addToc(){
			TOCS_AddToPaper();
		},//e:addToc
	},
	components: {
		PageFooter,
		PaperRunningHead,
	}
}
</script>

<style lang="scss">
	@import "../../../assets/styles/vars_perrla.scss";

	.instance-toc {
		.placeholder-text {
			font-family: $font-family-base;
			font-size: 15px;
			line-height: $line-height-base;
			text-align: center;
		}

		ul.dotted {
			max-width: 40em;
			padding: 0;
			overflow-x: hidden;
			list-style: none;

			li:before {
				float: left;
				width: 0;
				white-space: nowrap;
				content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
			}

			li {
				list-style: none !important;

				// &.toc-h1 {
				// }

				&.toc-h2 {
					margin-left: 20px;
				}

				&.toc-h3 {
					margin-left: 40px;
				}

				&.toc-h4 {
					margin-left: 60px;
				}

				&.toc-h5 {
					margin-left: 80px;
				}
			}
			span:first-child {
				padding-right: 0.25em;
				background: white;
			}
			span + span {
				float: right;
				padding-left: 0.25em;
				background: white;
			}
		}
		.btn-bottom-wrp {
			position: absolute;
			right: $page-margin;
			left: $page-margin;
		}
		
		// larger indents on the TOC headings
		&.page-group-turabian9{
			ul.dotted {
				li:first-child{
					&.toc-h1 {
						margin-top: 0 !important;
					}
				}
				li{
					&.toc-h1 {
						margin-top: 10px;
						font-weight: bold;
					}
					&.toc-h2 {
						margin-left: 50px;
					}
					&.toc-h3 {
						margin-left: 100px;
					}

					&.toc-h4 {
						margin-left: 150px;
					}

					&.toc-h5 {
						margin-left: 200px;
					}
				}
			}
		}

	} //.instance-toc

</style>