<!--
- DataDecoder
-- so far this only works for <citations> but include other types later
-->

<template>
	<div>
		<div class="row">
			<div class="col">
				<h1>Decode Data</h1>
				<hr />
			</div><!--col-->
		</div><!--row-->

		<div class="row">
			<div class="col">
				<div class="form-check">
					<input
						class="form-check-input"
						type="radio"
						id="Decoder_Radio_outline"
						value="outline"
						v-model="decodeType" /> 
					<label class="form-check-label" for="Decoder_Radio_outline">
						&lt;outline&gt;
					</label>
				</div>
				<div class="form-check">
					<input
						class="form-check-input"
						type="radio"
						id="Decoder_Radio_citations"
						value="citations"
						v-model="decodeType" /> 
					<label class="form-check-label" for="Decoder_Radio_citations">
						&lt;citations&gt;
					</label>
				</div>
			</div><!--col-->
		</div><!--row-->
			
		<div class="row">
			<div class="col">
				
				<div class="form-group">
					<label>
						Input:
					</label>
						
					<textarea
						class="form-control"
						ref="txtInput"
						rows="5" />
				</div>
				
				
				<button class="btn btn-outline-success w-100" type="button" @click.prevent="btnDecodeData">
					Decode Data
				</button>

				<div class="form-group" v-show="outputValue !== ''">
					<label>
						Output
					</label>
					<div class="d-block bg-light text-dark">

						<div class="p-3" ref="jsonOutput" />
					</div>
				</div>
			</div><!--col-->
		</div><!--row-->
		
	</div>
</template>

<script>
import jsonview from '@pgrabovets/json-view';

import Swal from 'sweetalert2';

export default {
	name: 'DataDecoder',
	props:{

	},
	data() {
		return {
			decodeType: 'outline',	// citations || outline 
			outputValue: '',
		}
	},
	computed:{

	},
	methods: {
		btnDecodeData(){
			let inputValue = this.$refs.txtInput.value.trim();

			this.outputValue = '';
			
			this.$nextTick(()=>{
				try{
					if(this.decodeType === 'citations'){
						this.outputValue = JSON.parse(decodeURIComponent(window.atob(inputValue)));
						
						// create json tree object
						const tree = jsonview.create(this.outputValue);
						jsonview.render(tree, this.$refs.jsonOutput);
						jsonview.expand(tree);

					} else if(this.decodeType === 'outline'){
						this.outputValue = decodeURIComponent(window.atob(inputValue));
						this.$refs.jsonOutput.innerHTML = this.outputValue;
					}
				} catch(error){
					console.log(error);

					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: '',
						text: error,
						icon: 'error',
						confirmButtonText: 'Got it - thanks!',
						showCloseButton: false,
						showCancelButton: false,
						showConfirmButton: true,
						customClass:{
							confirmButton: 'btn btn-success me-2',
						},
					});
				}

			});//nextTick
		},//e:btnDecodeData
	},
	created() {
	},
	mounted() {
	},
	components: {
	}
}
</script>
