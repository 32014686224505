<!--
- Add Payment
-->

<template>
	<generic-modal-wrap modal-id="AddPaymentMethod">
		<h2 class="nodal-title">
			Add Payment Method
		</h2>

		<modal-add-payment-method :is-modal="true" />

	</generic-modal-wrap>

</template>

<script lang="js">
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import ModalAddPaymentMethod from '@/modules/PaymentSettings';

export default {
	name: 'AddPaymentMethod',
	components:{
		GenericModalWrap,
		ModalAddPaymentMethod
	},
}
</script>
