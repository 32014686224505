<!-- 
- Based on vue-slide-up-down but with custom code, mainly nexttick in mounted 
- https://github.com/danieldiekmeier/vue-slide-up-down
-->
<template>
	<div ref="container" @transitionend="onTransitionEnd" :style="style">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'SlideUpDown',
	props:{
		active: {
			type: Boolean,
			default: false
		},
	},
	data(){
		return {
			duration: 250,
			initial: false,
			style: {},
		}
	},
	watch: {
		active () {
			this.layout();
		}	
	},
	methods: {
		layout () {
			if(this.$refs.container){
				if (this.active) {
					if (this.initial) {
						this.setHeight('0px', () => this.$refs.container.scrollHeight + 'px');
					}
				} else {
					this.setHeight(this.$refs.container.scrollHeight + 'px', () => '0px');
				}
			}
		},

		asap (callback) {
			if (!this.initial) {
				callback();
			} else {
				this.$nextTick(callback);
			}
		},

		setHeight (temp, afterRelayout) {
			this.style = { height: temp };

			this.asap(() => {
				// force relayout so the animation will run
				this.__ = this.$refs.container.scrollHeight;

				this.style = {
					height: afterRelayout(),
					overflow: 'hidden',
					'transition-property': 'height',
					'transition-duration': this.duration + 'ms'
				}
			});
		},

		onTransitionEnd () {
			if (this.active) {
				this.style = {};
			} else {
				this.style = {
					height: '0',
					overflow: 'hidden'
				}
			}
		}
	},
	mounted() {
		// using next tick here for references without any citations, once you add one i need that reference to stick open
		this.$nextTick(()=>{
			this.layout();
			this.initial = true;
		});
	},
}
</script>
