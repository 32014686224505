/*
- Annotations
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.annotations.x
*/
export default {
	namespaced: true,
	
	state:{
		isInclude:	false,	// Bool: show/hide annotations on the reference page
		inPaper: [],		// Array: each entry is an object for Annotation Data 
	},//e:state

	mutations: {
		ADD_TO_PAPER(state, value){
			state.inPaper.push(value);
		},
		CLEAR_IN_PAPER(state){
			state.inPaper = [];
		},
		IS_INCLUDE(state, value) {
			state.isInclude = value;
		},
		UPDATE_AT_INDEX(state, $opts){
			state.inPaper[$opts.index].content = $opts.content;
		},
	},//e:mutations
}
