var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: _vm.calcRowClasses }, [
    _c("td", { staticClass: "td-entity-title" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.calcVersionDate) + "\n\t"),
    ]),
    _vm._v(" "),
    _c("td", {
      staticClass: "word-break",
      domProps: { innerHTML: _vm._s(_vm.paperVersionData.action) },
    }),
    _vm._v(" "),
    _c("td", [
      _c(
        "a",
        {
          staticClass: "link-no-underline",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.openPDF.apply(null, arguments)
            },
          },
        },
        [_vm._v("Preview PDF")]
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _vm.isRestoreable
        ? _c(
            "a",
            {
              staticClass: "link-no-underline",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.restoreVersion.apply(null, arguments)
                },
              },
            },
            [_vm._v("Restore this version")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "td-more-menu" }, [
      _c("div", { staticClass: "dropstart" }, [
        _c("a", {
          class: ["svg bi-three-dots-vertical float-end"],
          attrs: {
            href: "#",
            "data-bs-toggle": "dropdown",
            "aria-expanded": "false",
          },
        }),
        _vm._v(" "),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openDownloadedDocument(
                      _vm.$enums.DownloadPaperContentType.WORD
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tDownload PERRLA Word File\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openDownloadedDocument(
                      _vm.$enums.DownloadPaperContentType.WORD_SIMPLE
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t\tDownload Simplified Word File\n\t\t\t\t\t"
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "dropdown-item hover hover-primary",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openDownloadedDocument(
                      _vm.$enums.DownloadPaperContentType.PDF
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\t\tDownload PDF\n\t\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }