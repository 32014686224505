<!--
- Settings Form Label and Input
-- For OrgUsers that might want to add another email
-->

<template>
	<div class="form-input-v2 row mb-3">
		<div class="col-label">
			<label
				class="form-label form-label-v2"
				:for="fullId"
				v-html="label" />
		</div>

		<div class="col-value">
			<!-- this is the primary email, make it readonly -->
			<input
				autocomplete="off"
				class="form-control mb-2"
				disabled
				type="text"
				:role="role"
				:value="orgEmail" />
			
			<div v-if="secondaryEmail != ''" key="divHasSecondaryEmail">
				<input
					autocomplete="off"
					type="text"
					:class="['form-control mb-2', {'is-invalid': isInvalidShown}]"	
					:id="fullId"
					:role="role"
					:value="secondaryEmail"
					@blur="blurInput($event.target.value)" />
					
				<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
					{{ invalidErrorMessage }}
				</div>
			</div><!--divHasSecondaryEmail-->

			<div v-else key="divElseSecondaryEmail">
				<div v-if="isNewFieldShown" key="divIsNewFieldShown">
					<input
						autocomplete="off"
						placeholder="Enter a new Email address"
						ref="userInputField"
						type="text"
						:class="['form-control mb-2', {'is-invalid': isInvalidShown}]"	
						:id="fullId"
						:role="role"
						:value="secondaryEmail"
						@blur="blurInput($event.target.value)" />

					<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
						{{ invalidErrorMessage }}
					</div>

					<div class="d-grid">
						<button type="button" class="btn btn-sm btn-hover btn-hover-danger" @click="btnCloseSecondaryEmail">
							<span class="svg bi-x-lg" /> Cancel
						</button>
					</div>		
				</div>
				<div v-else key="divElseIsNewFieldShown">
					<div class="d-grid">
						<button type="button" class="btn btn-sm btn-hover btn-hover-success" @click="btnAddSecondaryEmail">
							<span class="svg bi-plus-lg" /> Add Secondary Email
						</button>
					</div>				
				</div>
			</div><!--divElseSecondaryEmail-->

		</div><!--col-->

	</div>
	
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'FormEmailsV2',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isFullWidth:{
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		orgEmail:{
			required: true,
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		role: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			isNewFieldShown: false,	// true: text field to enter an email
			isShowSuggestion: false,
			secondaryEmail: '',
		}
	},
	computed:{
		fullId(){
			// generate one based on mixin if a specitic suffix isn't defined
			return this.safeId;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},

		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			// find this validation by form id
			let findValidation = this.$store.state.forms.validation.find((validationData)=>{
				return validationData.fullId === this.fullId
			});
			if(findValidation){
				return findValidation;
			} else {
				return null;
			}
		},
	},
	methods:{
		blurInput(value){
			this.secondaryEmail = value;

			AS_ValidateForm({
				singleFieldId: this.fullId
			}).then(()=>{
				// the user has blurred this field and it passed validation
				this.$store.commit('customer/PUSH_ONE_ALTERNATIVE_EMAIL', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:blurInput

		btnAddSecondaryEmail(){
			this.isNewFieldShown = true;
			this.$nextTick(()=>{
				if(this.$refs.userInputField){
					this.$refs.userInputField.focus();
				}
			})
			
		},//e:btnAddSecondaryEmail

		btnCloseSecondaryEmail(){
			// this.$refs.userInputField.blur();
			this.isNewFieldShown = false;
		},
	},
	mounted(){
		this.$store.dispatch('forms/addValidation', {
			formId: this.formId,
			fieldLabel: this.label,
			fullId: this.fullId,
			validateEnum: config.enums.Validation.EMAIL_REQUIRED,
		})
		if(!_isEmpty(this.$store.state.customer.alternativeEmails)){
			this.secondaryEmail = this.$store.state.customer.alternativeEmails[0];
		}
	},
}
</script>
