var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: [
        "tr-entity-grid-row tr-entity-grid-primary",
        { "tr-entity-grid-row-success": _vm.isComplete },
      ],
      on: {
        mouseover: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _c("td", { staticClass: "td-entity-title" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.decodePaperTitle) + "\n\t\t"),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c("td", { class: [{ "text-end": _vm.context === "ClassEdit" }] }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.outputLastModifiedDate) + "\n\t\t"),
      ]),
      _vm._v(" "),
      _c("td", { attrs: { width: "200px" } }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-hover btn-hover-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnDownloadPaper.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n                Download Paper (.docx)\n            ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }