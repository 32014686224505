var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group", attrs: { id: _vm.id } }, [
    _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "w-100" }, [
        _c("label", [_vm._v(_vm._s(_vm.label))]),
      ]),
      _vm._v(" "),
      _vm.isRemoveEndDate
        ? _c("div", { staticClass: "icon-row flex-shrink-1" }, [
            _c("div", { staticClass: "d-flex flex-row-reverse" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("end-date-remove")
                    },
                  },
                },
                [_c("span", { staticClass: "svg svg-delete bi-x-lg" })]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.helpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.helpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          "d-flex p-relative",
          { "datepicker-ref-wiz": _vm.isReferenceWizard },
        ],
      },
      [
        _c(
          "div",
          {
            class: [
              "ps-0",
              {
                "me-1": _vm.iOS || !_vm.isDateDisplayed || !_vm.isClearable,
                "form-control-fake w-100": !_vm.isReferenceWizard,
              },
            ],
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.m,
                  expression: "m",
                },
              ],
              ref: "month",
              class: [
                "form-control-stealth form-control-w2",
                {
                  "form-control me-2": _vm.isReferenceWizard,
                  "is-invalid-no-icon": _vm.isMonthInvalid,
                  "form-control-primary": _vm.isAllCommonUseShow(false),
                },
              ],
              attrs: {
                autocomplete: "off",
                maxlength: "2",
                placeholder: "mm",
                type: "text",
                id: _vm.calcIdMonth,
              },
              domProps: { value: _vm.m },
              on: {
                blur: function ($event) {
                  return _vm.dateBlur("m")
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.m = $event.target.value
                  },
                  function ($event) {
                    return _vm.dateInput("m")
                  },
                ],
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.dateBlur("m")
                },
              },
            }),
            _vm._v(" "),
            !_vm.isReferenceWizard
              ? _c(
                  "span",
                  {
                    class: [
                      "form-control-fake-text",
                      { placeholder: !_vm.isDateDisplayed },
                    ],
                  },
                  [_vm._v("/")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.d,
                  expression: "d",
                },
              ],
              ref: "day",
              class: [
                "form-control-stealth form-control-w2",
                {
                  "form-control me-2": _vm.isReferenceWizard,
                  "is-invalid-no-icon": _vm.isDayInvalid,
                  "form-control-primary": _vm.isAllCommonUseShow(true),
                },
              ],
              attrs: {
                autocomplete: "off",
                maxlength: "2",
                placeholder: "dd",
                type: "text",
                id: _vm.calcIdDay,
              },
              domProps: { value: _vm.d },
              on: {
                blur: function ($event) {
                  return _vm.dateBlur("d")
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.d = $event.target.value
                  },
                  function ($event) {
                    return _vm.dateInput("d")
                  },
                ],
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.dateBlur("d")
                },
              },
            }),
            _vm._v(" "),
            !_vm.isReferenceWizard
              ? _c(
                  "span",
                  {
                    class: [
                      "form-control-fake-text",
                      { placeholder: !_vm.isDateDisplayed },
                    ],
                  },
                  [_vm._v("/")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.y,
                  expression: "y",
                },
              ],
              ref: "year",
              class: [
                "form-control-stealth form-control-w4",
                {
                  "form-control": _vm.isReferenceWizard,
                  "form-control-primary": _vm.isCommonUseShow,
                },
              ],
              attrs: {
                autocomplete: "off",
                maxlength: "5",
                placeholder: "yyyy",
                type: "text",
                id: _vm.calcIdYear,
              },
              domProps: { value: _vm.y },
              on: {
                blur: function ($event) {
                  return _vm.dateBlur("y")
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.y = $event.target.value
                  },
                  function ($event) {
                    return _vm.dateInput("y")
                  },
                ],
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.dateBlur("y")
                },
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: ["btn btn-primary", { "ms-2": _vm.isDateDisplayed }],
            attrs: {
              type: "button",
              "data-bs-toggle": "tooltip",
              "data-bs-title": "Toggle Calendar",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleCalendar.apply(null, arguments)
              },
            },
          },
          [
            _c("span", {
              class: [
                "svg",
                {
                  "bi-calendar3": !_vm.showCalendar,
                  "bi-x-lg": _vm.showCalendar,
                },
              ],
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.iOS && _vm.isDateDisplayed && _vm.isClearable,
                expression: "!iOS && isDateDisplayed && isClearable",
              },
            ],
            staticClass: "flex-shrink-1",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-link hover-underline",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.clearDate.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tClear\n\t\t\t")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.iOS && !_vm.isDateDisplayed && _vm.isTodayButton,
                expression: "!iOS && !isDateDisplayed && isTodayButton",
              },
            ],
            staticClass: "flex-shrink-1",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-link hover-underline btn-today",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnSelectToday.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tToday\n\t\t\t")]
            ),
          ]
        ),
        _vm._v(" "),
        _c("picker-day", {
          attrs: {
            "calendar-id": _vm.calendarId,
            "has-selected": _vm.hasSelected,
            "selected-date": _vm.selectedDate,
            "show-calendar": _vm.showCalendar,
          },
          on: { selectDate: _vm.selectDate },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: ["invalid-feedback", { "d-block": _vm.isDateInvalid }] },
      [
        _vm._v(
          "\n\t\tPlease enter a full date or clear the field to remove the due date\n\t"
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { class: ["invalid-feedback", { "d-block": _vm.isError }] }, [
      _vm._v(
        "\n\t\tThe ending date cannot be before or the same as the starting date above.\n\t"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["invalid-feedback", { "d-block": _vm.isInvalidShown }] },
      [_vm._v("\n\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t")]
    ),
    _vm._v(" "),
    _vm.isAddEndDate
      ? _c("div", { staticClass: "d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-hover btn-hover-success",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("end-date-add")
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus-lg" }),
              _vm._v(" Add ending date\n\t\t"),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }