<!--
- Logout 
-- this will make a log out landing page in the SPA i can redirect to
-->

<template>
	<div class="min-height">
		<p>Logging out...</p>
	</div>
</template>

<script>
import AS_FullLogout from '@/services/app/fullLogout';
import config from '@/config';
// import CS_DeleteCookie from '@/services/app/deleteCookie';

export default {
	name: 'LogoutView',
	props:{

	},
	data() {
		return {
		}
	},
	computed:{

	},
	methods: {

	},
	created() {
		this.$store.commit('loaders/ADD_ID', 'App');
	},
	mounted() {
		AS_FullLogout().then(()=>{
			// console.log('delete cookie done, do not redirect');

			if(config.isDev){
				this.$router.push({
					name: 'Login'
				}).catch(()=>{});
			} else {
				// i have to use a hard redirect here to get from App to Public
				// window.location = config.appUrl + '/Public/#/login';
				if(config.isTest){
					window.location = '///testonline.perrla.com/Account/LogOff';
				} else {
					window.location = '///www.perrlacomplete.com/Account/LogOff';
				}
			}
		});//e:AS_FullLogout
	},
}
</script>
