<!--
- FreeTrialAlternateEmail
-
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0" style="background: url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff;">
			<main class="container-text mt-0 pt-3">

				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div class="text-center mb-5">
							<h1>PERRLA</h1>
						</div>

						<p>
							If you can't find our verification email or it was entered incorrectly, you can change the email for your Free Trial below.
						</p>

						<p class="fw-bold">
							The email you enter below will be the new email address you will use to log into PERRLA.
						</p>

						<form class="mt-4">
							<form-input
								label="Email"
								type="email"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED"
								:form-id="zFormId"
								v-model="email" />
						
							<div class="mt-4">
								<p>
									Here's the information we have for your account so far:<br />
										<span class="ms-4 fw-bold">Name:</span> {{ firstName }} {{ lastName }} <br />
										<span class="ms-4 fw-bold">Institution:</span> {{ school }} <br />
								</p>
							</div>

							<div class="form-group">
								<div class="d-grid">
									<button type="submit" class="btn btn-success" @click.prevent="btnUpdateEmail">
										Update my email
									</button>
								</div>
							</div>
						</form>

					</div>
				</div>
			</main>
		</section>

	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';

export default {
	name: 'FreeTrialAlternateEmail',
	data() {
		return {
			email: '',
			zFormId: 'formUpdateEmail',
		}
	},
	computed:{
		firstName(){
			return this.$store.state.freeTrial.firstName;
		},
		lastName(){
			return this.$store.state.freeTrial.lastName;
		},
		password(){
			return this.$store.state.freeTrial.password;
		},
		school(){
			return this.$store.state.freeTrial.school;
		},
	},
	methods: {
		btnUpdateEmail(){
			this.$store.commit('freeTrial/SET_EMAIL', this.email);

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');
				
				let sendBody = {
					email: this.email,
					firstName: this.firstName,
					lastName: this.lastName,
					password: this.password,
					platformID: config.platformId, 
					school: this.school,
				};
				
				// console.log('sendBody');
				// console.log(sendBody);

				// this.$store.commit('loaders/REMOVE_ID', 'App');

				ApiS_MakeCall({
					body: sendBody,
					ignoreAuth: true,
					method: 'POST',
					responseType: 'text',
					url: 'v4/FreeTrial',
				}).then((responseData)=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
					
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.$router.push({
						name: 'FreeTrialVerify',
						params: {
							verifyId: responseData
						}
					}).catch(()=>{});
					
				}).catch((error) =>{
					this.$store.commit('loaders/REMOVE_ID', 'App');
					console.log('error');
					console.log(error);
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
		},
	},
	mounted(){
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components: {
		FormInput
	}
}
</script>
