var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [_c("tool-drawer-title", { attrs: { title: "Create a Clean Copy" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _c("p", { staticClass: "org-size" }, [
        _vm._v(
          "\n\t\t\tCreate a clean version of your paper without Content Controls to turn into your professor (if you need it).\n\t\t"
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "d-grid mt-1" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.btnCreateCleanCopy.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\t\tCreate a clean copy\n\t\t\t")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "org-size" }, [
      _vm._v(
        "\n\t\t\tBy design, PERRLA generates citations and references as protected text inside "
      ),
      _c("em", [_vm._v("Content Controls")]),
      _vm._v(
        ".  To remove this protection and convert the data to plain text, click the button below.  We'll generate a clean, new document for you to save and give to your professor.\n\t\t"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }