<!--
- Paper Edit > Outline Tab > Last visited
-- used as a controller to direct the user to the last open drawer 
-->

<template>
	<div class="drawer">
		<div class="drawer-body">
			<p>Redirecting...</p>	
		</div>	
	</div>
</template>

<script>

export default {
	name: 'OutlineCitationLastVisitedDrawer',
	mounted() {
		if(this.$store.state.paperEdit.toolDrawer.lastOpenOutline === 'OutlineResearchNotes'){
			this.$router.push({
				name: 'OutlineResearchNotes'
			}).catch(()=>{});
		} else {
			this.$router.push({
				name: 'OutlineReferencesCitations'
			}).catch(()=>{});
		}
	},
}
</script>
