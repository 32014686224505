<!--
- Module > Papers > Create Reserch Paper
-- so far can be called from PaperList and Dashboard 2.0
-->

<template>
	<div>
		<h2 class="nodal-title">
			Create Research Paper
		</h2>
		<ul class="nav nav-tabs nav-tabs-module mb-3" v-if="paperType == $enums.PaperType.APA7_StudentPaper || paperType == $enums.PaperType.APA7_ProfessionalPaper">
			<li class="nav-item">
				<a href="#" @click.prevent="setIsStudentTab(true)" :class="['nav-link', {'nav-link-active': isStudentType}]">Student</a>
			</li>
			<li class="nav-item">
				<a href="#" @click.prevent="setIsStudentTab(false)" :class="['nav-link', {'nav-link-active': !isStudentType}]">Professional</a>
			</li>
		</ul>
		<form>
			<div v-if="paperType == $enums.PaperType.APA7_StudentPaper || paperType == $enums.PaperType.APA7_ProfessionalPaper">
				<div v-if="isStudentType" key="divStudentType">
					<p>Student Research Papers have a simpler format while still containing all of the elements students need.</p>
					<p>Your paper will have a Title Page, Body section, and separate Reference page. You can add a Table of Contents or Abstract as needed.</p>
				</div>

				<div v-else key="divProType">
					<p>Professional Research Papers are suitable for papers that will be submitted for publication.</p>
					<p>They have a Title Page, Abstract, Body section, and separate Reference page.</p>
				</div>
			</div>

			<form-select
				label="Paper Format"
				:select-object="paperFormatSelectList"
				v-model="paperType" />
			
			<form-input
				label="Title"
				placeholder="(required)"
				@input="calcRunningHead"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED"
				v-model="title" />

			<form-input-running-head
				v-if="!isStudentType" 
				:is-removeable="false"
				v-model="runningHead" />

			<form-input
				:label="calcAuthorFieldLabel"
				v-model="author" />

			<form-input
				v-show="paperType != $enums.PaperType.MLA9_AnnotatedBibliography && paperType != $enums.PaperType.MLA9_DiscussionPost && paperType != $enums.PaperType.MLA9_ReferenceList && paperType != $enums.PaperType.MLA9_ResearchPaper"
				label="Institution"
				v-model="institution" />
			
			<div v-if="isStudentType">
				<form-input-autocomplete
					label="Course Number & Name"
					placeholder="Type or select a class"
					:data-list="classListToSearch"
					:inject-value="injectClassSearchValue"
					@selected-from-search="selectedFromSearch" />

				<form-input
					label="Instructor"
					v-model="instructor" />
				
				<form-datepicker
					id="CRP_dueDate"
					label="Due Date"
					:paper-format="calcPaperFormatForDatePicker"
					@date-set="dueDateSet"
					@date-clear="dueDateClear" />

				<div v-if="paperType == $enums.PaperType.APA7_StudentPaper">
					<div class="form-group" v-show="!isStudentRunningHead" key="divNoStudentRunningHead">
						<label>Running Head</label>
						<div class="help-text">
							Not a standard part of a Student Research Paper
						</div>
						<div class="d-grid">
							<button type="button" class="btn btn-hover btn-hover-success" @click.prevent="runningHeadToggle">
								<span class="svg bi-plus-lg" /> Add running head
							</button>
						</div>
					</div>

					<form-input-running-head
						v-show="isStudentRunningHead" key="componentStudentRunningHead"
						:is-removeable="true"
						@running-head-toggle="runningHeadToggle"
						v-model="runningHead" />
				</div><!--if:APA7_StudentPaper-->
			</div><!--if:isStudentType-->

			<div v-if="!isStudentType">
				<div class="form-group">
					<label>
						Author's Note
					</label>
					<form-checkbox
						label="Include an Author's Note"
						v-model="isAuthorNote" />
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="button" class="btn btn-outline-danger" @click.prevent="emitter.emit('closeRouteModal')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid mt-2">
						<button type="submit" class="btn btn-success" @click.prevent="createPaper">
							Create Research Paper
						</button>
					</div>
				</div>
			</div><!--row-->
			
		</form>
	</div>
</template>

<script>
import _includes from 'lodash/includes';
import _sortBy from 'lodash/sortBy';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_LoadDatepicker from '@/services/app/loadDatepicker';
import AS_SyncData from '@/services/app/syncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormCheckbox from '@/components/form/Checkbox';
import FormDatepicker from '@/components/form/Datepicker';
import FormInput from '@/components/form/Input';
import FormInputAutocomplete from '@/components/form/InputAutocomplete';
import FormInputRunningHead from '@/components/form/InputRunningHead';
import FormSelect from '@/components/form/Select';
import HelperIsGenesisPaper from '@/helpers/is-genesis-paper';
import mixinCreateDocument from '@/mixins/create-document';
import TruncateRunningHead from '@/helpers/truncate-running-head';

export default {
	name: 'ModuleCreateReserchPaper',
	mixins: [mixinCreateDocument],

	data() {
		return {
			author: '',
			courseName: '',
			dueDate: '',
			injectClassSearchValue: '',
			institution: '',
			instructor: '',
			isAuthorNote: true,
			isRunningHeadLinked: true,	// defaults to true, if user types in Running Head make this false
			isStudentRunningHead: false,
			isStudentType: true,
			paperType: 0,
			runningHead: '',
			title: '',
			zFormId: 'formCreateResearchPaper'
		}
	},
	computed:{
		calcAuthorFieldLabel(){
			if(this.isStudentType){
				return 'Author';
			} else {
				return 'Author(s)';
			}
		},
		calcPaperFormatForDatePicker(){
			switch(parseInt(this.paperType)){
				case config.enums.PaperType.APA7_StudentPaper:
				case config.enums.PaperType.APA7_ProfessionalPaper:
					return config.enums.Format.APA7;
				case config.enums.PaperType.MLA9_ResearchPaper:
					return config.enums.Format.MLA9;
				case config.enums.PaperType.Turabian9_ResearchPaper:
					return config.enums.Format.Turabian9;
				default:
					return 0
			}
		},
		classListToSearch(){
			let returnArray = [];

			this.$store.state.classes.list.forEach((classData)=>{
				if(!classData.isArchived){
					returnArray.push({
						id: classData.classID,
						name: classData.name,
					})
				}
			});//forEach
			
			return _sortBy(returnArray, (gridData) => gridData.name.toLowerCase());
		},
		paperFormatSelectList(){
			if(this.isStudentType){
				// Student Options
				if(this.$isLive){
					return [
						{
							value: config.enums.PaperType.APA7_StudentPaper.toString(),
							name: "APA 7",
						},
						{
							value: config.enums.PaperType.MLA9_ResearchPaper.toString(),
							name: "MLA 9",
						},
						{
							value: config.enums.PaperType.Turabian9_ResearchPaper.toString(),
							name: "Turabian 9 - Footnotes",
						}
					];
				} else {
					return [
						{
							value: config.enums.PaperType.APA7_StudentPaper.toString(),
							name: "APA 7",
						},
						{
							value: config.enums.PaperType.MLA9_ResearchPaper.toString(),
							name: "MLA 9",
						},
						{
							value: config.enums.PaperType.Turabian9_ResearchPaper.toString(),
							name: "Turabian 9 - Footnotes",
						}
					];
				}
			} else {
				// Professional Options
				return [
					{
						value: config.enums.PaperType.APA7_ProfessionalPaper.toString(),
						name: "APA 7th Edition",
					},
				]
			}
		},
	},
	methods: {
		// Professional Papers calc the running head as the title types
		calcRunningHead(){
			if(!this.isRunningHeadLinked){
				return false;
			}
			this.runningHead = TruncateRunningHead(this.title);
		},

		createPaper(){
			let el = document.querySelector(':focus');
			if(el)el.blur();

			let _paperTypeInt = parseInt(this.paperType);

			// stop if the paperType is NOT in the enum list
			if(!_includes(config.enums.PaperType, _paperTypeInt)){
				console.error('paperType not found in Enums - CreateResearchPaper.vue');
				return false;
			}//e:if:!_paperTypeInt

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				// calculate Running Head Data
				let runningHeadData = '';
				if(this.isStudentRunningHead || !this.isStudentType){
					// only set if a Pro Paper, or a Student Paper with the running head specifically added
					runningHeadData = this.runningHead
				}
				
				let institutionDataOutput = '';
				if(this.paperType != config.enums.PaperType.MLA9_AnnotatedBibliography && this.paperType != config.enums.PaperType.MLA9_DiscussionPost && this.paperType != config.enums.PaperType.MLA9_ReferenceList && this.paperType != config.enums.PaperType.MLA9_ResearchPaper){
					institutionDataOutput = this.institution;
				}
			
				// main object passed to paper service create
				let sendData = {
					'Author': this.author,
					'Course': this.courseName,
					'DueDate': this.dueDate,
					'Institution': institutionDataOutput,
					'IncludeAuthorNote': this.isAuthorNote,
					'PaperType': _paperTypeInt,
					'RunningHead': runningHeadData,
					'Teacher': this.instructor,
					'Title': this.title,
					'UseNewCitationMode': true,
				};

				// append data if there should be an accociated class behind the scenes
				if(this.associatedClassId !== 0){
					sendData.ClassID = this.associatedClassId;
					sendData.AssociatedEntityID = this.associatedClassId;
					sendData.AssociatedEntityName = this.associatedClassName;
					sendData.AssociatedEntityTypeID = 2; // class entity type id is 2 in the old vmWare system
				}

				// append data if there should be an accociated class behind the scenes (from new beta Droplist)
				if(this.$route.params.associatedClassId !== 0){
					// look this class up by it's ID
					let findThisClassData = this.$store.state.classes.list.find((classData)=>{
						return classData.classID === this.$route.params.associatedClassId;
					})
					if(findThisClassData){
						sendData.AssociatedEntityID = this.$route.params.associatedClassId;
						sendData.AssociatedEntityName = findThisClassData.name;
						sendData.AssociatedEntityTypeID = 2; // class entity type id is 2 in the old vmWare system
						sendData.ClassID = this.$route.params.associatedClassId;
					}
				}
				
				// console.log('sendData');
				// console.log(sendData);

				ApiS_MakeCall({
					body: sendData,
					method: 'POST',
					responseType: 'json',
					url: 'v3/Paper',
				}).then((responseData)=>{
					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					// paper created in the database - redirect to the right location
					if(HelperIsGenesisPaper.checkPaperType(_paperTypeInt)){
						this.$router.push({
							name: 'EditorReferencesCitations',
							params:{
								encryptedId: responseData.encryptedPaperID
							},
						}).catch(()=>{});
					} else {
						// APA6 | MLA8 - redirect out of SPA
						window.location = '/Papers/Write/' + responseData.encryptedPaperID;
					}
				});//e:ApiS_MakeCall

			}).catch((error)=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				
			});//e:AS_ValidateForm
		},//e:createPaper
		dueDateClear(){
			this.dueDate = '';
		},
		dueDateSet(newDate){
			this.dueDate = newDate;
		},
		// Student Papers can add an optional running head
		runningHeadToggle(){
			this.isStudentRunningHead = !this.isStudentRunningHead;
		},
		selectedFromSearch($opts){
			this.$route.params.associatedClassId = $opts.selectedId;

			if($opts.selectedId === 0){
				this.courseName = $opts.selectedText;
			} else {
				// look up this selection option from the class list and use it to populate instructor
				let findThisClassData = this.$store.state.classes.list.find((classData)=>{
					return classData.classID === $opts.selectedId;
				})
				if(findThisClassData){
					this.instructor = findThisClassData.teacherName;
					this.courseName = findThisClassData.name;
				}
			}
		},//e:selectedFromSearch
		setIsStudentTab(isStudentType){
			this.isStudentType = isStudentType;
		
			// set default paper type;
			if(this.isStudentType){
				this.paperType = config.enums.PaperType.APA7_StudentPaper.toString();
				
				// let the ui catch up so CRP_dueDate renders on the screen
				this.$nextTick(()=>{
					AS_LoadDatepicker({
						id: 'CRP_dueDate',
						loadDate: this.dueDate,
						isRefWizard: false,
						paperFormat: config.enums.Format.APA7,
					});
				});
			} else {
				this.paperType = config.enums.PaperType.APA7_ProfessionalPaper.toString();
			}
		},
	},
	mounted() {
		// refresh here to keep the modal open
		if(this.$route.meta.isPaperCreate){
		
			this.isStudentType = true;

			this.emitter.emit('openRouteModal', {
				modalRoute: this.$route.name,
				modalParams: {
					associatedClassId: '0',
				},
			});

			// i need to call sync here again to make sure institution fully loads 
			AS_SyncData().then(()=>{
				// console.log('sync done');
				this.author = this.$store.state.customer.fullName;
				this.institution = this.$store.state.customer.institution;
				
				// use preferredFormatVersionID to calculate a default paper id
				switch(this.$store.state.customer.preferredFormatVersionID){
					case config.enums.Format.MLA9:
						this.paperType = config.enums.PaperType.MLA9_ResearchPaper.toString();
						break;
					case config.enums.Format.Turabian9:
						this.paperType = config.enums.PaperType.Turabian9_ResearchPaper.toString();
						break;
					default: // APA7
						this.paperType = config.enums.PaperType.APA7_StudentPaper.toString();
				}

				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormId
				});
				
				this.$store.commit('loaders/REMOVE_ID', 'App');

				// auto fill form fields (data is filled in from create-document.js)
				// the setimeout is just to make sure i have data to fill in
				setTimeout(()=>{
					if(this.$route.params.associatedClassId != '0'){
						let findThisClassData = this.$store.state.classes.list.find((classData)=>{
							return classData.classID === parseInt(this.$route.params.associatedClassId);;
						})
						if(findThisClassData){
							this.injectClassSearchValue = findThisClassData.name;
						}
					}

					this.courseName = this.associatedClassName;
					this.instructor = this.fillInTeacherName;
				}, 666);
			
			});	
		}
	},
	components: {
		FormCheckbox,
		FormDatepicker,
		FormInput,
		FormInputRunningHead,
		FormInputAutocomplete,
		FormSelect
	}
}
</script>
