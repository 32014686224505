<!--
- Paper Write > Write Tab
-->

<template>
	<nav-tab-content>
		<h2>Download Paper</h2>
		
		<table class="table table-sm table-borderless mb-0 align-middle">
			<tbody>
				<tr v-if="$store.state.paperEdit.meta.DocumentType === $enums.DocumentType.DISCUSSION_POST">
					<td class="ps-0" width="350px">
						<div class="d-grid">
							<button type="button" class="btn btn-success" @click.prevent="copyDiscussionPostToClipboard">
								Copy paper to clipboard
							</button>
						</div>
					</td>
					<td>
						<p class="mb-0">
							Copy your paper's content to the clipboard so it's easy to paste into an online forum.
						</p>
					</td>
				</tr>
				<tr>
					<td class="ps-0" width="350px">
						<div class="d-grid">
							<button type="button" class="btn btn-success" @click.prevent="btnDownloadedDocument($enums.DownloadPaperContentType.WORD_SIMPLE)">
								Download Simplified Word File (.docx)
							</button>
						</div>
					</td>
					<td>
						<p class="mb-0">
							Great option for turning in a paper to your teacher!
						</p>
					</td>
				</tr>
				<tr>
					<td class="ps-0">
						<div class="d-grid">
							<button type="button" class="btn btn-success" @click.prevent="btnDownloadedDocument($enums.DownloadPaperContentType.WORD)">
								Download PERRLA Word File (.docx)
							</button>
						</div>
					</td>
					<td>
						<p class="mb-0">
							Use this if you want to keep working on it with PERRLA for Word.
						</p>
					</td>
				</tr>
				<tr>
					<td class="ps-0">
						<div class="d-grid">
							<button type="button" class="btn btn-success" @click.prevent="btnDownloadedDocument($enums.DownloadPaperContentType.PDF)">
								Download as PDF (.pdf)
							</button>
						</div>
					</td>
					<td>
						<p class="mb-0">
							PDF's are great for static files, sharing, &amp; printing.
						</p>
					</td>
				</tr>
			</tbody>
		</table>

		<br /><br /><br />
		
		<h2>Print Paper</h2>
		<button type="button" class="btn btn-primary" @click.prevent="btnPrintPdf">
			Print my paper
		</button>

		<br /><br /><br />

		<form>
			<h2>Email Paper</h2>

			<form-input
				label="File Name"
				help-text="You can give your paper a custom name, different from the paper's title."
				v-model="fileName"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.REQUIRED" />
			
			<form-input
				label="Email"
				help-text="Enter the email address we should send the paper to."
				v-model="emailAddress"
				:form-id="zFormId"
				:validate-enum="$enums.Validation.EMAIL_REQUIRED" />

			<div class="form-group mb-0 py-2">
				<label>Select a format</label>
				<div class="help-text mb-1">
					Choose the format for your attached paper.	
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="Export_Radio_Word"
								:value="0"
								v-model="emailExportType" /> 
							<label class="form-check-label" for="Export_Radio_Word">
								Word file (.docx)
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="Export_Radio_Pdf"
								:value="1"
								v-model="emailExportType" /> 
							<label class="form-check-label" for="Export_Radio_Pdf">
								PDF (.pdf)
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="Export_Radio_SimpleWord"
								:value="2"
								v-model="emailExportType" /> 
							<label class="form-check-label" for="Export_Radio_SimpleWord">
								Simplified Word file (.docx)
							</label>
						</div>
					</div>
				</div>
			</div>
			
			<button type="submit" class="btn btn-primary" @click.prevent="sendEmailAsAttachment">
				Email as attachment
			</button> 
		</form>
	</nav-tab-content>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import PS_CopyDiscussionPostToClipboard from '@/services/paper/copyDiscussionPostToClipboard';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import NavTabContent from '@/components/NavTabContent';

export default {
	name: 'TabExport',
	data() {
		return {
			emailAddress: '',
			emailExportType: 0,
			fileName: '',
			zFormId: 'formDocumentExport'
		}
	},
	methods: {
		copyDiscussionPostToClipboard(){
			PS_CopyDiscussionPostToClipboard();
		},
		btnDownloadedDocument(contentType){
			PS_DownloadDocumentVersionV4({
				contentType: contentType,
				platform: 1, // just Online Papers here
			});
		},
		btnPrintPdf(){
			// console.log('btnPrintPdf');
			this.$store.commit('loaders/ADD_ID', 'App');
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.PDF,
				isPrint: true,
				platform: 1, // just Online Papers here
			});
		},
		sendEmailAsAttachment(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{

				this.$store.commit('loaders/ADD_ID', 'App');
	
				this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
					formId: this.zFormId
				});

				let params = {
					email: this.emailAddress,
					fileName: this.fileName,
					paperId: this.$store.state.paperEdit.meta.PaperID
				}

				if(this.emailExportType === 0){
					// Word Regular
					params['format'] = 1;
					params['normalDocument'] = false;
				} else if(this.emailExportType === 1){
					// PDF
					params['format'] = 3;
				} else if(this.emailExportType === 2){
					// Word Simple
					params['format'] = 1;
					params['normalDocument'] = true;
				}

				ApiS_MakeCall({
					params,
					method: 'GET',
					url: 'v3/EmailPaper',
				}).then(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');

					this.emitter.emit('globalToasterOpen',{
						textContent: 'Email successfully sent',
					});
				});//e:ApiS_MakeCall

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm

		},//e:sendEmailAsAttachment
	},
	mounted() {
		// refreshing page off the Editor Tab > force user back to it
		if(!this.$store.state.paperEdit.ck.isEditorLoadedOnce){
			this.$router.push({
				name:'EditorReferencesCitations'
			}).catch(()=>{});
		}
		
		this.emailAddress = this.$store.state.customer.email;
		this.fileName = this.$store.state.paperEdit.meta.FileName; // console.log(this.$store.state.paperEdit.meta.Title);

		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
	},
	components: {
		FormInput,
		NavTabContent,
	},
}
</script>
