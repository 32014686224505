<!--
- Paper Footnotes Dropdown
-- Right Click on each footnote row
-- make these look like Ck Context Menu Items
-->

<template>
	
	<div class="footnote-dropdown" v-show="isOpen" v-on-clickaway="closeMenu">
		<a class="footnote-dropdown-item" href="#" @click.prevent="showCitationInPaper">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/showinpaper.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Show in Paper
			</span>
		</a>

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnEditFootnote('citation')">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/edit.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Edit Footnote Citation
			</span>
		</a>

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnEditFootnote('text')">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/edit.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Edit Footnote Text
			</span>
		</a>

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnDeleteCitation">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/delete.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Delete Citation
			</span>
		</a>

		<span class="footnote-dropdown-separator" />

		<a class="footnote-dropdown-item" href="#" @click.prevent="btnEditReference">
			<span class="footnote-dropdown-item-color-block">
				<img src="/static/ckeditor/plugins/perrlaReferences/icons/hidpi/edit.png" />
			</span>
			<span class="footnote-dropdown-item-text">
				Edit Reference
			</span>
		</a>

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import config from '@/config';
import { mixin as clickaway } from 'vue-clickaway';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';

export default {
	name: 'PaperFootnotesDropdown',
	mixins: [clickaway],
	computed:{
		citationElementInPaper(){
			return this.$store.state.paperEdit.citations.elementInPaper;
		},
	},
	data(){
		return {
			firstCitationUniqueId: null,
			isOpen: false,
		}
	},
	methods: {
		btnDeleteCitation(){
			this.setFirstCitationUniqueId();
			
			if(this.firstCitationUniqueId){
				this.$store.dispatch('paperEdit/citations/setCitationElementIfActive', {
					findById: this.firstCitationUniqueId,
				}).then(()=>{
					let citationCopyContent;
					let saveMessage = '';	// resolves back so SaveDocument has a message of what was deleted

					// citation is active in paper - remove the ck element (this should technically always be the case if deleteing from here)
					// console.log('citation is active in paper - remove the ck element');

					// Single

					// remove citation from vuex store
					let findCitationIndex = this.$store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
						return citation.citationData.citationUniqueID.toUpperCase() === this.firstCitationUniqueId.toUpperCase();
					});
					// console.log(findCitationIndex);

					if(findCitationIndex !== -1){
						citationCopyContent = _cloneDeep(this.$store.state.paperEdit.citations.inPaper[findCitationIndex]);
						this.$store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
					}
							
					// remove footnote from vuex store
					if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
						this.$store.commit('paperEdit/footnotes/REMOVE_AT_CITATION_UID', this.firstCitationUniqueId.toUpperCase());
					}

					// remove the ck element
					if (this.citationElementInPaper && this.citationElementInPaper.getParent()) {
						if(this.$store.state.paperEdit.config.renderCitationsAsFootnotes){
							// footnotes have a superscript number instead of a citation value
							if(_has(citationCopyContent, 'turabian9') && _has(citationCopyContent.turabian9, 'first')){
								saveMessage = citationCopyContent.turabian9.first;
							} else {
								saveMessage = 'Turabian 9 Citation';
							}

							let $elToInsert = new CKEDITOR.dom.element('span');
							$elToInsert.setText('CITATION DELETED');
							$elToInsert.replace(this.citationElementInPaper.getParent());
						} else {
							saveMessage = this.citationElementInPaper.getHtml();
						}
					
					} else {
						console.log('There was a problem removing the citation from this paper');
					}

					PS_BuildReferencesInPaper().then(()=>{
						PS_SaveDocument({
							message: 'Deleted Citation - ' + saveMessage,
						}).then(()=>{
							this.closeMenu();
						});
					});//e:PS_BuildReferencesInPaper

				});//e:dispatch:paperEdit/citations/setCitationElementIfActive
			}
		},//e:btnDeleteCitation

		btnEditFootnote(editType){
			this.setFirstCitationUniqueId();
			
			if(this.firstCitationUniqueId){
				this.$router.push({
					name:'EditorCitationEdit',
					params: {
						citationUid: this.firstCitationUniqueId,
						openCitationTab: (editType === 'text') ? config.enums.CitationTab.FOOTNOTE : config.enums.CitationTab.CITATION,
					}
				}).catch(()=>{});

				this.$nextTick(()=>{
					this.closeMenu();
				});

			} else {
				this.closeMenu();

			}
		},//e:btnEditFootnote

		btnEditReference(){
			this.setFirstCitationUniqueId();
			
			if(this.firstCitationUniqueId){
				// look up this citation (so i can get it's reference uid)
				let citationFind = this.$store.state.paperEdit.citations.inPaper.find((citationObject)=>{
					return citationObject.citationData.citationUniqueID.toUpperCase() === this.firstCitationUniqueId.toUpperCase();
				});
				if(citationFind && citationFind.citationData.referenceUniqueID && citationFind.citationData.referenceUniqueID !== ''){
					this.$router.push({
						name: 'EditorEditReference',
						params:{
							referenceUid: citationFind.citationData.referenceUniqueID
						}
					}).catch(()=>{});

					this.$nextTick(()=>{
						this.closeMenu();
					});
				}	
			}
		},//e:btnEditReference

		closeMenu(){
			this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_OPEN_ON_CITATION_FIND_BY_ID', '');
			this.isOpen = false;
		},//e:closeMenu

		positionMenu(findById){
			let $footnoteRow = document.querySelector('div[citation-find-by-id="' + findById + '"]');

			if($footnoteRow){
				this.$store.commit('paperEdit/footnotes/SET_CONTEXT_MENU_OPEN_ON_CITATION_FIND_BY_ID', findById);
			
				let rect = $footnoteRow.getBoundingClientRect();
				
				if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
					this.$el.style.top = rect.top + 'px';
				} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
					// menu renders off the bottom of the screen - do a better calculation
					this.$el.style.top = rect.top + 'px';
				}

				this.$el.style.left = (rect.right - 40) + 'px';
			}
		},//e:positionMenu

		setFirstCitationUniqueId(){
			let $paperFootnotesBlock = document.getElementById('paperFootnotesBlock');

			if($paperFootnotesBlock){
				let $openFootnoteElement = $paperFootnotesBlock.querySelector('div.paper-footnote-menu-open');
				if($openFootnoteElement){
					let citationFindById = $openFootnoteElement.getAttribute('citation-find-by-id');
					if(citationFindById){
						// console.log('this.$store.state.paperEdit.citations.inPaper');
						// console.log(this.$store.state.paperEdit.citations.inPaper);
						
						// loop through citations for this paper - i need to know if i'm on a group or not
						_forEach(this.$store.state.paperEdit.citations.inPaper, (citationObject)=>{
							// console.log('citationObject');
							// console.log(citationObject);

							if(_has(citationObject, 'groupUniqueID')){
								// group
								if(citationObject.groupUniqueID.toUpperCase() === citationFindById.toUpperCase()){
									this.firstCitationUniqueId = citationObject.citationData.citationUniqueID.toUpperCase();
									return false;
								}
							} else {
								// single
								if(citationObject.citationData.citationUniqueID.toUpperCase() === citationFindById.toUpperCase()){
									this.firstCitationUniqueId = citationObject.citationData.citationUniqueID.toUpperCase();
									return false;
								}
							}
						});//e:forEach:citations.inPaper
					}//e:citationFindById
				}//e:$openFootnoteElement
			}//e:$paperFootnotesBlock
		},//e:setFirstCitationUniqueId

		showCitationInPaper(){
			this.setFirstCitationUniqueId();
			
			// console.log('this.firstCitationUniqueId');
			// console.log(this.firstCitationUniqueId);

			if(this.firstCitationUniqueId){
				this.$store.dispatch('paperEdit/citations/setCitationElementIfActive', {
					findById: this.firstCitationUniqueId,
				}).then(()=>{
					let $thisEditor = this.citationElementInPaper.getEditor(false);

					if($thisEditor){
						this.closeMenu();
						this.citationElementInPaper.$.scrollIntoView();
						$thisEditor.getSelection().selectElement(this.citationElementInPaper)
					}
				});
			} else {
				this.closeMenu();
			}//e:if.this.firstCitationUniqueId
		},//e:showCitationInPaper
	},
	created(){
		this.emitter.on('closeAllFootnoteContextMenus', ()=>{
			this.closeMenu();
		});

		this.emitter.on('showPaperFootnoteContextMenu', ($opts)=>{
			let $paperFootnotesBlock = document.getElementById('paperFootnotesBlock');
			
			if($paperFootnotesBlock){
				let $openFootnoteElement = $paperFootnotesBlock.querySelector('div.paper-footnote-menu-open');

				if($openFootnoteElement){
					// one open already - either move it (already open on different row) or close it (already open on this row)
					// is this menu open on the same row or a different one?
					if($opts.findById === this.$store.state.paperEdit.footnotes.contextMenuOpenOnCitationFindById){
						// same row click - close the menu
						this.closeMenu();
					} else {
						// different row click - just move it to the right new place
						this.positionMenu($opts.findById);
					}

				} else {
					// not one open 
					this.isOpen = true;
					
					this.positionMenu($opts.findById);

				}//e:if:openFootnoteElement

			}//e:if:$footnotesBlock
		
		});//e:showPaperFootnoteContextMenu

	},
	destroyed(){
		this.emitter.off('closeAllFootnoteContextMenus')
		this.emitter.off('showPaperFootnoteContextMenu');
	}
}
</script>


<style lang="scss" scoped>
@import "../../../../assets/styles/_vars_perrla.scss";

$row-height: 25px;	// height of a single row inside the dropdown

.footnote-dropdown{
	border: 1px solid #e9e9e9;
	width: 165px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;

	.footnote-dropdown-item {
		background: #fff;
		color: $body-color;
		display: block;
		overflow: hidden;
		text-decoration: none;
		
		.footnote-dropdown-item-color-block {
			background: #f8f8f8;
			display: block;
			float:left;
			height: $row-height;
			margin-right: 5px;
			width: $row-height;

			img {
				margin-left: 5px;	// seems like (row-height - 16px) / 2
				height: 16px;
				width: 16px;
			}
		}

		.footnote-dropdown-item-text{
			display: block;
			font-size: 12px;
			font-family: Arial, Helvetica, Tahoma, Verdana, Sans-Serif;
			line-height: $row-height;
			text-indent: 0;
		}

		&:hover {
			.footnote-dropdown-item-color-block, .footnote-dropdown-item-text{
				background: #e9e9e9;
			}
		}

	}//.footnote-dropdown-item

	.footnote-dropdown-separator {
		background-color: #d1d1d1;
		display: block;
		height: 1px
	}
	


}//.footnote-dropdown


</style>