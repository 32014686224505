<!--
- Tags - Tagify 
-- modified to a textrea on 2024.09.012
- https://github.com/yairEO/tagify
-->

<template>
	<div class="form-group">
		<label :for="$data['safeId']">
			Tags
		</label>
		<div class="help-text mb-1">
			Type your tags, separated by a comma.
		</div>
		<textarea
			autocomplete="off"
			class="form-control"
			ref="focusElement"
			rows="1"
			:id="$data['safeId']"
			:pendo-grab-text="pendoGrabText"
			:value="value"
			@input="typeInput($event.target.value)" />
	</div>
</template>

<script>
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import mixinId from '@/mixins/id';

export default {
	name: 'FormTags',
	mixins: [mixinExpandTextareaById, mixinId],
	props:{
		formId: {
			type: String,
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		value: {
			type: String,
		},
	},
	methods: {
		typeInput(value){
			this.expandTextarea('focusElement');
			this.$emit('input', value);
		}//e:typeInput
	},
	created(){
		// burried under a next tick so the mixin refernece type can fully mount
		this.emitter.on('expandTextareaBus', ()=>{
			this.$nextTick(()=>{
				this.expandTextarea('focusElement');
			});
		});
	},
	mounted(){
		this.$nextTick(()=>{
			this.expandTextarea('focusElement');
		});
	},
	destroyed(){
		this.emitter.off('expandTextareaBus');
	},
}
</script>
