var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "org-size" }, [
        _vm._v("\n\t\tStep 2 of 2: Confirm your reference\n\t"),
      ]),
      _vm._v(" "),
      _c("reference-output"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("h3", [_vm._v("Optional Content")]),
          _vm._v(" "),
          _c("form-input", {
            attrs: {
              label: "Nickname",
              "help-text": "Optional. Shows in Library & Panel.",
              "pendo-grab-text": "Txt_RefCreate_Nickname",
            },
            model: {
              value: _vm.$data["nickname"],
              callback: function ($$v) {
                _vm.$set(_vm.$data, "nickname", $$v)
              },
              expression: "$data['nickname']",
            },
          }),
          _vm._v(" "),
          _c("form-textarea", {
            attrs: {
              label: "Description",
              "help-text": "Add a private description to your Reference.",
              "pendo-grab-text": "Txt_RefCreate_Description",
            },
            model: {
              value: _vm.$data["description"],
              callback: function ($$v) {
                _vm.$set(_vm.$data, "description", $$v)
              },
              expression: "$data['description']",
            },
          }),
          _vm._v(" "),
          _c("form-tags", {
            attrs: { "pendo-grab-text": "Txt_RefCreate_Tags" },
            model: {
              value: _vm.$data["tags"],
              callback: function ($$v) {
                _vm.$set(_vm.$data, "tags", $$v)
              },
              expression: "$data['tags']",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { staticClass: "d-grid" },
            [
              _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.EDITOR
                ? _c(
                    "router-link",
                    {
                      key: "routerLinkTabEditor",
                      staticClass: "btn btn-outline-primary",
                      attrs: { to: { name: "EditorConvertReferenceStep1" } },
                    },
                    [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                  )
                : _vm.$router.currentRoute.meta.tab === _vm.$enums.Tab.OUTLINE
                ? _c(
                    "router-link",
                    {
                      key: "routerLinkTabOutline",
                      staticClass: "btn btn-outline-primary",
                      attrs: { to: { name: "OutlineConvertReferenceStep1" } },
                    },
                    [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.moduleModalBack.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tBack\n\t\t\t\t")]
                  ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col text-end" }, [
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.createReference.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tNext\n\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }