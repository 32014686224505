var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 position-relative" }, [
        _vm.filteredGridData && _vm.filteredGridData.length > 0
          ? _c("table", { staticClass: "table table-borderless entity-grid" }, [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("sortable-grid-th", {
                      attrs: {
                        "cell-text": "Title",
                        colspan: "2",
                        "sort-key": "title",
                        "is-title": true,
                        "sort-order-by": _vm.sortOrderBy,
                        sorts: _vm.sorts,
                      },
                      on: { "update-grid": _vm.updateGridSort },
                    }),
                    _vm._v(" "),
                    _c("th", { staticClass: "table-spacer" }),
                    _vm._v(" "),
                    _c("th", [_vm._v("\n\t\t\t\t\t\t\tType\n\t\t\t\t\t\t")]),
                    _vm._v(" "),
                    _c("th", {
                      staticClass: "table-spacer",
                      attrs: { width: "4px" },
                    }),
                    _vm._v(" "),
                    _c("sortable-grid-th", {
                      attrs: {
                        "cell-text": "Class",
                        "sort-key": "className",
                        "sort-order-by": _vm.sortOrderBy,
                        sorts: _vm.sorts,
                      },
                      on: { "update-grid": _vm.updateGridSort },
                    }),
                    _vm._v(" "),
                    _c("th", { staticClass: "table-spacer" }),
                    _vm._v(" "),
                    _c("sortable-grid-th", {
                      attrs: {
                        "cell-text": "Due Date",
                        "sort-key": "dueDate",
                        "sort-order-by": _vm.sortOrderBy,
                        sorts: _vm.sorts,
                      },
                      on: { "update-grid": _vm.updateGridSort },
                    }),
                    _vm._v(" "),
                    _c("th", {
                      staticClass: "table-spacer",
                      attrs: { width: "4px" },
                    }),
                    _vm._v(" "),
                    _c("sortable-grid-th", {
                      attrs: {
                        "cell-text": "Modified",
                        "sort-key": "lastModifiedDate",
                        "sort-order-by": _vm.sortOrderBy,
                        sorts: _vm.sorts,
                      },
                      on: { "update-grid": _vm.updateGridSort },
                    }),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "30px" } }, [
                      _vm._v("\n\t\t\t\t\t\t\t \t"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.filteredGridData, function (paperData) {
                  return _c("paper-row", {
                    key: paperData.paperID,
                    attrs: {
                      "paper-class-name": paperData.className,
                      "paper-description": paperData.description,
                      "paper-due-date": paperData.dueDate,
                      "paper-encrypted-id": paperData.encryptedID,
                      "paper-format-version-id": paperData.paperFormatVersionID,
                      "paper-id": paperData.paperID,
                      "paper-is-complete": paperData.isComplete,
                      "paper-last-modified-date": paperData.lastModifiedDate,
                      "paper-start-date": paperData.startDate,
                      "paper-title": paperData.title,
                      "paper-type": paperData.paperType,
                      context: "ReferenceEdit",
                    },
                  })
                }),
                1
              ),
            ])
          : _c("div", { staticClass: "text-muted" }, [
              _c("p", { staticClass: "org-size" }, [
                _vm._v(
                  "\n\t\t\t\t\tNo papers found for this Reference.\n\t\t\t\t"
                ),
              ]),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }