var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price-grid-wrp" }, [
      _c("section", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-6 text-center" }, [
              _c("main", [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h3", [_vm._v("PERRLA for Word")]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start mb-0" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tPERRLA's Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-6 text-center" }, [
              _c("main", [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png",
                    role: "presentation",
                  },
                }),
                _vm._v(" "),
                _c("h3", [_vm._v("PERRLA Online")]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start mb-0" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tPERRLA Online is our web-based application that allows you to write APA & MLA papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper's research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "py-0" }, [
        _c("div", { staticClass: "container" }, [_c("hr")]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Latest APA & MLA formats")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tPERRLA is 100% compatible with APA 7, APA 6, MLA 9, and MLA 8 formatting guidelines. We monitor all APA & MLA updates to make sure PERRLA is always up-to-date!\n\t\t\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(" ")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Google Chrome Extension")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tOur Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.\n\t\t\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(" ")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Formats your entire paper")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tPERRLA formats your references, page margins, headings & text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don’t have to.\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Auto-fill Reference Wizard")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tUse our Auto-fill tool to search for books, journal articles, & websites without any typing. Manually create every other reference type with our guided Reference Wizard.\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Reference Syncing")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tPERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.\n\t\t\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(" ")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
              _c("main", [
                _c("div", { staticClass: "text-center" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg",
                      role: "presentation",
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("PERRLA is always up-to-date")]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-start org-size" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tUpdating software is a pain. PERRLA updates itself automatically without you having to do a thing.\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-4 mx-md-auto g-start-md-5",
              },
              [
                _c("main", [
                  _c("div", { staticClass: "text-center" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: " https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg",
                        role: "presentation",
                      },
                    }),
                    _vm._v(" "),
                    _c("h4", [_vm._v("US-based Support")]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-start org-size" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tOur Customer Support team is serious about helping students. They’re available "
                    ),
                    _c("strong", [_vm._v("7 days a week until 10pm Central")]),
                    _vm._v(".\n\t\t\t\t\t\t"),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }