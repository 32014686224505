/*
- Review
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.review.x
*/
export default {
	namespaced: true,
	
	state:{
		DownloadCleanCopy: false,
		CopyPaperToClipboard: false,
		OneCitation: false,
		Period: false,
		TablesFigures: false,
		Typos: false,
	},//e:state

	mutations: {
		SET_STATE(state, $opts){
			state[$opts.key] = $opts.value;
		},
	},//e:mutations
}
