var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-8 mx-auto" }, [
        _c("div", { staticClass: "org-size" }, [
          _c("p", [
            _vm._v(
              "\n\t\t\t\t\tYou've already been invited to join PERRLA as part of an organization or school.\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n\t\t\t\t\tWe just sent your email invitation to "' +
                _vm._s(_vm.emailAddress) +
                '."\n\t\t\t\t'
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n\t\t\t\t\tPlease check your inbox for an invitation from "support@perrla.com."  Follow the instructions in the email to activate your PERRLA account.\n\t\t\t\t'
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ContactSupport",
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\tCan't find the invitation email? Click here to email our Customer Support team.\n\t\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("img", {
          staticClass: "img-max mx-auto d-block mb-4",
          attrs: {
            src: "https://perrla.s3.amazonaws.com/images/unicorn-rainbow.png",
            role: "presentation",
          },
        }),
        _vm._v(" "),
        _c(
          "h1",
          { staticClass: "text-center mt-4 mb-0 text-large d-none d-md-block" },
          [
            _vm._v(
              "\n\t\t\t\tYou've been invited to use PERRLA for free!\n\t\t\t"
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }