var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "nav-item" },
    [
      _c(
        "router-link",
        {
          class: ["nav-link", { "nav-link-active": _vm.isActive }],
          attrs: { to: _vm.to },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }