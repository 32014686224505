var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("generic-modal-wrap", { attrs: { "modal-id": "PaperSource" } }, [
    _c("h2", { staticClass: "nodal-title" }, [
      _vm._v("\n\t\tPaper Source\n\t"),
    ]),
    _vm._v(" "),
    _vm.documentAsHtml
      ? _c("div", [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.documentAsHtml,
                expression: "documentAsHtml",
              },
            ],
            staticClass: "form-control",
            domProps: { value: _vm.documentAsHtml },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.documentAsHtml = $event.target.value
              },
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }