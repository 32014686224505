<!--
- Settings > Your Papers > Row
-- 
-->

<template>
	<tr :class="['tr-entity-grid-row tr-entity-grid-primary', {'tr-entity-grid-row-success': isComplete}]" @mouseover="isHover = true" @mouseleave="isHover = false">
		<td class="td-entity-title">
			{{ decodePaperTitle }}
		</td> 
		<td class="table-spacer" />
		<td :class="[{'text-end': context === 'ClassEdit' }]">
			{{ outputLastModifiedDate }}
		</td>
		<td width="200px">
            <button type="button" class="btn btn-sm btn-hover btn-hover-primary" @click.prevent="btnDownloadPaper">
                Download Paper (.docx)
            </button>
        </td>
	</tr>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';

export default {
	name: 'PaperRow',
	props:{
		context:{
			type: String,
		},
		paperId:{
			required: true,
			type: Number,
		},
		paperIsComplete:{
			required: true,
			type: Boolean,
		},
		paperLastModifiedDate:{
			required: true,
			type: String,
		},
		paperTitle: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			isComplete: this.paperIsComplete,
			isHover: false,
		}
	},
	computed:{
		decodePaperTitle(){
			// there could be weird stuff in the paper title, so try to decode
			try {
				decodeURIComponent(this.paperTitle)
			} catch(err){
				// decode didn't work, so just send the paper title back
				return this.paperTitle;
			}
			return decodeURIComponent(this.paperTitle);
		},

		outputLastModifiedDate(){
			return dateToLongString({
				dateValue: this.paperLastModifiedDate,
				showTime: true,
			});
		},
	},
	methods: {
        btnDownloadPaper(){
            PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.WORD_SIMPLE,
				paperId: this.paperId,
				paperTitle: this.paperTitle,
				platform: 1, // just Online Papers here
			});
        },//e:btnDownloadPaper


	},

}
</script>
