// injected into Writing Tips

export default [
	//1
	{
		title: 'How to start writing a paper',
		blurb: 'Writing a paper may seem like the hardest part of your semester, but we’re here to turn that mountain into a mole hill. Here’s a few important things to remember before we dive in.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003994493-How-to-start-writing-a-paper',
	},
	//2
	{
		title: 'Parts of A Paper',
		blurb: 'Every paper is a little different, but almost all of them contain the same basic parts with a few extras when needed.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003995093-Parts-of-a-Paper',
	},
	//3
	{
		title: 'Types of Papers',
		blurb: 'No two essays are alike, but we can divide them up into categories. Here are four types that you are likely to encounter: argumentative, persuasive, expository, and analytical.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003995153-Types-of-Papers',
	},
	//4
	{
		title: 'The Ins & Outs of Doing Research',
		blurb: 'Before you ever begin writing your paper, you will have spend some time doing research. Some papers may only require a little extra time researching while others papers may require hours digging through material.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003963854-The-Ins-Outs-of-Doing-Research',
	},
	//5
	{
		title: 'Importance of a Good Outline',
		blurb: 'Never write a paper without an outline! The outline is how you plan your paper’s content. Without an outline, you’re “just winging it” - and that’s no way to write a quality paper.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003995453-Importance-of-Good-Outline',
	},
	//6
	{
		title: 'Writing the Body of a Paper',
		blurb: 'The body of your paper is its largest and most complex part, but it doesn’t have to be the most difficult. If you’ve done a good job of organizing your research and crafting a solid thesis statement, then the body of the paper should be a much easier process.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003995733-Writing-the-Body-of-the-Paper',
	},
	//7
	{
		title: 'Why Editing Matters',
		blurb: 'Although editing may seem like a minor part of any paper, it is actually one of the most important things you can do. No matter who you are, even the best of writers need to spend time editing their work.',
		link: 'https://perrla.zendesk.com/hc/en-us/articles/360003997073-Why-Editing-Matters',
	},
]