var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row-article",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.loadArticle.apply(null, arguments)
        },
      },
    },
    [
      _c("h4", [_vm._v(_vm._s(_vm.tipData.title))]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.tipData.blurb) + " "),
        _c("a", { attrs: { href: "#", title: "Learn more" } }, [
          _vm._v("Learn More..."),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }