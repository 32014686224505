<!--
- PaperList > Route Modal > Create Research Paper
-->
<template>
	<div>
		<module-create-research-paper />
	</div>
</template>

<script>
import ModuleCreateResearchPaper from '@/modules/paper/CreateResearchPaper'

export default {
	name: 'PaperListCreateResearchPaper',
	components: {
		ModuleCreateResearchPaper
	}
}
</script>
