var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.editorType === "document"
      ? _c("div", { staticClass: "toolbar-row" }, [
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: "Save Document",
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Save Document",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("saveDocument")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-save" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: "Print Document",
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Print Document",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("printDocument")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-print" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown",
              attrs: {
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Document Font",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: {
                    href: "#",
                    type: "button",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                    title: "Document Font",
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.activeFont) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "dropdownMenuFont" },
                },
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.ARIAL,
                          },
                        ],
                        staticStyle: {
                          "font-family": "Arial, Helvetica, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("ARIAL")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tArial\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.COURIER,
                          },
                        ],
                        staticStyle: {
                          "font-family": "CourierNew, Courier, monospace",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("COURIER")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tCourier\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.TIMES_NEW_ROMAN,
                          },
                        ],
                        staticStyle: {
                          "font-family": "TimesNewRoman, Times, serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("TIMES_NEW_ROMAN")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tTimes New Roman\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.TREBUCHET,
                          },
                        ],
                        staticStyle: {
                          "font-family":
                            "TrebuchetMS, LucidaSansUnicode, LucidaGrande, LucidaSans, Arial, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("TREBUCHET")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tTrebuchet\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.VERDANA,
                          },
                        ],
                        staticStyle: {
                          "font-family": "Verdana, Geneva, Tahoma, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("VERDANA")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tVerdana\n\t\t\t\t\t")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editorType === "document"
      ? _c("div", { staticClass: "toolbar-row" }, [
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_bold",
                title: "Bold",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Bold",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("bold")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-bold" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_italic",
                title: "Italic",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Italic",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("italic")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-italic" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_underline",
                title: "Underline",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Underline",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("underline")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-underline" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_numberedlist",
                title: "Insert/Remove Numbered List",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Numbered list",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("numberedlist")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-list-ol" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_bulletedlist",
                title: "Insert/Remove Bulleted List",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Bulleted List",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("bulletedlist")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-list-ul" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-flip",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_outdentblock",
                title: "Decrease Indent",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Decrease Indent",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("outdent")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-outdent" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-flip",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_indentblock",
                title: "Increase Indent",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Increase Indent",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnExecIndent.apply(null, arguments)
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-indent" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_subscript",
                title: "Subscript",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Subscript",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("subscript")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-subscript" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.BODY + "_superscript",
                title: "Superscript",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Superscript",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("superscript")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-superscript" })]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editorType === "outline"
      ? _c("div", { staticClass: "toolbar-row" }, [
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: "Save Outline",
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Save Outline",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("saveOutline")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-save" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: "Print Document",
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Print Document",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("printDocument")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-print" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown",
              attrs: {
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Document Font",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: {
                    href: "#",
                    type: "button",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                    title: "Document Font",
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.activeFont) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "dropdownMenuFont" },
                },
                [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item hover hover-primary",
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.ARIAL,
                          },
                        ],
                        staticStyle: {
                          "font-family": "Arial, Helvetica, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("ARIAL")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tArial\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.COURIER,
                          },
                        ],
                        staticStyle: {
                          "font-family": "CourierNew, Courier, monospace",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("COURIER")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tCourier\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.TIMES_NEW_ROMAN,
                          },
                        ],
                        staticStyle: {
                          "font-family": "TimesNewRoman, Times, serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("TIMES_NEW_ROMAN")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tTimes New Roman\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.TREBUCHET,
                          },
                        ],
                        staticStyle: {
                          "font-family":
                            "TrebuchetMS, LucidaSansUnicode, LucidaGrande, LucidaSans, Arial, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("TREBUCHET")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tTrebuchet\n\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: [
                          "dropdown-item hover hover-primary",
                          {
                            active:
                              _vm.$store.state.paperEdit.meta.FontFamily ==
                              _vm.$enums.FontFamily.VERDANA,
                          },
                        ],
                        staticStyle: {
                          "font-family": "Verdana, Geneva, Tahoma, sans-serif",
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.applyFont("VERDANA")
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tVerdana\n\t\t\t\t\t")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.$isLive || _vm.$store.state.customer.isImpersonating
            ? _c("div", { staticClass: "divider" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.$isLive || _vm.$store.state.customer.isImpersonating
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-source",
                  attrs: {
                    type: "button",
                    id:
                      "toolbar_" +
                      _vm.$enums.CkEditorType.OUTLINE +
                      "_indentblock",
                    title: "Increase Indent",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.execCommand("source")
                    },
                  },
                },
                [_vm._v("\n\t\t\tSRC\n\t\t")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editorType === "outline"
      ? _c("div", { staticClass: "toolbar-row" }, [
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_bold",
                title: "Bold",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Bold",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("bold")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-bold" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_italic",
                title: "Italic",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Italic",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("italic")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-italic" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_underline",
                title: "Underline",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Underline",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("underline")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-underline" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id:
                  "toolbar_" +
                  _vm.$enums.CkEditorType.OUTLINE +
                  "_numberedlist",
                title: "Insert/Remove Numbered List",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Numbered list",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("numberedlist")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-list-ol" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id:
                  "toolbar_" +
                  _vm.$enums.CkEditorType.OUTLINE +
                  "_bulletedlist",
                title: "Insert/Remove Bulleted List",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Bulleted List",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("bulletedlist")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-list-ul" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-flip",
              attrs: {
                type: "button",
                id:
                  "toolbar_" +
                  _vm.$enums.CkEditorType.OUTLINE +
                  "_outdentblock",
                title: "Decrease Indent",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Decrease Indent",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("outdent")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-outdent" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-flip",
              attrs: {
                type: "button",
                id:
                  "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_indentblock",
                title: "Increase Indent",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Increase Indent",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("indent")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-indent" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_subscript",
                title: "Subscript",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Subscript",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("subscript")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-subscript" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm",
              attrs: {
                type: "button",
                id:
                  "toolbar_" + _vm.$enums.CkEditorType.OUTLINE + "_superscript",
                title: "Superscript",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Superscript",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("superscript")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-superscript" })]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editorType === "footnote"
      ? _c("div", { staticClass: "toolbar-row" }, [
          _c(
            "button",
            {
              attrs: {
                type: "button",
                title: "Save Footnote",
                "data-bs-placement": "top",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Save Footnote",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("saveFootnotes")
                },
              },
            },
            [_c("span", { staticClass: "svg icons8-save" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "divider" }),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.FOOTNOTES + "_bold",
                title: "Bold",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Bold",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("bold")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-bold" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id: "toolbar_" + _vm.$enums.CkEditorType.FOOTNOTES + "_italic",
                title: "Italic",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Italic",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("italic")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-italic" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                type: "button",
                id:
                  "toolbar_" + _vm.$enums.CkEditorType.FOOTNOTES + "_underline",
                title: "Underline",
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
                "data-bs-title": "Underline",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.execCommand("underline")
                },
              },
            },
            [_c("span", { staticClass: "svg bi bi-type-underline" })]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }