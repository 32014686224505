<!--
- Paper Edit > Modal > Replace Figure 
-- alot of this is shared code with InsertFigure.vue
-- it's copy / paste instead of being a legit component
-->

<template>
	<generic-modal-wrap modal-id="ReplaceFigure">
		<h2 class="nodal-title">
			Replace Figure
		</h2>
		<label>Select the figure image</label>
		<div class="help-text mb-1">
			Choose where we to get your figure image from the options below.
		</div>
		
		<form>
			<div class="row mb-2">
				<div class="col ms-2">
					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							id="modalImgOnComputer"
							name="modalImgOnComputer" 
							:value="0"
							v-model="selectTypeId" /> 
						<label class="form-check-label" for="modalImgOnComputer">Image on my computer</label>
					</div><!--form-check-->

					<slide-up-down :active="selectTypeId === 0">
						<div class="ms-3">
							<input
								type="file"
								aria-label="Select an Image on my computer"
								:class="['form-control-file mb-1 p-1', {'file-select-is-invalid': isFileError}]"
								ref="formFileSelect"
								@change="fileSelectChange($event.target.files);" />

							<div class="invalid-feedback d-block" v-if="isFileError" key="divImageSelectError">
								Please select a .jpg, or .png file.
							</div>
							<div class="help-text" v-else key="divImageSelectHelp">
								You can insert .jpg, and .png files as figures.
							</div>
						</div>
					</slide-up-down>

					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							id="modalImgFromUrl"
							name="modalImgFromUrl" 
							:value="1"
							v-model="selectTypeId" /> 
						<label class="form-check-label" for="modalImgFromUrl">Image from a URL</label>
					</div><!--form-check-->

					<slide-up-down :active="selectTypeId === 1">
						<div class="ms-3">
							<input
								autocomplete="off"
								class="form-control"
								placeholder="Enter the image URL"
								ref="inputEnterImageUrl"
								type="text" v-model="imageUrl" v-debounce:300ms="inputImageUrl" />
						</div>
					</slide-up-down>
				</div>
			</div>

			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="$store.commit('modals/CLOSE', 'ReplaceFigure')">
							Cancel
						</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="insertFigurePrep" :disabled="isFormSubmitDisabled">
							Replace Figure
						</button>
					</div>
				</div>
			</div>
		</form>

	</generic-modal-wrap>

</template>

<script lang="js">
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';
import PS_SaveDocument from '@/services/paper/saveDocument';
import SlideUpDown from '@/components/VueSlideUpDown.vue';
import Swal from 'sweetalert2';

const maxImageWidth = 650;	// max width in px

export default {
	name: 'ModalReplaceFigure',
	computed:{
		passData(){
			if(!_isEmpty(this.$store.state.modals.passData)){
				return this.$store.state.modals.passData;
			}
			return null;
		},
	},
	data() {
		return {
			imageBase64Encode: '',
			imageUrl: '',
			isFileError: false,
			isFormSubmitDisabled: true,
			selectTypeId: 0,
		}
	},
	watch:{
		selectTypeId(oldValue, newValue){
			// if user is clicking to Enter an image from a url then focus that input after it renders
			if(newValue === 0){
				this.$nextTick(()=>{
					if(this.$refs.inputEnterImageUrl){
						this.$refs.inputEnterImageUrl.focus();
					}
				});
			}
		}
	},
	methods: {
		fileSelectChange(file){
			this.$store.commit('loaders/ADD_ID', 'App');

			// defaults
			this.isFileError = false;
			
			if(!file[0]){
				this.imageBase64Encode = '';
				return false;
			}

			// verify allowed file type
			const fileType = file[0].type;

			if(fileType.includes('jpeg') || fileType.includes('jpg') || fileType.includes('png') || fileType.includes('gif') || fileType.includes('webp')) {
				// Uploadable File Type
					
				// currently using FileReader
				// option 3 foud here: https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript

				// consider using a different approach that allows file compression
				// https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser

				// a larger example that combines some options (start with this one!)
				// https://zocada.com/compress-resize-images-javascript-browser/

				let reader = new FileReader();
				reader.readAsDataURL(file[0]);
				reader.onload = (event) => {
					const img = new Image();
					img.crossOrigin = 'Anonymous';
					img.src = event.target.result;

					img.onload = () => {
						let canvas = document.createElement('canvas');
						
						// calculated the new encoded image size now
						let setWidth = img.width;
						let setHeight = img.height;

						if(img.width > maxImageWidth){
							// needs to scale down this image
							setWidth = maxImageWidth;
							setHeight = img.height * (maxImageWidth / img.width);
						}

						canvas.width = setWidth;
						canvas.height = setHeight;

						let ctx = canvas.getContext('2d');
						ctx.drawImage(img, 0, 0, setWidth, setHeight);

						let imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
					
						this.imageBase64Encode = imageDataUrl.replace(/^data:image\/(jpeg|jpg|png|webp);base64,/, "");

					};//e:img.onload

					this.isFormSubmitDisabled = false;
					this.$store.commit('loaders/REMOVE_ID', 'App');
				};
				reader.onerror = () => {
					this.imageBase64Encode = '';
					this.isFormSubmitDisabled = true;
					this.$store.commit('loaders/REMOVE_ID', 'App');
				};	
			
			} else {
				// Invalid File Type Selected
				this.isFileError = true;
				this.$store.commit('loaders/REMOVE_ID', 'App');
			}
		},//e:fileSelectChange

		inputImageUrl(){
			// check for a query string, remove it
			if(this.imageUrl === ''){
				return false;
			}
			// make sure this can convert to a value url
			try {
				this.imageUrl = this.imageUrl.split(/[?#]/)[0];

				if(this.imageUrl !== '' && (/\.(jpe?g|png|gif|bmp|webp)$/i.test(this.imageUrl))){
					this.isFormSubmitDisabled = false;
				} else {
					this.isFormSubmitDisabled = true;
				}
				
			} catch (error) {
				console.log('Image URL Could not be found');
				
			}

		},//inputImageUrl

		// prepares the figure by checking the encoding process
		insertFigurePrep(){
			this.$store.commit('loaders/ADD_ID', 'App');

			if(this.selectTypeId == 0){
				// Image from Computer / Upload - make sure there is a base 64 encode
				if(this.imageBase64Encode == ''){
					return false;
				}
				this.replaceFigure();
				
			} else {
				// Image from URL
				// https://i.imgur.com/Z4Ji7Ix.jpg - normal
				// https://i.ytimg.com/vi/koKhBAOmm-c/mqdefault.jpg - CORS error
				
				let xhr = new XMLHttpRequest();

				// generic error
				xhr.onerror = () => {
					this.$store.commit('loaders/REMOVE_ID', 'App');

					Swal.fire({
						allowOutsideClick: false,
						buttonsStyling: false,
						title: 'Error',
						text: 'There was a problem loading the image from a url. If the problem persists, please try to download the image and select "Image on my computer".',
						icon: 'error',
						cancelButtonText: 'OK',
						showCloseButton: false,
						showCancelButton: true,
						showConfirmButton: false,
						customClass:{
							cancelButton: 'btn btn-outline-danger',
						},
					});
				}//e:onerror

				// success / load
				xhr.onload = () => {
					let reader = new FileReader();

					reader.onloadend = (event) => {
						const img = new Image();
						img.crossOrigin = 'Anonymous';
						img.src = event.target.result;
						
						img.onload = () => {
							let canvas = document.createElement('canvas');
							
							// calculated the new encoded image size now
							let setWidth = img.width;
							let setHeight = img.height;

							if(img.width > maxImageWidth){
								// needs to scale down this image
								setWidth = maxImageWidth;
								setHeight = img.height * (maxImageWidth / img.width);
							}

							canvas.width = setWidth;
							canvas.height = setHeight;

							let ctx = canvas.getContext('2d');
							ctx.drawImage(img, 0, 0, setWidth, setHeight);
														
							let imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
							this.imageBase64Encode = imageDataUrl.replace(/^data:image\/(jpeg|jpg|png|);base64,/, "");
							this.replaceFigure();

						};//e:img.onload
					};
					reader.readAsDataURL(xhr.response);
				};
				
				// using a proxy for CORS - https://stackoverflow.com/questions/20920965/cross-origin-problems-getting-base64-encoding-of-an-image
				// xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + this.imageUrl);
				
				xhr.open('GET', this.imageUrl);
				xhr.responseType = 'blob';
				xhr.send();
			}//e:if:selectTypeId

		},//e:insertFigurePrep

		// the actual insert after the prep function
		replaceFigure(){
			if(this.imageBase64Encode) {
				let $imgEl = _cloneDeep(this.$store.state.modals.passData.imgEl);

				$imgEl.removeAttribute('data-cke-saved-src');
				$imgEl.setAttribute( 'src', 'data:image/png;base64,' + this.imageBase64Encode);

				let figureEl = $imgEl.getParent();
				let figureTitle = '?';

				if(figureEl && figureEl.getName().toLowerCase() === 'figure'){
					// parse the title from this figure element
					figureTitle = figureEl.findOne('figure-title').getText();
				}
			
				setTimeout(()=>{
					PS_SaveDocument({
						message: 'Replaced figure ' + figureTitle,
					}).then(()=>{
						this.$store.commit('modals/CLOSE', 'ReplaceFigure');
						this.emitter.emit('globalToasterOpen',{
							textContent: 'Figure replaced',
						});
					});
				}, 100);
				

				// reset form
				this.imageBase64Encode = '';
				this.imageUrl = '';
				this.isFormSubmitDisabled = true;
				this.selectTypeId = 0;
		
			} else {
				alert('Error imageBase64Encode is empty string');
			}
			
			this.$store.commit('loaders/REMOVE_ID', 'App');

		},//e:replaceFigure

	},
	components:{
		GenericModalWrap,
		SlideUpDown,
	},
}
</script>
