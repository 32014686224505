var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$store.state.referenceDuplicate.isShowWarning
        ? _c("duplicate-reference-confirm")
        : _vm._e(),
      _vm._v(" "),
      _vm.thirdPartyReferenceResults &&
      _vm.thirdPartyReferenceResults.length > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "filter-results-trigger",
                    attrs: {
                      href: "#",
                      "data-bs-toggle": "dropdown",
                      role: "button",
                      "aria-expanded": "false",
                      id: "dropdownMenu3plu",
                    },
                  },
                  [
                    _c("span", { staticClass: "dropdown-toggle" }, [
                      _vm._v("Showing " + _vm._s(_vm.filteredByText)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu",
                    attrs: { "aria-labelledby": "dropdownMenu3plu" },
                  },
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.filterResults()
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tAll results\n\t\t\t\t\t")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.filterResults("book")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\tBook & Chapters only\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.filterResults("journal")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\tJournal Articles only\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.filteredThirdPartyResults, function (ref, index) {
                return _c("third-party-result-row", {
                  key: index,
                  attrs: {
                    "is-drawer": _vm.isDrawer,
                    "third-party-reference": ref,
                  },
                })
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }