var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.citationLocations.length > 0
    ? _c(
        "div",
        [
          _vm.isShowTurb9NewspaperWarning
            ? _c(
                "div",
                { key: "turabianAlert", staticClass: "alert alert-warning" },
                [_vm._m(0)]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("citation-live-preview", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLocationPart || _vm.isDatePart || _vm.isNamePart,
                expression: "isLocationPart || isDatePart || isNamePart",
              },
            ],
            attrs: { "citation-output": _vm.citationOutput },
          }),
          _vm._v(" "),
          _vm.isShowCitationParts
            ? _c(
                "section",
                { key: "spanPartsToInclude", staticClass: "mb-4" },
                [
                  _c("h3", [_vm._v("Parts to include in Citation")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "help-text mb-1" }, [
                    _vm._v(
                      "\n\t\t\t\tIf the author's name, date, or citation is already included in the sentence you're citing, you can leave it out of the citation.\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("form-checkbox", {
                    staticClass: "mb-0",
                    attrs: { label: "Contributor's name" },
                    model: {
                      value: _vm.isNamePart,
                      callback: function ($$v) {
                        _vm.isNamePart = $$v
                      },
                      expression: "isNamePart",
                    },
                  }),
                  _vm._v(" "),
                  _vm.isShowDateOption
                    ? _c("form-checkbox", {
                        staticClass: "mb-0",
                        attrs: { label: "Date" },
                        model: {
                          value: _vm.isDatePart,
                          callback: function ($$v) {
                            _vm.isDatePart = $$v
                          },
                          expression: "isDatePart",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("form-checkbox", {
                    staticClass: "mb-0",
                    attrs: { label: "Location (page number, etc.)" },
                    model: {
                      value: _vm.isLocationPart,
                      callback: function ($$v) {
                        _vm.isLocationPart = $$v
                      },
                      expression: "isLocationPart",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "mb-3" },
            [
              _c("h3", [
                _vm._v("\n\t\t\t\tChoose a Citation location\n\t\t\t"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.citationLocations, function (citationLocationEnum) {
                return _c("div", { key: citationLocationEnum }, [
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.NOT_A_DIRECT_QUOTE
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            ref: "focusNotDirectRadio",
                            refInFor: true,
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_NotDirect",
                              name: "type_NotDirect",
                              type: "radio",
                              disabled: !_vm.isLocationPart,
                              value: "n.d.",
                            },
                            domProps: {
                              checked: _vm._q(_vm.citationLocationType, "n.d."),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "n.d."
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_NotDirect" },
                            },
                            [_vm._v("Not a direct quote")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.ACT_SCENE_LINE
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Time",
                              name: "type_Time",
                              type: "radio",
                              value: "actSceneLine",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "actSceneLine"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "actSceneLine"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Time" },
                            },
                            [_vm._v("Act, Scene, & Line")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.citationLocationType === "actSceneLine",
                                expression:
                                  "citationLocationType === 'actSceneLine'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.actSceneLineAct,
                                        expression: "actSceneLineAct",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: { value: _vm.actSceneLineAct },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.actSceneLineAct =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(2, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.actSceneLineScene,
                                        expression: "actSceneLineScene",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: { value: _vm.actSceneLineScene },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.actSceneLineScene =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(3, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.actSceneLineLine,
                                        expression: "actSceneLineLine",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: { value: _vm.actSceneLineLine },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.actSceneLineLine =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.BOOK_CHAPTER_VERSE
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Religious",
                              autocomplete: "off",
                              name: "type_Religious",
                              type: "radio",
                              value: "religious",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "religious"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "religious"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Religious" },
                            },
                            [_vm._v("Book, chapter, & verse")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "religious",
                                expression:
                                  "citationLocationType === 'religious'",
                              },
                            ],
                            staticClass: "col px-3 my-1",
                          },
                          [
                            _c("p", { staticClass: "help-text" }, [
                              _vm._v(
                                "\n                            Abbreviate the book if possible. Separate chapter & verse with a colon.\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(4, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.otherLabel,
                                        expression: "otherLabel",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                      placeholder: "book",
                                    },
                                    domProps: { value: _vm.otherLabel },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.otherLabel = $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(5, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.citationLocationValue,
                                        expression: "citationLocationValue",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                      placeholder: "chapter:verse",
                                    },
                                    domProps: {
                                      value: _vm.citationLocationValue,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.citationLocationValue =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum === _vm.enumsCitationLocation.PAGE_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Page",
                              name: "type_Page",
                              type: "radio",
                              value: "page",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(_vm.citationLocationType, "page"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "page"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Page" },
                            },
                            [_vm._v("Page number")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "page",
                                expression: "citationLocationType === 'page'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.PARAGRAPH_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Parahraph",
                              name: "type_Parahraph",
                              type: "radio",
                              value: "paragraph",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "paragraph"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "paragraph"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Parahraph" },
                            },
                            [_vm._v("Paragraph")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "paragraph",
                                expression:
                                  "citationLocationType === 'paragraph'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.CHAPTER_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Chapter",
                              name: "type_Chapter",
                              type: "radio",
                              value: "chapter",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "chapter"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "chapter"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Chapter" },
                            },
                            [_vm._v("Chapter")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "chapter",
                                expression:
                                  "citationLocationType === 'chapter'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.SECTION_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Section",
                              name: "type_Section",
                              type: "radio",
                              value: "section",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "section"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "section"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Section" },
                            },
                            [_vm._v("Section")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "section",
                                expression:
                                  "citationLocationType === 'section'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.TABLE_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Table",
                              name: "type_Table",
                              type: "radio",
                              value: "table",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "table"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "table"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Table" },
                            },
                            [_vm._v("Table")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "table",
                                expression: "citationLocationType === 'table'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum ===
                  _vm.enumsCitationLocation.FIGURE_NUMBER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Figure",
                              name: "type_Figure",
                              type: "radio",
                              value: "figure",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "figure"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "figure"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Figure" },
                            },
                            [_vm._v("Figure")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "figure",
                                expression: "citationLocationType === 'figure'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "focus-update",
                                  rawName: "v-focus-update",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.citationLocationValue,
                                  expression: "citationLocationValue",
                                },
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "text", autocomplete: "off" },
                              domProps: { value: _vm.citationLocationValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.citationLocationValue =
                                    $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum === _vm.enumsCitationLocation.TIME
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_Time",
                              name: "type_Time",
                              type: "radio",
                              value: "time",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(_vm.citationLocationType, "time"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "time"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_Time" },
                            },
                            [_vm._v("Time")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "time",
                                expression: "citationLocationType === 'time'",
                              },
                            ],
                            staticClass: "col px-3 my-1 w-50",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(6, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.timeBasedMediaHours,
                                        expression: "timeBasedMediaHours",
                                      },
                                    ],
                                    ref: "time_hours",
                                    refInFor: true,
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: {
                                      value: _vm.timeBasedMediaHours,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.timeBasedMediaHours =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(7, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.timeBasedMediaMinutes,
                                        expression: "timeBasedMediaMinutes",
                                      },
                                    ],
                                    ref: "time_minutes",
                                    refInFor: true,
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: {
                                      value: _vm.timeBasedMediaMinutes,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.timeBasedMediaMinutes =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group form-group-horizontal mb-2",
                              },
                              [
                                _vm._m(8, true),
                                _vm._v(" "),
                                _c("div", { staticClass: "w-100" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.timeBasedMediaSeconds,
                                        expression: "timeBasedMediaSeconds",
                                      },
                                    ],
                                    ref: "time_seconds",
                                    refInFor: true,
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                    },
                                    domProps: {
                                      value: _vm.timeBasedMediaSeconds,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.timeBasedMediaSeconds =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  citationLocationEnum === _vm.enumsCitationLocation.OTHER
                    ? _c("div", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.citationLocationType,
                                expression: "citationLocationType",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              id: "type_other",
                              name: "type_other",
                              type: "radio",
                              value: "other",
                              disabled: !_vm.isLocationPart,
                            },
                            domProps: {
                              checked: _vm._q(
                                _vm.citationLocationType,
                                "other"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.citationLocationType = "other"
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "type_other" },
                            },
                            [_vm._v("Other")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.citationLocationType === "other",
                                expression: "citationLocationType === 'other'",
                              },
                            ],
                            staticClass: "col px-3 my-1",
                          },
                          [
                            _c(
                              "small",
                              { staticClass: "form-text text-muted mb-1" },
                              [
                                _vm._v(
                                  "\n                            If you enter something other than page, chapter, table, etc then you need to enter what part you are citing as well as the value. Ex: Track 15, Screen 7.\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.otherLabel,
                                      expression: "otherLabel",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-sm w-50 mb-1 d-inline",
                                  attrs: {
                                    placeholder: "Label",
                                    type: "text",
                                    autocomplete: "off",
                                  },
                                  domProps: { value: _vm.otherLabel },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.otherLabel = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.citationLocationValue,
                                      expression: "citationLocationValue",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-sm w-25 d-inline",
                                  attrs: {
                                    placeholder: "Value",
                                    type: "text",
                                    autocomplete: "off",
                                  },
                                  domProps: {
                                    value: _vm.citationLocationValue,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.citationLocationValue =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              _vm._v(" "),
              _c("copy-json-data", {
                staticClass: "mt-3",
                attrs: {
                  "data-to-display": JSON.stringify(_vm.citationDataToCopy),
                },
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n\t\t\t\tAccording to the Turabian Manual 17.4.2, "),
      _c("strong", [
        _vm._v("you should not include page numbers for newspaper references"),
      ]),
      _vm._v(" - even printed editions\n\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Act")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Scene")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Line")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-2" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Book")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Chap: Verse")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Hours")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Minutes")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Seconds")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }