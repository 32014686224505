<!--
- Paper Drawer > Table
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Insert Table" />
		</div><!--drawer-header-->

		<div class="drawer-body">
			<div v-if="showAppendixInsert" key="divAppendixInsert">
				<h2>Adding a correct Table Number in an appendix</h2>
				<p>Appendices require unique Table Numbers in the APA format depending on the content presented in the appendix.</p>
				<p><strong>If the table is the only element in the appendix, no table number should be shown.</strong></p>
				<p>The title of the Appendix takes the place of the table number.&nbsp;<em>Highlight the table number text and delete it.</em></p>
				<p>Any appendix callouts in your paper should refer to the appendix label directly (e.g., &quot;...as seen in the Appendix.&quot;).</p>
				<p><strong>If the appendix contains other elements, the table should be numbered according to the Appendix label.</strong></p>
				<p>Tables in a single appendix should be numbered: Table A1, Table A2, etc.</p>
				<p>Tables in Appendix B or greater should be numbered: Table B1, Table C1, etc.</p>
				<p>Any appendix callouts in the body of your paper should refer to the table or figure number&nbsp;directly (e.g., &quot;According to Table A1...&quot;).</p>

				<div class="d-grid">
					<button type="button" class="btn btn-success" @click.prevent="showAppendixInsert = false">
						Insert a new table
					</button>
				</div>

				<div class="text-empty mt-4">
					<p>
						<a href="https://perrla.zendesk.com/hc/en-us/articles/1260804431829-Figure-Table-Titles-in-Appendices-APA-7-" target="_blank" rel="noopener noreferrer">
							For a complete description of tables in appendices, click here.
						</a>
					</p>
				</div>
			</div><!--divAppendixInsert-->
			
			<div v-else key="divNoAppendixInsert">
				<p>To create a table, place your cursor where you want the table to be placed. Then, enter the table&#39;s details below.</p>
				<p>Enter your table&#39;s details below, then click <em>Insert Table</em>. PERRLA will create and format your table for you.</p>

				<form>
					<form-input
						label="Table Title"
						v-model="title"
						:form-id="zFormId"
						:help-text="calcHelpText"
						:validate-enum="$enums.Validation.REQUIRED" />

					<form-input
						label="Columns"
						:form-id="zFormId"
						help-text="The number of columns needed, including Headings."
						v-model="columns"
						:validate-enum="$enums.Validation.NUMERIC_REQUIRED" />

					<form-input
						label="Rows"
						:form-id="zFormId"
						help-text="The number of rows needed, including Headings."
						v-model="rows"
						:validate-enum="$enums.Validation.NUMERIC_REQUIRED" />

					<div v-if="$store.state.paperEdit.config.allowTableNotes">
						<div class="form-group mb-0">
							<label>
								Table notes <span class="font-weight-normal">(optional)</span>
							</label>
							<div class="help-text">
								Table notes are used to clarify information and avoid repetition in a table.
							</div>
						</div>
						
						<div class="form-group form-check ms-3 mb-3 mt-1">
							<input
								class="form-check-input"
								type="checkbox"
								id="chkIncludeNotes"
								v-model="includeNotes" />
							<label for="chkIncludeNotes" class="form-check-label">
								Include notes
							</label>
						</div>
					</div>
					
					<div class="d-grid">
						<button type="submit" class="btn btn-success" @click.prevent="insertTable" :disabled="!canAddTable">
							Insert Table
						</button>
					</div>
					
				</form>

				<p v-if="isPaperAbstract" class="text-danger mt-2">
					Note: Inserting a table in your abstract is not proper formatting for this type of paper.
				</p>

				<div class="text-empty mt-4">
					<p><strong>Writing Tips for Tables</strong></p>
					<p>Once your table is created, you can edit its content, title, and notes directly in your paper.</p>
				</div>
				
			</div><!--divNoAppendixInsert-->

			<br /><br />
		</div><!--drawer-body-->
	</div>
</template>

<script>
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import InsertRawHtml from '@/helpers/insert-raw-html';
import PS_SaveDocument from '@/services/paper/saveDocument';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'PaperDrawerInsertTable',
	data() {
		return {
			columns: '',
			includeNotes: false,
			rows: '',
			showAppendixInsert: false,
			title: '',
			valRequiredFields: ['columns', 'rows', 'title'],
			zFormId: 'formInsertTable',
		}
	},
	computed:{
		canAddTable(){
			if(this.editorLastFocused){
				if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.ABSTRACT ||
					this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX ||
					this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.BODY){
					return true;
				}
			}
			return false;
		},
		calcHelpText(){
			let _referenceFormatTypeId = 0;

			if(config.platformId === config.enums.Platform.ONLINE){
				_referenceFormatTypeId = this.$store.state.paperEdit.meta.PaperFormatVersionID;
			} else {
				_referenceFormatTypeId = this.$store.state.formatVersionID;
			}
			
			if(_referenceFormatTypeId === config.enums.Format.Turabian9){
				return `Titles should be concise and use sentence-case capitalization, usually without a terminal period.`;
			}
			return `Titles should be concise and use Title Case capitalization.`;
		},
		editorLastFocused(){
			if(CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused]){
				return CKEDITOR.instances[this.$store.state.paperEdit.ck.editorLastFocused];
			}
			return null;
		},
		isPaperAbstract(){
			if(this.editorLastFocused && this.editorLastFocused.name === 'ckPaperAbstract'){
				return true;
			}
			return false;
		},
	},
	methods: {
		insertTable(){
			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.editorLastFocused.focus();

				let elementPath = this.editorLastFocused.elementPath();
				
				// check element path to prevent table inserts in certain areas
				let isContainsTable = elementPath.contains( { 'table': 1}, 1 );
				if(isContainsTable){
					this.emitter.emit('globalToasterOpen', {
						title: 'Can not insert table',
						type: 'danger'
					});
					return false;
				}

				let titlePlaceholder = 'Table X';

				// inserting into an appendix
				if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX){
					titlePlaceholder = '***See panel for numbering instructions***';
				}

				let rawHtml ='<table style="width:100%">';

				if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					rawHtml += '<caption>' +
					'<table-caption title="Table numbers will be updated automatically">' + titlePlaceholder + '</table-caption>' + 
					'<table-title>' + this.title + '</table-title>' + 
					'</caption>';

				} else {
					rawHtml += '<caption>' +
					'<table-caption title="Table numbers will be updated automatically">' + titlePlaceholder + '</table-caption>' + 
					'<br/><br/>' + 
					'<table-title>' + this.title + '</table-title>' + 
					'</caption>';

				}

				
				rawHtml += '<tbody>';

				// How many rows
				for(let $r=1; $r <= this.rows; $r++){
					rawHtml += '<tr>';
					// How many cols
					for(let $c=1; $c <= this.columns; $c++){
						rawHtml += '<td><br/></td>';
					}
					rawHtml += '</tr>';
				}

				rawHtml += '</tbody>';
				
				switch(this.$store.state.paperEdit.meta.PaperFormatVersionID){
					case config.enums.Format.APA7:
						if(this.includeNotes){
							rawHtml += '<tfoot><tr><td colspan="' + this.columns + '"><em>Note.</em> Type your note content here.</td></tr></tfoot>';
						}
						break;
					case config.enums.Format.MLA9:
						rawHtml += '<tfoot><tr><td colspan="' + this.columns + '">If applicable, type the source for the table here.</td></tr></tfoot>';
						break;
					case config.enums.Format.Turabian9:
						rawHtml += '<tfoot>';
						rawHtml += '<tr><td colspan="' + this.columns + '" class="td-source"><em>Source:</em> Create a citation for your source here. Remove this line if the table content is your original research.</td></tr>';
						if(this.includeNotes){
							rawHtml += '<tr><td colspan="' + this.columns + '"><em>Note:</em> Type your note content here.</td></tr>';
						}
						rawHtml += '</tfoot>';
						break;
				}
				
				rawHtml += '</table>'; 
				
				InsertRawHtml.insertBlock(rawHtml);

				this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
					formId: this.zFormId
				});

				PS_SaveDocument({
					message: 'Added table ' + this.title,
				}).then(()=>{
					if(this.editorLastFocused.$ckEditorType === config.enums.CkEditorType.APPENDIX){
						this.showAppendixInsert = true;

					} else {
						this.emitter.emit('globalToasterOpen', {
							textContent: 'Table inserted',
						});
					}

					// reset form
					this.columns = '';
					this.includeNotes = false;
					this.rows = '';
					this.title = '';
					this.$store.commit('loaders/REMOVE_ID', 'App');
					
				});
			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');

			});//e:AS_ValidateForm
		},//e:insertTable
	},
	mounted(){
		this.$store.commit('forms/ADD_FORM_TO_LIST',{
			formId: this.zFormId
		});
		
		TDS_Open({
			drawerName: 'EditorInsertTable'
		});
	},
	components:{
		FormInput,
		ToolDrawerTitle,
	}
}
</script>

