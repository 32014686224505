var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rb-wrp clearfix" },
    [
      _c(
        "div",
        { staticClass: "rb float-start" },
        [
          _c(
            "router-link",
            {
              class: [
                "rb-btn",
                { "rb-btn-active": _vm.$route.name === "EditorTitlePage" },
              ],
              attrs: { to: { name: "EditorTitlePage" } },
            },
            [
              _c("div", { staticClass: "rb-icon rb-icon-title-page" }),
              _vm._v("\n\t\t\tTitle\n\t\t\t"),
              _c("br"),
              _vm._v("\n\t\t\tPage\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rb-block" },
            [
              _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                ? _c(
                    "router-link",
                    {
                      class: [
                        "rb-btn rb-btn-three",
                        {
                          "rb-btn-active": _vm.$route.name === "EditorAbstract",
                        },
                      ],
                      attrs: { to: { name: "EditorAbstract" } },
                    },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-abstract" }),
                      _vm._v(" Abstract\n\t\t\t"),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "rb-btn rb-btn-disabled rb-btn-three" },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-abstract" }),
                      _vm._v(" Abstract\n\t\t\t"),
                    ]
                  ),
              _vm._v(" "),
              _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                ? _c(
                    "router-link",
                    {
                      class: [
                        "rb-btn rb-btn-three",
                        {
                          "rb-btn-active":
                            _vm.$route.name === "EditorTableOfContents",
                        },
                      ],
                      attrs: { to: { name: "EditorTableOfContents" } },
                    },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-toc" }),
                      _vm._v(" Table of Contents\n\t\t\t"),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "rb-btn rb-btn-disabled rb-btn-three" },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-toc" }),
                      _vm._v(" Table of Contents\n\t\t\t"),
                    ]
                  ),
              _vm._v(" "),
              _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                ? _c(
                    "router-link",
                    {
                      class: [
                        "rb-btn rb-btn-three",
                        {
                          "rb-btn-active":
                            _vm.$route.name === "EditorAppendices",
                        },
                      ],
                      attrs: { to: { name: "EditorAppendices" } },
                    },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-appendix" }),
                      _vm._v(" Appendix\n\t\t\t"),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "rb-btn rb-btn-disabled rb-btn-three" },
                    [
                      _c("span", { staticClass: "rb-icon rb-icon-appendix" }),
                      _vm._v(" Appendix\n\t\t\t"),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
          _vm._v(" "),
          _c(
            "router-link",
            {
              class: [
                "rb-btn",
                {
                  "rb-btn-active":
                    _vm.$route.name === "EditorReferencesCitations",
                },
              ],
              attrs: { to: { name: "EditorReferencesCitations" } },
            },
            [
              _c("div", { staticClass: "rb-icon rb-icon-references" }),
              _vm._v("\n\t\t\tReferences &\n\t\t\t"),
              _c("br"),
              _vm._v("\n\t\t\tCitations\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rb-block" },
            [
              _c(
                "router-link",
                {
                  class: [
                    "rb-btn rb-btn-two",
                    {
                      "rb-btn-active":
                        _vm.$route.name === "EditorResearchNotes",
                    },
                  ],
                  attrs: { to: { name: "EditorResearchNotes" } },
                },
                [
                  _c("div", { staticClass: "rb-icon rb-icon-research-notes" }),
                  _vm._v("\n\t\t\t\tResearch Notes\n\t\t\t"),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  class: [
                    "rb-btn rb-btn-two",
                    { "rb-btn-active": _vm.$route.name === "EditorOutline" },
                  ],
                  attrs: { to: { name: "EditorOutline" } },
                },
                [
                  _c("span", { staticClass: "rb-icon rb-icon-outline" }),
                  _vm._v("\n\t\t\t\tOutline\n\t\t\t"),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
          _vm._v(" "),
          _c("ribbon-btn-heading-styles"),
          _vm._v(" "),
          _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
          _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
            ? _c(
                "router-link",
                {
                  class: [
                    "rb-btn rb-btn-three",
                    {
                      "rb-btn-active": _vm.$route.name === "EditorInsertFigure",
                    },
                  ],
                  attrs: { to: { name: "EditorInsertFigure" } },
                },
                [
                  _c("span", { staticClass: "rb-icon rb-icon-figure" }),
                  _vm._v("\n\t\t\tInsert"),
                  _c("br"),
                  _vm._v("Figure\n\t\t"),
                ]
              )
            : _c(
                "div",
                { staticClass: "rb-btn rb-btn-disabled rb-btn-three" },
                [
                  _c("span", { staticClass: "rb-icon rb-icon-figure" }),
                  _vm._v(" Insert"),
                  _c("br"),
                  _vm._v("Figure\n\t\t"),
                ]
              ),
          _vm._v(" "),
          _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
          _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
            ? _c(
                "router-link",
                {
                  class: [
                    "rb-btn rb-btn-three",
                    {
                      "rb-btn-active": _vm.$route.name === "EditorInsertTable",
                    },
                  ],
                  attrs: { to: { name: "EditorInsertTable" } },
                },
                [
                  _c("span", { staticClass: "rb-icon rb-icon-table" }),
                  _vm._v("\n\t\t\tInsert"),
                  _c("br"),
                  _vm._v("Table\n\t\t"),
                ]
              )
            : _c(
                "div",
                { staticClass: "rb-btn rb-btn-disabled rb-btn-three" },
                [
                  _c("span", { staticClass: "rb-icon rb-icon-table" }),
                  _vm._v(" Insert"),
                  _c("br"),
                  _vm._v("Table\n\t\t"),
                ]
              ),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
          _vm._v(" "),
          _c(
            "router-link",
            {
              class: [
                "rb-btn rb-btn-two",
                { "rb-btn-active": _vm.$route.name === "EditorPaperReview" },
              ],
              attrs: { to: { name: "EditorPaperReview" } },
            },
            [
              _c("span", { staticClass: "rb-icon rb-icon-paper-review" }),
              _vm._v("\n\t\t\tPaper"),
              _c("br"),
              _vm._v("Review\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              class: [
                "rb-btn rb-btn-two",
                {
                  "rb-btn-active": _vm.$route.name === "EditorCreateCleanCopy",
                },
              ],
              attrs: { to: { name: "EditorCreateCleanCopy" } },
            },
            [
              _c("span", { staticClass: "rb-icon rb-icon-create-clean-copy" }),
              _vm._v("\n\t\t\tCreate"),
              _c("br"),
              _vm._v("Clean Copy\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "rb-divider" }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ck-toolbar", { attrs: { "editor-type": "document" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "rb-btn",
        attrs: {
          href: "https://perrla.zendesk.com/hc/en-us/categories/360000330433-PERRLA-Online",
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      [
        _c("div", { staticClass: "rb-icon rb-icon-help" }),
        _vm._v("\n\t\t\tHelp\n\t\t"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }