<!--
- CreateCleanCopy
-
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Create a Clean Copy" />
		</div><!-- drawer-header -->
		<div class="drawer-body">
			<p class="org-size">
				Create a clean version of your paper without Content Controls to turn into your professor (if you need it).
			</p>
			<p class="org-size">
				By design, PERRLA generates citations and references as protected text inside <em>Content Controls</em>.  To remove this protection and convert the data to plain text, click the button below.  We'll generate a clean, new document for you to save and give to your professor.
			</p>

			<div class="d-grid mt-1">
				<button type="button" class="btn btn-outline-primary" @click.prevent="btnCreateCleanCopy">
					Create a clean copy
				</button>
			</div>
		</div><!--drawer-body-->
	</div>
</template>

<script>
import config from '@/config';
import PS_DownloadDocumentVersionV4 from '@/services/paper/downloadDocumentVersionV4';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'CreateCleanCopy',
	props:{

	},
	data() {
		return {
		}
	},
	computed:{

	},
	methods: {
		btnCreateCleanCopy(){
			PS_DownloadDocumentVersionV4({
				contentType: config.enums.DownloadPaperContentType.WORD_SIMPLE,
				platform: 1, // just Online Papers here
			}).then(()=>{

				// this.toggleCheckedItem({
				// 	key: 'DownloadCleanCopy',
				// 	value: true
				// });
			});
		},
	},
	created() {
	},
	mounted() {
	},
	components: {
		ToolDrawerTitle
	}
}
</script>
