var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-6 position-relative" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", { staticClass: "float-start" }, [
          _vm._v("\n\t\t\t\tClasses\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-success border-0 float-end",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$store.commit("modals/OPEN", "ClassCreate")
              },
            },
          },
          [
            _c("span", { staticClass: "svg bi-plus-lg me-1" }),
            _vm._v(" New Class\n\t\t\t"),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          ref: "tabDashboardClassListWrp",
          staticClass: "col overflow-y-scroll",
        },
        [
          _vm.filteredGridData.length > 0
            ? _c(
                "table",
                {
                  staticClass:
                    "table table-borderless entity-grid table-fixed-header mb-0",
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.filteredGridData, function (classData) {
                      return _c("class-row", {
                        key: classData.classID,
                        attrs: {
                          "class-data-color-value": classData.colorValue,
                          "class-data-encrypted-id": classData.encryptedID,
                          "class-data-id": classData.classID,
                          "class-data-name": classData.name,
                          "is-full": false,
                          "class-data-teacher-name": "",
                        },
                      })
                    }),
                    1
                  ),
                ]
              )
            : _c("div", { staticClass: "text-muted text-center mt-5" }, [
                _c("p", [_vm._v("No classes found.")]),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "th-entity-title" }, [
          _vm._v("\n\t\t\t\t\t\t\tName\n\t\t\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { width: "30px" } }, [_vm._v("\n\t\t\t\t\t\t\t \t")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }