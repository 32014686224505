var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    [
      _c("div", { staticClass: "d-grid g-research-notes-header" }, [
        _c("div", { staticClass: "row row-button-adds" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openOrganizeTabModuleModal(
                        "PaperTabResearchCreateReferenceStep1"
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tCreate a new reference\n\t\t\t\t\t")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnOpenAddReferenceFromLibraryModal.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\t\tAdd reference from library\n\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.filteredReferencesInPaper.length > 0
          ? _c("div", { staticClass: "d-grid g-sort-and-search" }, [
              _c("div", { staticClass: "dropdown" }, [
                _vm._v("\n\t\t\t\tSort: "),
                _c(
                  "a",
                  {
                    staticClass: "hover-underline text-body",
                    attrs: {
                      href: "#",
                      "data-bs-toggle": "dropdown",
                      role: "button",
                      "aria-expanded": "false",
                      id: "dropdownMenu_RN_SortReference",
                    },
                  },
                  [
                    _c("span", { staticClass: "dropdown-toggle" }, [
                      _c("span", { staticClass: "fw-bold" }, [
                        _vm._v("References"),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu dropdown-menu-end",
                    attrs: {
                      "aria-labelledby": "dropdownMenu_RN_SortReference",
                    },
                  },
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortReferenceBy ===
                                "displayValue_asc",
                              "hover hover-primary":
                                _vm.$data.sortReferenceBy !==
                                "displayValue_asc",
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortReferenceBy = "displayValue_asc"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tAlphabetical (A to Z)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortReferenceBy ===
                                "displayValue_desc",
                              "hover hover-primary":
                                _vm.$data.sortReferenceBy !==
                                "displayValue_desc",
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortReferenceBy = "displayValue_desc"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tAlphabetical (Z to A)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortReferenceBy === "createdDate_asc",
                              "hover hover-primary":
                                _vm.$data.sortReferenceBy !== "createdDate_asc",
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortReferenceBy = "createdDate_asc"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (newest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortReferenceBy ===
                                "createdDate_desc",
                              "hover hover-primary":
                                _vm.$data.sortReferenceBy !==
                                "createdDate_desc",
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortReferenceBy = "createdDate_desc"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (oldest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "hover-underline text-body",
                    attrs: {
                      href: "#",
                      "data-bs-toggle": "dropdown",
                      role: "button",
                      "aria-expanded": "false",
                      id: "dropdownMenu_RN_SortNotes",
                    },
                  },
                  [
                    _c("span", { staticClass: "dropdown-toggle" }, [
                      _c("span", { staticClass: "fw-bold" }, [_vm._v("Notes")]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu dropdown-menu-end",
                    attrs: {
                      "aria-labelledby": "dropdownMenu_RN_SortReference",
                    },
                  },
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortNotesBy ===
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_NEWEST_FIRST,
                              "hover hover-primary":
                                _vm.$data.sortNotesBy !==
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_NEWEST_FIRST,
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortNotesBy =
                                _vm.$enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (newest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortNotesBy ===
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_OLDEST_FIRST,
                              "hover hover-primary":
                                _vm.$data.sortNotesBy !==
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_OLDEST_FIRST,
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortNotesBy =
                                _vm.$enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (oldest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortNotesBy ===
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_FIRST_TO_LAST,
                              "hover hover-primary":
                                _vm.$data.sortNotesBy !==
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_FIRST_TO_LAST,
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortNotesBy =
                                _vm.$enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCitation (first to last)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          class: [
                            "dropdown-item",
                            {
                              "dropdown-item-selected":
                                _vm.$data.sortNotesBy ===
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_LAST_TO_FIRST,
                              "hover hover-primary":
                                _vm.$data.sortNotesBy !==
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_LAST_TO_FIRST,
                            },
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.$data.sortNotesBy =
                                _vm.$enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCitation (last to first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "w-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTerm,
                        expression: "searchTerm",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Search research notes",
                      type: "text",
                    },
                    domProps: { value: _vm.searchTerm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchTerm = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchTerm && _vm.searchTerm.length > 0,
                        expression: "searchTerm && searchTerm.length > 0",
                      },
                    ],
                    staticClass: "flex-shrink-1",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link hover-underline",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.$data.searchTerm = ""
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tClear\n\t\t\t\t\t")]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.filteredReferencesInPaper.length > 0
        ? _c(
            "div",
            { key: "divResearchNotesReferenceInPaper" },
            _vm._l(_vm.filteredReferencesInPaper, function (referenceObject) {
              return _c("reference-row", {
                key: referenceObject.referenceUniqueID,
                attrs: {
                  "reference-unique-id":
                    referenceObject.referenceUniqueID.toUpperCase(),
                  "reference-object": referenceObject,
                  "search-term": _vm.searchTerm,
                  "sort-by-enum": _vm.sortNotesBy,
                },
                on: {
                  "set-sort-by-date-newest-first": _vm.setSortByDateNewestFirst,
                },
              })
            }),
            1
          )
        : _c(
            "div",
            {
              key: "divReserachNotesNoReferencesInPaper",
              staticClass: "text-empty mt-2",
            },
            [
              _c("p", [
                _vm._v(
                  "Create a new reference or add an existing one to your paper."
                ),
              ]),
            ]
          ),
      _vm._v(" "),
      _c("route-modal"),
      _vm._v(" "),
      _c("modal-add-reference-from-library"),
      _vm._v(" "),
      _c("modal-research-note-citation-add"),
      _vm._v(" "),
      _c("modal-research-note-citation-edit"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }