<!--
- Dashboard > ClassList
-- Instead of Classes taking up real estate in the sidebar, they are now given their own space in the dashboard.
-- This solves the problem of the very limited space in the sidebar (long class names or lots of classes).
-- This change also reflects the larger role that a Class would have as a container of content in potential future productivity updates.
-- Dev: make this grid scroll vertically, so if there are more classes than will fit in the area based on the users screen, scroll up and down inside
-->

<template>
	<div class="col-6 position-relative">
		<div class="row">
			<div class="col">
				<h2 class="float-start">
					Classes
				</h2>
				<button class="btn btn-outline-success border-0 float-end" type="button" @click.prevent="$store.commit('modals/OPEN', 'ClassCreate')">
					<span class="svg bi-plus-lg me-1" /> New Class
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col overflow-y-scroll" ref="tabDashboardClassListWrp">
				<table v-if="filteredGridData.length > 0" class="table table-borderless entity-grid table-fixed-header mb-0">
					<thead>
						<tr>
							<th class="th-entity-title">
								Name
							</th>
							<th width="30px">
								&nbsp;	<!-- action menu -->
							</th>
						</tr>
					</thead>
					<tbody>
						<class-row
							v-for="classData in filteredGridData"
							:class-data-color-value="classData.colorValue"
							:class-data-encrypted-id="classData.encryptedID"
							:class-data-id="classData.classID"
							:class-data-name="classData.name"
							:is-full="false"
							:key="classData.classID"
							class-data-teacher-name="" />
							
						<!-- <tr>
							<td colspan="2">
								<router-link 
									class="btn btn-outline-primary border-0"
									:to="{
										name:'ClassList'
									}">
									View all classes
								</router-link>
							</td>
						</tr> -->
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No classes found.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';
import AS_SyncData from '@/services/app/syncData';
import ClassRow from '../Dashboard/ClassRow.vue';
import mixinResizer from '@/mixins/resizer';

export default {
	name: 'ClassList',
	mixins: [mixinResizer],
	data() {
		return {
			filteredGridData: [],
			rzRef: 'tabDashboardClassListWrp',
		}
	},
	computed:{
		showAlphaFeatures(){
			return this.$store.state.app.showAlphaFeatures;
		},
	},
	methods: {
		calcGridData(isFirstLoad){
			let searchedArray = [];

			let _data = _cloneDeep(this.$store.state.classes.list);

			_data.forEach((classData) =>{
				if(!classData.isArchived){
					searchedArray.push(classData);
				}
			});//e:forEach

			this.filteredGridData = _orderBy(searchedArray, [searchedArray => searchedArray['name'].toLowerCase()], ['asc']);

			if(isFirstLoad){
				// first load from Sync - go to the next one in line
				this.emitter.emit('loadUpcomingWorkList', {
					classListResults: this.filteredGridData.length
				});
			} else {
				// must have fired from updateClassList
				this.$store.commit('loaders/REMOVE_ID', 'App');
				this.emitter.emit('globalToasterOpen',{
					textContent: 'Class successfully archived',
				});
			}
			this.$nextTick(()=>{
				this.emitter.emit('resizeWindow');
			});
		},//e:calcGridData
	},
	created() {
		this.emitter.on('loadClassList', ()=>{
			this.calcGridData(true);
		});

		// emits after a class is archived (i need to just do another /Sync call to make it go away)
		this.emitter.on('updateClassList', ()=>{
			AS_SyncData().then(()=>{
				this.calcGridData(false);
			});
		});
	},
	components: {
		ClassRow,
	},
	destroyed(){
		this.emitter.off('loadClassList');
		this.emitter.off('updateClassList');
	}
}
</script>
