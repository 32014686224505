var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer drawer-references-citations" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", { attrs: { title: "Refs & Citations" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-grid mt-2 gap-2" },
          [
            _c("router-link", {
              attrs: {
                to: {
                  name: "OutlineCreateReferenceStep1",
                  params: {
                    openReferenceTab: "common",
                  },
                },
                custom: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ navigate }) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { role: "button", type: "button" },
                          on: {
                            click: navigate,
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return navigate.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tCreate a New Reference\n\t\t\t\t")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("router-link", {
              attrs: {
                to: {
                  name: "OutlineAddFromLibrary",
                },
                custom: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ navigate }) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { role: "button", type: "button" },
                          on: {
                            click: navigate,
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return navigate.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tAdd Reference from Library\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.isSeachSticky
              ? _c("div", { staticClass: "d-flex mb-2" }, [
                  _c("div", { staticClass: "w-100" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchTerm,
                          expression: "searchTerm",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        placeholder: "Search your references",
                        type: "text",
                      },
                      domProps: { value: _vm.searchTerm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.searchTerm = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchTerm && _vm.searchTerm.length > 0,
                          expression: "searchTerm && searchTerm.length > 0",
                        },
                      ],
                      staticClass: "flex-shrink-1",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link hover-underline",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.searchTerm = ""
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\tClear\n\t\t\t\t\t")]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "refsCitsScrollDrawerBody", staticClass: "drawer-body" },
      [
        _c("transition", { attrs: { name: "route-fade" } }, [
          _vm.showRefDeleteWaring
            ? _c("div", { staticClass: "close-warning" }, [
                _c("h1", [
                  _vm._v("\n\t\t\t\t\tRemove this Reference?\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\tRemoving the Reference from your paper will also delete its citations in your paper.  Are you sure you want to remove this reference?\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-grid gap-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeRefFromPaper.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tRemove Reference & Delete Citations\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.showRefDeleteWaring = false
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.filteredReferencesInPaper &&
        _vm.filteredReferencesInPaper.length > 0
          ? _c("div", { key: "divReferencesV2InPaper", staticClass: "mb-2" }, [
              _vm.isSearchScroll
                ? _c("div", { staticClass: "d-flex mb-2" }, [
                    _c("div", { staticClass: "w-100" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Search your references",
                          type: "text",
                        },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchTerm = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searchTerm && _vm.searchTerm.length > 0,
                            expression: "searchTerm && searchTerm.length > 0",
                          },
                        ],
                        staticClass: "flex-shrink-1",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link hover-underline",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.searchTerm = ""
                              },
                            },
                          },
                          [_vm._v("\n\t\t\t\t\t\tClear\n\t\t\t\t\t")]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "listOfReferencesWrp" },
                _vm._l(
                  _vm.filteredReferencesInPaper,
                  function (referenceObject) {
                    return _c("reference-row", {
                      key: referenceObject.referenceUniqueID,
                      attrs: {
                        "reference-unique-id":
                          referenceObject.referenceUniqueID.toUpperCase(),
                        "reference-object": referenceObject,
                      },
                    })
                  }
                ),
                1
              ),
            ])
          : _c(
              "div",
              { key: "divNoReferencesInPaper", staticClass: "text-empty mt-5" },
              [
                _c("p", [
                  _vm._v(
                    "Create a new reference or add an existing one to your paper using the above buttons."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "You can create citations once you've added a reference to your paper."
                  ),
                ]),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }