<!--
- Module > Create Reference Step 4 Third Party > Body
-->

<template>
	<div>
		<p class="org-size">
			Step 4: Confirm your reference
		</p>
			
		<reference-output />
		
		<section class="mb-5">
			<h3>Optional Content</h3>

			<form-textarea
				label="Description"
				help-text="Add a private description to your Reference."
				pendo-grab-text="Txt_RefCreate_Description"
				v-model="description" />

			<form-tags
				v-model="tags"
				pendo-grab-text="Txt_RefCreate_Tags" />
		</section> 
		
		<div class="row justify-content-between">
			<div class="col">
				<div class="d-grid">
					<a href="#" class="btn btn-outline-primary" @click.prevent="btnBack">
						Back
					</a>
				</div>
			</div>
			<div class="col text-end">
				<div class="d-grid">
					<button type="submit" class="btn btn-success" @click.prevent="createReference">
						Create
					</button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import FormTags from '@/components/form/Tags';
import FormTextarea from '@/components/form/Textarea';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import mixinTagCleaner from '@/mixins/tag-cleaner';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import ReferenceOutput from '@/components/ReferenceOutput';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'CreateReferenceStep4ThirdPartyBody',
	mixins: [mixinTagCleaner],
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		}
	},
	data() {
		return {
			description: '',
			tags: '',
		}
	},
	computed:{
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
	},
	methods: {
		btnBack(){
			this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');
			this.$router.go(-1);
		},
		createReference(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let params = {};

			// Whatever integer value you want to pass me to denote the reference type.
			params['referenceTypeId'] = this.$store.state.referenceRules.referenceTypeId;
			
			// A GUID to identify the reference. In the past I created this server side, but since they can create these without our server now, I need it passed to me.
			params['uniqueId'] = uuidv4().toUpperCase();

			let referenceRulesObject = {
				'data': this.$store.state.referenceRules.rulesObjectData,
				'values': this.$store.state.referenceRules.rulesObjectValues,
			}

			// Optional. Note/Description field like the old refs.
			if(this.description.length > 0){
				referenceRulesObject['note'] = this.description;
			}
			
			// Optional. Just like the old system.
			if(this.tags.length > 0){
				referenceRulesObject['tags'] = this.cleanTags(this.tags);
			}

			ApiS_MakeCall({
				body: referenceRulesObject,
				method: 'POST',
				params,
				responseType: 'json',
				url: 'v3/GenesisReference',
			}).then((responseData)=>{
				this.$store.commit('REFERENCE_ADD_TO_LIBRARY', responseData);

				this.$store.commit('references/RECENT_TYPE_IDS_ADD', this.$store.state.referenceRules.referenceTypeId);

				PS_AddReferenceToPaper({
					isDrawer: this.isDrawer,
					reference: responseData
				}).then(()=>{
					this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');

					if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
						if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
							this.$router.push({
								name:'EditorReferencesCitations'
							}).catch(()=>{});
							
						} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
							this.$router.push({
								name:'OutlineReferencesCitations'
							}).catch(()=>{});

						} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
							this.emitter.emit('closeRouteModal');
							this.emitter.emit('updateReferenceList');
							this.$store.commit('loaders/REMOVE_ID', 'App');
						}

					} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
						this.emitter.emit('updateReferenceList');
						this.emitter.emit('closeRouteModal');
						

					}
					
				});//e:PS_AddReferenceToPaper
				
			});//e:ApiS_MakeCall
		},//e:createReference
	},
	components: {
		FormTags,
		FormTextarea,
		ReferenceOutput,
	},
	destroyed(){
	}
}
</script>
