var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { ref: "divCalendarWrp", staticClass: "col-12" }, [
        _c("p", [_vm._v("Full Calendar")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("h1", { staticClass: "float-start me-4" }, [
          _vm._v("\n\t\t\t\tCalendar\n\t\t\t"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }