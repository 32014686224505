<!--
- Reference Convert Step 1
-->
<template>
	<div class="drawer">

		<div class="drawer-header">
			<tool-drawer-title
				title="Update Reference"
				close-to-route-name="EditorAddFromLibrary" />
		</div>
		
		<div class="drawer-body">
			<convert-reference-step1-body :is-drawer="true" />
		</div>
	</div>
</template>

<script>
import ConvertReferenceStep1Body from '@/modules/ConvertReferenceStep1Body';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditorConvertReferenceStep1',
	components: {
		ConvertReferenceStep1Body,
		ToolDrawerTitle,
	}
}
</script>
