var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.paperEdit.outline.citationObjects.length > 0,
          expression:
            "$store.state.paperEdit.outline.citationObjects.length > 0",
        },
      ],
      attrs: { id: "outlineFootnotesBlock" },
    },
    _vm._l(
      _vm.$store.state.paperEdit.outline.citationObjects,
      function (footnoteData, index) {
        return _c("outline-footnote-row", {
          key: index,
          attrs: { "footnote-data": footnoteData, "footnote-index": index },
        })
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }