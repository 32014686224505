<!--
- Reference Create Step 1 > Common Tab > Third Party Lookup
-- 
-->

<template>
	<div>
		<form @submit.prevent="doSearch">
			<div class="clearfix">
				<h3 class="float-start">
					Look up a reference
				</h3>
				<span class="svg bi-info-circle ms-1 has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Create book, journal, and website references by searching for them.  You can search by DOI, ISBN, URL, or author and title." role="tooltip" />
			</div>
			
			<transition name="route-fade" mode="out-in">
				<div v-if="isSearching" key="divIsSearching">
					<div class="alert alert-info clearfix">
						<span>
							Looking for your reference <animated-ellipsis />
						</span>

						<!-- <button type="button" class="btn-close float-end" aria-label="Close" @click.prevent="cancelSearch" /> -->
					</div>
				</div>
				<div v-else key="divNotSearching">
					<div class="d-flex">
						<div class="w-100">
							<div :class="['input-group', {'input-group-error': searchErrorText != ''}]">
								<input
									:class="['form-control', {'border-end-0': searchTerm != ''}]"
									aria-label="Look up a reference"
									autocomplete="off"
									id="ThirdPartyLookup"
									ref="focusSearchTerm"
									type="text"
									v-model="searchTerm" />
										
								<button v-show="searchTerm != ''" type="button" class="btn search-clear px-2" @click.prevent="clearSearch" title="Clear Search">
									<span aria-hidden="true">&times;</span>
								</button>

							</div>
						</div>

						<div class="flex-shrink-1" v-show="searchTerm != ''">
							<button type="submit" class="btn btn-link hover-underline" title="Search">
								Search
							</button>
						</div>
					</div>
								
					<div class="help-text mt-2 text-danger" v-if="searchErrorText != ''">
						{{ searchErrorText }}
					</div>
					<div class="help-text mt-2" v-else>
						Search for journal articles and books using DOI, ISBN, or author &amp; title. Find webpages using a URL.
					</div>
				</div>
			</transition>
		</form>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import AnimatedEllipsis from '@/components/AnimatedEllipsis';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import Convert3pluTypeToUrl from '@/helpers/convert-3plu-type-to-url';
import Tooltip from 'bootstrap/js/dist/tooltip';
// const controller = new AbortController();
// const signal = controller.signal;

export default {
	name: 'ReferenceCreateStep1SearchThirdParty',
	props:{
		isDrawer:{
			default: true,
			type: Boolean,
		},
		isFirstSearch:{
			default: false,
			type: Boolean,
		},
		refSelectCurrent: {
			default: 1,
			type: Number,
		},
	},
	data() {
		return {
			isSearching: false,					// Boolean: Active searching while the CrossRef API is running
			searchTerm: '',
			searchErrorText: '',
		}
	},
	computed:{
		thirdPartySearchTerm(){
			return this.$store.state.thirdPartyLookup.searchTerm;
		},
	},
	methods: {
		// cancelSearch(){
		// 	console.log('cancelSearch');
		// 	controller.abort();
		// 	this.isSearching = false;
		// },

		clearSearch(){
			this.searchTerm = '';
			this.searchErrorText = '';
			this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');
		},//e:clearSearch
		
		doSearch(){
			this.searchErrorText = '';
			
			if(this.searchTerm.trim() === ''){
				return false;
			}

			this.$store.commit('referenceDuplicate/HIDE_WARNING');

			this.isSearching = true;

			if(!this.isFirstSearch){
				// second search - empty results first
				this.$store.commit('thirdPartyLookup/REFERENCE_RESULTS', []);
			}

			this.$store.commit('thirdPartyLookup/SEARCH_TERM', this.searchTerm.trim());

			let cleanSearchTerm = this.thirdPartySearchTerm.replace('&', '%26');

			// https://perrla.monday.com/boards/1892814374/pulses/2173937175
			// users are using 3plu with terms for Reference types, check them here before going to the server
			let isRefTypeMatch = false;
			let refTypeRedirectSlug = '';

			switch(cleanSearchTerm){
				case 'bible':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'religious-work';
					break;
				case 'book':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'book';
					break;
				case 'blog':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'blog';
					break;
				case 'article':
				case 'journal':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'journal';
					break;
				case 'web':
				case 'webpage':
				case 'website':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'webpage';
					break;
				case 'youtube':
				case 'video':
					isRefTypeMatch = true;
					refTypeRedirectSlug = 'videos';
					break;
			}//e:switch

			if(isRefTypeMatch){
				// refType search term
				this.isSearching = false;

				// set a flag to show 3plu search term (yello bow on Create Ref Step 2 Body)
				this.$store.commit('SET_SHOW_3PLU_REDIRECT_MESSAGE', true);
				// console.log(this.$router.currentRoute.meta.module);
				// console.log(this.$router.currentRoute.meta.tab);

				if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
					if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
						this.$router.push({
							name:'EditorCreateReferenceStep2',
							params:{
								openReferenceTab: 'common',
								referenceTypeSlug: refTypeRedirectSlug
							}
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
						this.$router.push({
							name:'OutlineCreateReferenceStep2',
							params:{
								openReferenceTab: 'common',
								referenceTypeSlug: refTypeRedirectSlug
							}
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
						this.$router.push({
							name: 'PaperTabResearchCreateReferenceStep2',
							params: {
								openReferenceTab: 'common',
								referenceTypeSlug: refTypeRedirectSlug
							},
						}).catch(()=>{});

					}
				} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
					this.$router.push({
						name: 'ReferenceListCreateReferenceStep2',
						params: {
							openReferenceTab: 'common',
							referenceTypeSlug: refTypeRedirectSlug
						},
					}).catch(()=>{});
				}
				
			} else {
				// real 3plu search term
				// nextTick here so the UI can catch up to blank results before loading new ones
				this.$nextTick(()=>{
					/*
					Before doing a 3PLU, check their existing library for any references that
					contain a matching DOI, ISBN, or URL. We may need to "normalize" this some
					(e.g., "http://" vs "https://", stripping out just the number for DOI/ISBN)
					to compare correctly.
					*/
					/*
						978-0-316-47191-6 :: 0316471917
						978-1-975185-72-5 :: 1975185722
					*/
					
					// sanitize the search term to check for URL matches
					let sanitizedSearchTermURL = this.sanitizeSearchTermURL(cleanSearchTerm);
					let sanitizedSearchTermDOI = this.sanitizeSearchTermDOI(cleanSearchTerm);
					let sanitizedSearchTermISBN = this.sanitizeSearchTermISBN(cleanSearchTerm, true);
					
					_forEach(this.$store.state.referenceLibraryGenesis, (referenceObject)=>{
						if(!referenceObject.isDeleted){
							try{
								let refData = JSON.parse(referenceObject.data);

								// check url									
								if(_has(refData, 'url') && refData.url !== ''){
									let sanitizedReferenceURL = this.sanitizeSearchTermURL(refData.url);
									if(
										sanitizedReferenceURL !== '' &&
										sanitizedSearchTermURL !== '' &&
										sanitizedSearchTermURL === sanitizedReferenceURL
									){
										this.$store.dispatch('referenceDuplicate/processDuplicate', {
											refData: refData,
											referenceObject:referenceObject,
										});
										return false;	// stop looping one was found
									}//e:if:url
								}//e:if:_has:url
								
								// check doi
								if(_has(refData, 'doi') && refData.doi !== ''){
									let sanitizedReferenceDOI = this.sanitizeSearchTermDOI(refData.doi);
									if(
										sanitizedReferenceDOI !== '' &&
										sanitizedSearchTermDOI !== '' &&
										sanitizedSearchTermDOI === refData.doi
									){
										this.$store.dispatch('referenceDuplicate/processDuplicate', {
											refData: refData,
											referenceObject:referenceObject,
										});
										return false;	// stop looping one was found
									}//e:if:doi
								}//e:if:_has:doi
								
								// check isbn
								if(_has(refData, 'isbNs') && refData.isbNs.length > 0){
									refData.isbNs.forEach((isbn)=>{
										let sanitizedReferenceISBN = this.sanitizeSearchTermISBN(isbn, false);

										if(sanitizedReferenceISBN){
											if(
												sanitizedSearchTermISBN !== '' &&
												sanitizedReferenceISBN != '' &&
												sanitizedSearchTermISBN === sanitizedReferenceISBN
											){
												this.$store.dispatch('referenceDuplicate/processDuplicate', {
													refData: refData,
													referenceObject:referenceObject,
												});
												return false;	// stop looping one was found
											}//e:if:url
										}
									});//e:foreach
									
								}//e:if:_has:isbNs

							} catch(error){
								console.log('error SearchThirdParty.vue');
								console.log(error);

							}//e:try/catch
						}//e:if:!referenceObject.isDeleted
					});//e:_forEach
					
					if(this.$store.state.referenceDuplicate.isShowWarning){
						this.isSearching = false;

					} else {
						// there wasn't a duplicate reference detected, so do the actual search now
						ApiS_MakeCall({
							method: 'GET',
							params:{
								search: cleanSearchTerm,
							},
							responseType: 'json',
							url: 'v4/GenesisReference/Lookup',
							// signal,
						}).then((responseData)=>{
							// if(!this.$isLive){
							// 	console.log('v4/GenesisReference/Lookup');
							// 	console.log(responseData);
							// }

							// Store results somewhere
							this.$store.commit('thirdPartyLookup/REFERENCE_RESULTS', responseData.results);

							// are there any messages
							// https://perrla.monday.com/boards/1892814374/pulses/2277209120
							if(responseData.messages.length > 0){
								// console.log(responseData.messages[0].messageTypeID);
								switch(responseData.messages[0].messageTypeID){
									case 1:	// DOIInvalid
										this.searchErrorText = `This is an invalid DOI. Check the DOI for any errors or omissions. You can also search by author & title or create the reference manually.`;
										break;
									case 2:	// DOIUnrecognizedRegistrationAgency
										this.searchErrorText = `We do not have access to this DOI's information. You can search for the work by author & title or create the reference manually.`;
										break;
									case 3:	// URLUnauthorized
										this.searchErrorText = `This URL requires a login to retrieve information. You can create the reference manually by choosing a reference type in the All tab.`;
										break;
									case 4:	// URLTimeout
										this.searchErrorText = `The URL you entered is not responding. Try again or create the reference manually by choosing a reference type in the All tab.`;
										break;
									case 5:	// URLNotFound
										this.searchErrorText = `This is not an active URL. Check the url is correct or create the reference manually by choosing a reference type in the All tab.`;
										break;
									case 7:	// Local File
										this.searchErrorText = `It looks like you're trying to use a file stored on your computer. We can't search those for information yet. Try again with a different search criteria.`;
										break;
									default:
										this.searchErrorText = `We were unable to process this search. Try again or create the reference manually.`;
								}

							}//e:if:responseData.messages.length

							if(responseData.results.length === 1){
								// One Result - directly to step 3 - ex: 10.1093/ajae/aaq063

								this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', responseData.results[0].id);
								this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', responseData.results[0].typeLabel);

								this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', responseData.results[0].referenceData);
								
								let calcReferenceTypeSlug = Convert3pluTypeToUrl(responseData.results[0]);

								if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
									if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
										this.$router.push({
											name:'EditorCreateStep3ThirdParty',
											params:{
												referenceTypeSlug: calcReferenceTypeSlug
											}
										}).catch(()=>{});
									} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
										this.$router.push({
											name:'OutlineCreateStep3ThirdParty',
											params:{
												referenceTypeSlug: calcReferenceTypeSlug
											}
										}).catch(()=>{});
									} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
										this.$router.push({
											name: 'PaperTabResearchCreateReferenceStep3ThirdParty',
											params:{
												referenceTypeSlug: calcReferenceTypeSlug
											}
										}).catch(()=>{});
									}
								} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
									this.$router.push({
										name: 'ReferenceListCreateReferenceStep3ThirdParty',
										params:{
											referenceTypeSlug: calcReferenceTypeSlug
										}
									}).catch(()=>{});
								}

							} else if (responseData.results.length > 1){
								// More than One Result
								if(this.isFirstSearch){
									if(this.$router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
										if(this.$router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
											this.$router.push({
												name:'EditorCreateStep2ThirdParty'
											}).catch(()=>{});
										} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
											this.$router.push({
												name:'OutlineCreateStep2ThirdParty'
											}).catch(()=>{});
										} else if(this.$router.currentRoute.meta.tab === config.enums.Tab.RESEARCH){
											this.$router.push({
												name: 'PaperTabResearchCreateReferenceStep2ThirdParty',
											}).catch(()=>{});
										}
									} else if(this.$router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
										this.$router.push({
											name: 'ReferenceListCreateReferenceStep2ThirdParty',
										}).catch(()=>{});
									}
								} else {
									// Searching again from results page (step 2)
									this.emitter.emit('thirdPartySearchComplete');
								}
							} else {
								// No Results (and no messages)
								if(this.searchErrorText === ''){
									this.searchErrorText = 'Oops! Please try again or modify your search terms. You can search by DOI, ISBN, Author, Title, or URL.';
								}
							}

							this.isSearching = false;

						}).catch((error) => {
							this.isSearching = false;

							if(error && error === 'The user aborted a request.'){
								this.emitter.emit('globalToasterOpen',{
									textContent: 'Search canceled',
								});
							}
						});//e:api/makeCall

					}//e:if:this.$store.state.referenceDuplicate.isShowWarning
					
				});//e:nextTick
			}//e:if:else:isRefTypeMatch
		},//e:doSearch
		
		sanitizeSearchTermDOI(orgDoi){
			let sanitizedSearchTermDOI = orgDoi
				.replace(/https:\/\/doi.org\//, '')
				.replace(/http:\/\/doi.org\//, '');

			return sanitizedSearchTermDOI;
		},//e:sanitizeSearchTermDOI

		sanitizeSearchTermISBN(orgIsbn, isSearchTerm){
			let sanitizedSeachTermISBN = orgIsbn.replace(/-/g, '');
			
			if(isSearchTerm){
				sanitizedSeachTermISBN = sanitizedSeachTermISBN.substring(3); // remove the first 3 chracters (Prefix element – currently this can only be either 978 or 979. It is always 3 digits in length)
			}

			sanitizedSeachTermISBN = sanitizedSeachTermISBN.substring(0, sanitizedSeachTermISBN.length - 1); // remove the last chracters (Check digit – this is always the final single digit that mathematically validates the rest of the number. It is calculated using a Modulus 10 system with alternate weights of 1 and 3.)				)
			
			return sanitizedSeachTermISBN;
		},

		sanitizeSearchTermURL(orgUrl){
			// remove protocol
			let sanitizedSearchTermURL = orgUrl.replace(/(^\w+:|^)\/\//, '');
			// remove any querty strings
			//return sanitizedSearchTermURL.split(/[?#]/)[0];
			return sanitizedSearchTermURL;
		},//e:sanitizeSearchTerm

	},
	mounted(){
		this.searchTerm = this.thirdPartySearchTerm;
		if(this.isFirstSearch){
			if(this.$refs.focusSearchTerm){
				this.$refs.focusSearchTerm.focus();
			}
		}
	},
	components:{
		AnimatedEllipsis
	},
}
</script>

<style lang="scss" scoped>
	@import "./../../assets/styles/vars_perrla.scss";
	@include color-mode(dark) {
		button.search-clear {
			background-color: $dark-input-bg;
			border-color: $dark-input-border-color;
			color: $dark-body-color;
		}
	}
	
	.input-group-error{
		input.form-control, button.search-clear{
			border-color: $danger-500 !important;
		}
	}

	button.search-clear {
		border: 1px solid $input-border-color;
		border-left: none;
		border-radius: 0;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		color: $gray-500;
		font-size: $font-size-lg;
		font-weight: $font-weight-bold;
		line-height: 1;
	}
	
</style>