<!--
- PERRLA Online Layout > Header
-->

<template>
	<nav :class="['nav-header', {'nav-header-alpha': showAlphaFeatures && showBetaFeatures, 'nav-header-beta': !showAlphaFeatures && showBetaFeatures}]">
		
		<router-link :to="{name:'Dashboard'}" class="logo-link me-3 ps-3" title="PERRLA">
			<img src="///perrla.s3.amazonaws.com/images/logo-p-small.png" class="me-2" alt="PERRLA, LLC" />
			<span>PERRLA</span>
			<h1>PERRLA</h1>
		</router-link>

		<quick-add-menu v-if="$route.meta.module === $enums.Module.DASHBOARD" />
		
		<p class="d-none d-md-block mb-0" v-if="($route.meta.module === $enums.Module.DASHBOARD) && (subscriptionExpiresMessageOutput != '' && $store.state.customer.organizationId === 0)">
			<router-link
				class="hover-underline"
				:to="{
					name: 'MyProfile'
				}">
				{{ subscriptionExpiresMessageOutput }} 
				
			</router-link>
		</p>
	
		<!-- Impersonation Menu -->
		<ul class="navbar-nav ms-auto">
			<li class="nav-item dropdown" v-if="showImpersonationMenu">
				<a :class="['nav-link dropdown-trigger-icon']" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-label="DevTools">
					<span :class="['svg', {'bi-lightning-fill': showAlphaFeatures || showBetaFeatures, 'bi-lightning': !showAlphaFeatures && !showBetaFeatures}]" />
				</a>
				<ul class="dropdown-menu dropdown-menu-end">
					<li v-if="showAlphaFeatures">
						<a href="#" @click.prevent="setShowAlphaFeatures(false)" class="dropdown-item hover hover-danger" key="dropdownHideAlpha">
							Alpha Features Off
						</a>
					</li>
					<li v-else-if="!showAlphaFeatures">
						<a href="#" @click.prevent="setShowAlphaFeatures(true)" class="dropdown-item hover hover-danger" key="dropdownShowAlpha">
							Alpha Features On
						</a>
					</li>

					<li v-if="showBetaFeatures">
						<a href="#" @click.prevent="setShowBetaFeatures(false)" class="dropdown-item hover hover-warning" key="dropdownHideBeta">
							Beta Features Off
						</a>
					</li>
					<li v-else>
						<a href="#" @click.prevent="setShowBetaFeatures(true)" class="dropdown-item hover hover-warning" key="dropdownShowBeta">
							Beta Features On
						</a>
					</li>
					<li>
						<a href="#" @click.prevent="clearLocalLogs" class="dropdown-item hover hover-primary">
							Clear Local Logs
						</a>
					</li>
					<li>
						<a href="#" @click.prevent="clearLocalPapers" class="dropdown-item hover hover-primary">
							Clear Local Papers
						</a>
					</li>
					<li>
						<a href="#" @click.prevent="clearLocalStorage" class="dropdown-item hover hover-primary">
							Clear Local Storage
						</a>
					</li>
					
					<li>
						<router-link :to="{name:'CountDown'}" class="dropdown-item hover hover-primary">
							Countdown Timer
						</router-link>
					</li>
					<li>
						<router-link :to="{name:'ErrorTests'}" class="dropdown-item hover hover-danger">
							Error Tests
						</router-link>
					</li>
					<li>
						<a href="#" @click.prevent="expireCurrentToken" class="dropdown-item hover hover-danger">
							Expire Current Token
						</a>
					</li>
					<li>
						<router-link :to="{name:'FreeTrialDiscount'}" class="dropdown-item hover hover-success">
							Free Trial Discount
						</router-link>
					</li>
					<li>
						<router-link :to="{name:'DataDecoder'}" class="dropdown-item hover hover-success">
							Data Decoder
						</router-link>
					</li>
				</ul>
			</li>
			<li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span id="userFullName">{{ $store.state.customer.fullName }}</span>
				</a>
				<ul class="dropdown-menu dropdown-menu-end">
					<li>
						<router-link
							:to="{
								name: 'MyProfile'
							}"
							class="dropdown-item"
							key="settingsElse">
							Settings
						</router-link>
					</li>
					<li>
						<router-link :to="{name:'Logout'}" class="dropdown-item">
							Logout
						</router-link>
					</li>
				</ul>
			</li>
		</ul>

	</nav>
</template>

<script>
import config from '@/config';
import { differenceInDays } from 'date-fns'
import mixinUrlToEnv from '@/mixins/url-to-env.js';
import QuickAddMenu from '@/views/Dashboard/QuickAddMenu';

export default {
	name: 'HeaderMenu',
	mixins: [mixinUrlToEnv],
	computed:{
		showAlphaFeatures(){
			return this.$store.state.app.showAlphaFeatures;
		},
		showBetaFeatures(){
			return this.$store.state.app.showBetaFeatures;
		},
		showImpersonationMenu(){
			if(this.$isLive){
				return this.$store.state.customer.isImpersonating;
			}
			return true;
		},
		subscriptionExpiresMessageOutput(){
			// If a user's expiration date is within 14 days, show text to the right of the Create New button: "Your subscription expires in [days] days."
			if(this.$store.state.customer.expirationDate !== ''){
				let expirationDate = new Date(this.$store.state.customer.expirationDate);

				let diferenceInDays = differenceInDays(expirationDate.getTime(), config.today.getTime());

				if(diferenceInDays === 0){
					return 'Your PERRLA Free Trial expires today!';

				} else if(diferenceInDays <= 14 && diferenceInDays > 0){
					let returnString = diferenceInDays + ' day'
					if(diferenceInDays > 1){
						returnString += 's';
					}
					return  'Your subscription expires in ' + returnString +'.';

				} else if(diferenceInDays < 0){
					return 'Your PERRLA Free Trial has expired.';
				}
			}
			
			return '';
		},
					
	},
	methods: {
		clearLocalLogs(){
			this.$store.dispatch('logs/clearAll');
		},
		clearLocalPapers(){
			this.$store.dispatch('paperEdit/localVersion/clearAll');
		},
		clearLocalStorage(){
			localStorage.clear();
			alert('local storage cleared');
		},

		expireCurrentToken(){
			// set a new token that will be bad, ideally this should let you get a new token when you make an api call and get a 401
			const expiredToken = 'xyJhbGciOiJSUzI1NiIsImtpZCI6IjM2RTg0QTc3NDMyMDlGMERDQzUyQzFBQzZGOEZCQjdBQTYxOEE4OEYiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJOdWhLZDBNZ253M01Vc0dzYjQtN2VxWVlxSTgifQ.eyJuYmYiOjE2Mjk3Mjk2NDMsImV4cCI6MTYzNzUwNTY0MywiaXNzIjoiaHR0cHM6Ly90ZXN0YXV0aC5wZXJybGEuY29tIiwiYXVkIjpbImh0dHBzOi8vdGVzdGF1dGgucGVycmxhLmNvbS9yZXNvdXJjZXMiLCJwZXJybGFfYXBpX3YzIl0sImNsaWVudF9pZCI6ImNvbXBsZXRlIiwic3ViIjoiNSIsImF1dGhfdGltZSI6MTYyOTcyOTY0MywiaWRwIjoibG9jYWwiLCJjdXN0b21lcl9pZCI6IjUiLCJmaXJzdF9uYW1lIjoiUmlsZXkiLCJsYXN0X25hbWUiOiJNb29yZSIsInN1YnNjcmlwdGlvbl9leHBpcmF0aW9uIjoiMS8xLzIwOTkgMTI6MDA6MDAgQU0iLCJpbXBlcnNvbmF0aW5nIjpbIkZhbHNlIiwiRmFsc2UiXSwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSIsInBlcnJsYV9hcGlfdjMiXSwiYW1yIjpbImN1c3RvbSJdfQ.gepNq1OuutwaWG132eGoE_tMaaV4-KrfPmGoP99TTGZ24Q73Uy0OfBrf_jQckTh39rZwGdLWqZv_gBuBBGvhtzIXWfVGZXadG7Lq44m1TUqfpVcs7tN6bOd72yRa8vyglWErInVfsJvlIhUVfR12H2bZvUYuBkYtGu8K-3iw35N5tntcZ8C38fNSQWfcH9ya439zBAlsFIKnx6yAcGBV6lD43nrE3ZZG2FNk_Mqjgv9GGh0nICVzsNggIdcRvwrc7Ft2Wv0I8ZaYHy_8Z7qgQAzSHl9_MUyTccjhDQ75rNOJifDPcz7T-zCC3cBlzEPxZ4JBt6Caf14LYRiIBnQsJw';
			
			this.$store.commit('api/SET_TOKEN', expiredToken);

			this.emitter.emit('globalToasterOpen',{
				textContent: 'Current token has been replaced with an expired one.',
			});
		},
		
		setShowAlphaFeatures(setTo){
			this.$store.commit('app/SET_SHOW_ALPHA_FEATURES', setTo);
			
			// turning on Alpha / turn on Beta
			if(setTo){
				this.$store.commit('app/SET_SHOW_BETA_FEATURES', setTo);
			}
		},
		setShowBetaFeatures(setTo){
			this.$store.commit('app/SET_SHOW_BETA_FEATURES', setTo);

			// turning off Beta / turn off Alpha
			if(!setTo){
				this.$store.commit('app/SET_SHOW_ALPHA_FEATURES', setTo);
			}
		},
	},
	components:{
		QuickAddMenu
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";
	
	@include color-mode(dark) {
		.nav-header {
			background-color: $gray-800;
			border-bottom-color: $extra-dark;
		}
	}
	
	body.theme-serrena {
		.nav-header {
			background-color: #512151;
			border-bottom-color: shade-color(#512151, $btn-active-bg-tint-amount);
		}
	}
	
	body.theme-serrena {
		.nav-header {
			.logo-link {
				span {
					color: $gray-800;
				}
				h1 {
					color: $dark-body-color;
				}

				&:hover {
					h1 {
						color: shade-color($dark-body-color, $btn-active-bg-tint-amount),
					}
				}
			}

	
			.navbar-nav {
				> li > a {
					color: $dark-body-color;

					&:hover {
						background: $gray-700;
					}

				}
			}
		
		}
	}

	@include color-mode(dark) {
		.nav-header {
			.logo-link {
				span {
					color: $gray-800;
				}
				h1 {
					color: $dark-body-color;
				}

				&:hover {
					h1 {
						color: shade-color($dark-body-color, $btn-active-bg-tint-amount),
					}
				}
			}

	
			.navbar-nav {
				> li > a {
					color: $dark-body-color;

					&:hover {
						background: $gray-700;
					}

				}
			}
		
		}
	}


	.nav-header {
		align-items: center;
		background: $gray-200;
		border-bottom: 2px solid $body-color;
		display: flex;
		height: $po-navbar-height;
		z-index: 105;

		// eventually take this out when every page can inner scroll
		left: 0;
		position: fixed;
		right: 0;
		top: 0;

		&.nav-header-alpha{
			.bi-lightning-fill{
				color: $danger;
			}
		}
		&.nav-header-beta{
			.bi-lightning-fill{
				color: $warning;
			}
		}

		.logo-link{
			display: flex;
			justify-content: flex-start;
			text-decoration: none;

			img {
				margin-top: calc(($po-navbar-height - ($po-navbar-height * 0.7)) / 2);
				height: $po-navbar-height * 0.7;
				width: $po-navbar-height * 0.7;
			}
			
			// pre-logo text
			span {
				color: $gray-200;
				font-size: 0px;
				visibility: hidden;
			}

			h1 {
				margin-bottom:0;
				color: $body-color;
				font-size: 2rem;
				font-weight: 800;
				line-height: $po-navbar-height;
			}


			&:hover {
				h1 {
					color: tint-color($body-color, $btn-active-bg-tint-amount),
				}
			}
		}//.logo-link

		.navbar-nav {
			flex-direction: row;

			> li > a {
				color: $body-color;
				font-size: $font-size-base;
				padding: 0 0.75rem;
				line-height: $po-navbar-height;
				text-decoration:none;
				font-weight: 600;

				&:hover {
					background: $gray-400;
				}

				&.dropdown-trigger-icon {
					height: $po-navbar-height;
					
					span.svg {
						display:block;
						font-size: 1.5rem;
					}
				}

				&.dropdown-trigger-icon-alpha{
					color: $danger-500;
				}
			}

			> li.open > a {
				background: $gray-400;
			}   
		
			> .dropdown > a .caret {
				border-bottom-color: $gray-700;
				border-top-color: $gray-700;
			}
		} //.navbar-nav

	}//.nav-header
</style>