/*
- Table of Contents
-- under PaperEdit module 
-- ex: this.$store.state.paperEdit.toc.x
*/
import _forEach from 'lodash/forEach';
import config from '@/config';

const emptyContentHtml = `<p class="placeholder-text text-muted">
							The Table of Contents is built from the Headings you've included in your paper.
							<br />
							Add Headings into your paper, then refresh the Table of Contents using the button below
						</p>`;

export default {
	namespaced: true,
	
	state:{
		content: emptyContentHtml,
	},//e:state

	mutations: {
		CONTENT(state, value) {
			state.content = value;
		},
	},//e:mutations

	actions:{
		generateContent({commit, rootState}) {
			return new Promise((resolve) => {
				// blank guard
				if(!rootState.paperEdit.meta.IncludeTableOfContents || !CKEDITOR.instances.ckPaperBody || !CKEDITOR.instances.ckPaperBody.document){
					commit('CONTENT', '')
					return resolve();
				}

				// depending on what is found in the paper, there might not be real TOC content to generate and show
				let showTocContent = false;

				// reset page count
				let pageCount;
				if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					pageCount = 1;  // turabian starts with 1 at the body content
				} else {
					pageCount = 2;  // starts at 1 (title page is 1)
				}

				// local var to build toc content piece by piece then swap it over to the reactive content data at the very end (might prevent page/location jumping after adding a citation)
				let buildTocContent = '<ul class="dotted">';

				// Abstract (optional)
				if (rootState.paperEdit.meta.IncludeAbstract && rootState.paperEdit.meta.PaperFormatVersionID != config.enums.Format.Turabian9) {
					pageCount++;
				}
				
				// Table of Content (optional)
				if (rootState.paperEdit.meta.IncludeTableOfContents && rootState.paperEdit.meta.PaperFormatVersionID != config.enums.Format.Turabian9) {
					pageCount++;
				}

				// Paper Title
				if(rootState.paperEdit.meta.PaperFormatVersionID !== config.enums.Format.Turabian9){
					buildTocContent += '<li class="toc-h1">';
					buildTocContent += '<span>' + rootState.paperEdit.meta.Title + ' </span>';
					buildTocContent += '<span>' + pageCount + '</span>';
					buildTocContent += '</li>';
				}

				// Paper Body - scan for Hs with a nested order
				let $headingListEl = CKEDITOR.instances.ckPaperBody.document.find('h1,h2,h3,h4,span.h4,h5,span.h5');
				if ($headingListEl.count() > 0) {
					showTocContent = true;

					// loop through headingListEl to build toc output
					_forEach($headingListEl.$, (element) => {
						buildTocContent += calcHeadingOutput(element);
					});//e:_forEach
				}//e:if:$headingListEl.

				// References and Appendix are switched in Turabian9
				if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
					// Appendices (optional)
					_forEach(rootState.paperEdit.appendices.inPaper, (appendixData)=>{
						// add an h1 for the appendix title
						buildTocContent += '<li class="toc-h1">';
						buildTocContent += '<span>' + appendixData.label + ' </span>';
						buildTocContent += '<span># </span>';
						buildTocContent += '</li>';
						
						// scan that appendix instance for headings inside it
						if(CKEDITOR.instances['ck_appendix_' + appendixData.uid] && CKEDITOR.instances['ck_appendix_' + appendixData.uid].document){
							let $headingListEl = CKEDITOR.instances['ck_appendix_' + appendixData.uid].document.find('h1,h2,h3,span.h4,span.h5');
							if ($headingListEl.count() > 0) {
								// loop through headingListEl to build toc output
								_forEach($headingListEl.$, (element) => {
									// appendix title don't count toward the TOC, that's ok i can skip this whole block here
									if(!element.classList.contains('appendix-title')){
										buildTocContent += calcHeadingOutput(element);
									}
								})//e:forEach
							}//e:if
						}
					});
					
					// References (optional) - Bibliography in Turabian 
					if (rootState.paperEdit.referencesInPaper.length > 0) {
						showTocContent = true;
						buildTocContent += '<li class="toc-h1">';

						// RP or DP - check format
						if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 || rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
							buildTocContent += '<span>Bibliography </span>';
						} else if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
							// check for single or multiple referneces
							if(rootState.paperEdit.referencesInPaper.length === 1){
								buildTocContent += '<span>Work Cited </span>';
							} else {
								buildTocContent += '<span>Works Cited </span>';
							}
						}

						buildTocContent += '<span># </span>';
						buildTocContent += '</li>';
					}

				} else {
					// References (optional)
					if (rootState.paperEdit.referencesInPaper.length > 0) {
						showTocContent = true;
						buildTocContent += '<li>';

						// RP or DP - check format
						if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 || rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
							buildTocContent += '<span>References </span>';
						} else if(rootState.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
							// check for single or multiple referneces
							if(rootState.paperEdit.referencesInPaper.length === 1){
								buildTocContent += '<span>Work Cited </span>';
							} else {
								buildTocContent += '<span>Works Cited </span>';
							}
						}

						buildTocContent += '<span># </span>';
						buildTocContent += '</li>';
					}

					// Appendices (optional)
					_forEach(rootState.paperEdit.appendices.inPaper, (appendixData)=>{
						// add an h1 for the appendix title
						buildTocContent += '<li>';
						buildTocContent += '<span>' + appendixData.label + ' </span>';
						buildTocContent += '<span># </span>';
						buildTocContent += '</li>';
						
						// scan that appendix instance for headings inside it
						if(CKEDITOR.instances['ck_appendix_' + appendixData.uid] && CKEDITOR.instances['ck_appendix_' + appendixData.uid].document){
							let $headingListEl = CKEDITOR.instances['ck_appendix_' + appendixData.uid].document.find('h1,h2,h3,span.h4,span.h5');
							if ($headingListEl.count() > 0) {
								// loop through headingListEl to build toc output
								_forEach($headingListEl.$, (element) => {
									// appendix title don't count toward the TOC, that's ok i can skip this whole block here
									if(!element.classList.contains('appendix-title')){
										buildTocContent += calcHeadingOutput(element);
									}
								})//e:forEach
							}//e:if
						}
					});
				}


				buildTocContent += '</ul>';

				if(showTocContent){
					commit('CONTENT', buildTocContent);
				} else {
					commit('CONTENT', emptyContentHtml);
				}
				
				return resolve();
			});//e:Promise
		},//e:generateContent
	}
}

// takes a heading element and returns what should output in the TOC (body and appendix loop)
function calcHeadingOutput(element){
	let buildTocContent = '';

	let tagName = element.tagName.toLowerCase();
	let tagText = element.textContent.split('.').join("");	// remove any periods from the tag name (so they don't look weird with the line of dots)

	// don't show empty headings (could be with format changes not yet cleaned up)
	if (tagText.trim().length > 0) {
		let pageOutput = '#';
		let headingClass = '';
		
		// Check for each heading type, setting a class for each
		if (tagName == 'span') {
			if (element.classList.contains('h3')) {
				headingClass = 'toc-h3';
			} else if (element.classList.contains('h4')) {
				headingClass = 'toc-h4';
			} else {
				headingClass = 'toc-h5';
			}

		} else {
			headingClass = 'toc-' + tagName;
		}

		// output block (all headings)
		buildTocContent += '<li class="' + headingClass + '">';
		buildTocContent += '<span>' + tagText + ' </span>';
		buildTocContent += '<span>' + pageOutput + ' </span>';
		buildTocContent += '</li>';

		return buildTocContent;
	}//e:if trim

}//e:calcHeadingOutput