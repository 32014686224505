var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "paper-editor-footer",
        {
          "paper-editor-footer-tool-drawer-open":
            _vm.$store.state.paperEdit.isDrawerOpen,
        },
      ],
    },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-light btn-sm d-block mx-auto mt-1",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.openWordCountModal.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.wordCount) +
              " Words (" +
              _vm._s(_vm.sectionDisplay) +
              ")\n\t\t"
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }