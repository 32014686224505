<!--
- Outline Editor > Tool Drawer > Create Step 2 Third Party Lookup
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Create a reference"
				:is-browser-back="true" />
			<create-reference-step-2-third-party-header :is-drawer="true" />
		</div><!--drawer-header-->

		<div class="drawer-body" ref="scrollableBody">
			<create-reference-step-2-third-party-body :is-drawer="true" />
		</div><!--drawer-body-->
	</div>
</template>

<script>
import _throttle from 'lodash/throttle';
import CreateReferenceStep2ThirdPartyBody from '@/modules/CreateReferenceStep2ThirdParty/Body'
import CreateReferenceStep2ThirdPartyHeader from '@/modules/CreateReferenceStep2ThirdParty/Header'
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

const showManualCreateThreshold = 400;	//px amount user scrolls down before scrolling back up shows the button

export default {
	name: 'OutlineCreateStep2ThirdParty',
	data() {
		return {
			isReadyToShowManualCreate: false,	// changes to true once when user scrolls down to the threshold
			lastScrollTop: 0,
			showManualCreateButton: false,
		}
	},
	methods: {
		btnBack(){
			// Step 2 only goes back to 1, no need for browser back hijack
			this.$router.push({
				name:'EditorCreateReferenceStep1',
				params: {
					openReferenceTab: 'common'
				}
			}).catch(()=>{});
		},//e:btnBack
		
		
		handleScroll(){
			// don't process this if the create button is already showing
			if(this.showManualCreateButton || !this.$refs.scrollableBody){
				return false;
			}

			let currentScrollTop = this.$refs.scrollableBody.scrollTop;

			if(!this.isReadyToShowManualCreate && currentScrollTop >= showManualCreateThreshold){
				// Scrolled down to threshold, wait for up scroll 
				this.isReadyToShowManualCreate = true;
			}

			if(this.isReadyToShowManualCreate && currentScrollTop <= this.lastScrollTop){
				this.showManualCreateButton = true;
				this.emitter.emit('CreateReferenceStep2ThirdParty_ShowManualCreateButton');	// $emits to Header
			}

			this.lastScrollTop = currentScrollTop;
		},//e:handleScroll
	},
	// created() {
	// },
	mounted() {
		this.$nextTick(()=>{
			this.$refs.scrollableBody.addEventListener('scroll', _throttle(this.handleScroll, 666));
		});
	},
	components: {
		CreateReferenceStep2ThirdPartyBody,
		CreateReferenceStep2ThirdPartyHeader,
		ToolDrawerTitle,
	},

}
</script>
