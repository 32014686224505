<!--
- Generic Form Label and Input
-- Read Only / Display Version
-->

<template>
	<div class="form-display-v2 row mb-3">
		<div class="col-label">
			<label
				class="form-label form-label-v2"
				:for="fullId"
				v-html="label" />
		</div><!--col-->

		<div :class="{'col-value-full-width': isFullWidth, 'col-value': !isFullWidth }">
			<div :class="['form-control-display', {'text-danger': displayClass}]" v-html="value" />
		</div><!--col-->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputV2Display',
	mixins: [mixinId],
	props:{
		displayClass:{
			type: String,
		},
		helpText: {
			type: String,
		},
		isFullWidth:{
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			isShowSuggestion: false,
			isShowSuggestionGroupAuthor: false,
			localType: null,
		}
	},
	computed:{
		fullId(){
			return this.safeId;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.fullId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		
	},
	created(){
		
	},
	mounted(){
		// console.log('inputv2displaymounted');
	},

}
</script>
