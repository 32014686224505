<!--
- PaymentSettings
-- Cart > Step 3
-->

<template>
	<div class="payment-settings-wrp">
		<div class="boxset-wrp pt-4">
			<div class="alert alert-danger" v-if="!$isLive">
				<p class="fw-bold p-2">
					This is testing environment, to make a purchase, please visit: <a href="https://www.perrlacomplete.com/Public/#/cart-step-1">https://www.perrlacomplete.com/Public/#/cart-step-1</a>
				</p>
			</div>

			<a
				href="#"
				class="back-link"
				type="button"
				@click.prevent="btnGoBack">
				&lt; Back to Subscription Settings
			</a>

			<h1>Payment</h1>

			<div class="row pt-3">
				<div class="col-12 col-md-6 col-divider-right mt-2" v-if="$store.state.cart.grandTotal === 0" key="divFullyPaid">
					<img src="https://perrla.s3.amazonaws.com/images/unicorn-rainbow.png" class="img-max mx-auto d-block mb-4" role="presentation" />
					<p class="text-center org-size">
						Congratulations! Your Promo Code covered the balance!
					</p>
				</div>
				<div class="col-12 col-md-6 col-divider-right mt-2" v-else key="divPaymentFields">
					<!-- {{  isPaymentError  }} -->
					<transition name="route-fade" mode="out-in" appear>
						<div v-if="isShowCurrentPaymentMethod" key="divShowPaymentMethod">
							<h3 class="mt-0">
								Current Payment Method
							</h3>
							<table class="table table-borderless table-cart-totals">
								<tbody>
									<tr>
										<td class="text-end" width="200px">
											<h4 class="text-body m-0">
												Payment Info
											</h4>
										</td>
										<td class="fw-light text-start">
											{{ truncatePaymentSourceNumber }}
										</td>
									</tr>
									<tr v-if="($store.state.cart.paymentMethod.paymentTypeID === $enums.CartPaymentType.CARD)">
										<td class="text-end">
											<h4 class="text-body m-0">
												Expiration Date
											</h4>
										</td>
										<td class="fw-light text-start">
											{{ calcExpirationDateOnCurrentPaymentMethod }}
										</td>
									</tr>
								</tbody>
							</table>
							<div class="d-grid">
								<button 
									class="btn btn-outline-primary"
									type="button"
									@click.prevent="isHasPaymentMethodEnterNew = true">
									+ Enter a new payment method
								</button>
							</div>
							
						</div><!--divShowPaymentMethod-->

						<div v-else key="divEnterPaymentMethod">
							<module-payment-settings />

							<div class="d-grid mt-3" v-if="isHasPaymentMethodEnterNew">
								<button
									class="btn btn-outline-danger border-0 text-start"
									type="button"
									@click.prevent="isHasPaymentMethodEnterNew = false">
									Use existing payment method
								</button>
							</div>
							
						</div><!--divShowPaymentMethod-->
					</transition>
				</div>

				<div class="col-12 col-md-6 mt-2 ps-3">
					<transaction-preview />

					<div class="d-grid mb-2">
						<button
							class="btn btn-success"
							type="button"
							@click.prevent="btnProcessPayment">
							Purchase PERRLA
						</button>
					</div>

					<p>All charges are in US dollars. Payment will be processed today.</p>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import ModulePaymentSettings  from '@/modules/PaymentSettings';
import Swal from 'sweetalert2';
import TransactionPreview from './TransactionPreview';

export default {
	name: 'PaymentSettings',
	data() {
		return {
			isHasPaymentMethodEnterNew: false,
			isPaymentError: false,
		}
	},
	computed:{
		calcExpirationDateOnCurrentPaymentMethod(){
			if(this.$store.state.cart.paymentMethod && this.$store.state.cart.paymentMethod.expirationMonth && this.$store.state.cart.paymentMethod.expirationYear){
				return this.$store.state.cart.paymentMethod.expirationMonth + '/' + this.$store.state.cart.paymentMethod.expirationYear
			}
			return config.emptyText;
		},
		expirationMonth:{
			get(){
				return this.$store.state.cart.expirationMonth
			},
			set(newValue){
				this.$store.commit('cart/SET_EXPIRATION_MONTH', newValue);
			}
		},
		expirationYear:{
			get(){
				return this.$store.state.cart.expirationYear
			},
			set(newValue){
				this.$store.commit('cart/SET_EXPIRATION_YEAR', newValue);
			}
		},
		isShowCurrentPaymentMethod(){
			if(this.$store.state.cart.paymentMethod && !this.isHasPaymentMethodEnterNew && !this.isPaymentError){
				return true;
			}
			return false;
		},
		truncatePaymentSourceNumber(){
			if(this.$store.state.cart.paymentMethod){
				return this.$store.state.cart.paymentMethod.name;
			}
			return config.emptyText;
		},
		
		
	},
	methods: {
		btnGoBack(){
			this.$store.commit('loaders/ADD_ID', 'App');

			// simulate making an api call
			setTimeout(()=>{
				this.$router.push({
					name: 'SubscriptionSettings',
				}).catch(()=>{});
			}, 66);
		},
		btnProcessPayment(){
			AS_ValidateForm({
				formId: 'formCartPaymentSettings'
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				this.$store.dispatch('cart/assembleObject').then((cartObject)=>{
					let sendCartObject = _cloneDeep(cartObject);

					// for some reason the api token goes away, set it in local storage here so i can get it back on the receipt page
					localStorage.setItem("pc_PassToken", this.$store.state.api.token);
					
					// if the user has the new payment method panel open, then change the cart object to pass that information instead
					if(this.isHasPaymentMethodEnterNew || this.isPaymentError){
						if(sendCartObject.paymentTypeId === config.enums.CartPaymentType.ECHECK){
							sendCartObject.paymentMethod = {
								accountNumber: this.$store.state.cart.accountNumber,
								accountType: this.$store.state.cart.accountType,
								bankName: this.$store.state.cart.bankName,
								name: 'E-Check from Account Ending In ' + this.$store.state.cart.accountNumber.slice(this.$store.state.cart.accountNumber.length - 4, this.$store.state.cart.accountNumber.length),
								paymentTypeID: this.$store.state.cart.paymentTypeId,
								routingNumber: this.$store.state.cart.routingNumber,
							}
						} else {
							sendCartObject.paymentMethod = {
								cardNumber:  this.$store.state.cart.cardNumber.replace(/\s/g, ''),
								cardType: this.$store.state.cart.creditCardType,
								expirationMonth: this.$store.state.cart.expirationMonth.padStart(2, '0'),
								expirationYear: this.$store.state.cart.expirationYear.padStart(4, '0'),
								name: 'Credit Card Ending In ' + this.$store.state.cart.cardNumber.slice(this.$store.state.cart.cardNumber.length - 4, this.$store.state.cart.cardNumber.length),
								nameOnCard: this.$store.state.cart.firstName + ' ' + this.$store.state.cart.lastName,
								paymentTypeID: this.$store.state.cart.paymentTypeId,
								verificationCode: this.$store.state.cart.verificationCode.padEnd(3, '0'),
							}
						}
						// update the payment method in the vuex store
						this.$store.commit('cart/SET_PAYMENT_METHOD', sendCartObject.paymentMethod);
					}//e:if:this.isHasPaymentMethodEnterNew
					
					// 1- make the purchase
					ApiS_MakeCall({
						body: sendCartObject,
						ignoreAuth: true,
						method: 'POST',
						responseType: 'json',
						url: 'v4/Purchase',
					}).then((responseData)=>{
						console.log('v4/Purchase responseData');
						console.log(responseData);
					
						this.$store.commit('loaders/REMOVE_ID', 'App');

						if(responseData.isSuccessful){
							this.isPaymentError = false;
							
							this.$store.commit('cart/SET_PURCHASE_ID', responseData.purchaseID);
							this.$store.commit('cart/SET_PRODUCT_NAME', responseData.product);
							this.$store.commit('cart/SET_TRANSACTION_ID', responseData.transactionID);

							// 2 - store newUserToken - to get to the dashboard 
							if(!this.$store.state.cart.customerId || this.$store.state.cart.customerId === 0){
								this.$store.commit('api/SET_TOKEN', responseData.newUserToken);
							}

							// for some reason the api token goes away, set it in local storage here so i can get it back on the receipt page
							localStorage.setItem("pc_PassToken", this.$store.state.api.token);

							this.$router.push({
								name: 'Receipt'
							}).catch(()=>{});

						} else {
							// not a successful transaction, set this flag
							this.isPaymentError = true;
							
							Swal.fire({
								allowOutsideClick: false,
								buttonsStyling: false,
								title: '',
								text: responseData.errors[0],
								icon: 'error',
								confirmButtonText: 'Got it - thanks!',
								showCloseButton: false,
								showCancelButton: false,
								showConfirmButton: true,
								customClass:{
									confirmButton: 'btn btn-success me-2',
								},
							});

						}//e:else:isSuccessful

					}).catch((error)=>{
						// there was an error - clear the payment just incase
						let messageOutput = 'There was an error.';

						if(error && _has(error, 'message')){
							messageOutput = error.message;
						}
						
						this.$store.commit('loaders/REMOVE_ID', 'App');
						
						this.$store.commit('cart/CLEAR_PAYMENT_METHOD');
						
						Swal.fire({
							allowOutsideClick: false,
							buttonsStyling: false,
							title: '',
							text: messageOutput,
							icon: 'error',
							confirmButtonText: 'Got it - thanks!',
							showCloseButton: false,
							showCancelButton: false,
							showConfirmButton: true,
							customClass:{
								confirmButton: 'btn btn-success me-2',
							},
						});
					});//e:ApiS_MakeCall

				});//e:dispatch:cart/assembleObject

			}).catch(()=>{});//e:AS_ValidateForm
		
		},//e:btnProcessPayment
	},
	mounted() {
		if(this.$store.state.cart.shoppingCartUserId === 0) {
			this.$router.push({
				name: 'IdentifyUser'
			}).catch(()=>{});

		} else {
			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
	
			// force remove any theme classes - this view will not have a customer theme
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');
			document.body.classList.add('bg-simple');
			this.$store.dispatch('cart/updateCartTotals').then(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});
		}
	},
	components:{
		ModulePaymentSettings,
		TransactionPreview
	},
}
</script>


<style lang="scss" scoped>
@import "../../assets/styles/vars_perrla";

.payment-settings-wrp{
	margin-top: 5.5rem;
	
	@include media-breakpoint-down(md) { 
		margin-top: 0.75rem;
		margin-bottom: 1.5rem;
	}
}
</style>