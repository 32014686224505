var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.referenceOutput != ""
    ? _c("div", { staticClass: "alert alert-info mb-4" }, [
        _c("h3", [_vm._v("Live Preview")]),
        _vm._v(" "),
        _c("p", {
          directives: [
            { name: "add-blank-target", rawName: "v-add-blank-target" },
          ],
          staticClass: "org-size font-reference",
          domProps: { innerHTML: _vm._s(_vm.referenceOutput) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }