var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "flex-shrink-1 float-end pe-2 paper-status-wrp",
        { "cursor-pointer": _vm.isClickable },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "paper-status-dot",
          attrs: { title: _vm.calcPaperStatusTextOutput },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.clickOnStatusDot.apply(null, arguments)
            },
          },
        },
        [_c("div", { class: _vm.calcPaperStatusDotClasses })]
      ),
      _vm._v(" "),
      _vm.showPaperSavedText
        ? _c("p", { staticClass: "paper-status-text text-success me-2" }, [
            _vm._v("\n\t\tPaper Saved\n\t"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }