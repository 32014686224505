/*
- Logs
-- write logs to local storage
-- ex: this.$store.state.logs.x
*/
import _forEach from 'lodash/forEach';
import config from '@/config';

const daysOfLogsToKeep = 14;

export default {
	namespaced: true,
	
	state: {
		// hasPurgedThisSession: false,	// only purge once per 'session' so every sync isn't slow - there isn't a real session check it just runs the first time the SPA loads
	},
	
	mutations: {
		// SET_HAS_PURGED_THIS_SESSION(state, value){
		// 	state.hasPurgedThisSession = value;
		// }
	},

	actions: {
		clearAll ({commit}, $opts) {
			return new Promise((resolve, reject) => {

				const openRequest = indexedDB.open('po_logs', 1);

				openRequest.onupgradeneeded = () => {
					let db = openRequest.result;

					if(!db.objectStoreNames.contains('logs')){
						db.createObjectStore('logs', {
							keyPath: 'd'
						});
					}
				};//e:onupgradeneeded

				openRequest.onerror = () => {
					resolve();
				};

				openRequest.onsuccess = () => {
					let db = openRequest.result;

					if(db.objectStoreNames.contains('logs')){
						
						const txn = db.transaction(['logs'], 'readwrite');
						const store = txn.objectStore('logs');

						store.clear();

						window.$vm.emitter.emit('globalToasterOpen',{
							textContent: 'IndexedDB po_logs clear',
						});

						txn.oncomplete = () => {
							db.close();
							resolve();
						};
					} else {
						resolve();
					}

				};//e:onsuccess
			});//e:Promise
		},//e:clearAll

		// assemble an array of objects that live in the users local log file
		getAll({commit}) {
			return new Promise((resolve, reject) => {
				let returnedLogs = [];

				const openRequest = indexedDB.open('po_logs', 1);

				openRequest.onupgradeneeded = () => {
					// console.log('indb:onupgradeneeded logs getAll');

					let db = openRequest.result;

					if(!db.objectStoreNames.contains('logs')){
						db.createObjectStore('logs', {
							keyPath: 'd'
						});
					}
				};//e:onupgradeneeded

				openRequest.onerror = () => {
					resolve();
				};

				openRequest.onsuccess = () => {
					let db = openRequest.result;

					if(db.objectStoreNames.contains('logs')){
						const txn = db.transaction(['logs'], 'readwrite');
						const store = txn.objectStore('logs');

						let request = store.getAll();

						request.onsuccess = () => {
							if (request.result) {
								returnedLogs = request.result;
							}
						};//e:onsuccess

						txn.oncomplete = () => {
							db.close();
							resolve(returnedLogs);
						};
					} else {
						resolve([]);
					}
				};//e:onsuccess

			});//e:Promise
		},//e:getAll
		
		// verify the most recent log - usually that would be a placeholder - this calls after a successful save
		verify({commit}, $opts) {
			return new Promise((resolve, reject) => {
				const openRequest = indexedDB.open('po_logs', 1);
	
				// triggers if the client had no database
				openRequest.onupgradeneeded = () => {
					let db = openRequest.result;
					if(!db.objectStoreNames.contains('logs')){
						db.createObjectStore('logs', {
							keyPath: 'd',
						});
					}
				};//e:onupgradeneeded

				openRequest.onsuccess = () => {
					let db = openRequest.result;

					if(db.objectStoreNames.contains('logs')){
											
						const txn = db.transaction(['logs'], 'readwrite');
						const store = txn.objectStore('logs');

						let request = store.openCursor(null, 'prev');	// get the last entry - a placeholder was created right before this step

						request.onsuccess = () => {
							let requestResult = request.result;
							if (requestResult) {
								// this item should now be fresh since the save went through
								let item = requestResult.value;
								item.s = $opts.s3Link;
								store.put(item);
							}
						};//e:onsuccess

						txn.oncomplete = () => {
							db.close();
							resolve();
						};
					} else {
						resolve();
					}

				};//e:onsuccess
			});//e:Promise
		},//e:verify

		// write a log placeholder - usually done before right before a save call is made
		write({rootState}, $opts) {
			return new Promise((resolve, reject) => {
				// console.log('write to log');
				// console.log($opts.message);

				const openRequest = indexedDB.open('po_logs', 1);

				openRequest.onupgradeneeded = () => {
					// console.log('indb:onupgradeneeded logs write');
					let db = openRequest.result;

					if(!db.objectStoreNames.contains('logs')){
						db.createObjectStore('logs', {
							keyPath: 'd',
						});
					}
				};//e:onupgradeneeded

				openRequest.onsuccess = () => {
					let db = openRequest.result;

					if(db.objectStoreNames.contains('logs')){
						const txn = db.transaction(['logs'], 'readwrite');
						const store = txn.objectStore('logs');

						store.put({
							d: new Date().toISOString(),				// created date
							m: $opts.message,							// message
							p: rootState.paperEdit.openObject.paperID,	// paperId
							s: '',										// s3 link - placeholder that fills in during a verification step
						});

						txn.oncomplete = () => {
							resolve();
							db.close();
						};
					} else {
						resolve();
					}

				};//e:onsuccess
			});//e:Promise
		},//e:write
	},
}