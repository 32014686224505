<!--
- Outline Editor > Tool Drawer > Citation Add
-- not a real citation - this is the citation from the outline editor
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title
				title="Add Citation"
				close-to-route-name="OutlineReferencesCitations" />
		</div>

		<div class="drawer-body">
			<p class="mb-4">
				Before creating the citation, place your cursor where you want the citation to appear.
			</p>

			<form>
				<citation-form
					:paper-format-version-id="paperFormatVersionID"
					:reference-unique-id="$route.params.referenceUid.toUpperCase()"
					@enableCitationFormButton="isDisabled = false"
					@disableCitationFormButton="isDisabled = true" />

				<div class="row">
					<div class="col-6">
						<div class="d-grid">
							<router-link 
									:to="{name: 'OutlineReferencesCitations'}"
									class="btn btn-outline-danger">
									Cancel
							</router-link>
						</div>
					</div>
					<div class="col-6">
						<div class="d-grid">
							<button type="submit" class="btn btn-success" @click.prevent="btnAddCitation" :disabled="isDisabled" ref="btnCreateCitation">
								Create Citation
							</button>
						</div>
					</div>
				</div>

			</form>
			
		</div>
		
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import CitationForm from '@/modules/CitationForm';
import config from '@/config';
import ErrS_Reroute from '@/services/app/error/reroute.js';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import PCS_InsertCitation from '@/services/paper/citations/insertCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'OutlineCitationAdd',
	data() {
		return {
			isDisabled: true,
		}
	},
	computed:{
		paperFormatVersionID(){
			// on load the paper hasn't loaded it's meta data yet
			if(this.$store.state.paperEdit.meta.PaperFormatVersionID === ''){
				return 0;
			}
			return this.$store.state.paperEdit.meta.PaperFormatVersionID;
		},
	},
	methods: {
		btnAddCitation(){
			let newCitationResult = _cloneDeep(this.$store.state.paperEdit.citations.tempCitationData);

			try {
				newCitationResult.citationData.citationUniqueID = uuidv4().toUpperCase();
				newCitationResult.citationData.referenceUniqueID = this.$route.params.referenceUid.toUpperCase();
			} catch {
				ErrS_Reroute({
					pushToName: 'OutlineReferencesCitations'
				});

				return false;	// on catch stop
			}

			// clear tempCitationData now that you cloned it
			this.$store.commit('paperEdit/citations/SET_TEMP_CITATION_DATA', {});
			
			// console.log('newCitationResult');
			// console.log(newCitationResult);

			let valueFirst = '';
			let valueSubsequent = '';

			// calculate the first and subsequent values here
			if(this.paperFormatVersionID === config.enums.Format.APA7){
				// APA7 
				valueFirst = newCitationResult.apa7.first;
				valueSubsequent = newCitationResult.apa7.subsequent;

			} else if(this.paperFormatVersionID === config.enums.Format.MLA9){
				// MLA9
				valueFirst = newCitationResult.mla9.first;
				valueSubsequent = newCitationResult.mla9.subsequent;
			
			} else if(this.paperFormatVersionID === config.enums.Format.Turabian9){
				// Turabian9
				valueFirst = newCitationResult.turabian9.first;
				valueSubsequent = newCitationResult.turabian9.subsequent;

			}

			let newCitationResultAsJson = JSON.stringify(newCitationResult);

			let citationDataBase64 = window.btoa(newCitationResultAsJson.replace(/[\u00A0-\u2666]/g, (c)=>{
				return '&#' + c.charCodeAt(0) + ';';
			}));

			PCS_InsertCitation({
				'citationDataBase64': citationDataBase64,
				'citationUniqueID': newCitationResult.citationData.citationUniqueID,
				'referenceUniqueID': newCitationResult.citationData.referenceUniqueID,
				'valueFirst': valueFirst,
				'valueSubsequent': valueSubsequent,
			}).then(()=>{
				// now you can save
				PS_BuildReferencesInPaper().then(()=>{
					OS_SaveContent().then(()=>{
						this.emitter.emit('setOutlineEditorHeight');
						
						this.$router.push({
							name: 'OutlineReferencesCitations'
						}).catch(()=>{});
					});
				});//e:PS_BuildReferencesInPaper
				
			}).catch((error)=>{
				console.log('CitationAdd - error');
				console.log(error);
				this.$router.push({
					name: 'OutlineReferencesCitations'
				}).catch(()=>{});
			});//e:PCS_InsertCitation
		},//e:btnAddCitation
	},
	components: {
		CitationForm,
		ToolDrawerTitle
	},
	mounted(){
		this.$nextTick(()=>{
			if(this.$refs.btnCreateCitation){
				this.$refs.btnCreateCitation.focus();
			}
		});
	}
}
</script>
