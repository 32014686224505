import config from '@/config';
import ModalReminderEdit from '@/views/ReminderList/modals/Single.vue';
import ReminderList from '@/views/ReminderList';

export default [
	{
		name: 'ReminderList',
		component: ReminderList,
		path: '/reminders',
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.REMINDER_LIST,
			tab: config.enums.Tab._
		},
		children: [
			{
				name: 'ReminderListSingle',
				component: ModalReminderEdit,
				path: 'single/:encryptedId',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REMINDER_LIST,
					tab: config.enums.Tab._
				},
			},
		]
	},
]